import React, { Component } from "react";
import { Redirect, Route, Switch, } from "react-router-dom";
//Pages
import Signup from "../pages/Auth/Signup";

class PublicRouter extends Component {
  render = () => {
    return (
      <Switch>
        <Route path="/" exact render={() => <Redirect to="/signup" />} />
        <Route path="/signup" exact component={Signup} />
        <Route render={() => <Redirect to="/signup" />} />
      </Switch>
    );
  };
}

export default PublicRouter;
