import React, { Component } from "react";

import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Switch,
  Spin,
  Popover,
  Empty,
  Dropdown,
  Menu,
} from "antd";

import "antd/dist/antd.css";
import "../../stylesheets/styles.css";
import layer from "../../assets/Layer 2.svg";

import TopHeader from "../../components/ui/TopHeader";
import TableField from "../../components/ui/tableField";
import { STORAGE, UPDATE, APPROVAL, DELETE } from "../../constants/roleStore";

import {
  showSuccess,
  showError,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
} from "../../constants/Utils";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { FormOutlined, MoreOutlined } from "@ant-design/icons";
import { JOBACCESS } from "../../components/ui/constants";
class Jobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: "",
      total: 0,
      loading: true,
      currentPage: 1,
      tableLoading: false,
      queryStatus: {},
      totalDoc: "",
      app_name: "",
      app_type: "",
      checkJob: "deactive",

      pageSize: 20,
      dropdown_menu: false,
      list_index: "",
      update: false,
      approval: false,
    };
    this.wrapperRef = React.createRef();
  }
  componentDidMount() {
    let name = "";

    let storage = JSON.parse(STORAGE.view);
    name = storage?.filter((app) => app?.name === "Settings");

    let value = name;
    value?.filter((app) => {
      let names = app?.sub_roles?.filter((role) => role.name === "Jobs");
      names &&
        names.map((el) =>
          this.setState({
            update: el?.permissions?.update,
            approval: el?.permissions?.approval,
          })
        );
      this.setState({
        app_name: names,
      });
      document.addEventListener("click", this.clickOutside);
    });

    this.setState({
      app_type: STORAGE.type,
    });
    this.setQueryStatus();
    this.reloadPage();
    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.setQueryStatus();
        }
      });
    });
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",
          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    }
  };

  onPopstate = () => {
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);

    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";
    this.state.queryStatus = returnSearchObject(search);

    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      this.handleTable();
    });
  };
  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);
  }
  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };
  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";

    return querysearch;
  };

  handleFilter = () => {
    let query = this.getQuerySearch();

    this.setState(
      {
        queryStatus: query,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.handleTable();
      }
    );
  };

  handleTable = () => {
    APIGATEWAY.get(
      "jobs/" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          let data = response.data.jobs;
          let totals = response.data?.jobs?.length;
          this.setState({
            job: data,
            total: totals,
            loading: false,
            Tableloading: false,
            totalDoc: response.data?.total,
          });
        } else {
          this.setState({
            loading: true,
          });
        }
      }
    );
  };

  stateHandler = (data) => {
    data.status = data.status === "active" ? "deactive" : "active";

    APIGATEWAY.patch(
      "jobs/" + data.job_id,
      { status: data.status },
      (response) => {
        if (response.success) {
          this.handleTable();
          showSuccess(response.message);
        } else {
          showError(response.message);
        }
      }
    );
  };

  jobEnableChange = (data) => {
    data.status = data.status === "active" ? "deactive" : "active";

    this.setState({
      checkJob: data.status === "active" ? "deactive" : "active",
    });

    APIGATEWAY.patch(
      "jobs/" + data.job_id,
      { status: data.status },
      (response) => {
        if (response.success) {
          this.handleTable();
          showSuccess(response.message);
        } else {
          showError(response.message);
        }
      }
    );
  };
  JobEditHandler = (job_id) => {
    this.props.history.push("/jobs/edit/" + job_id);
  };

  previous = () => {
    this.setState(
      {
        currentPage: this.state.currentPage - 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  next = () => {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  handleActions = (e, job_id) => {
    if (e === "edit") {
      this.props.history.push("/jobs/edit/" + job_id);
    }
  };
  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      list_index: index,
    });
  };
  render() {
    const {
      job,
      total,
      loading,
      currentPage,
      Tableloading,
      app_name,
      app_type,
      pageSize,
    } = this.state;
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }
    const menu = (data) => (
      <Menu
        className="dropdown-menu-bar"
        onClick={(e) => this.handleActions(e, data.job_id)}
      >
        <Menu.Item key="edit">Edit</Menu.Item>
      </Menu>
    );

    return (
      <>
        <div>
          <div className="">
            <TopHeader
              title="Jobs"
              to="/jobs/add"
              type={app_type}
              permission={app_name}
            />
            {loading ? (
              <div className="spinning-star">
                <Spin size="large" />
              </div>
            ) : (
              <div style={{ padding: "0px 23px 30px 30px" }}>
                <div className="table_page_wrap">
                  <table className="table-response">
                    <thead>
                      <tr>
                        <th className="">S.No</th>
                        <th className="">Title</th>
                        <th className="">Skills</th>
                        <th className="">Experience</th>
                        <th></th>
                      </tr>
                    </thead>
                    {job.length > 0 ? (
                      <tbody ref={this.wrapperRef}>
                        {job.map((item, i) => (
                          <tr key={i}>
                            <td className="">
                              {" "}
                              {(this.state.currentPage - 1) * 10 + (i + 1)}
                            </td>
                            <td className="table-name text-capitalize">
                              {item.title}
                            </td>
                            <td className="">
                              {" "}
                              <div>
                                {item.skills?.length >= 14 ? (
                                  <Popover
                                    overlayStyle={{
                                      maxWidth: "20vw",
                                    }}
                                    content={item.skills}
                                  >
                                    {item.skills.slice(0, 13) + "..."}
                                  </Popover>
                                ) : (
                                  item.skills
                                )}
                              </div>
                            </td>
                            <td className="">
                              {" "}
                              {`${item?.experience?.minimum} - ${item?.experience?.maximum} (Years)`}
                            </td>

                            <td className="table-action">
                              <div className="tax-add-edit">
                                {JOBACCESS.approval && (
                                  <span className="mr-4">
                                    <span>
                                      <label className="switch-tax">
                                        <input
                                          type="checkbox"
                                          checked={item.status === "active"}
                                          value={this.state.checkJob}
                                          onChange={() =>
                                            this.jobEnableChange(item)
                                          }
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </span>
                                  </span>
                                )}

                                {JOBACCESS.update && (
                                  <span
                                    className="ml-5"
                                    onClick={() => this.dropdownClick(i)}
                                  >
                                    <div className="dropdown-head">
                                      <div className="dropbtn-head">
                                        <img
                                          src={layer}
                                          alt="profile"
                                          className="menu-bar-icon"
                                        />
                                      </div>
                                      {this.state.list_index === i &&
                                        this.state.dropdown_menu && (
                                          <div className="dropdown-content-heads">
                                            <div>
                                              <p
                                                className="action-menu-bar"
                                                onClick={() =>
                                                  this.handleActions(
                                                    "edit",
                                                    item.job_id
                                                  )
                                                }
                                              >
                                                Edit
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </span>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody className="empty-message">
                        <tr>
                          <td colspan="5">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                  {job.length > 0 && (
                    <TableField
                      data={job}
                      countprev={countprev}
                      countnext={countnext}
                      currentPage={currentPage}
                      ClickPrev={() => this.previous()}
                      ClickNext={() => this.next(this.state.currentPage)}
                      total={total}
                      overAll={this.state.totalDoc ?? 0}
                      pagelimit={this.state.pageSize}
                      pagelimitHandle={this.pageLimitOnchange}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default Jobs;
