// Old project(30/08/23)




import React, { Component } from "react";
import { Table, Input, Spin, Card, Button, Select, Empty } from "antd";
import "../../stylesheets/styles.css";
import "antd/dist/antd.css";
import TopHeader from "../../components/ui/TopHeader";

import { STORAGE, UPDATE } from "../../constants/roleStore";
import layer from "../../assets/Layer 2.svg";

import { Link } from "react-router-dom";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { FormOutlined } from "@ant-design/icons";

import Filter from "../../components/ui/filter";
import TableField from "../../components/ui/tableField";

import {
  showDate,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  showError
  , projectStatusColor,
  isErrorTxt
} from "../../constants/Utils";
const { Option } = Select;
class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      search: "",
      total: "",
      currentPage: 1,
      pageSize: 20,
      loading: true,
      status: "",
      searchOption: "",
      searchInput: "",
      Tableloading: false,
      totalDoc: "",

      statusData: [
        { value: "Active", key: "active" },
        { value: "Hold", key: "hold" },
        { value: "Discontinued", key: "discontinued" },
        { value: "Completed", key: "completed" },
        { value: "Canceled", key: "canceled" },
      ],
      searchData: [
        { value: "Client Id", key: "client_id" },
        { value: "Project Id", key: "project_id" },
        { value: "Emp Id", key: "employee" },
      ],
      app_name: "",
      app_type: "",
      queryStatus: {},
      dropdown_menu: false,
      list_index: '',
      update: false,
    };
    this.wrapperRef = React.createRef();

  }
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);

    this.setState({ status: params.get("status") || "" }, () => {
      this.setQueryStatus();
    });

    let name = "";

    let storage = JSON.parse(STORAGE.view);

    name = storage?.filter((app) => app?.name === "My Business");
    let value = name;

    value?.filter((app) => {
      let names = app?.sub_roles?.filter((role) => role.name === "Projects");
      names && names.map((el) => (

        this.setState({
          update: el?.permissions?.update,


        })
      ));
      this.setState({
        app_name: names,

      });
    });
    this.setState({

      app_type: STORAGE.type,
    });


    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);

    this.reloadPage();
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.clearSearch();
        }
      });
    });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.handleTable();
    });
  };
  handleSearchChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.handleTable();
      }
    );
  };
  onPopstate = () => {
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);

    this.state.searchOption = searchObj.type;
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";

    this.state.searchInput =
      (searchObj.value) ?? "";
    this.state.status = searchObj.status ?? "";
    this.state.queryStatus = returnSearchObject(search);

    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      this.handleTable();
    });
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);

  }
  clickOutside = (e) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(e.target)) {
      this.setState({ dropdown_menu: false });
    }
  };
  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    if (this.state.searchOption !== "" && this.state.searchInput !== "") {
      querysearch.type = this.state.searchOption;
      querysearch.value = this.state.searchInput;
      querysearch[this.state.searchOption] = this.state.searchInput;
    }
    querysearch.status = this.state.status ?? "";
    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";

    return querysearch;
  };
  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          searchInput:
            (queryObj.value) ?? "",
          searchOption: queryObj.type ?? "",
          status: queryObj.status ?? "",

          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",

          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    }
  };

  handleFilter = () => {
    let query = this.getQuerySearch();

    this.setState(
      {
        queryStatus: query,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.handleTable();
      }
    );
  };

  handleTable = () => {
    APIGATEWAY.get(
      "projects/" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          let currentData = response.data.projects;
          let total = response.data?.projects?.length;

          this.setState({
            data: currentData,
            total,
            loading: false,
            Tableloading: false,
            totalDoc: response.data?.total,
          });
        } else {
          this.setState({ loading: true });
        }
      }
    );
  };

  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  next = (prev) => {
    const { currentPage } = this.state;

    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };
  handleDetailpage = (username) => {
    this.props.history.push("/projects/detail/" + username);
  };
  handleEditPage = (username) => {
    this.props.history.push("/projects/edit/" + username);
  };


  Apply = () => {
    const { status, searchInput, searchOption } = this.state;
    if (status === "" && (searchInput === "" || searchOption === "")) {
      showError('Please select any one field');
    } else {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handleFilter();
        }
      );
    }

  };
  clearSearch = () => {
    this.setState(
      {
        searchInput: "",
        searchOption: "",
        status: "",
        currentPage: 1,
        queryStatus: { page: 1, limit: 20 },
        pageSize: 20,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);

        this.handleTable();
      }
    );
  };
  handleOnSearchChange = (value) => {
    this.setState(
      {
        status: value,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  searchInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  searchOption = (value) => {
    this.setState({
      searchOption: value,
    });
  };

  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  dropdownClick = (index) => {
    this.setState({ dropdown_menu: !this.state.dropdown_menu, list_index: index });
  };
  render() {
    const { data, total, currentPage, app_name, app_type, update } = this.state;
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * 10 + total;
      countprev = countnext - (total - 1);
    }

    return (
      <div className="">

        <div style={{ marginBottom: "100px" }}>
          <TopHeader
            title="Projects"
            to="/projects/add"
            type={app_type}
            permission={app_name}
          />
          <div className="main-content">
            <Filter
              searchData={this.state.searchData}
              statusData={this.state.statusData}
              optionChange={(e) => this.searchOption(e)}
              searchOption={this.state.searchOption}
              searchname="searchOption"
              statusName="status"
              statusSelect={this.state.status}
              statusOnChange={this.handleOnSearchChange}
              clear={() => this.clearSearch()}
              apply={() => this.Apply()}
              searchInputName="searchInput"
              searchInput={this.state.searchInput}
              inputChange={(e) => this.searchInput(e)}
            />
            {this.state.loading ? (
              <div className="spinning-star">
                <Spin size="large" />
              </div>
            ) : (
              <div className="table-main">
                <div className="table_page_wrap">

                  {data.length > 0 ?
                    (<table className="table-response">
                      <thead>
                        <tr>
                          <th className=''>S.No</th>
                          <th className=''>Name</th>
                          <th className=''>Start Date</th>
                          <th className=''>End Date</th>
                          <th className=''>Status</th>
                          <th></th>
                        </tr>
                      </thead>

                      {data.length > 0 ? (
                        <tbody ref={this.wrapperRef}>
                          {data.map((item, r) => (
                            <tr key={r}>
                              <td className=''>  {(this.state.currentPage - 1) * this.state.pageSize + (r + 1)}
                              </td>
                              <td className="table-name text-capitalize"><div
                                style={{ cursor: "pointer" }}
                                onClick={() => this.handleDetailpage(item.project_id)}
                              >
                                {item.name}
                              </div></td>

                              <td className="table-name">{showDate(item?.date?.start)}</td>
                              <td className="table-name">{showDate(item?.date?.close)}</td>
                              <td className="table-name text-capitalize font-weight-content">
                                {isErrorTxt(item.status) ? "-" : projectStatusColor(item.status)}

                              </td>

                              {app_type === 'sub_admin' && update === false ? "" :

                                <td className="table-action" onClick={() => this.dropdownClick(r)}>
                                  <div className="tax-add-edit">
                                    <span className="ml-5">
                                      <div className="dropdown-head">
                                        <div className="dropbtn-head">
                                          <img
                                            src={layer}
                                            alt="profile"
                                            className="menu-bar-icon"
                                          />
                                        </div>
                                        {this.state.list_index === r && this.state.dropdown_menu && (

                                          <div className="dropdown-content-heads">
                                            <div>
                                              {UPDATE(
                                                app_type,
                                                app_name,
                                                <p
                                                  className="action-menu-bar"

                                                  onClick={() =>
                                                    this.handleEditPage(
                                                      item.project_id
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </p>
                                              )}


                                            </div>

                                          </div>)}
                                      </div>
                                    </span>
                                  </div>
                                </td>}

                            </tr>
                          ))}

                        </tbody>) : ''}
                    </table>) :
                    (<table className="table-response">
                      <thead>
                        <tr>
                          <th className=''>S.No</th>
                          <th className=''>Name</th>
                          <th className=''>Start Date</th>
                          <th className=''>End Date</th>
                          <th className=''>Status</th>
                          <th></th>
                        </tr>
                      </thead>


                      <tbody className="empty-message">
                        <tr>
                          <td colspan="6">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </td>

                        </tr>
                      </tbody>



                    </table>)
                  }
                  {data.length > 0 && (
                    <TableField
                      data={data}
                      countprev={countprev}
                      countnext={countnext}
                      currentPage={currentPage}
                      ClickPrev={() => this.previous()}
                      ClickNext={() => this.next(this.state.currentPage)}
                      total={total}
                      overAll={this.state.totalDoc ?? 0}
                      pagelimit={this.state.pageSize}
                      pagelimitHandle={this.pageLimitOnchange}
                    />
                  )}


                </div>

              </div>)}

          </div>
        </div>

      </div>
    );
  }
}
export default Projects;
