import React from "react";
import { Select } from "antd";
import SelectIcon from "../../assets/Vector.svg";
const { Option } = Select;

const SelectItem = ( { label, placeholder, value,data, role, ...props } ) => {
  return (

    <div className="input-item-label" >
      <label className={props.disabled ? "disable-label":'block-label'}><span>{label}</span><span className="star">{props.star ? '*' : ''}</span></label>

      <div>
        <Select
          name={props.name}
          value={value || undefined}
          onChange={props.onChange}
          getPopupContainer={( trigger ) => trigger.parentNode}
          className={props.accessType ? "select-dropdown-menus-access" : "select-dropdown-menus"}
          placeholder={placeholder}
          autoComplete="off"
          disabled={props.disabled ? true : false}
          showSearch={props.showSearch?true:false}
          suffixIcon={
            <div>
              <img src={SelectIcon} alt="" width="10px" className="select-icon" />
            </div>
          }
        >

          {data &&
            data.map( ( status, index ) => (
              role ? (
                <Option key={status?.role_id} value={
                  JSON.stringify( {
                    name: status?.name,
                    role_id: status?.role_id,
                  } )

                }>
                  {status.name}
                </Option>
              ) : (
                <Option key={index} value={status.key}>
                  {status.value}
                </Option>
              )

            ) )}
        </Select>
      </div>
    </div>
  );
};

export default SelectItem;
