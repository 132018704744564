import React from "react";
import dashboard from "../assets/fi-rr-apps.png";
import employees from "../assets/Vector (7).png";
import timeoff from "../assets/fi-rr-calendar (1).png";
import payment from "../assets/fi-rr-dollar.png";
import hiring from "../assets/Group 1000004658.png";
import business from "../assets/Vector (10).png";
import settings from "../assets/Group 1000004661.png";
import { MODULENAME } from "../components/ui/constants";

const {
  Dashboard,
  Employees,
  Hiring,
  Leaves,
  Permissions,
  Invoices,
  Taxes,
  Tasks,
  Expenses,
  Salaries,
  EmployeeExpenses,
  Projects,
  Leads,
  Clients,
  Platforms,
  Technologies,
  ResourceManagement,
  Jobs,
  Accessories,
  RoleManagement,
  Careers,
  Queries,
  Holidays,
  Calculator,
  Roles,
  Vendors,
} = MODULENAME;

export const SidebarData = [
  {
    name: Dashboard,
    path: "/dashboard",
    icon: dashboard,
    isActive: false,
    permissions: {
      create: false,
      approval: false,
      update: false,
      delete: false,
    },
  },

  {
    name: Employees,
    path: "/employees",
    icon: employees,
    isActive: false,
    permissions: {
      create: false,
      approval: false,
      update: false,
      delete: false,
    },
  },
  
  {
    name: Hiring,
    path: "/onboard",
    icon: hiring,
    isActive: false,
    permissions: {
      create: false,
      approval: false,
      update: false,
      delete: false,
    },
  },
  {
    name: "Time Off",
    path: "",
    icon: timeoff,
    isActive: false,
    subRole: false,
    permissions: {
      create: false,
      approval: false,
      update: false,
      delete: false,
    },
    sub_roles: [
      {
        name: Leaves,
        path: "/leaves",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: Permissions,
        path: "/permissions",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
    ],
  },
  {
    name: "My Payments",
    path: "",
    icon: payment,
    isActive: false,
    subRole: false,
    permissions: {
      create: false,
      approval: false,
      update: false,
      delete: false,
    },
    sub_roles: [
      {
        name: Invoices,
        path: "/invoices",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: Taxes,
        path: "/taxes",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: Expenses,
        path: "/expenses",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: Salaries,
        path: "/salaries",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: EmployeeExpenses,
        path: "/employee/expenses",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
    ],
  },

  {
    name: "My Business",
    path: "",
    icon: business,
    isActive: false,
    subRole: false,
    permissions: {
      create: false,
      approval: false,
      update: false,
      delete: false,
    },
    sub_roles: [
      {
        name: Projects,
        path: "/projects",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: Leads,
        path: "/leads",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: Tasks,
        path: "/tasks",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: Clients,
        path: "/clients",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: Platforms,
        path: "/platforms",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: Technologies,
        path: "/technologies",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: ResourceManagement,
        path: "/resource_management",
      },
    ],
  },

  {
    name: "Settings",
    path: "",
    icon: settings,
    isActive: false,
    subRole: false,
    permissions: {
      create: false,
      approval: false,
      update: false,
      delete: false,
    },
    sub_roles: [
      {
        name: Jobs,
        path: "/jobs",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: Accessories,
        path: "/accessories",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: RoleManagement,
        path: "/role_manage",
      },
      {
        name: Careers,
        path: "/careers",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: Queries,
        path: "/requests",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: Holidays,
        path: "/holidays",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: Calculator,
        path: "/calculator",
      },
      {
        name: Roles,
        path: "/roles",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
      {
        name: Vendors,
        path: "/vendors",
        permissions: {
          create: false,
          approval: false,
          update: false,
          delete: false,
        },
      },
    ],
  },
];
