// import React, { useState, useEffect } from "react";
import React, { Component } from "react";

import { Table, DatePicker, Button, Spin, Empty } from "antd";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import {
  getIsoString,
  showError,
  showDate,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
} from "../../constants/Utils";
import "../../stylesheets/styles.css";
import Calendar from "../../assets/fi-rr-calendar (2).png";
import TopHeader from "../../components/ui/TopHeader";
import Filter from "../../components/ui/filter";
import moment from "moment";
import TableField from "../../components/ui/tableField";

class Requests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      request: "",
      total: 0,
      loading: false,
      Tableloading: true,
      currentPage: 1,
      dateRange: null,
      from: "",
      to: "",
      queryStatus: {},
      totalDoc: 0,
      pageSize: 20,
    };
  }
  componentDidMount() {
    this.setQueryStatus();
    this.setState({ from: "", to: "" });
    this.reloadPage();
    window.addEventListener("popstate", this.onPopstate);
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.clearHandler();
        }
      });
    });
  };

  handleTable = () => {
    APIGATEWAY.get(
      "requests" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          let data = response.data.request;
          let total = response.data?.request?.length;


          this.setState({
            request: data,
            total: total,
            loading: false,
            Tableloading: false,
            totalDoc: response?.data?.total,
          });
        } else {
          this.setState({
            loading: true,
          });
        }
      }
    );
  };
  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          from: getIsoString(queryObj.from) ?? "",
          to: getIsoString(queryObj.to) ?? "",
          currentPage: parseInt(queryObj.page) ?? "",
          pageLimit: parseInt(queryObj.limit) ?? "",
          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    }
  };

  onPopstate = () => {
    let params = new URLSearchParams(window.location.search);

    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";
    this.state.from = searchObj.from ?? "";
    this.state.to = searchObj.to ?? "";

    this.state.queryStatus = returnSearchObject(search);

    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      this.handleTable();
    });
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
  }

  // componentDidUpdate(prevProps) {
  //   let params = new URLSearchParams(window.location.search);

  //   const search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     const searchObj = returnSearchObject(search);
  //     this.state.currentPage = parseInt(searchObj.page) ?? "";

  //     this.state.from = searchObj.from ?? "";
  //     this.state.to = searchObj.to ?? "";

  //     this.state.queryStatus = returnSearchObject(search);

  //     this.setState({ queryStatus: this.getQuerySearch() }, () => {
  //       this.handleTable();
  //     });
  //   }
  // }

  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";

    querysearch.from = getIsoString(this.state.from) ?? "";
    querysearch.to = getIsoString(this.state.to) ?? "";

    return querysearch;
  };
  handleFilter = () => {
    let query = this.getQuerySearch();

    if (this.state.from > this.state.to) {
      showError("Select the valid date");
    } else {
      this.setState(
        {
          queryStatus: query,
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    }
  };

  clearHandler = () => {
    this.setState(
      {
        from: "",
        to: "",
        dateRange: null,
        currentPage: 1,
        queryStatus: { page: 1, limit: 20 },
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.handleTable();
      }
    );
  };

  // applyHandler = () => {
  //   this.setState({ currentPage: 1 }, () => {
  //     this.handleFilter();
  //   });
  // };

  applyHandler = () => {
    const {from,to} =this.state
    if(from ===''&& to ==="" ){
      showError('Please select the date')
    }else{
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handleFilter();
        }
      );
    }
   
  };
  // onDateRangeChange = (dateRange) => {

  //   if (dateRange) {

  //     this.setState({
  //       dateRange: this.returnMomentDateRange(dateRange[0], dateRange[1]),

  //       from: moment(dateRange[0]),
  //       to: moment(dateRange[1]),

  //     })

  //   } else {
  //     this.setState({
  //       dateRange: null,
  //     })
  //   }
  // };
  // returnMomentDateRange = (start, finish) => {
  //   return [moment(start, "YYYY-MM-DD"), moment(finish, "YYYY-MM-DD")];
  // };

  previous = () => {
    this.setState(
      { currentPage: this.state.currentPage - 1, Tableloading: true },
      () => {
        this.handleFilter();
      }
    );
  };

  next = () => {
    this.setState(
      { currentPage: this.state.currentPage + 1, Tableloading: true },
      () => {
        this.handleFilter();
      }
    );
  };

  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  handleFromChange = (date, dateString) => {
    this.setState({ from: date });
  };

  handleToChange = (date, dateString) => {
    this.setState({ to: date });
  };

  render() {
    const {
      request,
      total,
      loading,
      currentPage,
      dateRange,
      from,
      to,
      pageSize,
    } = this.state;
    const columns = [
      {
        title: "S.No",
        dataIndex: "S.No",

        render: (key, item, index) => (
          <div key={index} style={{ paddingLeft: "10px" }}>
            {(currentPage - 1) * pageSize + (index + 1)}
          </div>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (key) => <div>{key}</div>,
      },
      { title: "Email", dataIndex: "email" },
      { title: "Subject", dataIndex: "subject" },
      { title: "Message", dataIndex: "message" },
      {
        title: "Received on",
        dataIndex: "createdAt",
        render: (createdAt) => showDate(createdAt),
      },
    ];
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }
    return (
      <>
        {loading ? (
          <div className="spinning-star">
            <Spin size="large" />
          </div>
        ) : (
          <div className="">
            <TopHeader title="Requests" />

            <div className="main-content">
              <Filter
                clear={this.clearHandler}
                apply={this.applyHandler}
                // onpickerChange={this.onDateRangeChange}
                // dateRange={dateRange}
                // startDate={from}
                // endDate={to}
                handleOnFromChange={this.handleFromChange}
                handleOnToChange={this.handleToChange}
                fromDate={this.state.from}
                toDate={this.state.to}
                fromName="from"
                toName="to"
              />

            
            {this.state.Tableloading ? (
                <div className="tableview">
                  <Spin />
                </div>
              ) : (
              <div className='table-main'>
                <div className="table_page_wrap">

                  {request.length > 0 ?
                    (<table className="table-response">
                      <thead>
                        <tr>
                          <th className=''>S.No</th>
                          <th className=''>Name</th>
                          <th className=''>Email</th>
                          <th className=''>Subject</th>
                          <th className=''>Message</th>

                          <th className=''>Received on</th>
                        </tr>
                      </thead>

                      {request.length > 0 ? (
                        <tbody>
                          {request.map((req, r) => (
                            <tr key={r}>
                              <td className=''>  {(currentPage - 1) * pageSize + (r + 1)}
                              </td>
                              <td className="table-name text-capitalize">{req.name}</td>
                              <td className=''>{req.email}</td>
                              <td className='text-capitalize'>{req.subject}</td>
                              <td className=''>{req.message}</td>
                              <td>{req.createdAt !== "" ? showDate(req.createdAt) : '-'}</td>

                            </tr>
                          ))}

                        </tbody>) : ''}
                    </table>) :
                    (<table className="table-response">
                      <thead>
                        <tr>
                          <th className=''>S.No</th>
                          <th className=''>Name</th>
                          <th className=''>Email</th>
                          <th className=''>Subject</th>
                          <th className=''>Message</th>

                          <th className=''>Received on</th>
                        </tr>
                      </thead>


                      <tbody className="empty-message">
                        <tr>
                          <td colspan="6">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </td>

                        </tr>
                      </tbody>



                    </table>)
                  }
                  {request.length > 0 && (
                    <TableField
                       data={request}
                      countprev={countprev}
                      countnext={countnext}
                      currentPage={currentPage}
                      ClickPrev={() => this.previous()}
                      ClickNext={() => this.next()}
                      total={total}
                      overAll={this.state.totalDoc ?? 0}
                      pagelimit={this.state.pageSize}
                      pagelimitHandle={this.pageLimitOnchange}
                    />
                  )}


                </div>


              </div>)}
            </div>
          </div>
        )}
      </>
    );
  }
}
export default Requests;
