import React, { Component } from "react";
import { Table, Input, Spin, Card, Button, Empty } from "antd";
import { Link } from "react-router-dom";
import { SearchOutlined, FormOutlined } from "@ant-design/icons";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import "../../stylesheets/styles.css";
import TopHeader from "../../components/ui/TopHeader";
import Filter from "../../components/ui/filter";
import { STORAGE, UPDATE } from "../../constants/roleStore";
import TableField from "../../components/ui/tableField";
import layer from "../../assets/Layer 2.svg";

import {
  showDate,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  isErrorTxt,
} from "../../constants/Utils";
import { CLIENTSACCESS } from "../../components/ui/constants";
class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
      loading: true,
      search: "",
      data: [],
      total: "",
      Tableloading: false,
      app_name: "",
      app_type: "",
      queryStatus: {},
      totalDoc: "",
      dropdown_menu: false,
      list_index: "",
      update: false,
    };
    this.wrapperRef = React.createRef();
  }
  componentDidMount() {
    let name = "";

    let storage = JSON.parse(STORAGE.view);

    name = storage?.filter((app) => app?.name === "My Business");
    let value = name;

    value?.filter((app) => {
      let names = app?.sub_roles?.filter((role) => role.name === "Clients");
      names &&
        names.map((el) =>
          this.setState({
            update: el?.permissions?.update,
          })
        );
      this.setState({
        app_name: names,
      });
    });
    this.setState({
      app_type: STORAGE.type,
    });

    this.setQueryStatus();
    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);

    this.reloadPage();
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.clearSearch();
        }
      });
    });
  };

  onPopstate = () => {
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);

    this.state.search = searchObj.search_term ?? "";
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";

    this.state.queryStatus = returnSearchObject(search);

    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      this.handleTable();
    });
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);
  }

  // componentDidUpdate(prevProps) {
  //   const search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     const searchObj = returnSearchObject(search);

  //     this.state.search = searchObj.search_term ?? "";
  //     this.state.currentPage = parseInt(searchObj.page) ?? "";

  //     this.state.queryStatus = returnSearchObject(search);

  //     this.setState({ queryStatus: this.getQuerySearch() }, () => {
  //       this.handleTable();
  //     });
  //   }
  // }

  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };
  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    querysearch.search_term = this.state.search ?? "";
    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";

    return querysearch;
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          search: queryObj.search_term ?? "",
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",

          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    }
  };

  handleFilter = () => {
    let query = this.getQuerySearch();

    this.setState(
      {
        queryStatus: query,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.handleTable();
      }
    );
  };
  handleTable = () => {
    APIGATEWAY.get(
      "clients/" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          let data = response.data.clients;
          let total = response.data?.clients?.length;
          this.setState({
            data: data,
            total,
            loading: false,
            Tableloading: false,
            totalDoc: response.data?.total,
          });
        } else {
          this.setState({ loading: true });
        }
      }
    );
  };

  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  next = (prev) => {
    const { currentPage } = this.state;

    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  handleDetailpage = (username) => {
    this.props.history.push("/clients/detail/" + username);
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.handleTable();
    });
  };
  handleSearchChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  handleEditPage = (username) => {
    this.props.history.push("/clients/edit/" + username);
  };
  clearSearch = () => {
    this.setState(
      {
        search: "",
        currentPage: 1,
        queryStatus: { page: 1, limit: 20 },
        pageSize: 20,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);

        this.handleTable();
      }
    );
  };

  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      list_index: index,
    });
  };
  render() {
    const {
      data,
      currentPage,
      total,
      search,
      app_name,
      app_type,
      pageSize,
      update,
    } = this.state;
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }

    return (
      <div>
        <div>
          <TopHeader
            title="Clients"
            to="/clients/add"
            type={app_type}
            permission={app_name}
          />
          <div className="main-content">
            <Filter
              clientFieldname="search"
              clientFieldvalue={search}
              clientOnchange={this.handleSearchChange}
              clientClear={() => this.clearSearch()}
            />
            {this.state.loading ? (
              <div className="spinning-star">
                <Spin size="large" />
              </div>
            ) : (
              <div className="table-main">
                <div className="table_page_wrap">
                  {data.length > 0 ? (
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Company</th>
                          <th>Email</th>
                          <th></th>
                        </tr>
                      </thead>

                      {data.length > 0 ? (
                        <tbody ref={this.wrapperRef}>
                          {data.map((item, r) => (
                            <tr key={r}>
                              <td>
                                {" "}
                                {(this.state.currentPage - 1) *
                                  this.state.pageSize +
                                  (r + 1)}
                              </td>
                              <td className="table-name text-capitalize">
                                {item?.name?.first_name +
                                  " " +
                                  item?.name?.last_name}
                              </td>

                              <td className="table-name">
                                {isErrorTxt(item?.company_name)
                                  ? "-"
                                  : item?.company_name}
                              </td>
                              <td className="table-name">{item.email}</td>
                              {/* {app_type === 'sub_admin' && update === false ? "": */}
                              {CLIENTSACCESS.update && (
                                <td
                                  className="table-action"
                                  onClick={() => this.dropdownClick(r)}
                                >
                                  <div className="tax-add-edit">
                                    <span className="ml-5">
                                      <div className="dropdown-head">
                                        <div className="dropbtn-head">
                                          <img
                                            src={layer}
                                            alt="profile"
                                            className="menu-bar-icon"
                                          />
                                        </div>
                                        {this.state.list_index === r &&
                                          this.state.dropdown_menu && (
                                            <div className="dropdown-content-heads">
                                              <div>
                                                <p
                                                  className="action-menu-bar"
                                                  onClick={() =>
                                                    this.handleEditPage(
                                                      item.client_id
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              )}

                              {/* // } */}
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        ""
                      )}
                    </table>
                  ) : (
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>

                          <th>Company</th>
                          <th>Email</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody className="empty-message">
                        <tr>
                          <td colspan="5">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {data.length > 0 && (
                    <TableField
                      data={data}
                      countprev={countprev}
                      countnext={countnext}
                      currentPage={currentPage}
                      ClickPrev={() => this.previous()}
                      ClickNext={() => this.next(this.state.currentPage)}
                      total={total}
                      overAll={this.state.totalDoc ?? 0}
                      pagelimit={this.state.pageSize}
                      pagelimitHandle={this.pageLimitOnchange}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Clients;
