import React from "react";
import moment from "moment";
import Calendar from "../../assets/fi-rr-calendar.svg";
import SelectIcon from "../../assets/Vector.svg";
import { Input, Select, DatePicker, Col, Row } from "antd";
import { isErrorTxt } from "../../constants/Utils";
const { Option } = Select;
const { RangePicker } = DatePicker;
const Filter = (props) => {
  const {
    project_FieldName,
    project_FieldValue,
    project_onChange,
    clientClear,
    statusSelect,
    statusName,
    searchInput,
    searchInputName,
    fromDate,
    toDate,
    fromName,
    toName,
    handleOnToChange,
    handleOnFromChange,
    searchData,
    statusData,

    occurenceData,
    occurencePlaceHolder,
    occurenceName,
    onOccurenceChange,
    occurenceSelect,

    statusOnChange,

    searchOption,
    style,
    typeData,
    typeName,
    typeSelect,
    typeClick,
    startDate,
    endDate,
    onpickerChange,
    optionChange,
    inputChange,
    clear,
    apply,
    searchname,
    inputFieldname,
    inputFieldvalue,
    inputOnchange,
    clientFieldname,
    clientFieldvalue,
    clientOnchange,
    dateRange,
    holiday,
    taxOnchange,
    taxClear,
    taxFieldname,
    taxFieldvalue,
    img_name,
    primaryButton,
    primaryClick,
  } = props;
  return (
    <div
    className="filter-row-col"
    >
      <div className="qk-admin-filter-field">
        {isErrorTxt(typeData) ? (
          ""
        ) : (
          <div className="filters">
            <Select
              name={typeName}
              value={typeSelect}
              onChange={typeClick}
              className="select-dropdown-menu"
              placeholder="Select By"
              getPopupContainer={(trigger) => trigger.parentNode}
              autoComplete="off"
              suffixIcon={
                <div>
                  <img src={SelectIcon} alt="" width="10px" />
                </div>
              }
            >
              <Option value="">All</Option>
              {typeData &&
                typeData.map((type, s) => (
                  <Option key={s} value={type.key}>
                    {type.value}
                  </Option>
                ))}
            </Select>
          </div>
        )}

        {isErrorTxt(occurenceData) ? (
          ""
        ) : (
          <div className="filters">
            <Select
              name={occurenceName}
              value={occurenceSelect || undefined}
              onChange={onOccurenceChange}
              getPopupContainer={(trigger) => trigger.parentNode}
              className="select-dropdown-menu"
              autoComplete="off"
              suffixIcon={
                <div>
                  <img src={SelectIcon} alt="" width="10px" />
                </div>
              }
            >
              {/* <Option value="">All</Option> */}
              {occurenceData &&
                occurenceData.map((status, index) => (
                  <Option key={index} value={status.value}>
                    {status.type}
                  </Option>
                ))}
            </Select>
          </div>
        )}

        {isErrorTxt(statusData) ? (
          ""
        ) : (
          <div className="filters">
            <Select
              name={statusName}
              value={statusSelect || undefined}
              onChange={statusOnChange}
              getPopupContainer={(trigger) => trigger.parentNode}
              className="select-dropdown-menu"
              placeholder="Select Status"
              autoComplete="off"
              suffixIcon={
                <div>
                  <img src={SelectIcon} alt="" width="10px" />
                </div>
              }
            >
              <Option value="">All</Option>
              {statusData &&
                statusData.map((status, index) => (
                  <Option key={index} value={status.key}>
                    {status.value}
                  </Option>
                ))}
            </Select>
          </div>
        )}

        {isErrorTxt(searchData) ? (
          ""
        ) : (
          <div className="filters">
            <Input.Group compact style={{ width: "350px", display: "flex" }}>
              <Select
                name={searchname}
                placeholder="Select By"
                onChange={optionChange}
                value={searchOption || undefined}
                getPopupContainer={(trigger) => trigger.parentNode}
                suffixIcon={
                  <div>
                    <img src={SelectIcon} alt="" width="10px" />
                  </div>
                }
                className="select-dropdown-menuss"
              >
                {searchData &&
                  searchData.map((status, index) => (
                    <Option key={index} value={status.key}>
                      {status.value}
                    </Option>
                  ))}
              </Select>
              <Input
                className="select-dropdown-menu-input"
                placeholder="Search..."
                name={searchInputName}
                value={searchInput}
                onChange={inputChange}
              />
            </Input.Group>
          </div>
        )}

        {isErrorTxt(onpickerChange) ? (
          ""
        ) : (
          <div className="filters">
            <RangePicker
              allowClear={true}
              size="small"
              picker="date"
              value={dateRange !== "" ? dateRange : null}
              onChange={onpickerChange}
              inputReadOnly
              className="rangepicker"
              defaultValue={[startDate, endDate]}
              placeholder={["From", "To"]}
              suffixIcon={
                <div>
                  <img src={Calendar} alt="date" />
                </div>
              }
            />
          </div>
        )}
        {}

        {isErrorTxt(handleOnFromChange) ? (
          ""
        ) : (
          <div className="date-filter">
            <div className="date-field">Date</div>
            <div className="filters">
              <DatePicker
                selected={fromDate}
                format={"YYYY-MM-DD"}
                placeholder=""
                name={fromName}
                id="from"
                value={fromDate !== "" ? moment(fromDate) : ""}
                className="rangepicker"
                suffixIcon={false}
                getPopupContainer={(trigger) => trigger.parentNode}
                inputReadOnly
                showYearDropdown
                showMonthDropdown
                dropdownAlign={{ offset: [-40, 4] }}
                onChange={handleOnFromChange}
                allowClear={false}
              />
            </div>
            <div className="filter-date-to">to</div>

            <div className="filters">
              <DatePicker
                selected={toDate}
                format={"YYYY-MM-DD"}
                placeholder=""
                name={toName}
                id="to"
                value={toDate !== "" ? moment(toDate) : ""}
                className="rangepicker"
                dropdownAlign={{ offset: [-40, 4] }}
                suffixIcon={false}
                getPopupContainer={(trigger) => trigger.parentNode}
                allowClear={false}
                inputReadOnly
                showYearDropdown
                showMonthDropdown
                ariaDescribedBy="basic-addon2"
                onChange={handleOnToChange}
              />
            </div>
          </div>
        )}

        {isErrorTxt(inputOnchange) ? (
          ""
        ) : (
          <div>
            <Input
              className="input-menu-field"
              placeholder="Emp Id"
              name={inputFieldname}
              value={inputFieldvalue}
              onChange={inputOnchange}
            />
          </div>
        )}

        {isErrorTxt(clientOnchange) ? (
          ""
        ) : (
          <div>
            <Input
              className="input-menu-field"
              placeholder="Search..."
              name={clientFieldname}
              value={clientFieldvalue}
              onChange={clientOnchange}
            />
          </div>
        )}

        {isErrorTxt(project_onChange) ? (
          ""
        ) : (
          <div>
            <Input
              className="input-container"
              placeholder="Search Customer"
              name={project_FieldName}
              value={project_FieldValue}
              onChange={project_onChange}
            />
          </div>
        )}

        {isErrorTxt(taxOnchange) ? (
          ""
        ) : (
          <div className="input-field" style={{ marginBottom: -8 }}>
            <img src={img_name} alt="search" className="" />
            <input
              type="text"
              className="tax-input"
              placeholder="Search Tax"
              name={taxFieldname}
              value={taxFieldvalue}
              onChange={taxOnchange}
            />
          </div>
        )}
      </div>
      <div className="ad-filter-right">
        {isErrorTxt(clear) ? (
          ""
        ) : (
          <button
            className={holiday ? "primary_color" : "secondary_color"}
            onClick={clear}
          >
            Clear
          </button>
        )}
        {!holiday && apply !== undefined && apply !== null && apply !== "" && (
          <button className="primary_color" onClick={apply}>
            Apply
          </button>
        )}
        {isErrorTxt(clientClear) ? (
          ""
        ) : (
          <button className="primary_color" onClick={clientClear}>
            Clear
          </button>
        )}
        {isErrorTxt(taxClear) ? (
          ""
        ) : (
          <button className="primary_color" onClick={taxClear}>
            Clear
          </button>
        )}
        {isErrorTxt(primaryButton) ? '' : <button className='primary_color lh_28px' onClick={primaryClick}>{primaryButton}</button>}
      </div>
    </div>
  );
};

export default Filter;
