import React, { Component } from "react";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { DownOutlined } from "@ant-design/icons";
import { DatePicker, Space, Select, Input, notification, Button } from "antd";
import moment from "moment";
import { showSuccess } from "../../constants/Utils";
const { Option } = Select;
const { TextArea } = Input;

class CreateTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task_name: "",
      notes: "",
      due_date: "",
      due_date_selected: "",
      recurrence: "",
      remaindOn: "",
      remainder_date: "",
      assignFor: "",
      employeeName: "",
      employeeId: "",
      pickDate: false,
      emp_data: [],
      tommorrow: moment().add(1, "day").format("ddd"),
      today: moment().format("ddd"),
      edit: false,
      taskDetails: [],
      taskId: "",
      status: "",
      to: "",
    };
  }

  componentDidMount() {
    // APIGATEWAY.get('employees/?page=1&limit=9999', (response) => {
    //     let names = response.data.employees;
    //     this.state.emp_data = names.filter((name) => name.status === 'active');
    //     if (this.props.edit) {
    //         this.setState(
    //             {
    //                 edit: this.props.edit,
    //                 taskDetails: this.props.taskDetails
    //             },
    //             () => {
    //                 this.editTask();
    //             }
    //         );
    //     }
    // });
    APIGATEWAY.get("employees/all", (response) => {
      let names = response.data.employees;

      this.state.emp_data = names;
      if (this.props.edit) {
        this.setState(
          {
            edit: this.props.edit,
            taskDetails: this.props.taskDetails,
          },
          () => {
            this.editTask();
          }
        );
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.taskDetails !== prevProps.taskDetails ||
      this.props.edit !== prevProps.edit
    ) {
      this.setState(
        {
          edit: this.props.edit,
          taskDetails: this.props.taskDetails,
        },
        () => {
          this.editTask();
        }
      );
    }
  }

  editTask() {
    let { taskDetails } = this.state;
    if (this.props.edit) {
      this.setState({
        task_name: taskDetails.name,
        notes: taskDetails.notes,
        due_date_selected: taskDetails.due_date
          ? moment(taskDetails.due_date)
          : "",
        recurrence: taskDetails.recurrence,
        assignFor: taskDetails.to === "employer" ? "Myself" : "Employee",
        to: taskDetails.to,
        employeeName:
          taskDetails.to === "employer" ? "" : taskDetails.employee?.name,
        taskId: taskDetails.task_id,
        status: taskDetails.status,
        employeeId:
          taskDetails.to === "employer" ? "" : taskDetails.employee?.id,
      });
    } else {
      this.setState({
        task_name: "",
        notes: "",
        reccurrence: "",
        due_date_selected: "",
        assignFor: "",
        employeeName: "",
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  dueDate = (date, dateString) => {
    this.setState({
      due_date_selected: date,
    });
  };

  recurrenceChange = (value) => {
    this.setState({ recurrence: value });
  };

  remainder = (value) => {
    if (value === "pick") {
      this.setState({ pickDate: true });
    } else {
      this.setState({ pickDate: false });
    }
    this.setState({ remindOn: value });
  };

  remainderDate = (date) => {
    this.setState({ remainder_date: date.format("MMMM DD,YYYY") });
  };

  onAssign = (value) => {
    if (value === "Employee") {
      this.setState({ assignFor: value, to: "employee" });
    } else {
      this.setState({ assignFor: value, to: "employer" });
    }
  };

  onSelectPerson = (index) => {
    let { emp_data } = this.state;
    this.setState({
      employeeName: emp_data[index].name,
      employeeId: emp_data[index].emp_id,
    });
  };

  saveTask = () => {
    let {
      task_name,
      notes,
      recurrence,
      remindOn,
      remainder_date,
      due_date_selected,
      assignFor,
      employeeName,
      employeeId,
      emp_data,
      taskId,
      to,
    } = this.state;
    if (task_name === "") {
      this.showError("Please enter task name");
    } else if (task_name.length < 10) {
      this.showError("Task name should contain at least 10 characters");
    } else if (assignFor === "") {
      this.showError("Please select assign to");
    } else if (assignFor === "others" && employeeName === "") {
      this.showError("Please select employee");
    } else {
      const data = {
        emp_id: employeeId,
        name: task_name,
        recurrence: recurrence,
        notes: notes,
        to: to,
        due_date: due_date_selected,
      };

      if (!this.state.edit) {
        APIGATEWAY.post("tasks", data, (response) => {
          this.setState({
            task_name: "",
            notes: "",
            assignFor: "",
          });
          this.showSuccess(response.message);
          this.props.save();
          window.location.reload();
        });
      } else {
        let id = taskId;
        APIGATEWAY.patch(`tasks/${id}`, data, (response) => {
          this.showSuccess(response.message);
          this.props.cancel();
          window.location.reload();
        });
      }
    }
  };

  showError = (error) => {
    notification.error({
      message: error,
      placement: "bottomLeft",
    });
  };
  showSuccess = (message) => {
    notification.success({
      message: message,
      placement: "bottomLeft",
    });
  };

  cancel = () => {
    this.props.cancel();
  };

  complete = () => {
    let {
      task_name,
      employeeId,
      recurrence,
      notes,
      employeeName,
      due_date_selected,
      taskId,
    } = this.state;

    const data = {
      emp_id: employeeId,
      name: task_name,
      recurrence: recurrence,
      notes: notes,
      to: employeeName,
      due_date: due_date_selected,
    };
    APIGATEWAY.patch(`tasks/complete/${taskId}`, data, (response) => {
      this.showSuccess(response.message);
      this.props.cancel();
      window.location.reload();
    });
  };

  render() {
    let {
      task_details,
      task_name,
      notes,
      due_selected,
      recurrence,
      remindOn,
      pickDate,
      assignFor,
      employeeName,
      due_date_selected,
      emp_data,
      edit,
      status,
    } = this.state;
    // const today = moment().format('ddd');
    // const tommorrow =moment().add(1, 'day').format('ddd');

    return (
      <div className="task_container">
        <div>
          {status === "created" ? (
            <div className="complete" onClick={() => this.complete()}>
              Mark as Complete
            </div>
          ) : null}
          <div>
            <label className="label_for_input_fields">Task Name *</label>
            <input
              type="text"
              className="task_name_input"
              id="taskName"
              name="task_name"
              value={task_name}
              onChange={this.onChange}
            />
          </div>
          <div>
            <label className="label_for_input_fields mb-30">Notes</label>
            <input
              type="textarea"
              rows={4}
              className="task_name_input"
              name="notes"
              value={notes}
              onChange={this.onChange}
            />
          </div>
          <div className="due_date ">
            <Space
              direction="vertical"
              className={
                due_date_selected ? "wid-50 mr-10 gap0" : "width100 gap0 "
              }
            >
              <div>Due Date</div>
              <DatePicker
                className="width100 mt-10 date_input "
                onChange={(value, e) => this.dueDate(value, e)}
                name="due_date"
                value={due_date_selected}
                disabledDate={(current) =>
                  current.isBefore(moment().add(-1, "day"))
                }
                getPopupContainer={trigger => trigger.parentNode}
              />
            </Space>
            {due_date_selected ? (
              <div className="wid-50">
                <div>Set Recurrence</div>
                <Select
                  placeholder="Set Recurrence"
                  className="width100 mt-10 "
                  onChange={this.recurrenceChange}
                  name="recurrence"
                  value={recurrence}
                >
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Yearly">Yearly</option>
                </Select>
              </div>
            ) : null}
          </div>
          <div className="remind_Me">
            <div className={pickDate ? "wid-50 mr-10" : "width100"}>
              <div>Remind me</div>
              <Select
                placeholder="Remind Me"
                onChange={this.remainder}
                allowClear
                className="width100 mt-10 "
                name="remindOn"
                value={remindOn}
                getPopupContainer={trigger => trigger.parentNode}

              >
                <Option value="today">
                  Later Today {this.state.today} 7:00 PM
                </Option>
                <Option value="tommorrow">
                  Tomorrow {this.state.tommorrow} 9:00 AM
                </Option>
                <Option value="nextWeek">Next Week Mon 9:00 AM</Option>
                <Option value="pick">Pick a Date and Time</Option>
              </Select>
            </div>

            {pickDate ? (
              <div className="wid-50">
                <div>Select Date</div>

                <Space
                  direction="vertical"
                  className="width100 mt-10 drop_down"
                >
                  <DatePicker
                    className="width100 date_input"
                    name="remainder_date"
                    onChange={this.remainderDate}
                    disabledDate={(current) =>
                      current.isBefore(moment().subtract(1, "day"))
                    }
                    getPopupContainer={trigger => trigger.parentNode}
                  />
                </Space>
              </div>
            ) : null}
          </div>
          <div className="remind_Me">
            <div
              className={assignFor === "Employee" ? "wid-50 mr-10" : "width100"}
            >
              <div>Assign To *</div>
              <Select
                placeholder="Assign To"
                className="width100 mt-10 "
                onChange={this.onAssign}
                name="assignFor"
                getPopupContainer={trigger => trigger.parentNode}

                value={assignFor}
              >
                <option value="Myself">Myself</option>
                <option value="Employee">Employee</option>
              </Select>
            </div>
            {assignFor === "Employee" ? (
              <div className="wid-50">
                <div>Select Employee *</div>
                <Select
                  showSearch
                  placeholder="Employee"
                  optionFilterProp="children"
                  className="width100 mt-10 "
                  name="employeeName"
                  value={employeeName}
                getPopupContainer={trigger => trigger.parentNode}

                  onChange={this.onSelectPerson}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {emp_data.map((emp, i) => (
                    <Option value={i} key={i}>
                      {emp.name}
                    </Option>
                  ))}
                </Select>
              </div>
            ) : null}
          </div>
        </div>
        {status === "completed" || status === "deleted" ? null : (
          <div className="save_buttons">
            <div className="cancel" onClick={() => this.cancel()}>
              Cancel
            </div>
            <Space>
              <div className="save" onClick={() => this.saveTask()}>
                Save
              </div>
            </Space>
          </div>
        )}
      </div>
    );
  }
}

export default CreateTask;
