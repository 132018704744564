import React, { Component } from "react";
import "../../stylesheets/styles.css";
import { Input, Spin } from "antd";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import tick from "../../assets/tick.svg";
import { Checkbox } from "antd";
import "./role_manage.css";
import { message } from "antd";
import "antd/dist/antd.css";
import TopHeader from "../../components/ui/TopHeader";
import InputItem from "../../components/ui/InputItem";

import { RoleData } from "./RoleData";
import { showError } from "../../constants/Utils";
import { SidebarData } from "../../layouts/routes";

export default class RoleManageCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Holidaylist: [],
      search: "",
      currentPage: 1,
      total: "",
      pageSize: 10,
      loading: true,
      modalOpen: false,
      editmodal: false,
      ename: "",
      edate: "",
      edays: "",
      name: "",
      apps: "",
      holiday_id: "",
      modal1Visible: false,
      entire_app: [],
      file: "",
      count: 1,
      apps_list: [],
      role_data: "",
      id: this.props.match.params.id,
      sub_nav: false,
      app: {
        Timeoff: false,
        Employees: false,
        Hiring: false,
        Dashboard: false,
        Business: false,
        MyPayments: false,
        Settings: false,
      },
      role: {},
      apps_obj: {},
      Tableloading: true,
      add_edit_loader: false,
    };
  }

  componentDidMount() {
    // this.setupApps();
    let role = [];

    let appdata = SidebarData.map((app) => {
      app.isActive = false;
      role.push(app);
    });
    this.setState({ apps_obj: this.state.app, apps: role });
    if (this.state.id) {
      this.editRolemanage();
    }
  }

  editRolemanage = () => {
    APIGATEWAY.get("/rolemanagement/" + this.state.id, (response) => {
      if (response.success) {
        let data = response?.data?.roles;

        let editRole = [];
        let v1 = [];
        let result = this.state.apps;

        var arr3 = result.map(({ ...o }) => {
          let value = data.apps.find((x) => x.name === o.name);

          if (value) {
            o.subRole = true;
            o.isActive = true;
            o.permissions = value.permissions;
            let active = [];
            o?.sub_roles?.map(({ ...sub }) => {
              let child = value?.sub_roles?.find((x) => x.name === sub.name);
              if (child && child) {
                active.push(child);
              } else {
                active.push(sub);
              }
            });

            o.sub_roles && (o.sub_roles = active);
            !o.sub_roles && delete o.subRole;
            return v1.push(o);
          } else {
            o.isActive = false;
            v1.push(o);
          }
        });

        this.setState({
          name: response?.data?.roles?.name,
          apps: v1,
          Tableloading: false,
        });
      }
    });
  };

  handleChange = (e) => {
    this.setState({ name: e.target.value });
  };

  changeValue = (e, index, permissions, item) => {
    let apps = this.state.apps;
    apps[index].permissions[permissions] = e.target.checked;
    apps[index]?.sub_roles?.forEach((nav) => {
      if (nav.permissions) {
        nav.permissions[permissions] = e.target.checked;
      }
    });
    this.setState({ apps: apps });
  };

  changeSubValue = (e, i, n, permissions, nav) => {
    const apps = this.state.apps;
    const result = apps[i].sub_roles[n];
    result.permissions[permissions] = e.target.checked;
    const templength = apps[i].sub_roles?.length;
    let tempvalue = 0;
    apps[i].sub_roles?.forEach((nav) => {
      if (nav.permissions) {
        if (nav.permissions[permissions]) tempvalue++;
      } else tempvalue--;
      apps[i].permissions[permissions] =
        tempvalue === templength ? true : false;
    });

    this.setState({ apps: apps });
  };

  handleSubmit = (e) => {
    let { name, entire_app, apps } = this.state;

    const activeModule = [];
    apps?.map((module) => {
      if (module.sub_roles) {
        const active = [];
        module.sub_roles.map((rol) => {
          if (
            rol.permissions &&
            Object.values(rol.permissions).includes(true)
          ) {
            active.push({
              name: rol.name,
              permissions: rol.permissions,
            });
          }
        });
        if (active.length) {
          activeModule.push({
            name: module.name,
            permissions: module.permissions,
            sub_roles: active,
          });
        }
      } else if (Object.values(module.permissions).includes(true)) {
        activeModule.push({
          name: module.name,
          permissions: module.permissions,
        });
        module?.sub_roles?.length < 0 && delete activeModule["sub_roles"];
      }
    });

    if (name === "") {
      return showError("Please enter role name");
    } else if (activeModule.length <= 0) {
      return message.error("Please provide apps list");
    } else {
      let data = {
        name: this.state.name,
        apps: activeModule,
      };

      this.setState({ add_edit_loader: true });
      if (this.state.id) {
        APIGATEWAY.patch(
          "rolemanagement/update/" + this.state.id,
          data,
          (response) => {
            if (response.success) {
              this.setState({ add_edit_loader: false });
              message.success(response.message);
              this.props.history.goBack();

              this.setState({ isLoading: false });
            } else {
              this.setState({ add_edit_loader: false });
              message.error(response.message);
              this.setState({ isLoading: false });
            }
          }
        );
      } else {
        APIGATEWAY.post("rolemanagement/create", data, (response) => {
          if (response.success) {
            message.success(response.message);
            this.props.history.goBack();

            this.setState({ isLoading: false });
          } else {
            message.error(response.message);
            this.setState({ isLoading: false });
          }
        });
      }
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  roleSelect = (role, r) => {
    const apps = this.state.apps;

    apps[r].isActive = !apps[r].isActive;
    const parent = apps[r].permissions;
    for (const key in parent) {
      parent[key] = false;
    }

    if (apps[r].sub_roles) {
      apps[r].sub_roles.forEach((nav) => {
        const tempnav = nav.permissions;
        for (const key in tempnav) {
          tempnav[key] = false;
        }
      });
    }

    this.setState({ apps: apps });
  };

  subnavClick = (item, i) => {
    const apps = this.state.apps;
    apps[i].subRole = !apps[i].subRole;

    this.setState({ apps: apps });
  };
  render() {
    let { apps, name, sub_nav } = this.state;

    return (
      <div>
        <TopHeader
          title="Role Management"
          backClick={this.goBack}
          submit={(e) => this.handleSubmit(e)}
          id={this.state.id}
          spin={this.state.add_edit_loader}
        />

        <div className="main-content2">
          {this.state.id && this.state.Tableloading ? (
            <div className="tableview">
              <Spin />
            </div>
          ) : (
            <>
              <div>
                <div className="manage-role-name-input">
                  <InputItem
                    label="Role Name"
                    star={false}
                    name="price"
                    value={name}
                    placeholder="Role name"
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
              </div>

              <div style={{ marginTop: "30px" }}>
                <p>Apps</p>
                <div
                  style={{ width: "70%", borderRadius: "5px" }}
                  className="border"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "18px",
                      padding: "1rem",

                      flexFlow: "wrap",
                    }}
                  >
                    {apps &&
                      apps?.map((role, r) => {
                        return (
                          <div
                            key={r}
                            className="border-check"
                            style={{
                              backgroundColor: role.isActive ? "#F2FCF5" : "",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              gap: "5%",
                              justifyContent: "space-evenly",
                              width: role.isActive ? "158px" : "",
                            }}
                            onClick={() => this.roleSelect(role, r)}
                          >
                            {role.isActive ? <img src={tick} alt="tick" /> : ""}
                            <span>{role.name}</span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div style={{ width: "99%", marginTop: "30px" }}>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "rgb(33,37,41)",
                    marginBottom: "8px",
                  }}
                >
                  Permissions
                </p>

                <div>
                  <table className="role-table">
                    <thead>
                      <tr className="role-header">
                        <th>APPS</th>
                        <th>CREATE</th>
                        <th>APPROVAL</th>
                        <th>UPDATE</th>
                        <th>DELETE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {apps &&
                        apps.length > 0 &&
                        apps.map((app, i) => {
                          return (
                            app.isActive && (
                              <>
                                <tr>
                                  <td className="role-text">
                                    {app.name}{" "}
                                    <i
                                      onClick={
                                        app.sub_roles
                                          ? () => this.subnavClick(app, i)
                                          : ""
                                      }
                                      className={
                                        app?.sub_roles?.length > 0
                                          ? app?.subRole
                                            ? "fa fa-angle-up role-icon"
                                            : "fa fa-angle-down role-icon"
                                          : null
                                      }
                                    >
                                      {" "}
                                    </i>{" "}
                                  </td>
                                  <td className="role-td">
                                    <Checkbox
                                      checked={app?.permissions?.create}
                                      onChange={(e) =>
                                        this.changeValue(e, i, "create", app)
                                      }
                                    />
                                  </td>
                                  <td className="role-td">
                                    <Checkbox
                                      checked={app?.permissions?.approval}
                                      onChange={(e) =>
                                        this.changeValue(e, i, "approval", app)
                                      }
                                    />
                                  </td>
                                  <td className="role-td">
                                    <Checkbox
                                      checked={app?.permissions?.update}
                                      onChange={(e) =>
                                        this.changeValue(e, i, "update", app)
                                      }
                                    />
                                  </td>
                                  <td className="role-td">
                                    <Checkbox
                                      checked={app?.permissions?.delete}
                                      onChange={(e) =>
                                        this.changeValue(e, i, "delete", app)
                                      }
                                    />
                                  </td>
                                </tr>

                                {app.subRole &&
                                  app.sub_roles &&
                                  app.sub_roles.length > 0 &&
                                  app?.sub_roles?.map((nav, n) => {
                                    return (
                                      nav?.permissions && (
                                        <>
                                          <tr>
                                            <td className="sub-role-name">
                                              <span>{nav.name}</span>
                                            </td>
                                            <td className="role-td">
                                              <Checkbox
                                                checked={
                                                  nav?.permissions?.create
                                                }
                                                onChange={(e) =>
                                                  this.changeSubValue(
                                                    e,
                                                    i,
                                                    n,
                                                    "create",
                                                    nav
                                                  )
                                                }
                                              />
                                            </td>
                                            <td className="role-td">
                                              <Checkbox
                                                checked={
                                                  nav?.permissions?.approval
                                                }
                                                onChange={(e) =>
                                                  this.changeSubValue(
                                                    e,
                                                    i,
                                                    n,
                                                    "approval",
                                                    nav
                                                  )
                                                }
                                              />
                                            </td>
                                            <td className="role-td">
                                              <Checkbox
                                                checked={
                                                  nav?.permissions?.update
                                                }
                                                onChange={(e) =>
                                                  this.changeSubValue(
                                                    e,
                                                    i,
                                                    n,
                                                    "update",
                                                    nav
                                                  )
                                                }
                                              />
                                            </td>
                                            <td className="role-td">
                                              <Checkbox
                                                checked={
                                                  nav?.permissions?.delete
                                                }
                                                onChange={(e) =>
                                                  this.changeSubValue(
                                                    e,
                                                    i,
                                                    n,
                                                    "delete",
                                                    nav
                                                  )
                                                }
                                              />
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    );
                                  })}
                              </>
                            )
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
