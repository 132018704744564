import React, { Component } from "react";
import { Typography, DatePicker } from "antd";


class Datepicker extends Component {
  render() {
    const {
      labelClass,
      label,
      name,
      value,
      placeholder,
      handleChange,
      className,
      style,

    } = this.props;
    return (
      <div className=" flex-col" style={style ? style : {}}>
        <div>{label}</div>
        <Typography.Text className={`three-dots ${labelClass}`}>{label}</Typography.Text>
        <DatePicker
          className={className}
          value={value}
          placeholder={placeholder}
          name={name}
          inputReadOnly
          allowClear={false}
          getPopupContainer={trigger => trigger.parentElement}
          style={{ cursor: 'pointer' }}
          onChange={(date, dateString) => handleChange(date, dateString, name)}
        />
      </div>
    );
  }
}

export default Datepicker;
