import React, { useState, useEffect } from "react";
import "../../stylesheets/styles.css";
import { Button, Input, message, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import "../../stylesheets/styles.css";
import {
  showError,
  checkNameLength,
  isErrorTxt,
  checkDigitLength,
  checkTxtLength,
} from "../../constants/Utils";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import TopHeader from "../../components/ui/TopHeader";
import InputItem from '../../components/ui/InputItem';

const Addjobs = (props) => {
  let id = props.match.params.id;

  const [jobid, setJobid] = useState(props.match.params.id);
  const [jobtitle, setJobTitle] = useState("");
  const [minexper, setMinExper] = useState("");
  const [maxexper, setMaxExper] = useState("");
  const [minsalary, setMinSalary] = useState("");
  const [maxsalary, setMaxSalary] = useState("");
  const [opening, setOpening] = useState("");
  const [description, setDescription] = useState("");
  const [skills, setSkills] = useState("");
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(true);
  const [aeLoader, setaeLoader] = useState(false);

  const backtojobs = () => {
    props.history.push("/jobs");
  };

  const titleHandler = (e) => {
    setJobTitle(e.target.value);
  };

  const minexperHandler = (e) => {
    setMinExper(e.target.value);
  };

  const maxexperHandler = (e) => {
    setMaxExper(e.target.value);
  };

  const minsalaryHandler = (e) => {
    setMinSalary(e.target.value);
  };

  const maxsalaryHandler = (e) => {
    setMaxSalary(e.target.value);
  };

  const openingHandler = (e) => {
    setOpening(e.target.value);
  };

  const descriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  const skillsHandler = (e) => {
    setSkills(e.target.value);
  };

  const locationHandler = (e) => {
    setLocation(e.target.value);
  };

  useEffect(() => {
    EditJob();
  }, []);

  const EditJob = () => {
    APIGATEWAY.get("jobs/" + jobid, (response) => {
      setLoading(false);
      if (response.success) {
        let data = response.data.job;
        setJobTitle(data?.title);
        setMinExper(data?.experience?.minimum);
        setMaxExper(data?.experience?.maximum);
        setOpening(data?.no_opening);
        setDescription(data?.description);
        setSkills(data?.skills);
        setLocation(data?.location);
      }
    });
  };

  const onupdate = () => {
    if (isErrorTxt(jobtitle)) {
      showError("Please enter the title");
    } else if (isErrorTxt(minexper)) {
      showError("Please enter the minimum experience");
    } else if (isErrorTxt(maxexper)) {
      showError("Please enter the maximum experience");
    } else if (isErrorTxt(opening)) {
      showError("Please enter the opening");
    } else if (isErrorTxt(skills)) {
      showError("Please enter the skills");
    } else {
      setaeLoader(true);
      let data = {
        title: jobtitle,
        experience: { maximum: maxexper, minimum: minexper },
        salary: { maximum: maxsalary, minimum: minsalary },
        no_opening: opening,
        skills: skills,
        location: location,
      };

      if (jobid) {
        APIGATEWAY.patch("jobs/" + jobid, data, (response) => {
          if (response.success) {
            message.success("Added successfully");
            setaeLoader(false);
            props.history.push("/jobs");
          } else {
            message.error(response.message);
          }
        });
      } else {
        APIGATEWAY.post("jobs/create", data, (response) => {
          if (response.success) {
            setaeLoader(false);
            message.success("Added successfully");
            props.history.push("/jobs");
          } else {
            message.error(response.message);
          }
        });
      }
    }
  };

  return (
    <div>
      <TopHeader
        title="Jobs"
        submit={onupdate}
        id={jobid}
        backClick={backtojobs}
        spin={aeLoader}
      />
      {jobid && loading ? (
        <div className="spinning-star">
          <Spin size="large" />
        </div>
      ) : (
        <div className="main-content2">
          <div className="job_li">
            <div className="job_per">
              <InputItem label='Tittle' name='title' value={jobtitle} star={false} placeholder="Title" onChange={titleHandler} />
            </div>
            <div className="job_per modal_form_fields">
              <div>
              <InputItem label='Experience' name='exp_min' value={minexper} star={false} placeholder="Min experience" onChange={minexperHandler} />
              </div>
              <div>
              <InputItem label='' name='exp_max' value={maxexper} star={false} placeholder="Max experience" onChange={maxexperHandler} />
              </div>
            </div>
          </div>
          <div className="job_li">

            <div className="job_per">
              <InputItem label='No Of Openings' name='opening' value={opening} star={false} placeholder="Opening" onChange={openingHandler} />

            </div>
          </div>
          <div className="job_li">
            <div className="job_per">
              <InputItem label='Description' name='description' value={description} star={false} placeholder="Description" txtarea={true} onChange={descriptionHandler} />

            </div>
            <div className="job_per">
              <InputItem label='Skills' name='skills' value={skills} star={false} placeholder="Skills" txtarea={true} onChange={skillsHandler} />

            </div>
          </div>

        </div>)}
    </div>
  );
};

export default Addjobs;
