import React from "react";
import { Link, Outlet } from "react-router-dom";
import { CREATE, APPROVAL } from "../../constants/roleStore";
import { Spinner } from "reactstrap";

const TopHeader = ({
  classname,
  title,
  backClick,
  to,
  status,
  updateStatus,
  id,
  submit,
  imports,
  exports,
  statusName,
  backClick1,
  modals,
  vendors,
  check,
  type,
  permission,
  assing,
  reload,
  tax,
  spin,
  primary,
  name,
  createLeave,
}) => {
  return (
    <div className={`top-header ${classname}`}>
      {title === "projects" || title === "meeting" ? (
        <div></div>
      ) : (
        <div className="dec-title">
          <span>{title}</span>
        </div>
      )}

      <div className="d-flex justify-content-between align-items-center">
        {name === "leave" &&
          CREATE(
            type,
            permission,
            <button className="dec-btn" onClick={createLeave}>
              Add Leave
            </button>
          )}

        {imports !== undefined && imports !== null && imports !== "" && (
          <button className="ad-btn-secondary" onClick={imports}>
            Import
          </button>
        )}
        {modals !== undefined && modals !== null && modals !== "" && (
          <>
            {type === "" && (
              <button className="dec-btn" onClick={modals}>
                Add
              </button>
            )}
            {CREATE(
              type,
              permission,
              <button className="dec-btn" onClick={modals}>
                Add
              </button>
            )}
          </>
        )}

        {exports !== undefined && exports !== null && exports !== "" && (
          <button className="dec-btn" onClick={exports}>
            Export
          </button>
        )}

        {backClick1 !== "" &&
          backClick1 !== undefined &&
          backClick1 !== null && (
            <button
              className={"ad-btn-secondary"}
              type="primary"
              onClick={backClick1}
            >
              Back
            </button>
          )}

        {status === "working" ||
          (status === "unpaid" && (
            <button
              className="dec-btn"
              style={{ marginRight: "10px" }}
              type="primary"
              onClick={updateStatus}
            >
              {statusName}
            </button>
          ))}

        {status === "active" && (
          <>
            {type === "" && (
              <button
                className="dec-btn"
                style={{ marginRight: "10px" }}
                type="primary"
                onClick={updateStatus}
              >
                {status === "active" ? "Inactive" : "Active"}
              </button>
            )}
            {APPROVAL(
              type,
              permission,
              <button
                className="dec-btn"
                style={{ marginRight: "10px" }}
                type="primary"
                onClick={updateStatus}
              >
                {status === "active" ? "Inactive" : "Active"}
              </button>
            )}
          </>
        )}

        {assing !== "" && assing !== null && assing !== undefined && (
          <button className="probtn mr-3" onClick={assing}>
            Assign User
          </button>
        )}

        {to !== "" && to !== undefined && to !== null && (
          <>
            {title === "Employees" ? (
              ""
            ) : (
              <Link to={to}>
                {type === "" && (
                  <button className="probtn">
                    {title === "Leads"
                      ? "Add Lead"
                      : title === "Projects"
                      ? "Add Project"
                      : "Add"}
                  </button>
                )}

                {CREATE(
                  type,
                  permission,

                  <button className="probtn">
                    {title === "Leads"
                      ? "Add Lead"
                      : title === "Projects"
                      ? "Add Project"
                      : "Add"}
                  </button>,
                  ""
                )}
              </Link>
            )}
          </>
        )}

        {vendors !== undefined && vendors !== null && vendors !== "" && (
          <button className="probtn" onClick={vendors}>
            Add
          </button>
        )}

        {tax !== undefined &&
          tax !== null &&
          vendors !== "" &&
          CREATE(
            type,
            permission,
            <button className="probtn" onClick={tax}>
              Add Tax
            </button>,
            ""
          )}

        {submit !== undefined && submit !== null && submit !== "" && (
          <>
            {title === "projects" ? (
              ""
            ) : (
              <button
                className={"dec-btn-secondary"}
                type="primary"
                onClick={backClick}
              >
                {title ? "Back" : "Cancel"}
              </button>
            )}

            <button style={{ border: "none" }} onClick={submit}>
              <div className={id ? "dec-btn" : "probtn"}>
                {spin ? (
                  <div className="">
                    <Spinner children={false} />
                  </div>
                ) : (
                  <div>
                    {title === "Invoices"
                      ? id
                        ? "Update Invoice"
                        : "Save Invoice"
                      : title === "projects"
                      ? id
                        ? "Update Project"
                        : "Create project"
                      : primary
                      ? primary
                      : id
                      ? "Update"
                      : "Save"}
                  </div>
                )}
              </div>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default TopHeader;
