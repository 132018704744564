import React, { Component } from "react";
import {
  Table,
  Spin,
  Button,
  Modal,
  Form,
  Input,
  message,
  Select,
  Radio,
  Empty
} from "antd";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { Tooltip } from "antd";
import { AiOutlineInfoCircle } from "react-icons/ai";


import { Link } from "react-router-dom";
import {
  getIsoString,
  utcdate,
  getDateMonth,
  showError,
  numberWithComma,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
} from "../../constants/Utils";
import TableField from "../../components/ui/tableField";

import SelectIcon from "../../assets/select.png";
import TopHeader from "../../components/ui/TopHeader";
import Filter from "../../components/ui/filter";

import "antd/dist/antd.css";
import GBLVAR from "../../ApiService/GlobalVariables/globalVariables";
import axios from "axios";
import cookie from "react-cookies";
import urlLink from "../../ApiService/GlobalVariables/globalVariables";
import { currencyFormatter } from "muthu-plugins";
const { Option } = Select;

export default class Salary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      salaries: [],
      currentPage: 1,
      total: "",
      data: [],
      pageSize: 20,
      page: 1,
      limit: 20,
      from: "",
      to: "",
      modal1Visible: false,
      file: "",
      searchOption: "",
      searchInput: "",
      Tableloading: true,
      loading: true,
      modal2Visible: false,
      app_name: '',
      app_type: '',
      searchData: [
        { value: "Emp Id", key: "empid" },
        { value: "Name", key: "name" },
      ],
      queryStatus: {},
      export_month: "last",
      overall: "",
    };
  }

  componentDidMount() {

    let name = "";


    this.setQueryStatus();
    this.reloadPage();
    window.addEventListener("popstate", this.onPopstate);
  }
  onPopstate = () => {
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);

    this.state.searchOption = searchObj.type;
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";

    this.state.searchInput = (searchObj.value) ?? "";
    this.state.queryStatus = returnSearchObject(search);

    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      this.salaryApi();
    });
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
  }
  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.clearSearch();
        }
      });
    });
  };


  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    if (this.state.searchOption !== "" && this.state.searchInput !== "") {
      querysearch.type = this.state.searchOption;
      querysearch.value = this.state.searchInput;

      querysearch[this.state.searchOption] = this.state.searchInput;
    }

    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";

    return querysearch;
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.salaryApi();
    });
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          searchInput: (queryObj.value) ?? "",
          searchOption: queryObj.type,
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",
          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.salaryApi();
        }
      );
    } else {
      this.setState(
        {
          queryStatus: { ...this.state.queryStatus, page: 1, limit: 20 },
          pageSize: 20,
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.salaryApi();
        }
      );
    }
  };

  handleFilter = () => {
    let query = this.getQuerySearch();

    this.setState({ queryStatus: query }, () => {
      pushToFilteredPage(this.props, this.state.queryStatus);
      this.salaryApi();
    });
  };

  salaryApi = () => {
    APIGATEWAY.get(
      "salaries/" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          let res = response.data;
          this.setState({
            salaries: res.salaries,
            total: res?.salaries?.length,
            loading: false,
            Tableloading: false,
            overall: response.data?.total,
          });
        } else {
          this.setState({ loading: true });
        }
      }
    );
  };
  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };
  next = (prev) => {
    const { currentPage } = this.state;

    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };
 

  Apply = () => {
    const {searchInput,searchOption} =this.state
    if((searchInput ==="" || searchOption ==="")){
      showError('Please select the field')
    }else{
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handleFilter();
        }
      );
    }
   
  };
  clearSearch = () => {
    let self = this;
    self.setState(
      {
        searchInput: "",
        searchOption: "",
        from: "",
        to: "",
        currentPage: 1,
        queryStatus: { page: 1, limit: 20 },
        pageSize: 20,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);

        this.salaryApi();
      }
    );
  };

  setModal1Visible = (modal1Visible) => {
    this.setState({ modal1Visible, file: "" });
  };

  setModal2Visible = (modal2Visible) => {
    this.setState({ modal2Visible });
  };

  handleOnChange = (data) => {
    this.setState({
      file: data.target.files[0],
    });
  };

  submitHandle = () => {
    let self = this;
    const formData = new FormData();

    const { file } = self.state;
    if (file === "") {
      showError("please import file");
    } else {
      formData.append("file", file);
      formData.append("test", "StringValueTest");

      axios
        .post(`${GBLVAR.BASE_URL}/salaries/importSalary`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + cookie.load("empr_tk"),
          },
        })
        .then((result) => {
          message.success("Import successfully");
          this.salaryApi();
          self.setState({
            modal1Visible: false,
            file: "",
          });
        });
    }
  };
  canceExport = () => {
    this.setModal2Visible(false);
    this.setState({ export_month: "last" });
  };
  searchInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  searchOption = (value) => {
    this.setState({
      searchOption: value,
    });
  };
  onChangemonth = (e) => {
    this.setState(
      { export_month: e.target.value },

    );
  };
  download = () => {
    let self = this;
    let token = cookie.load("empr_tk");
    var bearer = "Bearer " + token;
    const { export_month } = this.state;
    if (
      export_month === "" ||
      export_month === undefined ||
      export_month === null
    ) {
      message.error("please select anyone");
    } else {
      fetch(urlLink.BASE_URL + `salaries/exportsalary?month=${export_month}`, {
        method: "POST",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      }).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `${getDateMonth(
            new Date(),
            export_month
          )} - Salaries.csv`;
          a.click();
        });
        this.setState({
          modal2Visible: false,
        });
      });
    }
  };

  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  render() {
    let { currentPage, total, salaries,pageSize } = this.state;
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }

  
    return (
      <div className="">
        
          <div>
            <TopHeader
              title="Salaries"
              imports={() => this.setModal1Visible(true)}
              // exports={() => this.download()}

              exports={() => this.setModal2Visible(true)}
            />
            <div className="main-content">
              <Filter
                searchData={this.state.searchData}
                optionChange={(value) => this.searchOption(value)}
                searchOption={this.state.searchOption}
                searchname="searchOption"
                clear={() => this.clearSearch()}
                apply={() => this.Apply()}
                searchInputName="searchInput"
                searchInput={this.state.searchInput}
                inputChange={(e) => this.searchInput(e)}
              />

              {this.state.Tableloading ? (
                <div className="tableview">
                  <Spin />
                </div>
              ) : (

                <div className="table-main">
                {this.state.Tableloading ? (
                 <div className="tableview">
                   <Spin />
                 </div>
                 ) :(
           <div className="table_page_wrap">

         {salaries.length > 0 ?
          (<table className="table-response">
          <thead>
             <tr>
             <th >S.No</th>
             <th>Employee ID</th>
             <th >Name</th>
             <th >Salary</th>
             <th >Last Review</th>
             <th >Next Review</th>
             <th >Last Hike</th>
             <th >Overall Salary</th>

             
             </tr>
          </thead>

          {salaries.length > 0 ? (
               <tbody>
     {salaries.map((item, r) => (
       <tr key={r}>
         <td className=''>  {(this.state.currentPage - 1) * this.state.pageSize + (r + 1)}
         </td>
         <td className="table-name "> 
         {item?.employee?.official_emp_id !==""? item?.employee?.official_emp_id:'-'}
       </td>

         <td className="table-name text-capitalize">
         {item?.employee?.name??'-'}
       </td>
         <td className="table-name ">
                                    <Tooltip
                                      placement="right"
                                      title={
                                        numberWithComma(
                                          item?.salary
                                        ) ?? 0
                                      }
                                    >
                                      {" "}
                                      <span className="pointer">
                                        {" "}
                                        <AiOutlineInfoCircle />
                                      </span>
                                    </Tooltip>
                                  </td>{" "}
         
         <td className="table-name ">{item.review_date ? utcdate(item.review_date) : "-"}</td>
       <td className="table-name">
       { item.next_review_date ? utcdate(item.next_review_date) : "-"}
       </td>
       <td className="table-name ">
                                    <Tooltip
                                      placement="right"
                                      title={
                                        numberWithComma(
                                          item?.hike
                                        ) ?? 0
                                      }
                                    >
                                      {" "}
                                      <span className="pointer">
                                        {" "}
                                        <AiOutlineInfoCircle />
                                      </span>
                                    </Tooltip>
                                  </td>{" "}

       <td className="table-name ">
                                    <Tooltip
                                      placement="right"
                                      title={
                                        numberWithComma(
                                          item?.total_amount
                                        ) ?? 0
                                      }
                                    >
                                      {" "}
                                      <span className="pointer">
                                        {" "}
                                        <AiOutlineInfoCircle />
                                      </span>
                                    </Tooltip>
                                  </td>{" "}

       
       </tr>
     ))}

             </tbody>) : ''}
             </table>) :
            (<table className="table-response">
            <thead>
            <tr>
             <th >S.No</th>
             <th>Employee ID</th>
             <th >Name</th>
             <th >Salary</th>
             <th >Last Review</th>
             <th >Next Review</th>
             <th >Last Hike</th>
             <th >Overall Salary</th>

             
             </tr>
            </thead>


           <tbody className="empty-message">
            <tr>
             <td colspan="8">
               <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
             </td>

            </tr>
           </tbody>



          </table>)
           }
         {salaries.length > 0 && (
            <TableField
             data={salaries}
             countprev={countprev}
             countnext={countnext}
             currentPage={currentPage}
             ClickPrev={() => this.previous()}
             ClickNext={() => this.next(this.state.currentPage)}
             total={total}
             overAll={this.state.overall ?? 0}
             pagelimit={this.state.pageSize}
             pagelimitHandle={this.pageLimitOnchange}
            />
          )}


           </div>
         )}

             </div>
                
              )}
            </div>
          </div>
        
        <Modal
          title="Import"
          style={{ top: 50 }}
          open={this.state.modal1Visible}
          onOk={() => this.setModal1Visible(false)}
          onCancel={() => this.setModal1Visible(false)}
          footer={[
            <div className="d-flex justify-content-between align-items-center">
              <button
                key="cancel"
                className="ad-btn-secondary"
                onClick={() => this.setModal1Visible(false)}
              >
                Cancel
              </button>

              <button
                key="submit"
                className="ad-btn"
                onClick={() => this.submitHandle()}
              >
                Submit
              </button>
            </div>,
          ]}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className="admin_input"
          >
            <div>
              <Input
                type="file"
                id="csvFileInput"
                accept=".csv"
                name="file"
                // value={this.state.file}
                onChange={(e) => this.handleOnChange(e)}
              />
            </div>
          </Form>
        </Modal>

        <Modal
          title="Export"
          style={{ top: 50 }}
          open={this.state.modal2Visible}
          // onOk={() => this.setModal2Visible(false)}
          onCancel={() => this.setModal2Visible(false)}
          footer={[
            <div className="d-flex justify-content-between align-items-center">
              <button
                key="cancel"
                className="ad-btn-secondary"
                onClick={() => this.canceExport()}
              >
                Cancel
              </button>

              <button
                key="submit"
                className="dec-btn"
                onClick={() => this.download()}
              >
                Export
              </button>
            </div>,
          ]}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className="admin_input"
          >
            <div>
              {/* <Input
                type="file"
                id="csvFileInput"
                accept=".csv"
                name="file"
                // value={this.state.file}
                onChange={(e) => this.handleOnChange(e)}
              /> */}

              <Radio.Group
                onChange={this.onChangemonth}
                value={this.state.export_month}
              >
                <Radio value="last">Last Month</Radio>
                <Radio value="current">Current Month</Radio>
              </Radio.Group>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}
