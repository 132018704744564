import React, { Component } from 'react';
import TopHeader from "../../components/ui/TopHeader";
import Apicall from '../../ApiService/ApiGateWay/apiGateWay';
import { Spin, Divider } from 'antd';
import { isErrorTxt, numberFormatWithCurrency, showCurrency, statusColor } from '../../constants/Utils';

export default class LeadDetail extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            lead_id: this.props.match.params.id,
            lead_data: '',
            loader: true,
        };

    }

    componentDidMount = () => {
        if ( this.state.lead_id ) {
            this.handleTable();

        }
    };

    handleTable = () => {
        Apicall.get( 'leads/' + this.state.lead_id, ( response ) => {
            if ( response.success ) {
             
                let data = response.data;
                this.setState( {
                    lead_data: data,
                    loader: false
                } );
            }
        } );
    };

    back = () => {
        this.props.history.goBack();
    };

    textContent = ( name, content, val ) => {
        return (
            <div className='name-wrappers'>
                <div className="title-wrapper font-500">{name}</div>
                <div className={val === "email" ? "data-content" : "data-content text-capitalize"}>{isErrorTxt( content ) ? "-" : content ?? "-"}</div>

            </div>
        );

    };
    render () {
        const { lead_data, loader } = this.state;
        return (
            <div>
                <TopHeader
                    title="Lead Details"
                    backClick1={this.back}
                    type=""
                />
                {loader ? (
                    <div className='tableview'>
                        <Spin />

                    </div>
                ) : (
                    <div className="main-content">
                        <div className="details-content-col">

                            {this.textContent( "Client Name", ( lead_data?.client_name ) )}
                            {this.textContent( "Project Name", ( lead_data?.project_name ) )}

                        </div>
                        <div className="details-content-col">

                            {this.textContent( "Email", ( lead_data?.email ), 'email' )}
                            {this.textContent( "Phone Number", ( lead_data?.phone?.national_number ) )}

                        </div>
                        <div className="details-content-col">
                            <div className="name-wrappers">
                                <div className="title-wrapper font-500">Quote Amount</div>
                                <div className="data-content">{showCurrency( lead_data?.amount?.currency )}{isErrorTxt( lead_data?.amount?.quote ) ? 0 : numberFormatWithCurrency( lead_data?.amount?.quote, lead_data?.amount?.currency )}</div>
                            </div>


                            <div className="name-wrappers">
                                <div className="title-wrapper font-500">Expected Amount</div>
                                <div className="data-content text-capitalize">{showCurrency( lead_data?.amount?.currency )}{isErrorTxt( lead_data?.amount?.expected ) ? 0 : numberFormatWithCurrency( lead_data?.amount?.expected, lead_data?.amount?.currency )}</div>
                            </div>
                        </div>
                        <div className="details-content-col">
                            <div className="name-wrappers">
                                <div className="title-wrapper font-500">Status</div>

                                <div className='data-content text-capitalize'>
                                    {isErrorTxt( lead_data?.status ) ? '-' : statusColor( lead_data?.status )}
                                </div>
                            </div>

                        </div>

                        {isErrorTxt( lead_data.description ) ? "" : (
                            <div className='mb-5 mt-4'>
                                <Divider orientation='left'>Description</Divider>
                                <div className="des-content">{lead_data.description}</div>
                            </div>
                        )}
                        {isErrorTxt( lead_data.notes ) ? "" : (
                            <div>
                                <Divider orientation='left'>Notes</Divider>
                                <div className="des-content">{lead_data.notes}</div>
                            </div>
                        )}

                    </div>
                )}

            </div>
        );
    }
}
