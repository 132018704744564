import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { BsChevronRight } from "react-icons/bs";
import { Input, Select, DatePicker, Spin, message } from "antd";
import {
  showError,
  isInvalidName,
  capitlizestring,
} from "../../constants/Utils";
import { useParams, useLocation } from "react-router-dom";
import moment from "moment";
import Breadcrumb from "../../components/ui/breadcrumbs";
import TopHeader from "../../components/ui/TopHeader";
import { act } from "react-dom/test-utils";
import breadcrumbs from "../../assets/breadcrumbs.svg";

const { TextArea } = Input;
const { Option } = Select;

const AddProject = (props) => {
  const [name, setName] = useState("");
  const [clientName, setClientName] = useState("");
  const [projectValue, setProjectValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [actual_startDate, setActual_StartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalDays, setTotalDays] = useState("");
  const [platform, setPlatForm] = useState([]);
  const [technologies, setTechnologies] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [team, setTeam] = useState([]);
  const [description, setDescription] = useState("");
  const [clientList, setClientList] = useState([]);
  const [platformList, setPlatFormList] = useState([]);
  const [technologiesList, setTechnologiesList] = useState([]);
  const [teamMembersList, setTeamMembersList] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [initPageLoading, setInitPageLoading] = useState(true);
  // const { id } = useParams();
  const { id } = useParams();
  const location = useLocation();
  const path = location.pathname;
  const tempLocationArray = path.split("/");
  tempLocationArray.shift();
  const current_date = moment().format("YYYY-MM-DD");
  
  
  useEffect(() => {
    Promise.all([
      fetchClientLists(),
      fetchPlatformLists(),
      fetchTechnologiesLists(),
      fetchEmployees(),
    ])
      .then(([clientList, platforms, technologies, employees]) => {
        getProjectDetails(platforms, technologies, employees,clientList);
      })
      .catch(() => {
        console.error("something went wrong");
      });
  }, []);

  useEffect(() => {
    if(endDate && actual_startDate){
      setTotalDays( actual_startDate && endDate && endDate.diff(actual_startDate, "days"));
    }
    
  },[endDate,actual_startDate])

  useEffect(() => {
    teamMembers.forEach((member) => {
    });
  }, [teamMembers]);

  const handelClientId = (item) => {
    setClientName(item);
  };

  function fetchClientLists() {
    return new Promise((resolve, reject) => {
      const queryParams = "";
      APIGATEWAY.get("clients/exists/list" + queryParams, (response) => {
        if (response.success) {
          const clients = response.data.exists_clients;
          setClientList(clients);
          resolve(clients);
        } else reject(false);
      });
    });
  }

  function fetchPlatformLists() {
    return new Promise((resolve, reject) => {
      const queryParams = "";
      APIGATEWAY.get("platforms" + queryParams, (response) => {
        if (!response.success) return reject(false);
        const platforms = response.data.platforms;
        setPlatFormList(platforms);
        resolve(platforms);
      });
    });
  }

  function fetchTechnologiesLists() {
    return new Promise((resolve, reject) => {
      const queryParams = "";
      APIGATEWAY.get("technologies" + queryParams, (response) => {
        if (response.success) {
          const techonologies = response.data.technologies;
          setTechnologiesList(techonologies);
          resolve(techonologies);
        } else reject(false);
      });
    });
  }

  
  function dynamicRouting(route) {
    if (route === null || route === undefined)
      return props.history.push(`/projects`);
    else return props.history.push(`/${route}`);
  }

  function getProjectDetails(platforms, technologies, employees,clientList) {
    APIGATEWAY.get("project/" + id, (response) => {
      const platformObj = {};
      const technologyObj = {};
      const teamMemberObj = {};
      if (response.success) {
        const result = response.data.project[0];
        const selectedPlatform = response.data.project[0].platforms;
        const selectedTechnologies = response.data.project[0].technologies;
        const teams = response.data.project[0].teams;

        setClientName(JSON.stringify(clientList.filter(client => { return (result.client && (client._id === result.client._id)) || (result.client_id && (client.client_id === result.client_id.length > 9 ? JSON.parse(result.client_id) : result.client_id)) })[0]))
  
        let addedTeamMembers = [];
        if (typeof teams[0] === "string") addedTeamMembers = teams;
        else
          addedTeamMembers = teams.map((team) => {
            return team.employee._id;
          });
        platforms.map((selectedPlatform) => {
          platformObj[selectedPlatform._id] = selectedPlatform;
        });
        setPlatForm(
          selectedPlatform.map((selected) => {
            return JSON.stringify(platformObj[selected]);
          })
        );

        technologies.map((selectedTecnology) => {
          technologyObj[selectedTecnology._id] = selectedTecnology;
        });
        setTechnologies(
          selectedTechnologies.map((selected) => {
            return JSON.stringify(technologyObj[selected]);
          })
        );

        employees.map((employee) => {
          teamMemberObj[employee._id] = employee;
        });
        setTeamMembers(
          addedTeamMembers.map((selectedTeam) => {
            return JSON.stringify(teamMemberObj[selectedTeam]);
          })
        );

        setTimeout(() => {}, 1000);
        setName(result.name);
        setProjectValue(result.projectValue);
        setStartDate(moment(result.startDate));
        setActual_StartDate(moment(result.actual_start));
        setEndDate(moment(result.endDate));
        // setPlatForm(result.platforms);
        // setTechnologies(result.technologies);
        // setTeamMembers(result.teams);
        setDescription(result.description);
        setTotalDays(result.totalDays);
        setInitPageLoading(false);

      }
    });
  }

  function fetchEmployees() {
    return new Promise((resolve, reject) => {
      const queryParams = "";
      APIGATEWAY.get("employees/all" + queryParams, (response) => {
        if (!response.success) return reject(false);
        const employees = response.data.employees;
        setTeamMembersList(employees);
        resolve(employees);
      });
    });
  }

  const handleCreateClick = () => {
    setBtnLoader(true);
    if (name === undefined || name.trim() === "") {
      setBtnLoader(false);
      showError("Please enter name");
    } else if (name.length <= 3) {
      setBtnLoader(false);
      showError("Name should contain atleast 3 characters");
      // } else if (isInvalidName(name)) {
      //   setBtnLoader(false);
      //   showError("Name should contain letters only");
      // } else if (clientName === "") {
      setBtnLoader(false);
      showError("Please select client");
    } else if (projectValue === "") {
      setBtnLoader(false);
      showError("Please enter project value");
    } else if (!startDate) {
      setBtnLoader(false);
      showError("Please enter start date");
    } else if (actual_startDate === "") {
      setBtnLoader(false);
      showError("Please enter actual start date");
    } else if (endDate === "") {
      setBtnLoader(false);
      showError("Please enter end date");
    // } else if (totalDays === "") {
    //   setBtnLoader(false);
    //   showError("Please enter total days");
    } else if (platform.length === 0) {
      setBtnLoader(false);
      showError("Please select platform");
    } else if (technologies.length === 0) {
      setBtnLoader(false);
      showError("Please select technologies");
    } else if (teamMembers.length === 0) {
      setBtnLoader(false);
      showError("Please select team members");
    } else {
      const team = [];
      const selectedPlatForm = [];
      const SelectedTechnologies = [];
      teamMembers.map((teamMember) => {
        team.push(JSON.parse(teamMember));
      });
      platform.map((el) => {
        const parsed = JSON.parse(el);
        selectedPlatForm.push(parsed._id);
      });
      technologies.map((tech) => {
        const parsed = JSON.parse(tech);
        SelectedTechnologies.push(parsed._id);
      });
      let client = JSON.parse(clientName);
      const data = {
        name: name,
        description: description,
        platforms: selectedPlatForm,
        technologies: SelectedTechnologies,
        startDate: startDate,
        actualStartDate: actual_startDate,
        endDate: endDate,
        totalDays: totalDays,
        client_id: client.client_id,
        projectValue: projectValue,
        status: "active",
        teams: team,
        client: client._id,
      };
      if (id) {
        APIGATEWAY.patch("project/" + id, data, (response) => {
          setBtnLoader(false);
          if (response.success) {
            message.success("Project Updated successfully");
            props.history.push("/projects");
          } else return message.error(response.message);
        });
      } else {
        APIGATEWAY.post("project", data, (response) => {
          setBtnLoader(false);
          if (response.success) {
            message.success("Project Added successfully");
            props.history.push("/projects");
          } else return message.error(response.message);
        });
      }
    }
  };

  const handleDateChange = (date, dateString, name) => {
    if (name === "startDate") {
      setStartDate(date);
    } else if (name === "actual_startDate") {
      setActual_StartDate(date);
    } else if (name === "endDate") {
      setEndDate(date);
    }
  };

  const handleNumberValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const reg = /^\d+$/;
    if (name === "project_value" && (value === "" || reg.test(value))) {
      setProjectValue(value);
    } else if (name === "totalDays" && (value === "" || reg.test(value))) {
    }
  };

  function onPlatformChange(e) {
    setPlatForm(e);
  }

  function onTeamChange(e) {
    if (id) return showError('Cannot update team member details here')
    setTeamMembers(e)
  }

  function checkStartDate(e) {
    if (!startDate) return e.isBefore(current_date);
    else if (startDate && actual_startDate)
      return (
        e.isBefore(startDate.format("YYYY-MM-DD")) ||
        e.isAfter(actual_startDate.format("YYYY-MM-DD"))
      );
    else if (endDate)
      return (
        e.isBefore(current_date) && e.isAfter(endDate.format("YYYY-MM-DD"))
      );
    else return e.isBefore(current_date);
  }

  function checkActualStartDate(e) {
    if (!actual_startDate && !startDate) return e.isBefore(current_date);
    else if (startDate && !endDate)
      return (
        e.isBefore(current_date) || e.isBefore(startDate.format("YYYY-MM-DD"))
      );
    else if (startDate && endDate)
      return (
        e.isBefore(startDate.format("YYYY-MM-DD")) ||
        e.isAfter(endDate.format("YYYY-MM-DD"))
      );
    else return e.isBefore(current_date);
  }

  function checkEndDate(e) {
    if (!endDate && !startDate && !actual_startDate)
      return e.isBefore(current_date);
    else if (!endDate && startDate && !actual_startDate)
      return e.isBefore(startDate.format("YYYY-MM-DD"));
    else if (actual_startDate) return e.isBefore(actual_startDate.format("YYYY-MM-DD"));
    else return
  }

  function onBackClick() {
    props.history.push("/projects");
  }

  return (
    <div>
      {initPageLoading && id ? (
        <div className="spinning-star">
          <Spin size="large" />
        </div>
      ) : (
        <div className="main-content">
          <div className="d-flex_jc_sb_al_c mb_30">
            {/* <Breadcrumb fields={tempLocationArray}></Breadcrumb> */}
            <div className="d-flex">
              <div
                className="fw_500 fs_15 c_light pointer"
                onClick={() => dynamicRouting(location?.state?.from)}
              >
                {location?.state?.from
                  ? capitlizestring(location?.state?.from)
                  : "Project"}
              </div>
              <div className="ml-10">
                <img src={breadcrumbs}></img>
              </div>
              <div className="ml-10 fw_600 fs_15">
                {location?.state?.from ? "Edit" : "Add Project"}
              </div>
            </div>
            <TopHeader
              // title="projects"
              classname="p_0"
              submit={handleCreateClick}
              backClick={onBackClick}
              id={id}
              spin={btnLoader}
            />
          </div>

          <div style={{ marginBottom: "100px" }} className="create-project">

          <div className="sm__grid--col-4 mb_16">
            <div style={{ marginBottom: "16px" }} className="project_inputs">
              <p className="onboard_input_name fw_500 fs_14 ">
                Name<span className="mandatory-field">&#42;</span>
              </p>
              <div className="project_input">
                <Input
                  name="name"
                  value={name}
                  placeholder="Enter Name"
                  onChange={(e) => setName(e.target.value)}
                  className="onboard-date "
                  style={{ height: 47, borderRadius: "5px" }}
                />
              </div>
            </div>

            {/* <div className="sm__grid--col-4"> */}
              <div className=" ">
                <div className="title-wrapper fw_500 fs_14">
                  Client Name<span className="mandatory-field">&#42;</span>
                </div>
                <div className="project_placeholder">
                  <Select
                    style={{ width: "100%" }}
                    className="blood"
                    showSearch
                    placeholder="Enter Client Name"
                    onChange={(value) => handelClientId(value)}
                    value={clientName || undefined}
                    optionFilterProp="children"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {clientList.map((item, i) => (
                      <Option key={i} value={JSON.stringify(item)}>
                        {item.name.first_name + " " + item.name.last_name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="">
                <div className="title-wrapper fw_500 fs_14">
                  Project Value<span className="mandatory-field">&#42;</span>
                </div>
                <div className="project_input">
                  <Input
                    name="project_value"
                    value={projectValue}
                    placeholder="Enter the value"
                    // onChange={this.handleOnNumberInputChange}
                    onChange={handleNumberValue}
                    className="onboard-date"
                  />
                </div>
              </div>
            {/* </div> */}
            </div> 

            <div className="sm__grid--col-4 mb_32">
              <div className="project_date_picker">
                <p className="fw_500 fs_14" style={{ margin: "0 0 0 0" }}>
                  Start Date<span className="mandatory-field">&#42;</span>
                </p>
                <DatePicker
                  disabledDate={checkStartDate}
                  name="date_start"
                  label="Start Date"
                  placeholder="Start Date"
                  value={startDate}
                  className="commonDatepicker"
                  labelClass="fw_500 fs_16"
                  // handleChange={this.handleOnDateChange}
                  onChange={(date, dateString) =>
                    handleDateChange(date, dateString, "startDate")
                  }
                />
              </div>
              <div>
                <p className="fw_500 fs_14" style={{ margin: "0 0 0 0" }}>
                  Actual Start Date
                  <span className="mandatory-field">&#42;</span>
                </p>
                <DatePicker
                  // disabled={!startDate}
                  disabledDate={checkActualStartDate}
                  // disabledDate={d => !d || d.isBefore(startDate.format("YYYY-MM-DD"))}
                  name="timeline_from"
                  value={actual_startDate}
                  placeholder="Actual Start Date"
                  className="commonDatepicker"
                  // getPopupContainer={trigger => trigger.parentNode}
                  inputReadOnly
                  // handleChange={this.handleOnDateChange}
                  onChange={(date, dateString) =>
                    handleDateChange(date, dateString, "actual_startDate")
                  }
                />
              </div>
              <div className="">
                <p className="fw_500 fs_14" style={{ margin: "0 0 0 0" }}>
                  End date<span className="mandatory-field">&#42;</span>
                </p>
                <DatePicker
                  // disabled={!actual_startDate}
                  disabledDate={checkEndDate}
                  // disabledDate={d => !d || d.isSameOrBefore(actual_startDate.format("YYYY-MM-DD"))}
                  name="timeline_to"
                  value={endDate}
                  placeholder="End Date"
                  inputReadOnly
                  // handleChange={this.handleOnDateChange}
                  onChange={(date, dateString) =>
                    handleDateChange(date, dateString, "endDate")
                  }
                  className="commonDatepicker"
                  // getPopupContainer={(trigger) => trigger.parentNode}
                />
              </div>
            </div>

            {/* <div className="sm__grid--col-4 mb_32">
           
              <div className="field-holder">
                <div className="title-wrapper fw_500 fs_14">
                  
                  Total Days<span className="mandatory-field">&#42;</span>
                </div>
                <div className="project_input">
                  <Input
                    name="totalDays"
                    value={totalDays}
                    placeholder="Enter Total Days"
                    onChange={handleNumberValue}
                    className="onboard-date"
                  />
                </div>
              </div>
            </div> */}
      <div className="sm__grid--col-4" style={{'margin-bottom': '0px'}}>
            <div className="create-project-select-tag">
              <div className="onboard_input_name fw_500 fs_14  mb-3">
                Platform<span className="mandatory-field">&#42;</span>
              </div>
              <div className="project_placeholder">
                <Select
                  // className="create-project-select"
                  value={platform || undefined}
                  style={{ width: "100%" }}
                  placeholder="Select Platform"
                  mode="multiple"
                  onChange={onPlatformChange}
                  // onChange={platformChange}
                  className="blood"
                >
                  {platformList.map((ele, i) => (
                    <Option value={JSON.stringify(ele)} key={i}>
                      {ele.name}
                    </Option>
                  ))}
                  <Option>Data</Option>
                </Select>
              </div>
            </div>

            <div className="create-project-select-tag">
              <div className="onboard_input_name fw_500 fs_14  mb-3">
                Technologies<span className="mandatory-field">&#42;</span>
              </div>
              <div className="project_placeholder">
                <Select
                  // className="create-project-select"
                  className="blood"
                  style={{ width: "100%" }}
                  value={technologies || undefined}
                  placeholder="Select Technologies"
                  mode="multiple"
                  onChange={(e) => setTechnologies(e)}
                >
                  {technologiesList.map((ele, i) => (
                    <Option key={i} value={JSON.stringify(ele)}>
                      {ele.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            </div>

            <div className="create-project-select-tag">
              <div className="onboard_input_name fw_500 fs_14  mb-3">
                Team Members<span className="mandatory-field">&#42;</span>
              </div>
              <div className="project_placeholder">
                <Select
                  // className="create-project-select"
                  className="blood"
                  style={{ width: "100%" }}
                  value={teamMembers || undefined}
                  placeholder="Select Team Members"
                  mode="multiple"
                  onChange={(e) => onTeamChange(e)}
                >
                  {teamMembersList.map((ele, i) => (
                    <Option key={i} value={JSON.stringify(ele)}>
                      {ele.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="create-project-description">
              <div className="onboard_input_name fw_500 fs_14  mb-3">
                Description
              </div>
              <div className="project_placeholder">
                <TextArea
                  className="project_textArea"
                  name="description"
                  value={description}
                  rows={4}
                  placeholder="Enter a Description"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddProject;
