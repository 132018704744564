import React, { Component } from "react";
import { Table, Input, Spin, Card } from "antd";
import { SearchOutlined, FormOutlined } from "@ant-design/icons";

import APIGATEWAY from "../ApiService/ApiGateWay/apiGateWay";

import { showDate } from "../constants/Utils";
class ClientProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      search: "",
      total: "",
      currentPage: 1,
      pageSize: 10,
      loading: true,
      status: "",
      id: this.props.match.params.id,
    };
  }
  componentDidMount() {
    this.handleTable(this.state.emp_id);
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.handleTable();
    });
  };
  handleSearchChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.handleTable();
      }
    );
  };
  handleTable = () => {
    let { currentPage, search, status, id } = this.state;
    var queryParams = "?page=" + currentPage;
    if (search !== "") {
      queryParams = "?search_term=" + search;
    }
    if (status !== "") queryParams += "&status=" + status;
    if (id !== "") {
      queryParams += "&client_id=" + id;
    }
    if (status !== "") queryParams += "&status=" + status;
    APIGATEWAY.get("projects" + queryParams, (response) => {
      if (response.success) {
        let currentData = response.data.projects;
        let total = response.data.total;

        this.setState({ data: currentData, total, loading: false });
      } else {
        this.setState({ loading: true });
      }
    });
  };
  handleDetailpage = (username) => {
    this.props.history.push("/addeditproject/details/" + username);
  };
  handleEditPage = (username) => {
    this.props.history.push("/addeditproject/" + username);
  };

  handleOnSearchChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.handleTable();
      }
    );
  };
  render() {
    let { data, total, currentPage } = this.state;
    const columns = [
      {
        title: "S.No",
        dataIndex: "S.No",
        align: "center",
        render: (value, item, index) =>
          (this.state.currentPage - 1) * 10 + (index + 1),
      },
      {
        title: "ID",
        dataIndex: "project_id",
        align: "center",
        render: (project_id) => (
          <a onClick={() => this.handleDetailpage(project_id)}>{project_id}</a>
        ),
      },

      {
        title: "Name",

        align: "center",
        dataIndex: "name",
      },
      {
        title: "Start",
        align: "center",
        render: (date) => showDate(date.start),
      },
      {
        title: "Status",
        align: "center",
        dataIndex: "status",
      },
      {
        title: "Edit",
        dataIndex: "project_id",
        align: "center",
        render: (project_id) => (
          <a onClick={() => this.handleEditPage(project_id)}>
            <FormOutlined />
          </a>
        ),
      },
    ];

    return (
      <div className="div-wrapper">
        {this.state.loading ? (
          <div className="spinning-star">
            <Spin size="large" />
          </div>
        ) : (
          <Card>
            <div>
              <div className="input-div">
                <h2 className="h4 gx-mb-3">Projects</h2>
                <div className="input-group-projects">
                  <div className="input-relative-div">
                    <Input
                      className="h4 gx-mb-3 input-group1"
                      placeholder="Search..."
                      name="search"
                      onChange={this.handleSearchChange.bind(this)}
                    />
                    <span className="gx-search-icon gx-pointer search-span">
                      <SearchOutlined />
                    </span>
                  </div>
                </div>
              </div>
              <Table
                align="center"
                className="gx-table-responsive"
                columns={columns}
                dataSource={data}
                size="middle"
                style={{ textTransform: "capitalize" }}
                pagination={
                  total.length > 10
                    ? {
                        pageSize: 10,
                        activePage: currentPage,
                        total: total,
                        pageSizeOptions: ["10", "20", "30", "40"],
                        onChange: this.handlePageChange.bind(this),
                      }
                    : false
                }
              />
            </div>
          </Card>
        )}
      </div>
    );
  }
}
export default ClientProject;
