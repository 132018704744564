import React from "react";
import { Progress } from "antd";
import "antd/dist/antd.css";

const ProgressBar = ({ percentage }) => {
  return (
    <div>
      <Progress
        className="project-progress-bar"
        percent={percentage}
        strokeWidth={"6px"}
      />
    </div>
  );
};

export default ProgressBar;
