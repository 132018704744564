import axios from "axios";
import GBLVAR from "../GlobalVariables/globalVariables";
import cookie from "react-cookies";
class ApiCall {
  get(url, callback) {
    this.createInstance(url, null, 0)
      .then((result) => {
        callback(result.data);
      })
      .catch((error) => {
        checkStatus(error?.response?.status ?? "");
      });
  }
  post(url, data, callback) {
    this.createInstance(url, data, 1)
      .then((result) => {
        callback(result.data);
      })
      .catch((error) => {
        checkStatus(error?.response?.status ?? "");
      });
  }

  patch(url, data, callback) {
    this.createInstance(url, data, 2)
      .then((result) => {
        callback(result.data);
      })
      .catch((error) => {
        checkStatus(error?.response?.status ?? "");
      });
  }

  delete(url, data, callback) {
    this.createInstance(url, data, 3)
      .then((result) => {
        callback(result.data);
      })
      .catch((error) => {
        checkStatus(error?.response?.status ?? "");
      });
  }

  createInstance(append, data, type) {
    var instance = axios.create();
    instance.defaults.headers.post["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] =
      "Bearer " + cookie.load("empr_tk");
    instance.defaults.headers.common["x-consumer-username"] = "admin_admin";
    // instance.defaults.headers.common["x-consumer-username"] = cookie.load("empr_tk");
    var url = GBLVAR.BASE_URL + append;
    switch (type) {
      case 0:
        return instance.get(url);
      case 1:
        return instance.post(url, data);
      case 2:
        return instance.patch(url, data);
      default:
        return instance.delete(url, data);
    }
  }
}

const checkStatus = (code) => {
  if (code === 401) {
    
    window.location.reload();
  }
};

export default new ApiCall();
