import React, { useState, useRef, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Select from "react-select";
import vector from "../../assets/Vector.png";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import {
  showDayMonthYear,
  projectColor,
  useDebounce,
  getStatusColor,
} from "../../constants/Utils";
import layer from "../../assets/Layer 2.svg";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom";
import { DatePicker, Drawer } from "antd";
import TopHeader from "../../components/ui/TopHeader";
import moment from "moment";
import close from "../../assets/close_symbol.svg";
import { message, Empty, Spin } from "antd";
import TableField from "../../components/ui/tableField";
import UpdateProjectComponent from "./updateProjectComponent";

function MeetingList(props) {
  const history = useHistory();
  const projectId = props.location.state;
  const params = useParams();
  const meetingStatusOptions = [
    { value: "Completed", key: "completed" },
    { value: "Cancelled", key: "cancelled" },
  ];

  const [selectedOption, setSelectedOption] = useState(null);
  //DropDown using antDesign
  const optionsThisMonth = [
    { value: "this_month", label: "This Month" },
    { value: "last_month", label: "Last Month" },
    { value: "this_year", label: "This Year" },
    { value: "last_year", label: "Last Year" },
  ];

  const optionsStatus = [
    { value: "all", label: " All" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "cancelled", label: "Cancelled" },
  ];
  const itemInThePage = [
    { value: "5", label: "5" },
    { value: "10", label: "10" },
    { value: "15", label: "15" },
  ];
  const newStyles = {
    control: (provided, state) => ({
      ...provided,

      width: "40px !important",
      lineHeight: "13px",
      color: "#214757",
      boxShadow: "none",
      outline: "none",
      border: 0,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "13px",
      width: "40px !important",
      fontWeight: "400", // Set your desired font size for options here
      paddingRight: "10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: "400",
      color: "#214757",
    }),
    indicatorsContainer: (provided) => {
      const topPosition = selectedOption === null ? "8%" : "-9999px";
      return {
        ...provided,
        backgroundColor: "transparent",
        fill: "#214757",
        border: "none",
        position: "absolute",
        top: topPosition,
        left: "20%",
      };
    },
    dropdownIndicator: (provided) => ({
      ...provided,
      transition: "none !important",
    }),
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,

      width: "158px",
      lineHeight: "13px",
      color: "#214757",
      height: "41px",
      boxShadow: "none",
      outline: "1px solid rgba(205, 205, 205, .4)",
      border: 0,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: "400", // Set your desired font size for options here
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: "400",
      color: "#214757",
      top: "45%",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      marginBottom: "7px",
      fill: "#214757",
      position: " absolute",
      top: "10%",
      right: "5%",
      paddingRight: "220px !impartant",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      transition: "none !important",
    }),
  };

  const [nextMeetingAdd, setNextMeetingAdd] = useState({
    project: projectId,
    title: "",
    nextMeeting: "",
    description: "",
  });

  const editRef1 = useRef(null);
  const [meetingList, setMeetingList] = useState(null);
  const [editVisible, setEditVisible] = useState(null);
  const [meetingId, setMeetingId] = useState(null);

  const [visibleAddMeeting, setVisibleAddMeeting] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  //pagination
  const [totalDoc, setTotalDoc] = useState("");
  const [total, setTotal] = useState("");
  const [itemPerPage, setItemPerPage] = useState("10");
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemPerPage;
  const [searchTerm, setSearchTerm] = useState("");
  const [dateFilter, setDateFilter] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    limit: 10,
    search_term: "",
    date_filter: "this_year",
    status: "all",
  });
  const [openMeetingStatusDrawer, setOpenMeetingStatusDrawer] = useState(false);

  let countnext = total;
  let countprev = total - (total - 1);
  if (currentPage > 1) {
    countnext = (currentPage - 1) * 10 + total;
    countprev = countnext - (total - 1);
  }

  useEffect(() => {
    getNextMeetingList(projectId);
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getNextMeetingList(projectId);
  }, [queryStatus]);

  const getNextMeetingList = () => {
    setLoading(true);

    return new Promise((resolve, reject) => {
      const queryString = new URLSearchParams(queryStatus).toString();

      APIGATEWAY.get(
        `project/meeting/${projectId}?${queryString}`,
        (response) => {
          if (response.success) {
            setLoading(false);

            setMeetingList(response.data.meetings);
            setTotalDoc(response.data.totalCount);
            setTotal(response.data.meetings.length);
          }
          resolve(response.data.meetings);
        }
      );
    });
  };

  const handleEditClick = (e, id) => {
    // e.preventDefault()
    setEditVisible((prevEditVisible) => (prevEditVisible === id ? null : id));
  };

  const handleClickOutside = (event) => {
    if (editRef1.current && !event.target.classList.contains("menu-bar-icon")) {
      setEditVisible(false);
    }
  };

  const handleEdit = (id) => {
    APIGATEWAY.get(`project/meeting/detail/${id}`, (response) => {
      if (response.success) {
        setVisibleAddMeeting(true);
        const data = response.data.meeting;
        setNextMeetingAdd({
          project: data?.project,
          title: data?.title,
          nextMeeting: data?.nextMeeting,
          description: data?.description,
        });
        setMeetingId(data._id);
      }
    });
  };

  const statusTitleChange = (event, type) => {
    const titleip = event.target.value;
    setNextMeetingAdd({ ...nextMeetingAdd, title: titleip });
  };

  const handleDateChange = (event, type) => {
    setNextMeetingAdd({ ...nextMeetingAdd, nextMeeting: event });
  };

  const meetingDescriptionAdd = (event, type) => {
    setNextMeetingAdd({ ...nextMeetingAdd, description: event.target.value });
  };

  const handleSelectDateFilter = (e) => {
    setDateFilter(e.value);
    setQueryStatus((prev) => ({ ...prev, date_filter: e.value }));
  };

  const handleSelectStatus = (e) => {
    setQueryStatus((prev) => ({ ...prev, status: e.value }));
  };

  const handleSearchFilter = (e) => {
    let searchTimeout;
    const value = e.target.value;
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setQueryStatus((prev) => ({ ...prev, search_term: value }));
    }, 1000);
    setSearchTerm(value);
  };

  const closeDrawer = (e) => {
    setOpenMeetingStatusDrawer(false);
    e && e.preventDefault();
    return new Promise((resolve, reject) => {
      setNextMeetingAdd({
        project: projectId,
        title: "",
        nextMeeting: "",
        description: "",
      });
      setVisibleAddMeeting(false);
      resolve(true);
    });
  };

  const createOthers = (e, id) => {
    e.preventDefault();

    let body;

    body = {
      nextMeeting: moment(nextMeetingAdd.nextMeeting),
      project: projectId,
      ...nextMeetingAdd,
    };
    setBtnLoader(true);

    if (id) {
      APIGATEWAY.patch(`project/meeting/${id}`, body, (response) => {
        if (response.success) {
          setBtnLoader(false);
          message.success(`meeting updated successfully`);
          closeDrawer(e).then((res) => {
            setNextMeetingAdd({
              project: projectId,
              title: "",
              nextMeeting: "",
              description: "",
            });
            getNextMeetingList(projectId);
          });
        }
      });
    } else {
      APIGATEWAY.post(`project/meeting`, body, (response) => {
        if (response.success) {
          message.success(`meeting added successfully`);
          closeDrawer(e).then((res) => {
            setBtnLoader(false);
            setNextMeetingAdd({
              project: projectId,
              title: "",
              nextMeeting: "",
              description: "",
            });
            getNextMeetingList(projectId);
          });
        }
      });
    }
  };

  const next = (prevPage) => {
    setCurrentPage(prevPage + 1);
    setQueryStatus((prev) => ({ ...prev, page: prevPage + 1 }));
    setLoading(true);
  };

  const pageLimitOnchange = (value) => {
    setLoading(true);
    setQueryStatus((prev) => ({ ...prev, limit: value }));
  };
  const previous = () => {
    setCurrentPage(currentPage - 1);
    setQueryStatus((prev) => ({ ...prev, page: currentPage - 1 }));
    setLoading(true);
  };

  const handleClickShowDrawer = () => {
    setVisibleAddMeeting(true);
  };

  const updateMeetingStatus = (data) => {
    setOpenMeetingStatusDrawer(true);
    setMeetingId(data._id);
  };

  function MeetingStatus(isTrue, response) {
    if (isTrue) {
      closeDrawer();
      setMeetingId(null);
      getNextMeetingList();
    }
  }

  return (
    <div className="Details-Page">
      <div className="crums">
        <Link to={`/projects`}>
          <p className="crums1 pointer">Projects</p>
        </Link>
        <img className="crums-vector" src={vector} alt="arrow"></img>
        <Link
          to={{
            pathname: `/projects/detailspage/${params.id}`,
            state: {
              searchString: "other",
            },
          }}
        >
          {" "}
          <p className="crums1 ml-2 pointer">Project Detail Page</p>
        </Link>
        <img className="crums-vector" src={vector} alt="arrow"></img>
        <p className="crums2">Meetings</p>
      </div>

      <div className="milestone-container">
        <div className="searchbar-container mb_20">
          <div className="search-input">
            <AiOutlineSearch className="searchicon" />
            <input
              className="search-inin "
              value={searchTerm}
              onChange={(e) => handleSearchFilter(e)}
              placeholder="Search"
            />
          </div>
          <div className="search-input">
            <Select
              options={optionsThisMonth}
              defaultValue={{ label: "This Year" }}
              isSearchable={false}
              styles={customStyles}
              onChange={(e) => handleSelectDateFilter(e)}
            />
          </div>
          <div className="search-input">
            <Select
              options={optionsStatus}
              defaultValue={{ label: "All" }}
              isSearchable={false}
              styles={customStyles}
              onChange={(e) => handleSelectStatus(e)}
            />
          </div>

          <div className="Addbutton-container">
            <button
              className="Add-Milestone"
              onClick={() => handleClickShowDrawer()}
            >
              <AiOutlinePlus className="plus-icon" /> Add Meeting
            </button>
          </div>
        </div>
        <div>
          {loading ? (
            <div className="spinning-star1">
              <Spin size="large" />
            </div>
          ) : (
            <table className="table-container">
              <thead>
                <tr className="tablehead-row">
                  <th className="small-tablehead">Title</th>
                  <th className="mid-tablehead">Next Meeting Date</th>
                  <th className="mid-tablehead">Description</th>
                  <th className="option-tablehead">{""}</th>
                </tr>
              </thead>
              {meetingList?.length > 0 ? (
                <tbody ref={editRef1}>
                  {meetingList?.map((rowdata) => (
                    <tr className="tabledatarow-maintable" key={rowdata._id}>
                      <td className="maincolumn-firstrowcolumn">
                        {rowdata.title?.length <= 10
                          ? rowdata.title
                          : `${rowdata?.title?.slice(0, 10)}...` ?? "-"}
                      </td>
                      <td>{showDayMonthYear(rowdata?.nextMeeting) ?? "-"}</td>

                      <td>{getStatusColor(rowdata.status) ?? "-"}</td>
                      <td
                        className="table-name"
                        onClick={(e) => handleEditClick(e, rowdata._id)}
                      >
                        <div className="tax-add-edit">
                          <span className="ml-5">
                            <div className="dropdown-head">
                              <div className="dropbtn-head">
                                <img
                                  src={layer}
                                  alt="profile"
                                  className="menu-bar-icon"
                                />
                              </div>
                              {editVisible === rowdata._id && (
                                <div className="dropdown-content-heads">
                                  <div>
                                    <p
                                      className="action-menu-bar"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleEdit(rowdata._id);
                                      }}
                                    >
                                      Edit
                                    </p>
                                  </div>
                                  <div
                                    onClick={() => updateMeetingStatus(rowdata)}
                                    className="d-flex_al_c action-menu-bar on_hover"
                                  >
                                    {/* <img alt="update status" style={{width: "15px",marginRight: "10px",}} src={updateicon}></img> */}
                                    <div className="light">
                                      <div className="m_0 p_0">
                                        Update Status
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="empty-message">
                  <tr>
                    <td colSpan="6">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          )}

          {meetingList?.length > 0 && (
            <TableField
              data={meetingList}
              countprev={countprev}
              countnext={countnext}
              currentPage={currentPage}
              ClickPrev={() => {
                previous();
              }}
              ClickNext={() => next(currentPage)}
              total={total}
              overAll={totalDoc ?? 0}
              pagelimit={queryStatus.limit}
              pagelimitHandle={pageLimitOnchange}
            />
          )}

          <Drawer
            placement="right"
            open={visibleAddMeeting}
            onClose={(e) => closeDrawer(e)}
            className="Milestones-main-drawer"
          >
            <div className="Milestone-drawer-container">
              <header>
                <h5 className="add-tax">Next Meeting</h5>
                {/* 
              <GrClose className="MilestoneClose-icon" />
             */}
                <span
                  onClick={(e) => closeDrawer(e)}
                  className="MilestoneClose-icon"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={close}
                    className="MilestoneClose-img"
                    alt="close"
                  ></img>
                </span>
              </header>
              <form>
                <div className="tax-label">
                  <label className="mb_15 ">
                    Title<span className="star">*</span>
                  </label>
                  <div className="tax-label-input">
                    <input
                      // disabled="true"
                      type="text"
                      name="tax_name"
                      placeholder="Enter a Title"
                      autoComplete="off"
                      value={nextMeetingAdd?.title}
                      onChange={(e) => statusTitleChange(e)}
                    />
                  </div>
                </div>
                <div className="tax-label">
                  <label>Description</label>
                  <div className="tax-label-input">
                    <textarea
                      placeholder="Add a text here.."
                      name="description"
                      autoComplete="off"
                      value={nextMeetingAdd?.description}
                      onChange={(e) => meetingDescriptionAdd(e)}
                    >
                      {" "}
                    </textarea>
                  </div>
                </div>
                <div className="tax-label mb_24">
                  <label>
                    Next Meeting Date<span className="star">*</span>
                  </label>
                  <DatePicker
                    value={
                      nextMeetingAdd.nextMeeting !== ""
                        ? moment(nextMeetingAdd.nextMeetin)
                        : ""
                    }
                    onChange={(e) => handleDateChange(e)}
                    style={{ width: "100%" }}
                    id="date-start"
                  />
                </div>
                <div>
                  <TopHeader
                    type="meeting"
                    backClick={(e) => closeDrawer(e)}
                    classname="p_0"
                    submit={(e) => {
                      createOthers(e, meetingId);
                    }}
                    // submit={createOthers}

                    // id={projectId}
                    spin={btnLoader}
                  />
                </div>
              </form>
            </div>
          </Drawer>
          <Drawer
            placement="right"
            open={openMeetingStatusDrawer}
            onClose={closeDrawer}
            className="tax-main-drawer"
          >
            <UpdateProjectComponent
              statusData={meetingStatusOptions}
              from={"meeting"}
              update={MeetingStatus}
              id={meetingId}
              onClose={closeDrawer}
            ></UpdateProjectComponent>
          </Drawer>
        </div>
      </div>
    </div>
  );
}

export default MeetingList;
