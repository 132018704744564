import React, { Component } from "react";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { Link } from "react-router-dom";
import TableField from "../../components/ui/tableField";

import {
  Table,
  Input,
  Spin,
  Button,
  Card,
  message,
  Modal,
  Form,
  DatePicker,
  TimePicker,
  Tooltip,
  Popover,
  Menu,
  Dropdown,
  Typography,
  Select,
  Empty
} from "antd";
import { STORAGE, UPDATE, APPROVAL } from "../../constants/roleStore";
import layer from "../../assets/Layer 2.svg";

import { MoreOutlined } from "@ant-design/icons";

import TopHeader from "../../components/ui/TopHeader";
import Filter from "../../components/ui/filter";
import InputItem from "../../components/ui/InputItem";
import DateItem from '../../components/ui/DateItem';

import "antd/dist/antd.css";
import "../../stylesheets/styles.css";
import {
  showError,
  isErrorTxt,
  getIsoString,
  showTime,
  showLongDateFormat,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  permissionStatus
} from "../../constants/Utils";
import Calendar from "../../assets/fi-rr-calendar (2).png";

import moment from "moment";
const { TextArea } = Input;
const { Option } = Select;

export default class Permission extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      search: "",
      currentPage: 1,
      modal1Visible: false,
      modal3Visible: false,
      status: "",
      loading: true,
      data: [],
      total: "",
      overall: "",
      pageSize: 20,
      searchInput: "",
      searchOption: "",
      permissionid: "",
      textarea: "",
      from: "",
      to: "",
      date: "",
      fromTime: "",
      toTime: "",
      reason: "",
      Tableloading: true,
      dateRange: null,
      statusData: [
        { value: "Accepted", key: "accepted" },
        { value: "Requested", key: "requested" },
        { value: "Cancelled", key: "cancelled" },
        { value: "Recalled", key: "recalled" },
        { value: "Rejected", key: "rejected" },
      ],
      searchData: [
        { value: "Name", key: "name" },
        { value: "Emp ID", key: "emp_id" },
      ],
      app_name: "",
      app_type: "",
      queryStatus: {},
      locationKeys: [],
      isBack: false,
      dropdown_menu: false,
      list_index: '',
      permission: '',
      approval: false,
      update: false,
    };
    this.wrapperRef = React.createRef();

  }
  componentDidMount = () => {
    // this.isComponentMounted = true;
    let name = "";

    let storage = JSON.parse( STORAGE.view );
    name = storage?.filter( ( app ) => app?.name === "Time Off" );

    let value = name;

    value?.filter( ( app => {
      let names = app?.sub_roles?.filter( ( role ) => role.name === 'Permissions' );

      let permission = names && names.map( ( el ) => (

        this.setState( {
          approval: el?.permissions?.approval,
          update: el?.permissions?.update,

        } )
      ) );
      this.setState( {
        app_name: names,
        permission: permission

      } );
    } ) );


    this.setState( {

      app_type: STORAGE.type,
    } );
    this.reload();
    this.setQueryStatus();
    window.addEventListener( "popstate", this.onPopstate );
    document.addEventListener("click", this.clickOutside);

  };
  onPopstate = () => {
    const search = this.props.location.search;
    const searchObj = returnSearchObject( search );
    this.state.status = searchObj.status ?? "";
    this.state.from = searchObj.from ?? "";
    this.state.to = searchObj.to ?? "";
    this.state.searchOption = searchObj.type??"";
    this.state.currentPage = parseInt( searchObj.page ) ?? "";
    this.state.pageSize = parseInt( searchObj.limit ) ?? "";

    this.state.searchInput = ( searchObj.value??"" );

    this.setState( { queryStatus: this.getQuerySearch() }, () => {
      this.handleTable();
    } );

  };

  componentWillUnmount () {

    window.removeEventListener( "popstate", this.onPopstate, false );
    document.removeEventListener("click", this.clickOutside); 

  }
  clickOutside = (e) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(e.target)) {
      this.setState({ dropdown_menu: false });
    }
  };
  reload = () => {
    this.props.history.listen( ( location, search ) => {
      this.setState( { queryStatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject( query );
        if ( Object.keys( query ).length === 0 ) {
          this.clearSearch();
        }
      } );
    } );
  };



  getQuerySearch = () => {
    const querysearch = returnSearchObject( this.props.location.search );

    if ( this.state.searchOption !== "" && this.state.searchInput !== "" ) {
      querysearch.type = this.state.searchOption ??"";
      querysearch.value = this.state.searchInput ??"";

      querysearch[ this.state.searchOption??'' ] = this.state.searchInput??"";
    }

    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";
    querysearch.status = this.state.status ?? "";

    querysearch.from = getIsoString( this.state.from ) ?? "";
    querysearch.to = getIsoString( this.state.to ) ?? "";

    return querysearch;
  };

  setQueryStatus = () => {
    let query = this.props.location.search;

    if ( query ) {
      let queryObj = returnSearchObject( query );
      this.setState(
        {
          searchInput: ( queryObj.value ),
          searchOption: queryObj.type,
          status: queryObj.status ?? "",
          from: queryObj.from ?? "",
          to: queryObj.to ?? "",

          currentPage: parseInt( queryObj.page ) ?? "",
          pageSize: parseInt( queryObj.limit ) ?? "",

          queryStatus: returnSearchObject( query ),
        },
        () => {
          pushToFilteredPage( this.props, this.state.queryStatus );
          this.handleTable();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage( this.props, this.state.queryStatus );
          this.handleTable();
        }
      );
    }
  };

  handleFilter = () => {
    let query = this.getQuerySearch();

    if ( this.state.from > this.state.to ) {
      showError( "Select the valid date" );
    } else {
      this.setState(
        {
          queryStatus: query,
        },
        () => {
          pushToFilteredPage( this.props, this.state.queryStatus );
          this.handleTable();
        }
      );
    }
  };

  handleTable = () => {
    APIGATEWAY.get(
      "employees/permissions/" + buildQueryString( this.state.queryStatus ),
      ( response ) => {
        if ( response.success ) {
          this.setState( {
            data: response.data.permissions,
            total: response.data?.permissions?.length,
            loading: false,
            Tableloading: false,
            overall: response?.data?.total,
          } );
        } else {
          this.setState( { loading: true } );
        }
      }
    );
  };

  previous = () => {
    const { currentPage } = this.state;
    this.setState( { currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    } );
  };

  next = ( prev ) => {
    const { currentPage } = this.state;

    this.setState( { currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    } );
  };
  setModal1Visible = ( modal1Visible ) => {
    this.setState( { modal1Visible } );
  };

  setModal3Visible = ( modal3Visible ) => {
    this.setState( { modal3Visible, textarea: "" } );
  };

  handleOnSearchChange = ( value ) => {
    this.setState( { status: value, currentPage: 1 }, () => {
      this.handleFilter();
    } );
  };

  handlePageChange = ( page ) => {
    this.setState( { currentPage: page }, () => {
      this.handleTable();
    } );
  };

  searchInput = ( e ) => {
    this.setState( {
      [ e.target.name ]: e.target.value,
    } );
  };

  searchOption = ( value ) => {
    this.setState( {
      searchOption: value,
    } );
  };

  Apply = () => {
    const { status, from, to, searchInput, searchOption } = this.state;
    if ( status === "" && from === '' && to === "" && ( searchInput === "" || searchOption === "" ) ) {
      showError( 'Please select any one field' );
    } else {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handleFilter();
        }
      );
    }

  };
  clearSearch = () => {
    let self = this;
    self.setState(
      {
        searchInput: "",
        searchOption: "",
        status: "",
        from: "",
        to: "",
        dateRange: null,
        currentPage: 1,
        queryStatus: { page: 1, limit: 20 },
        pageSize: 20,
      },
      () => {
        pushToFilteredPage( this.props, this.state.queryStatus );
        this.handleTable();
      }
    );
  };
  AcceptPermissions = ( id ) => {
    let datas = {
      status: "accepted",
    };
    APIGATEWAY.patch(
      "employees/permissions/accept/" + id,
      datas,
      ( response ) => {
        if ( response.success ) {
          message.success( response.message );
          this.handleTable();
        } else {
          message.error( response.message );
        }
      }
    );
  };

  RejectPermission = ( data ) => {
    this.setModal3Visible( true );
    this.setState( {
      permissionid: data,
    } );
  };
  handleText = ( e ) => {
    this.setState( {
      [ e.target.name ]: e.target.value,
    } );
  };

  SubmitPermissions = ( id ) => {
    const { textarea } = this.state;
    if ( textarea === "" ) {
      showError( "Please enter the reason" );
    } else {
      let data = {
        reason: textarea,
      };
      APIGATEWAY.patch(
        "employees/permissions/reject/" + id,
        data,
        ( response ) => {
          if ( response.success ) {
            message.success( response.message );
            this.handleTable();
            this.setState( {
              textarea: "",
            } );
          } else {
            message.error( response.message );
          }
        }
      );
      this.setModal3Visible( false );
    }
  };

  updateStateValues = ( key, value ) => {
    this.setState(
      {
        [ key ]: value,
      },
      function () {
        if ( value === null ) {
          this.setState( {
            [ key ]: "",
          } );
        }
      }
    );
  };
  // dateChange = (value) => {
  //   this.setState({
  //     from: value[0],
  //     to: value[1]
  //   })
  //   // (moment(value[0].format('YYYY-DD-MM')), "date");
  // }
  onDateRangeChange = ( dateRange ) => {
    if ( dateRange ) {
      this.setState( {
        dateRange: this.returnMomentDateRange( dateRange[ 0 ], dateRange[ 1 ] ),
        from: moment( dateRange[ 0 ] ),
        to: moment( dateRange[ 1 ] ),
      } );
    } else {
      this.setState( {
        dateRange: null,
      } );
    }
  };
  returnMomentDateRange = ( start, finish ) => {
    return [ moment( start, "YYYY-MM-DD" ), moment( finish, "YYYY-MM-DD" ) ];
  };
  handleOnToChange = ( e ) => {
    this.updateStateValues( "to", e );
  };
  handleOnFromChange = ( e ) => {
    this.updateStateValues( "from", e );
  };
  handleOnDateChanges = ( value ) => {
    this.setState(
      {
        date: value,
      },
      function () {
        if ( value === null ) {
          this.setState( {
            date: "",
          } );
        }
      }
    );
  };
  cancelFun = () => {
    this.setModal1Visible( false );
    this.setState( { fromTime: "", toTime: "" } );
  };
  handleChange = ( e ) => {
    this.setState( { [ e.target.name ]: e.target.value } );
  };
  handleOnDateTimeChange = ( e, time ) => {
    // let date = new Date(e);

    let self = this;
    if ( time === "from" ) {
      self.setState(
        {
          fromTime: e,
        },
        function () {
          if ( time === null ) {
            this.setState( { fromTime: "" } );
          }
        }
      );
    } else {
      self.setState(
        {
          toTime: e,
        },
        function () {
          if ( time === null ) {
            this.setState( { toTime: "" } );
          }
        }
      );
    }
  };
  editPermission = ( object ) => {
    this.setModal1Visible( true );
    if ( object ) {
      this.setState( {
        reason: object.reason,
        id: object.permission_id,
        // names: true,
        fromTime: moment( object.hours.from ),
        toTime: moment( object.hours.to ),
        date: moment( object.hours.from ),
      } );
    }
  };
  handlePermission = ( id ) => {
    const { reason, fromTime, toTime, date } = this.state;
    if ( date === "" || date === null ) {
      showError( "Please select the date" );
    }
    if ( fromTime === "" || fromTime === null ) {
      showError( "Please select the from time" );
    } else if ( toTime === "" || toTime === null ) {
      showError( "Please select the to time" );
    } else if ( fromTime > toTime ) {
      showError( "Please select valid time" );
    } else if ( reason === "" || reason === null ) {
      showError( "Please enter the reason" );
    } else {
      let selectedDate = moment( date ).format( "YYYY-MM-DD" );
      let from = moment( fromTime ).format( "HH:mm" );
      let to = moment( toTime ).format( "HH:mm" );
      let data = {
        reason: reason,
        hours: {
          from: moment( selectedDate + " " + from ).toISOString(),
          to: moment( selectedDate + " " + to ).toISOString(),
        },
      };
      APIGATEWAY.patch( "employees/permissions/" + id, data, ( response ) => {
        if ( response.success ) {
          this.setModal1Visible( false );
          message.success( response.message );
          this.handleTable();

          this.setState( {
            fromTime: "",
            toTime: "",
            reason: "",
            date: "",
          } );
        } else {
          message.error( response.message );
        }
      } );
    }
  };

  handleFromChange = ( date, dateString ) => {
    this.setState( { from: date } );
  };

  handleToChange = ( date, dateString ) => {
    this.setState( { to: date } );
  };
  handleActions = ( e, object ) => {
    if ( e === "edit" ) {
      this.editPermission( object );
    }
    if ( e === "reject" ) {
      this.RejectPermission( object.permission_id );
    }
  };

  pageLimitOnchange = ( e ) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  dropdownClick = ( index ) => {
    this.setState( { dropdown_menu: !this.state.dropdown_menu, list_index: index } );
  };
  render () {
    const { date } = this.state;

    const menu = ( object ) => {
      return (
        <Menu
          onClick={( e ) => this.handleActions( e, object )}

        >
          {UPDATE( app_type, app_name, <Menu.Item key="edit">Edit</Menu.Item>, '' )}
          {APPROVAL(
            app_type,
            app_name,
            <Menu.Item key="reject">Reject</Menu.Item>
          )}
        </Menu>
      );
    };
    const { pageSize, currentPage, total, data, app_name, app_type, permission, update, approval } =
      this.state;
    

    let countnext = total;
    let countprev = total - ( total - 1 );
    if ( currentPage > 1 ) {
      countnext = ( currentPage - 1 ) * pageSize + total;
      countprev = countnext - ( total - 1 );
    }



    return (
      <div className="">

        <div className="Tab-wrap">
          <TopHeader title="Permissions" />
          <div style={{padding: '0px 23px 30px 30px'}}>
            <Filter
              searchData={this.state.searchData}
              statusData={this.state.statusData}
              optionChange={( e ) => this.searchOption( e )}
              searchOption={this.state.searchOption}
              typeName="type"
              searchname="searchOption"
              statusName="status"
              statusSelect={this.state.status}
              statusOnChange={( e ) => this.handleOnSearchChange( e )}
              clear={() => this.clearSearch()}
              apply={() => this.Apply()}
              searchInputName="searchInput"
              searchInput={this.state.searchInput}
              inputChange={( e ) => this.searchInput( e )}

              handleOnFromChange={this.handleFromChange}
              handleOnToChange={this.handleToChange}
              fromDate={this.state.from}
              toDate={this.state.to}
              fromName="from"
              toName="to"
            />
            {this.state.Tableloading ? (
              <div className="tableview">
                <Spin />
              </div>
            ) : (

              <div className="table-main">
                {this.state.Tableloading ? (
                  <div className="tableview">
                    <Spin />
                  </div>
                ) : (
                  <div className="table_page_wrap">

                    {data.length > 0 ?
                      ( <table className="table-response">
                        <thead>
                          <tr>
                            <th >S.No</th>
                            <th >Name</th>
                            <th >Requested On</th>

                            <th >Date</th>
                            <th >Time</th>
                            <th >Reason</th>
                            <th >Status</th>
                            <th ></th>


                          </tr>
                        </thead>

                        {data.length > 0 ? (
                              <tbody ref={this.wrapperRef}>
                            {data.map( ( item, r ) => (
                              <tr key={r}>
                                <td className=''>  {( this.state.currentPage - 1 ) * this.state.pageSize + ( r + 1 )}
                                </td>
                                <td className="table-name text-capitalize ">
                                  {isErrorTxt( item?.name ) ? '-' : item?.name}
                                </td>
                                <td className="table-name ">{showLongDateFormat( item.createdAt )}</td>
                                <td className="table-name ">{showLongDateFormat( item.hours.from )}</td>
                                <td className="table-name ">{item.hours && showTime( `${item.hours.from}` ) + " -" + showTime( `${item.hours.to}` )}</td>

                                <td className="table-name text-capitalize">

                                  <div>
                                    {item.reason.length >= 10 ? (
                                      <Popover
                                        overlayStyle={{
                                          maxWidth: "20vw",
                                        }}
                                        content={item.reason}
                                        trigger="click"
                                      >
                                        {item.reason.slice( 0, 13 ) + "....."}
                                      </Popover>
                                    ) : (
                                      item.reason
                                    )}
                                  </div>
                                </td>
                                <td className="table-name text-capitalize font-weight-content">
                                  {permissionStatus( item.status, item?.reject_reason??"" )}
                                  
                                </td>


                                <td className="table-name">

                                  <div

                                    className="flex-content"
                                  >
                                    <span>
                                      {item.status === "requested" ? (
                                        <>
                                          {APPROVAL(
                                            app_type,
                                            app_name,
                                            <Button
                                              className="btn_style"
                                              onClick={() =>
                                                this.AcceptPermissions( item.permission_id )
                                              }
                                            >
                                              Accept
                                            </Button>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>

                                    {app_type && app_type === 'sub_admin' && approval === false && update === false ? ( '' ) : (
                                      <>
                                        <span className="ml-5" onClick={() => this.dropdownClick( r )}>
                                          <div className="dropdown-head">
                                            <div className="dropbtn-head">
                                              <img src={layer} alt="profile" className="menu-bar-icons" />
                                            </div>
                                            {this.state.list_index === r && this.state.dropdown_menu && (

                                              <div className="dropdown-content-heads">
                                                <div>

                                                  {UPDATE(
                                                    app_type,
                                                    app_name,
                                                    <p className="action-menu-bar" onClick={() => this.handleActions( "edit", item )}>
                                                      Edit
                                                    </p>
                                                  )}

                                                  {APPROVAL(
                                                    app_type,
                                                    app_name,
                                                    <p className="action-menu-bar" onClick={() => this.handleActions( "reject", item )}>
                                                      Reject
                                                    </p>
                                                  )}

                                                </div>

                                              </div> )}
                                          </div>
                                        </span>
                                      </>
                                    )}


                                  </div>

                                  {/* </div> */}
                                </td>


                              </tr>
                            ) )}

                          </tbody> ) : ''}
                      </table> ) :
                      ( <table className="table-response">
                        <thead>
                          <tr>
                            <th >S.No</th>
                            <th >Name</th>
                            <th >Requested On</th>

                            <th >Date</th>
                            <th >Time</th>
                            <th >Reason</th>
                            <th >Status</th>
                            <th ></th>


                          </tr>
                        </thead>


                        <tbody className="empty-message">
                          <tr>
                            <td colspan="11">
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </td>

                          </tr>
                        </tbody>



                      </table> )
                    }
                    {data.length > 0 && (
                      <TableField
                        data={data}
                        countprev={countprev}
                        countnext={countnext}
                        currentPage={currentPage}
                        ClickPrev={() => this.previous()}
                        ClickNext={() => this.next( this.state.currentPage )}
                        total={total}
                        overAll={this.state.overall ?? 0}
                        pagelimit={this.state.pageSize}
                        pagelimitHandle={this.pageLimitOnchange}
                      />
                    )}


                  </div>
                )}

              </div>

            )}
          </div>
        </div>


        <Modal
          style={{ top: 50 }}
          open={this.state.modal3Visible}
          footer={null}
          onCancel={() => this.setModal3Visible( false )}
          title="Reject Permissions"
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <div>
              <div>

                <InputItem label='Reason' name='textarea' value={this.state.textarea} star={false} txtarea={true} placeholder="Enter the reason" onChange={( e ) => this.handleText( e )} />

              </div>
              <div className="btn">
                <button
                  className="ad-btn-secondary"
                  onClick={() => this.setModal3Visible( false )}
                >
                  Cancel
                </button>
                <button
                  className="probtn"
                  onClick={( e ) =>
                    this.SubmitPermissions( this.state.permissionid )
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </Modal>

        <Modal
          style={{ top: 50 }}
          title="Permission Update"
          open={this.state.modal1Visible}
          footer={null}
          closable={false}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          />
          <div>
            <div className="mb-4">

              <DateItem label='Date' name='date' star={false} value={this.state.date} placeholder="Date" onChange={( value ) => this.handleOnDateChanges( value )} />

            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ marginBottom: "20px", width: "47%" }}>
                <TimePicker
                  name="fromTime"
                  value={this.state.fromTime}
                  className="onboard_date"
                  style={{ height: "47px" }}
                  format="h:mma"
                  onChange={( e ) => this.handleOnDateTimeChange( e, "from" )}
                  placeholder="FromTime"
                  showTime
                />
              </div>
              <div style={{ marginBottom: "20px", width: "47%" }}>
                <TimePicker
                  name="toTime"
                  value={this.state.toTime}
                  className="onboard_date"
                  style={{ height: "47px" }}
                  format="h:mma"
                  onChange={( e ) => this.handleOnDateTimeChange( e, "to" )}
                  placeholder="ToTime"
                  showTime
                />
              </div>
            </div>
            <div>

              <InputItem label='Reason' name='reason' value={this.state.reason} star={false} txtarea={true} placeholder="Enter the reason" onChange={( e ) => this.handleChange( e )} />
            </div>

            <div className="btn">
              <button
                className="ad-btn-secondary"
                onClick={() => this.cancelFun()}
                style={{ borderRadius: "5px" }}
              >
                Cancel
              </button>

              <button
                className="probtn"
                onClick={() => this.handlePermission( this.state.id )}
                style={{ borderRadius: "5px" }}
              >
                Update
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
