import React from "react";
import SelectIcon from "../../assets/down-arrow-2.png";
import { isErrorTxt } from "../../constants/Utils";
import { Table, Button, Select } from "antd";
const { Option } = Select;
const TableField = ({
  column,
  data,
  countprev,
  countnext,
  currentPage,
  ClickPrev,
  ClickNext,
  total,
  overAll,
  pagelimitHandle,
  pagelimit,
}) => {

  return (
    <>
      <div className={isErrorTxt(column) ? '' : "admin-table"} >
        {isErrorTxt(column) ? '' : (
          <Table
            align="left"
            className="gx-table-responsive"
            columns={column}
            dataSource={data}
            size="middle"
            pagination={false}
          />
        )
        }

        {data.length < 0 ? "" : (
          <div className="page-border">
            <div className="pagination">
              {total > 0 ? (
                <>
                  <span className="pagination-limit">Per Page</span>
                  <div className="filters mr-4 ml-4 page_limit">
                    <Select
                      
                      style={{ width: 100 }}
                      name={pagelimit}
                      className="dropdown-list"
                      
                      onSelect={pagelimitHandle}
                      value={pagelimit}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      autoComplete="off"
                      suffixIcon={
                        <div>
                          <img src={SelectIcon} alt='' width="21px" />
                        </div>
                      }
                    >
            
                      <Option value="10">10</Option>
                      <Option value="20">20</Option>
                      <Option value="30">30</Option>
                      <Option value="40">40</Option>
                      <Option value="50">50</Option>
                    </Select>
                  </div>
                  <div className="counter">
                    <span>
                      {countprev}-{countnext} of {overAll}
                    </span>
                  </div>
                </>
              ) : (
                ""
              )}
              {total > 0 && (
                <>
                  <Button
                    type="button"
                    className="prev"
                    disabled={currentPage === 1 ? true : false}
                    onClick={ClickPrev}
                  >
                    <i className="arrow left"></i>
                  </Button>
                  <Button
                    className="next"
                    disabled={total >= pagelimit && countnext !== overAll ? false : true}
                    onClick={ClickNext}
                  >
                    <i className="arrow right"></i>
                  </Button>
                </>
              )}

            </div>
          </div>
        )}


      </div>
    </>
  );
};

export default TableField;
