const DEVELOPMENT = {

  BASE_URL: "http://18.219.188.42:8000/api/v1/portal/admin/",

  // BASE_URL: "http://192.168.0.109:1601/api/portal/admin/",
  // BASE_URL: "http://localhost:1601/api/portal/admin/",
  // BASE_URL: "http://192.168.0.230:1601/api/portal/admin/",




  S3_UPLOADER: {
    SIGNIN_URL: "http://18.219.188.42:8000/api/v1/s3/sign",
    AWS_BASE_URL: "http://qkdev.s3.amazonaws.com/",
  },
};

const PRODUCTION = {
  BASE_URL: "https://api.quadkast.com/api/v1/portal/admin/",
  S3_UPLOADER: {
    SIGNIN_URL: "https://api.quadkast.com/api/v1/s3/sign",
    AWS_BASE_URL: "https://qklive-panel.s3.amazonaws.com/",
  },
};

export default process.env.REACT_APP_ENV === "production"
  ? PRODUCTION
  : DEVELOPMENT;
