// Old project(30/08/23)




import React, { Component } from "react";

import "../../stylesheets/styles.css";
import {
  Input,
  Select,
  Typography,
  Button,
  Table,
  Card,
  Tabs,
  message,
  Spin,
} from "antd";

import moment from "moment";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import TopHeader from "../../components/ui/TopHeader";

import InputField from "../../components/ui/InputField";

import DatePicker from "../../components/ui/DatePicker";
//Utils
import { showError, isInvalidName } from "../../constants/Utils";

const { Option } = Select;

const { TabPane } = Tabs;
const errorMessage = "Please fill the Details";

export default class ProjectAddEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employedetails: [],
      detailInfos: [{ name: "", url: "" }],
      testingInfos: [{ name: "", url: "" }],
      pcrInfos: [{ billing_days: "", value: "", desc: "" }],
      defaultTechValue: [],
      techValue: [],
      defaultProducts: [],
      defaultPlatformValue: [],
      platformValue: [],
      defaultEmployees: [],
      employees: [],
      reasonType: "",
      activeTab: "1",
      totalDays: "",
      name: "",
      desc: "",
      date_start: "",
      date_close: "",
      timeline_from: "",
      timeline_to: "",
      project_value: "",
      value: "",
      id: "",
      list: [],
      search: "",
      platformList: [],
      techList: [],
      employeeList: [],
      platformid: "",
      client_id: "",
      isFetching: true,
      emp_id: "",
      status: "",
      add_edit_loader: false,
    };
  }
  async componentDidMount() {
    if (this.props.match.params.id) {
      this.setState(
        {
          id: this.props.match.params.id,
        },
        () => {
          this.updateProject(this.state.id);
        }
      );
    }
    this.callClientAPI();
    this.callEmployeAPI();

    this.callPlatformAPI();
    this.callTechnologyAPI();
  }
  //Client-edit api call

  callEmployeAPI = () => {
    let { search, status } = this.state;
    var queryParams = "";
    if (search !== "") {
      queryParams = "?search_term=" + search;
    }
    // if (status) {
    //   queryParams = "?status=active" + status;
    // }

    // APIGATEWAY.get("employees/" + queryParams, (response) => {
    //   if (response.success) {
    //     this.setState({ employeeList: response.data.employees });
    //   }
    // });

    APIGATEWAY.get("employees/all" + queryParams, (response) => {
      if (response.success) {
        this.setState({ employeeList: response.data.employees });
      }
    });
  };
  callClientAPI = async () => {
    let { search } = this.state;
    var queryParams = "";
    if (search !== "") {
      queryParams = "?search_term=" + search;
    }
    APIGATEWAY.get("clients/exists/list" + queryParams, (response) => {
      if (response.success) {
        this.setState({ list: response.data.exists_clients });
      }
    });
  };
  callPlatformAPI = () => {
    let { search } = this.state;
    var queryParams = "";
    if (search !== "") {
      queryParams = "?search_term=" + search;
    }
    APIGATEWAY.get("platforms" + queryParams, (response) => {
      if (response.success) {
        this.setState({ platformList: response.data.platforms });
      }
    });
  };
  callTechnologyAPI = () => {
    let { search } = this.state;
    var queryParams = "";
    if (search !== "") {
      queryParams = "?search_term=" + search;
    }
    APIGATEWAY.get("technologies" + queryParams, (response) => {
      if (response.success) {
        this.setState({ techList: response.data.technologies });
      }
    });
  };

  updateProject = (id) => {
    let username = this.state.id;
    APIGATEWAY.get("projects/" + username, (response) => {
      if (response.success) {
        let data = response.data.project;
        var arrayProduct = data.platforms;
        var additionProducts = [];
        if (arrayProduct.length > 0) {
          for (let i = 0; i < arrayProduct.length; i++) {
            // newProducts = [...newProducts, ...[JSON.stringify(arrayProduct[i])]];
            additionProducts.push(JSON.stringify(arrayProduct[i]));
          }
        } else {
          additionProducts = [];
        }
        this.setState({
          defaultProducts: additionProducts,
        });

        var employeesArr = data.employees;
        var additionalEmploy = [];
        if (employeesArr.length > 0) {
          for (let i = 0; i < employeesArr.length; i++) {
            additionalEmploy.push(JSON.stringify(employeesArr[i]));
          }
        } else {
          additionalEmploy = [];
        }
        this.setState({ defaultEmployees: additionalEmploy });

        var technologyArr = data.technologies;

        var additionalTech = [];
        if (technologyArr.length > 0) {
          for (let i = 0; i < technologyArr.length; i++) {
            additionalTech.push(JSON.stringify(technologyArr[i]));
          }
        } else {
          additionalTech = [];
        }
        this.setState({ defaultTechValue: additionalTech });

        this.setState({
          name: data.name,
          desc: data.desc,
          client_id: data.client_id,
          date_start: data.date.start !== "" ? moment(data.date.start) : "",
          timeline_from:
            data.timeline.from === null ? "" : moment(data.timeline.from),
          timeline_to:
            data.timeline.to === null ? "" : moment(data.timeline.to),
          totalDays: data.timeline.total_days,
          project_value: data.value,
          detailInfos: data.design_links,
          testingInfos: data.testing_links,
          pcrInfos: data.pcr,
          date_close: data.date.close !== "" ? moment(data.date.close) : "",
          isFetching: false,
        });
      } else {
        this.setState({ isFetching: false });
      }
    });
  };

  clientID = (id) => {
    this.setState({ client_id: id });
  };
  employeeId = () => { };
  onSubmit = () => {
    let self = this;
    let {
      totalDays,
      name,
      desc,

      defaultTechValue,
      date_start,
      date_close,
      timeline_from,
      timeline_to,
      project_value,
      activeTab,
      defaultEmployees,
      detailInfos,
      testingInfos,
      pcrInfos,
      client_id,
    } = this.state;
    // platform
    var defProducts = this.state.defaultProducts;
    var upProducts = [];
    if (
      defProducts.length > 0 &&
      Array.isArray(defProducts[defProducts.length - 1])
    ) {
      var arrayProduc =
        this.state.defaultProducts[this.state.defaultProducts.length - 1];
      for (let i = 0; i < arrayProduc.length; i++) {
        upProducts.push(JSON.parse(arrayProduc[i]));
      }
    } else {
      var arrProduct = this.state.defaultProducts;
      for (let i = 0; i < arrProduct.length; i++) {
        upProducts.push(JSON.parse(arrProduct[i]));
      }
    }
    //technology
    let techvalue = [];
    var defTechnology = this.state.defaultTechValue;

    if (
      defTechnology.length > 0 &&
      Array.isArray(defTechnology[defTechnology.length - 1])
    ) {
      var arrayProducts =
        this.state.defaultTechValue[this.state.defaultTechValue.length - 1];
      for (let i = 0; i < arrayProducts.length; i++) {
        techvalue.push(JSON.parse(arrayProducts[i]));
      }
    } else {
      var arrProdu = this.state.defaultTechValue;
      for (let i = 0; i < arrProdu.length; i++) {
        techvalue.push(JSON.parse(arrProdu[i]));
      }
    }
    //employee
    let employeValue = [];
    var defEmploy = this.state.defaultEmployees;

    if (
      defEmploy.length > 0 &&
      Array.isArray(defEmploy[defEmploy.length - 1])
    ) {
      var arrayProduct =
        this.state.defaultEmployees[this.state.defaultEmployees.length - 1];
      for (let i = 0; i < arrayProduct.length; i++) {
        employeValue.push(JSON.parse(arrayProduct[i]));
      }
    } else {
      var arrProd = this.state.defaultEmployees;
      for (let i = 0; i < arrProd.length; i++) {
        employeValue.push(JSON.parse(arrProd[i]));
      }
    }
    if (name === undefined || name.trim() === "") {
      showError("Please enter name");
    } else if (name.length <= 3) {
      showError("Name should contain atleast 3 characters");
    } else if (isInvalidName(name)) {
      showError("Name should contain letters only");
    } else if (upProducts.length === 0) {
      showError("Please Select a Platform");
    } else if (activeTab === "1" && date_start === "") {
      showError("Please enter the start date");
    } else if (activeTab === "1" && client_id === "") {
      showError("Please choose a Existing client");
    } else {
      let data = {
        name: name,
        desc: desc,
        client_id: client_id,
        platforms: upProducts,
        technologies: techvalue,
        date: {
          start: date_start ? date_start.toISOString() : "",
        },
        timeline: {
          from: timeline_from ? timeline_from.toISOString() : "",
          to: timeline_to ? timeline_to.toISOString() : "",
          total_days: totalDays,
        },
        value: project_value,
        employees: employeValue,
        design_links: detailInfos,
        testing_links: testingInfos ? testingInfos : "",
        pcr: pcrInfos,
      };
      this.setState({ add_edit_loader: true });
      if (this.state.id) {
        let username = this.state.id;
        APIGATEWAY.patch("projects/" + username, data, (response) => {
          if (response.success) {
            this.setState({ add_edit_loader: false });
            message.success(response.message);
            this.props.history.push("/projects");
          } else {
            message.error(response.message);
          }
        });
      } else {
        APIGATEWAY.post("projects", data, (response) => {
          if (response.success) {
            this.setState({ add_edit_loader: false });
            message.success(response.message);
            this.props.history.push("/projects");
          } else {
            message.error(response.message);
          }
        });
      }
    }
  };
  // Date handler
  handleOnDateChange = (date, dateString, name) => {
    if (dateString === "")
      this.setState({
        [name]: dateString,
      });
    else
      this.setState({
        [name]: moment(dateString),
      });
  };

  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  handleOnStartdateChange = (e) => {
    this.updateStateValues("start_date", e);
  };

  handleOnInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleOnDetailInfoChange = (event, i) => {
    let { activeTab } = this.state;
    if (activeTab === "3") {
      let designLink = [...this.state.detailInfos];
      let detailItem = designLink[i];
      detailItem[event.target.name] = event.target.value;
      this.setState({
        detailInfos: designLink,
      });
    }
    if (activeTab === "4") {
      let testingLink = [...this.state.testingInfos];
      let testingItem = testingLink[i];
      testingItem[event.target.name] = event.target.value;
      this.setState({
        testingInfos: testingLink,
      });
    }
    if (activeTab === "5") {
      let pcrTab = [...this.state.pcrInfos];
      let pcrItem = pcrTab[i];
      pcrItem[event.target.name] = event.target.value;
      this.setState({
        pcrInfos: pcrTab,
      });
    }
  };

  //Product Handler
  addProduct = () => {
    let { activeTab } = this.state;
    const notEmpty = this.checkEmptyProduct();
    let dataObj = {
      name: "",
      url: "",
    };
    let data = {
      billing_days: "",
      value: "",
      desc: "",
    };
    if (!notEmpty && activeTab === "3") {
      this.setState({
        detailInfos: [...this.state.detailInfos, dataObj],
      });
    } else if (!notEmpty && activeTab === "4") {
      this.setState({
        testingInfos: [...this.state.testingInfos, dataObj],
      });
    } else if (!notEmpty && activeTab === "5") {
      this.setState({
        pcrInfos: [...this.state.pcrInfos, data],
      });
    } else {
      showError(errorMessage);
    }
  };
  checkEmptyProduct = () => {
    let result;
    let { activeTab } = this.state;
    if (activeTab === "3") {
      let detailArr = this.state.detailInfos;
      for (let i = 0; i < detailArr.length; i++) {
        let detailItem = detailArr[i];
        if (detailItem.name === "") {
          result = true;
        } else if (detailItem.url === "") {
          result = true;
        }
      }
    } else if (activeTab === "4") {
      let testingArr = this.state.testingInfos;
      for (let i = 0; i < testingArr.length; i++) {
        let testingItem = testingArr[i];
        if (testingItem.name === "") {
          result = true;
        } else if (testingItem.url === "") {
          result = true;
        }
      }
    } else if (activeTab === "5") {
      let pcrArr = this.state.pcrInfos;
      for (let i = 0; i < pcrArr.length; i++) {
        let pcrItem = pcrArr[i];
        if (pcrItem.billing_days === "") {
          result = true;
        } else if (pcrItem.value === "") {
          result = true;
        } else if (pcrItem.desc === "") {
          result = true;
        }
      }
    }
    return result;
  };

  handleOnNumberInputChange = (event) => {
    var PHONE_REGEX = /^\d+$/;
    if (event.target.value === "" || PHONE_REGEX.test(event.target.value))
      this.setState({ [event.target.name]: event.target.value });
  };
  handleClick = () => {
    this.props.history.push("/projects");
  };
  render() {
    let {
      defaultTechValue,
      activeTab,
      totalDays,
      name,
      date_start,
      date_close,
      timeline_from,
      timeline_to,
      project_value,
      defaultEmployees,
      list,
      platformList,
      techList,
      employeeList,
      id,
      defaultProducts,
    } = this.state;

    let detail = [];
    let testing = [];
    let pcr = [];
    let state = this.state;

    let columns = [
      {
        title: "Name *",
        render: (text, record) => (
          <Input
            name="name"
            value={record.name}
            placeholder="ENTER NAME"
            className="onboard-date"
            style={{ height: 47 }}
            onChange={(event) =>
              this.handleOnDetailInfoChange(event, record.sno - 1)
            }
          />
        ),
      },
      {
        title: "URL *",
        render: (text, record) => (
          <Input
            name="url"
            value={record.url}
            placeholder="Please enter the url"
            className="onboard-date"
            style={{ height: 47 }}
            onChange={(event) =>
              this.handleOnDetailInfoChange(event, record.sno - 1)
            }
          />
        ),
      },
    ];
    let column = [
      {
        title: "Billing days *",
        render: (text, record) => (
          <Input
            name="billing_days"
            value={record.billing_days}
            placeholder="Please enter the billing days"
            className="onboard-date"
            style={{ height: 47 }}
            onChange={(event) =>
              this.handleOnDetailInfoChange(event, record.sno - 1)
            }
          />
        ),
      },
      {
        title: "Value *",
        render: (text, record) => (
          <Input
            name="value"
            className="onboard-date"
            style={{ height: 47 }}
            value={record.value}
            placeholder="Please enter the value"
            onChange={(event) =>
              this.handleOnDetailInfoChange(event, record.sno - 1)
            }
          />
        ),
      },
      {
        title: "Description *",
        render: (text, record) => (
          <Input
            name="desc"
            value={record.desc}
            className="onboard-date"
            style={{ height: 47 }}
            placeholder="Please enter the description"
            onChange={(event) =>
              this.handleOnDetailInfoChange(event, record.sno - 1)
            }
          />
        ),
      },
    ];
    if (activeTab === "3") {
      let { detailInfos } = this.state;
      for (let i = 0; i < detailInfos.length; i++) {
        let product = detailInfos[i];
        let dataObj = {
          sno: i + 1,
          name: product.name,
          url: product.url,
        };
        detail.push(dataObj);
      }
    }
    if (activeTab === "4") {
      let { testingInfos } = this.state;
      for (let i = 0; i < testingInfos.length; i++) {
        let testingitem = testingInfos[i];
        let data = {
          sno: i + 1,
          name: testingitem.name,
          url: testingitem.url,
        };
        testing.push(data);
      }
    }
    if (activeTab === "5") {
      let { pcrInfos } = this.state;

      for (let i = 0; i < pcrInfos.length; i++) {
        let pcrItem = pcrInfos[i];
        let data = {
          sno: i + 1,
          billing_days: pcrItem.billing_days,
          value: pcrItem.value,
          desc: pcrItem.desc,
        };
        pcr.push(data);
      }
    }

    return (
      <div>
        <TopHeader
          title="Projects"
          backClick={this.handleClick}
          submit={this.onSubmit}
          id={id}
          spin={this.state.add_edit_loader}
        />
        {this.state.isFetching && this.state.id  ? ( <div className="spinning-star">
        <Spin size="large" />
      </div>) : (

          <div className="main-content">
            <div className="sm__grid--col-4">
              <div className="form_inputs">
                <p className="onboard_input_name">Project Name</p>
                <Input
                  name="name"
                  value={name}
                  placeholder="Enter the Project Name"
                  onChange={this.handleOnInputChange}
                  className="onboard-date"
                  style={{ height: 47, width: 400 }}
                />
              </div>
              <div className="form_inputs">
                <p className="onboard_input_name">Description</p>

                <Input
                  name="desc"
                  value={this.state.desc}
                  label="Description"
                  placeholder="Enter the Description"
                  onChange={this.handleOnInputChange}
                  className="onboard-date"
                  style={{ height: 47, width: 400 }}
                />
              </div>
              <div className="form_inputs">
                <div className="onboard_input_name mb-3">Platforms</div>
                <Select
                  mode="multiple"
                  style={{ width: "400px" }}
                  showArrow
                  placeholder="Please Select product categories"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  defaultValue={defaultProducts}
                  onChange={(value) => {
                    this.setState({
                      defaultProducts: [...defaultProducts, ...[value]],
                    });
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  showSearch
                  className="blood"
                >
                  {platformList.map((product, i) => (
                    <Option
                      value={JSON.stringify({
                        platform_id: product.platform_id,
                        name: product.name,
                      })}
                      key={product.platform_id}
                      className="text-c"
                    >
                      {product.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="sm__grid--col-4 ">
              <div className="field-holder">
                <div className="title-wrapper">Technologies </div>
                <Select
                  style={{ width: "400px" }}
                  className="blood"
                  mode="multiple"
                  placeholder="Please choose a Technology"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  defaultValue={defaultTechValue}
                  onChange={(value) => {
                    this.setState({
                      defaultTechValue: [...defaultTechValue, ...[value]],
                    });
                  }}
                >
                  {techList.map((tech, i) => (
                    <Option
                      key={i}
                      value={JSON.stringify({
                        tech_id: tech.tech_id,
                        name: tech.name,
                      })}
                    >
                      {tech.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <Tabs
              activeKey={activeTab}
              onChange={(activeKey) => this.setState({ activeTab: activeKey })}
            >
              <TabPane tab="Project Details" key="1">
                <div>
                  <div className="flex mb-20">
                    <Typography.Title level={5}>Date</Typography.Title>
                  </div>

                  <div className="sm__grid--col-4">
                    <DatePicker
                      name="date_start"
                      label="Start"
                      placeholder="Selecte Start date"
                      value={date_start}
                      className="commonDatepicker"
                      handleChange={this.handleOnDateChange}
                    />
                  </div>
                </div>
                <div className="mt-20 ">
                  <div>
                    <Typography.Title className="mb-20" level={5}>
                      TimeLine
                    </Typography.Title>
                    <div className="sm__grid--col-4">
                      <div>
                        <p style={{ margin: "0 0 5px 0" }}>From</p>
                        <DatePicker
                          name="timeline_from"
                          value={timeline_from}
                          placeholder="Select From date"
                          className="commonDatepicker"
                          // getPopupContainer={trigger => trigger.parentNode}
                          inputReadOnly
                          handleChange={this.handleOnDateChange}
                        />
                      </div>
                      <div>
                        <p style={{ margin: "0 0 5px 0" }}>To</p>
                        <DatePicker
                          name="timeline_to"
                          value={timeline_to}
                          placeholder="Selecte To date"
                          inputReadOnly
                          handleChange={this.handleOnDateChange}
                          className="commonDatepicker"
                          getPopupContainer={(trigger) => trigger.parentNode}
                        />
                      </div>
                      <div>
                        <p style={{ margin: "0 0 5px 0" }}>Total Days</p>
                        <Input
                          name="totalDays"
                          placeholder="Selecte Total days"
                          value={totalDays}
                          onChange={this.handleOnNumberInputChange}
                          className="total-days mt-2"
                          style={{ width: "350px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Typography.Title className=" mt-20" level={5}>
                  Other Information
                </Typography.Title>
                <div className="sm__grid--col-4">
                  <div className="field-holder">
                    <div className="title-wrapper">Existing Clients</div>
                    <Select
                      style={{ width: "350px" }}
                      className="blood"
                      showSearch
                      placeholder="Select Client"
                      onChange={(value) => this.clientID(value)}
                      value={this.state.client_id || undefined}
                      optionFilterProp="children"
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {list.map((item, i) => (
                        <Option key={i} value={item.client_id}>
                          {item.name.first_name + " " + item.name.last_name}
                        </Option>
                      ))}
                    </Select>

                  </div>
                  <div className="field-holder">
                    <div className="title-wrapper">Employee</div>

                    <Select
                      style={{ width: "350px" }}
                      className="blood"
                      mode="multiple"
                      placeholder="Select Employee"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      showSearch
                      defaultValue={defaultEmployees || undefined}
                      onChange={(value) =>
                        this.setState({
                          defaultEmployees: [...defaultEmployees, value],
                        })
                      }
                    >
                      {employeeList.map((employes, i) => (
                        <Option
                          key={i}
                          value={JSON.stringify({
                            emp_id: employes.emp_id,
                            name: employes.name,
                          })}
                        >
                          {employes.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="field-holder">
                    <div className="title-wrapper"> Project Value</div>

                    <Input
                      name="project_value"
                      value={project_value}
                      placeholder="Enter the value"
                      onChange={this.handleOnNumberInputChange}
                      className="total-days"
                      style={{ width: 350 }}
                    />
                  </div>
                </div>
                <div className="sm__grid--col-4 mb-50 "></div>
              </TabPane>
              <TabPane
                tab="Document Details"
                key="2"
                activeTab={activeTab}
              ></TabPane>
              <TabPane tab="Design Links" key="3" activeTab={activeTab}>
                <div className="flex-rw-e mb-20">
                  <button className="probtn" onClick={this.addProduct}>
                    Add Items
                  </button>
                </div>
                <div className="sm__grid--col-4">
                  <Table
                    columns={columns}
                    dataSource={detail}
                    pagination={false}
                  />
                </div>
              </TabPane>
              <TabPane tab="Testing Links" key="4" activeTab={activeTab}>
                <div className="flex-rw-e mb-20">
                  <button className="probtn" onClick={this.addProduct}>
                    Add Items
                  </button>
                </div>
                <div className="sm__grid--col-4">
                  <Table
                    columns={columns}
                    dataSource={testing}
                    pagination={false}
                  />
                </div>
              </TabPane>
              <TabPane tab="PCR" key="5" activeTab={activeTab}>
                <div className="flex-rw-e mb-20">
                  <button className="probtn" onClick={this.addProduct}>
                    Add Items
                  </button>
                </div>
                <div className="sm__grid--col-4">
                  <Table columns={column} dataSource={pcr} pagination={false} />
                </div>
              </TabPane>
            </Tabs>
          </div>
        )}

      </div>
    )
      
    
  }
}
