import React, { Component } from "react";
import { Link } from "react-router-dom";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { message, Drawer, Space, Spin, Empty } from "antd";
import layer from "../../assets/Layer 2.svg";

import TableField from "../../components/ui/tableField";
import { STORAGE, UPDATE, APPROVAL, DELETE } from "../../constants/roleStore";
import InputItem from "../../components/ui/InputItem";
import TopHeader from "../../components/ui/TopHeader";
import cookie from "react-cookies";
import { Spinner } from "reactstrap";
import "../../stylesheets/styles.css";
import ModalPopUp from "../../components/ui/modalpop";

import {
  showError,
  showSuccess,
  isInvalidEmail,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  checkNameLength,
  isErrorTxt,
  checkTxtLength,
  capitalize,
} from "../../constants/Utils";
import { VENDORSACCESS } from "../../components/ui/constants";

class Vendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemData: [],
      name: "",
      email: "",
      vendor_id: "",
      phone: "",
      address: "",
      vendorId: "",
      currentPage: 1,
      total: "",
      openDrawer: false,
      overallData: [],
      status: "",
      searchInput: "",
      searchOption: "",
      edit: false,
      loading: true,
      Tableloading: false,
      admin_app: [],
      app_name: "",
      app_type: "",
      queryStatus: {},
      totalDoc: 0,
      pageSize: 20,
      delete_name: "",
      id: "",
      deleteVendor: false,
      add_edit_spin: false,
      dropdown_menu: false,
      list_index: "",
      update: false,
      deleted: false,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    this.setQueryStatus();
    let view = localStorage.getItem("empr_app");
    let type = cookie.load("empr_type");

    let name = "";

    let storage = JSON.parse(STORAGE.view);
    name = storage?.filter((app) => app?.name === "Settings");

    let value = name;
    value?.filter((app) => {
      let names = app?.sub_roles?.filter((role) => role.name === "Vendors");
      names &&
        names.map((el) =>
          this.setState({
            update: el?.permissions?.update,
            deleted: el?.permissions?.delete,
          })
        );
      this.setState({
        app_name: names,
      });
    });

    this.setState({
      app_type: STORAGE.type,
    });

    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);

    this.reloadPage();
  }

  onPopstate = () => {
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";
    this.state.queryStatus = returnSearchObject(search);

    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      this.getVendors();
    });
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);
  }
  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };
  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";
    return querysearch;
  };
  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.setQueryStatus();
        }
      });
    });
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",
          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.getVendors();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.getVendors();
        }
      );
    }
  };

  handleFilter = () => {
    let { currentPage, status, search, searchInput } = this.state;

    let query = this.getQuerySearch();

    this.setState(
      {
        queryStatus: query,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.getVendors();
      }
    );
  };

  getVendors = () => {
    APIGATEWAY.get(
      `vendors` + buildQueryString(this.state.queryStatus),
      (response) => {
        let data = response.data.vendor;
        let total = response.data?.vendor?.length;
        if (response.success) {
          this.setState({
            itemData: data,
            total,
            loading: false,
            Tableloading: false,
            totalDoc: response?.data?.total,
          });
        } else {
          this.setState({ loading: true });
        }
      }
    );
  };

  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };
  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleOnNumberInputChange = (event) => {
    let REGEX = /^\d+$/;
    if (event.target.value === "" || REGEX.test(event.target.value))
      this.setState({ [event.target.name]: event.target.value });
  };
  next = (prev) => {
    const { currentPage } = this.state;

    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  addVendor = () => {
    this.setState(
      {
        vendor_id: "",
        name: "",
        email: "",
        address: "",
        phone: "",
        edit: false,
      },
      () => {
        this.setState({ openDrawer: true });
      }
    );
  };
  editVendorDetail = (vendor_id) => {
    APIGATEWAY.get("vendors/" + vendor_id, (response) => {
      let data = response.data.vendor;

      this.setState({
        vendor_id: vendor_id,
        name: data.name,
        email: data.email,
        phone: data.phone.national_number,
        address: data.address,
        openDrawer: true,
        edit: true,
      });
    });
  };

  editVendor = () => {
    let { name, email, phone, address, vendor_id } = this.state;

    let data = {
      name,
      email,
      phone: { country_code: "+91", national_number: phone },
      address,
    };
    this.setState({ add_edit_spin: true });
    APIGATEWAY.patch(`vendors/${vendor_id}`, data, (response) => {
      if (response.success) {
        showSuccess(response.message);
        this.setState({
          name: "",
          email: "",
          phone: "",
          address: "",
          openDrawer: false,
          edit: false,
          vendor_id: "",
          add_edit_spin: false,
        });
        this.getVendors();
      } else {
        showError("Could not updated");
      }
    });
  };
  addVendorDetail = () => {
    var self = this;
    let { name, email, address, phone } = self.state;

    if (isErrorTxt(name)) {
      showError("Please enter business name");
    } else if (checkNameLength(name)) {
      showError("Business Name should contain atleast 3 characters");
    } else if (isErrorTxt(phone)) {
      showError("Please enter phone number");
    } else if (checkTxtLength(phone, 10)) {
      showError("Phone Number should contain atleast 10 digits");
    } else if (isErrorTxt(email)) {
      showError("Please enter email");
    } else if (isInvalidEmail(email)) {
      showError("Please enter valid email address");
    } else if (isErrorTxt(address)) {
      showError("Please enter address");
    } else {
      let dataToSend = {
        name,
        email,
        address,
        phone: { country_code: "+91", national_number: phone },
      };
      this.setState({ add_edit_spin: true });
      APIGATEWAY.post("vendors", dataToSend, (response) => {
        if (response.success) {
          showSuccess(response.message);
          this.setState({
            openDrawer: false,
            name: "",
            email: "",
            phone: "",
            address: "",
            add_edit_spin: false,
          });
          this.getVendors();
        } else {
          message.error(response.message);
        }
      });
    }
  };
  getVendorDetail = (value) => {
    this.setState({
      edit: false,
      vendorId: value.expense_id,
      openDrawer: true,
    });
  };

  deleteVendor = (id, object) => {
    this.setState({
      deleteVendor: true,
      delete_name: object?.name ?? "- ",
      id: id,
    });
  };
  cancelDelete = () => {
    this.setState({ deleteVendor: false, delete_name: "", id: "" });
  };
  submitDelete = () => {
    let self = this;
    let data = {};
    APIGATEWAY.delete("vendors/" + this.state.id, data, (response) => {
      if (response.success) {
        message.success(response.message);

        this.getVendors();
        this.setState({ deleteVendor: false, delete_name: "", id: "" });
      }
    });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getVendors();
    });
  };

  goBack = () => {
    this.props.history.push("/employees");
  };

  closeDrawer = () => {
    this.setState({ openDrawer: false, vendorId: "" });
  };

  editItem = () => {
    this.closeDrawer();
    this.getItems();
  };

  clear = () => {
    this.setState({ status: "", searchInput: "", searchOption: "" }, () => {
      this.getItems();
    });
  };

  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      list_index: index,
    });
  };

  render() {
    let {
      itemData,
      currentPage,
      total,
      openDrawer,
      name,
      address,
      phone,
      email,
      admin_app,
      app_name,
      app_type,
      pageSize,
      vendor_id,
      add_edit_spin,
      dropdown_menu,
      list_index,
    } = this.state;

    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }

    return (
      <>
        {this.state.loading ? (
          <div className="spinning-star">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <>
              <TopHeader
                title="Vendors"
                vendors={this.addVendor}
                type={app_type}
                permission={app_name}
              />
              <div style={{ padding: "0px 23px 30px 30px" }}>
                <div style={{ "margin-bottom": "100px" }}>
                  <div className="table_page_wrap">
                    {itemData.length > 0 ? (
                      <table className="table-response">
                        <thead>
                          <tr>
                            <th className="">S.No</th>
                            <th className="">Business Name</th>
                            <th className="">Address</th>
                            <th className="">Email</th>
                            <th className="">Phone</th>

                            <th></th>
                          </tr>
                        </thead>

                        {itemData.length > 0 ? (
                          <tbody ref={this.wrapperRef}>
                            {itemData.map((item, i) => (
                              <tr key={i}>
                                <td className="">
                                  {" "}
                                  {(this.state.currentPage - 1) * 10 + (i + 1)}
                                </td>

                                <td className="table-name text-capitalize">
                                  {isErrorTxt(item?.name) ? "-" : item?.name}
                                </td>
                                <td className="">
                                  {isErrorTxt(item.address)
                                    ? "-"
                                    : capitalize(item.address)}
                                </td>
                                <td className="">
                                  {isErrorTxt(item.email)
                                    ? "-"
                                    : item.email ?? ""}
                                </td>
                                <td className="">
                                  {isErrorTxt(item.phone?.national_number)
                                    ? "-"
                                    : item.phone?.national_number ?? ""}
                                </td>
                                {(VENDORSACCESS.update ||
                                  VENDORSACCESS.delete) && (
                                  <td
                                    className="table-action"
                                    onClick={() => this.dropdownClick(i)}
                                  >
                                    <div className="tax-add-edit">
                                      <span className="ml-5">
                                        <div className="dropdown-head">
                                          <div className="dropbtn-head">
                                            <img
                                              src={layer}
                                              alt="profile"
                                              className="menu-bar-icon"
                                            />
                                          </div>
                                          {list_index === i &&
                                            this.state.dropdown_menu && (
                                              <div className="dropdown-content-heads">
                                                <div>
                                                  {VENDORSACCESS.update && (
                                                    <p
                                                      className="action-menu-bar"
                                                      onClick={() =>
                                                        this.editVendorDetail(
                                                          item.vendor_id
                                                        )
                                                      }
                                                    >
                                                      Edit
                                                    </p>
                                                  )}

                                                  {VENDORSACCESS.delete && (
                                                    <p
                                                      className="action-menu-bar"
                                                      onClick={() =>
                                                        this.deleteVendor(
                                                          item.vendor_id,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Delete
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      </span>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          ""
                        )}
                      </table>
                    ) : (
                      <table className="table-response">
                        <thead>
                          <tr>
                            <th className="">S.No</th>
                            <th className="">Business Name</th>
                            <th className="">Address</th>
                            <th className="">Email</th>
                            <th className="">Phone</th>

                            <th></th>
                          </tr>
                        </thead>

                        <tbody className="empty-message">
                          <tr>
                            <td colspan="7">
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                    {itemData.length > 0 && (
                      <TableField
                        data={itemData}
                        countprev={countprev}
                        countnext={countnext}
                        currentPage={currentPage}
                        ClickPrev={() => this.previous()}
                        ClickNext={() => this.next(this.state.currentPage)}
                        total={total}
                        overAll={this.state.totalDoc ?? 0}
                        pagelimit={this.state.pageSize}
                        pagelimitHandle={this.pageLimitOnchange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>

            <Space>
              <Drawer
                placement="right"
                open={openDrawer}
                onClose={() => this.closeDrawer()}
                keyboard={true}
                autoFocus={true}
              >
                <div>
                  <div className="drawerTitle">
                    <div className="dec-title">Vendor</div>
                    <div>
                      <button
                        className="dec-btn-secondary"
                        onClick={() => this.closeDrawer()}
                      >
                        Back
                      </button>

                      <button
                        className="dec-btn"
                        onClick={
                          this.state.vendor_id
                            ? () => this.editVendor()
                            : () => this.addVendorDetail()
                        }
                        checked={add_edit_spin}
                      >
                        {add_edit_spin ? (
                          <div className="addEdit_loader">
                            <Spinner children={false} />
                          </div>
                        ) : (
                          <div>{vendor_id ? "Update" : " Save"}</div>
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="item_content">
                    <div>
                      <InputItem
                        label="Business Name"
                        name="name"
                        value={name}
                        star={true}
                        placeholder="Enter the nam"
                        onChange={this.handleInputChange}
                      />
                    </div>

                    <div>
                      <InputItem
                        label="Phone"
                        name="phone"
                        value={phone}
                        star={false}
                        placeholder="Enter the phonenumber"
                        onChange={(e) => this.handleOnNumberInputChange(e)}
                      />
                    </div>

                    <div>
                      <InputItem
                        label="Email"
                        name="email"
                        value={email}
                        star={false}
                        placeholder="Enter the email"
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div>
                      <InputItem
                        label="Address"
                        name="address"
                        value={address}
                        star={false}
                        placeholder="Enter the address"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </Drawer>
            </Space>
            <ModalPopUp
              visible={this.state.deleteVendor}
              cancel={this.cancelDelete}
              submit={this.submitDelete}
              name="Vendor"
              delete_name={this.state.delete_name}
            />
          </div>
        )}
      </>
    );
  }
}

export default Vendors;
