import { getCookies } from "./Utils";

const STORAGE = {
  view: localStorage.getItem("empr_app"),
  type: getCookies("empr_type"),
};

const conditon = (type, value) => {
  return type === "super_admin" && value;
};

// const main = (type, permission, value, value2, data) => {
//   return type === "sub_admin" && permission !== ""
//     ? permission.map((app, i) =>
//         app?.permissions[`${data}`] ? value : value2 ?? ""
//       )
//     : conditon(type, value);
// };

const main = (type, permission, value, value2, data) => {
  return type === "sub_admin" && permission !== ""
    ? permission.map((app, i) =>
        app?.permissions[`${data}`] ? value : value2 ?? ""
      )
    : conditon(type, value);
};
export const CREATE = (type, permission, value, value2) => {
  return main(type, permission, value, value2, "create");
};

export const UPDATE = (type, permission, value1, value2) => {
  return main(type, permission, value1, value2, "update");
};

export const DELETE = (type, permission, value1) => {
  return main(type, permission, value1, "", "delete");
};

export const APPROVAL = (type, permission, value1) => {
  return main(type, permission, value1, "", "approval");
};

const { type, view } = STORAGE;
let result = view && JSON.parse(view);

const setAccessModule = (name, key) => {
  let access = false;
  let arr = [];

  result &&
    result.map((item) => {
      return item.name && arr.push(item);
    });


  if (type === "sub_admin") {
    arr?.map((obj, i) => {
      if (obj?.sub_roles?.length) {
        obj?.sub_roles?.forEach((sub) => {
          if (sub?.name === name) {
            if (sub.permissions[key]) {
              access = true;
            }
          }
        });
      } else if (obj?.name === name) {
        if (obj?.permissions[key]) {
          access = true;
        }
      }
    });
  } else {
    access = true;
  }

  return access;
};

export const Access = (name, key) => {
  return setAccessModule(name, key);
};
export { STORAGE };
