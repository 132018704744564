import React, { Component } from "react";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { showError, showSuccess,isErrorTxt } from "../../constants/Utils";
import { Input, DatePicker, Button, Modal } from "antd";
import moment from "moment";
import InputItem from '../../components/ui/InputItem';
import DateItem from '../../components/ui/DateItem';

// import { ApartmentOutlined } from "@ant-design/icons";
const { TextArea } = Input;

class ExpenseItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empId: "",
      itemId: "",
      itemName: "",
      itemDate: "",
      amount: "",
      description: "",
      editItemData: [],
      openModal: false,
      paidDate: "",
    };
  }

  componentDidMount() {
    this.getItem();
  }
  componentDidUpdate(prevProps) {
    if (this.props.itemId !== prevProps.itemId) {
      this.getItem();
    }
  }

  getItem = () => {
    if (this.props.itemId) {
      let id = this.props.itemId;

      APIGATEWAY.get("employees/expenses/" + id, (response) => {
        let data = response.data.employee_expense;

        this.setState({
          editItemData: data,
          itemName: data.item,
          itemDate: (isErrorTxt(data?.date)?'': moment(data?.date)),
          amount: data.amount,
          description: data.description,
        });
      });
    } else {
      this.setState({
        itemName: "",
        itemDate: "",
        amount: "",
        description: "",
      });
    }
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDateChange = (value) => {
   
    this.setState({ itemDate: value });
  };

  handleTypeChange = (value) => {
    this.setState({ type: value });
  };

  goBack = () => {
    this.props.close();
  };
  getPayDate = () => {
    this.setState({ openModal: true });
  };

  payDateChange = (value) => {
    this.setState({ paidDate: value });
  };

  editExpense = () => {
    let id = this.props.itemId;
    let { amount, itemName, itemDate, description } = this.state;
    let data = {
      item: itemName,
      date: itemDate,
      amount: amount,
      description: description,
    };
    APIGATEWAY.patch(`employees/expenses/${id}`, data, (response) => {
      if (response.success) {
        showSuccess(response.message);
        this.props.editItem();
      }
    });
  };

  payEmployee = () => {
    let { paidDate } = this.state;
    if (isErrorTxt(paidDate)) {
      showError("Please select Paid Date");
    } else {
      let id = this.props.itemId;
      let data = {
        paid_date: paidDate,
      };
      APIGATEWAY.patch(`employees/expenses/status/${id}`, data, (response) => {
        if (response.success) {
          showSuccess(response.message);

          this.setState({ openModal: false });
          this.props.editItem();
        }
      });
    }
  };

  render() {
    let {
      itemDate,
      itemName,
      amount,
      description,
      editItemData,
      openModal,
      paidDate,
    } = this.state;
    return (
      <div>
        <div className="drawerTitle">
          <div className="dec-title">Expense Item</div>
          <div>
            <button className="dec-btn-secondary" onClick={() => this.goBack()}>
              Back
            </button>
            {this.props.edit ? (
              <button className="dec-btn" onClick={() => this.editExpense()}>
                Edit
              </button>
            ) : (
              editItemData.status === "unpaid" && (
                <button onClick={() => this.getPayDate()} className="dec-btn">
                  Paid
                </button>
              )
            )}
          </div>
        </div>
        <div className="item_content">
          <div>
          <InputItem label='Item' name='itemName' value={itemName} star={true} placeholder="Item"  onChange={this.handleInputChange} />

          </div>
          <div>
          <DateItem label='Date' name='itemDate' star={true} value={itemDate} placeholder="Date" onChange={this.handleDateChange} />
          </div>
          <div>
            <p > <span className="interview-date">Amount</span> <span className="star">*</span></p>
            <Input
              type="number"
              value={amount}
              name="amount"
              onChange={this.handleInputChange}
              style={{ width: "100%", height: 47 }}
              className="onboard-date"
            />
          </div>

          <div>
          <InputItem label='Description' name='description' value={description} star={true} placeholder="Description"  onChange={this.handleInputChange} txtarea={true}/>
          </div>
        </div>
        <Modal
          title="Pay Date"
          open={openModal}
          onCancel={() => this.setState({ openModal: false })}
          footer={[
            <div>
              <Button
                type="primary"
                onClick={() => this.setState({ openModal: false })}
              >
                Cancel
              </Button>
              <Button type="primary" onClick={() => this.payEmployee()}>
                OK
              </Button>
            </div>
          ]}
        >
          <div className="paidDate">
            <div style={{ marginBottom: "2rem" }}>Select Paid Date</div>
            <DatePicker value={paidDate} onChange={this.payDateChange}  getPopupContainer={trigger => trigger.parentNode}/>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ExpenseItem;
