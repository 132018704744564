import React, { Component } from "react";
import { Spin, Empty } from "antd";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import {
  getIsoString,
  showDate,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  showError,
} from "../../constants/Utils";
import { EyeOutlined } from "@ant-design/icons";

import "../../stylesheets/styles.css";

import TopHeader from "../../components/ui/TopHeader";
import Filter from "../../components/ui/filter";
import moment from "moment";
import TableField from "../../components/ui/tableField";

class Carrers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      career: [],
      total: 0,
      loading: true,
      from: "",
      to: "",
      dateRange: null,
      queryStatus: {},
      totalDoc: 0,
      pageSize: 20,
      currentPage: 1,
    };
  }

  componentDidMount() {
    this.setQueryStatus();
    this.reloadPage();
    window.addEventListener("popstate", this.onPopstate);
  }

  onPopstate = () => {
    let params = new URLSearchParams(window.location.search);

    const search = this.props.location.search;
    // if (prevProps.location.search !== search) {
    const searchObj = returnSearchObject(search);
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.from = searchObj.from ?? "";
    this.state.to = searchObj.to ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";
    this.state.queryStatus = returnSearchObject(search);

    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      this.handleTable();
    });
    // }
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
  }
  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.clearHandler();
        }
      });
    });
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          from: queryObj.from ?? "",
          to: queryObj.to ?? "",
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",
          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    }
  };

  // componentDidUpdate(prevProps) {
  //   let params = new URLSearchParams(window.location.search);

  //   const search = this.props.location.search;
  //   if (prevProps.location.search !== search) {
  //     const searchObj = returnSearchObject(search);
  //     this.state.currentPage = parseInt(searchObj.page) ?? "";
  //     this.state.from = searchObj.from ?? "";
  //     this.state.to = searchObj.to ?? "";
  //     this.state.queryStatus = returnSearchObject(search);

  //     this.setState({ queryStatus: this.getQuerySearch() }, () => {
  //       this.handleTable();
  //     });
  //   }
  // }

  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";
    querysearch.from = getIsoString(this.state.from) ?? "";
    querysearch.to = getIsoString(this.state.to) ?? "";

    return querysearch;
  };
  handleFilter = () => {
    let query = this.getQuerySearch();

    if (this.state.from > this.state.to) {
      showError("Select the valid date");
    } else {
      this.setState(
        {
          queryStatus: query,
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    }
  };

  handleTable = () => {
    APIGATEWAY.get(
      "careers" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          let data = response.data.career;

          let total = response.data?.career?.length;
          this.setState({
            career: data,
            total: total,
            loading: false,
            totalDoc: response?.data?.total,
          });
        } else {
          this.setState({
            loading: true,
          });
        }
      }
    );
  };

  handleFromChange = (date, dateString) => {
    this.setState({ from: date });
  };

  handleToChange = (date, dateString) => {
    this.setState({ to: date });
  };
  // applyHandler = () => {
  //   this.setState({ currentPage: 1 }, () => {
  //     this.handleFilter();
  //   });
  // };

  applyHandler = () => {
    const {from,to} =this.state
    if(from ===''&& to ===""){
      showError('Please select the date')
    }else{
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handleFilter();
        }
      );
    }
   
  };
  clearHandler = () => {
    this.setState(
      {
        from: "",
        to: "",
        dateRange: null,
        currentPage: 1,
        queryStatus: { page: 1, limit: 20 },
        pageSize: 20,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.handleTable();
      }
    );
  };

  onDateRangeChange = (dateRange) => {
    if (dateRange) {
      this.setState({
        dateRange: this.returnMomentDateRange(dateRange[0], dateRange[1]),
        from: moment(dateRange[0]),
        to: moment(dateRange[1]),
      });
    } else {
      this.setState({
        dateRange: null,
      });
    }
  };
  returnMomentDateRange = (start, finish) => {
    return [moment(start, "YYYY-MM-DD"), moment(finish, "YYYY-MM-DD")];
  };
  previous = () => {
    this.setState(
      { currentPage: this.state.currentPage - 1, loading: true },
      () => {
        this.handleFilter();
      }
    );
  };

  next = () => {
    this.setState(
      { currentPage: this.state.currentPage + 1, loading: true },
      () => {
        this.handleFilter();
      }
    );
  };
  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  render() {
    const {
      career,
      total,
      loading,
      dateRange,
      from,
      to,
      currentPage,
      pageSize,
    } = this.state;
    const columns = [
      {
        title: "S.No",
        dataIndex: "S.No",

        render: (key, item, index) => (
          <div key={index} style={{ paddingLeft: "10px" }}>
            {(currentPage - 1) * pageSize + (index + 1)}
          </div>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (key) => <div>{key}</div>,
      },
      { title: "Role", dataIndex: "role" },
      { title: "Email", dataIndex: "email" },
      {
        title: "Received on",
        dataIndex: "createdAt",
        render: (createdAt) => showDate(createdAt),
      },
      {
        title: "Document",
        dataIndex: "document",
        render: (document) => (
          <a href={document} target="_blank" rel="noopener noreferrer">
            {<EyeOutlined />}
          </a>
        ),
      },
    ];
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }
    return (
      <>
        
          <div className="">
            <TopHeader title="Careers" />

            <div className="main-content">
              <Filter
                clear={this.clearHandler}
                apply={this.applyHandler}

                handleOnFromChange={this.handleFromChange}
                handleOnToChange={this.handleToChange}
                fromDate={this.state.from}
                toDate={this.state.to}
                fromName="from"
                toName="to"
              />
              {this.state.loading ? (
                <div className="tableview">
                  <Spin />
                </div>
              ) : (
              <div className="table_page_wrap">

                <table className="table-response">
                  <thead>
                    <tr>
                      <th className="">S.No</th>
                      <th className="">Name</th>
                      <th className="">Role</th>
                      <th className="">Email</th>
                      <th className="">Received on</th>
                      <th className="">Document</th>
                    </tr>
                  </thead>
                  {career.length > 0 ? (
                    <tbody>
                      {career.map((item, i) => (
                        <tr key={i}>
                          <td className="">  {(this.state.currentPage - 1) * 10 + (i + 1)}
                          </td>
                          <td className="table-name text-capitalize">{item.name}</td>
                          <td className="text-capitalize">{item.role}</td>
                          <td className="">{item.email}</td>
                          <td className="">{showDate(item.createdAt)}</td>
                          <td className=""><a href={item.document} target="_blank" rel="noopener noreferrer">
                            {<EyeOutlined />}
                          </a></td>



                        </tr>
                      ))}

                    </tbody>
                  ) : <tbody className="empty-message">
                    <tr>
                      <td colspan="6">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>

                    </tr>
                  </tbody>}

                </table>
                {career.length > 0 && (
                  <TableField
                    //  column={columns}
                     data={career}
                    countprev={countprev}
                    countnext={countnext}
                    currentPage={currentPage}
                    ClickPrev={() => this.previous()}
                    ClickNext={() => this.next()}
                    total={total}
                    overAll={this.state.totalDoc ?? 0}
                    pagelimit={this.state.pageSize}
                    pagelimitHandle={this.pageLimitOnchange}
                  />
                )}


              </div>
              )} 
            </div>
          </div>
       
      </>
    );
  }
}

export default Carrers;
