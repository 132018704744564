import React, { Component } from "react";
import TopHeader from "../../components/ui/TopHeader";
import { STORAGE, UPDATE, CREATE, DELETE } from "../../constants/roleStore";
import ModalPopUp from "../../components/ui/modalpop";
import {
  Input,
  Tabs,
  Checkbox,
  Divider,
  message,
  Select,
  Spin,
  Card,
  Button,
  Form,
  Space,
  Modal,
  DatePicker,
  Empty,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import InputDataField from "../../components/ui/InputDataField";
import InputItem from "../../components/ui/InputItem";
import DateItem from "../../components/ui/DateItem";
import SelectItem from "../../components/ui/SelectItem";
import { Link } from "react-router-dom";
import {
  isInvalidName,
  isInvalidEmail,
  showError,
  showSuccess,
  showDate,
  showDates,
  getIsoString,
  getDateMonth,
  numberWithComma,
  checkNameLength,
  isErrorTxt,
  checkTxtLength,
  getShortMonth,
  buildQueryString,
  decimalWithComma,
} from "../../constants/Utils";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import constants from "../../ApiService/GlobalVariables/globalVariables";
import imageCompression from "browser-image-compression";
import SelectIcon from "../../assets/Vector.svg";
import "../../stylesheets/styles.css";
import ReactS3Uploader from "react-s3-uploader";
import {
  UploadOutlined,
  CloseSquareFilled,
  FormOutlined,
  DeleteOutlined,
  InfoCircleFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import { subDays, addDays } from "date-fns";

import cookie from "react-cookies";
import urlLink from "../../ApiService/GlobalVariables/globalVariables";
import { currencyFormatter } from "muthu-plugins";
import vectorIcon from "../../assets/VectorG.svg";

const FormItem = Form.Item;
const { TabPane } = Tabs;
const { Option } = Select;
export default class CreateEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      name: "gopi",
      official_emp_id: "",
      personal: "",
      official: "",
      phone: "8667301732",
      dob: "",
      blood_group: "b-ve",
      address: "sdfdsfdsfdsfdsf",
      emergency_name: "sdfdsfdsfdsfdsf",
      emergency_phone: "8667301732",
      relation: "sdfdsfdsfdsfdsf",
      role: "",
      interviewed: "",
      joined: "",
      relieved: "",
      is_experienced: false,
      is_pfchecked: false,
      pfchecked: true,
      years: "",
      disableInput: false,
      checked: false,
      modelOpen: false,
      arrayOfArray: [],
      id: this.props.match.params.id,
      salaries: [],
      sal: "",
      currentPage: 1,
      loading: false,
      roleList: [],
      search: true,
      roleData: {},
      status: "",
      show: false,
      modal1Visible: false,
      modal2Visible: false,
      payslip1Visible: false,
      reviewDate: false,
      amount: "",
      salary: "",
      type: "",
      hshow: true,
      empl_id: "",
      review: "",
      password: "",
      from: "",
      dataa: [],
      total: "",
      nextReview: "",
      file: "",
      types: "",
      cname: "",
      exFrom: "",
      exTo: "",
      image: "",
      loadingState: false,
      acc_number: "",
      acc_name: "",
      ifsc_code: "",
      documents: [],
      url: "",
      totals: "",
      document_id: "",
      payslips: [],
      account_number: "",
      net_pay: "",
      paytotal_amount: "",
      bank: "",
      emp_role: "",
      tr_date: "",
      word: "",
      loss_of_pay: "",
      basic_pay: "",
      deductions: "",
      reference_number: "",
      pay: true,
      payslip_id: "",
      transaction_date: "",
      employee_name: "",
      salary_id: "",
      emp_id: "",
      ReviewDate: "",
      nextReviewDate: "",
      relieved: false,
      drelievedDate: "",
      relieveDate: "",
      interview_date: "",
      reasons: "",
      app_name: "",
      app_type: "",
      employee_ex_year: 0,
      employee_ex_month: 0,
      pf_amount: 3600,
      tds_amount: 12,
      pt_amount: 208.33,
      employeeList: "",
      search_repoter: "",
      statusData: [
        { value: "Active", key: "active" },
        { value: "Relieved", key: "relieved" },
        { value: "Fired", key: "fired" },
        { value: "Abscond", key: "abscond" },
        { value: "Offline", key: "offline" },
      ],
      uan: "",
      tds: "",
      add_edit_loader: false,
      loader: true,
      deletePayslipItem: false,
      deleteDocumentItem: false,
      delete_document: "",
      delete_payslip: "",
      activeTab: "1",
      maxlength: "",
      timer: null,
      employee: {
        emp_id: "",
        name: "",
        official_emp_id: "",
      },
    };
  }

  componentDidMount() {
    let { id } = this.state;
    if (id) {
      this.addEmployee(id);
      this.salaryApi();
      this.getDocuments();
      this.getPaySlipList();
    }
    let name = "";

    let storage = JSON.parse(STORAGE.view);

    name = storage?.filter((app) => app?.name === "Employees");

    this.setState({
      app_name: name,
      app_type: STORAGE.type,
    });

    this.callRoleAPI();
  }

  handleCheckBox = (e) => {
    this.setState({
      checked: e.target.checked,
      is_experienced: e.target.checked,
    });
  };

  handlepfCheckBox = (e) => {
    if (e.target.name === "is_PFchecked") {
      this.setState({
        pfchecked: e.target.checked,
      });
    } else if (e.target.name === "is_PTchecked") {
      this.setState({
        ptchecked: e.target.checked,
      });
    } else if (e.target.name === "is_TDSchecked") {
      this.setState({
        tdschecked: e.target.checked,
      });
    }
  };

  getDocuments = () => {
    let self = this;
    const { id } = self.state;
    APIGATEWAY.get("documents/" + id, (response) => {
      if (response.success) {
        let data = response.data.document;

        this.setState({
          documents: data,
          types: data?.type,
        });
      }
    });
  };

  yearsOnchange = (e) => {
    let REGEX = /^\d+$/;
    if (e.target.value === "" || REGEX.test(e.target.value))
      this.setState({ [e.target.name]: e.target.value });
  };

  monthOnchange = (e) => {
    let REGEX = /^\d+$/;
    if (e.target.value === "" || REGEX.test(e.target.value))
      this.setState({ [e.target.name]: e.target.value });
  };

  getPaySlipList = () => {
    let self = this;
    const { id } = self.state;

    APIGATEWAY.get("payslip/" + id, (response) => {
      if (response.success) {
        let res = response.data;

        self.setState({
          payslips: res.payslips,
          transaction_date: res.payslips[0].transaction_date,
        });
      }
    });
  };

  handleClose = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      checked: !e.target.checked,
    });
  };

  showModal = () => {
    this.setState({ modelOpen: true });
  };
  handleCancel = (e) => {
    this.setState({ modelOpen: false });
  };
  handleNumberChange = (event) => {
    // let REGEX = /^\d+$/;
    let REGEX = /^([0-9])+$/;
    if (event.target.value === "" || REGEX.test(event.target.value))
      this.setState({ [event.target.name]: event.target.value });
  };
  handleInputChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    this.setState({
      [name]: value,
    });
  };

  handleUanChange = (event) => {
    this.setState({ [event.target.name]: event.target.value.toUpperCase() });
  };
  handleSelectChange = (event) => {
    this.setState({ [event.target.name]: event.target.value.toUpperCase() });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.addEmployee();
    });
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  handleDateChange = (e) => {
    this.updateStateValues("dob", e);
  };
  handleJoinedDateChange = (e) => {
    this.updateStateValues("joined", e);
  };
  handleRelievedDateChange = (e) => {
    this.updateStateValues("relievedDate", e);
  };
  handleOnRelieved_dateChange = (e) => {
    this.updateStateValues("relieveDate", e);
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };
  handleOnFromChanges = (e) => {
    this.updateStateValues("exFrom", e);
  };
  handleOntr_dateChange = (e) => {
    this.updateStateValues("tr_date", e);
  };

  handleOnReviewDateChange = (e) => {
    this.updateStateValues("ReviewDate", e);
  };

  handleToFromChanges = (e) => {
    this.updateStateValues("exTo", e);
  };

  handleReviewDateChanges = (e) => {
    // this.updateStateValues( "ReviewDate", e );
    this.setState({
      ReviewDate: e,
    });
  };
  handleNextReviewDateChanges = (e) => {
    // this.updateStateValues( "nextReviewDate", e );
    this.setState({
      nextReviewDate: e,
    });
  };
  handleOnnextReviewChange = (e) => {
    this.updateStateValues("nextReview", e);
  };
  handleNextReviewDate = (e) => {
    this.updateStateValues("nextReviewDate", e);
  };

  handleInterviewDateChange = (e) => {
    this.updateStateValues("interviewed", e);
  };

  handleStatusChange = (value) => {
    this.setState({ status: value });
  };
  handleSelectChangeExperience = (value) => {
    this.setState({ years: value });
  };
  textareaChange = (e) => {
    // if(name='address'){
    // this.setState({ address: e.target.value });
    // }else if(name='reason'){
    //   this.setState({ reasons: e.target.value });
    // }
  };
  addEmployee = (id) => {
    let username = this.state.id;
    APIGATEWAY.get("employees/" + username, (response) => {
      if (response.success) {
        
        let data = response?.data?.employee??"";
        let bank = data.bank;
        if (response?.data?.employee._pf?.is_enabled)
          this.state.detailCheck1 = true;
        if (response?.data?.employee._pt?.is_enabled)
          this.state.detailCheck2 = true;
        if (response?.data?.employee._tds?.is_enabled)
          this.state.detailCheck3 = true;
        this.setState(
          {
            loader: false,
            name: data?.name,
            status: data?.status,
            official_emp_id: data?.official_emp_id,
            empl_id: data?.emp_id,
            personal: data?.email?.personal,
            official: data?.email?.official,
            phone: data?.phone?.national_number,
            blood_group: data?.blood_group,
            address: data?.address,
            emergency_name: data?.emergency?.name,
            relation: data?.emergency?.relation,
            emergency_phone: data?.emergency?.phone?.national_number,
            sal: data?.current_salary ? data?.current_salary?.monthly : 0,
            acc_number: data?.bank?.acc_no,
            acc_name: data?.bank?.acc_name,
            ifsc_code: data?.bank?.ifsc ?? "",
            pan_number: data?.pan_number,
            uan: data?.uan,
            role: JSON.stringify({
              name: data?.role?.name,
              role_id: data?.role?.role_id,
            }),
            dob: moment(data?.dob),
            interviewed: moment(data?.official_date?.interviewed),
            joined: moment(data?.official_date?.joined),
            relievedDate: data?.official_date?.relieved
              ? moment(data?.official_date?.relieved)
              : data?.official_date?.fired
              ? moment(data.official_date?.fired)
              : data.official_date?.abscond &&
                moment(data.official_date?.abscond),
            ReviewDate: moment(data?.next_review),
            reasons: data?.reason,
            checked: data?.experience?.is_experienced,
            years: data?.experience?.years,
            employee_ex_year: data?.experience?.years.split("-")[0],
            employee_ex_month: data?.experience?.years.split("-")[1],
            loading: false,
            dataa: data,
            pf_amount: data._pf.value,
            pt_amount: data._pt.value,
            tds_amount: data._tds.value,
            pfchecked: data._pf.is_enabled,
            ptchecked: data._pt.is_enabled,
            tdschecked: data._tds.is_enabled,
            tds: data.tds,
            search_repoter: data?.report_to?.name??"",
            emp_role: data?.role?.name ?? "",
            employee: data?.report_to ?? "",
          }
        );
        this.callEmployeAPI(data?.emp_id);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: true });
      }
    });
  };


  employeeSelect = (value, key) => {
    let result = JSON.parse(value);
    this.setState(() => ({
      employee: result,
    }));
  };

  callEmployeAPI = (empId) => {
    let { search_repoter } = this.state;

    if (search_repoter !== "" || empId !== "") {
      // queryParams = "?search_term=" + search_repoter +"&emp_id="+empl_id;
      // queryParams = "?emp_id="+empId;
      let searchQuery = {
        // search_terms: search_repoter,
        emp_id: empId,
      };
      APIGATEWAY.get(
        "employees/all" + buildQueryString(searchQuery),
        (response) => {
          if (response.success) {
            // let result = response.data.employees.map((emp) => {
            //   return {
            //     key: emp.name,
            //     value: emp.name,
            //   };
            // });
            this.setState({ employeeList: response.data.employees });
          }
        }
      );
    }
  };

  callSearchService = () => {
    APIGATEWAY.get(
      `employees/check?filter=${this.state.search_repoter}`,
      (res) => {
        if (res.success) {
          this.setState({
            employee_list: res?.data?.employees ?? [],
          });
        }
      }
    );
  };

  setModal1Visible = (modal1Visible) => {
    this.setState({ modal1Visible });
    this.setState({ show: true });
  };
  setpayslipVisible = (payslip1Visible) => {
    this.setState({ payslip1Visible });
  };
  salaryCancelHandler = (editReviewDateVisible) => {
    this.setState({ editReviewDateVisible });
  };

  payslipCancel = () => {
    this.setState({
      payslip1Visible: false,
      account_number: "",
      net_pay: "",
      paytotal_amount: "",
      bank: "",
      emp_role: "",
      tr_date: "",
      word: "",
      loss_of_pay: "",
      basic_pay: "",
      deductions: "",
      reference_number: "",
      pay: true,
      uan: "",
      pan_number: "",
      payslip_id: "",
    });
  };
  uploadFile = (modal2Visible) => {
    this.setState({ modal2Visible });
    this.setState({
      show: true,
      types: "",
      cname: "",
      exFrom: "",
      exTo: "",
      image: "",
      file: "",
      document_id: "",
    });
  };
  callRoleAPI = () => {
    let { search } = this.state;
    var queryParams = "";
    if (search !== "") {
      // queryParams = "?search_term=" + search;
      queryParams = "?all=" + search;
    }
    APIGATEWAY.get("roles" + queryParams, (response) => {
      if (response.success) {
        let total = response.data.total;

        this.setState({
          roleList: response.data.roles,
          total,
        });
      }
    });
  };
  searchInput = (value) => {
    this.setState({
      type: value,
    });
  };
  searchInputs = (value) => {
    this.setState({
      types: value,
    });
  };
  handleOnInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  salaryApi = (id) => {
    let username = this.state.id;
    APIGATEWAY.get("salaries/" + username, (response) => {
      if (response.success) {
        let res = response.data;

        this.setState(
          {
            salary: res?.salary,
          },
          () => {
            this.addEmployee();
          }
        );
      }
    });
  };

  initial = () => {
    this.setState({
      hshow: false,
    });
  };

  salarySubmitHandle = () => {
    let empId = this.state.id;
    let salaryId = this.state.salary_id;
    const { nextReviewDate, ReviewDate } = this.state;
    if (isErrorTxt(ReviewDate)) {
      showError("Please select the review date");
    } else if (isErrorTxt(nextReviewDate)) {
      showError("Please enter next review date");
    } else {
      let data = {
        review_date: `${getIsoString(ReviewDate)}`,
        next_review_date: `${getIsoString(nextReviewDate)}`,
      };
      if (this.state.id) {
        APIGATEWAY.patch(`salaries/${empId}/${salaryId}`, data, (response) => {
          if (response.success) {
            message.success(response.message);
            // this.props.history.push( "/employees" );
            this.salaryCancelHandler(false);
          } else {
            message.error(response.message);
          }
        });
      }
    }
  };

  submitHandle = () => {
    let username = this.state.id;
    const { sal, type, amount, from, nextReview } = this.state;
    if (sal === "") {
      showError("Please enter salary");
    } else if (from === "" && from === null) {
      showError("Please select the current review date");
    } else if (nextReview === "" && nextReview === null) {
      showError("Please select the next review date");
    } else if (type === "") {
      showError("Please select the type");
    } else if (type === "hike" && amount === "") {
      showError("Please enter amount");
    } else if (type === "hike" && from === "") {
      showError("Please select the current review date");
    } else if (type === "hike" && nextReview === "") {
      showError("Please select the next review date");
    } else {
      let data;
      {
        this.state.type === "initial"
          ? (data = {
              salary: sal ? parseInt(sal) : "-",
              type: type,
              review_date: from,
              next_review_date: nextReview,
            })
          : (data = {
              salary: sal ? parseInt(sal) : "-",
              type: type,
              hike: amount ? parseInt(amount) : "-",
              review_date: from,
              next_review_date: nextReview,
            });
      }
      APIGATEWAY.post(`salaries/create/${username}`, data, (response) => {
        if (response.success) {
          this.setState({
            modal1Visible: false,
            sal: "",
            type: "",
            amount: "",
            from: "",
            nextReview: "",
          });
          this.salaryApi();
        } else {
          this.setState({
            modal1Visible: true,
          });
        }
      });
    }
  };

  handleSubmit = () => {
    var self = this;
    let {
      name,
      personal,
      official,
      phone,
      dob,
      blood_group,
      address,
      emergency_name,
      emergency_phone,
      relation,
      role,
      joined,
      interviewed,
      checked,
      years,
      password,
      acc_name,
      acc_number,
      relievedDate,
      relieveDate,
      status,
      reasons,
      employee_ex_year,
      employee_ex_month,
      pan_number,
      uan,
      tds,
      pfchecked,
      ptchecked,
      tdschecked,
      pf_amount,
      pt_amount,
      tds_amount,
      ifsc_code,
      is_experienced,
      activeTab,
      search_repoter,
      official_emp_id,
      empl_id,
      employee,
    } = this.state;

    if (isErrorTxt(name)) {
      showError("Please enter employee name");
    } else if (isInvalidName(name)) {
      showError("Employee name should contain letters  only");
    } else if (checkNameLength(name)) {
      showError("Employee name should contain atleast 3 characters");
    } else if (isErrorTxt(official)) {
      showError("Please enter the office Email");
    } else if (isInvalidEmail(official)) {
      showError("Please enter valid office Email");
    } else if (isErrorTxt(personal)) {
      showError("Please enter the personal email");
    } else if (isInvalidEmail(personal)) {
      showError("Please enter valid personal email");
    } else if (isErrorTxt(phone)) {
      showError("Please enter the phone number");
    } else if (checkTxtLength(phone, 10)) {
      showError("Please enter 10 digits phone number");
    } else if (isErrorTxt(dob)) {
      showError("Please select date of birth");
    } else if (isErrorTxt(blood_group)) {
      showError("Please enter the blood group");
    } else if (isErrorTxt(status)) {
      showError("Please select status");
    } else if (
      status !== "active"
        ? status !== "offline" && isErrorTxt(relievedDate)
        : ""
    ) {
      showError("Please select date");
    } else if (
      status !== "active" ? status !== "offline" && isErrorTxt(reasons) : ""
    ) {
      showError("Please enter reason");
    } else if (isErrorTxt(address)) {
      showError("Please enter the address");
    } else if (isErrorTxt(emergency_name)) {
      showError("Please enter the emergency contact name");
    } else if (isInvalidName(emergency_name)) {
      showError("Emergency contact name should contain letters  only");
    } else if (checkNameLength(emergency_name)) {
      showError("Emergency contact name should contain atleast 3 characters");
    } else if (isErrorTxt(emergency_phone)) {
      showError("Please enter the emergency contact number");
    } else if (checkTxtLength(phone, 10)) {
      showError("Please enter 10 digits emergency contact number");
    } else if (isErrorTxt(relation)) {
      showError("Please enter the relation");
    } else if (activeTab === "1") {
      this.setState({ activeTab: "2" });
    } else {
      if (isErrorTxt(role)) {
        showError("Please select the role");
      } else if (isErrorTxt(interviewed)) {
        showError("Please select the interviewed date");
      } else if (isErrorTxt(joined)) {
        showError("Please select the joined date");
      } else if (
        checked &&
        (isErrorTxt(employee_ex_year) || isErrorTxt(employee_ex_month))
      ) {
        showError("Please select the number of years in experience");
      } else {
        let selectedrole = this.state.roleList.find(
          (role) => role.role_id === JSON.parse(this.state.role).role_id
        );
        if (isErrorTxt(selectedrole)) {
          return showError("Please select exists role");
        }
        let dateObject = {};
        if (status === "relieved") {
          dateObject = {
            interviewed:
              interviewed === "" ? interviewed : showDates(interviewed),
            joined: joined === "" ? joined : showDates(joined),
            relieved: showDates(relievedDate),
          };
        } else if (status === "fired") {
          dateObject = {
            interviewed:
              interviewed === "" ? interviewed : showDates(interviewed),
            joined: joined === "" ? joined : showDates(joined),
            fired: showDates(relievedDate),
          };
        } else if (status === "abscond") {
          dateObject = {
            interviewed:
              interviewed === "" ? interviewed : showDates(interviewed),
            joined: joined === "" ? joined : showDates(joined),
            abscond: showDates(relievedDate),
          };
        } else if (status === "active") {
          dateObject = {
            interviewed:
              interviewed === "" ? interviewed : showDates(interviewed),
            joined: joined === "" ? joined : showDates(joined),
          };
        }

        let current = showDates(joined);
        let d = moment().diff(moment(current), "months");
        let d_month = parseInt(d % 12);
        let d_years = parseInt((d / 12).toString().split(".")[0]);

        const pfValue = pfchecked ? pf_amount : 0;
        let employee_contribution = 0,
          employer_contribution = 0;
        if (pfValue > 3600) {
          employer_contribution = 1800;
          employee_contribution = pfValue - employer_contribution;
        } else if (pfValue > 0) {
          employee_contribution = Math.round(pfValue / 2);
          employer_contribution = pfValue - employee_contribution;
        }


        let data = {
          name: name,

          email: {
            personal: personal,
            official: official,
          },
          phone: {
            country_code: 91,
            national_number: phone,
          },
          dob: dob === "" ? dob : showDates(dob),
          blood_group: blood_group,
          address: address,
          emergency: {
            name: emergency_name,
            relation: relation,
            phone: {
              country_code: 91,
              national_number: emergency_phone,
            },
          },
          role: {
            name: selectedrole?.name,
            role_id: selectedrole?.role_id,
          },
          bank: {
            acc_no: acc_number,
            acc_name: acc_name,
            ifsc: ifsc_code,
          },
          status: status,

          official_date: dateObject,
          reason: status !== "active" ? reasons : "",
          password: "welcome@123",
          experience: {
            is_experienced: checked,
            years: `${
              parseInt(isErrorTxt(employee_ex_year) ? 0 : employee_ex_year) +
              "-" +
              parseInt(isErrorTxt(employee_ex_month) ? 0 : employee_ex_month)
            }`,
          },
          uan: uan,
          _pf: {
            is_enabled: pfchecked,
            value: pfValue,
            employee_contribution,
            employer_contribution,
          },
          _pt: { is_enabled: ptchecked, value: ptchecked ? pt_amount : "" },
          _tds: { is_enabled: tdschecked, value: tdschecked ? tds_amount : "" },
          pan_number: pan_number,
          report_to: employee,
        };
        this.setState({ add_edit_loader: true });
        if (this.state.id) {
          let username = this.state.id;
          APIGATEWAY.patch("employees/" + username, data, (response) => {
            this.setState({ add_edit_loader: false });
            if (response.success) {
              message.success(response.message);
              this.props.history.push("/employees");
            } else {
              message.error(response.message);
            }
          });
        } else {
          APIGATEWAY.post("employees/create", data, (response) => {
            this.setState({ add_edit_loader: false });
            if (response.success) {
              message.success(response.message);
              this.handleClick();
            } else {
              message.error(response.message);
            }
          });
        }
      }
    }
  };

  dateonChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleClick = () => {
    this.props.history.push("/employees");
  };
  statusRelieved = () => {
    this.setState({ relieved: true });
  };

  submitRelived = () => {
    let self = this;
    const { id, relieveDate } = self.state;
    let data = {
      relieved_date: moment(relieveDate).format("yyyy-MM-DD"),
    };
    if (relieveDate === "" || relieveDate === null) {
      showError("Please select the relieved date");
    } else {
      APIGATEWAY.patch("employees/relieved/" + id, data, (response) => {
        this.setState({ relieved: false });

        if (response.success) {
          message.success(response.message);
          self.addEmployee(id);
        }
      });
    }
  };

  submitUpload = () => {
    let self = this;
    const { types, image, id, cname, exFrom, exTo, document_id } = self.state;

    if (types === "") {
      showError("Please select a type");
    } else if (types === "certificate" && cname === "") {
      showError("Please enter the company name");
    } else if (types === "certificate" && exFrom === "") {
      showError("Please select the from date");
    } else if (types === "certificate" && exTo === "") {
      showError("Please select the to date");
    } else if (isErrorTxt(image)) {
      showError("Please upload the file");
    } else {
      let data;
      {
        this.state.types === "certificate"
          ? (data = {
              type: types,
              previous_company: cname,
              experience: {
                from: exFrom,
                to: exTo,
              },
              url: image,
            })
          : (data = {
              type: types,
              url: image,
            });
      }

      if (document_id) {
        APIGATEWAY.patch(
          "documents/" + id + "/" + document_id,
          data,
          (response) => {
            if (response.success) {
              self.setState({
                modal2Visible: false,
                cname: "",
                exTo: "",
                exFrom: "",
                types: "",
                image: "",
                document_id: "",
              });
              self.getDocuments();
              message.success(response.message);
            } else {
              message.error(response.message);
            }
          }
        );
      } else {
        APIGATEWAY.post("documents/" + id, data, (response) => {
          if (response.success) {
            self.setState({
              modal2Visible: false,
              types: "",
              cname: "",
              exTo: "",
              exFrom: "",
              image: "",
              file: "",
            });
            self.getDocuments();
            message.success(response.message);
          } else {
            message.error(response.message);
          }
        });
      }
    }
  };

  update = () => {
    let self = this;
    const { types, image, id, cname, exFrom, exTo } = self.state;

    if (isErrorTxt(types)) {
      showError("Please select a type");
    } else if (types === "certificate" && isErrorTxt(cname)) {
      showError("Please enter the company name");
    } else if (types === "certificate" && isErrorTxt(exFrom)) {
      showError("Please select the from date");
    } else if (types === "certificate" && isErrorTxt(exTo)) {
      showError("Please select the to date");
    } else if (isErrorTxt(image)) {
      showError("Please upload the file");
    } else {
      let data;
      {
        this.state.types === "certificate"
          ? (data = {
              type: this.state.types === "certificate" ? types : null,
              previous_company:
                this.state.types === "certificate" ? cname : null,
              experience: {
                from: exFrom,
                to: exTo,
              },
              url: image,
            })
          : (data = {
              type: types,
              previous_company: "",
              experience: {
                from: "",
                to: "",
              },
              url: image,
            });
      }

      APIGATEWAY.patch(
        "documents/" + id + "/" + this.state.document_id,
        data,
        (response) => {
          if (response.success) {
            self.setState({
              modal2Visible: false,
              cname: "",
              exTo: "",
              exFrom: "",
              types: "",
              image: "",
              document_id: "",
            });
            self.getDocuments();
            message.success(response.message);
          } else {
            message.error(response.message);
          }
        }
      );
    }
  };

  handleDelete = (obj) => {
    this.setState({
      deleteDocumentItem: true,
      document_id: obj?.document_id,
      delete_document: obj?.type ?? "-",
    });
  };

  cancelDocument = () => {
    this.setState({
      deleteDocumentItem: false,
      document_id: "",
    });
  };

  deleteDocument = () => {
    const { id } = this.state;
    let self = this;

    let data = {};
    APIGATEWAY.delete(
      "documents/" + id + "/" + this.state.document_id,
      data,
      (response) => {
        if (response) {
          showSuccess(response.message);
          this.setState(
            {
              deleteDocumentItem: false,

              document_id: "",
            },
            () => {
              this.getDocuments();
            }
          );
        } else {
          showError(response.message);
        }
      }
    );
  };
  payslipDelete = (obj) => {
    this.setState({
      deletePayslipItem: true,
      payslip_id: obj?.payslip_id,
      delete_payslip: `${
        isErrorTxt(obj?.transaction_date)
          ? "-"
          : showDate(obj?.transaction_date)
      } payslip`,
    });
  };

  deletePayslip = () => {
    let self = this;
    let data = {};
    APIGATEWAY.delete("payslip/" + this.state.payslip_id, data, (response) => {
      if (response.success) {
        self.getPaySlipList();
        showSuccess(response.message);
        this.setState({
          deletePayslipItem: false,
          payslip_id: "",
        });
      } else {
        showError(response.message);
        this.setState({
          deletePayslipItem: true,
        });
      }
    });
  };

  cancelPayslip = () => {
    this.setState({
      deletePayslipItem: false,
      payslip_id: "",
    });
  };

  handleOnChange = (data) => {
    this.setState({
      file: data.target.files[0],
    });
  };

  //s3 uploader

  onUploadOffer = (result) => {
    var self = this;

    self.setState({
      image: constants.S3_UPLOADER.AWS_BASE_URL + result.filename,
      loadingState: false,
    });
  };

  checkValidImage = (file, next) => {
    var self = this;
    if (file && file.type) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "application/pdf"
      ) {
        self.setState({
          loadingState: true,
          file: file,
        });

        if (file.size <= 2097152) {
          if (Math.round(file.size / 2028) > 1000) {
            var options = {
              maxSizeMB: 2,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            imageCompression(file, options)
              .then(function (compressedFile) {
                next(compressedFile);
              })
              .catch(function (error) {
                next(file);
              });
          } else next(file);
        } else {
          showError("File Size should not be greater than 2 MB");
        }
      } else {
        showError("Invalid Format");
      }
    } else {
      showError("File not found");
    }
  };

  onRemoveOfferImage = () => {
    this.setState({ image: "", file: "" });
  };
  handleDetailpage = (username) => {
    let self = this;
    const { id } = self.state;
    this.props.history.push("/employees/detail/" + id + "/" + username);
  };
  handleEditPage = (object) => {
    this.setState({
      modal2Visible: true,
      cname: object?.previous_company ?? "",
      types: object?.type ?? "",
      exTo: object.experience && moment(object.experience.to),
      exFrom: object.experience && moment(object.experience.from),
      file: object?.url ?? "",
      image: object?.url ?? "",
      document_id: object?.document_id ?? "",
    });
  };
  PayhandleEditPage = (object) => {
    this.setState({
      payslip1Visible: true,
      pay: false,
      account_number: object?.bank?.acc_no ?? "",
      net_pay: object?.amounts?.net_pay ?? "",
      paytotal_amount: object?.amounts?.total_amount ?? "",
      bank: object?.bank?.acc_name ?? "",
      emp_role: object?.employee?.role ?? "",
      tr_date: object.transaction_date && moment(object.transaction_date),
      word: object?.word ?? "",
      loss_of_pay: object?.deduction?.lop ?? "",
      basic_pay: object?.amounts?.total_amount ?? "",
      deductions: object?.items?.deductions ?? "",
      reference_number: object?.reference_number ?? "",
      payslip_id: object?.payslip_id ?? "",
    });
  };
  submitHandlepay = () => {
    let self = this;
    const {
      account_number,
      bank,
      emp_role,
      tr_date,
      loss_of_pay,
      basic_pay,
      reference_number,
      id,
      payslip_id,
    } = self.state;

    if (isErrorTxt(basic_pay)) {
      showError("Please enter the basic pay");
    } else {
      let data = {
        total_amount: parseInt(basic_pay),
      };
      if (payslip_id) {
        APIGATEWAY.patch("payslip/" + payslip_id, data, (response) => {
          if (response.success) {
            self.setState({
              payslip1Visible: false,
              account_number: "",
              net_pay: "",
              paytotal_amount: "",
              bank: "",
              emp_role: "",
              tr_date: "",
              word: "",
              loss_of_pay: "",
              basic_pay: "",
              deductions: "",
              reference_number: "",
              payslip_id: "",
            });
            showSuccess(response.message);
            this.getPaySlipList();
          } else {
            showError(response.message);
          }
        });
      } else {
        APIGATEWAY.post("payslip/" + id, data, (response) => {
          if (response.success) {
            self.setState({
              payslip1Visible: false,
              account_number: "",
              net_pay: "",
              paytotal_amount: "",
              bank: "",
              emp_role: "",
              tr_date: "",
              word: "",
              loss_of_pay: "",
              basic_pay: "",
              deductions: "",
              reference_number: "",
              payslip_id: "",
            });
            showSuccess(response.message);
            this.getPaySlipList();
          } else {
            showError(response.message);
          }
        });
      }
    }
  };

  download = (obj) => {
    let self = this;
    const { official_emp_id } = self.state;
    let token = cookie.load("empr_tk");
    var bearer = "Bearer " + token;

    let data = {};

    fetch(urlLink.BASE_URL + "payslip/pdf/" + obj.payslip_id, {
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${getDateMonth(
          obj?.transaction_date,
          "last"
        )} - ${official_emp_id}.pdf`;
        a.click();
      });
    });
  };

  reviewDateEditHandler = (object) => {
    this.setState({
      salary_id: object?.salary_id ?? "",
      editReviewDateVisible: true,
      employee_name: object?.employee?.name ?? "",
      emp_id: object?.employee?.official_emp_id ?? "",
      review_date: object?.review_date ?? "",
      // ReviewDate: ( this.state.review_date ),
      ReviewDate: moment(object?.review_date),

      nextreview_date: object.next_review_date,
      // nextReviewDate: moment( this.state.nextreview_date ),
      nextReviewDate: moment(object.next_review_date),
    });
  };

  cancelRelived = () => {
    this.setState({ relieveDate: "", relieved: false });
  };

  payslipData = (name, field, star, val) => {
    return (
      <div className={val === "payslip" ? "payslip-wrappers" : "pay-wrappers"}>
        <div className="title-wrapper font-500">
          {name}
          <span className="star">{star ? "*" : ""}</span>
        </div>
        <div className="data-content pay-data-field">{field}</div>
      </div>
    );
  };
  render() {
    const startDate = new Date(
      new Date().getFullYear() - 18,
      new Date().getMonth(),
      new Date().getDate()
    );

    var defaultRole = "";
    try {
      defaultRole = JSON.parse(this.state.role);
    } catch (error) {}

    const state = this.state;

    const {
      is_pfchecked,
      is_ptchecked,
      is_tdschecked,
      pfchecked,
      ptchecked,
      tdschecked,
      tds,
      checked,
      name,
      personal,
      official,
      phone,
      dob,
      blood_group,
      address,
      emergency_name,
      emergency_phone,
      relation,
      interviewed,
      joined,
      is_experienced,
      add_edit_loader,
      id,
      status,

      acc_number,
      acc_name,

      reasons,

      app_name,
      app_type,
      pan_number,
      uan,
      salary,
      ifsc_code,
      activeTab,
    } = this.state;
    let { search_repoter, empl_id, employee } = this.state;
  
    return (
      <Card style={{ marginBottom: 100 }}>
        <TopHeader
          title="Employees"
          backClick={this.handleClick}
          submit={this.handleSubmit}
          id={this.state.id}
          spin={this.state.add_edit_loader}
          primary={activeTab === "1" ? "Next" : "Save"}
        />
        {this.state.loader && this.state.id ? (
          <div className="tableview">
            <Spin />
          </div>
        ) : (
          <div className="main-content2">
            {/* 
            <div className="headerWithSave">
              <div className="breadcrumbContent"><div className="emplyeesBreadcrumb">Employees</div><img src={vectorIcon} alt="breadcrumbArrow"/><div className="emplyeeBreadcrumb">Employee</div> </div>
              <div>
                  <button className="employeeSavebtn">Save</button>
              </div>
            </div> */}

            <Tabs
              className="employeePageTabAlign"
              // size="small"
              activeKey={activeTab}
              onChange={(e) => this.setState({ activeTab: e })}
            >
              <TabPane tab="Profile" key="1">
                <div className="employeePageTabContent">
                  <div className="employeeTabProfile">
                    <InputItem
                      label="Name"
                      name="name"
                      value={name}
                      star={true}
                      placeholder="Enter Name"
                      onChange={this.handleInputChange}
                    />
                    <InputItem
                      label="Office Email"
                      name="official"
                      star={true}
                      value={official}
                      placeholder="Enter Office Email"
                      onChange={this.handleInputChange}
                    />
                    <InputItem
                      label="Personal Email"
                      name="personal"
                      star={true}
                      value={personal}
                      placeholder="Enter Personal Email"
                      onChange={this.handleInputChange}
                    />
                    <InputItem
                      label="Phone Number"
                      star={true}
                      name="phone"
                      number={true}
                      value={phone}
                      placeholder="Enter Phone Number"
                      onChange={this.handleNumberChange}
                    />
                    <DateItem
                      label="Date of Birth"
                      name="dob"
                      star={true}
                      value={dob}
                      placeholder="Date of Birth"
                      onChange={this.handleDateChange}
                    />
                    <InputItem
                      label="Blood Group"
                      name="blood_group"
                      star={true}
                      value={blood_group}
                      placeholder="Enter Blood Group"
                      onChange={this.handleSelectChange}
                    />
                    <SelectItem
                      label="Status"
                      star={true}
                      value={status}
                      placeholder="Status"
                      data={this.state.statusData}
                      onChange={this.handleStatusChange}
                    />

                    {status !== "active" && status !== "offline" && (
                      <DateItem
                        label="Date"
                        name="relievedDate"
                        star={true}
                        value={this.state.relievedDate}
                        placeholder="Date"
                        onChange={this.handleRelievedDateChange}
                      />
                    )}

                    {status !== "active" && status !== "offline" && (
                      <InputItem
                        label="Reason"
                        name="reasons"
                        star={true}
                        value={reasons}
                        placeholder="Enter the reason"
                        // textarea={true}
                        onChange={this.handleInputChange}
                      />
                    )}

                    <InputItem
                      label="Address"
                      name="address"
                      star={true}
                      value={address}
                      placeholder="Enter the Address"
                      // textarea={true}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div>
                    <Divider orientation="left">
                      Emergency Contact Details
                    </Divider>
                    <div className="profileTabEmergencyContent">
                      <InputItem
                        label="Name"
                        star={true}
                        name="emergency_name"
                        value={emergency_name}
                        placeholder="Enter name"
                        onChange={this.handleInputChange}
                      />
                      <InputItem
                        label="Phone Number"
                        star={true}
                        name="emergency_phone"
                        number={true}
                        value={emergency_phone}
                        placeholder="Enter Phone Number"
                        onChange={this.handleNumberChange}
                      />
                      <InputItem
                        label="Relation"
                        star={true}
                        name="relation"
                        value={relation}
                        placeholder="Enter Relation"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Employement" key="2">
                <>
                  <div className="employeeTabEmployement">
                    <SelectItem
                      label="Role"
                      star={true}
                      value={defaultRole.name}
                      role={true}
                      placeholder="Select a Role"
                      data={this.state.roleList}
                      onChange={(value) => {
                        this.setState({ role: value });
                      }}
                    />

                    <DateItem
                      label="Interview Date"
                      star={true}
                      name="interviewed"
                      placeholder="Interview Date"
                      dateFormat="YYYY-MM-DD"
                      value={interviewed}
                      inputReadOnly
                      getPopupContainer={(trigger) => trigger.parentNode}
                      disabledDate={(d) =>
                        !d ||
                        d.isAfter(addDays(new Date(), 0)) ||
                        d.isSameOrBefore(subDays(new Date(), 60))
                      }
                      onChange={this.handleInterviewDateChange}
                    />

                    <DateItem
                      label="Joining date"
                      star={true}
                      name="joined"
                      placeholder="Joining date"
                      dateFormat="YYYY-MM-DD"
                      value={joined}
                      inputReadOnly
                      getPopupContainer={(trigger) => trigger.parentNode}
                      disabledDate={(d) =>
                        !d ||
                        d.isAfter(addDays(new Date(), 120)) ||
                        d.isSameOrBefore(subDays(new Date(), 8))
                      }
                      onChange={this.handleJoinedDateChange}
                    />

                    <InputItem
                      label="UAN Number"
                      name="uan"
                      maxlength="12"
                      star={false}
                      value={uan}
                      placeholder="Enter UAN number"
                      onChange={this.handleNumberChange}
                    />
                    <InputItem
                      label="PAN Number"
                      name="pan_number"
                      maxlength="10"
                      star={false}
                      value={pan_number}
                      placeholder="Enter PAN number"
                      onChange={this.handleUanChange}
                    />
                    {/* <InputItem
                      label="Reporting to"
                      name="pan_number"
                      maxlength="10"
                      star={false}
                      value={this.state.employee.name}
                      placeholder="Reporting to"
                      onChange={this.employeeSelect}
                    /> */}
                    <div className="">
                      <p className="interview-date">Reporting to</p>
                      <Select
                        showSearch
                        getPopupContainer={(trigger) => trigger.parentNode}
                        value={this.state.employee.name || undefined}
                        placeholder="Reports to"
                        onChange={this.employeeSelect}
                        className="select-dropdown-menus-access"
                        suffixIcon={
                          <div>
                            <img src={SelectIcon} alt="" width="10px" />
                          </div>
                        }
                      >
                        <Option value="">Select</Option>
                        {this.state.employeeList && this.state.employeeList?.map((employee, i) => (
                          <Option value={JSON.stringify( {
                            name: employee?.name,
                            emp_id: employee?.emp_id,
                            official_emp_id:employee?.official_emp_id
                          } )} 
                          key={employee.emp_id}>
                            {employee.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    {/* <div className="mb-10">
                      <div className="mb-5e">
                        <Typography>Reporting to</Typography>
                      </div>
                      <input
                        type="text"
                        placeholder="Reports to"
                        autoComplete="off"
                        value={this.state.search_repoter}
                        onChange={(e) => this.onChangeSearch(e)}
                
                        className="leave-input-field"
                      />

                      {
                        this.state.employeeList.length > 0 && (
                          <div className="show-employee-list">
                            {this.state.employeeList.map((emp, p) => (
                              <p
                                key={p}
                                onClick={() => this.getemployeeid(emp)}
                              >
                                <span className="emp-name">
                                  {emp.official_emp_id}
                                </span>
                                /<span className="emp-id">{emp.name}</span>
                              </p>
                            ))}
                          </div>
                        )}
                    </div> */}
                    {/* <div className="form_inputs">
                  <p className="onboard_input_name">Reporting</p>

                  <Select
                    style={{ width: "100%" }}
                    className="blood"
                    placeholder="Reports to"
                    showSearch
                    getPopupContainer={(trigger) => trigger.parentNode}
                    value={reporter || undefined}
                    onChange={(value) =>
                      this.setState({
                        reporter: value,
                        repobj: value,
                      })
                    }
                  >
                    {employeeList.map((employes, i) => (
                      <Option
                        key={i}
                        value={JSON.stringify({
                          emp_id: employes.emp_id,
                          name: employes.name,
                        })}
                      >
                        {employes.name}
                      </Option>
                    ))}
                  </Select>
                </div> */}
                    {/* <InputItem label='Tax Deducted at Source' name='tds' star={false} value={tds} placeholder="Enter TDS number" onChange={this.handleNumberChange} /> */}
                    <div className="text-label-input">
                      {/* <Checkbox
                        className="employeeContentCardTitle"
                        checked={checked}
                        onChange={this.handleCheckBox}
                        name="is_experienced"
                        value={is_experienced}
                      >
                        Experienced
                      </Checkbox> */}
                      <div className="experience d-flex ">
                        <input
                          checked={checked}
                          onChange={this.handleCheckBox}
                          name="is_experienced"
                          value={is_experienced}
                          type="checkbox"
                          id="experience"
                        />
                        <div
                          className="block-label"
                          style={{ color: "#333333", paddingLeft: "5px" }}
                          for="experience"
                        >
                          Experienced
                        </div>
                      </div>
                      {checked ? (
                        <div className="text-label-input experiencedField">
                          <div className="ex_monthField  d-flex">
                            <div className="ex_data">
                              <Input
                                defaultValue={"0"}
                                type="text"
                                size="middle"
                                name="employee_ex_year"
                                maxlength="2"
                                value={this.state.employee_ex_year}
                                onChange={this.yearsOnchange}
                              />
                            </div>
                            <div className="ex_Text">Years</div>
                          </div>
                          <div className="ex_yearField  d-flex">
                            <div className="ex_data">
                              <Input
                                defaultValue={"0"}
                                type="text"
                                size="middle"
                                name="employee_ex_month"
                                maxlength="2"
                                value={this.state.employee_ex_month}
                                onChange={this.monthOnchange}
                              />
                            </div>

                            <div className="ex_Text">Months</div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="employeeContentCards">
                      <div className="experience d-flex ">
                        <input
                          checked={pfchecked}
                          onChange={this.handlepfCheckBox}
                          name="is_PFchecked"
                          value={is_pfchecked}
                          type="checkbox"
                          id="experience"
                        />
                        <div
                          className="block-label"
                          style={{ color: "#333333", paddingLeft: "5px" }}
                          for="experience"
                        >
                          PF Eligible
                        </div>
                      </div>
                      {/* <Checkbox
                        className="employeeContentCardTitle"
                        checked={pfchecked}
                        onChange={this.handlepfCheckBox}
                        name="is_PFchecked"
                        value={is_pfchecked}
                      >
                        PF Eligible
                      </Checkbox> */}
                      {pfchecked ? (
                        <div className="text-label-input">
                          <Input
                            size="large"
                            type="text"
                            placeholder="PF Amount"
                            name="pf_amount"
                            min="0"
                            value={this.state.pf_amount}
                            onChange={this.handleNumberChange}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="employeeContentCards">
                      <div className="experience d-flex ">
                        <input
                          checked={tdschecked}
                          onChange={this.handlepfCheckBox}
                          name="is_TDSchecked"
                          value={is_tdschecked}
                          type="checkbox"
                          id="experience"
                        />
                        <div
                          className="block-label"
                          style={{ color: "#333333", paddingLeft: "5px" }}
                        >
                          TDS Eligible
                        </div>
                      </div>
                      {/* <Checkbox
                        className="employeeContentCardTitle"
                        checked={tdschecked}
                        onChange={this.handlepfCheckBox}
                        name="is_TDSchecked"
                        value={is_tdschecked}
                      >
                        TDS Eligible
                      </Checkbox> */}
                      {tdschecked ? (
                        <div className="text-label-input">
                          <Input
                            type="text"
                            size="large"
                            placeholder="TDS Amount"
                            name="tds_amount"
                            min="0"
                            value={this.state.tds_amount}
                            onChange={this.handleNumberChange}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <Divider orientation="left">Bank Account Details</Divider>
                  <div
                    style={{
                      display: "grid",
                      marginBottom: 30,
                      gridTemplateColumns: "1fr 1fr 1fr",
                      columnGap: 51,
                    }}
                  >
                    <InputItem
                      label="Account Name"
                      star={false}
                      name="acc_name"
                      value={acc_name}
                      placeholder="Enter account name"
                      onChange={this.handleInputChange}
                    />
                    <InputItem
                      label="Account Number"
                      star={false}
                      name="acc_number"
                      maxlength="16"
                      value={acc_number}
                      placeholder="Enter account number"
                      onChange={this.handleNumberChange}
                    />
                    <InputItem
                      label="IFSC Code"
                      star={false}
                      name="ifsc_code"
                      maxlength="11"
                      value={ifsc_code}
                      placeholder="Enter your IFSC Code"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </>
              </TabPane>
              {id ? (
                <TabPane tab="Documents" key="3">
                  <div style={{ marginTop: "50px" }}>
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Date</th>
                          <th>Type</th>
                          <th style={{ padding: "0px 12px 0px 0px" }}>
                            {CREATE(
                              app_type,
                              app_name,
                              <button
                                onClick={() => this.uploadFile(true)}
                                className="probtn float-right"
                              >
                                Add
                              </button>
                            )}
                          </th>
                        </tr>
                      </thead>

                      {this.state.documents.length > 0 ? (
                        <tbody>
                          {this.state.documents.map((document, d) => (
                            <tr key={d}>
                              <td>
                                {" "}
                                {(this.state.currentPage - 1) * 10 + (d + 1)}
                              </td>
                              <td>
                                {isErrorTxt(document?.createdAt)
                                  ? "-"
                                  : showDate(document.createdAt)}
                              </td>
                              <td>{document?.type}</td>

                              <td className="table-action">
                                <div className="tax-add-edit">
                                  <div>
                                    <a
                                      href={document.url}
                                      style={{
                                        marginRight: "10px",
                                        color: "gray",
                                      }}
                                      target="_blank"
                                    >
                                      {" "}
                                      <InfoCircleFilled />{" "}
                                    </a>

                                    <a
                                      onClick={() =>
                                        this.handleEditPage(document)
                                      }
                                    >
                                      {UPDATE(
                                        app_type,
                                        app_name,
                                        <FormOutlined
                                          style={{ color: "#1890ff" }}
                                        />
                                      )}
                                    </a>
                                    <a>
                                      {DELETE(
                                        app_type,
                                        app_name,
                                        <DeleteOutlined
                                          onClick={() =>
                                            this.handleDelete(document)
                                          }
                                          style={{
                                            marginLeft: "20px",
                                            color: "red",
                                          }}
                                        />,
                                        ""
                                      )}
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colspan="4">
                              {" "}
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </TabPane>
              ) : (
                ""
              )}
              {id ? (
                <TabPane tab="Salaries" key="4">
                  <div style={{ marginTop: "50px" }}>
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Last review date</th>
                          <th>Next review date</th>
                          <th>Type</th>
                          <th>Amount</th>
                          <th>Final Income</th>
                          <th style={{ padding: "0px 12px 0px 0px" }}>
                            {CREATE(
                              app_type,
                              app_name,
                              <button
                                className="probtn float-right"
                                onClick={() => this.setModal1Visible(true)}
                              >
                                Add
                              </button>
                            )}
                          </th>
                        </tr>
                      </thead>

                      {salary.length > 0 ? (
                        <tbody>
                          {salary.map((sal, s) => (
                            <tr key={s}>
                              <td>
                                {" "}
                                {(this.state.currentPage - 1) * 10 + (s + 1)}
                              </td>
                              <td>
                                {isErrorTxt(sal?.createdAt)
                                  ? ""
                                  : showDate(sal.createdAt)}
                              </td>
                              <td>
                                {isErrorTxt(sal?.next_review_date)
                                  ? "-"
                                  : showDate(sal.next_review_date)}
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                {isErrorTxt(sal?.type) ? "-" : sal?.type}
                              </td>
                              <td>
                                <div>
                                  {sal?.type === "initial" && (
                                    <div>
                                      {numberWithComma(sal?.salary ?? 0)}
                                    </div>
                                  )}
                                  {sal?.type === "hike" && (
                                    <div>{numberWithComma(sal?.hike ?? 0)}</div>
                                  )}
                                </div>
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                {numberWithComma(sal?.total_amount ?? 0)}
                              </td>

                              {/* <td className="table-action">
                                <div className="tax-add-edit">
                                  <span className="mr-4">
                                    <Link
                                      onClick={() =>
                                        this.reviewDateEditHandler( sal )
                                      }
                                    >
                                      {UPDATE(
                                        app_type,
                                        app_name,
                                        <FormOutlined />
                                      )}
                                    </Link>
                                  </span>
                                </div>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colspan="7">
                              {" "}
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </TabPane>
              ) : (
                ""
              )}

              {id ? (
                <TabPane tab="Pay Slips" key="5">
                  <div style={{ marginTop: "50px" }}>
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Gross Pay</th>
                          <th>Total Deduction</th>
                          <th>Total Net pay</th>
                          <th>Transaction Date</th>
                          <th></th>
                        </tr>
                      </thead>

                      {this.state.payslips.length > 0 ? (
                        <tbody>
                          {this.state.payslips.map((pay, p) => (
                            <tr key={p}>
                              <td>
                                {" "}
                                {(this.state.currentPage - 1) * 10 + (p + 1)}
                              </td>
                              <td>
                                {isErrorTxt(pay?.amounts?.gross_amount)
                                  ? numberWithComma(0)
                                  : numberWithComma(pay?.amounts?.gross_amount)}
                              </td>
                              <td>
                                {pay?.amounts?.total_deduction !== ""
                                  ? decimalWithComma(pay.amounts.total_deduction)
                                  : 0}
                              </td>
                              <td>
                                {pay?.amounts?.net_pay
                                  ? decimalWithComma(pay.amounts.net_pay)
                                  : 0}
                              </td>
                              <td>
                                {pay?.transaction_date
                                  ? showDate(pay.transaction_date)
                                  : "-"}
                              </td>

                              <td className="table-action">
                                <div className="tax-add-edit">
                                  <div>
                                    <a
                                      href="#"
                                      onClick={() => this.download(pay)}
                                      style={{ marginRight: "10px" }}
                                    >
                                      {" "}
                                      <DownloadOutlined />{" "}
                                    </a>

                                    <Link
                                      onClick={() =>
                                        this.PayhandleEditPage(pay)
                                      }
                                    >
                                      {UPDATE(
                                        app_type,
                                        app_name,
                                        <FormOutlined />
                                      )}
                                    </Link>
                                    <a>
                                      {DELETE(
                                        app_type,
                                        app_name,
                                        <DeleteOutlined
                                          onClick={() =>
                                            this.payslipDelete(pay)
                                          }
                                          style={{
                                            marginLeft: "20px",
                                            color: "red",
                                          }}
                                        />
                                      )}
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colspan="6">
                              {" "}
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </TabPane>
              ) : (
                ""
              )}
            </Tabs>
          </div>
        )}

        {this.state.show && (
          <Modal
            title="Add Salary"
            style={{ top: 50 }}
            width={600}
            open={this.state.modal1Visible}
            onOk={() => this.setModal1Visible(false)}
            onCancel={() => this.setModal1Visible(false)}
            footer={[
              <div>
                <button
                  className="secondary_color"
                  onClick={() => this.setModal1Visible(false)}
                >
                  Cancel
                </button>

                <button className="probtn2" onClick={() => this.submitHandle()}>
                  Submit
                </button>
              </div>,
            ]}
          >
            <>
              {this.payslipData(
                "Salary",
                <Input
                  style={{ height: "47px", width: "330px" }}
                  className="onboard-date"
                  name="sal"
                  value={this.state.sal}
                  placeholder="Enter the Salary"
                  onChange={this.handleNumberChange}
                />,
                true
              )}
              {this.payslipData(
                "Current Review Date",
                <DatePicker
                  style={{
                    height: "47px",
                    maxWidth: "330px",
                  }}
                  className="onboard-date"
                  name="from"
                  placeholder="Current review"
                  dateFormat="YYYY-MM-DD"
                  inputReadOnly
                  value={this.state.from}
                  onChange={this.handleOnFromChange}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />,
                true
              )}
              {this.payslipData(
                "Next Review Date",
                <DatePicker
                  name="nextReview"
                  placeholder="Next review"
                  dateFormat="YYYY-MM-DD"
                  inputReadOnly
                  style={{
                    height: "47px",
                    maxWidth: "330px",
                  }}
                  className="onboard-date"
                  value={this.state.nextReview}
                  onChange={this.handleOnnextReviewChange}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />,
                true
              )}
              {this.payslipData(
                "Type",
                <Select
                  className="blood"
                  name="type"
                  onChange={(e) => this.searchInput(e)}
                  value={this.state.type}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  style={{
                    height: "47px",
                    width: "330px",
                  }}
                >
                  <Option value="">type</Option>
                  <Option value="initial">Initial</Option>
                  <Option value="hike">Hike</Option>
                </Select>,
                true
              )}
              <div
                className={this.state.type === "hike" ? "hikeshow" : "hidehike"}
              >
                {this.payslipData(
                  "Hike",
                  <Input
                    style={{
                      height: "47px",
                      width: "330px",
                    }}
                    className="onboard-date"
                    placeholder="Enter the amount..."
                    name="amount"
                    value={this.state.amount}
                    onChange={this.handleNumberChange}
                  />,
                  true
                )}
              </div>
            </>
          </Modal>
        )}

        <Modal
          title="Documents"
          style={{ top: 50 }}
          width={600}
          visible={this.state.modal2Visible}
          onOk={() => this.uploadFile(false)}
          closable={false}
          footer={[
            <div className="d-flex justify-content-between align-items-center">
              <button
                key="cancel"
                className="dec-btn-secondary"
                onClick={() => this.uploadFile(false)}
              >
                Cancel
              </button>
              <button
                key="submit"
                className="dec-btn"
                // onClick={() =>
                //   this.state.document_id ? this.update() : this.submitUpload()
                // }
                onClick={this.submitUpload}
              >
                {this.state.document_id ? "Update" : "Submit"}
              </button>
            </div>,
          ]}
        >
          <div>
            <div className="d-flex align-items-center mb-3">
              <div className="col-4">
                {" "}
                <label className="modal_form_fields_label table-employee-title">
                  Type*
                </label>
              </div>
              <div className="col-4">
                <Select
                  className="blood"
                  style={{
                    height: "47px",
                    width: "300px",
                  }}
                  name="types"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(e) => this.searchInputs(e)}
                  value={this.state.types}
                >
                  <Option value="">Select</Option>
                  <Option value="aadhar">Aadharcard</Option>
                  <Option value="pan">Pancard</Option>
                  <Option value="certificate">Experience</Option>
                </Select>
              </div>
            </div>
            <div
              className={
                this.state.types === "certificate" ? "exshow" : "exhike"
              }
            >
              <div className="d-flex align-items-center mb-3">
                <div className="col-4">
                  {" "}
                  <label className="modal_form_fields_label table-employee-title">
                    Company Name*
                  </label>
                </div>
                <div className="col-6">
                  <Input
                    style={{ width: "19rem", height: 47 }}
                    placeholder="Enter company name..."
                    name="cname"
                    value={this.state.cname}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className="d-flex align-items-center mb-3">
                <div className="col-4">
                  {" "}
                  <label className="modal_form_fields_label table-employee-title">
                    From*
                  </label>
                </div>
                <div className="col-6">
                  <DatePicker
                    name="exFrom"
                    placeholder="From date"
                    dateFormat="YYYY-MM-DD"
                    className="doc-date"
                    style={{ height: 47 }}
                    value={this.state.exFrom}
                    onChange={this.handleOnFromChanges}
                    inputReadOnly
                    getPopupContainer={(trigger) => trigger.parentNode}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="col-4">
                  {" "}
                  <label className="modal_form_fields_label table-employee-title">
                    To*
                  </label>
                </div>
                <div className="col-6">
                  <DatePicker
                    name="exTo"
                    placeholder="To date"
                    dateFormat="YYYY-MM-DD"
                    className="doc-date"
                    style={{ height: 47 }}
                    value={this.state.exTo}
                    inputReadOnly
                    onChange={this.handleToFromChanges}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3">
              <div
                className="modal_form_fields"
                style={{
                  width: "150%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label className="upload-file-label" style={{ width: "100%" }}>
                  <span className="upload-file-icon table-employee-title">
                    <UploadOutlined style={{ marginRight: "5px" }} /> Upload
                  </span>
                  <ReactS3Uploader
                    className="upload-file-input"
                    signingUrl={constants.S3_UPLOADER.SIGNIN_URL}
                    signingUrlMethod="GET"
                    accept=".doc,.docx,.pdf,image/*"
                    s3path={`employees/${this.state.id}/${this.state.types}`}
                    onProgress={this.onProgress}
                    preprocess={this.checkValidImage}
                    onFinish={this.onUploadOffer}
                    uploadRequestHeaders={{
                      "x-amz-acl": "public-read",
                    }}
                    contentDisposition="auto"
                    scrubFilename={(filename) =>
                      `_${moment(new Date()).format(
                        "h:mm:ss a"
                      )}_${filename.replace(/[^\w\d_\-.]+/gi, "")}`
                    }
                    autoUpload={true}
                  />
                </label>

                {this.state.file !== "" ? (
                  <div style={{ marginTop: 10 }}>
                    {" "}
                    {this.state.loadingState ? (
                      <div className="uploaded-image" style={{ width: "80%" }}>
                        <Spin />
                      </div>
                    ) : (
                      <div className="uploaded-image" style={{ width: "80%" }}>
                        {" "}
                        {this.state.file.type !== "application/pdf" ? (
                          <span>
                            <img
                              alt="invalid img url"
                              src={this.state.image}
                              width="330"
                              height="100"
                            />

                            <CloseSquareFilled
                              onClick={() => this.onRemoveOfferImage()}
                            />
                          </span>
                        ) : (
                          <span style={{ width: 330, height: 100 }}>
                            <a href={this.state.image}>View</a>

                            <CloseSquareFilled
                              onClick={() => this.onRemoveOfferImage()}
                            />
                          </span>
                        )}
                      </div>
                    )}{" "}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          title={this.state.payslip_id ? "Update" : "Add Payslip"}
          style={{ top: 50 }}
          // className="payModal"
          width={600}
          open={this.state.payslip1Visible}
          closable={false}
          footer={[
            <div className="d-flex justify-content-between align-items-center">
              <button
                key="cancel"
                className="dec-btn-secondary"
                onClick={() => this.payslipCancel()}
              >
                Cancel
              </button>
              <button
                key="submit"
                className="dec-btn"
                onClick={() => this.submitHandlepay()}
              >
                {this.state.payslip_id ? "Update" : "Submit"}
              </button>
            </div>,
          ]}
        >
          <div className="pay-content">
            {this.payslipData(
              "Basic Payment",
              <InputDataField
                placeholder="Enter the basic payment"
                name="basic_pay"
                value={this.state.basic_pay}
                onchange={this.handleNumberChange}
              />,
              true,
              "payslip"
            )}
          </div>
        </Modal>
        <Modal
          title="Salaries"
          visible={this.state.editReviewDateVisible}
          closable={false}
          footer={[
            <div className="d-flex justify-content-between align-items-center">
              <button
                key="cancel"
                className="dec-btn-secondary"
                onClick={() => this.salaryCancelHandler(false)}
              >
                Cancel
              </button>
              <button
                key="submit"
                className="dec-btn"
                onClick={() => this.salarySubmitHandle()}
              >
                Submit
              </button>
            </div>,
          ]}
        >
          <div>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              className="admin_input"
            >
              <FormItem className="modal_formitem" style={{ width: "145%" }}>
                <div className="modal_form_fields">
                  <label
                    className="modal_form_fields_label table-employee-title"
                    style={{ width: "250px" }}
                  >
                    Review Date
                  </label>
                  <div className="modal_amount" style={{ width: "100%" }}>
                    <DatePicker
                      inputReadOnly
                      name="ReviewDate"
                      placeholder="Review Date"
                      dateFormat="YYYY-MM-DD"
                      className="onboard_date"
                      style={{ marginLeft: -10, width: "100%" }}
                      value={this.state.ReviewDate}
                      onChange={this.handleReviewDateChanges}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                  </div>
                </div>
              </FormItem>

              <FormItem className="modal_formitem" style={{ width: "145%" }}>
                <div className="modal_form_fields">
                  <label
                    className="modal_form_fields_label table-employee-title"
                    style={{ width: "250px" }}
                  >
                    Next Review Date
                  </label>
                  <div className="modal_amount" style={{ width: "100%" }}>
                    <DatePicker
                      inputReadOnly
                      name="nextReviewDate"
                      placeholder="Next Review Date"
                      dateFormat="YYYY-MM-DD"
                      className="onboard_date"
                      style={{ marginLeft: -10, width: "100%" }}
                      value={this.state.nextReviewDate}
                      onChange={this.handleNextReviewDateChanges}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                  </div>
                </div>
              </FormItem>
            </Form>
          </div>
        </Modal>

        <Modal
          title="Relived Date"
          centered
          open={this.state.relieved}
          onCancel={this.cancelRelived}
          footer={[
            <div>
              <Button type="primary" onClick={this.cancelRelived}>
                Cancel
              </Button>
              <Button type="primary" onClick={this.submitRelived}>
                Relived
              </Button>
            </div>,
          ]}
        >
          <div className="modal_form_fields">
            <label
              className="modal_form_fields_label table-employee-title"
              style={{ width: "132px" }}
            >
              Relieved On
            </label>
            <div
              className="modal_amount"
              style={{ position: "relative", width: "100%" }}
            >
              <DatePicker
                dateFormat="MM-dd-yyyy"
                placeholder="Relieved Date"
                name="relieveDate"
                id="relieveDate"
                value={this.state.relieveDate}
                style={{ fontWeight: "500", width: "50%" }}
                showYearDropdown
                showMonthDropdown
                inputReadOnly
                ariaDescribedBy="basic-addon2"
                onChange={this.handleOnRelieved_dateChange}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </div>
          </div>
        </Modal>
        <ModalPopUp
          visible={this.state.deletePayslipItem}
          cancel={this.cancelPayslip}
          submit={this.deletePayslip}
          name="Payslip"
          delete_name={this.state.delete_payslip}
        />
        <ModalPopUp
          visible={this.state.deleteDocumentItem}
          cancel={this.cancelDocument}
          submit={this.deleteDocument}
          name="Document"
          delete_name={this.state.delete_document}
        />
      </Card>
    );
  }
}
