import React,{Component} from 'react';
import { Modal } from 'antd'
const ModalPopUp = ( { visible, cancel, submit, name, delete_name,invoice })=>{
  return(
      <>

          <Modal
              title=""
              open={visible}
              onCancel={cancel}
              footer={[
                  <div className="d-flex justify-content-between align-items-center">
                      <button
                          className="secondary_color"
                          onClick={cancel}
                      >
                          {invoice ?"No": "Cancel"}
                      </button>
                      <button className="dec-btn" onClick={submit}>
                          {invoice ? "Yes" : "Submit"}
                      </button>
                  </div>
              ]}
          >

              <div>
                  <p
                      style={{
                          fontSize: "19px",
                          fontWeight: "600",
                          color: "#333333",
                      }}

                  >
                      {invoice ? "Cancel":"Delete"} {name}
                  </p>
                  <p
                      style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#757575",
                          lineHeight: "24px",
                          marginBottom: "10px",
                      }}
                  >{`Are you sure you want to ${invoice ? "cancel":"delete"} ${delete_name} ?`}</p>
              </div>
          </Modal>
      </>
  )
}

export default ModalPopUp;
