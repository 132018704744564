import { message } from "antd";
import { useState, useEffect } from "react";
import { PhoneNumberUtil } from "google-libphonenumber";
import moment from "moment";
import cookie from "react-cookies";
import queryString from "query-string";
import * as QS from "query-string";
import { Tooltip } from "antd";

export const showSuccess = (responseMsg) => {
  message.success(responseMsg);
};
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const showError = (responseMsg) => {
  message.error(responseMsg);
};
export const number = (value) => {
  let REGEX = /^\d+$/;
  if (!REGEX.test(value)) {
    return true;
  } else {
    return false;
  }
};
export const localStorage = (value) => {
  return localStorage.setItem(value);
};

export const showShortDateFormat = (date) => {
  return moment(date).format("DD MMM").toString();
};

export const showday = (date) => {
  let daate = new Date(date).setFullYear(new Date().getFullYear());
  return moment(new Date(daate)).format("dddd");
};
export const getCookies = (name) => {
  return cookie.load(name);
};
export const setCookies = (token) => {
  return cookie.save(token);
};

export const removeCookies = (token) => {
  return cookie.remove(token, { path: "/" });
};
export const showDate = (date) => {
  return moment(date).format("DD-MM-YYYY");
};
export const showFullDate = (date) => {
  return moment(date).format("DD MMM, YYYY - h:mm a");
};
export const showMonthDate = (date) => {
  return moment(date).format("MM-DD-YYYY");
};
export const showYearMonth = (date) => {
  return moment(date).format("DD-MM-YYYY");
};
export const showDates = (date) => {
  return moment(date).format("YYYY-MM-DD");
};
export const showDayMonthYear = (date) => {
  return moment(date).format("DD MMM YYYY");
};
export const utcdate = (utcDate) => {
  var now = new Date(utcDate);
  var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  if (utcDate === "") return new Date();
  else return moment(new Date(utc)).format("DD-MM-YYYY");
};

export const showTime = (date) => {
  return moment(date).format("h:mm a");
};
export const getDateFormat = (dateString) => {
  return moment(dateString).format("MMMM Do YYYY");
};
export const getMonthFormat = (dateString) => {
  return moment(dateString).format("MMM");
};
export const getMonthYear = (dateString) => {
  return moment(dateString).format("MMM-YYYY");
};
export const getDateMonth = (dateString, month) => {
  const current = new Date();
  const getMonth = new Date(dateString);
  if (month === "last") {
    current.setMonth(getMonth.getMonth() - 1);
  }
  const Month = current.toLocaleString("default", { month: "short" });
  return Month;
};

export const capitlizestring = (string) => {
  const str = string.split("_").map((item) => {
    return item[0].toUpperCase() + item.slice(1);
  });
  return str.join(" ");
};

export const splitWithComma = (string) => {
  const tempArray = [];
  string.map((el) => {
    const ui = el.split(" ").map((eachEl) => {
      return eachEl[0].toUpperCase() + eachEl.slice(1);
    });
    const io = ui.join(" ");
    tempArray.push(io);
  });
  return [tempArray.join(` , `)];
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const getShortMonth = (date) => {
  return moment(date).format("MMM");
};
export const showDateTime = (date) => {
  return moment(date).format("DD MMM YYYY h:mm a");
};
export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};
export const timeToString = (time) => {
  return moment(time, "HH:mm").format("HHmm");
};

export const stringToTime = (time) => {
  return moment(time, "HHmm").format("HH:mm");
};

export const convertTimeFormat = (time) => {
  return moment(time, "HH:mm").format("HHmm");
};

export const showMomentFormat = (date) => {
  return moment(date).format("DD-MM-YYYY");
};

export const convertIsoSting = (date) => {
  return moment(date).toISOString();
};

export function getStartOfDay(date) {
  if (date !== "" && date !== isNaN) {
    return moment
      .utc(moment(date, "DD-MM-YYYY").startOf("day"))
      .local()
      .toISOString();
  } else return "";
}
export function getEndOfDay(date) {
  if (date !== "" && date !== isNaN)
    return moment
      .utc(moment(date, "DD-MM-YYYY").endOf("day"))
      .local()
      .toISOString();
  else return "";
}

export const setActive = (value) => {
  localStorage.setItem("menu", value);
};

export const isObjectEmpty = (obj) => {
  if (Object.keys(obj).length === 0) {
    return true;
  } else {
    return false;
  }
};
export const filterQuery = (query, data) => {
  let filter = query;
  if (filter === query) {
    return `&${query}=${data}`;
  }
};
export function getIsoString(date) {
  return date !== "" && date !== isNaN ? moment(date).format("YYYY-MM-DD") : "";
}

export const isCheckEmpty = (value) => {
  if (value === undefined || value === null || value === "") {
    return true;
  } else {
    return false;
  }
};
export const showFullMonthName = (key) => {
  return (
    <div>
      {key === "Jan" ? "January" : ""}
      {key === "Feb" ? "February" : ""}
      {key === "Mar" ? "March" : ""}
      {key === "Apr" ? "April" : ""}
      {key === "May" ? "May" : ""}
      {key === "Jun" ? "June" : ""}
      {key === "Jul" ? "July" : ""}
      {key === "Aug" ? "August" : ""}
      {key === "Sep" ? "September" : ""}
      {key === "Oct" ? "October" : ""}
      {key === "Nov" ? "November" : ""}
      {key === "Dec" ? "December" : ""}
    </div>
  );
};
export const isDisabledDate = (current) => {
  return current < moment().subtract(1, "days").endOf("day");
};

export const randomString = (len) => {
  let charSet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  for (let i = 0; i < len; i++) {
    let randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
};

export const getFixed = (value) => {
  return Math.round(parseFloat(value) * 100) / 100;
};

//Validations
export const isInvalidName = (value) => {
  let nameRegex = /^[a-zA-Z,. ]+$/;
  if (!nameRegex.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const isInvalidAlphaNumeric = (value) => {
  let alphaNumbericRegex = /^[a-zA-Z0-9 ]+$/;
  if (!alphaNumbericRegex.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const isInvalidNumber = (phoneNumber, country) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    return !phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber, country));
  } catch (e) {
    return true;
  }
};

export const isInvalidImage = (file) => {
  let imageType = "image/*";
  let result = false;
  if (!file.type.match(imageType)) {
    result = true;
  }
  return result;
};

export const isLargeFile = (file) => {
  return file.size / 1024 / 1024 > 5;
};

export const isInvalidEmail = (email) => {
  let eRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!eRegex.test(email)) return true;
  else return false;
};

export const checkNameLength = (text) => {
  return checkTxtLength(text, 3);
};

export const checkTxtLength = (text, count) => {
  return text.length < count;
};

export const isErrorTxt = (text) => {
  return (
    text === undefined ||
    text === "" ||
    text === null ||
    text === 0 ||
    text === Infinity ||
    text === NaN
  );
};
export const isErrorNum = (text) => {
  return (
    text === undefined ||
    text === "" ||
    text === null ||
    text === 0 ||
    text === Infinity ||
    isNaN(text)
  );
};

export const removeUnderscores = (text) => {
  return text.replace(/_/g, " ");
};

export const statusColor = (status) => {
  if (status === "hold") {
    return <span className="blue">{status}</span>;
  } else if (status === "converted") {
    return <span className="green">{status}</span>;
  } else if (status === "rejected") {
    return <span className="red">{status}</span>;
  } else if (status === "pending") {
    return <span className="yellow">{status}</span>;
  }
};

export const projectColor = (status) => {
  if (status === "hold") {
    return <span className="blue">{status}</span>;
  } else if (status === "converted") {
    return <span className="green">{status}</span>;
  } else if (status === "rejected" || status === "removed") {
    return <span className="red">{status}</span>;
  } else if (status === "pending") {
    return <span className="yellow">{status}</span>;
  } else if (status === "active") {
    return <span className="green">{status}</span>;
  }
};

export const empStatusColor = (status) => {
  if (status === "active") {
    return <span className="green">{status}</span>;
  } else if (
    status === "fired" ||
    status === "abscond" ||
    status === "offline"
  ) {
    return <span className="red">{status}</span>;
  } else if (status === "relieved") {
    return <span className="blue">{status}</span>;
  }
};

export const localNumeration = (value) => {
  return value.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
};

export const onboardStatusColor = (status) => {
  if (status === "accept") {
    return <span className="green">Accepted</span>;
  } else if (status === "onboard") {
    return <span className="onboard-color">Onboarded</span>;
  } else if (status === "reject") {
    return <span className="orange">Rejected</span>;
  } else if (status === "expired") {
    return <span className="orange">{status}</span>;
  } else if (status === "pending") {
    return <span className="purple">Pending</span>;
  } else if (status === "cancel") {
    return <span className="red">Canceled</span>;
  } else if (status === "failed") {
    return <span className="red">{status}</span>;
  }
};

export const leaveType = (type) => {
  if (type === "wfh") {
    return <span>Work From Home</span>;
  } else if (type === "comp_off") {
    return <span>Comp off</span>;
  } else if (type === "leave") {
    return <span>Casual Leave</span>;
  } else if (type === "sick") {
    return <span>Sick Leave</span>;
  } else if (type === "vacation") {
    return <span>Vacational Leave</span>;
  }
};

export const amountStatus = (status) => {
  if (status === "paid") {
    return <span className="green">Paid</span>;
  } else if (status === "unpaid") {
    return <span className="unpaid-color">UnPaid</span>;
  } else if (status === "cancelled") {
    return <span className="red">Cancelled</span>;
  }
};

export const projectStatusColor = (status) => {
  if (status === "active" || status === "completed") {
    return <span className="green">{status}</span>;
  } else if (status === "hold") {
    return <span className="status-recall">{status}</span>;
  } else if (status === "discontinued") {
    return <span className="status-reject">{status}</span>;
  } else if (status === "canceled") {
    return <span className="status-cancel">{status}</span>;
  }
};

export const project_statusColor = (status) => {
  if (status === "active" || status === "completed") {
    return <span className="project_complete">{status}</span>;
  } else if (status === "hold") {
    return <span className="project_hold">{status}</span>;
  } else if (status === "in_progress") {
    return <span className="project_progress">{status}</span>;
  } else if (status === "pending") {
    return <span className="project_pending">{status}</span>;
  } else if (status === "cancelled" || status === "discontinued") {
    return <span className="project_cancelled">{status}</span>;
  }
};

export const permissionStatus = (status, reason) => {
  if (status === "accepted") {
    return <span className="green">{status}</span>;
  } else if (status === "requested") {
    return <span className="status-request">{status}</span>;
  } else if (status === "cancelled") {
    return <span className="status-cancel">{status}</span>;
  } else if (status === "recalled") {
    return <span className="status-recall">{status}</span>;
  } else if (status === "rejected") {
    return (
      <div className="status-reject">
        {status}
        <Tooltip placement="rightTop" title={reason}>
          <span>
            <i
              className="fa fa-info-circle"
              style={{ fontSize: 20, color: "gray", marginLeft: 10 }}
            ></i>
          </span>
        </Tooltip>
      </div>
    );
  }
};

export const getStatusColor = (status) => {
  if (status === "inprogress") {
    return <span style={{ color: "#F48F23" }}>Inprogress</span>;
  } else if (status === "pending") {
    return <span style={{ color: "#F8B146" }}>Pending</span>;
  } else if (status === "cancelled") {
    return <span style={{ color: "#EF4733" }}>Cancelled</span>;
  } else if (status === "deleted") {
    return <span style={{ color: "#EF4733" }}>Deleted</span>;
  } else if (status === "on Hold") {
    return <span style={{ color: "#619FFC" }}>Onhold</span>;
  } else if (status === "active") {
    return <span style={{ color: "#009C22" }}>Active</span>;
  } else if (status === "completed") {
    return <span style={{ color: "#009C22" }}>Completed</span>;
  } else {
    return <span style={{ color: "#009C22" }}>status</span>;
  }
};

export const expenseAmount = (status, amount) => {
  if (status === "paid") {
    return <span className="green">{numberWithComma(amount)}</span>;
  } else if (status === "unpaid") {
    return <span className="red">{numberWithComma(amount)}</span>;
  }
};
export const isInvalidPan = (panNumber) => {
  let panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  if (!panRegex.test(panNumber)) return true;
  else return false;
};

export const isInValidIFSC = (ifsc) => {
  let ifscRejex = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;
  return ifsc === "" || !ifscRejex.test(ifsc);
};

export const isInvalidGSTNumber = (gstNumber) => {
  let gstRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
  if (!gstRegex.test(gstNumber)) return true;
  else return false;
};

export const isInValidActNum = (accountNumber) => {
  return (
    accountNumber.length < 5 ||
    parseInt(accountNumber) === 0 ||
    isNaN(parseInt(accountNumber))
  );
};
export function returnThirdDegreeObjValue(masterobj, obj, key, postion) {
  if (
    masterobj &&
    masterobj[obj] &&
    masterobj[obj][key] &&
    masterobj[obj][key][postion]
  )
    return masterobj[obj][key][postion];
  else return "Not Provided";
}
export function returnSecondDegreeObjValue(obj, key, postion) {
  if (obj && obj[key] && obj[key][postion]) return obj[key][postion];
  else return "Not Provided";
}
export function returnFirstDegreeObjValue(obj, key) {
  if (obj && obj[key]) return obj[key];
  else return "Not Provided";
}
export const decimalWithComma = (x) => {
  let value = x;
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });
  if (isNaN(value)) value = 0;
  return formatter.format(value?? 0);
};

export function downloadAllData(response, filename) {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(new Blob([response]));
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  // response.blob().then(blob => {
  //         let url = window.URL.createObjectURL(blob);
  //         let a = document.createElement('a');
  //         a.href = url;
  //         a.download = filename;
  //         a.click();
  //       })
}

export const currency = (x) => {
  return <span>&#x20B9; {x}</span>;
};

export const numberWithComma = (x) => {
  let value = Math.round(x);
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });
  if (isNaN(value)) value = 0;
  return formatter.format(value.toFixed() ?? 0);
};

export const getCommaSeperator = (x) => {
  let value = Math.round(x);
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });
  return formatter.format(value.toFixed());
};

export const numberWithCurrency = (x) => {
  let value = Math.round(x);

  return <span>{value.toLocaleString()}</span>;
};
export const numberWithCommas = (x) => {
  let value = Math.round(x);
  let fixed = value.toFixed(2);
  return <span> {fixed}</span>;
};
export const numberWithRupees = (x) => {
  let value = Math.round(x);
  return <span>{value.toLocaleString()}</span>;
};
// export const numberFormatWithCurrency = (x, currency, tofix) => {
//   let round = Math.round(x);
//   if (x && currency === "inr" && tofix === 0) {
//     var currency = new Intl.NumberFormat("en-IN",{
//       style: "currency",
//       currency: "INR",
//       minimumFractionDigits: 0,
//       maximumFractionDigits: 0,
//     }).format(x);
//     return <span>{`₹${currency}`}</span>;
//   } else if (x && currency === "inr") {
//     var currency = new Intl.NumberFormat("en-IN", {
//       style: "currency",
//       currency: "INR",
//       maximumFractionDigits: 0, minimumFractionDigits: 0
//     }).format(x);
//     return currency;
//   } else if (x && currency === "eur") {
//     var currency = new Intl.NumberFormat("en-US", {
//       style: "currency",
//       currency: "EUR",
//       minimumFractionDigits: 0,
//       maximumFractionDigits: 0,

//     }).format(x);
//     return currency;
//   } else if ((x && currency === "usd") || currency === "cad") {
//     var currency = new Intl.NumberFormat("en-US", {
//       style: "currency",
//       currency: "USD",
//       minimumFractionDigits: 0,
//       maximumFractionDigits: 0,

//     }).format(x);
//     return currency;
//   } else {
//     var currency = new Intl.NumberFormat("en-IN",{
//       style: "currency",
//       currency: "INR",
//       minimumFractionDigits: 0,
//       maximumFractionDigits: 0,

//     }).format(x);
//     return currency;
//   }
// };
export const numberFormatWithCurrency = (x, currency, tofix) => {
  let value;
  if (toString(x).indexOf(".")) {
    value = "decimal";
  }
  let result = parseFloat(x).toFixed(value === "decimal" ? 2 : 0);

  let round = Math.round(x);
  if (x && currency === "inr" && tofix === 0) {
    var currency = new Intl.NumberFormat("en-IN").format(result);
    return <span>{`₹${currency}`}</span>;
  } else if (x && currency === "inr") {
    var currency = new Intl.NumberFormat("en-IN").format(result);
    return currency;
  } else if (x && currency === "eur") {
    var currency = new Intl.NumberFormat("en-US").format(result);
    return currency;
  } else if ((x && currency === "usd") || currency === "cad") {
    var currency = new Intl.NumberFormat("en-US").format(result);
    return currency;
  } else {
    var currency = new Intl.NumberFormat("en-IN").format(result);
    return currency;
  }
};
export const showLongDateFormat = (date) => {
  return moment(date).format("DD MMM YYYY");
};

export const totalLeaveDayCalculater = (data) => {
  if (data.sub_type === "full") return data.total_days;
  else if (data.sub_type === "first_half") return (data.total_days = 0.5);
  else if (data.sub_type === "second_half") return (data.total_days = 0.5);
  else if (data.total_days) return data.total_days;
  else return "-";
};

export const isError = (text) => {
  return (
    text === undefined || text === "" || text === null || text === Infinity
  );
};
export const showDay = (date) => {
  return new Date(date).toLocaleString("default", { weekday: "long" });
};

export const convertIsoDateToDays = (from, to) => {
  const currentDate = new Date(from);
  const targetDate = new Date(to);
  const timeDifference = targetDate.getTime() - currentDate.getTime();
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference + 1;
};

export const showCurrency = (symbol) => {
  if (symbol === "inr") {
    return "₹";
  } else if (symbol === "eur") {
    return "€";
  } else if (symbol === "aed") {
    return "AED ";
  } else if (symbol === "usd" || symbol === "cad") {
    return "$";
  } else {
    return "";
  }
};

export const parseQueryStrings = (qs) => {
  return queryString.parse(qs);
};

export const returnSearchObject = (search) => {
  return parseQueryStrings(search);
};

export const pushToFilteredPage = (props, query) => {
  const values = Object.entries(query);

  for (let key in query) {
    if (query[key] === "") {
      delete query[key];
    }
  }

  props.history.push({
    pathname: props.location?.pathname,
    search: QS.stringify(query),
  });
};

export const buildQueryString = (params) => {
  let newParams = null;
  // delete params.value;
  // delete params.type;
  if (params) {
    newParams = [];
    for (let key in params) {
      if (params[key] === "") {
        delete params[key];
      } else {
        newParams.push(`${key}=${params[key]}`);
      }
    }
    return (newParams = "?" + newParams.join("&"));
  }
};

export const returnExperience = (experience_str, join_date) => {
  const TOTALMONTHS = 12;
  let date = join_date;
  let experience;
  if (date) {
    let selectedDate = moment(date);
    let today = moment();
    let exp_in_month;
    let current_exp = today.diff(selectedDate, "months");
    let get_exp = experience_str;
    if (get_exp) {
      let value = get_exp.includes("-");
      if (value === false) {
        let years_to_month = get_exp * 12;
        exp_in_month = parseInt(years_to_month);
      } else {
        let split = get_exp.split("-").join(".") * 12;
        exp_in_month = parseInt(split.toFixed(0));
      }
      let result = current_exp + exp_in_month;
      if (result < TOTALMONTHS) {
        experience = `${result} ${result > 1 ? "months" : "month"}  `;
        return experience;
      } else if (result > TOTALMONTHS) {
        let month = result % TOTALMONTHS;
        let year = Math.floor(result / TOTALMONTHS);
        experience =
          result &&
          `${year} ${year == 1 ? "year" : "years"}  ${
            month > 0 ? `${month} ${month > 1 ? "months" : "month"}` : ""
          }`;
        return experience;
      } else {
        experience = `1 year`;
        return experience;
      }
    }
  }
};

export const dateLabels = (expenses, invoices) => {
  let dateList = [];
  if (expenses || invoices) {
    if (expenses.length > 0) {
      expenses.forEach((el) => {
        dateList.push(el.x);
      });
    }
    if (invoices.length > 0) {
      invoices.forEach((el) => {
        dateList.push(el.x);
      });
    }
  }
  let uniqueDates = dateList.filter((el, i, arr) => {
    return arr.indexOf(el) === i;
  });

  let labels = uniqueDates.sort((a, b) => Date.parse(a) - Date.parse(b));

  return labels;
};
export const monthLables = (expenses, invoices) => {
  let month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let monthList = [];
  if (expenses || invoices) {
    if (expenses.length > 0) {
      expenses.forEach((el) => {
        monthList.push(el.x);
      });
    }
    if (invoices.length > 0) {
      invoices.forEach((el) => {
        monthList.push(el.x);
      });
    }
    let uniqueMonth = monthList.filter((el, i, ar) => {
      return ar.indexOf(el) === i;
    });
    let label = uniqueMonth.sort((a, b) => {
      return month.indexOf(a) - month.indexOf(b);
    });
    return label;
  }
};
export const showFullName = (name) => {
  if (
    name !== null &&
    name !== undefined &&
    name.last_name !== undefined &&
    name.first_name !== undefined
  ) {
    return name.first_name + " " + name.last_name;
  } else {
    return "";
  }
};
export const isCombinedAlphaNumeric = (value) => {
  let regex = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z\d]{2,}$/;
  if (!regex.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const daysCount = (from, to) => {
  var start = moment(from);
  var end = moment(to);

  return end.diff(start, "days");
};
