import React from "react";
import { DatePicker } from "antd";
import { subDays, addDays } from "date-fns";
import Calendar from "../../assets/fi-rr-calendar (2).png";

const DateItem = ( { label, name, placeholder, value, onChange, star, interview } ) => {
  return (

    <div className="input-item-label">
      <label className="block-label">{label}<span className="star">{star ? '*' : ''}</span></label>

      <DatePicker
        value={value}
        name={name}
        onChange={onChange}
        disableDate={interview ? ( d ) =>
          !d ||
          d.isAfter( addDays( new Date(), 0 ) ) ||
          d.isSameOrBefore( subDays( new Date(), 60 ) ) : ''
        }
        inputReadOnly
        placeholder={placeholder}
        className="onboard-dates"
        dateFormat="YYYY-MM-DD"
        allowClear={false}
        getPopupContainer={trigger => trigger.parentNode}
        suffixIcon={
          <div>
            <img src={Calendar} alt="date" />
          </div>
        }
      />
    </div>
  );
};

export default DateItem;
