import React, { useState, useRef, useEffect, useParams, Fragment } from "react";
import trash from "../../assets/delete_trash_icon.svg";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import {
  showDayMonthYear,
  projectColor,
  useDebounce,
  showLongDateFormat,
  statusColor,
  buildQueryString,
  capitalize
} from "../../constants/Utils";
import layer from "../../assets/Layer 2.svg";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { DatePicker, Drawer, Spin } from "antd";
import TopHeader from "../../components/ui/TopHeader";
import moment from "moment";
import close from "../../assets/close_symbol.svg";
import { message, Empty,Modal } from "antd";
import AntSelect from "antd/lib/select";
import Search from "../../components/ui/search";
import TableField from "../../components/ui/tableField";
import Filter from "../../components/ui/filter";
import { Input } from "reactstrap";
import edit from "../../assets/fi-rr-pencil.svg";



const { Option } = AntSelect;

const TeamMembers = (props) => 
{
  const projectId = props.project_id;

  //DropDown using antDesign
  const [occurenceData, setoccurenceData] = useState([
    { value: "this_month", type: "This Month" },
    { value: "this_year", type: "This Year" },
    { value: "last_month", type: "Last Month" },
    { value: "last_year", type: "Last Year" },
  ]);
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    search_term: "",
    date_filter: "this_year",
    status: "active",
    limit: 10,
  });
  const [statusData, setStatusData] = useState([
    { value: "Active", key: "active" },
    { value: "Removed", key: "removed" },
  ]);
  const [teamSelect, setTeamSelect] = useState([]);


  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [removeMember, setRemoveMember] = useState(false);
  const [removeData, setRemoveData] = useState(null);


  const [teamMemberAdd, setTeamMemberAdd] = useState({
    project: projectId,
    employee_id: "",
    employee: "",
    name: "",
    role_id: "",
    startDate: "",
    description: "",
  });
  const editRef1 = useRef(null);

  const [teamList, setTeamList] = useState(null);
  const [roleList, setRoleList] = useState(null);
  const [total, setTotal] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalDoc, setTotalDoc] = useState(null);

  const [editVisible, setEditVisible] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [totalCount, setTotalCount] = useState(null);

  const [visibleTeamAdd, setVisibleTeamAdd] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [emp, setEmp] = useState(null);
  const [role, setRole] = useState(null);

  //pagination
  const [itemPerPage, setItemPerPage] = useState("5");
  const [currentPage, setCurrentPage] = useState(1);
  //
  const [id, setId] = useState("");
  let countnext = total;
  let countprev = total - (total - 1);
  if (currentPage > 1) {
    countnext = (currentPage - 1) * 10 + total;
    countprev = countnext - (total - 1);
  }
  // const current_date = moment()
  const current_date = moment(props.startDate).format("YYYY-MM-DD");

  useEffect(() => {
    getTeamMemberList(projectId);
  }, [queryStatus]);

  const getTeamMemberList = () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      APIGATEWAY.get(
        `project/team/list/${projectId}` + buildQueryString(queryStatus),
        (response) => {
          if (response.success) {
            setLoading(false);
            setTeamList(response.data.teams);
            setTotalDoc(response.data.totalCount);
            setTotal(response.data?.teams?.length);

          }
          resolve(response.data.teams);
        }
      );
    });
  };

  const fetchEmployees = () => {
    return new Promise((resolve, reject) => {
      const queryParams = "";
      APIGATEWAY.get("employees/all" + queryParams, (response) => {
        if (!response.success) return reject(false);
        const employees = response.data.employees;

          const filteredArray=employees.filter((item1)=>{
          return  !teamList.some(item2=>   item2.status === 'active' && item2.employee_id ===item1.emp_id)
          })
        setEmployeeList(filteredArray);
          
        setTeamSelect(filteredArray.map((list)=>{
            return {label:list.name,
                    value:list.emp_id  
              }
          }))
        resolve(filteredArray);
      });
    });
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    setDropdownMenu(!dropdownMenu);
    setId(id);
    // setEditVisible((prevEditVisible) => (prevEditVisible === id ? null : id));
    // fetchEmployees();
  };

  const handleSelectStatus = (e) => {
    setQueryStatus((prev) => ({ ...prev, status: e }));
  };

  const handleDateFilter = (e) => {
    setQueryStatus((prev) => ({ ...prev, date_filter: e }));
  }
  const handleClickOutside = (event) => {
    if (editRef1.current &&  !event.target.classList.contains('menu-bar-icon')
    ) {
      setEditVisible(false);
    }
  };

  const handleSearchTerm = (e) => {
    let searchTerm
    const value = e.target.value;
    setLoading(true);
    clearTimeout(searchTerm);
    searchTerm = setTimeout(() => {
      setQueryStatus((prev) => ({ ...prev, search_term: value }));
    }, 1000);
  };

  // const handleClickOutside = (event) => {
  //   if (editRef1.current && !editRef1.current.contains(event.target)) {
  //     setEditVisible(false);
  //   }
  // };

  const handleEdit = (id) => {
    fetchEmployees()
    APIGATEWAY.get(`project/team/detail/${id}`, (response) => {
      if (response.success) {
        setVisibleTeamAdd(true);
        const data = response.data.team[0];
        setTeamMemberAdd({
          project: projectId,
          employee_id:data?.employee_id,
          employee: data?.employee._id,
          name: data.employee.name,
          role_id: data.role_id.role_id,
          startDate: data.startDate,
          description: data.description,
        });
      //   setTeamSelect({
      //       label:data.employee.name,
      // value:data?.employee_id
      //   })
        setRole(data.role_id.name);
        setTeamId(data._id);
      }
    });
  };

  const handleDelete = (data) => {
    const body = {
      status: "removed",
      name: data?.employee?.name,
    };
    APIGATEWAY.patch(`project/team/status/${data?._id}`, body, (response) => {
      if (response.success) {
        setLoading(false);
        setRemoveMember(false);

        setDropdownMenu(false)
        // setVisibleTeamAdd(true);
        // const data = response.data.team[0];
        getTeamMemberList();
        // setTeamMemberAdd({
        //   project: projectId,
        //   employee_id: data?.employee_id,
        //   employee: data?.employee._id,
        //   // name: data.employee.name,
        //   // role_id: data.role_id.role_id,
        //   startDate: data.startDate,
        //   description:data.description
        // });
        // setRole(data.role_id.name)
        // setTeamId(data._id);
      }
    });
  };

  const handleDateChange = (event) => {
    setTeamMemberAdd({ ...teamMemberAdd, startDate: event });
  };


  const confirmPopup = (data) => {
setRemoveData(data)
    setRemoveMember(true);

  };


  const cancelConfirmPopup = () => {
    setRemoveMember(false);
    setDropdownMenu(false)
  };


  const memberDescriptionAdd = (event, type) => {
    setTeamMemberAdd({ ...teamMemberAdd, description: event.target.value });
  };

  const addEmployee = (event) => {
    setEmp(
      employeeList.find((item) => {
        if (item.emp_id === event) {
          setTeamMemberAdd({
            ...teamMemberAdd,
            role_id: item?.role?.role_id,
            employee_id: item?.emp_id,
            employee: item?._id,
            name: item?.name,
          });
          setRole(item.role.name);
        }

      })
    );
  };



  const closeDrawer = (e) => {
    e.preventDefault();
    setDropdownMenu(false);
    return new Promise((resolve, reject) => {
      setTeamMemberAdd({
        project: projectId,
        employee_id: "",
        employee: "",
        name: "",
        role_id: "",
        startDate: "",
        description:''
      });
      setRole("");
      setVisibleTeamAdd(false);
      resolve(true);
    });
  };

  const createOthers = (e, id) => {
    e.preventDefault();
    let body;
    body = {
      startDate: moment(teamMemberAdd.startDate),
      project: projectId,
      ...teamMemberAdd,
    };
    setBtnLoader(true);

    if (id) {
      APIGATEWAY.patch(`project/team/${id}`, body, (response) => {
        setBtnLoader(false);
        setLoading(false);
        if (response.success) {
          message.success(`member updated successfully`);
          closeDrawer(e).then((res) => {
            setTeamMemberAdd({
              project: projectId,
              employee_id: "",
              employee: "",
              name: "",
              role_id: "",
              startDate: "",
            });
            getTeamMemberList(projectId);
          });
        }
      });
    } else {
      APIGATEWAY.post(`project/team`, body, (response) => {
        if (response.success) {
          setLoading(false);

          message.success(`member added successfully`);
          closeDrawer(e).then((res) => {
            setBtnLoader(false);
            setTeamMemberAdd({
              project: projectId,
              employee_id: "",
              employee: "",
              name: "",
              role_id: "",
              startDate: "",
            });
            getTeamMemberList(projectId);
          });
        }
      });
    }
  };
  const handleClickShowDrawer = () => {
    fetchEmployees();
    setVisibleTeamAdd(true);
  };

  const next = (prevPage) => {
    setCurrentPage(prevPage + 1);
    setQueryStatus((prev) => ({ ...prev, page: prevPage + 1 }));
    setLoading(true);
  };

  const previous = () => {
    setCurrentPage(currentPage - 1);
    setQueryStatus((prev) => ({ ...prev, page: currentPage - 1 }));
    setLoading(true);
  };

  const pageLimitOnchange = (value) => {
    setLoading(true);
    setQueryStatus((prev) => ({ ...prev, limit: value }));
  };

  const checkStartDate = (e) => {
    if (!teamMemberAdd.startDate) return e.isBefore(current_date);
    else if (teamMemberAdd.startDate) return e.isBefore(current_date);
  }

  return (
    <div className="milestone-container">
      <div className="searchbar-container ">
        <Search
          details={queryStatus}
          outerDivClassName="mr-16 w_20"
          placeholder="Search"
          searchClassName=""
          onSearchChange={handleSearchTerm}
        ></Search>
        <Filter
          occurenceData={occurenceData}
          occurenceName="occurence"
          occurenceSelect={queryStatus.date_filter}
          onOccurenceChange={handleDateFilter}
          statusData={statusData}
          statusName="status"
          statusSelect={queryStatus.status}
          statusOnChange={handleSelectStatus}
          primaryButton='+ Add Member'
          primaryClick={handleClickShowDrawer}
        />
      </div>

      {loading ? (
        <div className="spinning-star1">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {teamList?.length > 0 ? (
            <div>
              <table className="table-container">
                <thead>
                  <tr className="tablehead-row">
                    <th className="small-tablehead">Employee Name</th>
                    <th className="mid-tablehead">Role</th>
                    <th className="mid-tablehead">Start date</th>
                    <th className="mid-tablehead">Status</th>
                    <th className="option-tablehead">{""}</th>
                  </tr>
                </thead>
                <tbody>
                  {teamList.map((data) => (
                    <tr key={data.id} className="table-datarow">
                      <td className="table-datastitle">
                        {data?.employee?.name}
                      </td>
                      <td className="data-startend">
                        {data?.role_id.name ?? "-"}
                      </td>
                      <td className="data-startend">
                        {showDayMonthYear(data?.startDate) ?? "-"}
                      </td>
                      <td style={{ color : data.status === 'active' ? 'green' : 'red' }} className="data-startend">
                        {capitalize(data?.status) ?? "-"}
                      </td>
                      {
                        <td className="table-action">
                          <div className="tax-add-edit">
                            <span className="ml-5">
                              <div className="dropdown-head">
                                <div className="dropbtn-head">
                                  {data?.status === "active" && (
                                    <img
                                      onClick={(e) =>
                                        handleEditClick(e, data?._id)
                                      }
                                      src={layer}
                                      alt="profile"
                                      className="menu-bar-icon"
                                    />
                                  )}
                                </div>
                                {id === data._id && dropdownMenu && (
                                  <Fragment>
                                    <div
                                      className="overflow-overlay"
                                      onClick={() => setDropdownMenu(false)}
                                    >
                                      {" "}
                                    </div>
                                    <div className="dropdown-content-heads">
                                      <div style={{ width: "170px" }}>

                                      <div
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleEdit(data._id);
                                        }}
                                        className="d-flex_al_c action-menu-bar on_hover"
                                      >
                                        <div>
                                          <img
                                            alt="edit"
                                            style={{
                                              width: "15px",
                                              marginRight: "10px",
                                            }}
                                            src={edit}
                                          ></img>
                                        </div>
                                        <div className="light">
                                          <div className="m_0 p_0 anchor">
                                            Edit
                                          </div>
                                        </div>
                                      </div>
                                          <div
                                            className="d-flex_al_c action-menu-bar on_hover"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              confirmPopup(data)

                                            }}
                                          >
                                            <div>
                                              <img
                                                alt="delete"
                                                style={{
                                                  width: "15px",
                                                  marginRight: "10px",
                                                  marginBottom:"3px",
                                                }}
                                                src={trash}
                                              ></img>
                                            </div>
                                            <div className="light">
                                              <div className="m_0 p_0 anchor">
                                                Remove
                                              </div>
                                            </div>
                                          </div>
                                      </div>

                                
                                    </div>
                                  </Fragment>
                                )}
                              </div>
                            </span>
                          </div>
                        </td>
                      }
                    </tr>
                  ))}
                </tbody>
              </table>
              {teamList.length > 0 && (
                <TableField
                  data={teamList}
                  countprev={countprev}
                  countnext={countnext}
                  currentPage={currentPage}
                  ClickPrev={() => {
                    previous();
                  }}
                  ClickNext={() => next(currentPage)}
                  total={total}
                  overAll={totalDoc ?? 0}
                  pagelimit={queryStatus.limit}
                  pagelimitHandle={pageLimitOnchange}
                />
              )}
            </div>
          ) : (
            <table className="table-container">
              <thead>
                <tr className="tablehead-row">
                  <th className="small-tablehead">Employee Name</th>
                  <th className="mid-tablehead">Role</th>
                  <th className="mid-tablehead">Start date</th>
                  <th className="mid-tablehead">Status</th>
                  <th className="option-tablehead">{""}</th>
                </tr>
              </thead>
              <tbody className="empty-message">
                <tr>
                  <td colSpan="6">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      )}


      <Modal
            title="Remove Member"
            open={removeMember}
            onCancel={cancelConfirmPopup}
            footer={[
              <div className="d-flex justify-content-between align-items-center">
                <button
                  className="secondary_color"
                  onClick={cancelConfirmPopup}
                >
                  Cancel
                </button>
                <button className="dec-btn" onClick={()=>handleDelete(removeData)}>
                  Submit
                </button>
              </div>
            ]}
          >
              <div>
                <p>Are you sure you want to remove the {removeData?.employee?.name}?</p>
            </div>
        
          </Modal>


      <Drawer
        placement="right"
        open={visibleTeamAdd}
        onClose={(e) => closeDrawer(e)}
        className="Milestones-main-drawer"
      >
        <div className="Milestone-drawer-container">
          <header>
            <h5 className="add-tax">Team Members</h5>
            {/* 
              <GrClose className="MilestoneClose-icon" />
             */}
            <span
              onClick={(e) => closeDrawer(e)}
              className="MilestoneClose-icon"
              style={{ cursor: "pointer" }}
            >
              <img src={close} className="MilestoneClose-img" alt="close"></img>
            </span>
          </header>
          <form>
            <div className="tax-label mb_24">
              <label className="mb_15">
                Select Employee <span className="star">*</span>
              </label>
              <AntSelect
                onChange={(e) => addEmployee(e)}
                className="projectStatus"
                style={{ width: "100%", borderRadius: "7px" }}
                getPopupContainer={(trigger) => trigger.parentNode}
                // defaultValue={teamMemberAdd.name}
                value={teamMemberAdd.name}
                showSearch
                showArrow
                options={teamSelect}
              >
              </AntSelect>
            </div>

            <div className="tax-label mb_24">
              <label className="mb_15">
                Role 
              </label>
              <Input
                className="projectStatus"
                style={{ width: "100%", borderRadius: "7px" }}
                getPopupContainer={(trigger) => trigger.parentNode}
                value={role}
                disabled
                showSearch
                showArrow
              ></Input>
            </div>
            <div className="tax-label">
              <label>Description</label>
              <div className="tax-label-input">
                <textarea
                  placeholder="Add a text here.."
                  name="description"
                  autoComplete="off"
                  value={teamMemberAdd?.description}
                  onChange={(e) => memberDescriptionAdd(e)}
                >
                  {" "}
                </textarea>
              </div>
            </div>
            <div className="tax-label mb_24">
              <label>
                Start Date<span className="star">*</span>
              </label>
              <DatePicker
              disabledDate={checkStartDate}
                value={
                  teamMemberAdd.startDate !== ""
                    ? moment(teamMemberAdd.startDate)
                    : ""
                }
                onChange={(e) => handleDateChange(e)}
                style={{ width: "100%" }}
                id="date-start"
              />
            </div>
            <div>
              <TopHeader
                type="meeting"
                backClick={(e) => closeDrawer(e)}
                classname="p_0"
                submit={(e) => {
                  createOthers(e, teamId);
                }}
                // submit={createOthers}

                // id={projectId}
                spin={btnLoader}
              />
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  );
};


export default TeamMembers;
