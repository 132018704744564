import React, { Component } from "react";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { currencyFormatter } from "muthu-plugins";
import {
  Input,
  Select,
  Button,
  Table,
  message,
  Drawer,
  Space,
  Popover,
  Spin,
  Modal,
  Empty,
} from "antd";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";
import ExpenseItem from "./ExpenseItem";
import SelectIcon from "../../assets/select.png";
import TopHeader from "../../components/ui/TopHeader";
import Filter from "../../components/ui/filter";
import TableField from "../../components/ui/tableField";
import ModalPopUp from "../../components/ui/modalpop";
import {
  numberWithComma,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  showError,
  showDate,
  amountStatus,
  isErrorTxt,
  expenseAmount,
} from "../../constants/Utils";
import moment from "moment";
import { STORAGE, UPDATE, APPROVAL, DELETE } from "../../constants/roleStore";
import layer from "../../assets/Layer 2.svg";
import { EMPLOYEEEXPENSESACCESS } from "../../components/ui/constants";

const { Option } = Select;

class EmployeeExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemData: [],
      empId: "",
      itemId: "",
      currentPage: 1,
      pageSize: 20,
      total: "",
      openDrawer: false,
      overallData: [],
      overall: "",
      status: "",
      searchInput: "",
      searchOption: "",
      edit: false,
      Tableloading: true,
      app_name: "",
      app_type: "",
      deleteExpnses: false,
      delete_name: "",
      id: "",
      statusData: [
        { value: "Paid", key: "paid" },
        { value: "Unpaid", key: "unpaid" },
      ],
      searchData: [
        { value: "Emp Id", key: "emp_id" },
        { value: "Name", key: "name" },
      ],
      queryStatus: {},
      dropdown_menu: false,
      list_index: "",
      deleted: false,
      update: false,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    let name = "";

    let storage = JSON.parse(STORAGE.view);
    name = storage?.filter((app) => app?.name === "My Payments");

    let value = name;
    value?.filter((app) => {
      let names = app?.sub_roles?.filter(
        (role) => role.name === "Employee Expenses"
      );
      names &&
        names.map((el) =>
          this.setState({
            update: el?.permissions?.update,
            deleted: el?.permissions?.delete,
          })
        );
      this.setState({
        app_name: names,
      });
    });

    this.setState({
      app_type: STORAGE.type,
    });
    this.setQueryStatus();

    this.reloadPage();
    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);
  }
  onPopstate = () => {
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);

    this.state.searchOption = searchObj.type ?? "";
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";

    this.state.searchInput = searchObj.value ?? "";
    this.state.queryStatus = returnSearchObject(search);
    this.state.status = searchObj.status ?? "";
    this.setState({ queryStatus: this.handleFilter() }, () => {
      this.getItems();
    });
  };

  componentWillUnmount = () => {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);
  };
  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };
  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ queryStatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.clear();
        }
      });
    });
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          searchInput: queryObj.value ?? "",
          searchOption: queryObj.type ?? "",
          status: queryObj.status ?? "",
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",
          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.getItems();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.getItems();
        }
      );
    }
  };

  handleFilter = () => {
    let {
      currentPage,
      status,
      searchOption,
      searchInput,
      from,
      to,
      dateRange,
      pageSize,
    } = this.state;

    let query = returnSearchObject(this.props.location.search);
    if (this.state.searchOption !== "" && this.state.searchInput !== "") {
      query.type = this.state.searchOption ?? "";
      query.value = this.state.searchInput ?? "";
      query[this.state.searchOption ?? ""] = this.state.searchInput ?? "";
    }

    query.status = this.state.status ?? "";
    query.page = this.state.currentPage ?? "";
    query.limit = this.state.pageSize ?? "";

    this.setState(
      {
        queryStatus: query,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.getItems();
      }
    );
  };
  getItems = () => {
    APIGATEWAY.get(
      "employees/expenses/" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          let res = response.data;
          this.setState({
            itemData: res?.employee_expenses,
            total: res?.employee_expenses?.length,
            Tableloading: false,
            overall: response.data?.total,
          });
        }
      }
    );
  };

  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  next = (prev) => {
    const { currentPage } = this.state;

    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };
  addExpenseItem = () => {
    this.setState({ itemId: "" }, () => {
      this.setState({ openDrawer: true });
    });
  };

  getExpenseDetail = (value) => {
    this.setState({ edit: false, itemId: value.expense_id, openDrawer: true });
  };

  deleteExpenseItem = (expense_id, object) => {
    this.setState({
      id: expense_id,
      deleteExpnses: true,
      delete_name: object.item,
    });
  };

  cancelDelete = () => {
    this.setState({ id: "", deleteExpnses: false, delete_name: "" });
  };

  submitDelete = () => {
    let data;
    APIGATEWAY.delete(
      `employees/expenses/${this.state.id}`,
      data,
      (response) => {
        if (response.success) {
          message.success(response.message);
          this.getItems();
          this.setState({ id: "", deleteExpnses: false, delete_name: "" });
        }
      }
    );
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getItems();
    });
  };

  goBack = () => {
    this.props.history.push("/employees");
  };

  closeDrawer = () => {
    this.setState({ openDrawer: false, itemId: "" });
  };

  editItem = () => {
    this.closeDrawer();
    this.getItems();
  };
  editExpenseItem = (id) => {
    this.setState({ edit: true, itemId: id, openDrawer: true });
  };

  payEmployee = (value) => {};

  statusSearch = (value) => {
    this.setState({ status: value, currentPage: 1 }, () => {
      this.handleFilter();
    });
  };
  searchInput = (e) => {
    this.setState({ searchInput: e.target.value }, () => {});
  };
  searchOption = (value) => {
    this.setState({ searchOption: value });
  };

  clear = () => {
    this.setState(
      {
        status: "",
        searchInput: "",
        searchOption: "",
        currentPage: 1,
        queryStatus: { page: 1, limit: 20 },
        pageSize: 20,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);

        this.getItems();
      }
    );
  };

  Apply = () => {
    const { status, searchInput, searchOption } = this.state;
    if (status === "" && (searchInput === "" || searchOption === "")) {
      showError("Please select any one field");
    } else {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handleFilter();
        }
      );
    }
  };
  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      list_index: index,
    });
  };
  render() {
    let {
      itemData,
      currentPage,
      total,
      openDrawer,
      itemId,
      status,
      edit,
      app_name,
      app_type,
      pageSize,
      update,
      deleted,
    } = this.state;
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }

    return (
      <div>
        <div>
          <TopHeader title="Expenses" />
          <div className="main-content">
            <Filter
              searchData={this.state.searchData}
              statusData={this.state.statusData}
              optionChange={(e) => this.searchOption(e)}
              searchOption={this.state.searchOption}
              searchname="searchOption"
              statusName="status"
              statusSelect={this.state.status}
              statusOnChange={(e) => this.statusSearch(e)}
              clear={() => this.clear()}
              apply={() => this.Apply()}
              searchInputName="searchInput"
              searchInput={this.state.searchInput}
              inputChange={(e) => this.searchInput(e)}
            />

            {this.state.Tableloading ? (
              <div className="tableview">
                <Spin />
              </div>
            ) : (
              <div className="table-main">
                {this.state.Tableloading ? (
                  <div className="tableview">
                    <Spin />
                  </div>
                ) : (
                  <div className="table_page_wrap">
                    {itemData.length > 0 ? (
                      <table className="table-response">
                        <thead>
                          <tr>
                            <th className="">S.No</th>
                            <th className="">Employee ID</th>
                            <th className="">Name</th>
                            <th className="">Item</th>
                            <th className="">Amount</th>
                            <th className="">Description</th>
                            <th className="">Status</th>
                            <th></th>
                          </tr>
                        </thead>

                        {itemData.length > 0 ? (
                          <tbody ref={this.wrapperRef}>
                            {itemData.map((item, r) => (
                              <tr key={r}>
                                <td className="">
                                  {" "}
                                  {(this.state.currentPage - 1) *
                                    this.state.pageSize +
                                    (r + 1)}
                                </td>
                                <td className="table-name ">
                                  {isErrorTxt(item?.employee?.official_emp_id)
                                    ? "-"
                                    : item?.employee?.official_emp_id}
                                </td>

                                <td className="table-name text-capitalize">
                                  <div
                                    onClick={() => this.getExpenseDetail(item)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {isErrorTxt(item?.employee?.name)
                                      ? "-"
                                      : item?.employee?.name}
                                  </div>
                                </td>
                                <td className="table-name text-capitalize">
                                  {isErrorTxt(item?.item) ? "-" : item?.item}
                                </td>

                                <td className="table-name">
                                  {isErrorTxt(item.status)
                                    ? numberWithComma(0)
                                    : expenseAmount(
                                        item?.status,
                                        item?.amount ?? 0
                                      )}
                                </td>
                                <td className="table-name text-capitalize">
                                  <div>
                                    {isErrorTxt(item.description) ? (
                                      "-"
                                    ) : item.description?.length >= 14 ? (
                                      <Popover content={item.description}>
                                        {item.description.slice(0, 13) +
                                          "....."}
                                      </Popover>
                                    ) : (
                                      item.description
                                    )}
                                  </div>
                                </td>
                                <td className="table-name text-capitalize font-weight-content">
                                  {amountStatus(item.status)}
                                </td>

                                {(EMPLOYEEEXPENSESACCESS.update ||
                                  EMPLOYEEEXPENSESACCESS.delete) && (
                                    <td
                                      className="table-action"
                                      onClick={() => this.dropdownClick(r)}
                                    >
                                      <div className="tax-add-edit">
                                        <span className="ml-5">
                                          <div className="dropdown-head">
                                            <div className="dropbtn-head">
                                              <img
                                                src={layer}
                                                alt="profile"
                                                className="menu-bar-icon"
                                              />
                                            </div>
                                            {this.state.list_index === r &&
                                              this.state.dropdown_menu && (
                                                <div className="dropdown-content-heads">
                                                  <div>
                                                    {EMPLOYEEEXPENSESACCESS.update && (
                                                      <p
                                                        className="action-menu-bar"
                                                        onClick={() =>
                                                          this.editExpenseItem(
                                                            item.expense_id
                                                          )
                                                        }
                                                      >
                                                        Edit
                                                      </p>
                                                    )}

                                                    {EMPLOYEEEXPENSESACCESS.delete && (
                                                      <p
                                                        className="action-menu-bar"
                                                        onClick={() =>
                                                          this.deleteExpenseItem(
                                                            item.expense_id,
                                                            item
                                                          )
                                                        }
                                                      >
                                                        Delete
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        </span>
                                      </div>
                                    </td>
                                  )}
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          ""
                        )}
                      </table>
                    ) : (
                      <table className="table-response">
                        <thead>
                          <tr>
                            <th className="">S.No</th>
                            <th className="">Employee ID</th>
                            <th className="">Name</th>
                            <th className="">Item</th>
                            <th className="">Amount</th>
                            <th className="">Description</th>
                            <th className="">Status</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody className="empty-message">
                          <tr>
                            <td colspan="10">
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                    {itemData.length > 0 && (
                      <TableField
                        data={itemData}
                        countprev={countprev}
                        countnext={countnext}
                        currentPage={currentPage}
                        ClickPrev={() => this.previous()}
                        ClickNext={() => this.next(this.state.currentPage)}
                        total={total}
                        overAll={this.state.overall ?? 0}
                        pagelimit={this.state.pageSize}
                        pagelimitHandle={this.pageLimitOnchange}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <Space>
          <Drawer
            placement="right"
            visible={openDrawer}
            onClose={() => this.closeDrawer()}
          >
            <ExpenseItem
              itemId={itemId}
              close={this.closeDrawer}
              editItem={this.editItem}
              edit={edit}
            />
          </Drawer>
        </Space>

        <ModalPopUp
          visible={this.state.deleteExpnses}
          cancel={this.cancelDelete}
          submit={this.submitDelete}
          name="Expenses"
          delete_name={this.state.delete_name}
        />
      </div>
    );
  }
}

export default EmployeeExpense;
