import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, DatePicker, Divider,Spin, Table } from "antd";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import moment from "moment";
import "../../stylesheets/styles.css";
import TopHeader from "../../components/ui/TopHeader";
import InputItem from '../../components/ui/InputItem';
import DateItem from '../../components/ui/DateItem';
import { getIsoString, showError, showDate, isErrorTxt } from "../../constants/Utils";
const ExpensesAdd = (props) => {
  const [date, setDate] = useState();
  const [expense, setExpense] = useState();
  const [totalamount, setTotalAmount] = useState();
  const [listofexpenses, setListOfExpenses] = useState([
    { item: "", price: "", count: "" },
  ]);
  const [expensename, setExpenseame] = useState();
  const [amount, setAmount] = useState(0);
  const [aeLoader, setaeLoader] = useState(false);
  const [Loader, setLoader] = useState(true);


  let expenseId = props.match.params.id;

  const listnamechangeHandler = (e, index) => {
    const { name, value } = e.target;
    const list = [...listofexpenses];
    list[index][name] = value;
    setListOfExpenses(list);
  };

  const countChangeHandler = (e, index) => {
    const countCheck = /^-?\d+\.?\d*$/;
    const { name, value } = e.target;
    const list = [...listofexpenses];
    list[index][name] = value;
    if (value === "" || countCheck.test(value)) {
      setListOfExpenses(list);
    }
    getSubtotal();
  };

  const getSubtotal = () => {
    let subtotal = 0;
    let ct = 0;
    listofexpenses.forEach((item) => {
      if (item) {
        let total = item.price;
        let c = item.count;
        subtotal += +total;
        ct += +c * total;
      }
    });
    setTotalAmount(ct);
  };

  const amountHandler = (e) => {
    setAmount(e.target.value);
  };

  const amountChangeHandler = (e, index, data) => {
    const countCheck = /^-?\d+\.?\d*$/;
    const { name, value } = e.target;

    const list = [...listofexpenses];
    list[index][name] = value;

    if (value === "" || countCheck.test(value)) {
      setListOfExpenses(list);
    }

    getSubtotal();
  };
  let arrayOfItems = [];
  for (let i = 0; i < listofexpenses.length; i++) {
    let expense = listofexpenses[i];
    if (expense) {
      let data = {
        sno: i + 1,
        item: expense.item,
        price: expense.price,
        count: expense.count,
      };
      arrayOfItems.push(data);
    }
  }
  const column = [
    {
      title: "Name*",
      render: (text, record) => {
        return (
          <InputItem label='' name='item' star={false} value={record.item} placeholder="Enter item" onChange={(e) => listnamechangeHandler(e, record.sno - 1)} />
        );
      },
    },

    {
      title: "Quantity*",
      render: (text, record) => {
        return (
          <InputItem label='' name='count' star={false} value={record.count} placeholder="Quantity" onChange={(e) => countChangeHandler(e, record.sno - 1)} />
        );
      },
    },

    {
      title: "Total Amount*",
      render: (text, record) => {
        return (
          <InputItem label='' name='price' star={false} value={record.price} placeholder="" onChange={(e) => amountChangeHandler(e, record.sno - 1, record)} />

        );
      },
    },

    {
      title: "Action*",
      render: (text, record, index) =>
        record && (
         <div className="expenses-btn">
           <Button
            type="danger"
            disabled={listofexpenses.length < 2 ? true : false}
            onClick={() => removeFormFields(record.sno - 1)}
          >
            Delete
          </Button>
         </div>
        ),
    },
  ];

  useEffect(() => {
    editExpense();
  }, []);

  const editExpense = () => {
    APIGATEWAY.get("expenses/" + expenseId, (response) => {
      if (response.success) {
        let res = response.data.expense;
        let list = res.item_of_expenses;
        let total = response.data.expense.total_amount;
        let purchaseDate = response.data.expense.purchase_date;
        let da = moment(purchaseDate);
        setExpense(res);
        setDate(da);
        setTotalAmount(total);
        setListOfExpenses(list);
        setLoader(false)
      }
    });
  };

  const addItemHandler = () => {
    const notEmpty = checkEmptyProduct();

    if (!notEmpty) {
      setListOfExpenses([
        ...listofexpenses,
        { item: "", price: "", count: "" },
      ]);
    } else {
      showError("Please fill the field");
    }
  };

  const removeFormFields = (i) => {
    if (listofexpenses.length < 2) {
      showError("At least one field must be resent");
    } else listofexpenses.splice(i, 1);
    setListOfExpenses([...listofexpenses]);
    getSubtotal();
  };

  const apply = () => {
    let isExpenseError = false;
    let list = [...listofexpenses];
    if(isErrorTxt(date)){
      showError('Please select the puchase date')
    }else{
      for ( let i = 0; i < list.length; i++ ) {
        if ( !list[ i ].item ) {
          isExpenseError = true;
          return showError( "item missinig ", 3 );
        } else if ( !list[ i ].count ) {
          isExpenseError = true;
          return showError( "count missing", 3 );
        } else if ( !list[ i ].price ) {
          isExpenseError = true;
          return showError( "price missing", 3 );
        }
      }
    }
   

    if (!isExpenseError && !date) {
    } else {
      setaeLoader(true);
      let data = {
        purchase_date: getIsoString(date),
        total_amount: totalamount,
        item_of_expenses: listofexpenses,
      };
      if (expenseId) {
        APIGATEWAY.patch("expenses/" + expenseId, data, (response) => {
          if (response.success) {
            setaeLoader(false);
            props.history.push("/expenses");
          }
        });
      } else {
        APIGATEWAY.post("expenses", data, (response) => {
          if (response.success) {
            setaeLoader(false);
            props.history.push("/expenses");
          }
        });
      }
    }
  };
  const checkEmptyProduct = () => {
    let result;
    for (let i = 0; i < listofexpenses.length; i++) {
      let listItem = listofexpenses[i];
      if (listItem.item === "") {
        result = true;
      } else if (listItem.price === "") {
        result = true;
      } else if (listItem.count === "") {
        result = true;
      }
    }

    return result;
  };

  const expensenamechangeHandler = (e) => {
    setExpenseame(e.target.value);
  };
  const expenseDateHandler = (e) => {
    setDate(e);
  };
  return (
    <div className="">
      <TopHeader
        title="Expenses"
        backClick={() => props.history.goBack()}
        submit={apply}
        id={expenseId}
        spin={aeLoader}
      />
      {expenseId && Loader ? (
        <div className="tableview">
        <Spin />
      </div>
      ):(
        <div className="main-content2">
        <div style={{ marginBottom: "30px" }}>
          <div className="d-flex_jc_sb_al_e">
            <div class="d-flex_jc_c_al_c width-50">
              <DateItem label='Purchase Date' name='date' star={false} value={date} placeholder="purchase date" onChange={expenseDateHandler} />
              <InputItem style={{'margin-left': '20px'}} label='Total Amount' name='total_amount' star={false} value={totalamount} placeholder="Ente the total amount" readonly={true} onChange={''}/>
            </div>
            <button className="probtn" onClick={addItemHandler}>
              Add Item
            </button>
          </div>
        </div>

        <div
          className="sm__grid--col-4 exp_tab_border"
          style={{ width: "100%" }}
        >
          {arrayOfItems.length > 0 && (
            <Table
              columns={column}
              dataSource={arrayOfItems}
              pagination={false}
            />
          )}
        </div>
      </div>
      )}
      
    </div>
  );
};

export default ExpensesAdd;
