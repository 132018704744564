// Old project(30/08/23)



import React, { Component } from "react";
import "../../stylesheets/styles.css";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import {
  Input,
  Select,
  Button,
  Table,
  Card,
  Col,
  Row,
  Tabs,
  message,
  Modal,
  Spin,Divider
} from "antd";

//Utils
import {
  showError,
  returnSecondDegreeObjValue,
  returnFirstDegreeObjValue,
  showDate,
  projectStatusColor, isErrorTxt
} from "../../constants/Utils";
import TopHeader from "../../components/ui/TopHeader";

import moment from "moment";
import DatePicker from "../../components/ui/DatePicker";
const { TabPane } = Tabs;
const { Option } = Select;
export default class  ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activetab: "1",
      page: 1,
      currentPage: 1,
      id: this.props.match.params.id,
      data: {},
      loading: true,
      platform: [],
      technology: [],
      employes: [],
      testing_data: [],
      status: "",
      statusReason: "",
      relieved: "",
      reason: "",
      client_name: "",
      client_id: "",
    };
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.handleTenantsDetail();
    });
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleOnDateChange = (date, dateString, name) => {
    if (dateString === "")
      this.setState({
        [name]: dateString,
      });
    else
      this.setState({
        [name]: moment(dateString),
      });
  };

  handleCancel = (e) => {
    this.setState({ statusModal: false });
  };

  handleOk = (id) => {
    let { statusReason, reason, relieved } = this.state;
    if (statusReason === "") {
      showError("Please Select a Status");
    } else if (statusReason === "cancelled" && reason === "") {
      showError("Please Provide a Reason");
    } else {
      let data = {
        status: statusReason,
        "date.close": relieved.toISOString(),
        reason: reason,
      };
      APIGATEWAY.patch("projects/" + id, data, (response) => {
        if (response.success) {
          message.success(response.message);
          this.handleCancel();
          this.handleTenantsDetail();
        } else {
          message.error(response.message);
        }
      });
    }
  };
  componentDidMount() {
    var id = this.state.id;
    this.handleTenantsDetail(id);
  }

  clientlist = () => {
    APIGATEWAY.get("clients/" + this.state.client_id, (response) => {
      if (response.success) {
        let data = response.data.client;
        this.setState({ client_name: data.name });
      }
    });
  };
  handleTenantsDetail = () => {
    let username = this.state.id;
    APIGATEWAY.get("projects/" + username, (response) => {
      if (response.success) {
        let data = response.data.project;
        this.setState(
          {
            data: data,
            platform: data.platforms,
            technology: data.technologies,
            employes: data.employees,
            testing_data: data.testing_links,
            status: data.status,
            loading: false,
            client_id: data.client_id,
          },
          () => {
            this.clientlist();
          }
        );
      } else {
        this.setState({ loading: true });
      }
    });
  };
  handleClick = () => {
    this.props.history.push("/projects");
  };

  changeActiveStatus = (st) => {
    let data = {
      status: st,
    };
    this.statusChange(data);
  };

  statusChange = (data) => {
    let self = this;
    const { id } = self.state;
    APIGATEWAY.patch(`projects/status/${id}`, data, (response) => {
      if (response.success) {
        message.success(response.message);
        this.handleTenantsDetail();
      } else {
        message.error(response.message);
      }
    });
  };

  render() {
    let {
      activeTab,
      testing_data,
      status,
      data,
      platform,
      technology,
      employes,
      client_name,
    } = this.state;

    const testing_name = testing_data.map((detail) => detail.name);

    // for (let key of testing_name) {
    // }

    let column = [
      {
        title: "S.No",
        key: "index",
        render: (value, item, index) =>
          (this.state.currentPage - 1) * 5 + (index + 1),
      },

      {
        title: "Billing days ",
        dataIndex: "billing_days",
      },

      {
        title: "Value ",
        dataIndex: "value",
      },
      {
        title: "Description ",
        dataIndex: "desc",
      },
    ];
    let columns = [
      {
        title: "S.No",
        key: "index",
        render: (value, item, index) =>
          (this.state.currentPage - 1) * 10 + (index + 1),
      },

      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "URL",
        dataIndex: "url",
      },
    ];
    let testing_links = [
      {
        title: "S.No",
        key: "index",
        render: (value, item, index) =>
          (this.state.currentPage - 1) * 5 + (index + 1),
      },
      {
        title: "Name ",
        dataIndex: "name",
      },
      {
        title: "URL",
        dataIndex: "url",
      },
    ];
    const title = <p className="dec-title">Project Details</p>;
    return (
      <div className="">
        {this.state.loading ? (
          <div className="spinning-star">
            <Spin size="large" />
          </div>
        ) : (
          <Card
            title={title}
            style={{ padding: "25px" }}
            extra={
              <div>
                <div className="bread">
                  {status === "active" && (
                    <>
                      <button
                        className="probtn"
                        style={{ marginRight: "10px" }}
                        type="primary"
                        onClick={() => this.changeActiveStatus("discontinued")}
                      >
                        Discontinued
                      </button>

                      <button
                        className="probtn"
                        style={{ marginRight: "10px" }}
                        type="primary"
                        onClick={() => this.changeActiveStatus("completed")}
                      >
                        Completed
                      </button>

                      <button
                        className="probtn"
                        style={{ marginRight: "10px" }}
                        type="primary"
                        onClick={() => this.changeActiveStatus("hold")}
                      >
                        Hold
                      </button>
                    </>
                  )}

                  {status === "completed" && (
                    <button
                      className="probtn"
                      style={{ marginRight: "10px" }}
                      type="primary"
                      onClick={() => this.changeActiveStatus("active")}
                    >
                      Active
                    </button>
                  )}
                  {status === "hold" && (
                    <button
                      className="probtn"
                      style={{ marginRight: "10px" }}
                      type="primary"
                      onClick={() => this.changeActiveStatus("active")}
                    >
                      Active
                    </button>
                  )}
                  {status === "canceled" && (
                    <button
                      className="probtn"
                      style={{ marginRight: "10px" }}
                      type="primary"
                      onClick={() => this.changeActiveStatus("active")}
                    >
                      Active
                    </button>
                  )}
                  {status === "discontinued" && (
                    <button
                      className="probtn"
                      style={{ marginRight: "10px" }}
                      type="primary"
                      onClick={() => this.changeActiveStatus("active")}
                    >
                      Active
                    </button>
                  )}

                  <div>
                    <button
                      className="probtn"
                      type="primary"
                      onClick={() => {
                        this.handleClick();
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            }
          >
            <div className="main-content" style={{ marginBottom: 50 }}>
              <div className="mt-3">
                <Divider orientation='left'>Basic Details</Divider>
                <div className=" " style={{ width: "100%" }}>
                <div className="details-content-project">
                <div className="name-wrappers">
                  <div className="title-wrapper font-500">Name</div>
                  <div className="data-content text-capitalize">{data.name}</div>
                </div>

                <div className="name-wrappers">
                  <div className="title-wrapper font-500">Status</div>
                  <div className="data-content text-capitalize">
                    {isErrorTxt( data.status )?'-':projectStatusColor(data.status)}
                  </div>
                </div>
              </div>
              <div className="details-content-project">
                <div className="name-wrappers">
                  <div className="title-wrapper font-500">Description</div>
                  <div className="data-content text-capitalize">{data.desc}</div>
                </div>

                
              </div>
              <div className="details-content-project">
              <div className="name-wrappers">
                  <div className="title-wrapper font-500">Platform</div>
                  <div
                    className="data-content text-capitalize"
                    
                  >
                 <ol type='1' >
                            {platform.map((platform) => (
                              <li style={{ padding: "5px" }}>
                                {platform.name}
                              </li>
                            ))}
                          </ol>
                  </div>
                </div>

                <div className="name-wrappers">
                  <div className="title-wrapper font-500">Technology</div>
                  
                  <div
                    className="data-content text-capitalize"
                    
                  >
                <ol type='1' className='text-capitalize'>
                          {technology.map((technology) => (
                            <li style={{ padding: "5px" }}>
                              {technology.name}
                            </li>
                          ))}
                        </ol>
                  </div>
                </div>
              </div>
                  
                </div>
              </div>

              <div className="mb-30">
                <Tabs
                  activeKey={activeTab}
                  onChange={(activeKey) =>
                    this.setState({ activeTab: activeKey })
                  }
                >
                  <TabPane tab="Project Details" key="1">
                    <div className="field-holder mb-20">
                      <div>
                        
                          <Divider orientation="left">Date</Divider>
                        
                        <Row>
                          <Col className="font-500" span={4}>
                            Start
                          </Col>
                            <Col span={4} className='data-content'>
                            {showDate(
                              returnSecondDegreeObjValue(data, "date", "start")
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="field-holder mb-20">
                      <div>
                      <Divider orientation="left">Timeline</Divider>

                        
                        <Row>
                          <Col className="font-500" span={4}>
                            From
                          </Col>
                            <Col span={4} className='data-content'>
                            {showDate(
                              returnSecondDegreeObjValue(
                                data,
                                "timeline",
                                "from"
                              )
                            )}
                          </Col>
                          <Col className="font-500" span={4}>
                            To
                          </Col>
                            <Col span={4} className='data-content'>
                            {showDate(
                              returnSecondDegreeObjValue(data, "timeline", "to")
                            )}
                          </Col>
                          <Col className="font-500" span={4}>
                            {" "}
                            Total Days{" "}
                          </Col>
                            <Col span={4} className='data-content'>
                            {" "}
                            {returnSecondDegreeObjValue(
                              data,
                              "timeline",
                              "total_days"
                            )}{" "}
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="field-holder mb-30">
                      <div>
                      <Divider orientation="left">Other Information</Divider>

                       
                        <Row>
                          <Col className="font-500" span={4}>
                            Client
                          </Col>
                            <Col span={4} className='data-content text-capitalize'>{isErrorTxt( client_name?.first_name ) ? "" : client_name?.first_name + " " + isErrorTxt( client_name?.last_name ) ? "" : client_name?.last_name}</Col>
                          <Col className="font-500" span={4}>
                            Employee
                          </Col>
                          <Col span={4}>
                            {" "}
                              <ol type='1' className='data-content text-capitalize'>
                              {employes.map((employ) => (
                                <li style={{ padding: "5px" }}>
                                  {" "}
                                  {employ.name}
                                </li>
                              ))}
                            </ol>
                          </Col>
                          <Col className="font-500" span={4}>
                            Project Value{" "}
                          </Col>
                            <Col span={4} className='data-content'>
                            {returnFirstDegreeObjValue(data, "value")}{" "}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane key="2" tab="Document Details">
                    {data.documents.length !== 0
                      ? data.documents
                      : "No data found"}
                  </TabPane>
                  <TabPane key="3" tab="Design Details">
                    <Table
                      columns={columns}
                      className="gx-table-responsive"
                      dataSource={data.design_links}
                      pagination={false}
                      style={{ textTransform: "capitalize" }}
                    />
                  </TabPane>
                  <TabPane key="4" tab="Testing Details">
                    <Table
                      columns={testing_links}
                      className="gx-table-responsive"
                      dataSource={data.testing_links}
                      pagination={false}
                      style={{ textTransform: "capitalize" }}
                    />
                  </TabPane>
                  <TabPane key="5" tab="PCR">
                    <div className="field-holder mb-30">
                      <div>
                        <Table
                          columns={column}
                          className="gx-table-responsive"
                          dataSource={data.pcr}
                          pagination={false}
                          style={{ textTransform: "capitalize" }}
                        />
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>

            <Modal
              title={"Status"}
              open={this.state.statusModal}
              onCancel={() => this.handleCancel()}
              onOk={() => this.handleOk(this.state.id)}
            >
              <div className="field-holder">
                <div className="title-wrapper">Status:</div>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.statusReason}
                  onChange={(value) => this.setState({ statusReason: value })}
                  getPopupContainer={trigger => trigger.parentNode}

                >
                  <Option value="closed">Closed</Option>
                  <Option value="cancelled">Cancelled</Option>
                </Select>
              </div>
              {this.state.statusReason === "cancelled" ? (
                <div className="field-holder">
                  <div className="title-wrapper">Reason:</div>
                  <Input
                    placeholder="Enter the Reason"
                    name="reason"
                    value={this.state.reason}
                    onChange={this.handleInputChange}
                  ></Input>
                </div>
              ) : null}
              <div>
                <div className="title-wrapper">Last Working Day</div>
                <DatePicker
                  name="relieved"
                  value={this.state.relieved}
                  handleChange={this.handleOnDateChange}
                  getPopupContainer={trigger => trigger.parentNode}
                />
              </div>
            </Modal>
          </Card>
        )}
      </div>
    );
  }
}
