import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
} from "react";
import "../../stylesheets/project/ProjectDetailsPage.css";
import vector from "../../assets/Vector.png";
import pencil from "../../assets/fi-rr-pencil.svg";
import user from "../../assets/user.png";
import money from "../../assets/money.png";
import layer from "../../assets/layers.png";
import setting from "../../assets/settings.png";
import { Tabs, Spin, Space, Drawer } from "antd";
import "antd/dist/antd.css";
import Overview from "./Overview";
import Milestone from "./Milestone";
import { Progress } from "antd";
import ChangeRequest from "./ChangeRequest";
import TeamMembers from "./TeamMembers";
import ProjectInvoice from "./ProjectInvoice";
import ProjectOtherPage from "./ProjectOtherPage";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { useParams, Link, useLocation } from "react-router-dom";
import {
  showCurrency,
  showLongDateFormat,
  numberWithComma,
  capitlizestring,
  splitWithComma,
  project_statusColor,
} from "../../constants/Utils";
import UpdateProjectComponent from "./updateProjectComponent";
import { STORAGE } from "../../constants/roleStore";

const ProjectDetailsPage = (props) => {
  let adminType = STORAGE.type
  const subAdminRestricted = ['invoices']
  const id = props.location?.state?.id ?? null;
  const UserContext = createContext();
  const projectRef = useRef();
  const { TabPane } = Tabs;
  const params = useParams();
  const [details, setDetails] = useState(null);
  const [_id, setId] = useState(props.location?.state?._id);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("overview");
  const [employee, setEmployee] = useState([]);
  const [platformsName, setPlatformsName] = useState([]);
  const [techonologyName, setTechonologyName] = useState([]);
  const [desc, setDesc] = useState("");
  const [initPageLoading, setInitPageLoading] = useState(true);
  const [otherPageLoading, setOtherPageLoading] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [statusData, setStatusData] = useState([
    // { value: "All", key: "all" },
    { value: "Active", key: "active" },
    { value: "Hold", key: "hold" },
    { value: "Discontinued", key: "discontinued" },
    { value: "Completed", key: "completed" },
    { value: "Cancelled", key: "cancelled" },
  ]);
  const [status, setStatus] = useState();
  const [startDate, setStartDate] = useState('');
  const [currentPage, setCurrentPage] = useState();
  const tabsData = [
    {
      key: "overview",
      label: "Overview",
      content: (
        <Overview
          description={desc}
          pageLoading={otherPageLoading}
          project_id={_id}
          projectId={id}

        />
      ),
      data: "",
    },
    {
      key: "milestones",
      label: "Milestones",
      content: <Milestone 
      project_id={_id}
      startDate={startDate}
      
      > </Milestone>,
    },
    {
      key: "changeRequests",
      label: "Change Requests",
      content: <ChangeRequest project_id={_id}
      startDate={startDate}
      />,
    },
    {
      key: "teamMembers",
      label: "Team Members",
      content: <TeamMembers project_id={_id} 
      startDate={startDate}
      />,
      data: "",
    },
    {
      key: "invoices",
      label: "Invoices",
      content: <ProjectInvoice project_id={_id} 
      startDate={startDate}
      />,
      data: "",
    },
    {
      key: "others",
      label: "Others",
      content: <ProjectOtherPage project_id={_id} />,
      data: "",
    },
  ];

  useEffect(() => {
    setLoading(true);
    const projectId = params.id;
    if (props?.location?.state?.searchString === "other") {
      setActiveTab("others");
      setLoading(false);
    } else if (props?.location?.state?.from === "invoice") {
      setActiveTab("invoices");
      setLoading(false);
    } else setActiveTab("overview");

    Promise.all([
      fetchProjectData(projectId),
      fetchPlatformLists(),
      fetchTechnologiesLists(),
    ]).then(([data, platforms, technologies]) => {
      setLoading(false);

      getProjectDetails(data, platforms, technologies);
    });
  }, []);

  function onTabChange(e) {
    setCurrentPage(e);
  }

  function closeDrawer() {
    return new Promise((resolve, reject) => {
      setOpenDrawer(false);
      // setId(null);
      resolve(true);
    });
  }

  function fetchProjectData(id) {
    return new Promise((resolve, reject) => {
      APIGATEWAY.get(`project/${id}`, (response) => {
        const teams = response.data.project[0].teams;
        const technologies = response.data.project[0].technologies;
        const platforms = response.data.project[0].platforms;
        setDesc(response.data.project[0].description);
        const data = {
          teams: teams,
          technologies: technologies,
          platforms: platforms,
        };
        setId(response.data.project[0]._id);
        if (!response.success) return reject(false);
        setDetails(response.data.project[0]);
        setStatus(response.data.project[0].status);
        setStartDate(response.data.project[0].startDate);
        setInitPageLoading(false);
        resolve(data);
      });
    });
  }

  function fetchPlatformLists() {
    return new Promise((resolve, reject) => {
      const queryParams = "";
      APIGATEWAY.get("platforms" + queryParams, (response) => {
        if (!response.success) return reject(false);
        const platforms = response.data.platforms;
        resolve(platforms);
      });
    });
  }

  function fetchTechnologiesLists() {
    return new Promise((resolve, reject) => {
      const queryParams = "";
      APIGATEWAY.get("technologies" + queryParams, (response) => {
        if (response.success) {
          const techonologies = response.data.technologies;
          resolve(techonologies);
        } else reject(false);
      });
    });
  }

  function getProjectDetails(data, platforms, technologies) {
    const platFormObj = {};
    const technologiesObj = {};
    platforms.map((obj) => {
      platFormObj[obj._id] = obj;
    });
    data.platforms.map((platform_id) => {
      setPlatformsName((oldArray) => [
        ...oldArray,
        platFormObj[platform_id].name,
      ]);
    });
    technologies.map((obj) => {
      technologiesObj[obj._id] = obj;
    });
    data.technologies.map((technology_id) => {
      setTechonologyName((oldArray) => [
        ...oldArray,
        technologiesObj[technology_id].name,
      ]);
    });
    data.teams.map((emp) => {
      setEmployee((employee) => [...employee, emp.employee.name]);
    });
  }

  function projStatus(isTrue, response) {
    if (isTrue) {
      closeDrawer();
      // setStatus(response.data.project.status)
      fetchProjectData(id);
    }
  }

  return (
    <div>
      {initPageLoading ? (
        <div className="spinning-star">
          <Spin size="large" />
        </div>
      ) : (
        <div className="Details-Page">
          <div className="crums">
            <Link to={`/projects`}>
              <p className="crums1 pointer">Projects</p>
            </Link>
            <img className="crums-vector" src={vector} alt="arrow"></img>
            <p className="crums2">Project Detail Page</p>
          </div>

          <div className="Status-Box">
            <div className="round-progress-container">
              <Progress
                type="circle"
                percent={details?.percentage}
                width={102} // Adjust the size as needed
                strokeWidth={6}
                format={(percent) => (percent === 100 ? "100%" : `${percent}%`)}
                strokeColor="#F6C900" // Set the desired color
              />
            </div>
            <div className="statusbox-content">
              <p className="title">{details?.name}</p>
              <div className="statusbox-content1">
                <div className="content1-start">
                  <p>Start</p>
                  <span>{showLongDateFormat(details?.startDate)} </span>
                </div>
                <div className="content1-first">
                  <p>Actual Start</p>
                  <span>{showLongDateFormat(details?.actualStartDate)} </span>
                </div>
                <div className="content1-first">
                  <p>End </p>
                  <span>{showLongDateFormat(details?.endDate)}</span>
                </div>
                <div className="content1-end">
                  <p>Status</p>
                  <div className="progress-key">
                    <p className="in-prog-stat">
                      {project_statusColor(status)}
                    </p>
                    <img
                      className="pencil-icon pointer"
                      src={pencil}
                      alt="pencil-icon"
                      onClick={() => setOpenDrawer(true)}
                    ></img>
                  </div>
                </div>
              </div>
              <div className="statusbox-content2">
                <div style={{ 'min-width': '40%'}}>
                  <div className="content-1">
                    <img src={user} alt="user icon"></img>
                      <div>{details?.client?.name?.first_name} {details?.client?.name?.last_name}</div>
                  </div>
                  <div className="content-1">
                    <img src={layer} alt="layer icon"></img>
                    <div>{platformsName.length ? splitWithComma(platformsName) : '-'}</div>
                  </div>
                </div>
                <div class="ml_30">
                  <div className="content-1">
                    <img src={money} alt="money icon"></img>
                    <p>{numberWithComma(details?.projectValue)}</p>
                  </div>
                  <div className="content-1">
                    <img src={setting} alt="setting icon"></img>
                    <div>{techonologyName.length ? splitWithComma(techonologyName) : '-'}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="projectlist-navbar">
            <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
              {_id &&
                tabsData.map((tab) => (
                  <>
                    {(adminType !== 'sub_admin' || !subAdminRestricted.includes(tab.key)) && <TabPane tab={tab.label} key={tab.key}>
                      {tab.content}
                    </TabPane>}
                  </>
                ))}
            </Tabs>
          </div>
        </div>
      )}
      <Space>
        <Drawer
          placement="right"
          open={openDrawer}
          onClose={closeDrawer}
          className="tax-main-drawer"
        >
          {id && (
            <UpdateProjectComponent
              from={'projectDetail'}
              statusData={statusData}
              update={projStatus}
              projectId={id}
              onClose={closeDrawer}
            ></UpdateProjectComponent>
          )}
        </Drawer>
      </Space>
    </div>
  );
};

export default ProjectDetailsPage;
