import React, { useState, useEffect } from "react";
import { SidebarData } from "./routes";
import SubMenu from "./SubMenu";
import Logo from "../assets/FrameLogo.svg";
import Logo2 from "../assets/Group 2744.svg";
import { STORAGE } from "../constants/roleStore";

const Sidebar = () => {
  const [data, setData] = useState("");
  const [sidebars, setSidebars] = useState(SidebarData);

  useEffect(() => {
    //getProfile api and set it in localstorage
    const showApps = STORAGE?.view && JSON.parse(STORAGE?.view ?? "");
    const userType = STORAGE?.type ?? "";
    let subnavtitle = showApps?.map((module) => module?.sub_roles ?? "");
    let subName = [];
    subnavtitle?.map((sub) => {
      if (sub) {
        sub.forEach((obj) => {
          return subName.push(obj.name);
        });
      }
    });

    const result =
      userType === "sub_admin" && showApps?.map((module) => module.name);

    const activeModule = [];

    sidebars &&
      sidebars?.map((module) => {
        if (userType === "sub_admin" && module.sub_roles) {
          const active = [];
          module.sub_roles.forEach((sub) => {
            if (subName.includes(sub.name)) {
              active.push(sub);
            }
          });
          if (active.length) {
            module.sub_roles = active;
            activeModule.push(module);
          }
        } else if (
          userType === "sub_admin"
            ? result.includes(module.name)
            : module.name
        ) {
          activeModule.push(module);
        }
      });
    setData(activeModule);
  }, []);

  

  const setRouteValue = (item) => {
    let sideArr = [...sidebars];
    sideArr.map((item) => {
      item.isActive = false;
    });

    let index = sideArr.findIndex((obj) => obj.name === item.name);
    if (index >= 0) {
      sideArr[index].isActive = true;
      setSidebars(sideArr);
    }
  };

  return (
    <>
      <div className="qk_logo">
        <img src={Logo} id="qk_logo" width="200px" alt="qk" height="80px" />
      </div>
      <div className="qk_logo2">
        <img src={Logo2} alt="qk" />
      </div>
      <div className="qk_sidebar" id="qk_sidebar_admin">
        <div className="qk_sidebarWrap">
          {data &&
            data?.map((item, index) => {
              return (
                <SubMenu item={item} key={index} updateRoute={setRouteValue} />
              );
            })}
          
        </div>
      </div>
    </>
  );
};

export default Sidebar;
