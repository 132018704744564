import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../stylesheets/styles.css";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import ModalPopUp from "../../components/ui/modalpop";

import {
  Input,
  Table,
  Card,
  message,
  Spin,
  Modal,
  DatePicker,
  Select,
  Button,
  Form,
  Empty,
} from "antd";
import TableField from "../../components/ui/tableField";
import { STORAGE, UPDATE, APPROVAL, DELETE } from "../../constants/roleStore";
import layer from "../../assets/Layer 2.svg";
import InputItem from "../../components/ui/InputItem";
import DateItem from "../../components/ui/DateItem";
import moment from "moment";
import {
  showError,
  isInvalidName,
  showDate,
  showDates,
  showLongDateFormat,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  checkNameLength,
  isErrorTxt,
  checkDigitLength,
  checkTxtLength,
} from "../../constants/Utils";
import TopHeader from "../../components/ui/TopHeader";
import Filter from "../../components/ui/filter";

import "antd/dist/antd.css";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";
import GBLVAR from "../../ApiService/GlobalVariables/globalVariables";
import axios from "axios";
import cookie from "react-cookies";
import { HOLIDAYACCESS } from "../../components/ui/constants";
const { Option } = Select;

export default class Holiday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Holidaylist: [],
      search: "",
      currentPage: 1,
      total: "",
      pageSize: 20,
      loading: true,
      modalOpen: false,
      editmodal: false,
      ename: "",
      edate: "",
      edays: "",
      holiday_id: "",
      modal1Visible: false,
      modal2Visible: false,
      deleteHoliday: false,
      delete_name: "",
      file: "",
      count: 1,
      total: "",
      Tableloading: false,
      queryStatus: {},
      totalDoc: 0,
      key: "ty",
      statusData: [
        { value: "This Year", key: "ty" },
        { value: "Last Year ", key: "ly" },
        { value: "Upcoming Leaves ", key: "uc" },
      ],
      app_name: "",
      app_type: "",
      id: "",
      list_index: "",
      dropdown_menu: false,
      update: false,
      deleted: false,
    };
    this.wrapperRef = React.createRef();
  }
  componentDidMount() {
    let name = "";

    let storage = JSON.parse(STORAGE.view);
    name = storage?.filter((app) => app?.name === "Settings");

    let value = name;
    value?.filter((app) => {
      let names = app?.sub_roles?.filter((role) => role.name === "Holidays");
      names &&
        names.map((el) =>
          this.setState({
            update: el?.permissions?.update,
            deleted: el?.permissions?.delete,
          })
        );
      this.setState({
        app_name: names,
      });
    });

    this.setState({
      app_type: STORAGE.type,
    });
    this.setQueryStatus();
    this.reloadPage();
    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);
  }
  onPopstate = () => {
    const self = this;
    const search = this.props.location.search;
    const searchObj = returnSearchObject(search);

    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";
    this.state.key = searchObj.key ?? "";
    this.state.queryStatus = returnSearchObject(search);

    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      self.Holiday();
    });
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);
  }
  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };
  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);
    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";
    querysearch.key = this.state.key ?? "";

    return querysearch;
  };

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.setQueryStatus();
        }
      });
    });
  };

  setQueryStatus = () => {
    let query = this.props.location.search;

    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",
          key: queryObj.key ?? "",
          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.Holiday();
        }
      );
    } else {
      this.setState(
        {
          queryStatus: {
            page: 1,
            limit: 20,
            key: this.props?.location?.state?.searchString ?? "ty",
          },
          pageSize: 20,
        },
        () => {
          // pushToFilteredPage( this.props, this.state.queryStatus );
          // this.Holiday();
          this.handleOnStatusSearchChange(this.state.queryStatus.key);
        }
      );
    }
  };

  handleOnStatusSearchChange = (value) => {
    this.setState({ key: value, currentPage: 1 }, () => {
      this.handleFilter();
    });
  };

  handleFilter = () => {
    let { currentPage, key } = this.state;

    let query = this.getQuerySearch();

    this.setState(
      {
        queryStatus: query,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.Holiday();
      }
    );
  };
  Holiday = () => {
    APIGATEWAY.get(
      "employees/holidays" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          this.setState({
            Holidaylist: response.data.holiday,
            total: response.data.total,
            loading: false,
            total: response.data?.holiday?.length,
            Tableloading: false,
            totalDoc: response?.data?.total,
          });
        } else {
          this.setState({ loading: true });
        }
      }
    );
  };

  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  next = (prev) => {
    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.Holiday();
    });
  };
  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleEditPage = (username) => {
    this.setState({
      modal2Visible: true,
      holiday_id: username.holiday_id,
      edate: moment(username.date),
      ename: username.name,
    });
    this.HolidayDetail(username);
  };

  handleOk = () => {
    let { ename, edate, edays, holiday_id } = this.state;
    if (isErrorTxt(ename)) {
      showError("Please enter name");
    } else if (checkNameLength(ename)) {
      showError("Name should contain atleast 3 characters");
    } else if (isInvalidName(ename)) {
      showError("Name should contain letters only");
    } else if (isErrorTxt(edate)) {
      showError("Please select date");
    } else {
      let data = {
        name: ename,
        date: showDates(edate),
        day: edays,
      };
      if (holiday_id) {
        APIGATEWAY.patch(
          "employees/holidays/" + holiday_id,
          data,
          (response) => {
            if (response.success) {
              this.setState({ modal2Visible: false });
              this.Holiday();
              message.success(response.message);
              this.setState({
                holiday_id: "",
              });
            } else {
              message.error(response.message);
            }
          }
        );
      } else {
        APIGATEWAY.post("employees/holidays", data, (response) => {
          if (response.success) {
            this.setState({ modal2Visible: false });
            message.success(response.message);
            this.Holiday();
          } else {
            message.error(response.message);
          }
        });
      }
    }
  };

  HolidayDetail = (id) => {
    APIGATEWAY.get("employees/holidays/" + id, (response) => {
      if (response.success) {
        let Holiday = response.data.holiday;

        this.setState({
          ename: Holiday.name,
          edate: moment(Holiday.date),
          edays: Holiday.day,
          holiday_id: Holiday.holiday_id,
        });
      }
    });
  };

  handleaddCancel = () => {
    this.setState({ modalOpen: false });
  };
  handleEditCancel = () => {
    this.setState({ editmodal: false });
  };

  handleOnDateChange = (e) => {
    this.updateStateValues("edate", e);
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  leavedays = (value) => {
    this.setState({ edays: value }, () => {});
  };

  handleDelete = (id, object) => {
    this.setState({
      deleteHoliday: true,
      delete_name: object?.name ?? "- ",
      id: id,
    });
  };

  cancelDelete = () => {
    this.setState({ deleteHoliday: false, delete_name: "", id: "" });
  };

  submitDelete = () => {
    let self = this;
    let data = {};
    APIGATEWAY.delete(
      "employees/holidays/" + this.state.id,
      data,
      (response) => {
        if (response.success) {
          message.success(response.message);

          self.Holiday();
          this.setState({ deleteHoliday: false, delete_name: "", id: "" });
        }
      }
    );
  };
  setModal1Visible = (modal1Visible) => {
    this.setState({ modal1Visible, file: "" });
  };
  setModal2Visible = (modal2Visible) => {
    this.setState({ modal2Visible, ename: "", edate: "", holiday_id: "" });
  };
  handleOnChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };
  submitHandle = () => {
    let self = this;
    const formData = new FormData();

    const { file } = self.state;
    if (file === "") {
      showError("please import file");
    } else {
      formData.append("file", file);

      axios
        .post(`${GBLVAR.BASE_URL}/employees/holidays/import`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + cookie.load("empr_tk"),
          },
        })
        .then((result) => {
          message.success("Import successfully");
          this.Holiday();
          self.setState({
            modal1Visible: false,
            file: "",
          });
        });
    }
  };

  Apply = () => {
    const { status, from, to, searchInput, searchOption } = this.state;
    if (
      status === "" &&
      from === "" &&
      to === "" &&
      (searchInput === "" || searchOption === "")
    ) {
      showError("Please select any one field");
    } else {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handleFilter();
        }
      );
    }
  };

  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  clearSearch = () => {
    this.setState(
      {
        currentPage: 1,

        key: "",

        queryStatus: { page: 1, limit: 20 },
        pageSize: 20,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.Holiday();
      }
    );
  };

  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      list_index: index,
    });
  };

  render() {
    let {
      Holidaylist,
      currentPage,
      total,
      pageSize,
      modalOpen,
      editmodal,
      ename,
      holiday_id,
      edate,
      edays,
      app_name,
      app_type,
    } = this.state;

    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }

    let columns = [
      {
        title: "S.No",
        dataIndex: "S.No",

        render: (key, item, index) => (
          <div key={index} className="serials">
            {(this.state.currentPage - 1) * pageSize + (index + 1)}
          </div>
        ),
      },

      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (key) => <div>{key}</div>,
      },
      {
        title: "Date",
        dataIndex: "date",
        render: (date) => {
          return showLongDateFormat(date);
        },
      },
      {
        title: "Day",
        dataIndex: "day",
      },

      {
        title: "",
        dataIndex: "holiday_id",

        render: (holiday_id, object) => (
          <div style={{ display: "flex" }}>
            <div
              onClick={() => this.handleEditPage(object)}
              style={{ cursor: "pointer", color: "rgb(24 144 255)" }}
            >
              {UPDATE(app_type, app_name, <FormOutlined />)}
            </div>
            <div>
              {DELETE(
                app_type,
                app_name,
                <DeleteOutlined
                  onClick={() => this.handleDelete(holiday_id, object)}
                  style={{ marginLeft: "30px", color: "red" }}
                />
              )}
            </div>
          </div>
        ),
      },
    ];
    const title = <p className="dec-title">Holiday</p>;
    return (
      <div>
        {this.state.loading ? (
          <div className="spinning-star">
            <Spin size="large" />
          </div>
        ) : (
          <div className="">
            <div>
              <TopHeader
                title="Holidays"
                imports={() => this.setModal1Visible(true)}
                modals={() =>
                  this.setState({
                    modal2Visible: true,
                    ename: "",
                    edate: "",
                    edays: "",
                  })
                }
                type={app_type}
                permission={app_name}
              />

              <div className="main-content">
                <Filter
                  statusData={this.state.statusData}
                  statusName="key"
                  statusSelect={this.state.key}
                  statusOnChange={this.handleOnStatusSearchChange}
                  clear={() => this.clearSearch()}
                  apply={() => this.Apply()}
                  holiday={true}
                />

                <div className="table-main">
                  <div className="table_page_wrap">
                    {Holidaylist.length > 0 ? (
                      <table className="table-response">
                        <thead>
                          <tr>
                            <th className="">S.No</th>
                            <th className="">Name</th>
                            <th className="">Date</th>
                            <th className="">Day</th>
                            <th></th>
                          </tr>
                        </thead>

                        {Holidaylist.length > 0 ? (
                          <tbody ref={this.wrapperRef}>
                            {Holidaylist.map((holi, h) => (
                              <tr key={h}>
                                <td className="">
                                  {" "}
                                  {(this.state.currentPage - 1) * pageSize +
                                    (h + 1)}
                                </td>
                                <td className="table-name text-capitalize">
                                  {holi.name}
                                </td>
                                <td className="">
                                  {showLongDateFormat(holi.date)}
                                </td>
                                <td className="text-capitalize">{holi.day}</td>
                                {/* {app_type === 'sub_admin' && this.state.update === false && this.state.deleted === false ? "" : */}
                                {
                                  (HOLIDAYACCESS.update ||
                                    HOLIDAYACCESS.delete) && (
                                    <td
                                      className="table-action"
                                      onClick={() => this.dropdownClick(h)}
                                    >
                                      <div className="tax-add-edit">
                                        <span>
                                          <div className="dropdown-head">
                                            <div className="dropbtn-head">
                                              <img
                                                src={layer}
                                                alt="profile"
                                                className="menu-bar-icon"
                                              />
                                            </div>
                                            {this.state.list_index === h &&
                                              this.state.dropdown_menu && (
                                                <div className="dropdown-content-heads">
                                                  <div>
                                                    {HOLIDAYACCESS.update && (
                                                      <p
                                                        className="action-menu-bar"
                                                        onClick={() =>
                                                          this.handleEditPage(
                                                            holi
                                                          )
                                                        }
                                                      >
                                                        Edit
                                                      </p>
                                                    )}

                                                    {HOLIDAYACCESS.delete && (
                                                      <p
                                                        className="action-menu-bar"
                                                        onClick={() =>
                                                          this.handleDelete(
                                                            holi.holiday_id,
                                                            holi
                                                          )
                                                        }
                                                      >
                                                        Delete
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        </span>
                                      </div>
                                    </td>
                                  )}

                                {/* // }  */}
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          ""
                        )}
                      </table>
                    ) : (
                      <table className="table-response">
                        <thead>
                          <tr>
                            <th className="">S.No</th>
                            <th className="">Name</th>
                            <th className="">Date</th>
                            <th className="">Day</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody className="empty-message">
                          <tr>
                            <td colspan="4">
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                    {Holidaylist.length > 0 && (
                      <TableField
                        data={Holidaylist}
                        countprev={countprev}
                        countnext={countnext}
                        currentPage={currentPage}
                        ClickPrev={() => this.previous()}
                        ClickNext={() => this.next(this.state.currentPage)}
                        total={total}
                        overAll={this.state.totalDoc ?? 0}
                        pagelimit={this.state.pageSize}
                        pagelimitHandle={this.pageLimitOnchange}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Modal
                title={holiday_id ? "Edit Holiday" : "Add Holiday"}
                open={this.state.modal2Visible}
                onOk={() => this.setModal2Visible(false)}
                onCancel={() => this.setModal2Visible(false)}
                footer={[
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      key="cancel"
                      className="dec-btn-secondary"
                      onClick={() => this.setModal2Visible(false)}
                    >
                      Cancel
                    </button>

                    <button
                      key="submit"
                      className="dec-btn"
                      onClick={this.handleOk}
                    >
                      {holiday_id !== "" ? "Update" : "Add"}
                    </button>
                  </div>,
                ]}
              >
                <InputItem
                  label=""
                  name="ename"
                  value={ename}
                  star={false}
                  placeholder="Enter Holiday"
                  onChange={this.handleInputChange}
                />
                <DateItem
                  label=""
                  name="edate"
                  star={false}
                  value={edate}
                  placeholder="Date"
                  onChange={(e) => this.handleOnDateChange(e)}
                />
              </Modal>

              <Modal
                title="Import"
                style={{ top: 50 }}
                open={this.state.modal1Visible}
                onOk={() => this.setModal1Visible(false)}
                onCancel={() => this.setModal1Visible(false)}
                footer={[
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      key="cancel"
                      className="dec-btn-secondary"
                      onClick={() => this.setModal1Visible(false)}
                    >
                      Cancel
                    </button>

                    <button
                      key="submit"
                      className="dec-btn"
                      onClick={() => this.submitHandle()}
                    >
                      Submit
                    </button>
                  </div>,
                ]}
              >
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  autoComplete="off"
                  className="admin_input"
                >
                  <div>
                    <Input
                      type="file"
                      id="csvFileInput"
                      accept=".csv"
                      name="file"
                      onChange={(e) => this.handleOnChange(e)}
                    />
                  </div>
                </Form>
              </Modal>

              <ModalPopUp
                visible={this.state.deleteHoliday}
                cancel={this.cancelDelete}
                submit={this.submitDelete}
                name="Holiday"
                delete_name={this.state.delete_name}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
