import React, { Component } from "react";
import { Spin, Menu, Dropdown, Select, Empty } from "antd";
import { withRouter } from "react-router-dom";
import "../../stylesheets/styles.css";
import "antd/dist/antd.css";
import TopHeader from "../../components/ui/TopHeader";
import { STORAGE, UPDATE } from "../../constants/roleStore";
import layer from "../../assets/Layer 2.svg";
import { useLocation } from "react-router-dom";
import { Tooltip } from "antd";
import "antd/dist/antd.css";
import { AiOutlineInfoCircle } from "react-icons/ai";

import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { MoreOutlined } from "@ant-design/icons";
import {
  showDate,
  isErrorTxt,
  numberWithComma,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  empStatusColor,
} from "../../constants/Utils";
import Filter from "../../components/ui/filter";
import TableField from "../../components/ui/tableField";
import { EMPLOYEESACCESS } from "../../components/ui/constants";

const { Option } = Select;

class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total: "",
      currentPage: 1,
      pageSize: 20,
      loading: true,
      search: "",
      status: "active",
      employee_id: "",
      overall: "",
      name: "",
      number: "",
      email: "",
      searchInput: "",
      searchOption: "",
      Tableloading: true,
      statusData: [
        { value: "Active", key: "active" },
        { value: "Relieved", key: "relieved" },
        { value: "Fired", key: "fired" },
        { value: "Abscond", key: "abscond" },
        { value: "Offline", key: "offline" },
      ],
      searchData: [
        { value: "Employee ID", key: "empid" },
        { value: "Name", key: "name" },
        { value: "Number", key: "phonenumber" },
      ],
      app_name: "",
      app_type: "",
      queryStatus: "",
      isBack: false,
      dropdown_menu: false,
      list_index: "",
    };
    this.searchString = "";
    this.wrapperRef = React.createRef();
  }
  handleDetailpage = (username) => {
    this.props.history.push("/employees/detail/" + username);
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.handleTable();
    });
  };

  convertIsoDateToInteger = (startDate, type) => {
    const start = new Date(startDate);
    const end = new Date();
    let yearsDiff = end.getFullYear() - start.getFullYear();
    let monthsDiff = end.getMonth() - start.getMonth();
    const dayDiff = end.getDate() - start.getDate();
    if (dayDiff < 0) {
      monthsDiff--;
    }
    if (monthsDiff < 0) {
      yearsDiff--;
      monthsDiff += 12;
    }
    if (type === "type2") return `${yearsDiff}y-${monthsDiff}m`;
    else return `${yearsDiff}-${monthsDiff}`;
  };

  addYearsAndMonths = (yearMonth1, yearMonth2) => {
    const [year1, month1] = yearMonth1.split("-").map(Number);
    if (yearMonth1 && !yearMonth2) {
      const totalYears = year1;
      const totalMonths = month1;
      const result = `${year1}y-${month1 ?? 0}m`;
      return result;
    } else {
      const [year1, month1] = yearMonth1.split("-").map(Number);
      const [year2, month2] = yearMonth2?.split("-").map(Number);
      const totalYears = year1 + year2;
      const totalMonths = month1 + month2;
      const finalYears = totalYears + Math.floor(totalMonths / 12);
      const finalMonths = totalMonths % 12;
      const result = `${finalYears}y-${finalMonths}m`;

      return result;
    }
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          searchOption: queryObj.type,
          searchInput: queryObj.value,
          status: queryObj.status ?? "",
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",
          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);

          this.handleTable();
        }
      );
    } else {
      this.setState(
        {
          queryStatus: {
            page: 1,
            limit: 20,
            status: "active",
            type: this.searchString ?? "",
          },
          pageSize: 20,
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    }
  };

  handleFilter = () => {
    let query = this.getQuerySearch();

    this.setState(
      {
        queryStatus: query,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.handleTable();
      }
    );
  };

  handleTable = () => {
    APIGATEWAY.get(
      "employees" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          let currentData;

          currentData = response.data.employees;

          let total = response.data?.employees?.length;
          this.setState({
            data: currentData,
            name: response.data.name,
            total,
            loading: false,
            Tableloading: false,
            overall: response?.data?.total,
          });
        } else {
          this.setState({ loading: true });
        }
      }
    );
  };

  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };
  next = (prev) => {
    const { currentPage } = this.state;
    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  Apply = () => {
    this.setState(
      {
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  clearSearch = () => {
    this.setState(
      {
        searchInput: "",
        searchOption: "",
        status: "",
        currentPage: 1,
        queryStatus: { page: 1, limit: 20 },
        pageSize: 20,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.handleTable();
      }
    );
  };

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    if (this.props) {
      const { location } = this.props;
      this.searchString = location.state?.searchString;
    }
    this.setState({ status: params.get("status") || "" }, () => {
      this.setQueryStatus();
    });

    this.setState({ status: "active" });
    let name = "";

    let storage = JSON.parse(STORAGE.view);
    name = storage?.filter((app) => app?.name === "Employees");

    this.setState({
      app_name: name,
      app_type: STORAGE.type,
    });

    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);
    this.reloadPage();
  }

  onPopstate = () => {
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);
    this.state.status = searchObj.status ?? "";

    this.state.searchOption = searchObj.type ?? "";
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";

    this.state.searchInput = searchObj.value ?? "";
    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      this.handleTable();
    });
    // }
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);
  }
  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };
  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.stateActive();
        }
      });
    });
  };

  stateActive = () => {
    this.setState(
      {
        searchInput: "",
        searchOption: "",
        status: "active",
        currentPage: 1,
        queryStatus: { page: 1, limit: 20, status: "active" },
        pageSize: 20,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.handleTable();
      }
    );
  };

  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    if (this.state.searchOption !== "" && this.state.searchInput !== "") {
      querysearch.type = this.state.searchOption;
      querysearch.value = this.state.searchInput;

      querysearch[this.state.searchOption] = this.state.searchInput;
    }

    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";
    querysearch.status = this.state.status ?? "";

    return querysearch;
  };
  handleEditPage = (userid) => {
    this.props.history.push("/employees/edit/" + userid); //new component gopi
  };
  handleSearchChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.handleTable();
      }
    );
  };
  searchOption = (value) => {
    this.setState({
      searchOption: value,
    });
  };
  handleOnSearchChange = (value) => {
    this.setState({ status: value, currentPage: 1 }, () => {
      this.handleFilter();
    });
  };
  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.handleTable();
      }
    );
  };
  handleActions = (e, id) => {
    if (e === "edit") {
      this.handleEditPage(id);
    } else {
      this.props.history.push("/employees/expense/" + id);
    }
  };
  searchInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      list_index: index,
    });
  };
  render() {
    const { app_name, app_type } = this.state;
    const menu = (emp_id) => (
      <Menu
        className="dropdown-menu-bar"
        onClick={(e) => this.handleActions(e, emp_id)}
      >
        <Menu.Item key="edit">Edit</Menu.Item>
      </Menu>
    );

    let { data, currentPage, total, pageSize } = this.state;

    let countnext = total;
    let countprev = total - (total - 1);

    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }

    return (
      <div>
        <div>
          <TopHeader
            title="Employees"
            to="/employees/create"
            // to={`/employees/new`}
            type={app_type}
            permission={app_name}
            state={true}
          />
          <div style={{ padding: "0px 23px 30px 30px" }}>
            <Filter
              searchData={this.state.searchData}
              statusData={this.state.statusData}
              optionChange={(value) => this.searchOption(value)}
              searchOption={this.state.searchOption}
              searchname="searchOption"
              statusName="status"
              statusSelect={this.state.status}
              statusOnChange={(value) => this.handleOnSearchChange(value)}
              clear={() => this.clearSearch()}
              apply={() => this.Apply()}
              searchInputName="searchInput"
              searchInput={this.state.searchInput}
              inputChange={(e) => this.searchInput(e)}
            />

            {this.state.Tableloading ? (
              <div className="tableview">
                <Spin />
              </div>
            ) : (
              <div>
                <div className="table-main">
                  {this.state.Tableloading ? (
                    <div className="tableview">
                      <Spin />
                    </div>
                  ) : (
                    <div className="table_page_wrap">
                      {data?.length > 0 ? (
                        <table className="table-response">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Id</th>
                              <th>Name</th>
                              <th>Date Of Join</th>
                              <th>Experience</th>

                              {/* <th>Salary</th> */}
                              <th>Next Review</th>

                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>

                          {data.length > 0 ? (
                            <tbody ref={this.wrapperRef}>
                              {data.map((item, r) => (
                                <tr key={r}>
                                  <td className="">
                                    {" "}
                                    {(this.state.currentPage - 1) *
                                      this.state.pageSize +
                                      (r + 1)}
                                  </td>
                                  <td className="table-name ">
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.handleDetailpage(item.emp_id)
                                      }
                                    >
                                      {item?.official_emp_id}
                                    </div>
                                  </td>
                                  <td className="table-name text-capitalize">
                                    {isErrorTxt(item?.name) ? "-" : item.name}
                                  </td>
                                  <td className="table-name ">
                                    {isErrorTxt(item?.official_date?.joined)
                                      ? "-"
                                      : showDate(
                                          `${item?.official_date?.joined}`
                                        )}
                                  </td>
                                  <td className="table-name xp d-flex ">
                                    <Tooltip
                                      style={{
                                        marginLeft: 70,
                                        whiteSpace: "nowrap",
                                      }}
                                      placement="right"
                                      title={
                                        <div>
                                          Previous :
                                          {item?.experience?.is_experienced
                                            ? this.addYearsAndMonths(
                                                item?.experience?.years,
                                                null
                                              )
                                            : "0y-0m"}
                                          <br />
                                          In Quadkast :{" "}
                                          {this.convertIsoDateToInteger(
                                            item?.official_date?.joined,
                                            "type2"
                                          )}
                                        </div>
                                      }
                                    >
                                      {item?.experience?.is_experienced
                                        ? this.addYearsAndMonths(
                                            item?.experience?.years,
                                            this.convertIsoDateToInteger(
                                              item?.official_date?.joined,
                                              "type1"
                                            )
                                          )
                                        : this.convertIsoDateToInteger(
                                            item?.official_date?.joined,
                                            "type2"
                                          )}
                                      <span className="pointer">
                                        {" "}
                                        <AiOutlineInfoCircle />
                                      </span>
                                    </Tooltip>
                                  </td>
                                  {/* <td className="table-name ">
                                    <Tooltip
                                      placement="right"
                                      title={
                                        numberWithComma(
                                          item?.current_salary?.monthly
                                        ) ?? 0
                                      }
                                    >
                                      {" "}
                                      <span className="pointer">
                                        {" "}
                                        <AiOutlineInfoCircle />
                                      </span>
                                    </Tooltip>
                                  </td>{" "} */}
                                  <td className="table-name">
                                    {item?.next_review
                                      ? showDate(`${item?.next_review}`)
                                      : "-"}
                                  </td>
                                  <td className="table-name text-capitalize font-weight-content">
                                    {empStatusColor(item?.status)}
                                  </td>
                                  {EMPLOYEESACCESS.update && (
                                    <td
                                      className="table-name"
                                      onClick={() => this.dropdownClick(r)}
                                    >
                                      <div className="tax-add-edit">
                                        <span className="ml-5">
                                          <div className="dropdown-head">
                                            <div className="dropbtn-head">
                                              <img
                                                src={layer}
                                                alt="profile"
                                                className="menu-bar-icon"
                                              />
                                            </div>
                                            {this.state.list_index === r &&
                                              this.state.dropdown_menu && (
                                                <div className="dropdown-content-heads">
                                                  <div>
                                                    <p
                                                      className="action-menu-bar"
                                                      onClick={() =>
                                                        this.handleActions(
                                                          "edit",
                                                          item.emp_id
                                                        )
                                                      }
                                                    >
                                                      Edit
                                                    </p>
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        </span>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            ""
                          )}
                        </table>
                      ) : (
                        <table className="table-response">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Employee Id</th>
                              <th>Name</th>
                              <th>Date Of Join</th>

                              <th>Current Salary</th>
                              <th>Experience</th>
                              <th>Next Review Date</th>

                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>

                          <tbody className="empty-message">
                            <tr>
                              <td colSpan="11">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                      {data?.length > 0 && (
                        <TableField
                          data={data}
                          countprev={countprev}
                          countnext={countnext}
                          currentPage={currentPage}
                          ClickPrev={() => this.previous()}
                          ClickNext={() => this.next(this.state.currentPage)}
                          total={total}
                          overAll={this.state.overall ?? 0}
                          pagelimit={this.state.pageSize}
                          pagelimitHandle={this.pageLimitOnchange}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Employees);
