import { Space, Drawer, Select, Spin, message } from "antd";
import TopHeader from "../../components/ui/TopHeader";
import close from "../../assets/emp_close.svg";
import { useEffect, useState } from "react";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";

const { Option } = Select;

function UpdateProjectComponent(props) {
  const [btnLoader, setBtnLoader] = useState(false);
  const [initPageLoading, setInitPageLoading] = useState(true);
  const [updateStatus, setUpdateStatus] = useState({
    title: "",
    changeStatus: "",
    notes: "",
  });

  useEffect(() => {
    if (props.from === 'changeRequest') getRequestDetail(props.projectId);
    else if (props.from === 'meeting') getMeetingDetail(props.id)
    else if (props.from === 'review') getReviewDetail(props.id)
    else if (props.from === 'reference') getReferenceDetail(props.id)
    else getProject(props.projectId)
  }, [props.projectId]);

  function getProject(id) {
    APIGATEWAY.get(`project/${id}`, (response) => {
      if (response.success) {
        setInitPageLoading(false);
        setUpdateStatus({
          title: response.data.project[0].name,
          changeStatus: response.data.project[0].status,
          notes: response.data.project[0].description,
        });
      }
    });
  }

  const getRequestDetail = (id) => {
    APIGATEWAY.get(`project/changerequest/detail/${id}`, (response) => {
      if (response.success) {
        setUpdateStatus({
          title: response?.data?.changeRequest.title,
          changeStatus:response.data?.changeRequest.status,
          // notes: response.data?.changeRequest?.notes,
        });
        setInitPageLoading(false);
      }
    });
  };

  const getMeetingDetail = (id) => {
    APIGATEWAY.get(`project/meeting/detail/${id}`, (response) => {
      if (response.success) {
        setUpdateStatus({
          title: response?.data?.meeting.title,
          changeStatus:response.data?.meeting.status,
          // notes: response.data?.meeting?.notes,
        });
        setInitPageLoading(false);
      }
    });
  };

  const getReviewDetail = (id) => {
    APIGATEWAY.get(`project/review/detail/${id}`, (response) => {
      if (response.success) {
        setUpdateStatus({
          title: response?.data?.review.title,
          changeStatus:response.data?.review.status,
          // notes: response.data?.review?.notes,
        });
        setInitPageLoading(false);
      }
    });
  };

  const getReferenceDetail = (id) => {
    APIGATEWAY.get(`project/reference/detail/${id}`, (response) => {
      if (response.success) {
        setUpdateStatus({
          title: response?.data?.reference.title,
          changeStatus:response.data?.reference.status,
          // notes: response.data?.reference?.notes,
        });
        setInitPageLoading(false);
      }
    });
  };

const onClose=()=>{
   setUpdateStatus({
    title: "",
    changeStatus: "",
    notes: "",
  });
  props.onClose()
}


  const statusTitleChange = (event) => {
    const titleip = event.target.value;
    setUpdateStatus((previous) => {
      return { ...previous, title: titleip };
    });
  };

  const updateProjectStatus = (event) => {
    setUpdateStatus((previous) => {
      return { ...previous, changeStatus: event };
    });
  };

  const updateProjectNotes = (event) => {
    event.persist();
    setUpdateStatus((previous) => {
      return { ...previous, notes: event.target.value };
    });
  };

  function update() {
    setBtnLoader(true);
    const { title, changeStatus, notes } = updateStatus;
    const body = {
      status: updateStatus.changeStatus,
      notes: updateStatus.notes,
    };
    if(props.from==='changeRequest')
      APIGATEWAY.patch(`project/changerequest/status/${props.projectId}`, body, (response) => {
        setBtnLoader(false);
        if (response.success) {
          message.success("Change Request status  updated successfully");
          setUpdateStatus({
            title: "",
            changeStatus: "",
            notes: "",
          })
          props.update(response.success,response);

        }
      });
    else if (props.from === 'meeting') 
      APIGATEWAY.patch(`project/meeting/status/${props.id}`, body, (response) => {
        setBtnLoader(false);
        if (response.success) {
          message.success("Meeting status  updated successfully");
          setUpdateStatus({
            title: "",
            changeStatus: "",
            notes: "",
          })
          props.update(response.success,response);
        }
      });
    else if (props.from === 'review')
      APIGATEWAY.patch(`project/review/status/${props.id}`, body, (response) => {
        setBtnLoader(false);
        if (response.success) {
          message.success("Review status  updated successfully");
          setUpdateStatus({
            title: "",
            changeStatus: "",
            notes: "",
          })
          props.update(response.success,response);
        }
      });
    else if (props.from === 'reference')
      APIGATEWAY.patch(`project/reference/status/${props.id}`, body, (response) => {
        setBtnLoader(false);
        if (response.success) {
          message.success("Reference status  updated successfully");
          setUpdateStatus({
            title: "",
            changeStatus: "",
            notes: "",
          })
          props.update(response.success,response);
        }
      });
    else APIGATEWAY.patch(`project/${props.projectId}`, body, (response) => {
      setBtnLoader(false);
      if (response.success) {
        message.success("Project status  updated successfully");
        props.update(response.success,response);
      }
    })
  }

  return (
    <div>
      {initPageLoading ? (
        <div className="spinning-star">
           <Spin size="large" />
        </div>
      ) : (
        <div>
          <div className="tax-drawer">
            <div className="drawer-head">
              <span className="add-tax">{"Update Status"}</span>
              <span onClick={()=>onClose()} className="tax-close">
                <img src={close} alt="close" />
              </span>
            </div>

            <div style={{ margin: "40px 25px" }} className="">
              <div className="tax-label">
                <label className="mb_15">Title</label>
                <div className="tax-label-input">
                  <input
                    disabled="true"
                    type="text"
                    name="tax_name"
                    placeholder="Enter a Title"
                    autoComplete="off"
                    value={updateStatus.title}
                  />
                </div>
              </div>

              <div className="tax-label mb_24">
                <label className="mb_15">
                  Change Status <span className="star">*</span>
                </label>
                <Select
                  //   className="create-project-select"
                  value={updateStatus.changeStatus}
                  style={{ width: "100%" }}
                  placeholder="Select Platform"
                  onChange={(e) => updateProjectStatus(e)}
                  className="projectStatus"
                >
                 
                  {props.statusData.map((ele, i) => (
                    <Option value={ele.key} key={i}>
                      {ele.value}
                    </Option>
                  ))}
                </Select>
              </div>

              {props.from ==='project' &&

              <div className="tax-label">
                <label>Notes</label>
                <div className="tax-label-input">
                  <textarea
                    placeholder="Notes"
                    name="description"
                    autoComplete="off"
                    value={updateStatus.notes}
                    onChange={(e) => updateProjectNotes(e)}
                  ></textarea>
                </div>
              </div>
}
              <div>
                <TopHeader
                  // title="projects"
                  backClick={()=>onClose()}
                  classname="p_0"
                  submit={update}
                  id={props.id}
                  spin={btnLoader}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UpdateProjectComponent;
