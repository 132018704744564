import React, { Component } from "react";
import { BrowserRouter as Route } from "react-router-dom";
//Layout
import DefaultLayout from "../layouts/index";
import Signup from "../pages/Auth/Signup";
import isLoggedIn from "../constants/condition";
class PrivateRouter extends Component {
  render = () => {
    return isLoggedIn() ? (
      <DefaultLayout />
    ) : (
      <Route path="/" exact component={Signup} />
    );
  };
}

export default PrivateRouter;
