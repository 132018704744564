import React, { Component } from "react";
import "../../stylesheets/styles.css";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import {
  Input,
  Table,
  Card,
  message,
  Spin,
  Modal,
  Select,
  Button,
  Dropdown,
  Menu,
  Space,
  Drawer,
  Tooltip,
} from "antd";
import tick from "../../assets/tick.svg";
import layer from "../../assets/Layer 2.svg";
import ModalPopUp from "../../components/ui/modalpop";
import close from "../../assets/emp_close.svg";

import {
  showError,
  showLongDateFormat,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
} from "../../constants/Utils";
import TopHeader from "../../components/ui/TopHeader";

import { default as Rselect } from "react-select";
import "antd/dist/antd.css";
import { MoreOutlined } from "@ant-design/icons";
import Timeoff from "../../assets/time_role.svg";
import close_emp from "../../assets/emp_close.svg";
import Hiring from "../../assets/hiring_role.svg";
import search_a from "../../assets/search.svg";
import Dashboard from "../../assets/dashboard_role.svg";
import "./role_manage.css";
import TableField from "../../components/ui/tableField";
import Employees from "../../assets/apps/Group 1000006602.svg";
import Business from "../../assets/business_role.svg";
import settings from "../../assets/settings_role.svg";
import my_payments from "../../assets/payments_role.svg";


import Expense from "../../assets/apps/Group 1000006602 (10).svg";

// const {Search}= Input

export default class RoleManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      currentPage: 1,
      total: "",
      role_id: "",
      loading: true,
      modalOpen: false,
      editmodal: false,
      apps: {
        Dashboard: false,
        Hiring: false,
        // Business: false,
        Employees: false,
        "My Business": false,
        "Time Off": false,
        "My Payments": false,
        "Settings": false,
      },
      key: "",
      totalDoc: 0,
      delete_name: "",
      disabled: false,
      select_employees: [],
      apps_array: [],
      apps_img: [],
      RoleList: [],
      emp_ids: [],
      emp_drawer: {},
      value: "",
      employees_list: [],
      default_employee: [],
      apps_list: this.apps_list,
      ename: "",
      edate: "",
      mainEmp: [],
      openEmployee: false,
      openDrawer: false,
      drawerEmployees: {},
      edays: "",
      modal1Visible: false,
      deleteModalVisible: false,
      file: "",
      modalVisible: false,
      count: 1,
      search_term: "",
      employees: [],
      Tableloading: false,
      queryStatus: {},
      pageSize: 20,
      dropdown_menu: false,
      list_index: '',
    };
    this.wrapperRef = React.createRef();

  }
  componentDidMount() {
    // this.RoleList();
    window.addEventListener("popstate", this.onPopstate);
    this.setQueryStatus();

    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.setQueryStatus();
        }
      });
    });
    document.addEventListener("click", this.clickOutside);

  }

  onPopstate = () => {
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);
    this.state.search_term = searchObj.search_term ?? "";
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";

    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      this.RoleList();
    });
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);

  }
  clickOutside = (e) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(e.target)) {
      this.setState({ dropdown_menu: false });
    }
  };
  capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  setDrawerOpen = () => {
    this.EmployeeList();

    this.setState({ openDrawer: true });
  };
  EmployeeList = () => {
    APIGATEWAY.get("rolemanagement/list/employee", (response) => {
      if (response.success) {
        let employees_list = response.data.remainEmp.map((users) => {
          return { value: users.emp_id, label: users.name };
        });

        this.setState({
          employees: employees_list,
          loading: false,
          Tableloading: false,
        });
      } else {
        this.setState({ loading: true });
      }
    });
  };
  toolUsers = (value) => {
    let done = value.map((val) => val.name);
    return done.toString();
  };
  openEmployee = (value) => {
    this.setState({
      openEmployee: true,
      drawerEmployees: value,
      role_id: value.rolemanagement_id,
    });

    let apps = this.state.apps;
    value.apps.map((role) => {
      return (apps[role.name] = true);
    });
    this.setState({ apps: apps });

    let apps_array = Object.entries(this.state.apps);

    this.setState({ apps_array: apps_array });
  };
  RoleListDetail = (value) => {
    var queryParams = "?name=" + value;

    APIGATEWAY.get("rolemanagement" + queryParams, (response) => {
      if (response.success) {
        this.setState({
          total: response.data.total,
          loading: false,
          employees: response.data.roles[0].users,
          Tableloading: false,
        });
      } else {
        this.setState({ loading: true });
      }
    });
  };

  handleChange = (e) => {
    this.setState({ search_term: e.target.value }, () => {
      // this.RoleList("?name=" + this.state.search_term);
      this.handleFilter();
    });
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          search_term: queryObj.name ?? "",

          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",
          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.RoleList();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.RoleList();
        }
      );
    }
  };
  getQuerySearch = () => {
    const { search_term, currentPage, pageSize } = this.state;
    const querysearch = returnSearchObject(this.props.location.search);

    querysearch.name = search_term ?? "";
    querysearch.page = currentPage ?? "";
    querysearch.limit = pageSize ?? "";
    return querysearch;
  };

  handleFilter = () => {
    let { currentPage, search_term } = this.state;

    let query = this.getQuerySearch();
    this.setState(
      {
        queryStatus: query,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.RoleList();
      }
    );
  };

  RoleList = (term) => {
    let { currentPage } = this.state;



    APIGATEWAY.get(
      "rolemanagement" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          this.setState(
            {
              RoleList: response.data.roles,
              loading: false,
              total: response.data?.roles?.length,
              Tableloading: false,
              totalDoc: response.data?.total,
            },
            () => {
              if (this.state.total <= 0) {
                this.setState({ disabled: true });
              }
            }
          );
        } else {
          this.setState({ loading: true });
        }
      }
    );
  };


  submitHandle = () => {
    let data = {
      emp_id: [this.state.emp_drawer.emp_id],
    };
    if (this.state.emp_drawer) {
      APIGATEWAY.patch(
        "rolemanagement/remove_employee/" + this.state.role_id,
        data,
        (response) => {
          if (response.success) {

            message.success(response.message);
            this.setState({
              modalVisible: false,
              emp_drawer: {},
              openEmployee: false,
            });
            this.RoleList();
          } else {
            message.error(response.message);
          }
        }
      );
    }
  };
  addEmployeeDetail = () => {
    let { mainEmp, employees_list, role_id } = this.state;
    if (!role_id) {
      return message.error("Please select role");
    }
    if (employees_list.length <= 0) {
      return showError("Please select employees");
    }

    let default_emp_ids = [];
    let select_emp_ids = [];
    mainEmp.map((value) => {
      return default_emp_ids.push(value.value);
    });
    employees_list.map((value) => {
      return select_emp_ids.push(value.value);
    });

    let difference = select_emp_ids.filter((x) => !default_emp_ids.includes(x));
    let difference2 = default_emp_ids.filter(
      (x) => !select_emp_ids.includes(x)
    );
    //  let add_emp = difference2.map(user=>{
    //   return add_emp_ids.push(user.value)
    //  })

    let data = {
      add_emp: difference,
      remove_emp: difference2,
    };

    APIGATEWAY.patch(
      "rolemanagement/update_employee/" + role_id,
      data,
      (response) => {
        if (response.success) {
          message.success(response.message);
          this.setState(
            { employees_list: [], openDrawer: false, value: "", role_id: "" },
            () => {
              this.RoleList();
              this.EmployeeList();
            }
          );
        } else {
          message.error(response.message);
        }
      }
    );
    // }
  };

  SelectChange = (value, e) => {
    // let new_one=value
    if (e.action === "select-option") {
      this.setState({
        employees_list: [
          ...this.state.employees_list,
          { value: e.option.value, label: e.option.label },
        ],
      });
    } else {
      let final = this.state.mainEmp.find(
        (list) => list.value === e.removedValue.value
      );

      if (final) {
        let end = this.state.employees.find(
          (list) => list.value === e.removedValue.value
        );
        if (!end) {
          this.setState({
            employees: [
              ...this.state.employees,
              { value: e.removedValue.value, label: e.removedValue.label },
            ],
          });
        }
      }

      this.setState({
        employees_list: this.state.employees_list.filter(function (app) {
          return app.value !== e.removedValue.value;
        }),
      });
    }
  };
  SelectHandleChange = (value) => {
    this.setState(
      {
        value: value.label,
        role_id: value.value,
        employees_list: [],
      },
      () => {
        APIGATEWAY.get("/rolemanagement/" + value.value, (response) => {
          if (response.success) {
            let employees_list = response.data.roles.users.map((users) => {
              return { value: users.emp_id, label: users.name };
            });

            this.setState({
              employees_list: employees_list,
              mainEmp: employees_list,
            });
          }
        });
      }
    );
  };

  closeDrawer = () => {
    this.setState({
      openDrawer: false,
      value: "",
      role_id: "",
      openEmployee: false,
      employees_list: [],
      apps: {
        Dashboard: false,
        Hiring: false,
        // Business: false,
        Employees: false,
        "My Business": false,
        "Time Off": false,
        "My Payments": false,
        "Settings": false
      },
      apps_array: []
    });
  };
  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      // this.RoleList();
      this.handleFilter();
    });
  };

  addEmployeeDetailIds = (value) => {
    this.setState({ employees: [...this.state.employees, value.name] });
  };
  next = (prev) => {
    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      // this.RoleList();
      this.handleFilter();
    });
  };
  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.RoleList();
    });
  };
  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleEditPage = (key) => {
    this.props.history.push("role_manage/edit/" + key);
  };

  apps_list = {
    "Time Off": Timeoff,
    Dashboard,
    Hiring,
    Expense,
    Employees,
    "My Business": Business,
    "My Payments": my_payments,
    "Settings": settings,

  };

  handleaddCancel = () => {
    this.setState({ modalOpen: false });
  };
  handleEditCancel = () => {
    this.setState({ editmodal: false });
  };

  handleOnDateChange = (e) => {
    this.updateStateValues("edate", e);
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  leavedays = (value) => {
    this.setState({ edays: value }, () => { });
  };

  handleDetailpage = () => {
    this.props.history.push("role_manage/create");
  };

  handleOnChange = (event) => {
    let name = JSON.parse(event.pop());

    this.setState({
      employees_list: [...this.state.employees_list, name.name],
    });
  };
  DrawerCloseClick = (emp_id, name) => {
    this.setState({ modalVisible: true });
    this.setState({ emp_drawer: { emp_id, name } });
  };
  deleteSubmitHandle = () => {
    APIGATEWAY.delete(
      "rolemanagement/delete/" + this.state.key,
      {},
      (response) => {
        if (response.success) {
          this.setState({ key: "", delete_name: "", deleteModalVisible: false });
          message.success(response.message);
          this.RoleList();
          // window.location.reload();
        }
      }
    );
  };
  dropdownClick = (index) => {
    this.setState({ dropdown_menu: !this.state.dropdown_menu, list_index: index });
  };
  render() {
    let {
      RoleList,
      currentPage,
      total,
      apps_list,
      openDrawer,
      employees,
      value,
      role_id,
      search_term,
      openEmployee,
      employees_list,
      drawerEmployees,
      pageSize,
    } = this.state;

    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }
    const menu = (key, name) => (
      <Menu style={{ width: "130px" }}>
        <Menu.Item key={key + "edit"} onClick={() => this.handleEditPage(key)}>
          Edit
        </Menu.Item>
        <Menu.Item
          key={key + "delete"}
          onClick={() =>
            this.setState({
              deleteModalVisible: true,
              key: key,
              delete_name: name,
            })
          }
        >
          Delete
        </Menu.Item>
      </Menu>
    );

    let columns = [
      {
        title: (
          <div className="nice" style={{ width: "100%", marginRight: "-4%" }}>
            <Input
              className="oksd"
              value={search_term}
              prefix={<img src={search_a} alt="search" />}
              placeholder="Search Role"
              onChange={(e) => this.handleChange(e)}
            />
          </div>
        ),
        dataIndex: "name",
        key: "name",
        width: 250,
        render: (key) => <div className="text-capitalize" style={{ paddingLeft: "15%" }}>{key}</div>,
      },
      {
        title: <div>Created At</div>,
        key: "createdAt",
        dataIndex: "createdAt",
        render: (date) => {
          return showLongDateFormat(date);
        },
      },
      {
        title: <div>Users</div>,
        key: "rolemanagement_id",
        dataIndex: ["users", "rolemanagement_id"],
        render: (users, rolemanagement_id) => {
          return rolemanagement_id?.users?.length > 0 ? (
            <div
              className="table_profile"
              style={{ paddingLeft: "0.8rem" }}
              onClick={() => this.openEmployee(rolemanagement_id)}
            >
              {rolemanagement_id.users.slice(0, 3).map((users, index) => (
                <Tooltip title={users.name}>
                  <div
                    className={`profile-picture_new ${"profile_" + index}`}
                    style={{
                      marginLeft: "-12px",
                      padding: "16px",
                      fontSize: "16px",
                    }}
                  >
                    {users.name.slice(0, 1)}
                  </div>
                </Tooltip>
              ))}
              {rolemanagement_id.users.length >= 4 && (
                <Tooltip
                  title={this.toolUsers(rolemanagement_id.users.slice(3))}
                >
                  <p
                    style={{
                      backgroundColor: "#FFA8A7",
                      height: "34px",
                      width: "34px",
                      cursor: "pointer",
                      marginLeft: "-12px",
                      borderRadius: "68%",
                      marginBottom: "0rem",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",

                        left: "24.5%",
                        top: "22%",
                        color: "#475569",
                      }}
                    >
                      +{rolemanagement_id.users.length - 3}
                    </span>
                  </p>
                </Tooltip>
              )}
            </div>
          ) : (
            <p style={{ paddingLeft: "1rem" }}>-</p>
          );
        },
      },
      {
        title: <div style={{ marginLeft: "4%" }}>Apps</div>,
        dataIndex: "apps",
        key: "rolemanagement_id",
        render: (apps) => {
          return apps.length > 0 ? (
            <div className="table_profile">
              {apps.slice(0, 3).map((apps, i) => (
                <Tooltip title={apps.name}>
                  {" "}
                  <img
                    style={{ height: "34px", cursor: "pointer" }}
                    src={apps_list[this.capitalize(apps.name)]}
                    alt={apps.name}
                  />
                </Tooltip>
              ))}{" "}
              {apps.length >= 4 && (
                <Tooltip title={this.toolUsers(apps.slice(3))}>
                  {" "}
                  <p
                    style={{
                      backgroundColor: "#E8E8E8",
                      height: "34px",
                      width: "34px",
                      borderRadius: "68%",
                      marginBottom: "0rem",
                      position: "relative",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        left: "24.5%",
                        top: "20%",
                        color: "#475569",
                      }}
                    >
                      +{apps.length - 3}
                    </span>
                  </p>
                </Tooltip>
              )}
            </div>
          ) : (
            <p>---</p>
          );
        },
      },
      {
        title: "Last updated",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (date) => {
          return showLongDateFormat(date);
        },
      },
      {
        title: "",

        render: (object) => (
          <div className="dropdown-head" onClick={() => this.dropdownClick(object.rolemanagement_id)}>
            <div className="dropbtn-head">
              <img
                src={layer}
                alt="profile"
                className='menu-bar-icon'

              />

            </div>
            {this.state.list_index === object.rolemanagement_id && this.state.dropdown_menu && (
              <div className="dropdown-content-heads">
                <div>

                  <p
                    className="action-menu-bar"
                    onClick={() => this.handleEditPage(object.rolemanagement_id)}

                  >
                    Edit
                  </p>



                  <p className="action-menu-bar"
                    onClick={() =>
                      this.setState({
                        deleteModalVisible: true,
                        key: object.rolemanagement_id,
                        delete_name: object.name,
                      })
                    }>
                    Delete
                  </p>
                </div>


              </div>)}
          </div>

        ),
      },
    ];
    return (
      <div>
        {this.state.loading ? (
          <div className="spinning-star">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <Card>
              {this.state.Tableloading ? (
                <div className="tableview">
                  <Spin />
                </div>
              ) : (
                <>
                  <TopHeader
                    title="Role Management"
                    to="/role_manage/create"
                    type=""
                    assing={this.setDrawerOpen}

                  />
                  <div style={{padding: '0px 23px 30px 30px'}} >
                    {this.state.Tableloading ? (
                      <div className="tableview">
                        <Spin />
                      </div>
                    ) : (
                      <div ref={this.wrapperRef}>
                        <TableField
                          column={columns}
                          data={RoleList}
                          countprev={countprev}
                          countnext={countnext}
                          currentPage={currentPage}
                          ClickPrev={() => this.previous()}
                          ClickNext={() => this.next(this.state.currentPage)}
                          total={total}
                          overAll={this.state.totalDoc ?? 0}
                          pagelimit={this.state.pageSize}
                          pagelimitHandle={this.pageLimitOnchange}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </Card>
          </div>
        )}

        <Space>
          <Drawer
            placement="right"
            // title="Users List"
            open={openEmployee}
            onClose={() => this.closeDrawer()}
            // headerStyle={{ backgroundColor: "#FAFAFA" }}
            keyboard={true}
            autoFocus={true}
            closable={false}
            // width="30%"
            className='tax-main-drawer'
          >
            <div className="tax-drawer">
            <div className="drawer-head">
                  <span className="add-tax">Users List</span>
                  <span onClick={() => this.closeDrawer()} className="tax-close"><img src={close} alt="close" /></span>
                </div>
              <div className="item_content tax-content-field">
                <div>
                  <p className="drawer_title">Role Name</p>
                  <Input
                    style={{ height: "3rem" }}
                    name="price"
                    value={drawerEmployees.name}
                    disabled
                  />
                  {drawerEmployees?.users?.length > 0 &&
                    drawerEmployees.users.map((roles, index) => (
                      <div
                        style={{
                          display: "flex",
                          marginTop: "6%",
                          width: "100%",
                        }}
                      >
                        {" "}
                        <div
                          className={`profile-picture_new ${"profile_" + index
                            }`}
                          style={{
                            height: "34px",
                            width: "34px",
                            alignSelf: "center",
                          }}
                        >
                          {roles.name.slice(0, 1)}
                        </div>{" "}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "0.5rem",
                            width: "80%",
                          }}
                        >
                          <span
                            style={{
                              marginBottom: "none",
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#333333",
                              textTransform:"capitalize"
                            }}
                          >
                            {roles.name}
                          </span>
                          <span
                            style={{
                              marginBottom: "none",
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#757575",
                            }}
                          >
                            {roles?.email??"-"}
                          </span>
                        </div>
                        <img
                          src={close_emp}
                          alt="close"
                          style={{ cursor: "pointer", width: "25px" }}
                          onClick={() =>
                            this.DrawerCloseClick(roles.emp_id, roles.name)
                          }
                        ></img>
                      </div>
                    ))}

                  <div style={{ width: "95%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        marginTop: "7%",
                        flexFlow: "wrap",
                        marginLeft: "1rem",
                        width: "100%",
                      }}
                    >
                      {this.state.apps_array.map((role) =>
                        role[1] ? (
                          <div
                            className="border-check"
                            style={{ backgroundColor: "#F2FCF5" }}
                          >
                            {" "}
                            <img src={tick} alt="tick" />{" "}
                            <span>{role[0] && this.capitalize(role[0])}</span>
                          </div>
                        ) : (
                          <div className="border-check">
                            <span>{role[0] && this.capitalize(role[0])}</span>{" "}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </Space>
       
          <Drawer
            placement="right"
            // title="Assign User"
            open={openDrawer}
            onClose={() => this.closeDrawer()}
            // headerStyle={{ backgroundColor: "#fafafa" }}
            closable={true}
            keyboard={true}
            autoFocus={true}
            className='tax-main-drawer'
          >

            <div className="tax-drawer">
               <div className="drawer-head">
                  <span className="add-tax">Assign User</span>
                  <span onClick={() => this.closeDrawer()} className="tax-close"><img src={close} alt="close" /></span>
                </div>
                <div className="tax-content-field">
                <div>
                  <p className="drawer_title">Select the Role</p>
                  <Select
                    className="roles"
                    placeholder="Please Select the Role"
                    labelInValue
                    value={{
                      value: role_id,
                      label: value,
                    }}
                    style={{
                      width: "100%",
                    }}
                    onSelect={(event) => this.SelectHandleChange(event)}
                    options={RoleList.map((role) => ({
                      value: role.rolemanagement_id,
                      label: role.name,
                    }))}
                  />
                </div>
            
              <div style={{ marginTop: "24px" }}>
                {" "}
                <p className="drawer_title">Select Employees</p>
                <Rselect
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Please Select Employees"
                  value={
                    employees_list.length > 0 &&
                    this.state.employees_list.map((users) => {
                      return {
                        value: users.value,
                        label: users.label,
                      };
                    })
                  }
                  onChange={(e, value) => {
                    this.SelectChange(e, value);
                  }}
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={employees}
                ></Rselect>
              </div>
              <div className="roles d-flex justify-content-end align-items-center mt-5">
                <button
                  className="secondary_color"
                  onClick={() => this.closeDrawer()}
                >
                  Back
                </button>

                <button
                  onClick={() => this.addEmployeeDetail()}
                  className="primary_color"
                >
                  Submit
                </button>
              </div>
              </div>
            </div>
          </Drawer>
      
        <Modal
          className="role_modal"
          title=""
          centered
          width={460}
          open={this.state.modalVisible}
          onOk={() => this.setState({ modalVisible: false })}
          onCancel={() => this.setState({ modalVisible: false })}
          footer={[
            <div className="d-flex align-items-center">
              <button
                key="cancel"
                className="dec-btn-secondary"
                onClick={() => this.setState({ modalVisible: false })}
              >
                Cancel
              </button>
              <button
                key="submit"
                className="primary_color"
                onClick={() => this.submitHandle()}
              >
                Submit
              </button>
            </div>
          ]}
        >
          <div>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: "#333333",
              }}
            >
              Remove User
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#757575",
                lineHeight: "24px",
                marginTop: "10px",
              }}
            >{`Are you sure you want to remove ${this.state.emp_drawer.name}?`}</p>
          </div>
        </Modal>
        <ModalPopUp
          visible={this.state.deleteModalVisible}
          cancel={() => this.setState({ deleteModalVisible: false })}
          submit={this.deleteSubmitHandle}
          name='Role'
          delete_name={this.state.delete_name}
        />

      </div>
    );
  }
}
