import { Access } from "../../constants/roleStore";

export const MODULENAME = {
  Dashboard: "Dashboard",
  Employees: "Employees",
  Hiring: "Hiring",
  Leaves: "Leaves",
  Permissions: "Permissions",
  Invoices: "Invoices",
  Taxes: "Taxes",
  Tasks:"Tasks",
  Expenses: "Expenses",
  Salaries: "Salaries",
  EmployeeExpenses: "Employee Expenses",
  Projects: "Projects",
  Leads: "Leads",
  Clients: "Clients",
  Platforms: "Platforms",
  Technologies: "Technologies",
  ResourceManagement: "Resource Management",
  Jobs: "Jobs",
  Accessories: "Accessories",
  RoleManagement: "Role Management",
  Careers: "Careers",
  Queries: "Queries",
  Holidays: "Holidays",
  Calculator: "Calculator",
  Roles: "Roles",
  Vendors: "Vendors",
};

export const PERMISSION={
    create: "create",
    approval: "approval",
    update: "update",
    deleted: "delete"
}
const {deleted,create,approval,update} =PERMISSION;

const {
  Dashboard,
  Employees,
  Hiring,
  Leaves,
  Permissions,
  Invoices,
  Taxes,
  Expenses,
  Salaries,
  EmployeeExpenses,
  Projects,
  Leads,
  Clients,
  Platforms,
  Technologies,
  ResourceManagement,
  Jobs,
  Accessories,
  RoleManagement,
  Careers,
  Queries,
  Holidays,
  Calculator,
  Roles,
  Vendors,
} = MODULENAME;

export const DASHBOARDACCESS = {
  create: Access(Dashboard, create),
  approval: Access(Dashboard, approval),
  update: Access(Dashboard, update),
  delete: Access(Dashboard, deleted),
};

export const EMPLOYEESACCESS = {
  create: Access(Employees, create),
  approval: Access(Employees, approval),
  update: Access(Employees, update),
  delete: Access(Employees, deleted),
};

export const HIRINGACCESS = {
  create: Access(Hiring, create),
  approval: Access(Hiring, approval),
  update: Access(Hiring, update),
  delete: Access(Hiring, deleted),
};

export const LEAVEACCESS = {
  create: Access(Leaves, create),
  approval: Access(Leaves, approval),
  update: Access(Leaves, update),
  delete: Access(Leaves, deleted),
};

export const PERMISSIONACCESS = {
  create: Access(Permissions, create),
  approval: Access(Permissions, approval),
  update: Access(Permissions, update),
  delete: Access(Permissions, deleted),
};

export const INVOICEACCESS = {
  create: Access(Invoices, create),
  approval: Access(Invoices, approval),
  update: Access(Invoices, update),
  delete: Access(Invoices, deleted),
};

export const TAXESACCESS = {
  create: Access(Taxes, create),
  approval: Access(Taxes, approval),
  update: Access(Taxes, update),
  delete: Access(Taxes, deleted),
};

export const EXPENSESACCESS = {
  create: Access(Expenses, create),
  approval: Access(Expenses, approval),
  update: Access(Expenses, update),
  delete: Access(Expenses, deleted),
};

export const SALARIESACCESS = {
  create: Access(Salaries, create),
  approval: Access(Salaries, approval),
  update: Access(Salaries, update),
  delete: Access(Salaries, deleted),
};

export const EMPLOYEEEXPENSESACCESS = {
  create: Access(EmployeeExpenses, create),
  approval: Access(EmployeeExpenses, approval),
  update: Access(EmployeeExpenses, update),
  delete: Access(EmployeeExpenses, deleted),
};

export const PROJECTACCESS = {
  create: Access(Projects, create),
  approval: Access(Projects, approval),
  update: Access(Projects, update),
  delete: Access(Projects, deleted),
};

export const LEADSACCESS = {
  create: Access(Leads, create),
  approval: Access(Leads, approval),
  update: Access(Leads, update),
  delete: Access(Leads, deleted),
};

export const CLIENTSACCESS = {
  create: Access(Clients, create),
  approval: Access(Clients, approval),
  update: Access(Clients, update),
  delete: Access(Clients, deleted),
};

export const PLATFORMSACCESS = {
  create: Access(Platforms, create),
  approval: Access(Platforms, approval),
  update: Access(Platforms, update),
  delete: Access(Platforms, deleted),
};

export const TECHNOLOGIESACCESS = {
  create: Access(Technologies, create),
  approval: Access(Technologies, approval),
  update: Access(Technologies, update),
  delete: Access(Technologies, deleted),
};

export const RESOURCEACCESS = {
  create: Access(ResourceManagement, create),
  approval: Access(ResourceManagement, approval),
  update: Access(ResourceManagement, update),
  delete: Access(ResourceManagement, deleted),
};

export const JOBACCESS = {
  create: Access(Jobs, create),
  approval: Access(Jobs, approval),
  update: Access(Jobs, update),
  delete: Access(Jobs, deleted),
};

export const ACCESSORIESACCESS = {
  create: Access(Accessories, create),
  approval: Access(Accessories, approval),
  update: Access(Accessories, update),
  delete: Access(Accessories, deleted),
};

export const ROLEMANAGEMENTACCESS = {
  create: Access(RoleManagement, create),
  approval: Access(RoleManagement, approval),
  update: Access(RoleManagement, update),
  delete: Access(RoleManagement, deleted),
};

export const CAREERACCESS = {
  create: Access(Careers, create),
  approval: Access(Careers, approval),
  update: Access(Careers, update),
  delete: Access(Careers, deleted),
};

export const QUERIESACCESS = {
  create: Access(Queries, create),
  approval: Access(Queries, approval),
  update: Access(Queries, update),
  delete: Access(Queries, deleted),
};

export const HOLIDAYACCESS = {
  create: Access(Holidays, create),
  approval: Access(Holidays, approval),
  update: Access(Holidays, update),
  delete: Access(Holidays, deleted),
};

export const CALCULATORACCESS = {
  create: Access(Calculator, create),
  approval: Access(Calculator, approval),
  update: Access(Calculator, update),
  delete: Access(Calculator, deleted),
};

export const ROLESACCESS = {
  create: Access(Roles, create),
  approval: Access(Roles, approval),
  update: Access(Roles, update),
  delete: Access(Roles, deleted),
};

export const VENDORSACCESS = {
  create: Access(Vendors, create),
  approval: Access(Vendors, approval),
  update: Access(Vendors, update),
  delete: Access(Vendors, deleted),
};

