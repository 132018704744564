import React, { Component } from "react";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { Button, Table, Divider } from "antd";
import "../../stylesheets/styles.css";
import {
  returnFirstDegreeObjValue,
  utcdate,
  numberWithComma,
  showDate,
  isErrorTxt
} from "../../constants/Utils";
import TopHeader from "../../components/ui/TopHeader";

export default class DetailsExpenses extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      expenses_id: this.props.match.params.id,
      data: [],
      specification: [],
      currentPage: 1,
    };
  }

  back = () => {
    this.props.history.goBack();
  };

  componentDidMount = () => {
    this.handleTable();
  };
  handleTable = () => {
    let self = this;
    let id = self.state.expenses_id;
    APIGATEWAY.get( "expenses/" + id, ( response ) => {
      if ( response.success ) {
        let res = response.data;
        this.setState( {
          data: res.expense,
          specification: res.expense.item_of_expenses,
        } );
      }
    } );
  };

  Paid = () => {
    let self = this;
    let id = self.state.expenses_id;
    let data = {};
    APIGATEWAY.patch( "expenses/status/" + id, data, ( response ) => {
      if ( response.success ) {
        this.handleTable( id );
      }
    } );
  };
 
  render () {
    let self = this;
    let column = [
      {
        title: "S.No",
        key: "index",
        render: ( value, item, index ) =>
          ( this.state.currentPage - 1 ) * 5 + ( index + 1 ),
      },

      {
        title: "Type Name ",
        dataIndex: "item",
        key: "item",
      },
      {
        title: "Count ",
        dataIndex: "count",
        key: "count",
      },
      {
        title: "Amount ",
        dataIndex: "price",
        key: "price",

      },
    ];
    const { data, specification } = self.state;

    return (
      <div>
        <TopHeader
          title="Expense Details"
          statusName="Paid"
          updateStatus={this.Paid}
          backClick1={this.back}
        />
        <div className="main-content">
          <div style={{ marginBottom: 30 }}>
            <div className="details-content">
              <div className="name-wrappers">
                <div className="title-wrapper font-500">Purchase Date</div>
                <div className="data-content">
                  {isErrorTxt( data?.purchase_date ) ? "-" : showDate( data?.purchase_date )}
                </div>
              </div>
              
             
              <div className="name-wrappers">
                <div className="title-wrapper font-500">Status</div>
                <div className="data-content text-capitalize">
                  {returnFirstDegreeObjValue( data, "status" )}
                </div>
              </div>
            </div>

            <div className="details-content">
              <div className="name-wrappers">
                <div className="title-wrapper font-500">Total Amount</div>
                <div className="data-content">
                  {numberWithComma( data?.total_amount ?? 0 )}
                </div>
              </div>
            </div>
          </div>
          <Divider orientation="left">Items Of Expense</Divider>
          <div>
            <Table
              columns={column}
              dataSource={specification}
              pagination={false}
              style={{ textTransform: "capitalize" }}
            />
          </div>
        </div>
      </div>
    );
  }
}
