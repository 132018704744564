import React, { Component } from "react";
import "../../stylesheets/styles.css";
import {
  Input,
  Select,
  Button,
  Divider,
  Card,
  Tag,
  message,
  Checkbox,
  Spin
} from "antd";

import { TweenOneGroup } from "rc-tween-one";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import TopHeader from "../../components/ui/TopHeader";
import InputItem from '../../components/ui/InputItem';
import SelectItem from '../../components/ui/SelectItem';

//Utils
import {
  isInvalidName,
  isInvalidEmail,
  showError,
  checkNameLength,
  isErrorTxt,
  checkDigitLength,
  checkTxtLength,
} from "../../constants/Utils";

const { Option } = Select;
export default class AddClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",

      address: "",
      billing_address: "",

      reasonType: "",
      customer_type: "",

      currencyType: "",
      clientid: "",
      emailList: [""],
      alternative_email: "",

      referrer_name: "",
      referrer_about: "",
      tags: [],
      inputVisible: false,
      inputValue: "",
      id: this.props.match.params.id,
      loading: false,
      updateList: [],
      labelList: [],
      search: "",
      list: [],
      searchTerm: "",
      currentPage: 1,
      pageSize: 10,
      clients: [],
      searchList: [],
      client_id: "",
      client_name: "",
      clientname: "",
      first_name: "",
      last_name: "",
      company_name: "",
      email: "",
      phone: "",
      currency: "",
      business: "business",
      indivitual: "",
      business_check: true,
      indivitual_check: false,
      bill_address_line1: "",
      bill_address_line2: "",
      bill_address_country: "",
      bill_address_state: "",
      bill_address_city: "",
      bill_address_zipcode: "",
      ship_address_line1: "",
      ship_address_line2: "",
      ship_address_country: "",
      ship_address_countrys: [],
      tin_number: "",
      ship_address_state: "",
      ship_address_city: "",
      ship_address_zipcode: "",
      check_same_address: true,
      loader:true,
      customer_type_data:[{
        value:'Business',key:'business'

      },
      {
        value:'Indivitual',key:'indivitual'

      }
    ],
    currency_data:[
      {
        value:'INR',key:'inr'
      },
      {
        value:'USD',key:'usd'
      },
      {
        value:'EUR',key:'eur'
      },
      {
        value:'CAD',key:'cad'
      },
      {
        value: 'AED', key: 'aed'
      },
    ]
    ,
      add_edit_loader: false,
    };
  }
  componentDidMount() {
    let { id } = this.state;
    if (id) {
      this.updateClient(id);
    }
    // this.callAPI();
    // this.updateClient();
  }

  // Client update and submit Validations
  onSubmit = () => {
    var self = this;
    let {
      first_name,
      last_name,
      company_name,
      currencyType,
      customer_type,
      phone,
      email,

      referrer_about,
      referrer_name,
      client_id,
      bill_address_line1,
      bill_address_line2,
      bill_address_country,
      bill_address_state,
      bill_address_city,
      bill_address_zipcode,
      ship_address_line1,
      ship_address_line2,
      ship_address_country,
      ship_address_state,
      ship_address_city,
      ship_address_zipcode,
      check_same_address,
      tin_number,
      tags,
    } = this.state;

    let phone_regex = /^([0-9]{10,15})+$/;
    if (isErrorTxt(first_name)) {
      showError("Please enter first name");
    } else if (checkNameLength(first_name)) {
      showError("First Name should contain atleast 3 characters");
    } else if (isErrorTxt(customer_type)) {
      showError("Please Select customer type");
    } else if (isErrorTxt(email) || isInvalidEmail(email)) {
      showError("Please enter valid email");
    } else if (isErrorTxt(phone)) {
      showError("Please enter phone number");
    } else if (checkTxtLength(phone, 10)) {
      showError("Phone Number should contain atleast 10 digits");
    } else if (isErrorTxt(currencyType)) {
      showError("Please select the currency type");
    }else {
      let billing = {
        address: {
          address1: bill_address_line1,
          address2: bill_address_line2,
        },
        country: bill_address_country,
        state: bill_address_state,
        city: bill_address_city,
        zip_code: bill_address_zipcode,
      };

      let shipping = {
        address: {
          address1: ship_address_line1,
          address2: ship_address_line2,
        },
        country: ship_address_country,
        state: ship_address_state,
        city: ship_address_city,
        zip_code: ship_address_zipcode,
      };

      let data = {
        name: {
          first_name: first_name,
          last_name: last_name, 
        },
        email: email,
        phone: {
          country_code: "91",
          national_number: phone,
        },
        currency: currencyType,
        billing_address: billing,
        alternative_email: tags,
        shiping_address: check_same_address ? billing : shipping,
        customer_type: customer_type,
        is_same_billing: check_same_address,
        company_name: company_name,
        gst_num: tin_number,
      };

      if (this.state.reasonType === "existing") {
        data.referred = { client_id: client_id };
      }
      if (this.state.reasonType === "Others") {
        data.referred = { name: referrer_name, about: referrer_about };
      }
      this.setState({ add_edit_loader: true });
      if (this.state.id) {
        let username = this.state.id;
        APIGATEWAY.patch("clients/" + username, data, (response) => {
          if (response.success) {
            this.setState({ add_edit_loader: false });
            message.success(response.message);
            this.props.history.push("/clients");
          } else {
            message.error(response.message);
          }
        });
      } else {
        APIGATEWAY.post("clients/", data, (response)=> {
          if (response.success) {
            this.setState({ add_edit_loader: false });
            message.success(response.message);
            this.props.history.push("/clients");
          } else {
            message.error(response.message);
          }
        });
      }
    }
  };
  //Client-edit api call
  updateClient = (id) => {
    let username = this.state.id;
    APIGATEWAY.get("clients/" + username, (response) => {
      if (response.success) {
        let data = response?.data?.client;
        let billing = data?.billing_address;
        let shiping = data?.shiping_address;
        let billing_add = billing?.address;
        let shiping_add = shiping?.address;

        this.setState({
          loader:false,
          email: data?.email,
          phone: data?.phone?.national_number,
          tags: data?.alternative_email,
          currencyType: data?.currency ?? "",
          first_name: data?.name?.first_name ?? "",
          last_name: data?.name?.last_name ?? "",
          company_name: data?.company_name ?? "",
          tin_number: data?.gst_num ?? "",
          customer_type: data?.customer_type ?? "",
          bill_address_line1: billing_add?.address1 ?? "",
          bill_address_line2: billing_add?.address2 ?? "",
          bill_address_country: billing?.country ?? "",
          bill_address_state: billing?.state ?? "",
          bill_address_city: billing?.city ?? "",
          bill_address_zipcode: billing?.zip_code ?? "",
          ship_address_line1: shiping_add?.address1 ?? "",
          ship_address_line2: shiping_add?.address2 ?? "",
          ship_address_country: shiping?.country ?? "",
          ship_address_state: shiping?.state ?? "",
          ship_address_city: shiping?.city ?? "",
          ship_address_zipcode: shiping?.zip_code ?? "",
          check_same_address: data?.is_same_billing,
        });
      }
    });
  };
  //existing client api call
  callAPI = () => {
    let { search } = this.state;
    var queryParams = "";
    if (search !== "") {
      queryParams = "?search_term=" + search;
    }
    APIGATEWAY.get("clients/exists/list" + queryParams, (response) => {
      if (response.success) {
        this.setState({ list: response.data.exists_clients });
      }
    });
  };

  //Alternative Email
  handleOnInputChange = (event) => {
    // var letters = /^[A-Za-z]+$/;
    var regex = new RegExp("^[a-zA-Z s]+$");
    if (event.target.value === "" || event.target.value.match(regex)) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };
  customerTypeChnage = (e) => {
    this.setState({ customer_type: e });
    if (e === "indivitual") {
      this.setState({ company_name: "" });
    }
  };
  checkSameAddress = (e) => {

    this.setState({
      check_same_address: e.target.checked,
    });
    if(e.target.checked === false){
          this.setState({
          ship_address_line1: "",
          ship_address_line2: "",
          ship_address_country: "",
          ship_address_state: "",
          ship_address_city: "",
          ship_address_zipcode:  "",
      })
    }
    
  };
  EmailChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleOnAddressChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleOnTinChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };
  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);
    this.setState({ tags });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };
  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };
  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (isInvalidEmail(inputValue)) {
      showError("Enter valid alternate email id");
    } else if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    this.setState({
      tags,
      inputVisible: false,
      inputValue: "",
    });
  };
  saveInputRef = (input) => {
    this.input = input;
  };
  forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          this.handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  //number-validation function
  handleOnNumberInputChange = (event) => {
    let REGEX = /^\d+$/;
    if (event.target.value === "" || REGEX.test(event.target.value))
      this.setState({ [event.target.name]: event.target.value });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.updateClient();
    });
  };

  clientID = (id) => {
    this.setState({ client_id: id });
  };
  handleClick = () => {
    this.props.history.push("/clients");
  };
  customerType = (type) => {
    if (type === "business") {
      this.setState({
        business: type,
        business_check: true,
        indivitual: "",
        indivitual_check: false,
      });
    } else {
      this.setState({
        business: "",
        business_check: false,
        indivitual: "indivitual",
        indivitual_check: true,
      });
    }
  };
  render() {
    let {
      reasonType,
      

      list,
    } = this.state;

    const {
      tags,
      inputVisible,
      inputValue,
      business,
      indivitual,
      business_check,
      indivitual_check,
      currencyType,
      phone,
      bill_address_line1,
      bill_address_line2,
      bill_address_country,
      bill_address_state,
      bill_address_city,
      bill_address_zipcode,
      ship_address_line1,
      ship_address_line2,
      ship_address_country,
      ship_address_state,
      ship_address_city,
      ship_address_zipcode,
      check_same_address,
      ship_address_countrys,
      tin_number,
      customer_type_data,
      customer_type,
      currency_data,id } = this.state;
   
    const tagChild = tags.map(this.forMap);
    return (
      <div>
        <TopHeader
          title="Clients"
          submit={this.onSubmit}
          id={id}
          backClick={this.handleClick}
          spin={this.state.add_edit_loader}
        />
        {id && this.state.loader ? (
          <div className="tableview">
          <Spin />
        </div>
        ):(
          <div className="main-content2" style={{ marginBottom: "100px" }}>
            <Divider orientation="left">Basic Details</Divider>
          <div className="sm__grid--col-4">
            <div className="field-holder">
            <InputItem label='First Name' name='first_name' value={this.state.first_name} star={true}  placeholder="Enter the First Name" onChange={this.handleOnInputChange} />
            </div>

            <div className="field-holder">
            <InputItem label='Last Name' name='last_name' value={this.state.last_name} star={false} placeholder="Enter the Last Name" onChange={this.handleOnInputChange} />

            </div>

            <div className="field-holder">
            <SelectItem label='Customer Type ' star={true} value={customer_type} placeholder="Select by" data={customer_type_data}  onChange={(e) => this.customerTypeChnage(e)} />

            </div>
          </div>
          <div className="sm__grid--col-4">
            <div className="field-holder">
            <InputItem label='Email' name='email' value={this.state.email} star={true} placeholder="Enter the Email" onChange={(e) => this.EmailChange(e)} />
            
            </div>
            <div className="field-holder">
            <InputItem label=' Phone Number' name='phone' value={this.state.phone} star={true} placeholder="Enter the Phone Number"  onChange={(e) => this.handleOnNumberInputChange(e)} />

            </div>
            <div className="field-holder">
            <SelectItem label='Currency' star={true} value={currencyType} placeholder="Select by" data={currency_data} onChange={(value) => this.setState({ currencyType: value })} />
              
            </div>
          </div>

          <div className="sm__grid--col">
            <div className="field-holder">
            <InputItem label='TIN (Tax Identification Number)' name='tin_number' value={this.state.tin_number} star={false} placeholder="Enter the TAX number"  onChange={(e) => this.handleOnTinChange(e)} />

            </div>
            {customer_type === "business" && (
              <div className="field-holder">
              <InputItem label='Company Name' name='company_name' value={this.state.company_name} star={true} placeholder="Enter the Company Name"  onChange={this.handleOnInputChange} />
                
              </div>
            )}
          </div>

          <div>
            <Divider orientation="left">Billing Address</Divider>
            <div className="sm__grid--col-4">
              <div className="field-holder">
              <InputItem label='Address Line 1' name='bill_address_line1' value={bill_address_line1} star={false} placeholder="Enter the address line 1"  onChange={(e) => this.handleOnAddressChange(e)} />
                
              </div>
              <div className="field-holder">
              <InputItem label='Address Line 2' name='bill_address_line2' value={bill_address_line2} star={false} placeholder="Enter the address line 2"  onChange={(e) => this.handleOnAddressChange(e)} />
                
              </div>
              <div className="field-holder">
              <InputItem label='Country' name='bill_address_country' value={bill_address_country} star={false} placeholder="Enter the country name"  onChange={(e) => this.handleOnInputChange(e)} />
                
              </div>
            </div>
            <div className="sm__grid--col-4">
              <div className="field-holder">
              <InputItem label='Province/State' name='bill_address_state' value={bill_address_state} star={false} placeholder="Enter the state name"  onChange={(e) => this.handleOnInputChange(e)} />
                
              </div>
              <div className="field-holder">
              <InputItem label='City' name='bill_address_city' value={bill_address_city} star={false} placeholder="Enter the city name"  onChange={(e) => this.handleOnInputChange(e)} />
                
              </div>
              <div className="field-holder">
              <InputItem label='Postal/Zip Code' name='bill_address_zipcode' value={bill_address_zipcode} star={false} placeholder="Enter the zip code"  onChange={(e) => this.handleOnNumberInputChange(e)} />
                
              </div>
            </div>
          </div>
          <div>
            <Divider orientation="left">Shipping Address</Divider>
            <div>
              <Checkbox
                checked={check_same_address}
                onChange={(e) => this.checkSameAddress(e)}
                className="mr-2 mb-4"
              />{" "}
              Same as Billing Address
            </div>
            {!check_same_address && (
              <div>
                <div className="sm__grid--col-4">
                  <div className="field-holder">
                  <InputItem label='Address Line 1' name='ship_address_line1' value={ship_address_line1} star={false} placeholder="Enter the shipping address line 1"  onChange={(e) => this.handleOnAddressChange(e)} />
                    
                  </div>
                  <div className="field-holder">
                  <InputItem label='Address Line 2' name='ship_address_line2' value={ship_address_line2} star={false} placeholder="Enter the shipping address line 2"  onChange={(e) => this.handleOnAddressChange(e)} />
                    
                  </div>
                  <div className="field-holder">
                  <InputItem label='Country' name='ship_address_country' value={ship_address_country} star={false} placeholder="Enter the country name"  onChange={this.handleOnInputChange} />
                   
                  </div>
                </div>
                <div className="sm__grid--col-4">
                  <div className="field-holder">
                  <InputItem label='Province/State' name='ship_address_state' value={ship_address_state} star={false} placeholder="Enter the state name"  onChange={this.handleOnInputChange} />
                    
                  </div>
                  <div className="field-holder">
                  <InputItem label='City' name='ship_address_city' value={ship_address_city} star={false} placeholder="Enter the city name"  onChange={this.handleOnInputChange} />
                    
                  </div>
                  <div className="field-holder">
                  <InputItem label='Postal/Zip Code' name='ship_address_zipcode' value={ship_address_zipcode} star={false} placeholder="Enter the zipcode"  onChange={(e) => this.handleOnNumberInputChange(e)} />
                   
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            <Divider orientation="left"> Alternative Emails</Divider>

            <div className="sm__grid--col-4 mt-4" style={{ display: "flex" }}>
              {!inputVisible && (
                <div style={{ display: "flex" }}>
                  <Tag onClick={this.showInput} className="site-tag-plus">
                    <i className="fa fa-plus add-icon"></i>
                    <span>Add Email</span>
                  </Tag>
                </div>
              )}
              {inputVisible && (
                <Input
                  ref={this.saveInputRef}
                  type="text"
                  size="small"
                  className="client-field"
                  value={inputValue}
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputConfirm}
                  onPressEnter={this.handleInputConfirm}
                />
              )}
              <div>
                <TweenOneGroup
                  enter={{
                    onComplete: (e) => {
                      e.target.style = "";
                    },
                  }}
                  appear={false}
                  className="tag-client"
                >
                  {tagChild}
                </TweenOneGroup>
              </div>
            </div>
          </div>
        </div>
        )}
        
      </div>
    );
  }
}
