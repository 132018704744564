import React, { Component } from "react";
import { Link } from "react-router-dom";
import TableField from "../../components/ui/tableField";
import TopHeader from "../../components/ui/TopHeader";
import Filter from "../../components/ui/filter";
import search_a from "../../assets/search.svg";
import layer from "../../assets/Layer 2.svg";
import { STORAGE, UPDATE, APPROVAL, DELETE } from "../../constants/roleStore";
import ModalPopUp from "../../components/ui/modalpop";

import close from "../../assets/emp_close.svg";
import percentage from "../../assets/percent.svg";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { Spinner } from "reactstrap";
import { MoreOutlined } from "@ant-design/icons";
import {
  showDate,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
} from "../../constants/Utils";

import {
  Form,
  Input,
  Button,
  Table,
  Modal,
  DatePicker,
  Spin,
  Dropdown,
  Menu,
  Drawer,
  Space,
  message,
  Switch,
  Empty,
  Popover,
} from "antd";
import { showError, checkNameLength, isErrorTxt } from "../../constants/Utils";
import { TAXESACCESS } from "../../components/ui/constants";
export default class Taxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 20,
      openDrawer: false,
      tax_name: "",
      tax_percentage: 0,
      description: "",
      tax_id: "",
      data: "",
      total: "",
      enable: true,
      deleteTax: false,
      search_tax_name: "",
      queryStatus: {},
      total: "",
      totalDoc: "",
      Tableloading: false,
      loading: true,
      checkTax: false,
      add_edit_spin: false,
      app_name: "",
      app_type: "",
      dropdown_menu: false,
      list_index: "",
      deleted: false,
      update: false,
      approval: false,
    };
    this.wrapperRef = React.createRef();
  }
  componentDidMount() {
    // this.taxTable();
    let name = "";

    let storage = JSON.parse(STORAGE.view);
    name = storage?.filter((app) => app?.name === "My Payments");
    let value = name;
    value?.filter((app) => {
      let names = app?.sub_roles?.filter((role) => role.name === "Taxes");
      names &&
        names.map((el) =>
          this.setState({
            update: el?.permissions?.update,
            deleted: el?.permissions?.delete,
            approval: el?.permissions?.approval,
          })
        );

      this.setState({
        app_name: names,
      });
    });

    this.setState({
      app_type: STORAGE.type,
    });
    this.setQueryStatus();
    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);

    this.reloadPage();
  }
  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.clearSearch();
        }
      });
    });
  };

  onPopstate = () => {
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);

    this.state.search_tax_name = searchObj.name ?? "";
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";

    this.state.queryStatus = returnSearchObject(search);

    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      this.taxTable();
    });
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);
  }
  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };
  taxTable = () => {
    APIGATEWAY.get(
      "tax" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          let data = response.data;
          this.setState({
            data: data.taxes,
            total: data?.taxes?.length,
            totalDoc: data.total,
            loading: false,
          });
        }
      }
    );
  };
  addTax = () => {
    this.setState({
      openDrawer: true,
    });
  };
  closeDrawer = () => {
    this.setState({
      openDrawer: false,
      tax_percentage: 0,
      tax_name: "",
      description: "",
      tax_id: "",
    });
  };
  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    querysearch.name = this.state.search_tax_name ?? "";
    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";

    return querysearch;
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          search_tax_name: queryObj.name ?? "",
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",

          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.taxTable();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.taxTable();
        }
      );
    }
  };
  handleFilter = () => {
    let query = this.getQuerySearch();

    this.setState(
      {
        queryStatus: query,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.taxTable();
      }
    );
  };

  handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    var regex = new RegExp("^[a-zA-Z s]+$");
    if (value.length <= 30) {
      if (value === "" || value.match(regex)) {
        this.setState({
          [name]: value.toUpperCase(),
        });
      }
    }
  };

  handleDesChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    if (value.length <= 50) {
      this.setState({
        [name]: value,
      });
    }
  };

  changePercentage = (event) => {
    let REGEX1 = /^\d+$/;
    // let REGEX = (/^[0-9]{1,2}([,.][0-9]{1,2})?$/)
    // var lastdigits = amount.substring(amount.indexOf(".") + 1, amount.length);
    let REGEX = /^([0-9.])+$/;
    let value = event.target.value;
    let name = event.target.name;

    if (value <= 100) {
      if (value === "" || REGEX.test(value)) this.setState({ [name]: value });
    }
  };

  AddEdittax = () => {
    const { openDrawer, tax_id, tax_percentage, tax_name, description } =
      this.state;
    if (isErrorTxt(tax_name)) {
      showError("Please enter the name");
    } else if (checkNameLength(tax_name)) {
      showError("Name should contain atleast 3 characters");
    } else if (isErrorTxt(tax_percentage)) {
      showError("Please enter the percentage");
    } else {
      let data = {
        name: tax_name,
        percentage: tax_percentage,
        description: description,
      };

      this.setState({ add_edit_spin: true });
      if (tax_id) {
        APIGATEWAY.patch("tax/" + tax_id, data, (response) => {
          if (response.success) {
            message.success(response.message);
            this.setState({
              openDrawer: false,
              tax_percentage: 0,
              tax_name: "",
              description: "",
              tax_id: "",
              add_edit_spin: false,
            });
            this.taxTable();
          } else {
            message.error(response.message);
          }
        });
      } else {
        APIGATEWAY.post("tax", data, (response) => {
          if (response.success) {
            message.success(response.message);
            this.setState({
              openDrawer: false,
              tax_percentage: 0,
              tax_name: "",
              description: "",
              add_edit_spin: false,
            });
            this.taxTable();
          } else {
            message.error(response.message);
          }
        });
      }
    }
  };

  handleActions = (e, object) => {
    if (e === "edit") {
      this.setState({
        openDrawer: true,
        tax_name: object.name,
        tax_percentage: object.percentage,
        description: object.description,
        tax_id: object.tax_id,
      });
    } else {
      this.setState({
        deleteTax: true,
        delete_name: object?.name ?? "- ",
        tax_id: object.tax_id,
      });
    }
  };

  taxEnableChange = (data) => {
    this.setState({
      checkTax: !data.enable,
    });
    data.enable = !data.enable;

    let value = {
      enable: data.enable,
    };
    if (data !== "" || data !== undefined || data !== null) {
      APIGATEWAY.patch("tax/" + data.tax_id, value, (response) => {
        if (response.success) {
          message.success(response.message);

          this.taxTable();
        } else {
          message.error(response.message);
        }
      });
    }
  };
  cancelDelete = () => {
    this.setState({ deleteTax: false, delete_name: "", tax_id: "" });
  };
  submitDelete = () => {
    let self = this;
    let data = {};
    APIGATEWAY.delete("tax/delete/" + this.state.tax_id, data, (response) => {
      if (response.success) {
        message.success(response.message);
        this.taxTable();
        this.setState({ deleteTax: false, delete_name: "", tax_id: "" });
      }
    });
  };

  searchTaxName = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  clearSearch = () => {
    this.setState(
      {
        search_tax_name: "",
        currentPage: 1,
        queryStatus: { page: 1, limit: 20 },
        pageSize: 20,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);

        this.taxTable();
      }
    );
  };
  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  next = (prev) => {
    const { currentPage } = this.state;

    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };
  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      list_index: index,
    });
  };
  render() {
    const {
      openDrawer,
      tax_id,
      tax_percentage,
      tax_name,
      description,
      data,
      search_tax_name,
      total,
      currentPage,
      pageSize,
      add_edit_spin,
      app_name,
      app_type,
      update,
      deleted,
      approval,
    } = this.state;
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }
    const menu = (data) => (
      <Menu
        className="dropdown-menu-bar"
        onClick={(e) => this.handleActions(e, data)}
      >
        <Menu.Item key="edit">Edit</Menu.Item>
        <Menu.Item key="delete">Delete</Menu.Item>
      </Menu>
    );

    return (
      <div>
        <div>
          <TopHeader
            title="Taxes"
            tax={this.addTax}
            type={app_type}
            permission={app_name}
          />

          <div className="main-content">
            <Filter
              taxFieldname="search_tax_name"
              taxFieldvalue={search_tax_name}
              img_name={search_a}
              taxOnchange={this.searchTaxName}
              taxClear={() => this.clearSearch()}
            />
            {this.state.loading ? (
              <div className="spinning-star">
                <Spin size="large" />
              </div>
            ) : (
              <div className="table-main mt-4">
                <div className="table_page_wrap">
                  {data.length > 0 ? (
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th className="">S.No</th>
                          <th className="">Name</th>
                          <th className="">Percentage</th>
                          <th className="">Description</th>
                          <th></th>
                        </tr>
                      </thead>

                      {data.length > 0 ? (
                        <tbody ref={this.wrapperRef}>
                          {data.map((tax, t) => (
                            <tr key={t}>
                              <td className="">
                                {" "}
                                {(this.state.currentPage - 1) * 10 + (t + 1)}
                              </td>
                              <td className="table-name">{tax.name}</td>
                              <td className="">{tax.percentage} %</td>
                              <td className="">
                                <div style={{ cursor: "pointer" }}>
                                  {tax.description?.length >= 14 ? (
                                    <Popover
                                      overlayStyle={{
                                        maxWidth: "20vw",
                                      }}
                                      content={tax.description}
                                    >
                                      {tax.description.slice(0, 40) + "..."}
                                    </Popover>
                                  ) : tax.description !== "" ? (
                                    tax.description
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </td>

                              <td className="table-action">
                                <div className="tax-add-edit">
                                  {TAXESACCESS.approval && (
                                    <span className="mr-4">
                                      <label className="switch-tax">
                                        <input
                                          type="checkbox"
                                          checked={tax.enable}
                                          value={this.state.checkTax}
                                          onChange={() =>
                                            this.taxEnableChange(tax)
                                          }
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </span>
                                  )}
                                  {(TAXESACCESS.update ||
                                    TAXESACCESS.delete) && (
                                    <span
                                      className="ml-5"
                                      onClick={() => this.dropdownClick(t)}
                                    >
                                      <div className="dropdown-head">
                                        <div className="dropbtn-head">
                                          <img
                                            src={layer}
                                            alt="profile"
                                            className="menu-bar-icon"
                                          />
                                        </div>
                                        {this.state.list_index === t &&
                                          this.state.dropdown_menu && (
                                            <div className="dropdown-content-heads">
                                              <div>
                                                {TAXESACCESS.update && (
                                                  <p
                                                    className="action-menu-bar"
                                                    onClick={() =>
                                                      this.handleActions(
                                                        "edit",
                                                        tax
                                                      )
                                                    }
                                                  >
                                                    Edit
                                                  </p>
                                                )}

                                                {TAXESACCESS.delete && (
                                                  <p
                                                    className="action-menu-bar"
                                                    onClick={() =>
                                                      this.handleActions(
                                                        "delete",
                                                        tax
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </span>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        ""
                      )}
                    </table>
                  ) : (
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th className="">S.No</th>
                          <th className="">Name</th>
                          <th className="">Percentage</th>
                          <th className="">Description</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody className="empty-message">
                        <tr>
                          <td colSpan="5">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {data.length > 0 && (
                    <TableField
                      // column={columns}
                      data={data}
                      countprev={countprev}
                      countnext={countnext}
                      currentPage={currentPage}
                      ClickPrev={() => this.previous()}
                      ClickNext={() => this.next(this.state.currentPage)}
                      total={total}
                      overAll={this.state.totalDoc ?? 0}
                      pagelimit={this.state.pageSize}
                      pagelimitHandle={this.pageLimitOnchange}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <Space>
            <Drawer
              placement="right"
              open={openDrawer}
              onClose={() => this.closeDrawer()}
              className="tax-main-drawer"
            >
              <div className="tax-drawer">
                <div className="drawer-head">
                  <span className="add-tax">
                    {tax_id ? "Update Tax" : "Add Tax"}
                  </span>
                  <span
                    onClick={() => this.closeDrawer()}
                    className="tax-close"
                  >
                    <img src={close} alt="close" />
                  </span>
                </div>
                <div className="tax-content-field">
                  <div className="tax-label">
                    <label>
                      Name <span className="star">*</span>
                    </label>
                    <div className="tax-label-input">
                      <input
                        type="text"
                        name="tax_name"
                        placeholder="Enter Tax Name"
                        autoComplete="off"
                        value={tax_name}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="tax-label">
                    <label>
                      Percantage <span className="star">*</span>
                    </label>
                    <div className="tax-label-input">
                      <input
                        type="text"
                        placeholder="Enter Tax Percentage"
                        name="tax_percentage"
                        autoComplete="off"
                        value={tax_percentage}
                        onChange={this.changePercentage}
                        maxLength={5}
                      />
                      <img
                        src={percentage}
                        alt="percentage"
                        className="tax-percent"
                      />
                    </div>
                  </div>

                  <div className="tax-label">
                    <label>Description</label>
                    <div className="tax-label-input">
                      <textarea
                        placeholder="Enter Description"
                        name="description"
                        autoComplete="off"
                        value={description}
                        onChange={this.handleDesChange}
                      >
                        {" "}
                      </textarea>
                    </div>
                  </div>
                </div>

                <div className="tax-footer">
                  <button
                    className="dec-btn-secondary"
                    onClick={() => this.closeDrawer()}
                  >
                    Cancel
                  </button>
                  <button className="dec-btn" onClick={() => this.AddEdittax()}>
                    {tax_id ? "Update" : " Save"}
                  </button>
                </div>
              </div>
            </Drawer>
          </Space>

          <ModalPopUp
            visible={this.state.deleteTax}
            cancel={this.cancelDelete}
            submit={this.submitDelete}
            name="Tax"
            delete_name={this.state.delete_name}
          />
        </div>
      </div>
    );
  }
}
