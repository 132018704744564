import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Table,
  Modal,
  Form,
  DatePicker,
  Spin,
  message,
  Empty,
} from "antd";
// import DatePicker from "../../components/ui/DatePicker";
import TopHeader from "../../components/ui/TopHeader";
import TableField from "../../components/ui/tableField";

import "antd/dist/antd.css";
import "../../stylesheets/styles.css";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { STORAGE, UPDATE, APPROVAL, DELETE } from "../../constants/roleStore";
import layer from "../../assets/Layer 2.svg";
import ModalPopUp from "../../components/ui/modalpop";

import { FormOutlined, DeleteOutlined } from "@ant-design/icons";
import Calendar from "../../assets/fi-rr-calendar (2).png";
import Filter from "../../components/ui/filter";
import moment from "moment";
import {
  showDate,
  getIsoString,
  numberWithComma,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  showError,
} from "../../constants/Utils";
// const FormItem = Form.Item;
import { currencyFormatter } from "muthu-plugins";
import { EXPENSESACCESS } from "../../components/ui/constants";

export default class Expenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 20,
      currentPage: 1,
      data: [],
      searchOption: "",
      searchInput: "",
      total: "",
      status: "",
      modal1Visible: false,
      from: "",
      to: "",
      Tableloading: true,
      dateRange: null,
      queryStaus: {},
      overall: "",
      isBack: false,
      app_name: "",
      app_type: "",
      deleteExpnses: false,
      delete_name: "",
      id: "",
      dropdown_menu: false,
      list_index: "",
      deleted: false,
      update: false,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    // this.setState({ status: params.get("status") || "" }, () => {
    //   this.setQueryStatus();
    // });
    let name = "";

    let storage = JSON.parse(STORAGE.view);
    name = storage?.filter((app) => app?.name === "My Payments");

    let value = name;
    value?.filter((app) => {
      let names = app?.sub_roles?.filter((role) => role.name === "Expenses");
      names &&
        names.map((el) =>
          this.setState({
            update: el?.permissions?.update,
            deleted: el?.permissions?.delete,
          })
        );
      this.setState({
        app_name: names,
      });
    });

    this.setState({
      app_type: STORAGE.type,
    });
    this.setQueryStatus();
    this.reaload();
    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);
  }
  onPopstate = () => {
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.from = searchObj.from ?? "";
    this.state.to = searchObj.to ?? "";
    this.state.queryStaus = returnSearchObject(search);
    this.state.pageSize = parseInt(searchObj.limit) ?? "";

    this.setState({ queryStaus: this.getQuerySearch() }, () => {
      this.handleTable();
    });
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);
  }
  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };
  reaload = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.clearSearch();
        }
      });
    });
  };

  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";

    querysearch.from = getIsoString(this.state.from) ?? "";
    querysearch.to = getIsoString(this.state.to) ?? "";

    return querysearch;
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",

          from: queryObj.from ?? "",
          to: queryObj.to ?? "",
          queryStaus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStaus);
          this.handleTable();
        }
      );
    } else {
      this.setState(
        {
          queryStaus: { ...this.state.queryStaus, page: 1, limit: 20 },
          pageSize: 20,
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStaus);
          this.handleTable();
        }
      );
    }
  };

  handleFilter = () => {
    let query = this.getQuerySearch();

    if (this.state.from > this.state.to) {
      showError("Select the valid date");
    } else {
      this.setState({ queryStaus: query }, () => {
        pushToFilteredPage(this.props, this.state.queryStaus);
        this.handleTable();
      });
    }
  };

  handleTable = () => {
    let { currentPage, from, to } = this.state;

    APIGATEWAY.get(
      "expenses/" + buildQueryString(this.state.queryStaus),
      (response) => {
        if (response.success) {
          let res = response.data;
          this.setState({
            data: res.expenses,
            total: res?.expenses?.length,
            Tableloading: false,
            overall: response.data?.total,
          });
        }
      }
    );
  };

  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  next = (prev) => {
    const { currentPage } = this.state;

    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };
  setModal1Visible = (modal1Visible) => {
    this.setState({ modal1Visible });
  };
  handleDetailpage = (id) => {
    this.props.history.push("expenses/detail/" + id);
  };
  handleEditPage = (id) => {
    this.props.history.push("expenses/edit/" + id);
  };
  onDateRangeChange = (dateRange) => {
    if (dateRange) {
      this.setState({
        dateRange: this.returnMomentDateRange(dateRange[0], dateRange[1]),
        from: moment(dateRange[0]),
        to: moment(dateRange[1]),
      });
    } else {
      this.setState({
        dateRange: null,
      });
    }
  };
  returnMomentDateRange = (start, finish) => {
    return [moment(start, "YYYY-MM-DD"), moment(finish, "YYYY-MM-DD")];
  };

  Apply = () => {
    const { from, to } = this.state;
    if (from === "" && to === "") {
      showError("Please select date");
    } else {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handleFilter();
        }
      );
    }
  };
  clearSearch = () => {
    let self = this;
    self.setState(
      {
        searchInput: "",
        searchOption: "",
        status: "",
        from: "",
        to: "",
        dateRange: null,
        queryStaus: { page: 1, limit: 20 },
        pageSize: 20,
        currentPage: 1,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStaus);

        this.handleTable();
      }
    );
  };

  searchInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  hanleStatus = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () =>
      this.handleTable()
    );
  };
  searchOption = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleDelete = (id, object) => {
    this.setState({
      deleteExpnses: true,
      delete_name: object?.item_of_expenses[0]?.item ?? "- ",
      id: id,
    });
  };
  cancelDelete = () => {
    this.setState({ deleteExpnses: false, delete_name: "", id: "" });
  };
  submitDelete = () => {
    let self = this;
    let data = {};
    APIGATEWAY.delete("expenses/" + this.state.id, data, (response) => {
      if (response.success) {
        message.success(response.message);

        self.handleTable();
        this.setState({ deleteExpnses: false, delete_name: "", id: "" });
      }
    });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.handleTable();
    });
  };

  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };

  dateChange = (value) => {
    this.setState({
      from: value[0],
      to: value[1],
    });
  };

  handleFromChange = (date, dateString) => {
    this.setState({ from: date });
  };

  handleToChange = (date, dateString) => {
    this.setState({ to: date });
  };
  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      list_index: index,
    });
  };
  render() {
    let self = this;
    const {
      data,
      currentPage,
      pageSize,
      total,
      app_name,
      app_type,
      overall,
      update,
      deleted,
    } = self.state;
    let countnext = overall;

    let countprev = overall - (overall - 1);

    if (currentPage > 1) {
      countnext = (currentPage - 1) * this.state.pageSize + total;
      countprev = countnext - (overall - 1);
    }

    return (
      <div className="">
        <div>
          <TopHeader
            title="Expenses"
            to="/expenses/add"
            type={app_type}
            permission={app_name}
          />
          <div className="main-content">
            <Filter
              clear={() => this.clearSearch()}
              apply={() => this.Apply()}
              handleOnFromChange={this.handleFromChange}
              handleOnToChange={this.handleToChange}
              fromDate={this.state.from}
              toDate={this.state.to}
              fromName="from"
              toName="to"
            />

            <div className="table-main">
              {this.state.Tableloading ? (
                <div className="tableview">
                  <Spin />
                </div>
              ) : (
                <div className="table_page_wrap">
                  {data.length > 0 ? (
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th className="">S.No</th>
                          <th className="">Name</th>
                          <th className="">Date</th>
                          <th className="">Amount</th>

                          <th></th>
                        </tr>
                      </thead>

                      {data.length > 0 ? (
                        <tbody ref={this.wrapperRef}>
                          {data.map((item, r) => (
                            <tr key={r}>
                              <td className="">
                                {" "}
                                {(this.state.currentPage - 1) *
                                  this.state.pageSize +
                                  (r + 1)}
                              </td>
                              <td className="table-name text-capitalize">
                                {" "}
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.handleDetailpage(item?.expense_id)
                                  }
                                >
                                  {item?.item_of_expenses[0]?.item ?? "-"}
                                </div>
                              </td>

                              <td className="table-name">
                                {showDate(item.purchase_date)}
                              </td>
                              <td className="table-name">
                                {numberWithComma(item.total_amount ?? 0)}
                              </td>

                              {(EXPENSESACCESS.update ||
                                EXPENSESACCESS.delete) && (
                                <td className="table-action">
                                  <div
                                    className="tax-add-edit"
                                    onClick={() => this.dropdownClick(r)}
                                  >
                                    <span className="ml-5">
                                      <div className="dropdown-head">
                                        <div className="dropbtn-head">
                                          <img
                                            src={layer}
                                            alt="profile"
                                            className="menu-bar-icon"
                                          />
                                        </div>
                                        {this.state.list_index === r &&
                                          this.state.dropdown_menu && (
                                            <div className="dropdown-content-heads">
                                              <div>
                                                {EXPENSESACCESS.update && (
                                                  <p
                                                    className="action-menu-bar"
                                                    onClick={() =>
                                                      this.handleEditPage(
                                                        item.expense_id
                                                      )
                                                    }
                                                  >
                                                    Edit
                                                  </p>
                                                )}

                                                {EXPENSESACCESS.delete && (
                                                  <p
                                                    className="action-menu-bar"
                                                    onClick={() =>
                                                      this.handleDelete(
                                                        item.expense_id,
                                                        item
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        ""
                      )}
                    </table>
                  ) : (
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th className="">S.No</th>
                          <th className="">Name</th>
                          <th className="">Date</th>
                          <th className="">Amount</th>

                          <th></th>
                        </tr>
                      </thead>

                      <tbody className="empty-message">
                        <tr>
                          <td colspan="10">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {data.length > 0 && (
                    <TableField
                      data={data}
                      countprev={countprev}
                      countnext={countnext}
                      currentPage={currentPage}
                      ClickPrev={() => this.previous()}
                      ClickNext={() => this.next(this.state.currentPage)}
                      total={overall}
                      overAll={this.state.overall ?? 0}
                      pagelimit={this.state.pageSize}
                      pagelimitHandle={this.pageLimitOnchange}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          title="Import"
          style={{ top: 50 }}
          open={this.state.modal1Visible}
          onOk={() => this.setModal1Visible(false)}
          onCancel={() => this.setModal1Visible(false)}
          footer={[
            <div>
              <Button
                key="cancel"
                type="primary"
                onClick={() => this.setModal1Visible(false)}
              >
                Cancel
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={() => this.submitHandle()}
              >
                Submit
              </Button>
            </div>,
          ]}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className="admin_input"
          >
            <div>
              <Button
                style={{ backgroundColor: "#4287f5", color: "#fff" }}
                type="info"
                onClick={() => this.upload()}
              >
                Upload
              </Button>
            </div>
          </Form>
        </Modal>
        <ModalPopUp
          visible={this.state.deleteExpnses}
          cancel={this.cancelDelete}
          submit={this.submitDelete}
          name="Expenses"
          delete_name={this.state.delete_name}
        />
      </div>
    );
  }
}
