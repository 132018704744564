import React, { Component } from "react";

import "../stylesheets/styles.css";
import APIGATEWAY from "../ApiService/ApiGateWay/apiGateWay";
import { Input, message, Spin, Modal, Empty } from "antd";
import {
  showError,
  isInvalidName,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
} from "../constants/Utils";
import { FormOutlined } from "@ant-design/icons";
import TopHeader from "../components/ui/TopHeader";
import { STORAGE, UPDATE } from "../constants/roleStore";
import TableField from "../components/ui/tableField";
import InputItem from "../components/ui/InputItem";
import layer from "../assets/Layer 2.svg";
import { PLATFORMSACCESS } from "../components/ui/constants";

export default class PlatformList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      platformList: [],
      search: "",
      currentPage: 1,
      total: "",
      pageSize: 20,
      loading: true,
      modalOpen: false,
      editmodal: false,
      name: "",
      platform_id: "",
      Tableloading: false,
      modal1Visible: false,
      app_name: "",
      app_type: "",
      queryStatus: {},
      totalDoc: "",
      dropdown_menu: false,
      list_index: "",
      update: false,
    };
    this.wrapperRef = React.createRef();
  }
  componentDidMount() {
    let name = "";

    let storage = JSON.parse(STORAGE.view);

    name = storage?.filter((app) => app?.name === "My Business");
    let value = name;
    value?.filter((app) => {
      let names = app?.sub_roles?.filter((role) => role.name === "Platforms");
      names &&
        names.map((el) =>
          this.setState({
            update: el?.permissions?.update,
          })
        );
      this.setState({
        app_name: names,
      });
    });
    this.setState({
      app_type: STORAGE.type,
    });
    document.addEventListener("click", this.clickOutside);

    this.setQueryStatus();
    this.reloadPage();
  }
  componentWillUnmount = () => {
    document.removeEventListener("click", this.clickOutside);
  };
  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };
  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.setQueryStatus();
        }
      });
    });
  };
  setModal1Visible = (modal1Visible) => {
    this.setState({ modal1Visible, name: "", platform_id: "" });
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",

          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.callPlatformAPI();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.callPlatformAPI();
        }
      );
    }
  };

  handleFilter = () => {
    let { currentPage, pageSize } = this.state;

    let query = returnSearchObject(this.props.location.search);

    query.page = currentPage ?? "";
    query.limit = pageSize ?? "";

    this.setState(
      {
        queryStatus: query,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.callPlatformAPI();
      }
    );
  };
  callPlatformAPI = () => {
    APIGATEWAY.get(
      "platforms" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          let total = response.data?.platforms?.length;

          this.setState({
            platformList: response.data.platforms,
            total,
            loading: false,
            Tableloading: false,
            totalDoc: response.data?.total,
          });
        } else {
          this.setState({ loading: true });
        }
      }
    );
  };

  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  next = (prev) => {
    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.callPlatformAPI();
    });
  };
  handleaddCancel = () => {
    this.setState({ modalOpen: false });
  };
  handleEditCancel = () => {
    this.setState({ editmodal: false });
  };
  handleOk = () => {
    let { name } = this.state;
    if (name === undefined || name.trim() === "") {
      showError("Please enter name");
    } else if (name.length <= 3) {
      showError("Name should contain atleast 3 characters");
    } else if (isInvalidName(name)) {
      showError("Name should contain letters only");
    } else {
      let data = {
        name: name,
      };
      APIGATEWAY.post("platforms", data, (response) => {
        if (response.success) {
          this.setState({ modal1Visible: false });
          message.success(response.message);

          this.callPlatformAPI();
        } else {
          message.error(response.message);
        }
      });
    }
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleEditPage = (username) => {
    this.setState({
      modal1Visible: true,
      name: username.name,
      platform_id: username.platform_id,
    });
  };
  platformDetail = (id) => {
    APIGATEWAY.get("platforms/" + id, (response) => {
      if (response.success) {
        let platform = response.data.platform;
        this.setState({
          name: platform.name,
          platform_id: platform.platform_id,
        });
      }
    });
  };
  handleEditOk = (id) => {
    let self = this;
    let { name } = this.state;
    if (name === undefined || name.trim() === "") {
      showError("Please enter Name");
    } else if (name.length <= 3) {
      showError("Name should contain atleast 3 characters");
    } else {
      let data = {
        name: name,
      };
      APIGATEWAY.patch("platforms/" + id, data, (response) => {
        if (response.success) {
          self.setState({ modal1Visible: false });

          message.success(response.message);
          self.callPlatformAPI();
        } else {
          message.error(response.message);
        }
      });
    }
  };

  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      list_index: index,
    });
  };
  render() {
    let {
      total,
      platformList,
      currentPage,

      name,
      pageSize,
      platform_id,
      app_type,
      app_name,
      update,
    } = this.state;
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }

    return (
      <div className="">
        <>
          <TopHeader
            title="Platforms"
            modals={() => this.setModal1Visible(true)}
            type={app_type}
            permission={app_name}
          />
          <div style={{ padding: "0px 23px 30px 30px" }}>
            {this.state.loading ? (
              <div className="spinning-star">
                <Spin size="large" />
              </div>
            ) : (
              <div style={{ margin: "0px 0px 100px 0px" }}>
                <div className="table_page_wrap">
                  {platformList.length > 0 ? (
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th className="">S.No</th>
                          <th className="">Name</th>

                          <th></th>
                        </tr>
                      </thead>

                      {platformList.length > 0 ? (
                        <tbody ref={this.wrapperRef}>
                          {platformList.map((plat, r) => (
                            <tr key={r}>
                              <td className="">
                                {" "}
                                {(this.state.currentPage - 1) *
                                  this.state.pageSize +
                                  (r + 1)}
                              </td>
                              <td className="table-name text-capitalize">
                                {plat.name}
                              </td>

                              {PLATFORMSACCESS.update && (
                                <td
                                  className="table-action"
                                  onClick={() => this.dropdownClick(r)}
                                >
                                  <div className="tax-add-edit">
                                    <span className="ml-5">
                                      <div className="dropdown-head">
                                        <div className="dropbtn-head">
                                          <img
                                            src={layer}
                                            alt="profile"
                                            className="menu-bar-icon"
                                          />
                                        </div>
                                        {this.state.list_index === r &&
                                          this.state.dropdown_menu && (
                                            <div className="dropdown-content-heads">
                                              <div>
                                                <p
                                                  className="action-menu-bar"
                                                  onClick={() =>
                                                    this.handleEditPage(plat)
                                                  }
                                                >
                                                  Edit
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        ""
                      )}
                    </table>
                  ) : (
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th className="">S.No</th>
                          <th className="">Name</th>

                          <th></th>
                        </tr>
                      </thead>

                      <tbody className="empty-message">
                        <tr>
                          <td colspan="5">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {platformList.length > 0 && (
                    <TableField
                      data={platformList}
                      countprev={countprev}
                      countnext={countnext}
                      currentPage={currentPage}
                      ClickPrev={() => this.previous()}
                      ClickNext={() => this.next(this.state.currentPage)}
                      total={total}
                      overAll={this.state.totalDoc ?? 0}
                      pagelimit={this.state.pageSize}
                      pagelimitHandle={this.pageLimitOnchange}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <Modal
            title={platform_id !== "" ? "Edit Platform" : "Add Platform"}
            open={this.state.modal1Visible}
            onOk={() => this.setModal1Visible(false)}
            onCancel={() => this.setModal1Visible(false)}
            footer={[
              <div className="d-flex justify-content-between align-items-center">
                <button
                  key="cancel"
                  className="dec-btn-secondary"
                  onClick={() => this.setModal1Visible(false)}
                >
                  Cancel
                </button>

                <button
                  key="submit"
                  className="dec-btn"
                  onClick={
                    platform_id !== ""
                      ? () => this.handleEditOk(platform_id)
                      : () => this.handleOk()
                  }
                >
                  {platform_id !== "" ? "Update" : "Submit"}
                </button>
              </div>,
            ]}
          >
            <InputItem
              label="Name"
              name="name"
              value={name}
              star={false}
              placeholder="Enter the Platform"
              onChange={this.handleInputChange}
            />
          </Modal>
        </>
      </div>
    );
  }
}
