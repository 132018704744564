import React, { useState, useEffect, useContext, Fragment } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import ProgressBar from "./Progressbar";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Select from "react-select";
import { DatePicker, Drawer, Slider, Spin, Empty, message } from "antd";
import TableField from "../../components/ui/tableField";
import TopHeader from "../../components/ui/TopHeader";
import moment from "moment";
import edit from "../../assets/fi-rr-pencil.svg";
import "../../stylesheets/project/project.css";
import "../../pages/Project/Milestone.css";
// import { GrClose } from "react-icons/gr";
import updateicon from "../../assets/updateStatus.svg";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import Search from "../../components/ui/search";
import layer from "../../assets/Layer 2.svg";
import Filter from "../../components/ui/filter";
import close from "../../assets/close_symbol.svg";
import { useParams } from "react-router-dom";
import {
  showLongDateFormat,
  buildQueryString,
  showError,
  capitalize,
} from "../../constants/Utils";
import { milliseconds } from "date-fns";
 
const Milestone = (props) => {

  const params = useParams();
  const [selectedOption, setSelectedOption] = useState(null);
  const [visibleOrNot, setVisibleOrNot] = useState(false);
  let searchTerm = null;
  //DropDown using antDesign
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [milseStone, setMilestone] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState("");
  const [totalDoc, setTotalDoc] = useState("");
  const [id, setId] = useState("");
  const [mileStoneId, setMileStoneId] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const [milestoneUpdateBtn, setMilestoneUpdateBtn] = useState(false);
  const [milestoneTitle, setMilestoneTitle] = useState("");
  const [description, setDescription] = useState("");
  const [expectedDay, setExpectedDay] = useState("");
  const [startDay, setStartDay] = useState("");
  const [endDay, setEndDay] = useState("");
  const [hoidayList, setHolidayList] = useState("");
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [listIndex, setListIndex] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [percentage, setPercentage] = useState();
  const [milestoneDetail, setMilestoneDetail] = useState();
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    search_term: "",
    date_filter: "Overall",
    status: "all",
    limit: 10,
  });
  const [occurenceData, setoccurenceData] = useState([
    { value: "this_month", type: "This Month" },
    { value: "this_year", type: "This Year" },
    { value: "last_month", type: "Last Month" },
    { value: "last_year", type: "Last Year" },
    { value: "overall", type: "Overall" },
  ]);
  const [statusData, setStatusData] = useState([
    { value: "Completed", key: "completed" },
    { value: "Inprogress", key: "inprogress" },
    { value: "Pending", key: "pending" },
  ]);
  const [mileStonePageLoading, setMileStonePageLoading] = useState(true);
  const [updateStatusPageLoading, setUpdateStatusPageLoading] = useState(false);
  const [updateMilestonePageLoading, setUpdateMilestonePageLoading] =
    useState(false);
  const [color, setColor] = useState("#214757");
  // const current_date = moment().format("YYYY-MM-DD");
  const current_date = moment(props.startDate).format("YYYY-MM-DD");

  const wrapperRef = React.createRef();

  useEffect(() => {
    const projectId = props.project_id;
    setId(props.project_id);
    !visibleOrNot && fetchMilestoneList(projectId);
    setBtnLoader(false);
  }, [queryStatus, visibleOrNot]);


  let countnext = total;
  let countprev = total - (total - 1);
  if (currentPage > 1) {
    countnext = (currentPage - 1) * 10 + total;
    countprev = countnext - (total - 1);
  }

  function fetchMilestoneList(id) {
    return new Promise((resolve, reject) => {
      APIGATEWAY.get(
        `project/milestone/${id}` + buildQueryString(queryStatus),
        (response) => {
          setLoading(false);
          if (!response.success) return reject(false);
          setMilestone(response.data.milestones);
          setTotal(response.data?.milestones?.length);
          setTotalDoc(response.data?.totalCount);
          setMileStonePageLoading(false);
        }
      );
    });
  }

  const getStatusColor = (status) => {
    if (status === "pending") {
      return "#ffc700";
    } else if (status === "cancelled") {
      return "#EF4733";
    } else if (status === "inprogress") {
      return "#fca600";
    } else {
      return "#009C22";
    }
  };

  function onSearchChange(e) {
    const value = e.target.value;
    setLoading(true);
    clearTimeout(searchTerm);
    searchTerm = setTimeout(() => {
      setQueryStatus((prev) => ({ ...prev, search_term: value }));
    }, 1000);
  }

  function previous() {
    setCurrentPage(currentPage - 1);
    setQueryStatus((prev) => ({ ...prev, page: currentPage - 1 }));
    setLoading(true);
  }

  function next(prevPage) {
    setCurrentPage(prevPage + 1);
    setQueryStatus((prev) => ({ ...prev, page: prevPage + 1 }));
    setLoading(true);
  }

  function pageLimitOnchange(value) {
    setLoading(true);
    // setQueryStatus({ ...queryStatus, limit: value });
    setQueryStatus((prev) => ({ ...prev, limit: value }));
  }

  function create(e) {
    e.preventDefault();

    setBtnLoader(true);
    if (!milestoneTitle) {
      setBtnLoader(false);
      showError("Please enter title");
    } else if (!startDay) {
      setBtnLoader(false);
      showError("Please enter start date");
    } else if (!endDay) {
      setBtnLoader(false);
      showError("Please enter end date");
    } else {
      const body = {
        project: id,
        title: milestoneTitle,
        description: description,
        expectedDays: expectedDay,
        startDate: startDay,
        endDate: endDay,
      };
      const cb = (response) => {
        message.success(response.message);
        setBtnLoader(false);
        if (response.success) closeDrawer(e)
      }
      if (mileStoneId) APIGATEWAY.patch(`project/milestone/${mileStoneId}`, body, cb);
      else APIGATEWAY.post(`project/milestone/`, body, cb)
    }
  }

  function checkStartDate(e) {
    if (!startDay) return e.isBefore(current_date);
    else if (startDay && !endDay) return e.isBefore(current_date);
    else if (startDay && endDay)
      return e.isBefore(current_date) || e.isAfter(endDay.format("YYYY-MM-DD"));
  }

  function checkEndDateDate(e) {
    if (!startDay) return e.isBefore(current_date);
    else if (startDay) return e.isBefore(startDay);
  }

  function openMilestoneStatus(milestoneid) {
    setUpdateStatusPageLoading(true);
    setMileStoneId(milestoneid);
    getMilestoneDetails(milestoneid);
    setIsUpdate(true);
    setDropdownMenu(false);
  }

  function updateMilestone(id) {
    setVisibleOrNot(true);
    getHolidayList()
    if (id) {
      setUpdateMilestonePageLoading(true);
      getMilestoneDetails(id);
      setMileStoneId(id);
    } else setUpdateMilestonePageLoading(false);
  }

  function getMilestoneDetails(id) {
    return new Promise((resolve, reject) => {
      APIGATEWAY.get(`/project/milestone/detail/${id}`, (response) => {
        const localBody = response.data.milestone;
        setMilestoneDetail(localBody);
        setPercentage(localBody.percentage);
        setMilestoneTitle(localBody.title);
        setDescription(localBody.description);
        setStartDay(moment(localBody.startDate));
        setEndDay(moment(localBody.endDate));
        setUpdateStatusPageLoading(false);
        setUpdateMilestonePageLoading(false);
      });
    });
  }

  const dropdownClick = (index) => {
    setDropdownMenu(!dropdownMenu);
    setListIndex(index);
  };

  const handleOccurenceChange = (value) => {
    setTimeout(() => {
      setLoading(true);
    }, 30);
    setQueryStatus({ ...queryStatus, date_filter: value });
  };

  const closeDrawer = (e) => {
    e.preventDefault();
    setDropdownMenu(false);
    setVisibleOrNot(false);
    setIsUpdate(false);
    setMilestoneTitle("");
    setMileStoneId("")
    setDescription("");
    setStartDay("");
    setEndDay("");
    setExpectedDay("");
    setBtnLoader(false);
  };

  function handleOnStateChange(value) {
    setTimeout(() => {
      setLoading(true);
    }, 30);
    setQueryStatus({ ...queryStatus, status: value });
  }

  function updateMilestoneStatus(e) {
    e.preventDefault();
    setMilestoneUpdateBtn(true);
    if (percentage > 100) {
      showError(`Percentage must be less than 100`);
      setMilestoneUpdateBtn(false);
    } else if (percentage < milestoneDetail?.percentage) {
      showError(`${milestoneDetail?.percentage}% of work is already done`);
      setMilestoneUpdateBtn(false);
    } else {
      const body = {
        percentage: percentage,
        status:
          percentage === 0
            ? "pending"
            : percentage === 100
            ? "completed"
            : "inprogress",
      };
      return new Promise((resolve, reject) => {
        APIGATEWAY.patch(
          `project/milestone/status/${mileStoneId}`,
          body,
          (response) => {
            setMilestoneUpdateBtn(false);
            if (response.success) {
              message.success("Milestone status  updated successfully");
              setIsUpdate(false);
              fetchMilestoneList(id);
            }
          }
        );
      });
    }
  }

  function calculateWeekdays(startDate, endDate) {
    let currentDate = moment(startDate)
    let endDateFormatted = moment(endDate).add(1, 'days').format('YYYY-MM-DD'); // Format end date
    let weekdays = 0;
    while (currentDate.format('YYYY-MM-DD') !== endDateFormatted) {
      if (currentDate.day() !== 0 && currentDate.day() !== 6 && !hoidayList.includes(currentDate.format('YYYY-MM-DD'))) { // 0 is Sunday, 6 is Saturday
        weekdays++;
      }
      currentDate.add(1, 'days');
    }
    return weekdays;
  }

  const getHolidayList=()=>{
  const  queryStatus={ page: 1, limit: 100}
    APIGATEWAY.get(
      `employees/holidays?page=${queryStatus.page}&limit=${queryStatus.limit}`,
      (response) => {
        if (response.success){
          const holiday= response?.data?.holiday.map((list)=>{
            return moment(list.date).format('YYYY-MM-DD')
          })      
          setHolidayList(holiday)
        
        }

      }
    );
  }


  return (
    <div>
      {mileStonePageLoading ? (
        <div className="spinning-star">
          <Spin size="large" />
        </div>
      ) : (
        <div className="milestone-container">
          <div className="searchbar-container">
            <Search
              details={queryStatus}
              outerDivClassName="mr-16 w_20"
              placeholder="Search"
              searchClassName=""
              onSearchChange={onSearchChange}
            ></Search>
            <Filter
              occurenceData={occurenceData}
              occurenceName="occurence"
              occurenceSelect={queryStatus.date_filter}
              onOccurenceChange={handleOccurenceChange}
              statusData={statusData}
              statusName="status"
              statusSelect={queryStatus.status}
              statusOnChange={handleOnStateChange}
              primaryButton="+ Add Milestone"
              primaryClick={() => updateMilestone()}
              // primaryClick={openMilestoneStatus}
            />
          </div>
          {loading ? (
            <div className="spinning-star1">
              <Spin size="large" />
            </div>
          ) : milseStone.length > 0 ? (
            <div>
              <table className="table-container">
                <thead>
                  <tr className="tablehead-row">
                    <th className="small-tablehead">Title</th>
                    <th className="wide-tablehead">Percentage</th>
                    <th className="mid-tablehead">Start date</th>
                    <th className="mid-tablehead">End date</th>
                    <th className="mid-tablehead">Status</th>
                    <th className="option-tablehead">{""}</th>
                  </tr>
                </thead>
                {milseStone.length > 0 ? "" : "<div></div>"}
                <tbody>
                  {milseStone.map((data, i) => (
                    <tr key={i}>
                      <td className="fw_500 fs_14 c_dark">{data.title}</td>
                      <td>{<ProgressBar percentage={data.percentage} />}</td>
                      <td className="fw_500 fs_14 c_dark">
                        {showLongDateFormat(data.startDate)}
                      </td>
                      <td className="fw_500 fs_14 c_dark">
                        {showLongDateFormat(data.endDate)}
                      </td>
                      <td
                        className="fw_500 fs_14"
                        style={{ color: getStatusColor(data.status) }}
                      >
                        {capitalize(data.status)}
                      </td>
                      <td className="table-action">
                        <div className="tax-add-edit">
                          <span className="ml-5 " ref={wrapperRef}>
                            <div className="dropdown-head">
                              <div className="dropbtn-head">
                                <img
                                  onClick={() => dropdownClick(i)}
                                  src={layer}
                                  alt="profile"
                                  className="menu-bar-icon"
                                />
                              </div>
                              {listIndex === i && dropdownMenu && (
                                <Fragment>
                                  <div
                                    className="overflow-overlay"
                                    onClick={() => setDropdownMenu(false)}
                                  ></div>
                                  <div className="dropdown-content-heads">
                                    <div style={{ width: "170px" }}>
                                      {
                                        <div
                                          onClick={() =>
                                            updateMilestone(data._id)
                                          }
                                          className="d-flex_al_c action-menu-bar on_hover"
                                        >
                                          <div>
                                            <img
                                              alt="edit"
                                              style={{
                                                width: "15px",
                                                marginRight: "10px",
                                              }}
                                              src={edit}
                                            ></img>
                                          </div>
                                          <div className="light">
                                            <div className="m_0 p_0 anchor">
                                              Edit
                                            </div>
                                          </div>
                                        </div>
                                      }
                                      {data.status !== "completed" && (
                                        <div
                                          onClick={() =>
                                            openMilestoneStatus(data._id)
                                          }
                                          className="d-flex_al_c action-menu-bar on_hover"
                                        >
                                          <div>
                                            <img
                                              alt="edit"
                                              style={{
                                                width: "15px",
                                                marginRight: "10px",
                                              }}
                                              src={updateicon}
                                            ></img>
                                          </div>
                                          <div className="light">
                                            <div className="m_0 p_0 anchor">
                                              Update Status
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Fragment>
                              )}
                            </div>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {milseStone.length > 0 && (
                <TableField
                  data={milseStone}
                  countprev={countprev}
                  countnext={countnext}
                  currentPage={currentPage}
                  ClickPrev={() => {
                    previous();
                  }}
                  ClickNext={() => next(currentPage)}
                  total={total}
                  overAll={totalDoc ?? 0}
                  pagelimit={queryStatus.limit}
                  pagelimitHandle={pageLimitOnchange}
                />
              )}
            </div>
          ) : (
            <table className="table-container">
              <thead>
                <tr className="tablehead-row">
                  <th className="small-tablehead">Title</th>
                  <th className="wide-tablehead">Percentage</th>
                  <th className="mid-tablehead">Start date</th>
                  <th className="mid-tablehead">End date</th>
                  <th className="mid-tablehead">Status</th>
                  <th className="option-tablehead">{""}</th>
                </tr>
              </thead>
              <tbody className="empty-message">
                <tr>
                  <td colSpan="6">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </td>
                </tr>
              </tbody>
            </table>
          )}

          <Drawer
            placement="right"
            open={visibleOrNot}
            onClose={(e) => closeDrawer(e)}
            className="Milestones-main-drawer"
          >
            {updateMilestonePageLoading ? (
              <div className="spinning-star">
                <Spin size="large" />
              </div>
            ) : (
              <div className="Milestone-drawer-container">
                <header>
                  <h5 className="milestone-header-content">Milestones</h5>
                  {/* 
              <GrClose className="MilestoneClose-icon" />
             */}
                  <span
                    onClick={(e) => closeDrawer(e)}
                    className="MilestoneClose-icon"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={close}
                      className="MilestoneClose-img"
                      alt="close"
                    ></img>
                  </span>
                </header>
                <form>
                  <div className="tax-label">
                    <label className="mb_15">
                      Title<span className="mandatory-field">&#42;</span>
                    </label>
                    <div className="tax-label-input">
                      <input
                        type="text"
                        name="tax_name"
                        placeholder="Enter a Title"
                        autoComplete="off"
                        value={milestoneTitle}
                        onChange={(e) => setMilestoneTitle(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="tax-label">
                    <label>Description</label>
                    <div className="tax-label-input">
                      <textarea
                        placeholder="Add a text here..."
                        name="description"
                        autoComplete="off"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      >
                        {" "}
                      </textarea>
                    </div>
                  </div>

                  <div className="tax-label mb-24">
                    <label>
                      Start Date<span className="mandatory-field">&#42;</span>
                    </label>
                    <DatePicker
                      disabledDate={checkStartDate}
                      value={startDay}
                      onChange={(e) => setStartDay(e)}
                      style={{ width: "100%" }}
                      id="date-start"
                    />
                  </div>

                  <div className="tax-label mb-24 ">
                    <label htmlFor="date-end">
                      End Date<span className="mandatory-field">&#42;</span>
                    </label>
                    <DatePicker
                      disabledDate={checkEndDateDate}
                      value={endDay}
                      onChange={(e) => setEndDay(e)}
                      style={{ width: "100%" }}
                      id="date-end"
                    />
                  </div>

                  <div className="tax-label mb-24 ">
                    <label htmlFor="date-end">Expected Days</label>
                    <div className="tax-label-input">
                      <input
                        type="number"
                        name="tax_name"
                        placeholder="Enter a expected days"
                        autoComplete="off"
                        value={
                          startDay && endDay && calculateWeekdays(startDay, endDay)
                        }
                        // onChange={(e) => setExpectedDay(e.target.value)}
                        // onChange={(e) => statusTitleChange(e)}
                      />
                    </div>
                  </div>
                  <div>
                    <TopHeader
                      backClick={closeDrawer}
                      classname="p_0"
                      submit={(e) => create(e)}
                      // id={id}
                      spin={btnLoader}
                    />
                  </div>
                </form>
              </div>
            )}
          </Drawer>

          <Drawer
            placement="right"
            open={isUpdate}
            onClose={(e) => closeDrawer(e)}
            className="Milestones-main-drawer"
          >
            {updateStatusPageLoading ? (
              <div className="spinning-star">
                <Spin size="large" />
              </div>
            ) : (
              <div className="Milestone-drawer-container">
                <header>
                  <h5 className="milestone-header-content">Update Status</h5>
                  <span
                    onClick={(e) => closeDrawer(e)}
                    className="MilestoneClose-icon"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={close}
                      className="MilestoneClose-img"
                      alt="close"
                    ></img>
                  </span>
                </header>
                <form>
                  <div className="tax-label">
                    <label className="mb_15">Completed Percentage</label>
                    <div className="ant_slider">
                      <Slider
                        onChange={(e) => setPercentage(e)}
                        trackStyle={{ backgroundColor: color }}
                        value={percentage}
                        step={5}

                      />
                      <div className="d-flex_jc_sb_al_c">
                        <div
                          className="fw_500 fs_16"
                          style={{ color: "#212529" }}
                        >
                          0
                        </div>
                        <div
                          className="fw_500 fs_16"
                          style={{ color: "#212529" }}
                        >
                          100%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex_al_c_jc_e mt_30">
                    <TopHeader
                      backClick={closeDrawer}
                      classname="p_0"
                      submit={updateMilestoneStatus}
                      id={id}
                      spin={milestoneUpdateBtn}
                    />
                  </div>
                </form>
              </div>
            )}
          </Drawer>
        </div>
      )}
    </div>
  );
};

export default Milestone;
