import React, { useEffect, useState, useRef, Fragment } from "react";
import Search from "../../components/ui/search";
import Filter from "../../components/ui/filter";
import "../../pages/Project/changeRequest.css";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import {
  buildQueryString,
  showLongDateFormat,
  showError,
  getStatusColor,
} from "../../constants/Utils";
import TableField from "../../components/ui/tableField";
import { Space, Drawer, Spin, DatePicker, Empty, message } from "antd";
import close from "../../assets/emp_close.svg";
import TopHeader from "../../components/ui/TopHeader";
import layer from "../../assets/Layer 2.svg";
import moment from "moment";
import edit from "../../assets/fi-rr-pencil.svg";
import updateicon from "../../assets/updateStatus.svg";
import UpdateProjectComponent from "./updateProjectComponent";

const ChangeRequest = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  //DropDown using antDesign
  const optionsThisMonth = [
    { value: "this_month", label: "This month" },
    { value: "last_month", label: "Last month" },
    { value: "this_year", label: "This Year" },
  ];
  const [changeRequest, setChangeRequest] = useState([]);
  const [loading, setLoading] = useState(true);
  const [occurenceData, setoccurenceData] = useState([
    { value: "this_month", type: "This Month" },
    { value: "this_year", type: "This Year" },
    { value: "last_month", type: "Last Month" },
    { value: "last_year", type: "Last Year" },
  ]);

  const [updateStatusData, setUpdateStatusData] = useState([
    { value: "Inprogress", key: "inprogress" },
    { value: "Completed", key: "completed" },
    { value: "Cancelled", key: "cancelled" },
  ]);
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    limit: 10,
    search_term: "",
    date_filter: "this_year",
    status: "all",
    // limit: 10,
  });
  const [id, setId] = useState("");
  // const [total, setTotal] = useState("");
  const [statusData, setStatusData] = useState([
    { value: "Completed", key: "completed" },
    { value: "Cancelled", key: "cancelled" },
    { value: "Pending", key: "pending" },
  ]);

  const [totalDoc, setTotalDoc] = useState("");
  const itemInThePage = [
    { value: "5", label: "5" },
    { value: "10", label: "10" },
    { value: "15", label: "15" },
  ];
  const newStyles = {
    control: (provided, state) => ({
      ...provided,

      width: "40px !important",
      lineHeight: "13px",
      color: "#214757",
      boxShadow: "none",
      outline: "none",
      border: 0,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "13px",
      width: "40px !important",
      fontWeight: "400", // Set your desired font size for options here
      paddingRight: "10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: "400",
      color: "#214757",
    }),
    indicatorsContainer: (provided) => {
      const topPosition = selectedOption === null ? "8%" : "-9999px";
      return {
        ...provided,
        backgroundColor: "transparent",
        fill: "#214757",
        border: "none",
        position: "absolute",
        top: topPosition,
        left: "20%",
      };
    },
    dropdownIndicator: (provided) => ({
      ...provided,
      transition: "none !important",
    }),
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,

      width: "158px",
      lineHeight: "13px",
      color: "#214757",
      height: "41px",
      boxShadow: "none",
      outline: "1px solid rgba(205, 205, 205, .4)",
      border: 0,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: "400", // Set your desired font size for options here
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: "400",
      color: "#214757",
      top: "45%",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      marginBottom: "7px",
      fill: "#214757",
      position: " absolute",
      top: "10%",
      right: "5%",
      paddingRight: "220px !impartant",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      transition: "none !important",
    }),
  };
  const [openDrawer, setOpenDrawer] = useState(null);
  const [openUpdateDrawer, setOpenUpdateDrawer] = useState(null);

  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [initPageLoading, setInitPageLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [changeRequestId, setChangeRequestId] = useState("");
  const [changeRequestTitle, setChangeRequestTitle] = useState("");
  const [description, setDescription] = useState("");
  const [editVisible, setEditVisible] = useState(null);
  const editRef1 = useRef(null);
  const [requestId, setRequestId] = useState(null);
  //pagination
  const [itemPerPage, setItemPerPage] = useState("5");
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [total, setTotal] = useState("");
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  // const currentItems = changeRequest?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(changeRequest.length / itemPerPage);
  const wrapperRef = React.createRef();
  // const current_date = moment().format("YYYY-MM-DD");
  const current_date = moment(props.startDate).format("YYYY-MM-DD");

  let countnext = total;
  let countprev = total - (total - 1);
  if (currentPage > 1) {
    countnext = (currentPage - 1) * 10 + total;
    countprev = countnext - (total - 1);
  }

  useEffect(() => {
    setId(props.project_id);
    getChangeRequest();

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [queryStatus, openDrawer === "false"]);

  function getChangeRequest() {
    // props.project_id = '64cccc0ae6b48b22b4a264b4'
    return new Promise((resolve, reject) => {
      APIGATEWAY.get(
        `project/changerequest/${props.project_id}` +
          buildQueryString(queryStatus),
        (response) => {
          setLoading(false);

          setChangeRequest(response.data.changeRequests);
          setTotalDoc(response.data?.totalCount);
          setTotal(response.data?.changeRequests?.length);
        }
      );
    });
  }

  const handleClickOutside = (event) => {
    if (editRef1.current && !event.target.classList.contains("menu-bar-icon")) {
      setEditVisible(false);
    }
  };

  // const getStatusColor = (status) => {
  //   if (status === "in Progress") {
  //     return <span style={{ color: '#F48F23' }}>Inprogress</span>;
  //   } else if (status === "pending") {
  //     return <span style={{ color: '#F8B146' }}>Pending</span>;
  //   } else if (status === "cancelled") {
  //     return <span style={{ color: '#EF4733' }}>Cancelled</span>;
  //   } else if (status === "on Hold") {
  //     return <span style={{ color: '#619FFC' }}>Onhold</span>;
  //   } else if (status === "active") {
  //     return <span style={{ color: '#009C22' }}>Active</span>;
  //    } else {
  //       return <span style={{ color: '#009C22' }}>status</span>;
  //     }

  // };

  const onSearchChange = (e) => {
    const value = e.target.value;
    setLoading(true);
    setTimeout(() => {
      setQueryStatus({ ...queryStatus, search_term: value });
    }, 1000);
  };

  const handleOccurenceChange = (value) => {
    setTimeout(() => {
      setLoading(true);
    }, 30);
    setQueryStatus({ ...queryStatus, date_filter: value });
  };

  const handleOnSearchChange = (value) => {
    // setTimeout(() => {
    setLoading(true);
    // }, 0);
    setQueryStatus({ ...queryStatus, status: value });
    setCurrentPage(1);
  };

  const previous = () => {
    setCurrentPage(currentPage - 1);
    setQueryStatus({ ...queryStatus, page: currentPage - 1 });
    setLoading(true);
  };

  const next = (prev) => {
    setCurrentPage(prev + 1);
    setQueryStatus({ ...queryStatus, page: prev + 1 });
    setLoading(true);
  };

  const pageLimitOnchange = (value) => {
    setLoading(true);
    setQueryStatus({ ...queryStatus, limit: value });
  };

  const closeDrawer = () => {
    return new Promise((resolve, reject) => {
      setDropdownMenu(false);
      setChangeRequestTitle("");
      setDescription("");
      setStartDate("");
      setEndDate("");
      setRequestId("");
      setOpenDrawer(false);
      setOpenUpdateDrawer(false);
      resolve(true);
    });
  };

  function addRequest() {
    setOpenDrawer(true);
  }

  const handleEditClick = (e, id) => {
    e.preventDefault();
    setDropdownMenu(!dropdownMenu);
    setEditVisible((prevEditVisible) => (prevEditVisible === id ? null : id));
    // getChangeRequest();
  };

  const handleEdit = (id) => {
    setOpenDrawer(true);
    setInitPageLoading(true);
    APIGATEWAY.get(`project/changerequest/detail/${id}`, (response) => {
      if (response.success) {
        setInitPageLoading(false);

        const data = response.data.changeRequest;
        setChangeRequestId(id);
        setChangeRequestTitle(data?.title);
        setDescription(data?.description);
        setStartDate(data?.startDate);
        setEndDate(data?.endDate);
        setRequestId(data._id);
      }
    });
  };

  function projStatus(isTrue, response) {
    if (isTrue) {
      closeDrawer();
      // setStatus(response.data.project.status)
      getChangeRequest(id);
    }
  }

  const updateRequest = (data) => {
    setOpenUpdateDrawer(true);
    setRequestId(data._id);
  };

  // const cancelRequest = (data) => {
  //   let body={status:'cancelled'}
  //   // setOpenDrawer(true);
  //   setInitPageLoading(true);
  //   APIGATEWAY.patch(`project/changerequest/status/${data._id}`,body, (response) => {
  //     if (response.success) {
  //       setInitPageLoading(false);
  //       getChangeRequest()

  //     }
  //   });
  // };

  function createRequest(e) {
    setBtnLoader(true);
    e.preventDefault();
    if (!changeRequestTitle) {
      setBtnLoader(false);
      showError("Please enter title");
    } else if (!startDate) {
      setBtnLoader(false);
      showError("Please enter start date");
    } else if (!endDate) {
      setBtnLoader(false);
      showError("Please enter end date");
    } else {
      const body = {
        project: id,
        title: changeRequestTitle,
        description: description,
        startDate: startDate,
        endDate: endDate,
      };
      if (requestId) {
        APIGATEWAY.patch(
          `project/changerequest/${changeRequestId}`,
          body,
          (response) => {
            if (response.success) {
              message.success(response.message);
              closeDrawer(e);
              setBtnLoader(false);
              getChangeRequest();
            }
          }
        );
      } else {
        APIGATEWAY.post(`project/changerequest`, body, (response) => {
          if (response.success) {
            message.success(response.message);
            closeDrawer(e);
            setBtnLoader(false);
            getChangeRequest();
          }
        });
      }
    }
  }

  function checkStartDate(e) {
    if (!startDate) return e.isBefore(current_date);
    else if (startDate && !endDate) return e.isBefore(current_date);
    else if (startDate && endDate)
      return (
        e.isBefore(current_date) || e.isAfter(endDate.format("YYYY-MM-DD"))
      );
  }

  function checkEndDateDate(e) {
    if (!startDate) return e.isBefore(current_date);
    else if (startDate) return e.isBefore(startDate);
  }
  return (
    <div className="milestone-container">
      <div className="searchbar-container change_request ">
        <Search
          details={queryStatus}
          outerDivClassName="mr-16 w_20"
          placeholder="Search"
          searchClassName=""
          onSearchChange={onSearchChange}
        ></Search>
        <Filter
          occurenceData={occurenceData}
          occurenceName="occurence"
          occurenceSelect={queryStatus.date_filter}
          onOccurenceChange={handleOccurenceChange}
          statusData={statusData}
          statusName="status"
          statusSelect={queryStatus.status}
          statusOnChange={handleOnSearchChange}
          primaryButton="+ Add Request"
          primaryClick={addRequest}
        />
      </div>
      {loading ? (
        <div className="spinning-star1">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <table className="table-container">
            <thead>
              <tr className="tablehead-row">
                <th className="small-tablehead">Title</th>
                <th className="mid-tablehead">Start date</th>
                <th className="mid-tablehead">End date</th>
                <th className="mid-tablehead">Status</th>
                <th className="option-tablehead">{""}</th>
              </tr>
            </thead>

            {changeRequest?.length > 0 ? (
              <tbody ref={editRef1}>
                {changeRequest?.map((data) => (
                  <tr key={data._id} className="table-datarow">
                    <td className="table-datastitle">{data.title}</td>
                    <td className="data-startend">
                      {showLongDateFormat(data.startDate)}
                    </td>
                    <td className="data-startend">
                      {showLongDateFormat(data.endDate)}
                    </td>
                    <td
                      className="datasatus-color"
                      // style={{ color: getStatusColor(data.status) }}
                    >
                      {getStatusColor(data.status)}
                    </td>

                    <td className="table-action">
                      <div className="tax-add-edit">
                        <span className="ml-5 ">
                          <div className="dropdown-head">
                            {data.status !== "completed" &&
                              data.status !== "cancelled" && (
                                <div className="dropbtn-head">
                                  <img
                                    onClick={(e) =>
                                      handleEditClick(e, data?._id)
                                    }
                                    src={layer}
                                    alt="profile"
                                    className="menu-bar-icon"
                                  />
                                </div>
                              )}
                            {editVisible === data._id && dropdownMenu && (
                              <Fragment>
                                <div
                                  className="overflow-overlay"
                                  onClick={() => setDropdownMenu(false)}
                                ></div>
                                <div className="dropdown-content-heads">
                                  <div style={{ width: "170px" }}>
                                    {
                                      <div
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleEdit(data._id);
                                        }}
                                        className="d-flex_al_c action-menu-bar on_hover"
                                      >
                                        <div>
                                          <img
                                            alt="edit"
                                            style={{
                                              width: "15px",
                                              marginRight: "10px",
                                            }}
                                            src={edit}
                                          ></img>
                                        </div>
                                        <div className="light">
                                          <div className="m_0 p_0 anchor">
                                            Edit
                                          </div>
                                        </div>
                                      </div>
                                    }
                                    <div
                                      onClick={() => updateRequest(data)}
                                      className="d-flex_al_c action-menu-bar on_hover"
                                    >
                                      <div>
                                        <img
                                          alt="update status"
                                          style={{
                                            width: "15px",
                                            marginRight: "10px",
                                          }}
                                          src={updateicon}
                                        ></img>
                                      </div>
                                      <div className="light">
                                        <div className="m_0 p_0 anchor">
                                          Update Status
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Fragment>
                            )}
                          </div>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody className="empty-message">
                <tr>
                  <td colSpan="6">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          {changeRequest.length > 0 && (
            <TableField
              data={changeRequest}
              countprev={countprev}
              countnext={countnext}
              currentPage={currentPage}
              ClickPrev={() => {
                previous();
              }}
              ClickNext={() => next(currentPage)}
              total={total}
              overAll={totalDoc ?? 0}
              pagelimit={queryStatus.limit}
              pagelimitHandle={pageLimitOnchange}
            />
          )}
        </div>
      )}

      <Space>
        <div>
          <Drawer
            placement="right"
            open={openDrawer}
            onClose={() => closeDrawer()}
            className="tax-main-drawer"
          >
            {initPageLoading ? (
              <div className="spinning-star">
                <Spin size="large" />
              </div>
            ) : (
              <div className="tax-drawer">
                <div className="drawer-head">
                  <span className="add-tax">{"Update Status"}</span>
                  <span onClick={() => closeDrawer()} className="tax-close">
                    <img src={close} alt="close" />
                  </span>
                </div>
                <div style={{ margin: "40px 25px" }}>
                  <div className="tax-label">
                    <label className="mb_15">
                      Title<span className="mandatory-field">&#42;</span>
                    </label>
                    <div className="tax-label-input">
                      <input
                        type="text"
                        name="tax_name"
                        placeholder="Enter a Title"
                        autoComplete="off"
                        value={changeRequestTitle}
                        onChange={(e) => setChangeRequestTitle(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="tax-label">
                    <label>Description</label>
                    <div className="tax-label-input">
                      <textarea
                        placeholder="Add a text here..."
                        name="description"
                        autoComplete="off"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      >
                        {" "}
                      </textarea>
                    </div>
                  </div>

                  <div className="tax-label mb-24">
                    <label>
                      Start Date<span className="mandatory-field">&#42;</span>
                    </label>
                    <DatePicker
                      disabledDate={checkStartDate}
                      value={startDate !== "" ? moment(startDate) : ""}
                      onChange={(e) => setStartDate(e)}
                      style={{ width: "100%" }}
                      id="date-start"
                    />
                  </div>

                  <div className="tax-label mb-24 ">
                    <label htmlFor="date-end">
                      End Date<span className="mandatory-field">&#42;</span>
                    </label>
                    <DatePicker
                      disabledDate={checkEndDateDate}
                      value={endDate !== "" ? moment(endDate) : ""}
                      onChange={(e) => setEndDate(e)}
                      style={{ width: "100%" }}
                      id="date-end"
                    />
                  </div>
                  <div>
                    <TopHeader
                      // title="milestone"
                      backClick={closeDrawer}
                      classname="p_0"
                      submit={(e) => createRequest(e, requestId)}
                      // id={id}
                      spin={btnLoader}
                    />
                  </div>
                </div>
              </div>
            )}
          </Drawer>
        </div>
        <Drawer
          placement="right"
          open={openUpdateDrawer}
          onClose={closeDrawer}
          className="tax-main-drawer"
        >
          {id && (
            <UpdateProjectComponent
              statusData={updateStatusData}
              from={"changeRequest"}
              update={projStatus}
              projectId={requestId}
              onClose={closeDrawer}
            ></UpdateProjectComponent>
          )}
        </Drawer>
      </Space>
    </div>
  );
};

export default ChangeRequest;
