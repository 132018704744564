import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import isLoggedIn from "../constants/condition";
//Routers
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";

class MainRouter extends Component {
  render = () => {
    return isLoggedIn() ? (
      <Router>
        <PrivateRouter />
      </Router>
    ) : (
      <Router>
        <PublicRouter />
      </Router>
    );
  };
}

export default MainRouter;
