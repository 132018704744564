import { React, useState, useEffect, useCallback, useRef, Fragment } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { DatePicker, Drawer } from "antd";
import close from "../../assets/close_symbol.svg";
import updateicon from "../../assets/updateStatus.svg";
import TopHeader from "../../components/ui/TopHeader";
import { useParams } from "react-router-dom";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { message, Spin } from "antd";
import moment from "moment";
import { showDayMonthYear, projectColor,getStatusColor } from "../../constants/Utils";
import { Menu, Dropdown,Empty } from "antd";
import layer from "../../assets/Layer 2.svg";
import { Link } from "react-router-dom";
import UpdateProjectComponent from "./updateProjectComponent";

const ProjectOtherPage = (props) => {
  const meetingStatusOptions =[
    { value: "Completed", key: "completed" },
    { value: "Cancelled", key: "cancelled" },
  ]
  const referenceStatusOptions =[
    { value: "Active", key: "active" },
    { value: "Deleted", key: "deleted" },
  ]

  const [openMeetingStatusDrawer, setOpenMeetingStatusDrawer] = useState(false)
  const [openReviewStatusDrawer, setOpenReviewStatusDrawer] = useState(false)
  const [openReferenceStatusDrawer, setOpenReferenceStatusDrawer] = useState(false)


  const params = useParams();
  const paramsId = params.id;
  const projectId = props.project_id;
  const [visibleAddMeeting, setVisibleAddMeeting] = useState(false);
  const [visibleAddReview, setVisibleAddReview] = useState(false);
  const [visibleAddReference, setVisibleAddReference] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [meetingLoading, setMeetingLoading] = useState(false);
  const [reviewLoading, setReviewLoading] = useState(false);
  const [referenceLoading, setReferenceLoading] = useState(false);

  const [editVisible, setEditVisible] = useState(null);
  const editRef1 = useRef(null);
  const editRef2 = useRef(null);
  const editRef3 = useRef(null);

  const [nextMeetingAdd, setNextMeetingAdd] = useState({
    project: projectId,
    title: "",
    nextMeeting: "",
    description: "",
  });
  const [meetingId, setMeetingId] = useState(null);
  const [reviewId, setReviewId] = useState(null);
  const [referenceId, setReferenceId] = useState(null);

  const [nextReviewAdd, setNextReviewAdd] = useState({
    project: projectId,
    title: "",
    nextReview: "",
    description: "",
  });
  const [referenceAdd, setReferenceAdd] = useState({
    project: projectId,
    title: "",
    link: "",
    description: "",
  });
  const [meetingList, setMeetingList] = useState(null);
  const [reviewList, setReviewList] = useState(null);
  const [referenceList, setReferenceList] = useState(null);

  const queryObj = {
    page: "1",
    limit: "10",
    search_term: "",
    status: "all",
    date_filter: 'this_year'
  };

  // const getStatusColor = (status) => {
  //   if (status === "cancelled") {
  //     return "#EF4733";
  //   } else if (status === "pending") {
  //     return "#F8B146";
  //   }else if (status === "unpaid") {
  //       return "#F8B146";
  //   } else {
  //     return "#009C22";
  //   }
  // };

  useEffect(() => {
    getNextMeetingList();
    getNextReviewList();
    getReferenceList();

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      [editRef1, editRef2, editRef3].every(
        (ref) => ref.current &&  !event.target.classList.contains('menu-bar-icon')
      )
    ) {
      setEditVisible(false);
    }

  };

  // const handleClickOutside = (event) => {
  //   if (
  //     editRef1.current &&
  //     !editRef1.current.contains(event.target) &&
  //     editRef2.current &&
  //     !editRef2.current.contains(event.target) &&
  //     editRef3.current &&
  //     !editRef3.current.contains(event.target)
  //   ) {
  //     setEditVisible(false);
  //   }
  // };
  const getNextMeetingList = () => {
    setMeetingLoading(true)

    return new Promise((resolve, reject) => {
      const queryString = new URLSearchParams(queryObj).toString();

      APIGATEWAY.get(
        `project/meeting/${projectId}?${queryString}`,
        (response) => {
          if (response.success) {
            setMeetingLoading(false)

            setMeetingList(response.data.meetings);
          }
          resolve(response.data.meetings);
        }
      );
    });
  };

  const getNextReviewList = () => {
    setReviewLoading(true)

    return new Promise((resolve, reject) => {
      const queryString = new URLSearchParams(queryObj).toString();

      APIGATEWAY.get(
        `project/review/${projectId}?${queryString}`,
        (response) => {
          if (response.success) {
            setReviewLoading(false)

            setReviewList(response.data.reviews);
          }
          resolve(response.data.reviews);
        }
      );
    });
  };

  const getReferenceList = () => {
    setReferenceLoading(true)
    return new Promise((resolve, reject) => {
      const queryString = new URLSearchParams({...queryObj, status:'active'}).toString();

      APIGATEWAY.get(
        `project/reference/${projectId}?${queryString}`,
        (response) => {
          if (response.success) {
            setReferenceLoading(false)

            setReferenceList(response.data.references);
          }
          resolve(response.data.references);
        }
      );
    });
  };

  const statusTitleChange = (event, type) => {
    const titleip = event.target.value;
    if (type === "meeting")
      setNextMeetingAdd({ ...nextMeetingAdd, title: titleip });
    else if (type === "review")
      setNextReviewAdd({ ...nextReviewAdd, title: titleip });
    else setReferenceAdd({ ...referenceAdd, title: titleip });
  };

  const handleDateChange = (event, type) => {
    if (type === "meeting")
      setNextMeetingAdd({ ...nextMeetingAdd, nextMeeting: event });
    else setNextReviewAdd({ ...nextReviewAdd, nextReview: event });
  };

  const meetingDescriptionAdd = (event, type) => {
    if (type === "meeting")
      setNextMeetingAdd({ ...nextMeetingAdd, description: event.target.value });
    else if (type === "review")
      setNextReviewAdd({ ...nextReviewAdd, description: event.target.value });
    else setReferenceAdd({ ...referenceAdd, description: event.target.value });
  };

  const referenceLinkChange = (event) => {
    setReferenceAdd({ ...referenceAdd, link: event.target.value });
  };

  const handleClickShowDrawer = (type) => {
    if (type === "meeting") setVisibleAddMeeting(true);
    else if (type === "review") setVisibleAddReview(true);
    else if (type === "reference") setVisibleAddReference(true);
    // setVisibleAddMeeting(true);
  };

  const createOthers = (e, type, id) => {
    e.preventDefault();
    let body;

    if (type === "meeting") {
      body = {
        nextMeeting: moment(nextMeetingAdd.nextMeeting),
        project: projectId,
        ...nextMeetingAdd,
      };
    } else if (type === "review") {
      body = {
        nextReview: moment(nextReviewAdd.nextReview),
        project: projectId,
        ...nextReviewAdd,
      };
    } else
      body = {
        project: projectId,
        ...referenceAdd,
      };
    setBtnLoader(true);

    if (type && id) {
      APIGATEWAY.patch(`project/${type}/${id}`, body, (response) => {
        if (response.success) {
          message.success(`${type} updated successfully`);
          closeDrawer(e, type).then((res) => {
            setNextMeetingAdd({
              project: projectId,
              title: "",
              nextMeeting: "",
              description: "",
            });

            setReferenceAdd({
              project: projectId,
              title: "",
              link: "",
              description: "",
            });
            setNextReviewAdd({
              project: projectId,
              title: "",
              nextReview: "",
              description: "",
            });
            setBtnLoader(false);
            if(type==='meeting')   getNextMeetingList();
            else if (type==='review')  getNextReviewList();
            else  getReferenceList();
          });
        }
      });
    } else {
      // setDropdownMenu(false);
      APIGATEWAY.post(`project/${type}`, body, (response) => {
        if (response.success) {
          message.success(`${type} added successfully`);
          closeDrawer(e, type).then((res) => {
            setNextMeetingAdd({
              project: projectId,
              title: "",
              nextMeeting: "",
              description: "",
            });

            setReferenceAdd({
              project: projectId,
              title: "",
              link: "",
              description: "",
            });
            setNextReviewAdd({
              project: projectId,
              title: "",
              nextReview: "",
              description: "",
            });
            setBtnLoader(false);
            if(type==='meeting')   getNextMeetingList();
            else if (type==='review')  getNextReviewList();
            else  getReferenceList();
          });
        }
      });
    }
  };

  const closeDrawer = (e, type) => {
    setOpenMeetingStatusDrawer(false)
    setOpenReviewStatusDrawer(false)
    setOpenReferenceStatusDrawer(false)

    e && e.preventDefault();
    return new Promise((resolve, reject) => {



      if (type === "meeting") {
        setNextMeetingAdd({
          project: projectId,
          title: "",
          nextMeeting: "",
          description: "",
        });
        setVisibleAddMeeting(false);
      } else if (type === "review") {
        setReferenceAdd({
          project: projectId,
          title: "",
          link: "",
          description: "",
        });
        setVisibleAddReview(false);
      } else if (type === "reference") {
        setNextReviewAdd({
          project: projectId,
          title: "",
          nextReview: "",
          description: "",
        });
        setVisibleAddReference(false);
      }
      resolve(true);
    });
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    setEditVisible((prevEditVisible) => (prevEditVisible === id ? null : id));
  };

  const updateMeetingStatus = (data) => {
    setOpenMeetingStatusDrawer(true)
    setMeetingId(data._id)
  }

  function MeetingStatus(isTrue, response) {
    if (isTrue) {
      closeDrawer();
      setMeetingId(null)
      getNextMeetingList();
    }
  }

  const updateReviewStatus = (data) => {
    setOpenReviewStatusDrawer(true)
    setReviewId(data._id)
  }

  function ReviewStatus(isTrue, response) {
    if (isTrue) {
      closeDrawer();
      setReviewId(null)
      getNextReviewList();
    }
  }

  const updateReferenceStatus = (data) => {
    setOpenReferenceStatusDrawer(true)
    setReferenceId(data._id)
  }

  function ReferenceStatus(isTrue, response) {
    if (isTrue) {
      closeDrawer();
      setReferenceId(null)
      getReferenceList();
    }
  }

  const handleEdit = (id, type) => {
    if (type === "meeting") {
      APIGATEWAY.get(`project/meeting/detail/${id}`, (response) => {
        if (response.success) {
          setVisibleAddMeeting(true);
          const data = response.data.meeting;

          setNextMeetingAdd({
            project: data?.project,
            title: data?.title,
            nextMeeting: data?.nextMeeting,
            description: data?.description,
          });
          setMeetingId(data._id);
        }
      });
    } else if (type === "review") {
      APIGATEWAY.get(`project/review/detail/${id}`, (response) => {
        if (response.success) {
          setVisibleAddReview(true);
          const data = response.data.review;
          setNextReviewAdd({
            ...nextMeetingAdd,
            title: data.title,
            nextReview: data.nextReview,
            description: data.description,
          });
          setReviewId(data._id);
        }
      });
    } else if (type === "reference")
      APIGATEWAY.get(`project/reference/detail/${id}`, (response) => {
        if (response.success) {
          setVisibleAddReference(true);

          const data = response.data.reference;
          setReferenceAdd({
            ...referenceAdd,
            title: data.title,
            link: data.link,
            description: data.description,
          });
          setReferenceId(data._id);
        }
      });
  };

  return (
    <div className="Main-Container-Others">
      <div className="toptwo-containerslist">
        <div className="Sub-Container-Others">
          <div style={{}} className="Sub-ContainerNav-Bar-container">
            <div className="Sub-ContainerNav-Bar">
              <span>Next Meeting</span>
              <div className="subContainer-buttons">
                <button onClick={() => handleClickShowDrawer("meeting")}>
                  <AiOutlinePlus className="addbuttonPlusicon" />
                  Add Meeting
                </button>
                <Link
                  to={{
                    pathname: `/projects/detailspage/${paramsId}/meetinglist`,
                    state: projectId,
                  }}
                >
                  <button className="end-button">View All</button>
                </Link>
              </div>
            </div>
          </div>
          <main className="Sub-Table-Container">
          {meetingLoading? (
          <div className="spinning-star1">
            <Spin size="large" />
          </div>
        ) : (
            <table className="Sub-TableList">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Next Meeting Date</th>
                  <th>Status</th>
                  <th>{""}</th>
                </tr>
              </thead>
              {meetingList?.length> 0 ? (

              <tbody ref={editRef1}>
                {meetingList?.slice(0, 8).map((rowdata) => (
                  <tr className="tabledatarow-maintable" key={rowdata._id}>
                    <td className="maincolumn-firstrowcolumn">
                      {rowdata.title?.length <= 10
                        ? rowdata.title
                        : `${rowdata?.title?.slice(0, 10)}...` ?? "-"}
                    </td>
                    <td>{showDayMonthYear(rowdata?.nextMeeting) ?? "-"}</td>
                    {/* <td>
                      {(rowdata?.Description?.length <= 15
                        ? rowdata?.Description
                        : `${rowdata.Description?.slice(0, 15)}...`)}
                    </td> */}
                    <td>{getStatusColor(rowdata.status) ?? "-"}</td>
                    <td
                      className="table-name"
                      onClick={(e) => handleEditClick(e, rowdata._id)}
                    >
                      <div className="tax-add-edit">
                        <span className="ml-5">
                          <div className="dropdown-head">
                            <div className="dropbtn-head">
                              <img
                                src={layer}
                                alt="profile"
                                className="menu-bar-icon"
                              />
                            </div>
                            {editVisible === rowdata._id && (
                              <Fragment>
                                <div className="dropdown-content-heads">
                                  <div>
                                    <p
                                      className="action-menu-bar"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleEdit(rowdata._id, "meeting");
                                      }}
                                    >
                                      Edit
                                    </p>
                                  </div>
                                  <div onClick={() => updateMeetingStatus(rowdata)} className="d-flex_al_c action-menu-bar on_hover">
                                    {/* <img alt="update status" style={{width: "15px",marginRight: "10px",}} src={updateicon}></img> */}
                                    <div className="light"><div className="m_0 p_0">Update Status</div></div>
                                  </div>
                                </div>
                              </Fragment>
                            )}
                          </div>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              ):(
                <tbody className="empty-message">
                <tr>
                  <td colSpan="6">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </td>
                </tr>
              </tbody>
              )}
            </table>)}
          </main>
          <Drawer
            placement="right"
            open={visibleAddMeeting}
            onClose={(e) => closeDrawer(e, "meeting")}
            className="Milestones-main-drawer"
          >
            <div className="Milestone-drawer-container">
              <header>
                <h5 className="add-tax">Next Meeting</h5>
                {/* 
              <GrClose className="MilestoneClose-icon" />
             */}
                <span
                  onClick={(e) => closeDrawer(e, "meeting")}
                  className="MilestoneClose-icon"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={close}
                    className="MilestoneClose-img"
                    alt="close"
                  ></img>
                </span>
              </header>
              <form>
                <div className="tax-label">
                  <label className="mb_15 star">Title<span className="star">*</span></label>
                  <div className="tax-label-input">
                    <input
                      // disabled="true"
                      type="text"
                      name="tax_name"
                      placeholder="Enter a Title"
                      autoComplete="off"
                      value={nextMeetingAdd?.title}
                      onChange={(e) => statusTitleChange(e, "meeting")}
                    />
                  </div>
                </div>
                <div className="tax-label">
                  <label>Description</label>
                  <div className="tax-label-input">
                    <textarea
                      placeholder="Add a text here.."
                      name="description"
                      autoComplete="off"
                      value={nextMeetingAdd?.description}
                      onChange={(e) => meetingDescriptionAdd(e, "meeting")}
                    >
                      {" "}
                    </textarea>
                  </div>
                </div>
                <div className="tax-label mb_24">
                  <label>Next Meeting Date<span className="star">*</span></label>
                  <DatePicker
                    value={
                      nextMeetingAdd.nextMeeting !== ""
                        ? moment(nextMeetingAdd.nextMeeting)
                        : ""
                    }
                    onChange={(e) => handleDateChange(e, "meeting")}
                    style={{ width: "100%" }}
                    id="date-start"
                  />
                </div>
                <div>
                  <TopHeader
                    type="meeting"
                    backClick={(e) => closeDrawer(e, "meeting")}
                    classname="p_0"
                    submit={(e) => {
                      createOthers(e, "meeting", meetingId);
                    }}
                    // submit={createOthers}

                    // id={projectId}
                    spin={btnLoader}
                  />
                </div>
              </form>
            </div>
          </Drawer>
        </div>
        <div className="Sub-Container-Others">
          <div className="Sub-ContainerNav-Bar-container">
            <div className="Sub-ContainerNav-Bar">
              <span>Next Review</span>
              <div className="subContainer-buttons">
                <button onClick={() => handleClickShowDrawer("review")}>
                  <AiOutlinePlus className="addbuttonPlusicon" />
                  Add Review
                </button>
                <Link
                  to={{
                    pathname: `/projects/detailspage/${paramsId}/reviewlist`,
                    state: projectId,
                  }}
                >
                  {" "}
                  <button className="end-button">View All</button>
                </Link>
              </div>
            </div>
          </div>
          <main className="Sub-Table-Container">
          {reviewLoading? (
          <div className="spinning-star1">
            <Spin size="large" />
          </div>
        ) : (
            <table className="Sub-TableList">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Next Review Date</th>
                  <th>Status</th>
                  <th className="option-tablecolumn">{""}</th>
                </tr>
              </thead>

              {reviewList?.length> 0 ? (

              <tbody ref={editRef2}>
                {reviewList?.slice(0, 8).map((rowdata) => (
                  <tr className="tabledatarow-maintable" key={rowdata._id}>
                    <td className="maincolumn-firstrowcolumn">
                      {rowdata.title?.length <= 10
                        ? rowdata.title
                        : `${rowdata.title?.slice(0, 10)}...`}
                    </td>
                    <td>{showDayMonthYear(rowdata.nextReview) ?? "-"}</td>
                    <td>{getStatusColor(rowdata.status) ?? "-"}</td>
                    <td
                      className="table-name"
                      onClick={(e) => handleEditClick(e, rowdata._id)}
                    >
                      <div className="tax-add-edit">
                        <span className="ml-5">
                          <div className="dropdown-head">
                            <div className="dropbtn-head">
                              <img
                                src={layer}
                                alt="profile"
                                className="menu-bar-icon"
                              />
                            </div>
                            {editVisible === rowdata._id && (
                              <Fragment>
                                <div className="dropdown-content-heads">
                                  <div>
                                    <p
                                      className="action-menu-bar"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleEdit(rowdata._id, "review");
                                      }}
                                    >
                                      Edit
                                    </p>
                                  </div>
                                  <div onClick={() => updateReviewStatus(rowdata)} className="d-flex_al_c action-menu-bar on_hover">
                                    {/* <img alt="update status" style={{width: "15px",marginRight: "10px",}} src={updateicon}></img> */}
                                    <div className="light"><div className="m_0 p_0">Update Status</div></div>
                                  </div>
                                </div>
                              </Fragment>
                            )}
                          </div>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              ):(
                <tbody className="empty-message">
                <tr>
                  <td colSpan="6">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </td>
                </tr>
              </tbody>

              )}
            </table>)}
          </main>
          <Drawer
            placement="right"
            open={visibleAddReview}
            onClose={(e) => closeDrawer(e, "review")}
            className="Milestones-main-drawer"
          >
            <div className="Milestone-drawer-container">
              <header>
                <h5 className="add-tax">Next Review</h5>
                {/* 
              <GrClose className="MilestoneClose-icon" />
             */}
                <span
                  onClick={(e) => closeDrawer(e, "review")}
                  className="MilestoneClose-icon"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={close}
                    className="MilestoneClose-img"
                    alt="close"
                  ></img>
                </span>
              </header>
              <form>
                <div className="tax-label">
                  <label className="mb_15">Title<span className="star">*</span></label>
                  <div className="tax-label-input">
                    <input
                      // disabled="true"
                      type="text"
                      name="tax_name"
                      placeholder="Enter a Title"
                      autoComplete="off"
                      value={nextReviewAdd.title}
                      onChange={(e) => statusTitleChange(e, "review")}
                    />
                  </div>
                </div>
                <div className="tax-label">
                  <label>Description</label>
                  <div className="tax-label-input">
                    <textarea
                      placeholder="Add a text here.."
                      name="description"
                      autoComplete="off"
                      value={nextReviewAdd.description}
                      onChange={(e) => meetingDescriptionAdd(e, "review")}
                    >
                      {" "}
                    </textarea>
                  </div>
                </div>
                <div className="tax-label mb_24">
                  <label>Next Review Date<span className="star">*</span></label>
                  <DatePicker
                    value={
                      nextReviewAdd.nextReview !== ""
                        ? moment(nextReviewAdd.nextReview)
                        : ""
                    }
                    onChange={(e) => handleDateChange(e, "review")}
                    style={{ width: "100%" }}
                    id="date-start"
                  />
                </div>
                <div>
                  <TopHeader
                    type="meeting"
                    backClick={(e) => {
                      closeDrawer(e, "review");
                    }}
                    classname="p_0"
                    submit={(e) => {
                      createOthers(e, "review", reviewId);
                    }}
                    // id={projectId}
                    spin={btnLoader}
                  />
                </div>
              </form>
            </div>
          </Drawer>
        </div>
      </div>
      <div className="bottomleft-table">
        <div className="Sub-Container-Others">
          <div className="Sub-ContainerNav-Bar-container">
            <div className="Sub-ContainerNav-Bar">
              <span>Reference</span>
              <div className="subContainer-buttons">
                <button onClick={() => handleClickShowDrawer("reference")}>
                  <AiOutlinePlus className="addbuttonPlusicon" />
                  Add Reference
                </button>
                <Link
                  to={{
                    pathname: `/projects/detailspage/${paramsId}/referencelist`,
                    state: projectId,
                  }}
                >
                  {" "}
                  <button className="end-button">View All</button>
                </Link>
              </div>
            </div>
          </div>
          <main className="Sub-Table-Container">
          {referenceLoading? (
          <div className="spinning-star1">
            <Spin size="large" />
          </div>
        ) : (
            <table className="Sub-TableList">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Link</th>
                  <th>{""}</th>
                </tr>
              </thead>
              {referenceList?.length> 0 ? (

              <tbody ref={editRef3}>
                {referenceList?.slice(0, 8).map((rowdata) => (
                  <tr className="tabledatarow-maintable" key={rowdata._id}>
                    <td className="maincolumn-firstrowcolumn">
                      {rowdata.title?.length <= 10
                        ? rowdata.title
                        : `${rowdata.title?.slice(0, 10)}...`}
                    </td>
                    <td>{getStatusColor(rowdata.status) ?? "-"}</td>
                    <td>
                      {rowdata.link?.length <= 15
                        ? rowdata.link
                        : `${rowdata.link?.slice(0, 15)}...`}
                    </td>
                    <td
                      className="table-name"
                      onClick={(e) => handleEditClick(e, rowdata._id)}
                    >
                      <div className="tax-add-edit">
                        <span className="ml-5">
                          <div className="dropdown-head">
                            <div className="dropbtn-head">
                              <img
                                src={layer}
                                alt="profile"
                                className="menu-bar-icon"
                              />
                            </div>
                            {editVisible === rowdata._id && (
                              <Fragment>
                                <div className="dropdown-content-heads">
                                  <div className="light">
                                    <p
                                      className="action-menu-bar"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleEdit(rowdata._id, "reference");
                                      }}
                                    >
                                      Edit
                                    </p>
                                  </div>
                                  <div onClick={() => updateReferenceStatus(rowdata)} className="d-flex_al_c action-menu-bar on_hover">
                                    {/* <img alt="update status" style={{width: "15px",marginRight: "10px",}} src={updateicon}></img> */}
                                    <div className="light"><div className="m_0 p_0">Update Status</div></div>
                                  </div>
                                </div>
                              </Fragment>
                            )}
                          </div>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              ):(
                <tbody className="empty-message">
                <tr>
                  <td colSpan="6">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </td>
                </tr>
              </tbody>

              )}
            </table>)}
          </main>
          <Drawer
            placement="right"
            open={visibleAddReference}
            onClose={(e) => closeDrawer(e, "reference")}
            className="Milestones-main-drawer"
          >
            <div className="Milestone-drawer-container">
              <header>
                <h5 className="add-tax">References</h5>
                {/* 
              <GrClose className="MilestoneClose-icon" />
             */}
                <span
                  onClick={(e) => closeDrawer(e, "reference")}
                  className="MilestoneClose-icon"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={close}
                    className="MilestoneClose-img"
                    alt="close"
                  ></img>
                </span>
              </header>
              <form>
                <div className="tax-label">
                  <label className="mb_15">Title<span className="star">*</span></label>
                  <div className="tax-label-input">
                    <input
                      // disabled="true"
                      type="text"
                      name="tax_name"
                      placeholder="Enter a Title"
                      autoComplete="off"
                      value={referenceAdd.title}
                      onChange={(e) => statusTitleChange(e, "reference")}
                    />
                  </div>
                </div>
                <div className="tax-label">
                  <label>Description</label>
                  <div className="tax-label-input">
                    <textarea
                      placeholder="Add a text here.."
                      name="description"
                      autoComplete="off"
                      value={referenceAdd.description}
                      onChange={(e) => meetingDescriptionAdd(e, "reference")}
                    >
                      {" "}
                    </textarea>
                  </div>
                </div>
                <div className="tax-label mb_24">
                  <label> Link<span className="star">*</span></label>
                  <div className="tax-label-input">
                    <input
                      // disabled="true"
                      type="text"
                      name="tax_name"
                      placeholder="Enter link"
                      autoComplete="off"
                      value={referenceAdd.link}
                      onChange={(e) => referenceLinkChange(e)}
                    />
                  </div>
                </div>

                <div>
                  <TopHeader
                    type="meeting"
                    backClick={(e) => closeDrawer(e, "reference")}
                    classname="p_0"
                    submit={(e) => {
                      createOthers(e, "reference", referenceId);
                    }}
                    // id={projectId}
                    spin={btnLoader}
                  />
                </div>
              </form>
            </div>
          </Drawer>
          <Drawer placement="right" open={openMeetingStatusDrawer} onClose={closeDrawer} className="tax-main-drawer" >
            <UpdateProjectComponent statusData={meetingStatusOptions} from={"meeting"} update={MeetingStatus} id={meetingId} onClose={closeDrawer} ></UpdateProjectComponent>
          </Drawer>
          <Drawer placement="right" open={openReviewStatusDrawer} onClose={closeDrawer} className="tax-main-drawer" >
            <UpdateProjectComponent statusData={meetingStatusOptions} from={"review"} update={ReviewStatus} id={reviewId} onClose={closeDrawer} ></UpdateProjectComponent>
          </Drawer>
          <Drawer placement="right" open={openReferenceStatusDrawer} onClose={closeDrawer} className="tax-main-drawer" >
            <UpdateProjectComponent statusData={referenceStatusOptions} from={"reference"} update={ReferenceStatus} id={referenceId} onClose={closeDrawer} ></UpdateProjectComponent>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default ProjectOtherPage;
