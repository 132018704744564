import React, { Component } from "react";

import {
  Input,
  Spin,
  Divider,
  Tag,
  Select,

} from "antd";
import "antd/dist/antd.css";
import "../../stylesheets/styles.css";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import {
  showError,
  showSuccess,
  isErrorTxt,
} from "../../constants/Utils";
import TopHeader from "../../components/ui/TopHeader";
import InputItem from '../../components/ui/InputItem';
import DateItem from '../../components/ui/DateItem';
import SelectItem from '../../components/ui/SelectItem';
import { TweenOneGroup } from "rc-tween-one";
import moment from "moment";
import SelectIcon from "../../assets/Vector.svg";

const { Option } = Select;

class AddAccessory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      id: this.props.match.params.id,
      data: [],
      name: "",
      purchase_date: "",
      item: "",
      price: "",
      accessoryType: "",
      warranty_expired: "",
      specificationss: [],
      inputValue: "",
      tags: [],
      inputVisible: false,
      employeeList: [],
      employee: {
        emp_id: "",
        name: "",
      },
      vendor: {
        vendor_id: "",
        name: "",
      },
      vendorList: [],
      accessory_id: "",
      accessory_type_data:[
        {
          value:'Laptop',key:'laptop'
        },
        {
          value:'Mobile',key:'mobile'

        }
      ],
     
      add_edit_loader: false,
    };
  }

  componentDidMount = () => {
    this.handleTable();
  };
  handleTable = () => {
    let self = this;
    const { id } = self.state;
    APIGATEWAY.get("accessories/" + id, (response) => {
      if (response.success) {
        let res = response.data.Accessory;

        self.setState({
          data: res,
          name: res.name,
          item: res.item,
          price: res.price,
          accessory_id: res.acc_id,
          purchase_date: moment(res.purchase_date),
          specificationss: res.specifications,
          tags: res.specifications,
          accessoryType: res.type,
          employee: {
            emp_id: res.employee?.emp_id,
            name: res.employee?.name,
          },
          vendor: {
            vendor_id: res.vendor?.vendor_id,
            name: res.vendor?.name,
          },
          warranty_expired: res.warranty_expired,
          loading:false
        });
      }
    });

    APIGATEWAY.get("employees/all", (response) => {
      let employees = response.data.employees;
      this.setState({ employeeList: employees });
    });
    APIGATEWAY.get("vendors", (response) => {
      if (response.success) {
        let Vendor = response.data.vendor;
        this.setState({
          vendorList: Vendor,
        });
      }
    });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  handleOnFromChange = (e) => {
    this.updateStateValues("purchase_date", e);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleNumberChange = (event) => {
    let REGEX = /^\d+$/;
    if (event.target.value === "" || REGEX.test(event.target.value))
      this.setState({ [event.target.name]: event.target.value });
  };

  back = () => {
    this.props.history.goBack();
  };

  Apply = () => {
    let self = this;
    const { id } = self.state;
    const {
      item,
      price,
      purchase_date,
      accessoryType,
      employee,
      warranty_expired,
      vendor,
      accessory_id,
    } = self.state;

    if (isErrorTxt(item)) {
      showError("Please enter item name");
    }else if(isErrorTxt(price)){
      showError("Please enter price");
    } else if (isErrorTxt(purchase_date)) {
      showError("Select purchase date");
    } else if (isErrorTxt(accessoryType)) {
      showError("Select type of accessory");
    } else if (isErrorTxt(accessory_id)) {
      showError("Please enter accessory id");
    }
   
    else {
      this.setState({ add_edit_loader: true });
      let data = {
        item: item,
        price: price,

        purchase_date: purchase_date,

        specifications: this.state.tags,
        type: accessoryType,
        employee: {
          emp_id: employee.emp_id,
          name: employee.name,
        },
        vendor: {
          vendor_id: vendor.vendor_id,
          name: vendor.name,
        },
        warranty_expired: warranty_expired,
        acc_id: this.state.accessory_id,
      };
      if (id) {
        APIGATEWAY.patch("accessories/" + id, data, (response) => {
          if (response.success) {
            showSuccess("successfully updated");
            this.setState({ add_edit_loader: false });
            this.props.history.push("/accessories");
          }
        });
      } else {
        APIGATEWAY.post("accessories", data, (response) => {
          if (response.success) {
            showSuccess("succesfully created");
            this.setState({ add_edit_loader: false });
            this.props.history.push("/accessories");
          }
        });
      }
    }
  };

  typeSelect = (value) => {
    this.setState({ accessoryType: value });
  };
  employeeSelect = (value, key) => {
    this.setState(() => ({
      employee: {
        name: key.value,
        emp_id: key.key,
      },
    }));
  };
  vendorSelect = (value, key) => {
    this.setState(() => ({
      vendor: {
        vendor_id: key.key,
        name: key.value,
      },
    }));
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue === "") {
      showError("Enter Specification");
    } else if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    this.setState({
      tags,
      inputVisible: false,
      inputValue: "",
    });
  };
  saveInputRef = (input) => {
    this.input = input;
  };
  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);
    this.setState({ tags });
  };
  forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          this.handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };
  render() {
    const {
      loading,
      id,
      purchase_date,
      item,
      price,
      inputValue,
      tags,
      inputVisible,
      accessoryType,
      employee,
      employeeList,
      warranty_expired,
      vendorList,
      vendor,
      accessory_type_data
    } = this.state;

    const tagChild = tags.map(this.forMap);
    return loading && id ? (
      <div className="flex-cen wh-100">
        <Spin size="large" />
      </div>
    ) : (
      <>
        <div>
          <TopHeader
            title="Accessories"
            submit={this.Apply}
            backClick={this.back}
            id={id}
            spin={this.state.add_edit_loader}
          />

          <div className="main-content2">
            <div className="job_li">
              <div className="job_pers">
              <InputItem label='Item' name='item' value={item} star={true} placeholder="Item" onChange={(e) => this.handleChange(e)} />
               
              </div>
              <div className="job_pers">
              <InputItem label='Price' name='price' value={price} star={true} placeholder="Price" onChange={this.handleNumberChange} />

              </div>
              <div className="job_pers">
              <DateItem label='Date' name='purchase_date' star={true} value={purchase_date} placeholder="Date" onChange={this.handleOnFromChange} />
              </div>
            </div>
            <div className="job_li">
              <div className="job_pers" >
              <SelectItem label='Type' star={true} value={accessoryType} placeholder="Status" data={accessory_type_data}  accessType={true} onChange={this.typeSelect} />

              </div>
              <div className="job_pers">


                <p className="interview-date">Name</p>
                <Select
                  showSearch
                  getPopupContainer={(trigger) => trigger.parentNode}
                  value={employee.name}
                  onChange={this.employeeSelect}
                 
                  className="select-dropdown-menus-access"
                  suffixIcon={
                    <div>
                      <img src={SelectIcon} alt="" width="10px" />
                   </div>
                      }
                >
                  <Option value="">Select</Option>
                  {employeeList.map((employee, i) => (
                    <Option value={employee.name} key={employee.emp_id}>
                      {employee.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="job_pers">
              <InputItem label='Warranty expired' name='warranty_expired' value={warranty_expired} star={false} placeholder="Warranty expired" onChange={(e) => this.handleChange(e)} />

              </div>
            </div>
            <div className="job_li">
              <div className="job_pers">
                <p className="interview-date">Vendor</p>
                <Select
                  showSearch
                  getPopupContainer={(trigger) => trigger.parentNode}
                  value={vendor.name ||undefined}
                  onChange={this.vendorSelect}
                  // style={{ width: "100%" }}
                  className="select-dropdown-menus-access"
                  placeholder='Select by'
                  suffixIcon={
                    <div>
                      <img src={SelectIcon} alt="" width="10px" />
                   </div>
                      }
                >
                  {vendorList.map((vendor, i) => (
                    <Option value={vendor.name} key={vendor.vendor_id}>
                      {vendor.name}
                    </Option>
                  ))}
                </Select>
              </div>

              <div className="job_pers">
              <InputItem label='Accessory Id' name='accessory_id' value={this.state.accessory_id} star={true} placeholder="Accessory Id" onChange={(e) => this.handleChange(e)} />
               
              </div>
              <div className="job_pers"></div>
            </div>

            <Divider orientation="left">Specifications</Divider>

            <div className="sm__grid--col-4" style={{ display: "flex" }}>
              {!inputVisible && (
                <div style={{ display: "flex" }}>
                  <Tag onClick={this.showInput} className="site-tag-plus">
                    <i className="fa fa-plus add-icon"></i>
                    <span>New Tag</span>
                  </Tag>
                </div>
              )}
              {inputVisible && (
                <Input
                  ref={this.saveInputRef}
                  type="text"
                  size="small"
                  className="client-field"
                  value={inputValue}
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputConfirm}
                  onPressEnter={this.handleInputConfirm}
                />
              )}
              <div>
                <TweenOneGroup
                  enter={{
                    onComplete: (e) => {
                      e.target.style = "";
                    },
                  }}
                  appear={false}
                  className="tag-client"
                >
                  {tagChild}
                </TweenOneGroup>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AddAccessory;
