import React, { Component } from "react";
import "antd/dist/antd.css";
import "../stylesheets/styles.css";
import APIGATEWAY from "../ApiService/ApiGateWay/apiGateWay";
import { Input, message, Spin, Modal, Empty } from "antd";
import {
  showError,
  isInvalidName,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
} from "../constants/Utils";
import TopHeader from "../components/ui/TopHeader";
import { STORAGE, UPDATE } from "../constants/roleStore";
import TableField from "../components/ui/tableField";
import InputItem from "../components/ui/InputItem";
import layer from "../assets/Layer 2.svg";

import { FormOutlined } from "@ant-design/icons";
import { TECHNOLOGIESACCESS } from "../components/ui/constants";
export default class TechnologyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      techList: [],
      search: "",
      currentPage: 1,
      total: "",
      pageSize: 10,
      loading: true,
      modalOpen: false,
      editmodal: false,
      name: "",
      tech_id: "",
      Tableloading: false,
      modal1Visible: false,
      app_name: "",
      app_type: "",
      queryStatus: {},
      dropdown_menu: false,
      list_index: "",
      update: false,
    };
    this.wrapperRef = React.createRef();
  }
  componentDidMount() {
    let name = "";

    let storage = JSON.parse(STORAGE.view);

    name = storage?.filter((app) => app?.name === "My Business");
    let value = name;
    value?.filter((app) => {
      let names = app?.sub_roles?.filter(
        (role) => role.name === "Technologies"
      );
      names &&
        names.map((el) =>
          this.setState({
            update: el?.permissions?.update,
          })
        );
      this.setState({
        app_name: names,
      });
    });
    this.setState({
      app_type: STORAGE.type,
    });
    this.setQueryStatus();

    this.reloadPage();
    document.addEventListener("click", this.clickOutside);
  }

  componentWillUnmount = () => {
    document.removeEventListener("click", this.clickOutside);
  };
  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.setQueryStatus();
        }
      });
    });
  };
  setModal1Visible = (modal1Visible) => {
    this.setState({ modal1Visible, name: "", tech_id: "" });
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",

          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.callTechnologyAPI();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.callTechnologyAPI();
        }
      );
    }
  };

  handleFilter = () => {
    let { currentPage, pageSize } = this.state;

    let query = returnSearchObject(this.props.location.search);

    query.page = currentPage ?? "";
    query.limit = pageSize ?? "";

    this.setState(
      {
        queryStatus: query,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.callTechnologyAPI();
      }
    );
  };
  callTechnologyAPI = () => {
    APIGATEWAY.get(
      "technologies" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          this.setState({
            techList: response.data.technologies,
            total: response.data?.technologies?.length,
            loading: false,
            Tableloading: false,
            totalDoc: response.data?.total,
          });
        } else {
          this.setState({ loading: true });
        }
      }
    );
  };

  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };
  next = (prev) => {
    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.callTechnologyAPI();
    });
  };
  handleaddCancel = () => {
    this.setState({ modalOpen: false });
  };
  handleEditCancel = () => {
    this.setState({ editmodal: false });
  };
  handleOk = () => {
    let { name } = this.state;
    if (name === undefined || name.trim() === "") {
      showError("Please enter name");
    } else if (name.length <= 3) {
      showError("Name should contain atleast 3 characters");
    } else if (isInvalidName(name)) {
      showError("Name should contain letters only");
    } else {
      let data = {
        name: name,
      };
      APIGATEWAY.post("technologies", data, (response) => {
        if (response.success) {
          this.setState({ modal1Visible: false });
          message.success(response.message);
          this.callTechnologyAPI();
        } else {
          message.error(response.message);
        }
      });
    }
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleEditPage = (username) => {
    this.setState({
      modal1Visible: true,
      name: username.name,
      tech_id: username.tech_id,
    });
    this.technologyDetail(username);
  };
  technologyDetail = (id) => {
    APIGATEWAY.get("technologies/" + id, (response) => {
      if (response.success) {
        let technology = response.data.technology;
        this.setState({
          name: technology.name,
          tech_id: technology.tech_id,
        });
      }
    });
  };
  handleEditOk = (id) => {
    let self = this;
    let { name } = this.state;
    if (name === undefined || name.trim() === "") {
      showError("Please enter Name");
    } else if (name.length <= 3) {
      showError("Name should contain atleast 3 characters");
    } else {
      let data = {
        name: name,
      };
      APIGATEWAY.patch("technologies/" + id, data, (response) => {
        if (response.success) {
          self.setState({ modal1Visible: false });
          self.callTechnologyAPI();
          message.success(response.message);
        } else {
          message.error(response.message);
        }
      });
    }
  };
  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      list_index: index,
    });
  };
  render() {
    let {
      total,
      techList,
      currentPage,
      name,
      tech_id,
      app_type,
      app_name,
      pageSize,
    } = this.state;
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }

    let columns = [
      {
        title: "S.No",
        dataIndex: "S.No",

        render: (key, item, index) => (
          <div key={index} className="serials">
            {(this.state.currentPage - 1) * this.state.pageSize + (index + 1)}
          </div>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (key) => <div>{key}</div>,
      },
      {
        title: "",
        dataIndex: "tech_id",
        align: "center",
        render: (tech_id, object) => (
          <p onClick={() => this.handleEditPage(object)}>
            {UPDATE(app_type, app_name, <FormOutlined className="editicon" />)}
          </p>
        ),
      },
    ];
    return (
      <div className="">
        <div>
          <TopHeader
            title="Technologies"
            modals={() => this.setModal1Visible(true)}
            type={app_type}
            permission={app_name}
          />
          <div style={{ padding: "0px 23px 30px 30px" }}>
            {this.state.loading ? (
              <div className="spinning-star">
                <Spin size="large" />
              </div>
            ) : (
              <div style={{ margin: "0px 0px, 100px, 0px" }}>
                <div className="table_page_wrap">
                  {techList.length > 0 ? (
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th className="">S.No</th>
                          <th className="">Name</th>

                          <th></th>
                        </tr>
                      </thead>

                      {techList.length > 0 ? (
                        <tbody ref={this.wrapperRef}>
                          {techList.map((tech, r) => (
                            <tr key={r}>
                              <td className="">
                                {" "}
                                {(this.state.currentPage - 1) *
                                  this.state.pageSize +
                                  (r + 1)}
                              </td>
                              <td className="table-name text-capitalize">
                                {tech.name}
                              </td>

                              {TECHNOLOGIESACCESS.update && (
                                <td className="table-action">
                                  <div
                                    className="tax-add-edit"
                                    onClick={() => this.dropdownClick(r)}
                                  >
                                    <span className="ml-5">
                                      <div className="dropdown-head">
                                        <div className="dropbtn-head">
                                          <img
                                            src={layer}
                                            alt="profile"
                                            className="menu-bar-icon"
                                          />
                                        </div>
                                        {this.state.list_index === r &&
                                          this.state.dropdown_menu && (
                                            <div className="dropdown-content-heads">
                                              <div>
                                                <p
                                                  className="action-menu-bar"
                                                  onClick={() =>
                                                    this.handleEditPage(tech)
                                                  }
                                                >
                                                  Edit
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        ""
                      )}
                    </table>
                  ) : (
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th className="">S.No</th>
                          <th className="">Name</th>

                          <th></th>
                        </tr>
                      </thead>

                      <tbody className="empty-message">
                        <tr>
                          <td colspan="5">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {techList.length > 0 && (
                    <TableField
                      data={techList}
                      countprev={countprev}
                      countnext={countnext}
                      currentPage={currentPage}
                      ClickPrev={() => this.previous()}
                      ClickNext={() => this.next(this.state.currentPage)}
                      total={total}
                      overAll={this.state.totalDoc ?? 0}
                      pagelimit={this.state.pageSize}
                      pagelimitHandle={this.pageLimitOnchange}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <Modal
            title={tech_id !== "" ? "Edit Technology" : "Add Technology"}
            open={this.state.modal1Visible}
            onOk={() => this.setModal1Visible(false)}
            onCancel={() => this.setModal1Visible(false)}
            footer={[
              <div className="d-flex justify-content-between align-items-center">
                <button
                  key="cancel"
                  className="dec-btn-secondary"
                  onClick={() => this.setModal1Visible(false)}
                >
                  Cancel
                </button>

                <button
                  key="submit"
                  className="dec-btn"
                  onClick={
                    tech_id !== ""
                      ? () => this.handleEditOk(tech_id)
                      : () => this.handleOk()
                  }
                >
                  {tech_id !== "" ? "Update" : "Submit"}
                </button>
              </div>,
            ]}
          >
            <InputItem
              label="Name"
              name="name"
              value={name}
              star={false}
              placeholder="Enter the Platform"
              onChange={this.handleInputChange}
            />
          </Modal>
        </div>
      </div>
    );
  }
}
