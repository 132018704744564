import React, { Component } from "react";
import "../../stylesheets/styles.css";
import { Input,  Button, Card, message } from "antd";

import InputField from "../../components/ui/InputField";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";

import { showError } from "../../constants/Utils";
//Utils



export default class AddSalary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salary: "",
      id: true,
      amount: "",
      type: "",
      employee: "",
    };
  }
  searchInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleClick = () => {
    this.props.history.push("/salaries");
  };
  searchOption = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleOnInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount() {
    this.handleTable();
  }

  handleTable = () => {
    APIGATEWAY.get("salaries/", function (response) {
      if (response) {
        this.setState({
          data: response.data.salaries,
          total: response.data.total,
          employee: response.data.employee.emp_id,
        });
      }
    });
  };
  sumbit = () => {

    let { salary, type, amount, employee } = this.state;
    if (salary === "") {
      showError("Please enter the salary");
    } else if (type ==="") {
      showError("Please select the type");
    } else if (amount === "") {
      showError("Please enter the amount");
    } else {
       let id=employee;
      let data = {
        salary: salary,
        type: type,
        amount: amount,
      };
      
      APIGATEWAY.post(`salaries/create/${id}`, data, (res) => {
        if (res.success) {
          message.success(res.message);
        } else {
          message.error(res.message);
        }
      });
    }
  };
  render() {
    const {  salary, amount, type } = this.state;
    return (
      <div>
        <Card
          title="Add Salary"
          extra={
            <div className="button-list">
              <div className="bread">
                <div>
                  <Button
                    className="btnClick"
                    onClick={() => {
                      this.handleClick();
                    }}
                  >
                    Back
                  </Button>
                </div>
                <button
                  type="primary"
                  onClick={() => {
                    this.sumbit();
                  }}
                >
                  Add Salary
                </button>
              </div>
            </div>
          }
        >
          <div className="sm__grid--col-4">
            <div className="field-holder">
              <InputField
                name="salary"
                value={salary}
                label="Salary*"
                placeholder="Enter the Salary"
                handleChange={this.handleOnInputChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Input.Group compact style={{ display: "flex" }}>
                <select
                  style={{ width: "33%" }}
                  className="dropdown-employee"
                  name="type"
                  onChange={(e) => this.searchOption(e)}
                  value={type}
                >
                  <option value="">type</option>
                  <option value="initial">Initial</option>
                  <option value="hike">Hike</option>
                </select>
                <Input
                  style={{ width: "65%", height: "36px" }}
                  placeholder="Enter the amount..."
                  name="amount"
                  value={amount}
                  onChange={(e) => this.searchInput(e)}
                />
              </Input.Group>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
