import React, { Component } from "react";
import { Input, Typography } from "antd";

class InputField extends Component {
  render() {
    const {
      label,
      name,
      value,
      placeholder,
      handleChange,
      disabled,
      index,
      type,
      style,
      maxLength,
    } = this.props;
    return (
      <div className="flex-col " style={style ? style : {}}>
        <Typography.Text className="mb-5 three-dots">{label}</Typography.Text>
        {type && type === "textarea" ? (
          <Input.TextArea
            placeholder={placeholder}
            maxLength={250}
            showCount
            name={name}
            value={value}
            onChange={(event) =>
              index ? handleChange : handleChange(event, index)
            }
            disabled={disabled}
          />
        ) : (
          <Input
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={(event) =>
              index ? handleChange : handleChange(event, index)
            }
            disabled={disabled}
            maxLength={maxLength ? maxLength : undefined}
          />
        )}
      </div>
    );
  }
}

export default InputField;
