import React, { Component } from "react";
import { SidebarData } from "./routes";
import { SidebarRouter } from "./PathRouter";

// Pages - 404 Page
import PageNotFound from "../pages/404Page";
import { Route, Switch, Redirect } from "react-router-dom";
import { Layout } from "antd";
import "antd/dist/antd.css";

import { STORAGE, UPDATE } from "../constants/roleStore";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: SidebarData,
      data: "",
      pathrouter: "",
    };
  }

  componentWillMount = () => {
    this.handleModule();
  };

  handleModule = () => {
    const showApps = STORAGE?.view && JSON.parse(STORAGE?.view ?? "");
    const userType = STORAGE?.type ?? "";
    let subnavtitle = showApps?.map((module) => module?.sub_roles ?? "");
    let subName = [];
    subnavtitle?.map((sub) => {
      if (sub) {
        sub.forEach((obj) => {
          return subName.push(obj.name);
        });
      }
    });

    const result =
      userType === "sub_admin" && showApps?.map((module) => module.name);

    const activeModule = [];
    let modules = this.state.sidebar;
    modules &&
      modules.map((module) => {
        if (userType === "sub_admin" && module.sub_roles) {
          const active = [];
          module.sub_roles.forEach((sub) => {
            if (subName.includes(sub.name)) {
              active.push(sub);
            }
          });
          if (active.length) {
            module.sub_roles = active;
            activeModule.push(module);
          }
        } else if (
          userType === "sub_admin" ? result.includes(module.name) : module.name
        ) {
          activeModule.push(module);
        }
      });

    let activetitle = activeModule?.map((module) => module?.sub_roles ?? "");
    let activeName = [];
    activetitle?.map((sub) => {
      if (sub) {
        sub.forEach((obj) => {
          return activeName.push(obj.name);
        });
      }
    });
    const activeRoutes = activeModule?.map((module) => module.name);
    const mergedModule = activeRoutes.concat(activeName);
    const finalActiveModule = [];

    let Finalmodules = SidebarRouter;
    Finalmodules &&
      Finalmodules.map((module) => {
        if (mergedModule.includes(module.name)) {
          finalActiveModule.push(module);
        }
      });

    this.setState({ data: finalActiveModule });

    if (finalActiveModule[0]?.sub_roles && finalActiveModule[0]?.sub_roles[0]) {
      this.setState({
        pathrouter: finalActiveModule[0]?.sub_roles[0]?.path ?? "",
      });
    } else {
      this.setState({ pathrouter: finalActiveModule[0]?.path ?? "" });
    }
  };

  render() {
    const { pathrouter, data } = this.state;

    return (
      <Layout.Content
        style={{
          overflowY: "scroll",
          width: "100%",
          height: "90%",
          backgroundColor: "#fff",
        }}
      >
        <Route
          path={"/signup" || "/"}
          exact
          render={() => <Redirect to={pathrouter} />}
        />

        <Switch>
          {data &&
            data.map((item, i) => (
              <Route
                key={i}
                path={item.path}
                exact
                component={item.path ? item.component : PageNotFound}
              />
            ))}
        </Switch>
      </Layout.Content>
    );
  }
}

export default Content;
