import React, { Component } from "react";
import Sidebar from "./Sidebar.js";
import Content from "./Content";
import Header from "../pages/Header";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="ad-main-sidebar"
        style={{
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          display: "flex",

          justifyContent: "space-between",
        }}
      >
        <div id="main-admin-sidebar">
          <Sidebar />
        </div>
        <div className="ad-main-content" id="main-admin-content">
          <Header />
          <Content />
        </div>
      </div>
    );
  }
}

export default DefaultLayout;
