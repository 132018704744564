import React, { Component } from "react";
import { Divider, Spin } from "antd";
import "antd/dist/antd.css";
import "../../stylesheets/styles.css";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { showDate, showError, showSuccess, numberWithComma, isErrorTxt } from "../../constants/Utils";
import TopHeader from "../../components/ui/TopHeader";

export default class Accessory extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      id: this.props.match.params.id,
      name: "",
      price: "",
      item: "",
      purchase_date: "",
      status: "",
      specifications: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    this.handleTable();
  };

  handleTable = () => {
    let self = this;
    const { id } = self.state;
    APIGATEWAY.get( "accessories/" + id, ( response ) => {
      if ( response.success ) {
        let res = response.data.Accessory;

        self.setState( {
          loading:false,
          name: res?.employee?.name ?? '-',
          price: res?.price ?? "-",
          purchase_date: res?.purchase_date ?? "-",
          item: res?.item ?? "-",
          status: res?.status ?? "-",
          specifications: res?.specifications ?? "-",
        } );
      }
    } );
  };

  statusChange = () => {
    const { id, status } = this.state;
    let data = {
      status: status === "active" ? "inactive" : "active",
    };

    APIGATEWAY.patch( "accessories/status/" + id, data, ( response ) => {
      if ( response.success ) {
        showSuccess( response.message );
        this.handleTable();
      } else {
        showError( response.message );
      }
    } );
  };

  back = () => {
    this.props.history.goBack();
  };
  textContent = ( name, content, val ) => {
    return (
      <div className="name-wrappers">
        <div className="title-wrapper font-500">{name}</div>
        <div className="data-content text-capitalize">{val === "date" ? isErrorTxt( content ) ? "-" : showDate( content ) : isErrorTxt( content ) ? "-" : content}</div>
      </div>
    );
  };
  render () {
    let self = this;
    const { name, price, purchase_date, item, status, id, specifications,loading } =
      self.state;
    return (
      <div className="">
        <div>


          <TopHeader
            title="Accessories"
            updateStatus={this.statusChange}
            backClick1={this.back}
            status={status}
            type=""
          />
         {loading ? (

            <div className="spinning-star">
              <Spin size="large" />
            </div>
         ):(

              <div className="main-content">
                <div className="details-content">

                  {this.textContent( 'Name', name )}
                  {this.textContent( 'Accessory Id', id )}

                </div>
                <div className="details-content">

                  {this.textContent( 'Purchase Date', purchase_date, 'date' )}
                  {this.textContent( 'Item', item )}

                </div>

                <div className="details-content">

                  {this.textContent( 'Price', numberWithComma( price ) )}
                  {this.textContent( 'Status', status )}

                </div>
                {specifications.length > 0 && (
                  <div className="mt-5">
                    <Divider orientation="left" >Specifications</Divider>
                    {specifications &&
                      specifications.map( ( item, i ) => (
                        <div style={{ marginLeft: "20px" }}>
                          <ul key={i}>
                            <li>{item}</li>
                          </ul>
                        </div>
                      ) )}
                  </div>
                )}

              </div>
         )}
         
          
        </div>
      </div>
    );
  }
}
