import React, { useState, useEffect, Fragment } from "react";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { Link } from "react-router-dom";
import edit from "../../assets/fi-rr-pencil.svg";
import {
  Input,
  Drawer,
  Select,
  Dropdown,
  Space,
  Empty,
  Spin,
  message,
} from "antd";
import { STORAGE, UPDATE } from "../../constants/roleStore";
import { useLocation } from "react-router-dom";
import close from "../../assets/emp_close.svg";
import { useHistory } from "react-router-dom";

import { ProjectDetail } from "./ProjectDetail";
import updateicon from "../../assets/updateStatus.svg";
import {
  showDate,
  buildQueryString,
  project_statusColor,
  isErrorTxt,
  getCommaSeperator,
} from "../../constants/Utils";
import layer from "../../assets/Layer 2.svg";
import {
  BsThreeDotsVertical,
  BsChevronLeft,
  BsChevronRight,
} from "react-icons/bs";
import Filter from "../../components/ui/filter";
import "../../stylesheets/project/project.css";
import TopHeader from "../../components/ui/TopHeader";
import { capitlizestring, showError } from "../../constants/Utils";
// import "../../stylesheets/styles.css";
import Search from "../../components/ui/search";
import TableField from "../../components/ui/tableField";
import { Placeholder } from "reactstrap";
import UpdateProjectComponent from "./updateProjectComponent";
import ProgressBar from "./Progressbar";
import { PROJECTACCESS } from "../../components/ui/constants";

const { Option } = Select;
let searchTimeOut = null;

const Project = (props) => {
  const [projectList, setProjectList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    limit: 20,
    // clientSearch?'client': "":projectSearch?project:'':'',
    date_filter: "overall",
    status: "active",
    limit: 10,
  });
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const [editId, setEditId] = useState("");
  const [appType, setAppType] = useState("");
  const [appName, , setAppName] = useState("");
  const [total, setTotal] = useState("");
  const [totalDoc, setTotalDoc] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const [btnLoader, setBtnLoader] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [initPageLoading, setInitPageLoading] = useState(false);
  const [invoiceOverView, setInvoiceOverView] = useState([
    { card: "total_invoices", value: 0 },
    { card: "invoices_paid", value: 0 },
    { card: "invoices_pending", value: 0 },
    { card: "cancelled", value: 0 },
  ]);
  const [searchOption, setSearchOption] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const [searchData, setSearchData] = useState([
    { value: "Client", key: "client" },
    { value: "Project", key: "project" },
  ]);
  const [occurenceData, setoccurenceData] = useState([
    { value: "this_month", type: "This Month" },
    { value: "last_month", type: "Last Month" },
    { value: "this_year", type: "This Year" },
    { value: "last_year", type: "Last Year" },
    { value: "overall", type: "Overall" },
  ]);
  const [statusData, setStatusData] = useState([
    // { value: "All", key: "all" },
    { value: "Active", key: "active" },
    { value: "Hold", key: "hold" },
    { value: "Discontinued", key: "discontinued" },
    { value: "Completed", key: "completed" },
    { value: "Cancelled", key: "cancelled" },
  ]);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [listIndex, setListIndex] = useState("");
  const [updateStatus, setUpdateStatus] = useState({
    title: "",
    changeStatus: "",
    notes: "",
  });
  const [projectOverView, setProjectOverView] = useState([
    { card: "total_projects", value: 0 },
    { card: "active_projects", value: 0 },
    { card: "completed_projects", value: 0 },
    { card: "projects_on_hold", value: 0 },
  ]);
  const location = useLocation();
  const history = useHistory();

  const wrapperRef = React.createRef();
  const statusDate = [
    { value: "All" },
    { value: "This Year" },
    { value: "This Month" },
    { value: "Previous Month" },
  ];

  useEffect(() => {
    getProjectOverview();
  }, []);

  useEffect(() => {
    getProjectList();
  }, [queryStatus]);

  useEffect(() => {
    // let handler = (e) => {
    //   if (!wrapperRef.current !== e.target) {
    //     setDropdownMenu(false);
    //   }
    // };
    // document.addEventListener("mousedown", handler);
    // return () => {
    //   document.removeEventListener("mousedown", handler);
    // };
  }, [dropdownMenu]);

  const body = document.getElementById("root");
  let countnext = total;
  let countprev = total - (total - 1);
  if (currentPage > 1) {
    countnext = (currentPage - 1) * 10 + total;
    countprev = countnext - (total - 1);
  }

  const getProjectList = () => {
    APIGATEWAY.get("project" + buildQueryString(queryStatus), (response) => {
      if (response.success) {
        const result = response.data.projects;
        setTotal(response.data?.projects?.length);
        setLoading(false);
        setProjectList(result);
        setTotalDoc(response.data?.total);
      } else {
        setLoading(true);
      }
    });
  };

  const getProjectOverview = () => {
    APIGATEWAY.get("project/overview", (response) => {
      if (response.success) {
        const project = response.data.project;
        const invoice = response.data.invoice;
        setProjectOverView([
          {
            card: "total_projects",
            value:
              (project.active ?? 0) +
              (project.completed ?? 0) +
              (project.hold ?? 0),
          },
          { card: "active_projects", value: project.active ?? 0 },
          { card: "completed_projects", value: project.completed ?? 0 },
          { card: "projects_on_hold", value: project.hold ?? 0 },
        ]);
        setInvoiceOverView([
          {
            card: "total_invoices",
            value:
              (invoice.paid ?? 0) +
              (invoice.unpaid ?? 0) +
              (invoice.cancelled ?? 0),
          },
          { card: "invoices_paid", value: invoice.paid ?? 0 },
          { card: "invoices_pending", value: invoice.unpaid ?? 0 },
          { card: "cancelled", value: invoice.cancelled ?? 0 },
        ]);
      }
    });
  };

  function closeDrawer() {
    return new Promise((resolve, reject) => {
      setDropdownMenu(false);
      setOpenDrawer(false);
      setId(null);
      resolve(true);
    });
  }

  const dropdownClick = (index) => {
    setDropdownMenu(!dropdownMenu);
    setListIndex(index);
  };

  const updateProject = (id) => {
    setId(id);
    // const project_id = id;
    setOpenDrawer(true);
    // setInitPageLoading(true);
    // getProject(project_id);
  };

  function getProject(project_id) {
    APIGATEWAY.get(`project/${project_id}`, (response) => {
      if (response.success) {
        setInitPageLoading(false);
        setUpdateStatus({
          title: response.data.project[0].name,
          changeStatus: response.data.project[0].status,
        });
      }
    });
  }

  const statusTitleChange = (event) => {
    const titleip = event.target.value;
    setUpdateStatus({ ...updateStatus, title: titleip });
  };

  const updateProjectStatus = (event) => {
    setUpdateStatus((previous) => {
      return { ...previous, changeStatus: event };
    });
  };

  const updateProjectNotes = (event) => {
    setUpdateStatus({ ...updateStatus, notes: event.target.value });
  };

  const previous = () => {
    setCurrentPage(currentPage - 1);
    setQueryStatus({ ...queryStatus, page: currentPage - 1 });
    setLoading(true);
  };

  const next = (prev) => {
    setCurrentPage(prev + 1);
    setQueryStatus({ ...queryStatus, page: prev + 1 });
    setLoading(true);
  };

  const onSearchChange = (e) => {
    const value = e.target.value;
    setLoading(true);
    setTimeout(() => {
      setQueryStatus({ ...queryStatus, search_term: value });
    }, 1000);
  };

  const searchOptionChange = (value) => {
    setSearchOption(value);
    delete queryStatus.client;
    delete queryStatus.project;
    if (searchInput) {
      setLoading(true);
      setQueryStatus({ ...queryStatus, [value]: searchInput });
    }
  };

  const searchInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    setLoading(true);
    searchTimeOut && clearTimeout(searchTimeOut);
    searchTimeOut = setTimeout(() => {
      delete queryStatus.client;
      delete queryStatus.project;
      searchOption && setQueryStatus({ ...queryStatus, [searchOption]: value });
    }, 1000);
  };

  const handleOccurenceChange = (value) => {
    setTimeout(() => {
      setLoading(true);
    }, 30);
    setQueryStatus({ ...queryStatus, date_filter: value });
  };

  const handleOnSearchChange = (value) => {
    // setTimeout(() => {
    setLoading(true);
    // }, 0);
    setQueryStatus({ ...queryStatus, status: value });
    setCurrentPage(1);
  };

  const pageLimitOnchange = (value) => {
    setLoading(true);
    setQueryStatus({ ...queryStatus, limit: value });
  };

  const handleNavigateClick = (e) => {
    history.push("/projects/detailspage");
  };

  function update() {
    setBtnLoader(true);
    const { title, changeStatus, notes } = updateStatus;
    const body = {
      status: updateStatus.changeStatus,
      notes: updateStatus.notes,
    };
    setDropdownMenu(false);
    APIGATEWAY.patch(`project/${id}`, body, (response) => {
      if (response.success) {
        message.success("Project status  updated successfully");
        closeDrawer().then((res) => {
          setBtnLoader(false);
          getProjectList();
        });
      }
    });
  }

  function projStatus(data) {
    if (data) {
      getProjectList();
      closeDrawer();
    }
  }

  function toProjectEdit(id) {
    history.push({
      pathname: `projects/edit/${id}`,
      state: { from: "projects" },
    });
  }

  function cardSelect(e) {
    switch (e) {
      case "total_projects":
        handleOnSearchChange("all");
        break;
      case "active_projects":
        handleOnSearchChange("active");
        break;
      case "completed_projects":
        handleOnSearchChange("completed");
        break;
      case "projects_on_hold":
        handleOnSearchChange("hold");
        break;
    }
  }

  return (
    <div className="project-parent">
      <TopHeader
        title="Projects"
        to="/projects/add"
        type={appType}
        permission={appName}
      />
      <div style={{ padding: "0px 23px 30px 30px" }}>
        <div className="newcard_overall">
          {projectOverView.map((item, index) => (
            <div
              onClick={() => cardSelect(item.card)}
              className="newcard"
              style={{ cursor: "pointer" }}
            >
              <div className="card_header">{capitlizestring(item.card)}</div>
              <div className="card_value">{item.value}</div>
            </div>
          ))}
        </div>
        <div className="newcard_invoice_overall">
          {invoiceOverView.map((item, index) => (
            <div className="newInvoiceCard">
              <div className="card_header">{capitlizestring(item.card)}</div>
              <div className="card_value">{getCommaSeperator(item.value)}</div>
            </div>
          ))}
        </div>
        <div className="newcard_filter mb_24">
          {/* <Search
            details={queryStatus}
            outerDivClassName="mr-16 w_80"
            placeholder="Search Customer"
            searchClassName=""
            onSearchChange={onSearchChange}
          ></Search> */}
          <Filter
            searchData={searchData}
            optionChange={(e) => searchOptionChange(e)}
            searchOption={searchOption}
            searchname="searchOption"
            searchInputName="searchInput"
            searchInput={searchInput}
            inputChange={(e) => searchInputChange(e)}
            occurenceData={occurenceData}
            occurenceName="occurence"
            occurenceSelect={queryStatus.date_filter}
            onOccurenceChange={handleOccurenceChange}
            statusData={statusData}
            statusName="status"
            statusSelect={queryStatus.status}
            statusOnChange={handleOnSearchChange}
          />
          <Filter />

          {/* <Filter
              searchData={this.state.searchData}
              statusData={this.state.statusData}
              optionChange={(e) => this.searchOption(e)}
              searchOption={this.state.searchOption}
              searchname="searchOption"
              statusName="status"
              statusSelect={this.state.status}
              statusOnChange={this.handleOnSearchChange}
              clear={() => this.clearSearch()}
              apply={() => this.Apply()}
              searchInputName="searchInput"
              searchInput={this.state.searchInput}
              inputChange={(e) => this.searchInput(e)}
            /> */}
        </div>
        {loading ? (
          <div className="spinning-star">
            <Spin size="large" />
          </div>
        ) : (
          <div className="project-table-container">
            <table className="tables">
              <thead>
                <tr>
                  <th className="w_20 ">Name</th>
                  <th className="w_20 ">Percentage</th>
                  <th className="pl_4 w_15 ">Start</th>
                  <th className="w_15">Actual Start</th>
                  <th className="w_15 ">Completion</th>
                  <th className="w_15">Overall Value</th>
                  <th className="w_15">Status</th>
                  <th></th>
                </tr>
              </thead>
              {projectList.length > 0 ? (
                <tbody>
                  {projectList.map((ele, i) => (
                    <tr key={i}>
                      <td>
                        <Link
                          to={{
                            pathname: `projects/detailspage/${ele.project_id}`,
                            state: { id: ele.project_id, _id: ele._id },
                          }}
                        >
                          <b className="fw_500 fs_14 c_dark">{ele.name}</b>
                        </Link>
                      </td>
                      <td className="">
                        {<ProgressBar percentage={ele.percentage} />}
                      </td>
                      <td className="fw_500 fs_14 c_dark pl_4">
                        {showDate(ele?.startDate) ?? "-"}
                      </td>
                      <td className="fw_500 fs_14 c_dark">
                        {ele?.actualStartDate
                          ? showDate(ele?.actualStartDate)
                          : "-"}
                      </td>
                      <td className="fw_500 fs_14 c_dark">
                        {ele?.actualStartDate ? showDate(ele?.endDate) : "-"}
                      </td>
                      <td className="fw_500 fs_14 c_dark">
                        {ele?.projectValue
                          ? getCommaSeperator(ele.projectValue)
                          : getCommaSeperator(0)}
                      </td>
                      <td className="fw_500 fs_14 c_dark">
                        {isErrorTxt(ele.status)
                          ? "-"
                          : project_statusColor(ele.status)}
                      </td>
                      {PROJECTACCESS.update && (
                        <td className="table-action">
                          <div className="tax-add-edit">
                            <span className="ml-5 " ref={wrapperRef}>
                              <div className="dropdown-head">
                                <div className="dropbtn-head">
                                  <img
                                    onClick={() => dropdownClick(i)}
                                    src={layer}
                                    alt="profile"
                                    className="menu-bar-icon"
                                  />
                                </div>
                                {listIndex === i && dropdownMenu && (
                                  <Fragment>
                                    <div
                                      className="overflow-overlay"
                                      onClick={() => setDropdownMenu(false)}
                                    ></div>
                                    <div className="dropdown-content-heads">
                                      <div style={{ width: "170px" }}>
                                        {
                                          // UPDATE(
                                          //   app_type,
                                          //   app_name,
                                          <div
                                            onClick={() =>
                                              toProjectEdit(ele.project_id)
                                            }
                                            className="d-flex_al_c action-menu-bar on_hover"
                                          >
                                            <div>
                                              <img
                                                alt="edit"
                                                style={{
                                                  width: "15px",
                                                  marginRight: "10px",
                                                }}
                                                src={edit}
                                              ></img>
                                            </div>
                                            <div className="light">
                                              <div className="m_0 p_0 anchor">
                                                Edit
                                              </div>
                                            </div>
                                          </div>
                                          // )
                                        }
                                        <div
                                          onClick={() =>
                                            updateProject(ele.project_id)
                                          }
                                          className="d-flex_al_c action-menu-bar on_hover"
                                        >
                                          <div>
                                            <img
                                              alt="update status"
                                              style={{
                                                width: "15px",
                                                marginRight: "10px",
                                              }}
                                              src={updateicon}
                                            ></img>
                                          </div>
                                          <div className="light">
                                            <div className="m_0 p_0 anchor">
                                              Update Status
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                )}
                              </div>
                            </span>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="empty-message">
                  <tr>
                    <td colSpan="6">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </td>
                  </tr>
                </tbody>
              )}
            </table>

            {projectList.length > 0 && (
              <TableField
                data={projectList}
                countprev={countprev}
                countnext={countnext}
                currentPage={currentPage}
                ClickPrev={() => {
                  previous();
                }}
                ClickNext={() => next(currentPage)}
                total={total}
                overAll={totalDoc ?? 0}
                pagelimit={queryStatus.limit}
                pagelimitHandle={pageLimitOnchange}
              />
            )}
          </div>
        )}
      </div>

      <Space>
        <Drawer
          placement="right"
          open={openDrawer}
          onClose={closeDrawer}
          className="tax-main-drawer"
        >
          {id && (
            <UpdateProjectComponent
              statusData={statusData}
              update={projStatus}
              projectId={id}
              onClose={closeDrawer}
            ></UpdateProjectComponent>
          )}
        </Drawer>
      </Space>
    </div>
  );
};

export default Project;
