import React, { Component } from "react";
import {
  Form,
  Input,
  Modal,
  DatePicker,
  Spin,
  Dropdown,
  Menu,
  Empty,
} from "antd";
import { Link } from "react-router-dom";
import { STORAGE, UPDATE, APPROVAL } from "../../constants/roleStore";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { subDays, addDays } from "date-fns";
import layer from "../../assets/Layer 2.svg";
import InputItem from "../../components/ui/InputItem";

import TopHeader from "../../components/ui/TopHeader";
import { MoreOutlined } from "@ant-design/icons";
import {
  isErrorTxt,
  isInvalidEmail,
  showError,
  showDates,
  showSuccess,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  getIsoString,
  onboardStatusColor,
} from "../../constants/Utils";
import "../../stylesheets/styles.css";
import TableField from "../../components/ui/tableField";
import DateItem from "../../components/ui/DateItem";
import { HIRINGACCESS } from "../../components/ui/constants";

export default class Onboard extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 20,
      total: 0,
      overall: "",
      onboard: [],
      modalOpen: false,
      office_email: "",
      review_date: "",
      onboard_id: "",
      resendmodalOpen: false,
      expires: "",
      cancelOfferModal: false,
      cancel_reason: "",
      rejectOfferModal: false,
      reject_reason: "",
      loading: true,
      Tableloading: true,
      admin_app: [],
      app_name: "",
      app_type: "",
      queryStaus: {},
      dropdown_menu: false,
      list_index: "",
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.setQueryStatus();

    let name = "";

    let storage = JSON.parse(STORAGE.view);

    name = storage?.filter((app) => app?.name === "Hiring");

    this.setState({
      app_name: name,
      app_type: STORAGE.type,
    });

    this.reload();
    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);
  }
  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };
  reload = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystaus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.setQueryStatus();
        }
      });
    });
  };
  onPopstate = () => {
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);

    this.state.currentPage = parseInt(searchObj.page) ?? "";

    this.state.queryStaus = returnSearchObject(search);

    this.state.pageSize = parseInt(searchObj.limit) ?? "";

    this.setState(
      {
        queryStaus: this.getQuerySearch(),
      },
      () => {
        this.handleTable();
      }
    );
  };

  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";

    return querysearch;
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",

          queryStaus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStaus);
          this.handleTable();
        }
      );
    } else {
      this.setState(
        {
          queryStaus: { ...this.state.queryStaus, page: 1, limit: 20 },
          pageSize: 20,
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStaus);
          this.handleTable();
        }
      );
    }
  };

  handleFilter = () => {
    let { currentPage, pageSize } = this.state;
    let query = returnSearchObject(this.props.location.search);

    query.page = currentPage ?? "";
    query.limit = pageSize ?? "";

    this.setState({ queryStaus: query }, () => {
      pushToFilteredPage(this.props, this.state.queryStaus);
      this.handleTable();
    });
  };

  handleTable = () => {
    APIGATEWAY.get(
      "employee/onboard/" + buildQueryString(this.state.queryStaus),
      (response) => {
        if (response.success) {
          let data = response.data.employee_onboards;

          this.setState({
            total: response.data?.employee_onboards?.length,
            onboard: data,
            loading: false,
            Tableloading: false,
            overall: response.data?.total,
          });
        } else {
          this.setState({ loading: true });
        }
      }
    );
  };

  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  next = (prev) => {
    const { currentPage } = this.state;

    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.handleTable();
    });
  };

  CancelOffer = (obj) => {
    this.setState({ cancelOfferModal: true, onboard_id: obj.onboard_id });
  };

  submitCancelOffer = () => {
    const { cancel_reason, onboard_id } = this.state;
    let data = {
      reason: cancel_reason,
    };

    APIGATEWAY.patch(
      "employee/onboard/cancel/" + onboard_id,
      data,
      (response) => {
        if (response.success) {
          showSuccess(response.message);
          this.setState({ cancelOfferModal: false, onboard_id: "" });
          this.handleTable();
        } else {
          showError(response.message);
        }
      }
    );
  };

  RejectOffer = (obj) => {
    this.setState({ rejectOfferModal: true, onboard_id: obj.onboard_id });
  };

  submitRejectOffer = () => {
    const { reject_reason, onboard_id } = this.state;
    let data = {
      reason: reject_reason,
    };

    APIGATEWAY.patch(
      "employee/onboard/reject/" + onboard_id,
      data,
      (response) => {
        if (response.success) {
          showSuccess(response.message);
          this.setState({ rejectOfferModal: false, onboard_id: "" });
          this.handleTable();
        } else {
          showError(response.message);
        }
      }
    );
  };

  ResendOffer = (obj) => {
    this.setState({ resendmodalOpen: true, onboard_id: obj.onboard_id });
  };

  resendOffer = () => {
    const { onboard_id, expires } = this.state;
    let data = {
      offer_expires: showDates(expires),
    };
    APIGATEWAY.post(
      "employee/onboard/resend/" + onboard_id,
      data,
      (response) => {
        if (response.success) {
          showSuccess(response.message);
          this.handleTable();
          this.setState({ resendmodalOpen: false, onboard_id: "" });
        } else {
          showError(response.message);
        }
      }
    );
  };

  Onboard = (obj) => {
    this.setState({ modalOpen: true, onboard_id: obj.onboard_id });
  };

  cancelModal = () => {
    this.setState({ modalOpen: false, office_email: "" });
  };
  cancelRsendModal = () => {
    this.setState({
      resendmodalOpen: false,
      expires: "",
    });
  };
  cancelOfferModal = () => {
    this.setState({
      cancelOfferModal: false,
      cancel_reason: "",
    });
  };
  rejectOfferModal = () => {
    this.setState({
      rejectOfferModal: false,
      reject_reason: "",
    });
  };
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleReview = (value) => {
    this.setState({
      review_date: value,
    });
  };
  submitOnboard = () => {
    const { office_email, review_date, onboard_id } = this.state;
    if (office_email === "" || office_email === null) {
      showError("Please enter your email address");
    } else if (isInvalidEmail(office_email)) {
      showError("Please enter a valid email address");
    } else if (review_date === "" || review_date === null) {
      showError("Please enter review date");
    } else {
      let next_review_date = showDates(review_date);
      let data = {
        official_email: office_email,
        next_review_date: next_review_date,
      };
      APIGATEWAY.patch(
        "employee/onboard/status/" + onboard_id,
        data,
        (response) => {
          if (response.success) {
            showSuccess(response.message);
            this.setState({
              modalOpen: false,
              office_email: "",
              onboard_id: "",
            });
            this.handleTable();
          } else {
            showError(response.message);
          }
        }
      );
    }
  };

  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  handleDateChange = (e) => {
    this.updateStateValues("expires", e);
  };

  onBoardDetail = (obj) => {
    this.props.history.push(`/onboard/detail/${obj.onboard_id}`);
  };
  
  showTitle = (job_title) => {
    if (job_title && job_title.name) {
      return job_title.name;
    } else {
      return "Not Provided";
    }
  };

  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      list_index: index,
    });
  };
  render() {
    const { currentPage, total, onboard, app_name, app_type, pageSize } =
      this.state;
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }

    const menu = (object) => {
      let menuitem = "";
      if (object.status === "pending") {
        menuitem = <Menu.Item key="pending">Cancel Offer</Menu.Item>;
      }
      // if (
      //   object.status === "reject" ||
      //   object.status === "failed" ||
      //   object.status === "pending" ||
      //   object.status === "cancel"
      // ) {
      //   menuitem = <Menu.Item key="resend">Resend Offer</Menu.Item>;
      // }
      return <Menu>{<div>{menuitem}</div>}</Menu>;
    };

    return (
      <>
        <div className="">
          <>
            <TopHeader
              title="Onboard"
              to="/onboard/add"
              type={app_type}
              permission={app_name}
            />

            <div style={{ padding: "0px 23px 30px 30px" }}>
              {this.state.Tableloading ? (
                <div className="tableview">
                  <Spin />
                </div>
              ) : (
                <div>
                  {this.state.Tableloading ? (
                    <div className="tableview">
                      <Spin />
                    </div>
                  ) : (
                    <div className="table_page_wrap">
                      {onboard.length > 0 ? (
                        <table className="table-response">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Name</th>
                              <th>Sent Date</th>

                              <th>Job Title</th>
                              <th>Joining Date</th>
                              <th>Expiry Date</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>

                          {onboard.length > 0 ? (
                            <tbody ref={this.wrapperRef}>
                              {onboard.map((item, r) => (
                                <tr key={r}>
                                  <td className="">
                                    {" "}
                                    {(this.state.currentPage - 1) *
                                      this.state.pageSize +
                                      (r + 1)}
                                  </td>
                                  <td
                                    className="table-name text-capitalize"
                                    onClick={() => this.onBoardDetail(item)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {UPDATE(
                                      app_type,
                                      app_name,
                                      <a>{item.name}</a>,
                                      <div>{item.name}</div>
                                    )}
                                  </td>
                                  <td className="table-name ">
                                    {showDates(`${item?.createdAt}`)}
                                  </td>
                                  <td className="table-name text-capitalize font-weight-content">
                                    {isErrorTxt(item?.job_title?.name)
                                      ? "-"
                                      : item?.job_title?.name}
                                  </td>
                                  <td className="table-name ">
                                    {isErrorTxt(item?.official_date?.joined)
                                      ? ""
                                      : showDates(
                                          `${item.official_date.joined}`
                                        )}
                                  </td>
                                  <td className="table-name ">
                                    {isErrorTxt(item?.offer_expires)
                                      ? ""
                                      : showDates(`${item.offer_expires}`)}
                                  </td>
                                  <td className="table-name text-capitalize font-weight-content">
                                    {onboardStatusColor(item.status)}
                                  </td>
                                  <td
                                    className="table-action"
                                    onClick={() => this.dropdownClick(r)}
                                  >
                                    {item.status === "onboard" ||
                                    item.status === "expired" ||
                                    item.status === "cancel" ||
                                    item.status === "reject" ? (
                                      ""
                                    ) : (
                                      <>
                                        {(HIRINGACCESS.approval ||
                                          HIRINGACCESS.update) && (
                                          <div className="dropdown-head">
                                            <div className="dropbtn-head">
                                              <img
                                                src={layer}
                                                alt="profile"
                                                className="menu-bar-icons"
                                              />
                                            </div>
                                            {this.state.list_index === r &&
                                              this.state.dropdown_menu && (
                                                <div className="dropdown-content-heads">
                                                  <div>
                                                    {/* {APPROVAL(
                                                    app_type,
                                                    app_name, */}

                                                    <>
                                                      {HIRINGACCESS.update &&
                                                        item.status ===
                                                          "pending" && (
                                                          <Link
                                                            className="action-menu-bar"
                                                            // onClick={() => this.EditDetails(item)}
                                                            to={{
                                                              pathname: `/onboard/detail/${item.onboard_id}`,
                                                              state: item,
                                                            }}
                                                          >
                                                            Edit
                                                          </Link>
                                                        )}

                                                      {HIRINGACCESS.approval &&
                                                        item.status ===
                                                          "pending" && (
                                                          <p
                                                            className="action-menu-bar"
                                                            onClick={() =>
                                                              this.CancelOffer(
                                                                item
                                                              )
                                                            }
                                                          >
                                                            Cancel Offer
                                                          </p>
                                                        )}

                                                      {HIRINGACCESS.approval &&
                                                        (item.status ===
                                                          "reject" ||
                                                        item.status ===
                                                          "failed" ||
                                                        item.status ===
                                                          "pending" ||
                                                        item.status ===
                                                          "cancel" ||
                                                        item.status ===
                                                          "expired" ? (
                                                          <p
                                                            className="action-menu-bar"
                                                            onClick={() =>
                                                              this.ResendOffer(
                                                                item
                                                              )
                                                            }
                                                          >
                                                            Resend Offer
                                                          </p>
                                                        ) : (
                                                          ""
                                                        ))}

                                                      {item.status ===
                                                        "accept" && (
                                                        <>
                                                          <p
                                                            className="action-menu-bar"
                                                            onClick={() =>
                                                              this.Onboard(item)
                                                            }
                                                          >
                                                            Onboard now
                                                          </p>
                                                          {HIRINGACCESS.approval && (
                                                            <p
                                                              className="action-menu-bar"
                                                              onClick={() =>
                                                                this.CancelOffer(
                                                                  item
                                                                )
                                                              }
                                                            >
                                                              Cancel Offer
                                                            </p>
                                                          )}

                                                          {HIRINGACCESS.approval && (
                                                            <p
                                                              className="action-menu-bar"
                                                              onClick={() =>
                                                                this.RejectOffer(
                                                                  item
                                                                )
                                                              }
                                                            >
                                                              Reject Offer
                                                            </p>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                    {/* // )} */}
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            ""
                          )}
                        </table>
                      ) : (
                        <table className="table-response">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Name</th>
                              <th>Sent Date</th>
                              <th>Job Title</th>
                              <th>Joining Date</th>
                              <th>Expiry Date</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>

                          <tbody className="empty-message">
                            <tr>
                              <td colspan="11">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                      {onboard.length > 0 && (
                        <TableField
                          data={onboard}
                          countprev={countprev}
                          countnext={countnext}
                          currentPage={currentPage}
                          ClickPrev={() => this.previous()}
                          ClickNext={() => this.next(this.state.currentPage)}
                          total={total}
                          overAll={this.state.overall ?? 0}
                          pagelimit={this.state.pageSize}
                          pagelimitHandle={this.pageLimitOnchange}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
          <Modal
            title="Onboard"
            open={this.state.modalOpen}
            onCancel={this.cancelModal}
            footer={[
              <div className="d-flex justify-content-between align-items-center">
                <button className="secondary_color" onClick={this.cancelModal}>
                  Cancel
                </button>
                <button className="dec-btn" onClick={this.submitOnboard}>
                  Submit
                </button>
              </div>,
            ]}
          >
            <div>
              <InputItem
                label="Official Email Id"
                star={false}
                name="office_email"
                value={this.state.office_email}
                placeholder="Enter the email id"
                onChange={this.handleInputChange}
              />
            </div>
            <div className="mt-4">
              <DateItem
                label="Next Review Date"
                name="review_date"
                star={false}
                value={this.state.review_date}
                placeholder="Enter review date"
                onChange={this.handleReview}
              />
            </div>
          </Modal>

          <Modal
            title="Resend"
            open={this.state.resendmodalOpen}
            onCancel={this.cancelRsendModal}
            footer={[
              <div className="d-flex justify-content-between align-items-center">
                <button
                  className="secondary_color"
                  onClick={this.cancelRsendModal}
                >
                  Cancel
                </button>
                <button className="dec-btn" onClick={this.resendOffer}>
                  Submit
                </button>
              </div>,
            ]}
          >
            <label className="block-label">OfferExpires Date</label>
            <DatePicker
              onChange={this.handleDateChange}
              name="expires"
              dateFormat="YYYY-MM-DD"
              className="onboard_date"
              placeholder="Expires date"
              style={{ marginTop: 10, height: "47px" }}
              value={this.state.expires}
              getPopupContainer={(trigger) => trigger.parentNode}
              disabledDate={(d) =>
                !d ||
                d.isAfter(addDays(new Date(), 30)) ||
                d.isSameOrBefore(subDays(new Date(), 1))
              }
            />
          </Modal>

          <Modal
            title="Cancel Offer"
            open={this.state.cancelOfferModal}
            onCancel={this.cancelOfferModal}
            footer={[
              <div className="d-flex justify-content-between align-items-center">
                <button
                  className="secondary_color"
                  onClick={this.cancelOfferModal}
                >
                  Cancel
                </button>
                <button className="dec-btn" onClick={this.submitCancelOffer}>
                  Submit
                </button>
              </div>,
            ]}
          >
            <div>
              <InputItem
                label="Enter the cancel reason"
                star={false}
                name="cancel_reason"
                value={this.state.cancel_reason}
                placeholder="Enter the reason"
                onChange={this.handleInputChange}
              />
            </div>
          </Modal>

          <Modal
            title="Reject Offer"
            open={this.state.rejectOfferModal}
            onCancel={this.rejectOfferModal}
            footer={[
              <div className="d-flex justify-content-between align-items-center">
                <button
                  className="secondary_color"
                  onClick={this.rejectOfferModal}
                >
                  Cancel
                </button>

                <button className="dec-btn" onClick={this.submitRejectOffer}>
                  Submit
                </button>
              </div>,
            ]}
          >
            <div>
              <InputItem
                label="Enter the reject reason"
                star={false}
                name="reject_reason"
                value={this.state.reject_reason}
                placeholder="Enter the reason"
                onChange={this.handleInputChange}
              />
            </div>
          </Modal>
        </div>
      </>
    );
  }
}
