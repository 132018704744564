import React, { Component } from "react";
import { Drawer, Popover, Modal } from "antd";
import CreateTask from "./CreateTask";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import "../../stylesheets/styles.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineUserAdd } from "react-icons/ai";
import moment from "moment";

class MyTaskCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myTask: [],
      all_tasks: [],
      todayStart: moment().startOf("day").valueOf(),
      todayEnd: moment().endOf("day").valueOf(),
      tommorrowEnd: moment().add(1, "days").endOf("day").valueOf(),
      pastDueTask: [],
      todayTask: [],
      tommorrowTask: [],
      upcommingTask: [],
      nodue:[],
      visible: false,
      edit: false,
      taskDetails: [],
    };
  }

  componentDidMount() {
    this.displayTask();
  }

  componentDidUpdate(prevProps) {
    if (this.props.tasks !== prevProps.tasks) {
      this.displayTask();
    }
  }

  displayTask = () => {
    let { todayEnd, todayStart, tommorrowEnd } = this.state;
    let dataPast = [];
    let dataToday = [];
    let dataTom = [];
    let dataUp = [];
    let dataNodue=[];

    this.props.tasks.map((element) => {
      const due = moment(element.due_date).valueOf();

      if (due < todayStart) {
        dataPast = [...dataPast, element];
      } else if (element.due_date === null) {
        dataNodue = [...dataNodue, element];
      } else if (due < todayEnd) {
        dataToday = [...dataToday, element];
      } else if (due < tommorrowEnd) {
        dataTom = [...dataTom, element];
      } else {
        dataUp = [...dataUp, element];
      }
    });

    this.setState({
      pastDueTask: dataPast,
      nodue: dataNodue,
      todayTask: dataToday,
      tommorrowTask: dataTom,
      upcommingTask: dataUp,
    });
  };

  editTask = (i, task) => {
    this.setState({
      taskDetails: task,
      edit: true,
      visible: true,
    });
  };

  deleteTask = (task, i) => {
    this.setState({
      showModal: true,
      deletebutton: false,
      taskDetails: task,
    });
  };

  handleVisibleChange(newVisible) {
    this.setState({ deletebutton: newVisible });
  }
  confirmDelete = (taskDetails) => {

    APIGATEWAY.delete(`tasks/${taskDetails.task_id}`, (response) => {
      
    });
    this.setState({ showModal: false });
    window.location.reload();
  };

  reject = () => {
    this.setState({ showModal: false });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  render() {
    let {
      todayTask,
      tommorrowTask,
      pastDueTask,
      upcommingTask,
      taskDetails,
      visible,
      nodue,
    } = this.state;
    return (
      <div>
      {(this.props.filter === "pastDue" || this.props.filter === "all") &&
      pastDueTask.length > 0 ? (
        <div>
          <div className="mt-30 mb-30">
            <span className="task_day">Past Due </span>
            <span className="task_number backcolor_pastdue">
              {pastDueTask.length}
            </span>
          </div>
          <div className="tasks_lists">
            {pastDueTask.map((task, i) => (
              <div
                className="task_card bordercolor_pastdue"
                onClick={(e) => this.editTask(task, e)}
                key={task.task_id}
              >
                <div className="task_card_details mb-20">
                  <div className="task_card_name">{task.name}</div>
                  <div className="task_card_option">
                    <Popover
                      placement="rightTop"
                      content={
                        <div
                          onClick={() => this.deleteTask(task, i)}
                          className="delete_Task"
                        >
                          Delete
                        </div>
                      }
                      trigger="hover"
                      visibile={this.state.deletebutton}
                      // onVisibleChange={this.handleVisibleChange}
                    >
                      <BsThreeDotsVertical />
                    </Popover>
                  </div>
                </div>
                <div className="task_card_details">
                  <div className="task_date">
                    {moment(task.due_date).format("MMM DD,YYYY")}
                  </div>
                  <div className="task_assign_to">
                  {task.to === "employer" ? "Myself" : task.employee?.name }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {(this.props.filter === "nodue" || this.props.filter === "all") &&
      nodue.length > 0 ? (
        <div>
          <div className="mt-30 mb-30">
            <span className="task_day">No Due </span>
            <span className="task_number backcolor_nodue">
              {nodue.length}
            </span>
          </div>
          <div className="tasks_lists">
            {nodue.map((task, i) => (
              <div className="task_card bordercolor_nodue" key={task.task_id}>
                <div className="task_card_details mb-20">
                  <div
                    className="task_card_name"
                    onClick={(e) => this.editTask(task, e)}
                  >
                    {task.name}
                  </div>
                  <div className="task_card_option">
                    <Popover
                      placement="rightTop"
                      content={
                        <div
                          onClick={() => this.deleteTask(task, i)}
                          className="delete_Task"
                        >
                          Delete
                        </div>
                      }
                      trigger="hover"
                      visibile={this.state.deletebutton}
                      // onVisibleChange={this.handleVisibleChange}
                    >
                      <BsThreeDotsVertical />
                    </Popover>
                  </div>
                </div>
                <div className="task_card_details">
                  <div className="task_date"></div>
                  <div className="task_assign_to">
                    {task.to === "employer" ? "Myself" : task.employee?.name }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {(this.props.filter === "today" || this.props.filter === "all") &&
      todayTask.length > 0 ? (
        <div>
          <div className="mt-30 mb-30">
            <span className="task_day">Today </span>
            <span className="task_number backcolor_today">
              {todayTask.length}
            </span>
          </div>
          <div className="tasks_lists">
            {todayTask.map((task, i) => (
              <div
                className="task_card bordercolor_today"
                onClick={(e) => this.editTask(task, e)}
                key={task.task_id}
              >
                <div className="task_card_details mb-20">
                  <div
                    className="task_card_name"
                    onClick={(e) => this.editTask(task, e)}
                  >
                    {task.name}
                  </div>
                  <div className="task_card_option">
                    <Popover
                      placement="rightTop"
                      content={
                        <div
                          onClick={() => this.deleteTask(task, i)}
                          className="delete_Task"
                        >
                          Delete
                        </div>
                      }
                      trigger="hover"
                      visibile={this.state.deletebutton}
                      // onVisibleChange={this.handleVisibleChange}
                    >
                      <BsThreeDotsVertical />
                    </Popover>
                  </div>
                </div>
                <div className="task_card_details">
                  <div className="task_date">
                    {moment(task.due_date).format("MMM DD,YYYY")}
                  </div>
                  <div className="task_assign_to">
                  {task.to === "employer" ? "Myself" : task.employee?.name }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {(this.props.filter === "all" || this.props.filter === "tommorrow") &&
      tommorrowTask.length > 0 ? (
        <div>
          <div className="mt-30 mb-30">
            <span className="task_day">Tommorrow </span>
            <span className="task_number backcolor_tommorrow">
              {tommorrowTask.length}
            </span>
          </div>
          <div className="tasks_lists">
            {tommorrowTask.map((task, i) => (
              <div
                className="task_card bordercolor_tommorrow"
                onClick={(e) => this.editTask(task, e)}
                key={task.task_id}
              >
                <div className="task_card_details mb-20">
                  <div className="task_card_name">{task.name}</div>
                  <div className="task_card_option">
                    <Popover
                      placement="rightTop"
                      content={
                        <div
                          onClick={() => this.deleteTask(task, i)}
                          className="delete_Task"
                        >
                          Delete
                        </div>
                      }
                      trigger="hover"
                      visibile={this.state.deletebutton}
                      // onVisibleChange={this.handleVisibleChange}
                    >
                      <BsThreeDotsVertical />
                    </Popover>
                  </div>
                </div>
                <div className="task_card_details">
                  <div className="task_date">
                    {moment(task.due_date).format("MMM DD,YYYY")}
                  </div>
                  <div className="task_assign_to">
                    {task.to === "employer" ? "Myself" : task.employee?.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {(this.props.filter === "upcomming" || this.props.filter === "all") &&
      upcommingTask.length > 0 ? (
        <div>
          <div className="mt-30 mb-30">
            <span className="task_day">Upcomming </span>
            <span className="task_number backcolor_nodue">
              {upcommingTask.length}
            </span>
          </div>
          <div className="tasks_lists">
            {upcommingTask.map((task, i) => (
              <div className="task_card bordercolor_nodue" key={task.task_id}>
                <div className="task_card_details mb-20">
                  <div
                    className="task_card_name"
                    onClick={(e) => this.editTask(task, e)}
                  >
                    {task.name}
                  </div>
                  <div className="task_card_option">
                    <Popover
                      placement="rightTop"
                      content={
                        <div
                          onClick={() => this.deleteTask(task, i)}
                          className="delete_Task"
                        >
                          Delete
                        </div>
                      }
                      trigger="hover"
                      visibile={this.state.deletebutton}
                      // onVisibleChange={this.handleVisibleChange}
                    >
                      <BsThreeDotsVertical />
                    </Popover>
                  </div>
                </div>
                <div className="task_card_details">
                  <div className="task_date">
                    {moment(task.due_date).format("MMM DD,YYYY")}
                  </div>
                  <div className="task_assign_to">
                    {task.to === "employer" ? "Myself" : task.employee?.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {this.props.filter === "completed" ? (
        <div className="tasks_lists">
          {this.props.completedTask.map((task, i) => (
            <div
              className="task_card bordercolor_nodue"
              onClick={(e) => this.editTask(task, e)}
              key={task.task_id}
            >
              <div className="task_card_details mb-20">
                <div className="task_card_name">{task.name}</div>
              </div>
              <div className="task_card_details">
                <div className="task_date">
                {task.due_date ? moment(task.due_date).format("MMM DD,YYYY"):null }
                </div>
                <div className="task_assign_to">
                  {task.to === "employer" ? "Myself" : task.employee?.name}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      {/* {this.props.filter === "deleted" ? (
        <div className="tasks_lists">
          {this.props.deletedTask.map((task, i) => (
            <div
              className="task_card bordercolor_pastdue"
              onClick={(e) => this.editTask(task, e)}
              key={task.employee.id}
            >
              <div className="task_card_details mb-20">
                <div className="task_card_name">{task.name}</div>
              </div>
              <div className="task_card_details">
                <div className="task_date">
                  {moment(task.due_date).format("MMM DD,YYYY")}
                </div>
                <div className="task_assign_to">{task.to}</div>
              </div>
            </div>
          ))}
        </div>
      ) : null} */}

      <Modal
        open={this.state.showModal}
        centered
        footer={[]}
        width="600px"
      >
        <div className="delete_text">Delete</div>
        <div className="confirm_message">
          Are you sure you want to delete {taskDetails.name}
        </div>
        <div className="confirm_buttons">
          <div
            className="delete_yes_button"
            onClick={() => this.confirmDelete(taskDetails)}
          >
            Yes
          </div>
          <div className="delete_no_button" onClick={() => this.reject()}>
            No
          </div>
        </div>
      </Modal>

      <Drawer
        placement="right"
        onClose={this.onClose}
        open={visible}
        width="600px"
      >
        <CreateTask
          cancel={this.onClose}
          edit={this.state.edit}
          taskDetails={this.state.taskDetails}
        />
      </Drawer>
    </div>
    );
  }
}

export default MyTaskCard;
