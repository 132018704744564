import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Input,
  DatePicker,
  Table,
  Select,
  Spin,
  Checkbox,
  Popover,
} from "antd";
import { Modal } from "reactstrap";
import { subDays, addDays } from "date-fns";
import {
  numberFormatWithCurrency,

  isErrorTxt,
  numberWithComma,
  isCombinedAlphaNumeric,
  numberWithRupees,
  showCurrency
} from "../../constants/Utils";
import "../../stylesheets/styles.css";
import "antd/dist/antd.css";
import moment, { utc } from "moment";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import TopHeader from "../../components/ui/TopHeader";
import Remove from "../../assets/remove_list.svg";
import Add from "../../assets/add_list.svg";
import { showError, utcdate, showFullName, showSuccess } from "../../constants/Utils";

import Preview from "../../assets/View.svg";
import Logo from "../../assets/logo_black.svg";
const errorMessage = "Please fill the Details";
const { Option } = Select;

export default class AddInvoice extends Component {

  constructor(props) {
    super(props);

    this.state = {
      stateId:this.props.location?.state?.project,
      id: this.props.match.params.id,
      duplicate:this.props.match.params.duplicate,
      loading: true,
      client_id: "",
      project_name: "",
      client_name: "",
      reference_number: "",
      data: [],
      list: [],
      projectlist: [],
      project_id: "",
      invoice_items: [
        {
          name: "",
          description: "",
          quantity: "",
          rate: "",
          amount: "",
        },
      ],
      dueDate: "",
      invoiceDate: "",
      month_Filter: "last_month",
      from: moment().startOf("month").add(-1, "month"),
      to: moment().endOf("month").add(-1, "month"),
      totalAmount: "",
      mode_type: "",
      paid: "",
      amount: "",
      role: "",
      Discount: true,
      is_Discount: false,
      is_Gst: false,
      is_Sgst: false,
      is_Address: false,
      Gst_total: "",
      discount_amt: 0,
      gst_amt: 0,
      sgst_amt: 0,
      selectedImage: null,
      Wholesome_amount: 0,
      Discount_percentage: 0,
      modalVisible: false,
      client_address: "",
      GST_no: "",
      Taxes: [],
      gst: {},
      sgst: {},
      company_name: "",
      Tax_Amt: 0,
      client_currency: "",
      Invoice_no: "",
      Notes: "",
      exchange_rate: 0,
      add_edit_loader: false,
      customer_name: ''

    };

  }

  componentDidMount = () => {
    const {id,duplicate} =this.state;
    Promise.all([
      this.Taxlist(),
      this.projectlist()
    ]).then(() => {if (id || duplicate) this.handleTable(id || duplicate)})
  };

  Taxlist = () => {
    return new Promise((resolve, reject) => {
      APIGATEWAY.get("tax/", (response) => {
        if (response.success) {
          let taxes = response.data.taxes;
          taxes.map((el) => {
            el.checked = false;
          });
          this.setState({ Taxes: taxes }, () => resolve(taxes));
        }
      });
    })
  };

  clientlist = () => {
    APIGATEWAY.get("clients/" + this.state.client_id, (response) => {

      if (response.success) {
        let data = response?.data?.client;

        this.setState(
          {
            client_name: data?.name,
            // customer_name: data?.name?.first_name ?? '' + " " + data?.name?.last_name ?? '',
            customer_name: data?.name?.first_name +" "+data?.name?.last_name,

            GST_no: data?.gst_num,
            client_currency: data?.currency,
            client_address: data?.billing_address ?? '',
            company_name: data?.company_name ?? '',
          },
        );
      }
    });
  };

  projectlist = () => {
    return new Promise((resolve, reject) => {
      let { project_name } = this.state;
      let queryParams = "";
      if (project_name !== "") {
        queryParams = "?search_term=" + project_name;
      }
      APIGATEWAY.get("project/status/active" + queryParams, (response) => {
        if (response.success) {
          let data = response.data.projects;
  
          this.setState({
            projectlist: data,
          }, () => resolve(data));
        }
      });
    })
  };

  handleTable = (id) => {
    const { duplicate } =this.state;
    APIGATEWAY.get("invoices/" + id, (response) => {
      if (response.success) {
        let res = response.data;
        const invoice = res.invoice

        const allTaxes = [...this.state.Taxes.map(tax => {return {...tax, checked: false}}), ...invoice.tax.map(tax => {return {...tax, enable: true, checked: true}})]
        const allTaxObject = {}
        allTaxes.forEach(tax => allTaxObject[tax.tax_id] = tax)

        this.setState(
          {
            loading: false,
            data: invoice,
            project_name: invoice.project.name,
            project_id: invoice.project.project_id,
            GST_no: res?.invoice?.gst_number,
            customer_name: res?.invoice?.client?.name,
            client_id: invoice.client.client_id,
            invoice_items: invoice.items,
            dueDate: isErrorTxt(duplicate)? moment(invoice.due_date):"",
            invoiceDate: isErrorTxt(duplicate) ? moment(invoice.invoice_date):'',
            from: moment(invoice.from),
            to: moment(invoice.to),
            totalAmount: invoice.total_amount ?? 0,
            mode_type: invoice.mode_type,
            paid: moment(invoice.paid_date),

            Wholesome_amount: invoice.final_amount ?? 0,
            Notes: res?.invoice?.notes ?? "",
            Invoice_no: isErrorTxt(duplicate) ? res?.invoice?.invoice_number:'',
            exchange_rate: res?.invoice?.converted_rate,
            Taxes:  Object.values(allTaxObject)
          },
          () => {
            this.clientlist();
            this.onChangeTax(this.state.Discount_percentage);
          }
        );
      }
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  Handlechange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  addProduct = () => {
    const { invoice_items } = this.state;
    const notEmpty = this.checkEmptyProduct();

    let data = {
      name: "",
      description: "",
      rate: "",
      quantity: "",
      amount: "",
      action: true,
    };
    if (!notEmpty) {
      invoice_items.map((el) => {
        return (el.action = false);
      });

      this.setState({
        invoice_items: [...invoice_items, data],
      });
    } else {
      showError("Please enter item details");
    }
  };
  checkEmptyProduct = () => {
    let result;
    let pcrArr = this.state.invoice_items;
    for (let i = 0; i < pcrArr.length; i++) {
      let pcrItem = pcrArr[i];
      result = this.checkEachProduct(pcrItem);
    }
    return result;
  };
  checkEachProduct = (pcrItem) => {
    if (isErrorTxt(pcrItem.name)) {
      return true;
    } else if (isErrorTxt(pcrItem.quantity)) {
      return true;
    } else if (isErrorTxt(pcrItem.rate)) {
      return true;
    } else if (isErrorTxt(pcrItem.amount)) {
      return true;
    } else {
      return false;
    }
  };
  checkEmptyone = () => {
    let result;
    let pcrArr = this.state.invoice_items;

    for (let i = 0; i < pcrArr.length; i++) {
      let pcrItem = pcrArr[i];

      if (pcrArr.length === 1) {
        result = this.checkEachProduct(pcrItem);
      } else if (pcrArr.length - 1 === i) {
        if (
          isErrorTxt(pcrItem.name) &&
          isErrorTxt(pcrItem.quantity) &&
          isErrorTxt(pcrItem.rate) &&
          isErrorTxt(pcrItem.amount)
        ) {
          this.removeFormFields(i);
          result = false;
        } else {
          result = this.checkEachProduct(pcrItem);
        }
      } else {
        result = this.checkEachProduct(pcrItem);
      }
    }

    return result;
  };

  handleOnDetailInfoChange = (event, i) => {
    let pcrTab = [...this.state.invoice_items];
    let pcrItem = pcrTab[i];
    pcrItem[event.target.name] = event.target.value;

    this.setState({
      invoice_items: pcrTab,
    });
  };
  handleOnQuantityChanges = (event, i) => {

    let pcrTab = [...this.state.invoice_items];
    let pcrItem = pcrTab[i];
    let REGEX = /^\d+$/;

    if (event.target.value === "" || REGEX.test(event.target.value)) {
      if (event.target.value === "") {
        pcrItem[ event.target.name ] = (event.target.value);
      } else {
        pcrItem[event.target.name] = parseInt(event.target.value);
      }

      if (pcrTab[i].rate > 0) {
        pcrTab[i].amount = pcrTab[i].rate * event.target.value;
      }

      this.setState(
        {
          invoice_items: pcrTab,
        },
        () => {
          this.getSubtotal();
        }
      );
    }
  };

  handleOnDetailInfoChanges = (event, i) => {
    // let REGEX = /^\d+$/;
    let REGEX = /^([0-9.])+$/;
   
    let pcrTab = [...this.state.invoice_items];
    let pcrItem = pcrTab[i];
    if (event.target.value === "" || REGEX.test(event.target.value)) {
      if (event.target.value === "") {
        pcrItem[event.target.name] = event.target.value;
      } else {
        pcrItem[ event.target.name ] = (event.target.value);
      }

      pcrTab[i].amount = pcrTab[i].quantity * event.target.value;

      this.setState(
        {
          invoice_items: pcrTab,
        },
        () => {
          this.getSubtotal();
        }
      );
    }
  };
  NumberChange = (e) => {
    let Regex = /^([0-9])+$/;
    if (e.target.value === "" || Regex.test(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  handleOnInvoiceChange = (e) => {
    this.updateStateValues("invoiceDate", e);
  };

  handleOnDueChange = (e) => {
    this.updateStateValues("dueDate", e);
  };

  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };

  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };

  handleOnPaidChange = (e) => {
    this.updateStateValues("paid", e);
  };
  removeFormFields = (i) => {
    let invoice_items = this.state.invoice_items;
    invoice_items.splice(i, 1);

    invoice_items.map((el) => {
      return (el.action = false);
    });
    this.setState({ invoice_items: invoice_items });
    this.getSubtotal();
  };

  getSubtotal = () => {
    const { totalAmount } = this.state;
    let subtotal = 0;
    let invoice_items = this.state.invoice_items;
    invoice_items.forEach((item) => {
      if (item) {
        let total = item.amount;
        subtotal += +total;
      }
    });
    this.setState({ totalAmount: subtotal }, () => this.checkboxvisible());
  };

  checkboxvisible = () => {
    const { is_Discount, totalAmount } = this.state;
    let mappedArr = this.state.Taxes.map((ele) => {
      ele.tax_amount = ele.checked ? ((totalAmount * ele.percentage) / 100) : 0
      return ele;
    });
    this.add_remove_discount(is_Discount);
    this.setState({ Taxes: mappedArr }, () => this.wholesome());
  };

  // clientID = (e) => {
  //   let client = JSON.parse(e);
  //   this.setState(
  //     {
  //       client_id: client.client_id,
  //       client_name: client.name,
  //     },
  //     () => {}
  //   );
  // };

  ProjectID = (e) => {
    let project = JSON.parse(e);

    this.setState(
      {
        project_id: project.project_id,
        project_name: project.name,
        client_id: project.client_id,
        exchange_rate: 0
      },
      () => {
        this.clientlist();
      }
    );
  };

  monthFilterChange = (value) => {
    this.setState({ month_Filter: value }, () => {
      this.setDate(value);
    });
  };

  setDate = (value) => {
    if (value === "this_month") {
      this.setState({
        from: moment().startOf("month"),
        to: moment().endOf("month"),
      });
    } else if (value === "last_month") {
      this.setState({
        from: moment().startOf("month").add(-1, "month"),
        to: moment().endOf("month").add(-1, "month"),
      });
    }
  };

  handleSubmit = () => {
    let self = this;
    const {
      id,
      project_name,
      project_id,
      client_id,
      client_name,
      reference_number,
      invoice_items,
      dueDate,
      invoiceDate,
      from,
      to,
      GST_no,
      totalAmount,
      Taxes,
      Discount_percentage,
      billing_address,
      discount_amt,
      Wholesome_amount,
      company_name,
      is_Gst,
      is_Sgst,
      gst,
      sgst,
      is_Discount,
      client_address,
      client_currency,
      Invoice_no,
      Notes,
      exchange_rate,
      add_edit_loader,
    } = self.state;
    let current_date = moment().startOf("day");
    let due_date = moment(dueDate).startOf("day");
    const notEmpty = this.checkEmptyone();
    if (isErrorTxt(project_name)) {
      showError("Please select a project name");
    } else if (isErrorTxt(client_name)) {
      showError("Please enter a client name");
    } else if (isErrorTxt(Invoice_no)) {
      showError("Please enter the invoice number");
    } else if (isErrorTxt(invoiceDate)) {
      showError("Please select the invoicedate");
    } else if (isErrorTxt(dueDate)) {
      showError("Please select the duedate");
    } else if (!current_date.isSameOrBefore(due_date)) {
      showError("Duedate cannot be less than currentdate");
    }else if ( client_currency !== "inr" && isErrorTxt(exchange_rate)) {
      showError("Please enter the exchnage rate ");
    }
    else if (client_currency !=="inr" && exchange_rate <= 0) {
      showError("Please enter the exchnage rate greater than zero");
    }

    else if (notEmpty && invoice_items.length === 1) {
      showError("Please enter atleast 1 item");
    } else if (notEmpty) {
      showError("Please enter item details");
    } else {
      let invoice_item_arr = this.state.invoice_items;
      invoice_item_arr.forEach((el) => {
        delete el.action;
      });

      let tax = [];
      Taxes.forEach(({checked, _id, name, percentage, description, tax_id, tax_amount}) => {
        checked && tax.push({_id, name, percentage, description, tax_id, tax_amount});
      });
      this.setState({ add_edit_loader: true });

      let datas = {
        project: {
          name: project_name,
          project_id: project_id,
        },
        client: {
          name: this.state.client_name
            ? showFullName(this.state.client_name)
            : "",
          client_id: client_id,
          client_address: client_address,
          currency: client_currency,
          company_name:
            company_name !== ""
              ? company_name
              : this.state.client_name
                ? showFullName(this.state.client_name)
                : "",
        },
        items: invoice_item_arr,
        from: from,
        to: to,
        invoice_date: invoiceDate,
        due_date: dueDate,
        total_amount: totalAmount,
        final_amount: Wholesome_amount,
        tax: tax,
        gst_number: GST_no,
        invoice_number: Invoice_no,
        notes: Notes,
        converted_rate: exchange_rate
      };
      if (id) {
        APIGATEWAY.patch("invoices/" + id, datas, (response) => {
          if (response.success) {
            showSuccess(response.message);
            this.setState({ add_edit_loader: false });
            if(this.state.stateId) this.props.history.push({pathname:`/projects/detailspage/${this.state.stateId}`,state:{from:'invoice'}})
           else  this.props.history.push("/invoices");
            // this.props.history.goBack()

          } else {
            this.setState({ add_edit_loader: false });
            showError(response.message);
          }
        });
      } else {
        APIGATEWAY.post("invoices", datas, (response) => {
          if (response.success) {
            showSuccess(response.message);

            this.setState({ add_edit_loader: false });
            if(this.state.stateId) this.props.history.push({pathname:`/projects/detailspage/${this.state.stateId}`,state:{from:'invoice'}})
            else  this.props.history.push("/invoices");
          } else {
            this.setState({ add_edit_loader: false });
            showError(response.message);
          }
        });
      }
    }
  };
  Discount = (e) => {
    this.setState({ is_Discount: e.target.checked }, () =>
      this.add_remove_discount(e.target.checked)
    );
  };

  add_remove_discount = (check) => {
    const { Discount_percentage, totalAmount } = this.state;
    if (check) {
      this.setState({ discount_amt: 0 });
      if (check && Discount_percentage !== 0) {
        let discount = (totalAmount * Discount_percentage) / 100;
        this.setState({ discount_amt: discount }, () => this.wholesome());
      } else {
        this.setState({ discount_amt: 0 }, () => this.wholesome());
      }
    } else {
      this.setState({ discount_amt: 0 }, () => this.wholesome());
    }
  };

  wholesome = () => {
    const { discount_amt, totalAmount, Taxes } = this.state;

    let Taxarr = Taxes;
    let sum = 0;

    Taxarr.forEach((el) => {
      if (el.checked === true && el.tax_amount) {
        sum += el.tax_amount;
      }
    });

    this.setState({ Wholesome_amount: totalAmount + sum - discount_amt });
  };

  removeImage = () => {
    this.setState({ selectedImage: null });
  };
  editAddress = (value) => {
    this.setState({ is_Address: !value });
  };
  imageUpload = (e) => {
    if (e.target == null) {
      this.removeImage();
    } else {
      this.setState({ selectedImage: e.target.files[0] });
    }
  };
  onChangeTax = (value) => {
    const { is_Discount } = this.state;

    this.setState({ Discount_percentage: value }, () =>
      this.add_remove_discount(is_Discount)
    );
  };

  toggleModal = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };

  ModalonChange = () => {
    const {
      project_id,
      project_name,
      client_id,
      client_name,
      invoiceDate,
      dueDate,
      GST_no,
      Invoice_no,
      invoice_items,
    } = this.state;
    let notEmpty = this.checkEmptyone();

    if ((isErrorTxt(project_id) && isErrorTxt(project_name))) {
      showError("Please select a project name");
    } else if (isErrorTxt(client_id) && isErrorTxt(client_name)) {
      showError("Please enter a client name");
    } else if (isErrorTxt(Invoice_no)) {
      showError("Please enter the Invoice no");
    } else if (isErrorTxt(invoiceDate)) {
      showError("Please select the invoiceDate");
    } else if (isErrorTxt(dueDate)) {
      showError("Please select the dueDate");
    } else if (notEmpty && invoice_items.length === 1) {
      showError("Please enter at least 1 item");
    } else if (notEmpty) {
      showError("Please enter Item details");
    } else {
      this.setState({ modalVisible: !this.state.modalVisible });
    }
  };
  onChangegst = (e) => {
    this.setState({ GST_no: e.target.value });
  };
  Taxcalc = (e, i) => {
    let taxarr = [...this.state.Taxes];
    const checked = e.target.checked

    taxarr[i].checked = checked
    taxarr[i].tax_amount = checked ? ((this.state.totalAmount * taxarr[i].percentage) / 100) : 0

    this.setState({ Taxes: [...taxarr] }, () => {
      this.wholesome();
    });
  };

  onChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onChangeNotes = (e) => {
    let charlimit = 35;

    let lines = e.target.value.split("\n");
    for (var i = 0; i < lines.length; i++) {
      if (lines[i].length <= charlimit) continue;
      var j = 0;
      let space = charlimit;

      while (j++ <= charlimit) {
        if (lines[i].charAt(j) === " ") space = j;
      }
      lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
      lines[i] = lines[i].substring(0, space);
    }
    e.target.value = lines.slice(0, 10).join("\n");
    this.setState({ Notes: e.target.value });
  };
  handleNumberChange = (event) => {
    // let REGEX = /^\d+$/;
    let REGEX = /^([0-9.])+$/;
    if (event.target.value === "" || REGEX.test(event.target.value))
      this.setState({ [event.target.name]: event.target.value });
  };


  render() {
    const {
      dueDate,
      invoiceDate,
      totalAmount,
      id,
      is_Discount,
      discount_amt,
      GST_no,
      Wholesome_amount,
      selectedImage,
      Discount_percentage,
      Taxes,
      client_address,
      company_name,
      Invoice_no,
      Notes,
      client_currency,
      client_name,
      project_id,
      client_id,
      project_name,
      exchange_rate
    } = this.state;
    let pcr = [];

    let { invoice_items } = this.state;
    if (invoice_items) {
      let data = null;
      for (let i = 0; i < invoice_items.length; i++) {
        let pcrItem = invoice_items[i];
        let len = invoice_items.length - 1;

        if (len === i) {
          data = {
            sno: i + 1,
            name: pcrItem.name,
            description: pcrItem.description,
            quantity: pcrItem.quantity,
            rate: pcrItem.rate,
            amount: pcrItem.amount,
            action: true,
          };
        } else {
          data = {
            sno: i + 1,
            name: pcrItem.name,
            description: pcrItem.description,
            quantity: pcrItem.quantity,
            rate: pcrItem.rate,
            amount: pcrItem.amount,
            action: false,
          };
        }

        pcr.push(data);
      }
    }

    let column = [
      {
        title: "",
        render: (text, record, index) => (
          <>
            {record?.action ? (
              <div className="invoice_btn">
                <img
                  src={Add}
                  style={{ width: "24px" }}
                  className="add_icon_class"
                  onClick={() => this.addProduct()}
                />
              </div>
            ) : (
              <div className="invoice_btn">
                <img
                  src={Remove}

                  style={{ width: "24px" }}
                  onClick={() => this.removeFormFields(index)}
                />
              </div>
            )}
          </>
        ),
      },
      {
        title: () => {
          return (
            <div className="flex-sp">
              Item<span className="star">*</span>
            </div>
          );
        },
        render: (text, record) => (
          <div className="invoice_td">
            <Input
              name="name"
              value={record.name}
              placeholder="Please enter Item name"
              onChange={(event) => {
                this.handleOnDetailInfoChange(event, record.sno - 1);
              }}
              autoComplete="off"
              style={{ fontWeight: "500", marginTop: "10px", height: "47px" }}
              className="onboard_date"
            />
          </div>
        ),
      },

      {
        title: () => {
          return <div className="flex-sp">Description</div>;
        },
        render: (text, record) => (
          <div className="invoice_td" style={{ cursor: "pointer" }}>
            {record.description?.length >= 50 ? (
              <Popover content={record.description}>
                <Input
                  name="description"
                  value={record.description}
                  placeholder="Please enter Item description"
                  onChange={(event) =>
                    this.handleOnDetailInfoChange(event, record.sno - 1)
                  }
                  autoComplete="off"

                  style={{
                    fontWeight: "500",
                    marginTop: "10px",
                    height: "47px",
                    maxWidth: ' 10rem',
                    minWidth: '29rem',
                  }}
                  className="onboard_date"
                />
              </Popover>
            ) : (
              <Input
                name="description"
                value={record.description}
                placeholder="Please enter Item description"
                onChange={(event) =>
                  this.handleOnDetailInfoChange(event, record.sno - 1)
                }
                style={{
                  fontWeight: "500", marginTop: "10px", height: "47px", maxWidth: ' 10rem',
                  minWidth: '29rem'
                }}
                className="onboard_date"
                autoComplete="off"
              />
            )}
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div className="flex-sp">
              Quantity<span className="star">*</span>
            </div>
          );
        },
        render: (text, record) => (
          <div className="invoice_td">
            <Input
              name="quantity"

              value={record.quantity}
              placeholder="Quantity"
              onChange={(event) =>
                this.handleOnQuantityChanges(event, record.sno - 1)
              }
              autoComplete="off"

              style={{ fontWeight: "500", marginTop: "10px", height: "47px", maxWidth: '6rem' }}
              className="onboard_date"
            />
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div className="flex-sp">
              Rate<span className="star">*</span>
            </div>
          );
        },
        render: (text, record) => (
          <div className="invoice_td">
            
            <Input
              name="rate"
              
              value={record.rate}
              placeholder="Rate"
              onChange={(event) =>
                this.handleOnDetailInfoChanges(event, record.sno - 1)
              }
              style={{ fontWeight: "500", marginTop: "10px", height: "47px", maxWidth: '6rem' }}
              className="onboard_date"
              autoComplete="off"

            />
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div className="flex-sp">
              Amount<span className="star">*</span>
            </div>
          );
        },
        render: (text, record) => (
          <div className="invoice_td">
           
            <Input
              name="amount"
              value={record.amount}
              placeholder=""
              onChange={(event) =>
                
                this.handleOnTotalAmount(event, record.sno - 1)
              }
              style={{ fontWeight: "500", marginTop: "10px", height: "47px", maxWidth: '6rem' }}
              className="onboard_date"
              readOnly={true}
            />
          </div>
        ),
      },
    ];
    // result = sqrt( stringContainingANumber );
    let result =123
   let decimal = new RegExp( "." );
    let value = decimal.test( result )
    // let value = decimal.test( result )

   
    return (
      <div style={{ marginBottom: "100px" }}>
        <TopHeader
          title="Invoices"
          backClick={this.goBack}
          submit={this.handleSubmit}
          id={id}
          spin={this.state.add_edit_loader}
        />
        {id && this.state.loading ? (
          <div className="tableview">
            <Spin />
          </div>
        ) : (

          <div

            className="invoice_card"
          >
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
              className="invoice_view"
            >
              <Col className="gutter-row" span={20}>
                <span className="invoice_view_info">
                  Just view your invoice before sending it
                </span>
              </Col>
              <Col className="gutter-row invoice_img_con" span={4}>
                <div className="preview_block">
                  <span className="invoice_preview">
                    <img src={Preview}></img>
                  </span>
                  <span className="invoice_preview">Preview</span>
                  <Button onClick={this.ModalonChange} className="preview_show">
                    preview
                  </Button>
                </div>
              </Col>
            </Row>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
              className="invoice_block"
            >
              <Col
                className="gutter-row"
                span={17}
                style={{ marginBottom: "50px" }}
              >
                <span className="Invoice_company_name">Quadkast Private Ltd</span>
                <p className="Invoice_company_addr">
                  Plot 12C, 1, 3rd Cross St, South Phase, Guindy Industrial
                  Estate, <br /> Guindy, Chennai, Tamil Nadu 600032<br></br>
                  GSTIN<span className="hypen">-</span>
                  <span className="font-600">33AAACQ4345B1ZZ</span>
                </p>
              </Col>
              <Col className="gutter-row Invoice_company_logo" span={7}>
                <div className="company_logo_block">


                  {selectedImage === null ? (
                    <img src={Logo} />
                  ) : (
                    <>
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        width="100%"
                        height="100px"
                      />
                      <Button
                        onClick={this.removeImage}
                        className="image_close_btn"
                      >
                        X
                      </Button>
                    </>
                  )}
                </div>
              </Col>

              <Col span={11} className="invoice_select_input">
                <div className="invoice_input_label">
                  Project Name<span className="star">*</span>
                </div>
                <Select
                  name="project_name"
                  placeholder="Select Project Name"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  value={this.state.project_name || undefined}
                  showSearch
                  style={{ width: "50%" }}
                  className="blood add_placeholder"
                  onChange={(value) => this.ProjectID(value)}
                >
                  {this.state.projectlist.map((item, i) => (
                    <Option key={i} value={JSON.stringify(item)}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col span={11} offset={2} className="invoice_select_input">
                <div className="invoice_input_label">
                  Customer Name<span className="star">*</span>
                </div>
                <Input
                  name="client_name"
                  // value={
                  //   client_name && client_name !== ""
                  //     ? showFullName(client_name)
                  //     : ""
                  // }
                  value={this.state.customer_name}
                  style={{
                    fontWeight: "500",

                    height: "47px",
                    width: "50%",
                  }}

                  className="onboard_date"
                  placeholder="Customer Name"
                  readOnly={true}

                />
              </Col>

              <Col span={11} className="invoice_select_input">
                <div className="invoice_input_label">
                  GST No<span className="star"></span>
                </div>
                <Input
                  name="gst_no"
                  value={GST_no}

                  style={{ height: "47px", width: "50%" }}
                  placeholder="GST No"
                  className="onboard_date"

                  readOnly={true}
                />
              </Col>
                <Col span={11} offset={2} className="invoice_select_input">
                  <div className="invoice_input_label">
                    Invoice No<span className="star">*</span>
                  </div>
                  <Input
                    name="Invoice_no"
                    value={Invoice_no}
                    // type="number"
                    style={{ height: "47px", width: "50%" }}
                    placeholder="Invoice No"
                    className="onboard_date"
                    onChange={(e) => this.onChangeInput(e)}
                  />
                </Col>

              <Col span={11} className="invoice_select_input">
                <div className="invoice_input_label">
                  Invoice Date<span className="star">*</span>
                </div>

                <DatePicker
                  selected={invoiceDate}
                  dateFormat="MM-dd-yyyy"
                  placeholder="Invoice Date"
                  name="invoiceDate"
                  id="invoiceDate"
                  value={invoiceDate}
                  className="invoice_Datepickers"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  inputReadOnly
                  showYearDropdown
                  showMonthDropdown
                  ariaDescribedBy="basic-addon2"
                  onChange={this.handleOnInvoiceChange}
                />
              </Col>

                <Col span={11} offset={2} className="invoice_select_input">
                <div className="invoice_input_label">
                  Due Date<span className="star">*</span>
                </div>

                <DatePicker
               
                  dateFormat="MM-dd-yyyy"
                  placeholder="Due Date"
                  name="dueDate"
                  id="dueDate"
                  value={dueDate}
                  className="invoice_Datepickers"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  inputReadOnly
                  showYearDropdown
                  showMonthDropdown
                  ariaDescribedBy="basic-addon2"
                  onChange={this.handleOnDueChange}
                  disabledDate={(current) => {return moment().add(-1, "days") > current;}}
                />
              </Col>
              
              <Col span={11} className="invoice_select_input">
                <div className="invoice_input_label ">Notes</div>
                <Input.TextArea
                  name="Notes"
                  value={Notes}

                  onChange={(e) => this.onChangeNotes(e)}

                  cols={10}
                  style={{ height: "47px", width: "50%", padding: "10px" }}
                  placeholder="Notes"
                  id="invoice_note_input"
                  className="onboard_date "
                  maxLength={250}

                />

              </Col>
              {client_currency === 'inr' || client_currency === '' ? "" : (
                <Col span={11} offset={2} className="invoice_select_input">
                  <div className="invoice_input_label">
                    <span className="text-uppercase mr-1"> ({client_currency}) </span><span>Exchange Rate</span><span className="star">*</span>
                  </div>
                  <Input
                    name="exchange_rate"
                    value={exchange_rate}

                    style={{ height: "47px", width: "50%" }}
                    placeholder="Exchange Rate"
                    className="onboard_date"
                    onChange={this.handleNumberChange}
                  />

                </Col>
              )}

              {company_name || client_address ? (
                <>

                  <div className="invoice_addr_edit_block">
                    <Col className="gutter-row" span={22}>
                      <>

                        <p className="quadkast_comp_name">
                          {isErrorTxt(company_name) ? '' : company_name !== ""
                            ? company_name
                            : client_name
                              ? showFullName(client_name)
                              : ""}
                        </p>
                        {isErrorTxt(client_address?.address) ? '' : (
                          <>
                            <p className="quadkast_addr_detail long_addr_desc">
                              {isErrorTxt(client_address?.address?.address1) ? '' : `${client_address?.address?.address1}, `}
                              {isErrorTxt(client_address?.address?.address2) ? '' : `${client_address?.address?.address2.split(",")
                                .join(", ")}`}
                            </p>

                            <p className="quadkast_addr_detail">
                              {isErrorTxt(client_address?.city) ? '' : `${client_address?.city},`}
                              {isErrorTxt(client_address?.state) ? '' : `${client_address?.state},`}
                              {isErrorTxt(client_address?.country) ? '' : `${client_address?.country}`}
                              {(isErrorTxt(client_address?.country) ? '' : client_address?.country) && (isErrorTxt(client_address?.zip_code) ? '' : client_address?.zip_code) && ` - `}

                              {isErrorTxt(client_address?.zip_code) ? '' : `${client_address?.zip_code} `}
                            </p>
                          </>
                        )}
                        <p>
                          {isErrorTxt(GST_no) ? '' : client_currency === 'inr' && GST_no && GST_no !== "" ? (
                            <>
                              <p className="quadkast_addr_detail mt-3">
                                GSTIN<span className="hypen">-</span>
                                <span className="quadkast_gst">{GST_no}</span>
                              </p>
                            </>
                          ) : (
                            ""
                          )}
                        </p>
                      </>
                    </Col>
                  </div>
                </>
              ) : (
                ""
              )}
            </Row>
            <div className="antd_row">
              <div
                className="sm__grid--col-4 add-table"
                style={{ width: "100%" }}
              >
                <div className="invoice_table">
                  <Table columns={column} dataSource={pcr} pagination={false} />
                </div>
              </div>

              <div className="invoice_tot">
                <div className="wrap">
                  <div className="invoice_checkbox">
                    <div className="invoice_flex">
                      <p className="total_heading">Sub Total</p>

                      <h5
                        className="invoice_taxes"
                        style={{ color: "#303030", width: "150px" }}
                      >
                          <span className={client_currency === "aed" ? "m-right":'' }> {showCurrency(client_currency)}</span>
                        {totalAmount === "" || totalAmount === 0
                          ? 0
                          : numberFormatWithCurrency(
                            totalAmount,
                            client_currency
                          )}
                      </h5>
                    </div>

                    {Taxes.map((el, i) => (
                        el.enable && <div className="invoice_flex">
                          <Checkbox
                            onChange={(e) => this.Taxcalc(e, i)}
                            checked={el.checked ? true : false}
                            className={el.checked ? "" : "invoice_title"}
                          >
                            {el.name + " " + el.percentage + "%"}
                          </Checkbox>

                          <div
                            className={
                              el.enable ? "default_color" : "invoice_taxes"
                            }
                          >
                            <span className="m-right"> {el.checked && showCurrency(client_currency)}</span>
                            {el.checked
                              ? numberFormatWithCurrency(el.tax_amount, client_currency)
                              : 0}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <hr className="hr" />
              <div className="invoice_tot">
                <div className="wrapper">
                  <div>
                    <p className="total_heading">Total Amount</p>
                  </div>
                  <div>
                    <h6 className="invoice_taxes" style={{ color: "#303030" }}>
                        <span className="m-right">{showCurrency(client_currency)}</span>  {totalAmount === "" || totalAmount === 0
                        ? 0
                        : 
                          numberWithRupees( Wholesome_amount )
                          }
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}


        <Modal
          isOpen={this.state.modalVisible}
          size="lg"

          toggle={() => this.toggleModal()}
          centered
          className="invoice_preview_show_modal"
        >
          <div style={{ padding: "20px 35px" }}>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
              className="invoice_preview_show_con"
            >
              <Col span={24}>
                <div className="invoice_logo_titile_block">
                  <div>
                    <img src={Logo}></img>
                  </div>
                  <div className="tax_invoice_title">
                    <span>Tax Invoice</span>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="invoice_logo_titile_block">
                  <div>
                    <span
                      className="invoice_no_title"
                      style={{ paddingBottom: "20px" }}
                    >
                      INVOICE NO
                    </span>
                    <br />
                    <span className="invoice_number">{Invoice_no ?? "-"}</span>
                  </div>
                  <div>
                    <span></span>
                    <br />
                    <span className="invoice_due_dates">INVOICE DATE :</span>
                    <span className="invoice_due_dates">
                      {invoiceDate ? utcdate(invoiceDate) : "-"}
                    </span>
                    <span
                      className="invoice_due_dates"
                      style={{ paddingLeft: "25px" }}
                    >
                      DUE ON :
                    </span>
                    <span className="invoice_due_dates">
                      {dueDate ? utcdate(dueDate) : "-"}
                    </span>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="flex-center" style={{ marginTop: "33px" }}>
                  <div className="flex-center wid-48">
                    <p>FROM</p>
                    <p className="fromborder"></p>
                  </div>
                  <div className="flex-center  wid-48">
                    <p>TO</p>
                    <p className="toborder"></p>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="flex-top">
                  <div className=" wid-48">
                    <p className="quadkast_comp_name">
                      Quadkast Technologies Private limited
                    </p>
                    <p className="quadkast_addr_detail long_addr_desc">
                      Plot 12C, 1, 3rd Cross St, South Phase,<br></br>
                      Guindy Industrial Estate, Guindy,
                    </p>

                    <p className="quadkast_addr_detail">
                      Chennai, Tamil Nadu 600032
                    </p>
                  </div>
                  <div className=" wid-48">
                    <p className="quadkast_comp_name">
                      {isErrorTxt(company_name) ? '' : company_name !== ""
                        ? company_name
                        : client_name
                          ? showFullName(client_name)
                          : ""}
                    </p>
                    {isErrorTxt(client_address?.address) ? '' : (
                      <>
                        <p className="quadkast_addr_detail long_addr_desc">
                          {isErrorTxt(client_address?.address?.address1) ? '' : `${client_address?.address?.address1},`}
                          {isErrorTxt(client_address?.address?.address2) ? '' : `${client_address?.address?.address2.split(",")
                            .join(", ")}`}
                        </p>

                        <p className="quadkast_addr_detail">
                          {isErrorTxt(client_address?.city) ? '' : `${client_address?.city},`}
                          {isErrorTxt(client_address?.state) ? '' : `${client_address?.state},`}
                          {isErrorTxt(client_address?.country) ? '' : `${client_address?.country}`}
                          {(isErrorTxt(client_address?.country) ? '' : client_address?.country) && (isErrorTxt(client_address?.zip_code) ? '' : client_address?.zip_code) && ` - `}

                          {isErrorTxt(client_address?.zip_code) ? '' : `${client_address?.zip_code} `}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="flex-top mt-4">
                  <div className=" wid-48">
                    <p className="quadkast_addr_detail">
                      GSTIN<span className="hypen">-</span>
                      <span className="quadkast_gst">333AAACQ434B1ZZ</span>
                    </p>
                  </div>
                  <div className=" wid-48">
                    {" "}
                    {isErrorTxt(GST_no) ? '' : client_currency === 'inr' && GST_no && GST_no !== "" ? (
                      <>
                        <p className="quadkast_addr_detail">
                          GSTIN<span className="hypen">-</span>
                          <span className="quadkast_gst">{GST_no ?? "-"}</span>
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div
                  className="flex-center"
                  style={{ width: "27%", marginTop: "32px" }}
                >
                  <p className="place_of_supply">
                    Place Of Supply{" "}
                    <span style={{ paddingRight: "3px" }}>:</span>
                  </p>

                  <p className="place_of_state">Tamil Nadu (33)</p>
                </div>
              </Col>
              <Col span={24}>
                <div className="preview-table-wrap">
                  <div className="preview-table-responsive">
                    <table className="preview_table table">
                      <thead>
                        <tr>
                          <th scope="col">Item & Description</th>
                          <th scope="col" style={{ textAlign: "end" }}>
                            Quantity
                          </th>
                          <th scope="col" style={{ textAlign: "end" }}>
                            Rate
                          </th>
                          <th scope="col" style={{ textAlign: "end" }}>
                            Amount
                          </th>
                        </tr>
                      </thead>
                      {pcr.length > 0 ? (
                        <tbody>
                          {pcr.map((item, i) => (
                            <tr>
                              <td scope="row">
                                <span className="preview_item_name">
                                  {item.name ?? ""}
                                </span>
                                <br></br>
                                <span className="preview_item_desc">
                                  {item.description ?? ""}
                                </span>
                              </td>
                              <td className="preview_item_common">
                                {item.quantity ?? "-"}
                              </td>
                              <td className="preview_item_common">
                                {item.rate ? (
                                  <>
                                    {showCurrency( client_currency )}

                                    {numberFormatWithCurrency(
                                      item.rate,
                                      client_currency
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td className="preview_item_common">
                                {item.amount ? (
                                  <>
                                    {showCurrency( client_currency )}

                                    {numberFormatWithCurrency(
                                      item.amount,
                                      client_currency
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>no data</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="flex-top" style={{ marginTop: "10px" }}>

                  <div style={{ marginLeft: "30px", width: "30%" }}>
                    {isErrorTxt(Notes) ? "" : (
                      <>
                        <p className="Notes_title">Notes</p>
                        <p className="Notes_desc">{Notes}</p>
                      </>
                    )}
                  </div>


                  <div style={{ width: "24%", marginRight: "27px" }}>
                    <div className="flex-top margin_bottom_10">
                      <p className="sub_amount_title">Sub Total</p>
                      <p className="sub_amount">
                        <span>
                          {showCurrency( client_currency )}

                          {totalAmount === "" || totalAmount === 0
                            ? ""
                            : numberFormatWithCurrency(
                              totalAmount,
                              client_currency
                            )
                            }
                        </span>
                      </p>
                    </div>

                    {is_Discount ? (
                      <div className="flex-top margin_bottom_10">
                        <p className="Discount_title">
                          Discount {Discount_percentage} %
                        </p>
                        <p className="Discount_amount">
                          {" "}
                          {/* {client_currency !== ""
                            ? showCurrency(client_currency)
                            : ""} */}
                          <span>
                            {is_Discount
                              ? numberFormatWithCurrency(
                                discount_amt,
                                client_currency
                              )
                              : 0}
                          </span>
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    {Taxes.map((el) => {
                      return (el.enable && el.checked === true) ? (
                        <div className="flex-top margin_bottom_10">
                          <p className="Tax_name_title">
                            {el.name + " " + el.percentage + "%"}
                          </p>
                          <p className="Taxes_amount">
                            <span>
                              {el.checked && showCurrency( client_currency )}

                              {el.checked
                                ? numberFormatWithCurrency(
                                  el.tax_amount,
                                  client_currency
                                )
                                : 0}
                            </span>
                          </p>
                        </div>
                      ) : (
                        ""
                      );
                    })}
                    <div
                      style={{ border: "0.651458px solid #E0E0E0" }}
                      className=" margin_bottom_10"
                    ></div>

                    <div className="flex-top margin_bottom_10">
                      <p className="final_amt_title">Total Amount</p>
                      <p className="final_amount">
                        <span>
                          {showCurrency( client_currency )}{totalAmount === "" || totalAmount === 0
                            ? ""
                            : numberWithRupees(
                              Wholesome_amount
                            )}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}
