import React, { Component } from "react";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import "../../stylesheets/styles.css";
import CreateTask from "./CreateTask";
import MyTaskCard from "./MyTaskCard";
import RecurringTask from "./RecurringTask";
import { Tabs, Button, Drawer, Select } from "antd";
import TaskCard from "./TaskCard";
const { Option } = Select;
const { TabPane } = Tabs;

class Task extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      taskAdded: false,
      edit: false,
      all_tasks: [],
      taskAll: [],
      myTask: [],
      recurrenceTask: [],
      taskDetails: [],
      filter: "all",
      searchName: "",
      completedAllTask: [],
      completedMyTask: [],
      completedRecurrence: [],
      deletedAllTask: [],
      deletedMyTask: [],
      deletedRecurrence: [],
      filteredAllTasks: [],
      filteredMyTask: [],
      filteredRecurrenceTask: [],
      filteredCompletedAllTask: [],
      filteredCompletedMyTask: [],
      filteredCompletedRecurrenceTask: [],
      filteredDeletedAll: [],
      filteredDeletedMy: [],
      filteredDeletedRecurrence: [],
    };
  }

  componentDidMount() {
    APIGATEWAY.get("/tasks?page=1&limit=10", (response) => {
      this.setState({ all_tasks: response.data.tasks }, () => {
        this.taskCard();
      });
    });
  }

  taskCard() {
    let { all_tasks } = this.state;
    let dataCreated = [];
    let dataCompleted = [];
    let dataDeleted = [];
    let myTaskData = [];
    let myTaskCompleted = [];
    let myTaskDeleted = [];
    let dataRecurrence = [];
    let dataRecurrenceCompleted = [];
    let dataRecurrenceDeleted = [];

    all_tasks.map((tasks) => {
      if (tasks.status === "created") {
        dataCreated = [...dataCreated, tasks];
      } else if (tasks.status === "completed") {
        dataCompleted = [...dataCompleted, tasks];
      } else if (tasks.status === "deleted") {
        dataDeleted = [...dataDeleted, tasks];
      }
    });

    dataCreated.map((task) =>
      task.to === "employer" ? (myTaskData = [...myTaskData, task]) : null
    );
    dataCompleted.map((task) =>
      task.to === "employer"
        ? (myTaskCompleted = [...myTaskCompleted, task])
        : null
    );
    dataDeleted.map((task) =>
      task.to === "employer" ? (myTaskDeleted = [...myTaskDeleted, task]) : null
    );

    dataCreated.map((task) =>
      task.recurrence ? (dataRecurrence = [...dataRecurrence, task]) : null
    );
    dataCompleted.map((task) =>
      task.recurrence
        ? (dataRecurrenceCompleted = [...dataRecurrenceCompleted, task])
        : null
    );
    dataDeleted.map((task) =>
      task.recurrence
        ? (dataRecurrenceDeleted = [...dataRecurrenceDeleted, task])
        : null
    );
    this.setState(
      {
        taskAll: dataCreated,
        completedAllTask: dataCompleted,
        deletedAllTask: dataDeleted,
        myTask: myTaskData,
        completedMyTask: myTaskCompleted,
        deletedMyTask: myTaskDeleted,
        recurrenceTask: dataRecurrence,
        completedRecurrence: dataRecurrenceCompleted,
        deletedRecurrence: dataRecurrenceDeleted,
      },
      () => {
        this.onSearchTask();
      }
    );
  }

  onSearchTask = () => {
    let {
      completedAllTask,
      completedMyTask,
      completedRecurrence,
      deletedMyTask,
      deletedAllTask,
      deletedRecurrence,
      myTask,
      recurrenceTask,
      searchName,
      taskAll,
    } = this.state;
    let all = [];
    let allCompleted = [];
    let allDeleted = [];
    let myCompleted = [];
    let my = [];
    let myDeleted = [];
    let rec = [];
    let recCompleted = [];
    let recDeleted = [];
    all = taskAll.filter((task) =>
      task.name.toLowerCase().includes(searchName.toLowerCase())
    );
    allCompleted = completedAllTask.filter((task) =>
      task.name.toLowerCase().includes(searchName.toLowerCase())
    );
    allDeleted = deletedAllTask.filter((task) =>
      task.name.toLowerCase().includes(searchName.toLowerCase())
    );
    my = myTask.filter((task) =>
      task.name.toLowerCase().includes(searchName.toLowerCase())
    );
    myCompleted = completedMyTask.filter((task) =>
      task.name.toLowerCase().includes(searchName.toLowerCase())
    );
    myDeleted = deletedMyTask.filter((task) =>
      task.name.toLowerCase().includes(searchName.toLowerCase())
    );
    rec = recurrenceTask.filter((task) =>
      task.name.toLowerCase().includes(searchName.toLowerCase())
    );
    recCompleted = completedRecurrence.filter((task) =>
      task.name.toLowerCase().includes(searchName.toLowerCase())
    );
    recDeleted = deletedRecurrence.filter((task) =>
      task.name.toLowerCase().includes(searchName.toLowerCase())
    );

    this.setState({
      filteredAllTasks: all,
      filteredMyTask: my,
      filteredRecurrenceTask: rec,
      filteredCompletedAllTask: allCompleted,
      filteredCompletedMyTask: myCompleted,
      filteredCompletedRecurrenceTask: recCompleted,
      filteredDeletedAll: allDeleted,
      filteredDeletedMy: myDeleted,
      filteredDeletedRecurrence: recDeleted,
    });
  };



  showDrawer = () => {
    this.setState({ visible: true, edit: false });
  };

  openDrawer = () => {
    this.setState({ visible: true });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  onSave = () => {
    this.onClose();
    this.setState({ taskAdded: true });
  };

  filterChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.onSearchTask();
    });
  };

  render() {
    let { visible, searchName } = this.state;

    return (
      <div className="expensescontainer">
        <h2 className="h4 gx-mb-3">Tasks</h2>
        <div className="taskss">
          <div className="searches_under_tabs">
            <div className="search_filter_create">
              <div>
                <input
                  type="text"
                  className="task_searchbox"
                  placeholder="search Tasks"
                  name="searchName"
                  value={searchName}
                  onChange={this.onChange}
                />
              </div>
              <div className="filter_right">
                <div>
                  <select
                    className="filter"
                    name="filter"
                    onChange={this.filterChange}
                  >
                    <option value="all">All</option>
                    <option value="pastDue">Past Due</option>
                    <option value="nodue">No Due</option>
                    <option value="today">Today</option>
                    <option value="tommorrow">Tommorrow</option>
                    <option value="upcomming">Upcomming</option>
                    <option value="completed">Completed</option>
                    {/* <option value="deleted">Deleted</option> */}
                  </select>
                </div>
                <div className="create_button">
                  <Button
                    type="primary"
                    className="createTask_button"
                    onClick={() => this.showDrawer()}
                  >
                    Create Task
                  </Button>
                  <Drawer
                    placement="right"
                    onClose={this.onClose}
                    open={visible}
                    width="600px"
                  >
                    <CreateTask
                      cancel={this.onClose}
                      save={this.onSave}
                      open={this.openDrawer}
                    />
                  </Drawer>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Tabs defaultActiveKey="1">
              <TabPane tab="All Tasks" key="1" className="all_tabs">
                <div className="task_details">
                  <TaskCard
                    filter={this.state.filter}
                    tasks={this.state.filteredAllTasks}
                    completedTask={this.state.filteredCompletedAllTask}
                    deletedTask={this.state.filteredDeletedAll}
                    search={this.state.searchName}
                  />
                </div>
              </TabPane>
              <TabPane tab="My Tasks" key="2">
                <div className="task_details">
                  <MyTaskCard
                    tasks={this.state.filteredMyTask}
                    completedTask={this.state.filteredCompletedMyTask}
                    deletedTask={this.state.filteredDeletedMy}
                    search={this.state.searchName}
                    filter={this.state.filter}
                  />
                </div>
              </TabPane>
              <TabPane tab="Recurring Tasks" key="3">
                <div className="task_details">
                  <RecurringTask
                    tasks={this.state.filteredRecurrenceTask}
                    completedTask={this.state.filteredCompletedRecurrenceTask}
                    deletedTask={this.state.filteredDeletedRecurrence}
                    search={this.state.searchName}
                    filter={this.state.filter}
                  />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default Task;
