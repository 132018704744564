import React, { useState, useRef, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Select from "react-select";
import vector from "../../assets/Vector.png";
import TopHeader from "../../components/ui/TopHeader";
import moment from "moment";
import close from "../../assets/close_symbol.svg";
import { message } from "antd";
import { DatePicker, Drawer,Empty,Spin } from "antd";
import { Link, useHistory,useParams } from "react-router-dom/cjs/react-router-dom";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import {
  showDayMonthYear,
  projectColor,
  useDebounce,
  getStatusColor
} from "../../constants/Utils";
import layer from "../../assets/Layer 2.svg";
import TableField from "../../components/ui/tableField";
import UpdateProjectComponent from "./updateProjectComponent";


function ReferenceList(props) {
  const params=useParams()
  const projectId = props.location.state;
  const history = useHistory();
  const referenceStatusOptions =[
    { value: "Active", key: "active" },
    { value: "Deleted", key: "deleted" },
  ]

  const goBack = () => {
    history.goBack(); // This will navigate back to the previous path
  };
  const [selectedOption, setSelectedOption] = useState(null);
  //DropDown using antDesign
  const optionsThisMonth = [
    { value: "this_month", label: "This Month" },
    { value: "last_month", label: "Last Month" },
    { value: "this_year", label: "This Year" },
    { value: "last_year", label: "Last Year" },

  ];

  const optionsStatus = [
    { value: "all", label: " All" },
    { value: "active", label: "Active" },
    { value: "deleted", label: "Deleted" },
  ];

  const newStyles = {
    control: (provided, state) => ({
      ...provided,

      width: "40px !important",
      lineHeight: "13px",
      color: "#214757",
      boxShadow: "none",
      outline: "none",
      border: 0,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "13px",
      width: "40px !important",
      fontWeight: "400", // Set your desired font size for options here
      paddingRight: "10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: "400",
      color: "#214757",
    }),
    indicatorsContainer: (provided) => {
      const topPosition = selectedOption === null ? "8%" : "-9999px";
      return {
        ...provided,
        backgroundColor: "transparent",
        fill: "#214757",
        border: "none",
        position: "absolute",
        top: topPosition,
        left: "20%",
      };
    },
    dropdownIndicator: (provided) => ({
      ...provided,
      transition: "none !important",
    }),
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,

      width: "158px",
      lineHeight: "13px",
      color: "#214757",
      height: "41px",
      boxShadow: "none",
      outline: "1px solid rgba(205, 205, 205, .4)",
      border: 0,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: "400", // Set your desired font size for options here
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: "400",
      color: "#214757",
      top: "45%",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      marginBottom: "7px",
      fill: "#214757",
      position: " absolute",
      top: "10%",
      right: "5%",
      paddingRight: "220px !impartant",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      transition: "none !important",
    }),
  };


  const editRef1 = useRef(null);
  const [referenceList, setReferenceList] = useState(null);
  const [editVisible, setEditVisible] = useState(null);
  const [referenceId, setReferenceId] = useState(null);
  const [visibleAddReference, setVisibleAddReference] = useState(false);
  const [referenceAdd, setReferenceAdd] = useState({
    project: projectId,
    title: "",
    nextReview: "",
    description: "",
  });

  const [totalDoc, setTotalDoc] = useState("");
  const [total, setTotal] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  //pagination
  // const [totalCount, setTotalCount] = useState(null);
  const [itemPerPage, setItemPerPage] = useState("5");
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  // const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
  // const totalPages = Math.ceil(totalCount / itemPerPage);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const [openReferenceStatusDrawer, setOpenReferenceStatusDrawer] = useState(false)
  const [dateFilter, setDateFilter] = useState("");
  const [queryStatus, setQueryStatus] = useState({

    page: 1,
    limit: 10,
    search_term:'',
    date_filter:'this_year',
    status:'active'
  
  });

  let countnext = total;
  let countprev = total - (total - 1);
  if (currentPage > 1) {
    countnext = (currentPage - 1) * 10 + total;
    countprev = countnext - (total - 1);
  }

  useEffect(() => {
    setLoading(true)

    getReferenceList();
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getReferenceList(projectId);
  }, [queryStatus]);

  const getReferenceList = () => {

    return new Promise((resolve, reject) => {
      const queryString = new URLSearchParams(queryStatus).toString();

      APIGATEWAY.get(
        `project/reference/${projectId}?${queryString}`,
        (response) => {
          if (response.success) {
            setLoading(false)

            setReferenceList(response.data.references);
            setTotalDoc(response.data.totalCount);
            setTotal(response.data.references.length);
          }
          resolve(response.data.references);
        }
      );
    });
  };

  const handleClickShowDrawer = () => {
    setVisibleAddReference(true);
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    setEditVisible((prevEditVisible) => (prevEditVisible === id ? null : id));
  };

  const handleClickOutside = (event) => {
    if (editRef1.current &&  !event.target.classList.contains('menu-bar-icon')
    ) {
      setEditVisible(false);
    }
  };
  const handleEdit = (id) => {
    APIGATEWAY.get(`project/reference/detail/${id}`, (response) => {
      if (response.success) {
        setVisibleAddReference(true);
        const data = response.data.reference;
        setReferenceAdd({
          project: data?.project,
          title: data?.title,
          link: data?.link,
          description: data?.description,
        });
        setReferenceId(data._id);
      }
    });
  };

  const statusTitleChange = (event, type) => {
    const titleip = event.target.value;
    setReferenceAdd({ ...referenceAdd, title: titleip });
  };

  const referenceLinkChange = (event) => {
    setReferenceAdd({ ...referenceAdd, link: event.target.value });
  };
  const meetingDescriptionAdd = (event, type) => {
    setReferenceAdd({ ...referenceAdd, description: event.target.value });
  };

  const handleSelectDateFilter = (e) => {
    setDateFilter(e.value);
    setQueryStatus((prev) => ({...prev,date_filter:e.value }))

  };

  const handleSelectStatus = (e) => {
      setQueryStatus((prev) => ({...prev,status:e.value }))
  };



  const handleSearchFilter = (e) => {
    let searchTimeout
    setLoading(true);

    const value=e.target.value
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setQueryStatus((prev) => ({...prev,search_term:value }))
    }, 1000);
    setSearchTerm(value);

  };



  const createOthers = (e, id) => {
    e.preventDefault();
    let body;
    body = {
      project: projectId,
      ...referenceAdd,
    };
    setBtnLoader(true);

    if (id) {
      APIGATEWAY.patch(`project/reference/${id}`, body, (response) => {
        if (response.success) {
          setBtnLoader(false);
          message.success(`reference updated successfully`);
          closeDrawer(e).then((res) => {
            setReferenceAdd({
              project: projectId,
              title: "",
              link: "",
              description: "",
            });
            getReferenceList(projectId);
          });
        }
      });
    } else {
      APIGATEWAY.post(`project/reference`, body, (response) => {
        if (response.success) {
          message.success(`reference added successfully`);
          closeDrawer(e).then((res) => {
            setBtnLoader(false);
            setReferenceAdd({
              project: projectId,
              title: "",
              link: "",
              description: "",
            });
            getReferenceList(projectId);
          });
        }
      });
    }
  };

  const next = (prevPage) => {
    setCurrentPage(prevPage + 1);
    setQueryStatus((prev) => ({ ...prev, page: prevPage + 1 }));
    setLoading(true);
  };

  const pageLimitOnchange = (value) => {
    setLoading(true);
    setQueryStatus((prev) => ({ ...prev, limit: value }));
  };
  const previous = () => {
    setCurrentPage(currentPage - 1);
    setQueryStatus((prev) => ({ ...prev, page: currentPage - 1 }));
    setLoading(true);
  };


  const closeDrawer = (e) => {
    setOpenReferenceStatusDrawer(false)
    return new Promise((resolve, reject) => {
      setReferenceId(null)
      setReferenceAdd({
        project: projectId,
        title: "",
        link: "",
        description: "",
      });
      setVisibleAddReference(false);
      resolve(true);
    });
  };

  const updateReferenceStatus = (data) => {
    setOpenReferenceStatusDrawer(true)
    setReferenceId(data._id)
  }

  function ReferenceStatus(isTrue, response) {
    if (isTrue) {
      closeDrawer();
      setReferenceId(null)
      getReferenceList();
    }
  }

  return (
    <div className="Details-Page">
      <div className="crums">
      <Link to={`/projects`}><p className="crums1 pointer">Projects</p></Link>
        <img className="crums-vector" src={vector} alt="arrow"></img>
        <Link 
          to={{
                pathname:`/projects/detailspage/${params.id}`,
                      state: {
                        searchString: "other",
                      }
                    }}
                    > <p className="crums1 ml-2 pointer">Project Detail Page</p></Link>
        <img className="crums-vector" src={vector} alt="arrow"></img>
        <p className="crums2">References</p>
      </div>

      <div className="milestone-container">
        <div className="searchbar-container mb_20">
          <div className="search-input">
            <AiOutlineSearch className="searchicon" />
            <input
              className=""
              value={searchTerm}
              onChange={(e) => handleSearchFilter(e)}
              placeholder="Search"
            />
          </div>
          <div className="search-input">
              <Select
              options={optionsThisMonth}
              defaultValue={{ label: "This Year" }}
              isSearchable={false}
              styles={customStyles}
              onChange={(e) => handleSelectDateFilter(e)}
            />
          </div>
          <div className="search-input">
            <Select
              options={optionsStatus}
              defaultValue={{ label: "Active" }}
              isSearchable={false}
              styles={customStyles}
              onChange={(e) => handleSelectStatus(e)}

            />
          </div>

          <div className="Addbutton-container">
            <button
              className="Add-Milestone"
              onClick={() => handleClickShowDrawer()}
            >
              <AiOutlinePlus className="plus-icon" /> Add Reference
            </button>
          </div>
        </div>
        <div>
        {loading ? (
        <div className="spinning-star1">
          <Spin size="large" />
        </div>
      ):
      <div>
          <table className="table-container">
            <thead>
              <tr className="tablehead-row">
                <th className="small-tablehead">Title</th>
                <th className="mid-tablehead">Description</th>
                <th className="mid-tablehead">Link</th>

                <th className="option-tablehead">{""}</th>
              </tr>
            </thead>
            {referenceList?.length>0?(
            <tbody ref={editRef1}>
              {referenceList?.slice(0, 8).map((rowdata) => (
                <tr className="tabledatarow-maintable" key={rowdata._id}>
                  <td className="maincolumn-firstrowcolumn">
                    {rowdata.title?.length <= 10
                      ? rowdata.title
                      : `${rowdata.title?.slice(0, 10)}...`}
                  </td>
                  <td>{getStatusColor(rowdata.status) ?? "-"}</td>
                  <td>
                    {rowdata.link?.length <= 15
                      ? rowdata.link
                      : `${rowdata.link?.slice(0, 15)}...`}
                  </td>
                  <td
                    className="table-name"
                    onClick={(e) => handleEditClick(e, rowdata._id)}
                  >
                    <div className="tax-add-edit">
                      <span className="ml-5">
                        <div className="dropdown-head">
                          <div className="dropbtn-head">
                            <img
                              src={layer}
                              alt="profile"
                              className="menu-bar-icon"
                            />
                          </div>
                          {editVisible === rowdata._id && (
                            <div className="dropdown-content-heads">
                              <div>
                                <p
                                  className="action-menu-bar"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleEdit(rowdata._id, "reference");
                                  }}
                                >
                                  Edit
                                </p>
                              </div>
                              <div onClick={() => updateReferenceStatus(rowdata)} className="d-flex_al_c action-menu-bar on_hover">
                                {/* <img alt="update status" style={{width: "15px",marginRight: "10px",}} src={updateicon}></img> */}
                                <div className="light"><div className="m_0 p_0">Update Status</div></div>
                              </div>
                            </div>
                          )}
                        </div>
                      </span>
                    </div>
                  </td>
                </tr>
              ))}

            </tbody>
   

          )
          :(
            <tbody className="empty-message">
              <tr>
                <td colSpan="6">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </td>
              </tr>
            </tbody>
          )}
          </table>
        {referenceList?.length > 0 && (
            <TableField
              data={referenceList}
              countprev={countprev}
              countnext={countnext}
              currentPage={currentPage}
              ClickPrev={() => {
                previous();
              }}
              ClickNext={() => next(currentPage)}
              total={total}
              overAll={totalDoc ?? 0}
              pagelimit={queryStatus.limit}
              pagelimitHandle={pageLimitOnchange}
            />
          )}
          </div>
      }

          <Drawer
            placement="right"
            open={visibleAddReference}
            onClose={(e) => closeDrawer(e, "reference")}
            className="Milestones-main-drawer"
          >
            <div className="Milestone-drawer-container">
              <header>
                <h5 className="add-tax">References</h5>
                {/* 
              <GrClose className="MilestoneClose-icon" />
             */}
                <span
                  onClick={(e) => closeDrawer(e)}
                  className="MilestoneClose-icon"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={close}
                    className="MilestoneClose-img"
                    alt="close"
                  ></img>
                </span>
              </header>
              <form>
                <div className="tax-label">
                  <label className="mb_15 ">Title<span className="star">*</span></label>
                  <div className="tax-label-input">
                    <input
                      // disabled="true"
                      type="text"
                      name="tax_name"
                      placeholder="Enter a Title"
                      autoComplete="off"
                      value={referenceAdd.title}
                      onChange={(e) => statusTitleChange(e)}
                    />
                  </div>
                </div>
                <div className="tax-label">
                  <label>Description</label>
                  <div className="tax-label-input">
                    <textarea
                      placeholder="Add a text here.."
                      name="description"
                      autoComplete="off"
                      value={referenceAdd.description}
                      onChange={(e) => meetingDescriptionAdd(e)}
                    >
                      {" "}
                    </textarea>
                  </div>
                </div>
                <div className="tax-label mb_24">
                  <label > Link<span className="star">*</span></label>
                  <div className="tax-label-input">
                    <input
                      // disabled="true"
                      type="text"
                      name="tax_name"
                      placeholder="Enter link"
                      autoComplete="off"
                      value={referenceAdd.link}
                      onChange={(e) => referenceLinkChange(e)}
                    />
                  </div>
                </div>

                <div>
                  <TopHeader
                    type="meeting"
                    backClick={(e) => closeDrawer(e)}
                    classname="p_0"
                    submit={(e) => {
                      createOthers(e, referenceId);
                    }}
                    // id={projectId}
                    spin={btnLoader}
                  />
                </div>
              </form>
            </div>
          </Drawer>
          <Drawer placement="right" open={openReferenceStatusDrawer} onClose={closeDrawer} className="tax-main-drawer" >
            <UpdateProjectComponent statusData={referenceStatusOptions} from={"reference"} update={ReferenceStatus} id={referenceId} onClose={closeDrawer} ></UpdateProjectComponent>
          </Drawer>
        </div>
      </div>
    </div>
  );
}

export default ReferenceList;
