import "../../stylesheets/project/project.css";
import close from "../../assets/emp_close.svg";
import TopHeader from "../../components/ui/TopHeader";
import "../ResourceManagement/resource.css";
import React, { useState, useEffect, useRef, useMemo, Fragment } from "react";
import edit from "../../assets/Layer 2.svg";
import add from "../../assets/add resources.svg";
import { useHistory } from "react-router-dom";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import {
  Drawer,
  Select,
  Dropdown,
  Space,
  Empty,
  DatePicker,
  Spin,
  message,
} from "antd";
import { showError } from "../../constants/Utils";
import moment from "moment";
import updateicon from "../../assets/updateStatus.svg";
import editProject from "../../assets/fi-rr-pencil.svg";
import { AiOutlineMenu } from "react-icons/ai";
import { Input } from "reactstrap";

const { Option } = Select;
const { TextArea } = Input;
function ResourceManagement() {
  let start = null;
  let end = null;
  let emp = null;
  const history = useHistory();
  const current_date = moment().format("YYYY-MM-DD");
  let dragItem = null;
  let dropItem = null;
  const [projects, setProjects] = useState([]);
  const [unassignedEmployees, setUnassignedEmployees] = useState([]);
  const [trainees, setTrainees] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAddEmp, setOpenAddEmp] = useState(false);
  const [description, setDescription] = useState("");
  const [empObj, setEmpObj] = useState();
  const [destination, setDestination] = useState();
  const [to, setTO] = useState();
  const [fromType, setFromType] = useState("");
  const [fromTo, setFromTO] = useState();
  const [employeeList, setEmployeeList] = useState([]);
  const [roleslists, setRoleLists] = useState([]);
  const [projectId, setProjectId] = useState();
  const [employee, setEmployee] = useState();
  // const [role, setrole] = useState();
  const [startDate, setStartDate] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [listIndex, setListIndex] = useState("");
  const [initPageLoading, setInitPageLoading] = useState(false);
  const [id, setId] = useState("");
  const [popupOpen, setPopupOpen] = useState(true);
  const [teamMemberAdd, setTeamMemberAdd] = useState({
    employee_id: "",
    employee: "",
    name: "",
    role_id: "",
    startDate: "",
    description: "",
  });
  const [filterEmployee, setFilterEmployee] = useState(null);
  const [teamId, setTeamId] = useState(null);

  const [role, setRole] = useState(null);
  const [updateStatus, setUpdateStatus] = useState({
    title: "",
    changeStatus: "",
    notes: "",
  });
  const [statusData, setStatusData] = useState([
    { value: "All", key: "all" },
    { value: "Active", key: "active" },
    { value: "Hold", key: "hold" },
    { value: "Discontinued", key: "discontinued" },
    { value: "Completed", key: "completed" },
    { value: "Cancelled", key: "cancelled" },
  ]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getResourcesList();
    getEmployees();
    getRoles();
  }, []);

  useEffect(() => {
    if (fromTo) {
      const { from, to } = fromTo;
      if (from === "project" && to === "projectCard")
        return AddingEmpToProject();
      else if (from === "project" && to === "unassignedCard")
        //2
        return AddingEmpToUnassigned();
      else if (from === "project" && to === "trainee")
        //1
        return AddingEmpToTrainee();
      else if (from === "unassigned" && to === "projectCard")
        return AddingEmpToProjectFromUnassigned();
      else if (from === "trainee" && to === "projectCard")
        return addingEmpToProjectFromTrainee();
      else if (from === "unassigned" && to === "trainee")
        //1
        return addingEmpfromUnassignToTrainee();
      else if (from === "trainee" && to === "unassignedCard")
        //2
        return addingEmpFromTraineeToUnassign();
    }
  }, [empObj, destination, fromTo]);

  useEffect(() => {
    if (fromType && to) {
      setFromTO({
        from: fromType,
        to: to,
      });
    }
  }, [fromType, to]);

  function getResourcesList() {
    return new Promise((resolve, reject) => {
      APIGATEWAY.get("resource", (response) => {
        settingResponse(response);
        // setProjects(response.data.projects);
        // setUnassignedEmployees(response.data.unassigned);
        // setTrainees(response.data.training);
        resolve(true);
      });
    });
  }

  function getRoles() {
    return new Promise((resolve, reject) => {
      APIGATEWAY.get("roles", (response) => {
        if (!response.success) return reject(false);
        const roles = response.data.roles;
        setRoleLists(roles);
        resolve(roles);
      });
    });
  }

  function apicall(body) {
    APIGATEWAY.patch("resource", body, (response) => {
      settingResponse(response);
    });
  }

  function settingResponse(response) {
    setProjects([]);
    setProjects(response.data.projects);
    setUnassignedEmployees([]);
    setUnassignedEmployees(response.data.unassigned);
    setTrainees([]);
    setTrainees(response.data.training);
    setLoading(false);
  }

  function getEmployees() {
    return new Promise((resolve, reject) => {
      APIGATEWAY.get("employees/all", (response) => {
        if (!response.success) return reject(false);
        const employees = response.data.employees;
        setEmployeeList(employees);
        resolve(employees);
      });
    });
  }

  function AddingEmpToProject() {
    const body = {
      team: { ...empObj },
      destination: { ...destination },
    };
    apicall(body);
  }

  function AddingEmpToUnassigned() {
    const body = {
      team: { ...empObj },
      destination: {
        _id: "unassigned",
      },
    };
    apicall(body);
  }

  function AddingEmpToTrainee() {
    const body = {
      team: { ...empObj },
      destination: {
        _id: "training",
      },
    };
    apicall(body);
  }

  function addingEmpfromUnassignToTrainee() {
    const body = {
      employee: { ...empObj },
      destination: {
        _id: "training",
      },
    };
    apicall(body);
  }

  function AddingEmpToProjectFromUnassigned() {
    const body = {
      employee: { ...empObj },
      destination: { ...destination },
    };
    apicall(body);
  }

  function addingEmpToProjectFromTrainee() {
    const body = {
      employee: { ...empObj },
      destination: { ...destination },
    };
    apicall(body);
  }

  function addingEmpFromTraineeToUnassign() {
    const body = {
      employee: { ...empObj },
      destination: {
        _id: "unassigned",
      },
    };
    apicall(body);
  }

  function dragOver(e) {
    e.preventDefault();
  }

  function dragStartComponent(e, value) {
    let nodeElement = e.target;
    start = value;
    emp = nodeElement;
    dragItem = nodeElement;

    setTimeout(() => {
      nodeElement.className = "invisible";
    }, 0);
  }

  function dragEndComponent(e, from) {
    dragItem.className = "item";
    dragItem = null;
  }

  function dragDrop(element) {}

  function closeDrawer(e) {
    e.preventDefault();
    return new Promise((resolve, reject) => {
      setOpenDrawer(false);
      setOpenAddEmp(false);
      resolve(true);
    });
  }

  function onAdd(empObject) {
    setProjectId(empObject._id);
    // setOpenDrawer(true);
    setOpenAddEmp(true);
  }

  function checkStartDate(e) {
    return e.isBefore(current_date);
  }

  const update = (e) => {
    e.preventDefault();

    setBtnLoader(true);
    if (!teamMemberAdd.employee) {
      setBtnLoader(false);
      showError("Please select employee");
    } else if (!teamMemberAdd.role_id) {
      setBtnLoader(false);
      showError("Please select role");
    } else if (!teamMemberAdd.startDate) {
      setBtnLoader(false);
      showError("Please select start date");
    } else {
      const body = {
        startDate: moment(teamMemberAdd.startDate),
        project: projectId,
        ...teamMemberAdd,
      };
      // const body = {
      //   employee_id: employee?.emp_id,
      //   project: projectId,
      //   employee: employee?._id,
      //   description: description,
      //   name: employee?.name,
      //   role_id: role?.role_id,
      //   startDate: startDate,
      // };
      APIGATEWAY.post("project/team", body, (response) => {
        setBtnLoader(false);
        if (response.success) {
          message.success(`${body.name} is added to the project successfully`);
          closeDrawer(e);
          getResourcesList();
          setEmployee("");
          setRole("");
          setDescription("");
          setStartDate("");
          setBtnLoader(false);
          setTeamMemberAdd({
            project: "",
            employee_id: "",
            employee: "",
            name: "",
            role_id: "",
            startDate: "",
          });
        }
      });
    }
  };

  function droped(e, end) {
    dropItem = e.currentTarget;
    end = end;

    const card = dragItem.getAttribute("cardindex");
    const child = dragItem.getAttribute("childrenindex");
    const dropParent = dropItem.getAttribute("dropindex");

    if (start === end && card === dropParent) return;
    dragItem = null;

    const body = {};
    if (start === "project") body["team"] = projects[card].team[child];
    else if (start === "unassigned")
      body["employee"] = unassignedEmployees[child];
    else if (start === "trainee") body["employee"] = trainees[child];

    if (end === "project") body["destination"] = projects[dropParent];
    else if (end === "unassigned") body["destination"] = { _id: "unassigned" };
    else if (end === "trainee") body["destination"] = { _id: "training" };

    setLoading(true);
    APIGATEWAY.patch("/resource", body, (response) => {
      if (!response || !response.success) {
        setLoading(false);
        showError(response.message);
      }
      settingResponse(response);
    }).catch((error) => {
      setLoading(false);
      showError(error.message);
    });
  }

  function toProjectDetail(id) {
    history.push({
      pathname: `projects/edit/${id}`,
      state: { from: "resource_management" },
    });
  }

  const dropdownClick = (index) => {
    setDropdownMenu(!dropdownMenu);
    setListIndex(index);
  };

  function updateProject(id) {
    setId(id);
    const project_id = id;
    setOpenDrawer(true);
    setDropdownMenu(false);
    setInitPageLoading(true);
    getProject(project_id);
  }

  function getProject(project_id) {
    APIGATEWAY.get(`project/${project_id}`, (response) => {
      if (response.success) {
        setInitPageLoading(false);
        setUpdateStatus({
          title: response.data.project[0].name,
          changeStatus: response.data.project[0].status,
        });
      }
    });
  }

  const updateProjectStatus = (event) => {
    setUpdateStatus((previous) => {
      return { ...previous, changeStatus: event };
    });
  };

  const updateProjectNotes = (event) => {
    setUpdateStatus({ ...updateStatus, notes: event.target.value });
  };

  const openPopup = (previous) => {
    setPopupOpen(!previous);
  };

  const addEmployee = (event) => {
    setFilterEmployee(
      employeeList.find((item) => {
        if (item.emp_id === event) {
          setTeamMemberAdd({
            ...teamMemberAdd,
            role_id: item?.role?.role_id,
            employee_id: item?.emp_id,
            employee: item?._id,
            name: item?.name,
          });
          setRole(item.role.name);
        }
      })
    );
  };
  const memberDescriptionAdd = (event, type) => {
    setTeamMemberAdd({ ...teamMemberAdd, description: event.target.value });
  };

  const handleDateChange = (event) => {
    setTeamMemberAdd({ ...teamMemberAdd, startDate: event });
  };

  // function updateStatus() {
  //   setBtnLoader(true);
  //   const { title, changeStatus, notes } = updateStatus;
  //   const body = {
  //     status: updateStatus.changeStatus,
  //     notes: updateStatus.notes,
  //   };
  //   setDropdownMenu(false);
  //   APIGATEWAY.patch(`project/${id}`, body, (response) => {
  //     if (response.success) {
  //       message.success("Project status  updated successfully");
  //       closeDrawer().then((res) => {
  //         setBtnLoader(false);
  //       });
  //     }
  //   });
  // }

  return (
    <Fragment>
      {loading ? (
        <div className="spinning-star">
          <Spin size="large" />
        </div>
      ) : (
        <Fragment>
          <div className="d-flex">
            <div
              className={popupOpen ? "w_80" : "w_100"}
              style={{ padding: "20px 23px 20px 30px" }}
            >
              <div className="d-flex_jc_sb_al_c">
                <div class="fs_20 fw_600 c_21 mb-23px">Resource Management</div>
                <AiOutlineMenu
                  className="burger-icon pointer"
                  onClick={(e) => openPopup(popupOpen)}
                />
              </div>
              <div className="cardBody">
                {projects.map((item, i) => (
                  <div key={i} className="card">
                    <div className="project_header">
                      <div className="fw_600 fs_14 c_dark">{item.name}</div>
                      <div className="dropdown-head">
                        <img
                          className="editResource pointer"
                          onClick={() => dropdownClick(i)}
                          src={edit}
                        ></img>
                        {listIndex === i && dropdownMenu && (
                          <Fragment>
                            <div
                              className="overflow-overlay"
                              onClick={() => setDropdownMenu(false)}
                            ></div>
                            <div className="dropdown-content-heads">
                              <div style={{ width: "170px" }}>
                                {
                                  <div
                                    onClick={() =>
                                      toProjectDetail(item.project_id)
                                    }
                                    className="d-flex_al_c action-menu-bar"
                                  >
                                    <div>
                                      <img
                                        alt="edit"
                                        style={{
                                          width: "15px",
                                          marginRight: "10px",
                                        }}
                                        src={editProject}
                                      ></img>
                                    </div>
                                    <div className="light">
                                      <div className="m_0 p_0 anchor">Edit</div>
                                    </div>
                                  </div>
                                }
                                <div
                                  onClick={() => updateProject(item.project_id)}
                                  className="d-flex_al_c action-menu-bar"
                                >
                                  <div>
                                    <img
                                      alt="update status"
                                      style={{
                                        width: "15px",
                                        marginRight: "10px",
                                      }}
                                      src={updateicon}
                                    ></img>
                                  </div>
                                  <div className="light">
                                    <div className="m_0 p_0 anchor">
                                      Update Status
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </div>
                    <div
                      id="card"
                      dropindex={i}
                      onDrop={(e) => droped(e, "project")}
                      onDragOver={(e) => dragOver(e)}
                      className="employeeList"
                    >
                      {item?.team.map((emp, j) => (
                        <div
                          key={j}
                          cardindex={i}
                          childrenindex={j}
                          from={"project"}
                          onDragEnd={(e) => dragEndComponent(e, "project")}
                          onDragStart={(e) => dragStartComponent(e, "project")}
                          draggable="true"
                          id="employee"
                          className={`pointer`}
                          style={{
                            padding: "14px 16px",
                            borderBottom: "1px solid #EAEAEA",
                          }}
                        >
                          <div className="fw_500 fs_14 c_30">
                            {emp?.employee?.name}
                          </div>
                          <div className="d-flex_jc_sb_al_c">
                            <div className="c_light fw_400 fs_12">
                              {emp?.role?.name}
                            </div>
                            <div
                              className="fw_500 fs_12"
                              style={{ color: "#0893CF" }}
                            >
                              {moment(emp.startDate).format("DD MMM YY")}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex_jc_c_al_c">
                      <img
                        className="p_14 pointer"
                        onClick={() => onAdd(item)}
                        src={add}
                      ></img>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={popupOpen ? " flex-du-after" : "flex-du"}>
              <div className="unAssignedEmployees">
                <div
                  style={{ backgroundColor: "#F8F8F9", padding: "20px 0px" }}
                  className=" d-flex"
                >
                  <div
                    style={{ width: "2px", backgroundColor: "#214757" }}
                  ></div>
                  <div className="fw_600 fs_13 c_dark ml-13 ">UN-ASSIGNED</div>
                </div>
                <div
                  id="unassigned"
                  onDrop={(e) => droped(e, "unassigned")}
                  onDragOver={(e) => dragOver(e)}
                  className="un_assigned"
                >
                  {unassignedEmployees.map((item, i) => (
                    <div
                      key={i}
                      onDragEnd={(e) => dragEndComponent(e, "unassigned")}
                      onDragStart={(e) => dragStartComponent(e, "unassigned")}
                      childrenindex={i}
                      from={"unassigned"}
                      draggable="true"
                      id="unassignedEmp"
                      style={{ borderBottom: "1px solid rgb(234, 234, 234)" }}
                      className="p_15 threeSides pointer"
                    >
                      <div>
                        <div className="fw_500 c_30 fs_14">{item?.name}</div>
                        <div className="c_light fw_400 fs_12">
                          {item?.role?.name}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div
                  style={{ backgroundColor: "#F8F8F9", padding: "20px 0px" }}
                  className="d-flex"
                >
                  <div
                    style={{ width: "2px", backgroundColor: "#214757" }}
                  ></div>
                  <div className="fw_600 fs_13 c_dark ml-13 ">IN TRAINING</div>
                </div>
                <div
                  id="trainee"
                  onDrop={(e) => droped(e, "trainee")}
                  onDragOver={(e) => dragOver(e)}
                  className="trainee"
                >
                  {trainees.map((item, k) => (
                    <div
                      onDragEnd={(e) => dragEndComponent(e, "trainee")}
                      onDragStart={(e) => dragStartComponent(e, "trainee")}
                      childrenindex={k}
                      draggable="true"
                      id="individualTrainee"
                      style={{ borderBottom: "1px solid rgb(234, 234, 234)" }}
                      className="p_15 threeSides pointer"
                    >
                      <div>
                        <div className="fw_500 c_30 fs_14">{item?.name}</div>
                        <div className="c_light fw_400 fs_12">
                          {item?.role?.name}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* <Space>
            <div>
              <Drawer
                placement="right"
                visible={openAddEmp}
                onClose={() => closeDrawer()}
                className="tax-main-drawer"
              >
                <div className="tax-drawer">
                  <div className="drawer-head">
                    <span className="fw_500 fs_20 c_dark">
                      {"Team Members"}
                    </span>
                    <span onClick={() => closeDrawer()} className="tax-close">
                      <img src={close} alt="close" />
                    </span>
                  </div>
                  <div className="drawer_boody">
                    <div className="create-project-select-tag">
                      <div className="onboard_input_name fw_500 fs_16 mb-3">
                        Select Employee
                        <span className="mandatory-field">&#42;</span>
                      </div>
                      <div className="project_placeholder">
                        <Select
                          // className="create-project-select"
                          className="blood"
                          style={{ width: "100%" }}
                          value={employee?.name || undefined}
                          placeholder="Select Employee"
                          // mode="multiple"
                          onChange={(e) => setEmployee(JSON.parse(e))}
                        >
                          {employeeList.map((ele, i) => (
                            <Option key={i} value={JSON.stringify(ele)}>
                              {ele.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="create-project-select-tag">
                      <div className="onboard_input_name fw_500 fs_16 mb-3">
                        Select Role
                        <span className="mandatory-field">&#42;</span>
                      </div>
                      <div className="project_placeholder">
                        <Select
                          // className="create-project-select"
                          className="blood"
                          style={{ width: "100%" }}
                          value={role?.name || undefined}
                          placeholder="Select Role"
                          // mode="multiple"
                          onChange={(e) => setrole(JSON.parse(e))}
                        >
                          {roleslists.map((ele, i) => (
                            <Option key={i} value={JSON.stringify(ele)}>
                              {ele.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="create-project-description">
                      <div className="onboard_input_name fw_500 fs_16 mb-3">
                        Description
                      </div>
                      <div className="project_placeholder">
                        <TextArea
                          className="project_textArea"
                          name="description"
                          value={description}
                          rows={4}
                          placeholder="Enter a Description"
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="project_date_picker mb-24">
                      <p className="fs_16 fw_500" style={{ margin: "0 0 0 0" }}>
                        Start Date<span className="mandatory-field">&#42;</span>
                      </p>
                      <DatePicker
                        disabledDate={checkStartDate}
                        name="date_start"
                        label="Start Date"
                        placeholder="Start Date"
                        value={startDate}
                        className="commonDatepicker"
                        labelClass="fw_500 fs_16"
                        // handleChange={this.handleOnDateChange}
                        onChange={(date, dateString) => setStartDate(date)}
                      />
                    </div>
                    <div>
                      <TopHeader
                        // title="projects"
                        backClick={closeDrawer}
                        classname="p_0"
                        submit={update}
                        // id={id}
                        spin={btnLoader}
                      />
                    </div>
                  </div>
                </div>
              </Drawer>
            </div>
          </Space> */}

          <Drawer
            placement="right"
            open={openAddEmp}
            onClose={(e) => closeDrawer(e)}
            className="Milestones-main-drawer"
          >
            <div className="Milestone-drawer-container">
              <header>
                <h5 className="add-tax">Team Members</h5>
                {/* 
              <GrClose className="MilestoneClose-icon" />
             */}
                <span
                  onClick={(e) => closeDrawer(e)}
                  className="MilestoneClose-icon"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={close}
                    className="MilestoneClose-img"
                    alt="close"
                  ></img>
                </span>
              </header>
              <form>
                <div className="tax-label mb_24">
                  <label className="mb_15">
                    Select Employee <span className="star">*</span>
                  </label>
                  <Select
                    onChange={(e) => addEmployee(e)}
                    className="projectStatus"
                    style={{ width: "100%", borderRadius: "7px" }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    value={teamMemberAdd.employee_id}
                    showSearch
                    showArrow
                  >
                    {employeeList.map((list) => (
                      <Option key={list._id} value={list.emp_id}>
                        {list.name}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className="tax-label mb_24">
                  <label className="mb_15">Role</label>
                  <Input
                    className="projectStatus"
                    style={{ width: "100%", borderRadius: "7px" }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    value={role}
                    disabled
                    showSearch
                    showArrow
                  ></Input>
                </div>
                <div className="tax-label">
                  <label>Description</label>
                  <div className="tax-label-input">
                    <textarea
                      placeholder="Add a text here.."
                      name="description"
                      autoComplete="off"
                      value={teamMemberAdd?.description}
                      onChange={(e) => memberDescriptionAdd(e)}
                    >
                      {" "}
                    </textarea>
                  </div>
                </div>
                <div className="tax-label mb_24">
                  <label>
                    Start Date<span className="star">*</span>
                  </label>
                  <DatePicker
                    disabledDate={checkStartDate}
                    value={
                      teamMemberAdd.startDate !== ""
                        ? moment(teamMemberAdd.startDate)
                        : ""
                    }
                    onChange={(e) => handleDateChange(e)}
                    style={{ width: "100%" }}
                    id="date-start"
                  />
                </div>
                <div>
                  <TopHeader
                    type="meeting"
                    backClick={(e) => closeDrawer(e)}
                    classname="p_0"
                    submit={(e) => {
                      update(e, teamId);
                    }}
                    // submit={createOthers}

                    // id={projectId}
                    spin={btnLoader}
                  />
                </div>
              </form>
            </div>
          </Drawer>

          <Space>
            <div>
              <Drawer
                placement="right"
                open={openDrawer}
                onClose={(e) => closeDrawer(e)}
                className="tax-main-drawer"
              >
                {initPageLoading ? (
                  <div className="spinning-star">
                    <Spin size="large" />
                  </div>
                ) : (
                  <div className="tax-drawer">
                    <div className="drawer-head">
                      <span className="add-tax">{"Update Status"}</span>
                      <span
                        onClick={(e) => closeDrawer(e)}
                        className="tax-close"
                      >
                        <img src={close} alt="close" />
                      </span>
                    </div>
                    <div style={{ margin: "40px 25px" }} className="">
                      <div className="tax-label">
                        <label className="mb_15">Title</label>
                        <div className="tax-label-input">
                          <input
                            disabled="true"
                            type="text"
                            name="tax_name"
                            placeholder="Enter a Title"
                            autoComplete="off"
                            value={updateStatus.title}
                            // onChange={(e) => statusTitleChange(e)}
                          />
                        </div>
                      </div>

                      <div className="tax-label mb_24">
                        <label className="mb_15">
                          Change Status <span className="star">*</span>
                        </label>
                        <Select
                          //   className="create-project-select"
                          value={updateStatus.changeStatus}
                          style={{ width: "100%" }}
                          placeholder="Select Platform"
                          onChange={(e) => updateProjectStatus(e)}
                          className="projectStatus"
                        >
                          {statusData.map((ele, i) => (
                            <Option value={ele.key} key={i}>
                              {ele.value}
                            </Option>
                          ))}
                        </Select>
                      </div>

                      <div className="tax-label">
                        <label>Notes</label>
                        <div className="tax-label-input">
                          <textarea
                            placeholder="Notes"
                            name="description"
                            autoComplete="off"
                            value={updateStatus.notes}
                            onChange={(e) => updateProjectNotes(e)}
                          >
                            {" "}
                          </textarea>
                        </div>
                      </div>
                      <div>
                        <TopHeader
                          // title="projects"
                          backClick={(e) => closeDrawer(e)}
                          classname="p_0"
                          submit={updateStatus}
                          id={id}
                          spin={btnLoader}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Drawer>
            </div>
          </Space>
        </Fragment>
      )}
    </Fragment>
  );
}
export default ResourceManagement;
