import React, { Component } from "react";
import { Input } from "antd";
import { parse } from "date-fns";
const { TextArea } = Input;
class Calculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: "",
      calciValue: "",
    };
  }
  replaceSpecCharacter = (value) => {
    return value.replaceAll(
      /[_\/\\^$?()| [\]{}abcdefghijklmnopqrstuvwxyzABCDEFGHOJKLMOPQRSTUVXYZ#]/g,
      ""
    );
  };


  calculate = (e) => {
    let value = e.target.value;
    let data = this.replaceSpecCharacter(value);
    this.setState({ calciValue: value });

    let total = 0;
    let operator = "";
    let first_number = "";
    for (let i = 0; i < data.length; i++) {
      let int = data[i];
      if (int === "+" || int === "-") {
        if (total === 0) {
          total = parseFloat(first_number);
        } else {
          operator === "+"
            ? (total += parseFloat(first_number))
            : (total -= parseFloat(first_number));
        }
        operator = int;
        first_number = "";
      } else {
        first_number = first_number.concat(int);
      }
    }
    operator === "+"
      ? (total += parseFloat(first_number))
      : operator === "-"
        ? (total -= parseFloat(first_number))
        : (total = parseFloat(first_number));

    this.setState({ result: total });
  };

  render() {
    let { result, calciValue } = this.state;
    return (
      <div style={{ padding: "20px", width: "50%" }}>
        <div
          style={{ fontWeight: "500", fontSize: "17px" }}
          className="sideHeading"
        >
          {/* {localStorage.getItem("menu")} */}
        </div>

        <div className="calci">
          <div className="calciValue">
            <div className="calciName"></div>
            <TextArea value={calciValue} onChange={this.calculate} rows={4} />
          </div>
          <div className="calciResultArea">
            <div className="calciName">Total</div>
            <TextArea disabled value={result} />
          </div>
        </div>
      </div>
    );
  }
}

export default Calculator;
