import React, { useState, useEffect, useRef } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import ProgressBar from "./Progressbar";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Select from "react-select";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import {
  showDayMonthYear,
  projectColor,
  useDebounce,
  amountStatus,
  showLongDateFormat,
  numberWithComma,
  getMonthYear,
  showSuccess,
  showError,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  showCurrency,
  numberWithCommas,
  numberFormatWithCurrency,
  numberWithRupees,
  isErrorTxt,
  showDate,
} from "../../constants/Utils";
import layer from "../../assets/Layer 2.svg";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom";
import Search from "../../components/ui/search";
import TableField from "../../components/ui/tableField";

import { DatePicker, Drawer, Spin, Popover } from "antd";
import TopHeader from "../../components/ui/TopHeader";
import moment from "moment";
import close from "../../assets/close_symbol.svg";
import { message, Empty } from "antd";
import cookie from "react-cookies";
import urlLink from "../../ApiService/GlobalVariables/globalVariables";
import ModalPopUp from "../../components/ui/modalpop";
import Filter from "../../components/ui/filter";

const ProjectInvoice = (props) => {
  const params = useParams();
  const project = params.id;

  const projectId = props.project_id;
  const history = useHistory(); // Initialize the useHistory hook

  const [selectedOption, setSelectedOption] = useState(null);
  //DropDown using antDesign

  const occurrenceData = [
    { value: "this_month", type: "This Month" },
    { value: "last_month", type: "Last Month" },
    { value: "this_year", type: "This Year" },
    { value: "last_year", type: "Last Year" },
    { value: "overall", type: "Overall" },
  ];
  const statusData = [
    { value: "Paid", key: "paid" },
    { value: "Unpaid", key: "unpaid" },
    { value: "Cancelled", key: "cancelled" },
  ];

  const newStyles = {
    control: (provided, state) => ({
      ...provided,

      width: "40px !important",
      lineHeight: "13px",
      color: "#214757",
      boxShadow: "none",
      outline: "none",
      border: 0,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "13px",
      width: "40px !important",
      fontWeight: "400", // Set your desired font size for options here
      paddingRight: "10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: "400",
      color: "#214757",
    }),
    indicatorsContainer: (provided) => {
      const topPosition = selectedOption === null ? "8%" : "-9999px";
      return {
        ...provided,
        backgroundColor: "transparent",
        fill: "#214757",
        border: "none",
        position: "absolute",
        top: topPosition,
        left: "20%",
      };
    },
    dropdownIndicator: (provided) => ({
      ...provided,
      transition: "none !important",
    }),
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,

      width: "158px",
      lineHeight: "13px",
      color: "#214757",
      height: "41px",
      boxShadow: "none",
      outline: "1px solid rgba(205, 205, 205, .4)",
      border: 0,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: "400", // Set your desired font size for options here
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: "400",
      color: "#214757",
      top: "45%",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      marginBottom: "7px",
      fill: "#214757",
      position: " absolute",
      top: "10%",
      right: "5%",
      paddingRight: "220px !impartant",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      transition: "none !important",
    }),
  };

  //pagination
  const [itemPerPage, setItemPerPage] = useState("5");
  const [currentPage, setCurrentPage] = useState(1);
  const [invoiceList, setInvoiceList] = useState();
  const [totalCount, setTotalCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editVisible, setEditVisible] = useState(null);
  const editRef1 = useRef(null);
  const [cancelInvoiceModal, setCancelInvoiceModal] = useState(false);
  const [invoice_number, setInvoice_number] = useState(null);
  const [invoice_id, setInvoice_id] = useState(null);
  const [totalDoc, setTotalDoc] = useState("");
  const [total, setTotal] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  // const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(totalCount / itemPerPage);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateFilter, setDateFilter] = useState(occurrenceData[4].value);
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    client_name: "",
    from: "",
    to: "",
    status: "all",
    limit: 10,
    project_id: project,
  });

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  useEffect(() => {
    handleSelectDateFilter(dateFilter);
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getInvoiceList(projectId);
  }, [queryStatus]);

  const getInvoiceList = () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      APIGATEWAY.get(`invoices` + buildQueryString(queryStatus), (response) => {
        if (response.success) {
          setLoading(false);
          setInvoiceList(response.data.invoices);
          setTotalDoc(response.data.total);
          setTotal(response.data?.invoices?.length);
        }
        resolve(response.data.invoices);
      });
    });
  };

  const handleClickOutside = (event) => {
    if (editRef1.current && !event.target.classList.contains("menu-bar-icon")) {
      setEditVisible(false);
    }
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    setEditVisible((prevEditVisible) => (prevEditVisible === id ? null : id));
  };

  const getStatusColor = (status) => {
    if (status === "cancelled") {
      return "#EF4733";
    } else if (status === "pending") {
      return "#F8B146";
    } else if (status === "unpaid") {
      return "#F8B146";
    } else {
      return "#009C22";
    }
  };

  const handleActions = (e, object) => {
    if (e === "edit") {
      handleEditPage(object.invoice_id);
    } else if (e === "delete") {
      handleDelete(object.invoice_id);
    } else if (e === "download") {
      download(object);
    }
    if (e === "cancel") {
      setCancelInvoiceModal(true);
      setInvoice_number(object?.invoice_number ?? "");
      setInvoice_id(object?.invoice_id ?? "");
      // this.setState({
      //   cancelInvoiceModal: true,
      //   invoice_number: object?.invoice_number ?? "",
      //   invoice_id: object?.invoice_id ?? "",
      // });
    } else if (e === "duplicate") {
      handleCreatePage(object.invoice_id);
    }
    // if (e === "payInvoice") {
    //   this.setState({
    //     modal1Visible: true,
    //     payData: object,
    //   });
    // }
  };

  const handleSearchTerm = (e) => {
    const value = e.target.value;
    setLoading(true);
    clearTimeout(searchTerm);
    searchTerm = setTimeout(() => {
      setQueryStatus((prev) => ({ ...prev, client_name: value }));
    }, 1000);
  };

  const handleDelete = (id) => {
    setLoading(true);
    let self = this;
    let data = {};
    APIGATEWAY.delete("invoices/" + id, data, (response) => {
      if (response.success) {
        setLoading(false);
        message.success(response.message);
        getInvoiceList();
        // self.handleTable();
        // self.overallInvoices();
      } else {
        message.error(response.message);
      }
    });
  };

  const handleEditPage = (id) => {
    history.push("/invoices/add/" + id); // Navigate to the specified route
  };

  const handleCreatePage = (duplicate) => {
    history.push({
      pathname: "/invoices/add/duplicate/" + duplicate,
      state: { project: project },
    });
  };

  const download = (object) => {
    let token = cookie.load("empr_tk");

    var bearer = "Bearer " + token;
    let monthyear = getMonthYear(object.invoice_date);

    let client_name = object.client.name.split(" ").join("");
    fetch(urlLink.BASE_URL + "/invoices/pdf/" + object.invoice_id, {
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response) {
        response.blob().then((data) => {
          let url = window.URL.createObjectURL(data);

          let a = document.createElement("a");
          a.href = url;
          a.download = `${monthyear}-${client_name}-Invoice.pdf`;
          a.click();
          showSuccess("Downloaded Successesfully");
        });
      } else {
        showError("Download Fail");
      }
    });
  };

  const cancelInvoice = () => {
    setCancelInvoiceModal(false);
    setInvoice_number("");
    setInvoice_id("");
  };

  const submitInvoice = () => {
    // const { invoice_id } = this.state;
    let data = {};
    let status = "cancelled";
    APIGATEWAY.patch(
      "invoices/" + status + "/" + invoice_id,
      data,
      (response) => {
        if (response.success) {
          showSuccess(response.message);
          cancelInvoice();
          getInvoiceList();
          // this.overallInvoices();
        } else {
          showError(response.message);
        }
      }
    );
  };

  const handleSelectDateFilter = (filter) => {
    const count = filter.includes("last")
      ? -1
      : filter.includes("next")
      ? 1
      : filter === "overall"
      ? -10
      : 0;
    const period = filter.includes("month")
      ? "month"
      : filter.includes("year")
      ? "year"
      : filter.includes("all")
      ? "year"
      : filter;
    setDateFilter(filter);
    setQueryStatus((prev) => ({
      ...prev,
      from: moment().startOf(period).add(count, period).format("YYYY-MM-DD"),
      to: filter.includes("all")
        ? moment().endOf("day").format("YYYY-MM-DD")
        : moment().endOf(period).add(count, period).format("YYYY-MM-DD"),
    }));
  };

  const next = (prevPage) => {
    setCurrentPage(prevPage + 1);
    setQueryStatus((prev) => ({ ...prev, page: prevPage + 1 }));
    setLoading(true);
  };

  const handleSelectStatus = (e) => {
    let value = e;
    if (e === "all") value = "";
    setQueryStatus((prev) => ({ ...prev, status: value }));
  };

  const pageLimitOnchange = (value) => {
    setLoading(true);
    setQueryStatus((prev) => ({ ...prev, limit: value }));
  };
  const previous = () => {
    setCurrentPage(currentPage - 1);
    setQueryStatus((prev) => ({ ...prev, page: currentPage - 1 }));
    setLoading(true);
  };
  let countnext = total;
  let countprev = total - (total - 1);
  if (currentPage > 1) {
    countnext = (currentPage - 1) * 10 + total;
    countprev = countnext - (total - 1);
  }
  return (
    <div className="milestone-container">
      <div className="searchbar-container ">
        <Search
          details={queryStatus}
          style={{ width: "27%" }}
          outerDivClassName="mr-16 w_20"
          placeholder="Search"
          searchClassName=""
          onSearchChange={handleSearchTerm}
        ></Search>
        <Filter
          occurenceData={occurrenceData}
          occurenceName="occurence"
          occurenceSelect={dateFilter}
          onOccurenceChange={handleSelectDateFilter}
          statusData={statusData}
          statusName="status"
          statusSelect={queryStatus.status}
          statusOnChange={handleSelectStatus}
        />
      </div>
      {loading ? (
          <div className="spinning-star1">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {invoiceList?.length > 0 ? (
              <>
                <table className="table-response">
                  <thead>
                    <tr>
                      {/* <th>S.No</th> */}
                      <th>Invoice #</th>
                      <th>Created On</th>
                      <th>Due On</th>
                      <th>Amount</th>
                      <th>Converted Amount</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody ref={editRef1}>
                    {invoiceList.map((item, r) => (
                      <tr key={r}>
                        {/* <td>
                                    {" "}
                                    {(this.state.currentPage - 1) *
                                      this.state.pageSize +
                                      (r + 1)}
                                  </td> */}
                        <td
                          onClick={() => this.handleDetailpage(item.invoice_id)}
                          style={{
                            color: "#333333",
                            cursor: "pointer",
                            fontWeight: "600 !important",
                          }}
                        >
                          {isErrorTxt(item?.invoice_number)
                            ? "-"
                            : item?.invoice_number}
                        </td>
                        <td className="table-name">
                          {item.createdAt ? `${showDate(item.createdAt)}` : "-"}
                        </td>
                        <td className="table-name">
                          {item.due_date ? `${showDate(item.due_date)}` : "-"}
                        </td>

                        <td className="table-name">
                          {isErrorTxt(item?.final_amount) ? (
                            "-"
                          ) : (
                            <span>
                              {item?.client?.currency !== "inr" && (
                                <span>
                                  {showCurrency(item?.client?.currency ?? "")}
                                </span>
                              )}

                              <span>
                                {item?.client?.currency !== "inr"
                                  ? numberWithRupees(item?.final_amount)
                                  : numberWithComma(item?.final_amount)}
                              </span>
                            </span>
                          )}
                        </td>
                        <td className="table-name">
                          {numberWithComma(item?.converted_amount) ?? 0}
                        </td>
                        <td className="table-name">
                          {amountStatus(item?.status)}
                        </td>
                        <td
                          className="table-name"
                          onClick={(e) => handleEditClick(e, item.invoice_id)}
                        >
                          {item.status !== "cancelled" && (
                            <div className="tax-add-edit">
                              <span className="ml-5">
                                <div className="dropdown-head">
                                  <div className="dropbtn-head">
                                    <img
                                      src={layer}
                                      alt="profile"
                                      className="menu-bar-icon"
                                    />
                                  </div>
                                  {editVisible === item.invoice_id && (
                                    <div className="dropdown-content-heads">
                                      <div>
                                        {item.status !== "paid" && (
                                          <p
                                            className="action-menu-bar"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleActions("edit", item);
                                            }}
                                          >
                                            Edit
                                          </p>
                                        )}
                                        {item.status === "unpaid" && (
                                          <p
                                            className="action-menu-bar"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleActions("cancel", item);
                                            }}
                                          >
                                            Cancel
                                          </p>
                                        )}
                                        {/* {data.status!=='paid' && 

                              <p
                                className="action-menu-bar"
                                onClick={(e) => {
                                e.preventDefault();
                                handleActions('delete',data);
                                }}
                              >
                                Delete
                              </p>} */}

                                        <p
                                          className="action-menu-bar"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleActions("download", item);
                                          }}
                                        >
                                          Download
                                        </p>

                                        <p
                                          className="action-menu-bar"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleActions("duplicate", item);
                                          }}
                                        >
                                          Duplicate
                                        </p>

                                        {item.status === "unpaid" && (
                                          <p
                                            className="action-menu-bar"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleActions("payInvoice", item);
                                            }}
                                          >
                                            Mark as Paid
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </span>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {invoiceList.length > 0 && (
                  <TableField
                    data={invoiceList}
                    countprev={countprev}
                    countnext={countnext}
                    currentPage={currentPage}
                    ClickPrev={() => {
                      previous();
                    }}
                    ClickNext={() => next(currentPage)}
                    total={total}
                    overAll={totalDoc ?? 0}
                    pagelimit={queryStatus.limit}
                    pagelimitHandle={pageLimitOnchange}
                  />
                )}
              </>
            ) : (
              <table className="table-container ">
                <thead>
                  <tr className="tablehead-row">
                    <th className="wid-10">S.No</th>
                    <th className="wid-10">Invoice #</th>
                    <th className="small-tablehead">Title</th>
                    <th className="mid-tablehead">Created on</th>
                    <th className="mid-tablehead">Due On</th>
                    <th className="wide-tablehead">Amount</th>
                    <th className="mid-tablehead">Status</th>
                    <th className="option-tablehead">{""}</th>
                  </tr>
                </thead>
                <tbody className="empty-message">
                  <tr>
                    <td colSpan="6">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </>
      )}

    </div>
  );
};

export default ProjectInvoice;
