import React, { useEffect, useState, useImperativeHandle } from "react";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import {
  showCurrency,
  showLongDateFormat,
  numberWithComma,
  showDayMonthYear,
} from "../../constants/Utils";
import { Spin } from "antd";
//

const Overview = (props) => {

  const [overview, setOverview] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [project, setProject] = useState(null);
  const [overView, setOverView] = useState({
    totalMileStone: 0,
    completedMilestone: 0,
    nextMeeting: null,
    nextReview: null,
    totalChangeRequest: 0,
    totalCost: 0,
    paidInvoice: 0,
    // cancelledInvoice: 0,
    unPaidInvoice:0,
  });
  const [overViewPageLoading, setOverViewPageLoading] = useState(true);
  useEffect(() => {
    fetchProjectOverview();
  }, []);

  function fetchProjectOverview() {
    return new Promise((resolve, reject) => {
      APIGATEWAY.get(
        `project/detailedOverview/${props.project_id}?project_id=${props.projectId}`,
        (response) => {
          if (!response.success) return reject(false);
          const res = response.data.overview;
          setOverView({
            totalMileStone: res.totalMilestone,
            completedMilestone: res.completedMilestone,
            totalChangeRequest: res.totalChangeRequest,
            totalCost: Math.trunc((res.invoice.paid ?? 0) + (res.invoice.unpaid ?? 0)),
            paidInvoice: res.invoice.paid,
            // cancelledInvoice: res.invoice.cancelled,   
            unPaidInvoice: res.invoice.unpaid,
            nextReview: res.nextReview,
            nextMeeting: res.nextMeeting,
          });
          setOverViewPageLoading(false)
          // setOverview(project);
          // setInvoice(invoice);
          // resolve(overview);
        }
      );
    });
  }

  const cards = [
    {
      id: "1",
      title: "MILESTONES",
      value: overView.totalMileStone,
    },
    {
      id: "2",
      title: "COMPLETED MILESTONE",
      value: overView.completedMilestone,
    },
    {
      id: "3",
      title: "CHANGE REQUEST",
      value: overView.totalChangeRequest,
    },
    {
      id: "4",
      title: "TOTAL COST",
      value: numberWithComma(overView.totalCost),
    },

    {
      id: "5",
      title: "PAID INVOICE",
      value: numberWithComma(overView.paidInvoice),
    },
    {
      id: "6",
      title: "UNPAID INVOICE",
      value: numberWithComma(overView.unPaidInvoice),
    },
    {
      id: "7",
      title: "NEXT REVIEW",
      value: overView.nextReview ? showDayMonthYear(overView.nextReview) : "-",
    },
    {
      id: "8",
      title: "NEXT MEETING",
      value: overView.nextMeeting
        ? showDayMonthYear(overView.nextMeeting)
        : "-",
    },
  ];






  return (
    <div>
      {overViewPageLoading ? (
        <div className="spinning-star">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div className="project-cards">
            {cards.map((items) => (
              <div key={items.id} className="projectcards-list">
                <p>{items.title}</p>
                <span>{items.value}</span>
              </div>
            ))}
          </div>
          {props.description && (
            <div className="description-container">
              <h4>Description</h4>
              {props.description}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Overview;
