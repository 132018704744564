import React from 'react';
import { Input } from "antd";

const InputDataField = ({ placeholder, name, value, onchange,state }) => {
    return (
        <div>
            <Input
                placeholder={placeholder}
                name={name}
                style={{ height: "47px" }}
                value={value}
                onChange={onchange}
                disabled={state}
                // readOnly={state}
            />
        </div>
    )
}

export default InputDataField;