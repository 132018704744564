import React, { Component } from "react";

import { Card, Col, Spin, Row } from "antd";

//Utils
import {
  returnSecondDegreeObjValue,
  returnFirstDegreeObjValue,
} from "../../constants/Utils";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";

export default class ClientDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      id: this.props.match.params.id,
      loading: true,
      currentPage: 1,
      name: "",
      number: "",
      address: "",
      billingAddress: "",
      email_id: "",
      reasonType: "",
      clientid: "",
      alternative_email: "",
      alternativeEmail: "",
      referrer_name: "",
      referrer_about: "",
      tags: [],
      inputVisible: false,
      inputValue: "",
    };
  }
  componentDidMount() {
    var id = this.state.id;
    if (id) {
      this.handleTable(id);
    }
  }
  //Alternative Email
  handleOnInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleTable = (id) => {
    let username = this.state.id;
    APIGATEWAY.get("clients/" + username, (response) => {
      if (response.success) {
        let data = response.data.client;
        this.setState({
          data: data,
          alternative_email: data.alternative_email.toString(),
          loading: false,
        });
      } else {
        this.setState({ loading: true });
      }
    });
  };
  // Client Validations
  handleProjectPage = (id) => {
    this.props.history.push("/client/project/" + id);
  };
  render() {
    let { data } = this.state;
    return (
      <div style={{ padding: "40px" }}>
        {this.state.loading ? (
          <div className="spinning-star">
            <Spin size="large" />
          </div>
        ) : (
          <Card

            extra={
              <button
                className="button-div"
                type="primary"
                style={{ marginRight: "25px" }}
                onClick={() => this.handleProjectPage(this.state.id)}
              >
                Project
              </button>
            }
          >
            <div className="flex-sp text-capitalize " style={{ width: "100%" }}>
              <div className="width-50 mr-20">
                <div className="field-holder mb-30">
                  <Row>
                    <Col className="font-500" span={8}>
                      Name :
                    </Col>
                    <Col span={16}>
                      {returnFirstDegreeObjValue(data, "name")}
                    </Col>
                  </Row>
                </div>
                <div className="field-holder mb-30">
                  <Row>
                    <Col className="font-500" span={8}>
                      Email :
                    </Col>
                    <Col span={16}>
                      {returnFirstDegreeObjValue(data, "email")}
                    </Col>
                  </Row>
                </div>
                <div className="field-holder mb-30">
                  <Row>
                    <Col className="font-500" span={8}>
                      Phone Number :
                    </Col>
                    <Col span={16}>
                      {returnSecondDegreeObjValue(
                        data,
                        "phone",
                        "national_number"
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="field-holder mb-30">
                  {this.state.alternative_email ? (
                    <Row>
                      <Col className="font-500" span={8}>
                        Alternative Email :
                      </Col>
                      {this.state.alternative_email ? (
                        <Col span={16}>{this.state.alternative_email}</Col>
                      ) : null}
                    </Row>
                  ) : null}
                </div>
              </div>
              <div className="width-50">
                <div className="field-holder mb-30">
                  <Row>
                    <Col className="font-500" span={8}>
                      Address:
                    </Col>
                    <Col span={16}>
                      {returnFirstDegreeObjValue(data, "address")}
                    </Col>
                  </Row>
                </div>
                <div className="field-holder mb-30">
                  <Row>
                    <Col className="font-500" span={8}>
                      Business Address:
                    </Col>
                    <Col span={16}>
                      {returnFirstDegreeObjValue(data, "billing_address")}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <div>
              <h3 className="title-wrapper"> Referred By</h3>

              <div className="field-holder">
                {returnSecondDegreeObjValue(data, "referred", "name") ===
                  "Not Provided" && (
                    <div className="field-holder flex-sp">
                      <p className="title-wrapper font-500">Existing Client :</p>
                      <p>
                        {returnSecondDegreeObjValue(
                          data,
                          "referred",
                          "client_id"
                        )}
                      </p>
                    </div>
                  )}

                {returnSecondDegreeObjValue(data, "referred", "client_id") ===
                  "Not Provided" && (
                    <div className="field-holder">
                      <h4 className="title-wrapper font-500">Other</h4>
                      <div className="field-holder flex-sp">
                        <p className=" font-500" style={{ marginRight: "20px" }}>
                          Name:
                        </p>
                        <p>
                          {returnSecondDegreeObjValue(data, "referred", "name")}
                        </p>
                      </div>
                      <div className="field-holder flex-sp">
                        <p className=" font-500" style={{ marginRight: "20px" }}>
                          About:
                        </p>
                        <p>
                          {returnSecondDegreeObjValue(data, "referred", "about")}
                        </p>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </Card>
        )}
      </div>
    );
  }
}
