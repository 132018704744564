
import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

const SubMenu = ({ item,updateRoute }) => {

  const [subnav1, setSubnav1] = useState(false);
  const [pathUrl, setPathUrl] = useState("");

  const showSubnav = () => {
    setSubnav1(!subnav1);
  };
  useEffect(() => {
    showActiveNav();
    const queryString = window.location.pathname.split("/");
    let query = queryString[1];
    setPathUrl(query);
  }, []);
  
  const menuClick = (item) => {
    if (item.sub_roles) {
      showSubnav();
     
    }

    // small_sidebar(item);

  };

  const showActiveNav = () => {
    let location = window.location.pathname;
    let moduleName = ["Time Off","My Payments","My Business","Settings"]
    const title = item.name
    // title === "Time Off" || title === "My Payments" || title === "My Business" || title === "Settings"
    if (moduleName.includes(title)) {
      let sub = item.sub_roles;
      sub.forEach((el) => {
        if (location.slice(0, el?.path.length) === el.path) {
          activeLink();
        }
      });
    }
  };

  const small_sidebar = (data) => {
   
    // setSubnav1(true);
    updateRoute(data);
    // showActiveNav()
  };

  const activeLink = () => {
    setSubnav1(true);
    item.isActive = true;
  };


  return (
    <>
      <div className="full_bar">
        <NavLink
          to={item.path !== "" && item.path}
          className="qk-sidemenu qk-sidebar-hover"
          activeClassName={"qk-sidebar-active"}
          onClick={() => menuClick(item)}
        >
          <div className="qk-sidebar-wrapper">
            <span className="qk-sidebar-head">
              <img
                src={item.icon}
                alt=''
                style={{ fontSize: "18px", marginRight: "15px" }}
              />
              <span className="qk-sidebar-title">{item.name}</span>
            </span>
          </div>
          <div className="qk-updown">
            <span>
              {item?.sub_roles && subnav1
                ? <HiChevronUp />
                : item.sub_roles && !subnav1
                ? <HiChevronDown />
                : null}
            </span>
          </div>
        </NavLink>
        {item.sub_roles &&
          subnav1 &&
          item?.sub_roles.map((MainItem, index) => {
            return (
              <NavLink
                to={MainItem.path}
                key={index}
                className="qk-sidemenu-wrapper qk-sidebar-hover"
                activeClassName={"qk-sidebar-actives"}
                onClick={() => small_sidebar(item)}
              >
                <div>
                  <span className="qk-sidebar-head">
                    <span className="qk-sidebar-title">{MainItem.name}</span>
                  </span>
                </div>
              </NavLink>
            );
          })}
      </div>

      <div className="small_bar small-screen-bar">
        <div style={{ width: "100%" }} className="sidebar_icon">
          <NavLink
            to={item.path !== "" && item.path}
           
            className={
              item.isActive
                ? "qk-sidemenus qk-sidebar-hover change qk-dec-col"
                : "qk-sidemenus qk-sidebar-hover change"
            }
            activeClassName={"qk-sidebar-active"}
            onClick={() => small_sidebar(item)}
          >
            <div
              className="qk-sidebar-wrapper"
             
            >
              <span className="qk-sidebar-head">
                <img src={item.icon} alt='' className="qk-menu-icons" />
              </span>
            </div>
          </NavLink>
        </div>
        <div className="small-bar-container">
          {item.sub_roles &&
            item.sub_roles.map((subItem, index) => {
              return (
                <NavLink
                  to={subItem?.path}
                  key={index}
                  className="qk-sidemenus-small-screen  qk-sidebar-hover"
                  activeClassName={"qk-sidebar-active-small"}
                  onClick={() => small_sidebar(item)}
                >
                  <span className="qk-sidebar-title-small">
                    {subItem.name}
                  </span>
                </NavLink>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SubMenu;
