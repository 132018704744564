
function Search({ details, style, outerDivClassName, placeholder, searchClassName,onSearchChange }) {
  return (
    <div style={style} className={`${outerDivClassName}`}>
      <input
        onChange={onSearchChange}
        className={`${searchClassName ?? ""} search`}
        placeholder={placeholder}
        type="text"
      ></input>
    </div>
  );
}


export default Search;
