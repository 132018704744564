import React, { Component } from "react";
import { Divider, Tabs, Spin, Modal, Input, Select, message, Card } from "antd";
import TopHeader from "../../components/ui/TopHeader";
import { STORAGE, APPROVAL } from "../../constants/roleStore";
import InputItem from '../../components/ui/InputItem';
import DateItem from '../../components/ui/DateItem';
import SelectItem from '../../components/ui/SelectItem';
import { showDate, showError, returnExperience, isErrorTxt, numberWithComma, capitalize } from "../../constants/Utils";
import moment from "moment";
import DatePicker from "../../components/ui/DatePicker";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";

const { TabPane } = Tabs;

const { Option } = Select;

export default class EmployeeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      id: this.props.match.params.id,
      payslip_id: this.props.match.params.payslips_id,
      loading: true,
      salaries: [],
      salaryArray: [],
      currentPage: 1,
      statusModal: false,
      statusReason: "",
      reason: "",
      relieved: "",
      status: "",
      sal_id: "",
      total: 10,
      emp_id: "",
      payslips: [],
      app_name: "",
      app_type: "",
      statusData: [
        { key: 'relieved', value: 'Releived' },
        { key: 'absconded', value: 'Absconded' },
        { key: 'terminated', value: 'Terminated' },
      ]
    };
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.handleTenantsDetail();
    });
  };
  handleCancel = (e) => {
    this.setState({
      statusModal: false,
      relieved: "",
      reason: "",
      statusReason: "",
    });
  };
  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  // handleDateChange = ( date, dateString, name ) => {
  //   if ( dateString === "" )
  //     this.setState( {
  //       [ name ]: dateString,
  //     } );
  //   else
  //     this.setState( {
  //       [ name ]: moment( dateString ),
  //     } );
  // };

  handleDateChange = (e) => {
    this.setState({
      relieved: e
    });
  };

  update = () => {
    this.setState({
      statusModal: true,
    });
  };

  componentDidMount() {
    var id = this.state.id;
    this.handleTenantsDetail(id);
    this.salaryApi();
    this.handlepaySlipDetails(id);
    let name = "";

    let storage = JSON.parse(STORAGE.view);

    name = storage?.filter((app) => app?.name === "Employees");

    this.setState({
      app_name: name,
      app_type: STORAGE.type,
    });

    let date = new Date();
  }
  handleTenantsDetail = (id) => {
    let username = this.state.id;
    APIGATEWAY.get("employees/" + username, (response) => {
      if (response.success) {
        let data = response?.data;
        let salaryInfo = data?.employee?.salaries??"";
        this.setState({
          data: data?.employee??"",
          salaries:salaryInfo??"",
          loading: false,
          status: data?.employee?.status??"",
          emp_id: data?.employee?.emp_id??"",
        });
      } else {
        this.setState({ loading: true });
      }
    });
  };

  handlepaySlipDetails = (id) => {
    let self = this;
    const { emp_id } = self.state;
    APIGATEWAY.get("payslip/" + emp_id + "/" + id, (response) => {
      if (response.success) {
        let res = response.data;
        self.setState({
          payslips: res.payslips,
        });
      }
    });
  };
  handleBackPage = () => {
    this.props.history.push("/employees");
  };
  handleEditPage = () => {
    let username = this.state.id;
    this.props.history.push("/employees/edit/" + username);
  };
  handleOk = (id) => {
    let { statusReason, reason, relieved } = this.state;
    if (statusReason === "") {
      showError("Please Select a Status");
    } else if (statusReason === "absconded" && reason === "") {
      showError("Please Provide a Reason");
    } else if (statusReason === "terminated" && reason === "") {
      showError("Please Provide a Reason");
    } else {
      let data = {
        status: statusReason,
        "official_date.relieved": relieved.toISOString(),
        reason: reason,
      };
      APIGATEWAY.patch("employees/" + id, data, (response) => {
        if (response.success) {
          message.success(response.message);
          this.handleCancel();
          this.handleTenantsDetail();
        } else {
          message.error(response.message);
        }
      });
    }
  };
  handleProjectPage = (id) => {
    this.props.history.push("/employees/project/" + id);
  };

  salaryApi = () => {
    let username = this.state.id;
    APIGATEWAY.get(`salaries/${username}`, (response) => {
      if (response.success) {
        let res = response.data.salary;
        this.setState({
          salaryArray: res,
        });
      }
    });
  };

  textContent = (content, name, val) => {
    return (
      <div className="name-wrappers">
        <div className="title-wrapper font-500">{name}</div>
        <div className={name === 'Address' || val === 'email' ? "data-content " : "data-content text-capitalize"}>
          {name === 'Address' ? (
            isErrorTxt(content) ? "-" : capitalize(content) ?? '-'
          ) : val === 'email' ? (isErrorTxt(content) ? "-" : content ?? '-') : (
            isErrorTxt(content) ? "-" : isErrorTxt(val) ? content ?? '-' : showDate(content)
          )}

        </div>
      </div>
    );
  };
  render() {
    const { app_name, app_type, data, salaries, reason, relieved } = this.state;

    let { experience } = this.state.data;
    const title = <span className="dec-title">Employee Details</span>;
    return (
      <div>
        {this.state.loading ? (
          <div className="spinning-star">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <TopHeader
              title="Employee Details"
              backClick1={this.handleBackPage}
              updateStatus={this.update}
              statusName=" Update Status"
              status={this.state.status}
              type={app_type}
              permission={app_name}
            />

            <div
              className="onboard-detail_main"
              style={{ marginBottom: "100px" }}
            >
              <Modal
                title="Status"
                open={this.state.statusModal}
                // onCancel={() => this.handleCancel()}
                // onOk={() => this.handleOk(this.state.id)}

                footer={[
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      className="ad-btn-secondary"
                      onClick={() => this.handleCancel()}
                    >
                      Cancel
                    </button>
                    <button
                      className="probtn"
                      onClick={() => this.handleOk(this.state.id)}
                    >
                      Submit
                    </button>
                  </div>
                ]}
              >
                <div className="field-holder">

                  <SelectItem label='Status' star={false} value={this.state.statusReason} placeholder="Selected by" data={this.state.statusData} onChange={(value) => this.setState({ statusReason: value })} />

                </div>
                {this.state.statusReason === "absconded" ||
                  this.state.statusReason === "terminated" ? (
                  <div className="field-holder">

                    <InputItem label='Reason' name='reason' value={reason} star={false} placeholder="Enter the Reason" onChange={this.handleInputChange} />
                  </div>
                ) : null}
                <div>

                  <DateItem label='Last Working Day' name='relieved' star={false} value={relieved} placeholder="Date" onChange={this.handleDateChange} />

                </div>
              </Modal>

              <Divider orientation="left">Basic Details</Divider>
              <div className="details-content">
                {this.textContent(data.name, 'Name')}
                {this.textContent(data?.email?.personal, 'Personal Email', 'email')}

              </div>

              <div className="details-content">
                {this.textContent(data?.official_emp_id, 'Employee ID')}
                {this.textContent(data?.blood_group, 'Blood Group')}
              </div>

              <div className="details-content">
                {this.textContent(data?.phone?.national_number, ' Phone Number(+91)')}
                {this.textContent(data?.dob, 'Date of Birth', 'dob')}
              </div>

              <div className="details-content">

                {this.textContent(data?.email?.official, 'Office Email', 'email')}

                {this.textContent(data?.status, 'Status')}
              </div>
              <div className="details-content mb-4">
                {this.textContent(data?.address, 'Address')}
              </div>

              <Divider orientation="left">Bank Account Details</Divider>
              <div className="details-content emergency-width mb-4">
                {this.textContent(data?.bank?.acc_name, 'Account Name')}
                {this.textContent(data?.bank?.acc_no, 'Account Number')}
                {this.textContent(data?.bank?.ifsc, 'IFSC Code')}

              </div>
              <Divider orientation="left">Emergency Contact Details</Divider>
              <div className="details-content emergency-width">
                {this.textContent(data?.emergency?.name, 'Name')}
                {this.textContent(data?.emergency?.relation, 'Relation')}
                {this.textContent(data?.emergency?.phone?.national_number, 'Phone Number')}
              </div>

              {salaries < 1 ? null : (
                <div>
                  <Divider orientation="left">Current Salary</Divider>
                  <div
                    className="details-content"

                  >
                    <div className="name-wrappers">
                      <div className="title-wrapper font-500">
                        Monthly Salary
                      </div>
                      <div className="data-content">
                        {isErrorTxt(data?.current_salary?.monthly) ? numberWithComma(0) : numberWithComma(data?.current_salary?.monthly)}
                      </div>
                    </div>
                    <div className="name-wrappers">
                      <div className="title-wrapper font-500">
                        Annual Salary
                      </div>
                      <div className="data-content">
                        {isErrorTxt(data?.current_salary?.annual) ? numberWithComma(0) : numberWithComma(data?.current_salary?.annual)}

                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Tabs defaultActiveKey="1" className="tab-render">
                <TabPane tab="Office Details" key="1">
                  <div className="details-content">

                    {this.textContent(data?.role?.name, 'Role')}
                    {this.textContent(data?.official_date?.interviewed, 'Interviewed Date', 'dob')}

                  </div>
                  <div className="details-content">

                    {this.textContent(data?.official_date?.joined, 'Joined Date', 'dob')}

                    {this.state.status !== "working" && (

                      this.textContent(data?.official_date?.relieved, 'Relieved Date', 'dob')

                    )}
                  </div>
                  <div className="details-content">
                    <div className="name-wrappers">
                      <div className="title-wrapper font-500">Experience</div>
                      <div className="data-content">
                        {experience.is_experienced === false
                          ? "Fresher"
                          : "Experienced"}
                      </div>
                    </div>
                    {experience.is_experienced === true ? (
                      <div className="name-wrappers">
                        <div className="title-wrapper font-500">
                          No.of Experience(inYears)
                        </div>

                        <div className="data-content">
                          {returnExperience(
                            experience?.years,
                            isErrorTxt(data?.official_date?.joined) ? "-" : showDate(data?.official_date?.joined)
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        )}
      </div>
    );
  }
}
