import React, { Component } from "react";

//Router
import MainRouter from "./routes/Router";

class App extends Component {
  render = () => {
    return <MainRouter />;
  };
}

export default App;
