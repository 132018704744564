import React from "react";

const InputItem = ( { label, name, placeholder, value, onChange, notes, star, number, txtarea, labelstar, readonly, phone, height ,style,maxlength} ) => {
  return (
    <div className="input-item-label" style={{width: '100%', ...style }}>
      {label && <label className="block-label">{label}<span className="star">{star ? '*' : ''}</span></label>}
      {txtarea ? ( <div className="textarea-field" style={{ width:  '100%' }}>
        <textarea placeholder={placeholder} name={name} autoComplete="off" value={value} onChange={onChange} style={{ height: height ? '130px' : '', width: notes ? '111%' : '' }}> </textarea>
      </div> ) : (
        <div className={number ? "number-label-input" : "text-label-input"}>
          <input type="text" name={name} placeholder={placeholder} autoComplete="off" value={value} onChange={onChange} readOnly={readonly ? true : false} maxLength={maxlength ?? (phone || number ? 10 : '')} />
          {number && ( <span className="input-number-item" >91</span> )}
        </div>
      )}
    </div>

  );
};

export default InputItem;
