import React from "react";
import { Button, Form, Input, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import cookie from "react-cookies";
import { setCookies, showSuccess, showError } from "../../constants/Utils";
//Logo
import Logo from "../../assets/FrameLogo.svg";
import { Component } from "react";
import "../../stylesheets/styles.css";
import { Spinner } from "reactstrap";

const FormItem = Form.Item;
class Signup extends Component {
  state = {
    email: "",
    password: "",
    errMsg: "",
    loading: false,
    disabled: false,
    errors: {
      email: false,
      password: false,
    },
    btn_loader: false,
  };
  handleSubmit = () => {
    let { email, password } = this.state;
    if (email === "") {
      message.error("Email field should not be empty");
    } else if (password === "") {
      message.error("Password field should not be empty");
    } else {
      let data = {
        email: this.state.email,
        password: this.state.password,
      };
      this.setState({ loading: true, disabled: true, btn_loader: true });
      APIGATEWAY.post("auth/login", data, (response) => {
        if (response.success) {
          const {token, type, app} = response.data
          showSuccess( response.message );

          cookie.save("empr_type", type, { maxAge: 604800 });
          localStorage.setItem("empr_app", JSON.stringify(app));
          cookie.save("empr_tk", token, { maxAge: 604800 });
          setTimeout(()=>{
            window.location.reload();
          },1000)
          this.setState({
            btn_loader: false,
          });
        } else {
          showError(response.message);
        }
        if (response.success === false) {
          this.setState({ btn_loader: false });
        }
      });
    }
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidUpdate = () => {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
  };
  enter = (e) => {
    e.key === "Enter" && this.handleSubmit();
  };

  render() {
    const { btn_loader } = this.state;
    
    return (
      <div className="login-wrap">
        <div className="login-flex-wrap">
          <div className="image-flex">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-wid sign-in_text">
                <img src={Logo} alt="logo"></img>
              </div>
            </div>
            <div className="app-login-content">
              <Form
                className="gx-signin-form gx-form-row0"
                style={{ width: "90%" }}
              >
                <p className="login-header mb-2">Login</p>
                <p className="signin-title mb-3">Sign In to your Account</p>
                <FormItem>
                  <Input
                    placeholder="Email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    className="sign-input-menu-field"
                    // style={{ height: 44 }}
                    prefix={<UserOutlined className="input-menu-icon" />}
                  />
                </FormItem>
                <FormItem>
                  {this.state.errors.password}
                  <Input.Password
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="sign-input-menu-field"
                    value={this.state.password}
                    onChange={this.handleChange}
                    onKeyDown={this.enter} //enter to hit handle submit
                    prefix={<LockOutlined />}
                    // style={{ height: 44 }}
                  />
                </FormItem>
                <FormItem>
                  <div className="signup-block">
                    {btn_loader ? (
                      <div className="btn_loader">
                        <Spinner children={false} />
                      </div>
                    ) : (
                      <button
                        type="primary"
                        // className="ad-btn"
                        className="signup-btn"
                        onClick={this.handleSubmit}
                      >
                        Sign In
                      </button>
                    )}
                  </div>
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Signup;
