import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Notification from "../assets/Path_425.png";

import ProfileIcon from "../assets/blank-profile-picture-973460__480.webp";
import Profile from "../assets/Group 1000001212.svg";
import cookie from "react-cookies";
import APIGATEWAY from "../ApiService/ApiGateWay/apiGateWay";
import SubMenu from "../layouts/SubMenu";
import {
  Layout,
  Modal,
  Button,
  Form,
  Input,
  message,
  Typography,
  Divider,
  Dropdown,
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { showError, setActive } from "../constants/Utils";
import { DownOutlined } from "@ant-design/icons";
import { STORAGE } from "../constants/roleStore";
class Hearder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {},
      open: false,
      notification: {},
      data: [],
      modal1Visible: false,
      show: true,
      new_password: "",
      old_password: "",
      confirm_password: "",
      type: "",
      username: "",
      unread_notification: "",
      menubar: true,
      type: "",
    };
    this.myRef = React.createRef();
  }
  componentDidMount = () => {
   
    this.handleTable();
    this.adminProfile();
    let type = STORAGE.type;
    this.notification();

    this.setState({ type: type });
    let closeDropdown = (e) => {
      if (e.path[0] !== this.myRef.current) {
        this.setState({ open: false });
      }
    };

    return () => document.body.removeEventListener("click", closeDropdown);
  };

  handleButtonClick = () => {
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };

  notification = () => {
    APIGATEWAY.get("employees/notifications", (response) => {
      if (response.success) {
        let data = response.data.notifications;
        this.setState({ notification: data });
      }
    });
  };

  notificationRead = (item) => {
    let data = {};

    APIGATEWAY.patch(
      "employees/notifications/" + item.notification_id,
      data,
      (response) => {
        if (response.success) {
          item.type === "leave"
            ? this.props.history.push("/leaves")
            : this.props.history.push("/permissions");

          this.notification();
          this.adminProfile();
        }
      }
    );
  };

  readAll = () => {
    APIGATEWAY.patch("employees/notifications", {}, (response) => {
      if (response.success) {
        this.notification();
        this.adminProfile();
        this.setState({
          open: false,
        });
      }
    });
  };

  menuBar = () => {
    this.setState({ menubar: !this.state.menubar });
    localStorage.setItem("menubar", this.state.menubar);

    let body = document.body;

    if (body.classList.contains("full_side_bar")) {
      body.classList.add("small_side_bar");
      body.classList.remove("full_side_bar");
    } else {
      body.classList.add("full_side_bar");
      body.classList.remove("small_side_bar");
    }
  };

  adminProfile = () => {
    APIGATEWAY.get("get/profile", (response) => {
      if (response.success) {
        let data = response?.data;
        this.setState({
          username: data?.profile?.name ?? "Admin",
          unread_notification: data?.unReadNotification ?? 0,
        });
      }
    });
  };
  cancel = () => {
    let self = this;
    self.setState({
      modal1Visible: false,
      old_password: "",
      new_password: "",
      confirm_password: "",
    });
  };
  setModal1Visible = (modal1Visible) => {
    this.setState({
      modal1Visible,
      new_password: "",
      old_password: "",
      confirm_password: "",
      c_password: "",
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onMenuClicks = () => {
    setActive("holiday");
  };
  submitHandle = () => {
    let { old_password, new_password, confirm_password } = this.state;

    if (old_password === "") {
      showError("Please enter your current password");
    } else if (new_password === "") {
      showError("Please enter your new password");
    } else if (confirm_password === "") {
      showError("Please enter your confirm_password");
    } else if (new_password !== confirm_password) {
      showError("Password must be the same");
    } else {
      this.setState({ show: false });
      let data = {
        password: this.state.old_password,
        new_password: this.state.new_password,
      };
      APIGATEWAY.post("resetpassword", data, function (response) {
        if (response.success) {
          message.success(response.message);
          this.setState({
            new_password: "",
            old_password: "",
            confirm_password: "",
          });
        } else {
          message.error(response.message);
        }
      });
      this.setModal1Visible(false);
    }
  };

  handleTable = () => {
    APIGATEWAY.get("employees", (response) => {
      if (response.success) {
        let res = response.data;
        this.setState({
          data: res,
        });
      }
    });
  };

  render() {
    let self = this;
    const { profile, notification, unread_notification, username, type } =
      self.state;
    const location = this.props.location.pathname;

    return (
      <div className="header-top">
        <div className="header-qk">
          <img src={Profile} className="head-icon" onClick={this.menuBar}></img>
          <span>{location === "/dashboard" && "Dashboard"}</span>
        </div>
        <div className="head-col-2">
          <div className="dropdown">
            <p
              className="dropdown-toggle m-0 mr-3"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => this.handleButtonClick()}
            >
              <img
                src={Notification}
                alt="notification"
                className="notification"
              />
              <span className="notification-box">
                {unread_notification ?? 0}
              </span>
            </p>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {this.state.open && (
                <div className="dropdown-dash" id="div1">
                  <div className="dropdown-noti">
                    <p className="dropdown-noti-name">Notification</p>

                    {unread_notification === 0 ? (
                      <div></div>
                    ) : (
                      <p
                        className="dropdown-noti-clear"
                        onClick={() => {
                          this.readAll();
                        }}
                      >
                        Read All
                      </p>
                    )}
                  </div>
                  <Divider />
                  <ul className="dropdown-employee-notification">
                    {notification &&
                      notification.map((item, n) => (
                        <li
                          className={
                            item.read
                              ? "dropdown-noti-list-read"
                              : "dropdown-noti-list"
                          }
                          key={n}
                          onClick={() => this.notificationRead(item)}
                        >
                          {item.employee?.image ? (
                            <img
                              className="profile-picture"
                              src={item.employee.image}
                              alt="employee"
                            />
                          ) : (
                            <p className="profile-picture">
                              {item?.employee?.name?.slice(0, 1).toUpperCase()}
                            </p>
                          )}
                          <div style={{ marginTop: "3px", padding: "5px" }}>
                            <p className="noti-admin">
                              {item?.employee?.name ?? "-"}
                            </p>
                            <p className="noti-admin-des">
                              {item?.description ?? ""}
                            </p>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="right-border"></div>
          <div className="head-col-3">
            <div className="header">
              <div className="head">
                <div className="dropdown-head">
                  <div className="dropbtn-head">
                    <img
                      src={ProfileIcon}
                      alt="profile"
                      className="profile-pic"
                    />
                    <div className="head-right">
                      <p className="header-name">{username ?? "-"}</p>
                    </div>
                  </div>
                  <div className="dropdown-content-head">
                    <a
                      // to="#"
                      style={{ padding: "13px 14px" }}
                      onClick={() => this.setModal1Visible(true)}
                    >
                      <i className="fa fa-user-plus plus"></i> Change Password
                    </a>
                    {type === "super_admin" && (
                      <a href="/holidays" style={{ padding: "13px 14px" }}>
                        <i className="fa fa-umbrella pluss"></i> Holidays
                      </a>
                    )}

                    <a
                      href="/signin"
                      onClick={() => {
                        cookie.remove("empr_tk");
                        window.location.reload();
                      }}
                    >
                      <i className="fa fa-sign-out pluss"></i>
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.show && (
            <Modal
              title="Change Password"
              style={{ top: 50 }}
              open={this.state.modal1Visible}
              onOk={() => this.setModal1Visible(false)}
              onCancel={() => this.setModal1Visible(false)}
              footer={[
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    className="secondary_color"
                    onClick={() => this.cancel()}
                  >
                    Cancel
                  </button>
                  <button
                    className="add-btn"
                    onClick={() => this.submitHandle()}
                  >
                    Submit
                  </button>
                </div>,
              ]}
            >
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                className="admin_input"
              >
                <div className="margin">
                  <Typography>Current Password</Typography>
                </div>
                <Form.Item
                  name="old_password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your current password!",
                    },
                  ]}
                  className="form_in"
                >
                  <Input.Password
                    name="old_password"
                    style={{ width: "100%" }}
                    placeholder="Please enter your current password"
                    value={this.state.old_password}
                    onChange={(e) => this.handleChange(e)}
                  />
                </Form.Item>
                <div className="margin">
                  <Typography>New Password</Typography>
                </div>
                <Form.Item
                  name="new_password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your new password!",
                    },
                  ]}
                  className="form_in"
                >
                  <Input.Password
                    name="new_password"
                    style={{ width: "100%" }}
                    placeholder="Please enter your new password"
                    value={this.state.new_password}
                    onChange={(e) => this.handleChange(e)}
                    suffix={<EyeOutlined />}
                  />
                </Form.Item>

                <div className="margin">
                  <Typography>Confirm Password</Typography>
                </div>
                <Form.Item
                  name="confirm_password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your confirm password!",
                    },
                  ]}
                  className="form_in"
                >
                  <Input.Password
                    name="confirm_password"
                    style={{ width: "100%" }}
                    placeholder="Please enter your confirm password"
                    value={this.state.confirm_password}
                    onChange={(e) => this.handleChange(e)}
                    suffix={<EyeOutlined />}
                  />
                </Form.Item>
              </Form>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(Hearder);
