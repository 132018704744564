//Pages - Dashboard
import Dashboard from "../pages/dashboard";

//Pages - Employees
// import Employees from "../pages/Employee/index";
import Employees from "../pages/Employee/index";

import CreateEmployee from "../pages/Employee/CreateEmployee";
import EmployeeDetail from "../pages/Employee/employeeDetail";
//import NewEmployee from "../pages/Employee/newEmployee";

//Pages - clients
import Clients from "../pages/Clients/index";
import AddClient from "../pages/Clients/addClient";
import ClientDetails from "../pages/Clients/clientDetails";

// pages - projects
import Projects from "../pages/Projects/index";
import ProjectAddEdit from "../pages/Projects/projectAddEdit";
import ProjectDetails from "../pages/Projects/projectDetails";

// pages - project
import Project from "../pages/Project/index";
import AddProject from "../pages/Project/AddProject";
import ProjectDetail from "../pages/Project/ProjectDetail";

//Pages -leave
import Leave from "../pages/leave/index";

// pages -Salary
import Salary from "../pages/Salary/index";
import AddSalary from "../pages/Salary/addSalary";

//Pages -permission
import Permission from "../pages/permission/index";

//pages Jobs
import Jobs from "../pages/Jobs/index";
import AddJobs from "../pages/Jobs/addjobs";
// import EditJobs from "../pages/Jobs/editjobs";

//Expenses
import Expenses from "../pages/Expenses/index";
import AddExpenses from "../pages/Expenses/expensesAdd";
import DetailsExpenses from "../pages/Expenses/DetailsExpenses";
//EmployeeExpenses

import EmployeeExpenses from "../pages/EmployeeExpenses/EmployeeExpenses";

//Accessory
import Accessory from "../pages/Accessories/index";
import DetailsAccessory from "../pages/Accessories/details";
import AddAccessory from "../pages/Accessories/addEdit";

//Vendors
import Vendors from "../pages/Vendors/vendor";

//Carrers
import Career from "../pages/Carrers/index";
//Holiday
import Holiday from "../pages/Holiday/index";

//Requests
import Requests from "../pages/Requests/index";
// Invoice
import Invoice from "../pages/Invoice/index";
import InvoiceDetails from "../pages/Invoice/Invoicedetails";
import AddInvoice from "../pages/Invoice/AddEdit";

//Taxes
import Taxes from "../pages/Taxes/index";

// Pages - 404 Page

import PlatformList from "../pages/platformlist";
import TechnologyList from "../pages/technologylist";
import RoleList from "../pages/rolelist";
import EmployeeProject from "../pages/employeeProject";
import ClientProject from "../pages/clientProject";

import Onboard from "../pages/Onboard/index";
import AddOnboard from "../pages/Onboard/addEdit";

import Tasks from "../pages/Tasks/Tasks";
import Calculator from "../pages/Calculator/calculator";
import RoleManage from "../pages/RoleManage/RoleManage";
import Create from "../pages/RoleManage/Create";

import Leads from "../pages/Leads/index";
import LeadsAddEdit from "../pages/Leads/addEdit";
import LeadsDetails from "../pages/Leads/detail";

import ProjectDetailsPage from "../pages/Project/ProjectDetailsPage";
import Milestone from "../pages/Project/Milestone";
import Overview from "../pages/Project/Overview";
import ResourceManagement from "../pages/ResourceManagement";
import MeetingList from "../pages/Project/meetingList";
import ReviewList from "../pages/Project/reviewList";
import ReferenceList from "../pages/Project/referenceList";
import { LEADSACCESS,CLIENTSACCESS, PROJECTACCESS, JOBACCESS, ACCESSORIESACCESS, INVOICEACCESS, EXPENSESACCESS, HIRINGACCESS, EMPLOYEESACCESS } from "../components/ui/constants";

export const SidebarRouter = [

  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/", name: "Dashboard", component: Dashboard },

  { path: "/employees", name: "Employees", component: Employees },
  { path: "/employees/create", name: "Employees", component: CreateEmployee },
  { path: "/employees/detail/:id", name: "Employees",component: EmployeeDetail },
  { path: "/employees/detail/:id/:payslips_id", name: "Employees",component: EmployeeDetail },

  { path: "/onboard",name: "Hiring", component: Onboard },


  { path: "/leaves", name: "Leaves", component: Leave },
  { path: "/permissions",  name: "Permissions", component: Permission },
  
  { path: "/invoices",  name: "Invoices", component: Invoice },
  { path: "/invoices/detail/:id", name: "Invoices", component: InvoiceDetails },

  { path: "/taxes",  name: "Taxes", component: Taxes },

  { path: "/expenses", name: "Expenses", component: Expenses },
 
  { path: "/expenses/detail/:id", name: "Expenses", component: DetailsExpenses },

  { path: "/salaries",  name: "Salaries", component: Salary },
  { path: "/salaries/add",  name: "Salaries", component: AddSalary },

  { path: "/employee/expenses", name: "Employee Expenses", component: EmployeeExpenses },

  { path: "/projects",  name: "Projects", component: Project },
  { path: "/projects/detailspage/:id", name: "Projects", component: ProjectDetailsPage },
  { path: "/projects/detailspage/:id/meetinglist", name: "Projects", component: MeetingList },
  { path: "/projects/detailspage/:id/reviewlist", name: "Projects", component: ReviewList },
  { path: "/projects/detailspage/:id/referencelist", name: "Projects", component: ReferenceList },


  { path: "/leads",  name: "Leads", component: Leads },
  { path: "/leads/detail/:id",  name: "Leads",component: LeadsDetails },
 

  { path: "/clients", name: "Clients", component: Clients },
  { path: "/clients/detail/:id", name: "Clients", component: ClientDetails },
  { path: "/platforms",  name: "Platforms", component: PlatformList },
  { path: "/technologies",  name: "Technologies", component: TechnologyList },

  { path: "/resource_management",  name : "Resource Management", component: ResourceManagement },

  { path: "/jobs", name: "Jobs", component: Jobs },
  

  { path: "/accessories", name: "Accessories", component: Accessory },
  { path: "/accessories/detail/:id", name: "Accessories", component: DetailsAccessory },

  { path: "/role_manage",  name: "Role Management", component: RoleManage },
  { path: "/role_manage/create",  name: "Role Management", component: Create },
  { path: "/role_manage/edit/:id",  name: "Role Management", component: Create },

  { path: "/careers",name: "Careers", component: Career },
  { path: "/careers/:id",name: "Careers", component: Career },

  { path: "/requests", name: "Queries", component: Requests },
  
  { path: "/holidays",name: "Holidays", component: Holiday },
  
  { path: "/calculator",  name: "Calculator", component: Calculator },

  { path: "/roles",  name: "Roles", component: RoleList },
  
  { path: "/vendors", name: "Vendors", component: Vendors },

  // { path: '/employees/new/:id', component: CreateEmployee },
  // { path: '/employees/new', component: CreateEmployee },
  //{ path: '/employees/add', component: AddEmployee },
  { path: "/employee/project/:id", component: EmployeeProject },
  { path: "/client/project/:id", component: ClientProject },

  { path: "/tasks", name: "Tasks", component: Tasks },
  

  { path: EMPLOYEESACCESS.update && "/employees/edit/:id",name: "Employees", component: CreateEmployee },
  { path: HIRINGACCESS.create && "/onboard/add", name: "Hiring", component: AddOnboard },
  { path: HIRINGACCESS.update && "/onboard/detail/:id", name: "Hiring", component: AddOnboard },
  { path: EXPENSESACCESS.create && "/expenses/add/", name: "Expenses", component: AddExpenses },
  { path: EXPENSESACCESS.update && "/expenses/edit/:id", name: "Expenses", component: AddExpenses },
  { path: INVOICEACCESS.update && "/invoices/add/:id",  name: "Invoices", component: AddInvoice },
  { path: INVOICEACCESS.create &&"/invoices/add/duplicate/:duplicate",  name: "Invoices", component: AddInvoice },
  { path: INVOICEACCESS.create &&"/invoices/add/",  name: "Invoices", component: AddInvoice },
  { path: ACCESSORIESACCESS.create && "/accessories/add", name: "Accessories", component: AddAccessory },
  { path: ACCESSORIESACCESS.update && "/accessories/add/:id",  name: "Accessories",component: AddAccessory },
  { path: JOBACCESS.create && "/jobs/add", name: "Jobs", component: AddJobs },
  { path: JOBACCESS.update &&"/jobs/edit/:id", name: "Jobs", component: AddJobs },
  { path: CLIENTSACCESS.create && "/clients/add", name: "Clients", component: AddClient },
  { path: CLIENTSACCESS.update && "/clients/edit/:id", name: "Clients", component: AddClient },
  { path: PROJECTACCESS.create && "/projects/add", name: "Projects", component: AddProject },
  { path: PROJECTACCESS.update && "/projects/edit/:id",  name: "Projects", component: AddProject },
  { path: LEADSACCESS.create && "/leads/add",  name: "Leads", component: LeadsAddEdit},
  { path: LEADSACCESS.update && "/leads/edit/:id",  name: "Leads", component: LeadsAddEdit},
  
];
