import React, { Component } from "react";
import "../stylesheets/styles.css";
import APIGATEWAY from "../ApiService/ApiGateWay/apiGateWay";
import { Input, message, Spin, Modal, Empty } from "antd";
import {
  showError,
  isInvalidName,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
} from "../constants/Utils";
import TopHeader from "../components/ui/TopHeader";
import TableField from "../components/ui/tableField";
import { STORAGE, UPDATE, APPROVAL, DELETE } from "../constants/roleStore";
import InputItem from "../components/ui/InputItem";
import layer from "../assets/Layer 2.svg";

import { Spinner } from "reactstrap";
import { FormOutlined } from "@ant-design/icons";
import { ROLESACCESS } from "../components/ui/constants";
export default class RoleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleList: [],
      search: "",
      currentPage: 1,
      total: "",
      pageSize: 20,
      loading: true,
      modalOpen: false,
      editmodal: false,
      name: "",
      role_id: "",
      Tableloading: false,
      modal1Visible: false,
      queryStatus: {},
      totalDoc: 0,
      app_name: "",
      app_type: "",
      add_edit_spin: false,
      dropdown_menu: false,
      role_index: "",
      update: false,
    };
    this.wrapperRef = React.createRef();
  }
  componentDidMount() {
    let name = "";

    let storage = JSON.parse(STORAGE.view);
    name = storage?.filter((app) => app?.name === "Settings");

    let value = name;
    value?.filter((app) => {
      let names = app?.sub_roles?.filter((role) => role.name === "Roles");
      names &&
        names.map((el) =>
          this.setState({
            update: el?.permissions?.update,
          })
        );
      this.setState({
        app_name: names,
      });
    });

    this.setState({
      app_type: STORAGE.type,
    });
    this.setQueryStatus();
    this.reloadPage();
    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);
  }
  onPopstate = () => {
    const self = this;
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";
    this.state.queryStatus = returnSearchObject(search);
    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      self.callRoleAPI();
    });
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);
  }
  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };
  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";
    return querysearch;
  };
  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.setQueryStatus();
        }
      });
    });
  };
  setModal1Visible = (modal1Visible) => {
    this.setState({ modal1Visible, name: "", role_id: "" });
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",
          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.callRoleAPI();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.callRoleAPI();
        }
      );
    }
  };

  handleFilter = () => {
    let query = this.getQuerySearch();

    this.setState(
      {
        queryStatus: query,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.callRoleAPI();
      }
    );
  };
  callRoleAPI = () => {
    APIGATEWAY.get(
      "roles" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          let total = response.data?.roles?.length;

          this.setState({
            roleList: response.data.roles,
            total,
            loading: false,
            Tableloading: false,
            totalDoc: response?.data?.total,
          });
        } else {
          this.setState({ loading: true });
        }
      }
    );
  };

  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  next = (prev) => {
    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };
  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.callRoleAPI();
    });
  };
  handleaddCancel = () => {
    this.setState({ modalOpen: false });
  };
  handleEditCancel = () => {
    this.setState({ editmodal: false });
  };
  handleOk = () => {
    let { name } = this.state;
    if (name === undefined || name.trim() === "") {
      showError("Please enter name");
    } else if (name.length < 3) {
      showError("Name should contain atleast 3 characters");
    } else if (isInvalidName(name)) {
      showError("Name should contain letters only");
    } else {
      let data = {
        name: name,
      };
      this.setState({ add_edit_spin: true });
      APIGATEWAY.post("roles", data, (response) => {
        if (response.success) {
          this.setState({ modal1Visible: false });
          this.setState({ add_edit_spin: false });
          this.callRoleAPI();
          message.success(response.message);
        } else {
          message.error(response.message);
        }
      });
    }
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleEditPage = (username) => {
    this.setState({
      modal1Visible: true,
      name: username.name,
      role_id: username.role_id,
    });
    this.roleDetail(username);
  };
  roleDetail = (id) => {
    APIGATEWAY.get("roles/" + id, (response) => {
      if (response.success) {
        let role = response.data.role;
        this.setState({
          name: role.name,
          role_id: role.role_id,
        });
      }
    });
  };
  handleEditOk = (id) => {
    let self = this;
    let { name } = this.state;
    if (name === undefined || name.trim() === "") {
      showError("Please enter Name");
    } else if (name.length <= 3) {
      showError("Name should contain atleast 3 characters");
    } else {
      let data = {
        name: name,
      };
      this.setState({ add_edit_spin: true });
      APIGATEWAY.patch("roles/" + id, data, (response) => {
        if (response.success) {
          self.setState({ modal1Visible: false });
          this.setState({ add_edit_spin: false });
          message.success(response.message);
          self.callRoleAPI();
        } else {
          this.setState({ add_edit_spin: false });
          message.error(response.message);
        }
      });
    }
  };
  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      role_index: index,
    });
  };
  render() {
    let {
      total,
      roleList,
      currentPage,
      name,
      role_id,
      app_name,
      app_type,
      pageSize,
      add_edit_spin,
      dropdown_menu,

      role_index,
    } = this.state;
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }

    const title = (
      <div className="">
        <p className="dec-title">Role</p>
      </div>
    );
    return (
      <div className="">
        {this.state.loading ? (
          <div className="spinning-star">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <TopHeader
              title="Roles"
              modals={() => this.setModal1Visible(true)}
              type={app_type}
              permission={app_name}
            />
            <div style={{ padding: "0px 23px 30px 30px" }}>
              <div style={{ "margin-bottom": "100px" }}>
                <div className="table_page_wrap">
                  {roleList.length > 0 ? (
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th className="">S.No</th>
                          <th className="">Name</th>

                          <th></th>
                        </tr>
                      </thead>

                      {roleList.length > 0 ? (
                        <tbody ref={this.wrapperRef}>
                          {roleList.map((role, r) => (
                            <tr key={r}>
                              <td className="">
                                {" "}
                                {(this.state.currentPage - 1) * pageSize +
                                  (r + 1)}
                              </td>
                              <td className="table-name">{role.name}</td>
                              {ROLESACCESS.update && (
                                <td
                                  className="table-action"
                                  onClick={() => this.dropdownClick(r)}
                                >
                                  <div className="tax-add-edit">
                                    <span className="ml-5">
                                      <div className="dropdown-head">
                                        <div className="dropbtn-head">
                                          <img
                                            src={layer}
                                            alt="profile"
                                            className="menu-bar-icon"
                                          />
                                        </div>
                                        {role_index === r && dropdown_menu && (
                                          <div className="dropdown-content-heads">
                                            <div>
                                              <p
                                                className="action-menu-bar"
                                                onClick={() =>
                                                  this.handleEditPage(role)
                                                }
                                              >
                                                Edit
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        ""
                      )}
                    </table>
                  ) : (
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th className="">S.No</th>
                          <th className="">Name</th>

                          <th></th>
                        </tr>
                      </thead>

                      <tbody className="empty-message">
                        <tr>
                          <td colspan="5">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {roleList.length > 0 && (
                    <TableField
                      data={roleList}
                      countprev={countprev}
                      countnext={countnext}
                      currentPage={currentPage}
                      ClickPrev={() => this.previous()}
                      ClickNext={() => this.next(this.state.currentPage)}
                      total={total}
                      overAll={this.state.totalDoc ?? 0}
                      pagelimit={this.state.pageSize}
                      pagelimitHandle={this.pageLimitOnchange}
                    />
                  )}
                </div>
              </div>
            </div>

            <Modal
              title={role_id ? "Edit Roles" : "Add Roles"}
              open={this.state.modal1Visible}
              onOk={() => this.setModal1Visible(false)}
              onCancel={() => this.setModal1Visible(false)}
              footer={[
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    key="cancel"
                    className="dec-btn-secondary"
                    onClick={() => this.setModal1Visible(false)}
                  >
                    Cancel
                  </button>

                  <button
                    key="submit"
                    className="dec-btn"
                    onClick={
                      role_id !== ""
                        ? () => this.handleEditOk(role_id)
                        : () => this.handleOk()
                    }
                    checked={add_edit_spin}
                  >
                    {/* {role_id !== "" ? "Update" : "Save"} */}

                    {add_edit_spin ? (
                      <div className="addEdit_loader">
                        <Spinner children={false} />
                      </div>
                    ) : (
                      <div>{role_id ? "Update" : " Save"}</div>
                    )}
                  </button>
                </div>,
              ]}
            >
              <InputItem
                label="Name"
                name="name"
                value={name}
                star={false}
                placeholder="Enter the Platform"
                onChange={this.handleInputChange}
              />
            </Modal>
          </div>
        )}
      </div>
    );
  }
}
