import React, { Component } from "react";
import { Link } from "react-router-dom";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { Menu, Dropdown, Checkbox, Spin } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import "../../stylesheets/styles.css";
import moment from "moment";
import TopHeader from "../../components/ui/TopHeader";
import { STORAGE, UPDATE, APPROVAL, CREATE } from "../../constants/roleStore";
import TableField from "../../components/ui/tableField";
import {
  Input,
  Button,
  message,
  Modal,
  Form,
  Tooltip,
  Tabs,
  Popover,
  Select,
  Radio,
  DatePicker,
  Typography,
  Empty,
} from "antd";
import "antd/dist/antd.css";
import layer from "../../assets/Layer 2.svg";

import {
  showError,
  showDates,
  getIsoString,
  showLongDateFormat,
  convertIsoDateToDays,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  isErrorTxt,
  showFullMonthName,
  removeUnderscores,
  leaveType,
  totalLeaveDayCalculater,
} from "../../constants/Utils";
// import { array } from "prop-types";
import Filter from "../../components/ui/filter";
import CreateLeaveModal from "./createLeavemodal";
import InputItem from "../../components/ui/InputItem";
import SelectItem from "../../components/ui/SelectItem";

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;
let filterTimeout;
export default class Leave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      currentPage: 1,
      currentPage1: 1,
      status: "",
      loading: true,
      modal1Visible: false,
      modal3Visible: false,
      groupLeave: "",
      data: "",
      total_leave: "",
      total_month: "",

      total1: "",
      pageSize: 20,
      pageSize1: 20,

      searchInput: "",
      searchOption: "",
      searchEmp: "",
      id: "",
      leave_id: "",
      textarea: "",
      type_leaves: "",
      casualTypes: "",
      halfType: "",
      from: "",
      to: "",
      efrom: moment(),
      eto: moment(),
      type: "",
      dateRange: null,
      Leaveloading: true,
      Monthloading: false,
      isDisable: false,
      showAccept: false,
      isChecked: false,
      leave_list: true,
      monthwise_list: false,
      year_list: false,
      tab: "Daily",
      typeData: [
        { value: "Work From Home", key: "wfh" },
        { value: "Comp-Off", key: "comp_off" },
        { value: "Vacation", key: "vacation" },
        { value: "Sick", key: "sick" },
        { value: "Casual", key: "leave" },
        { value: "Leave", key: "sick_leave" },
        { value: "First Half", key: "first_half" },
        { value: "Second Half", key: "second_half" },
      ],
      searchData: [
        { value: "Name", key: "name" },
        { value: "Emp ID", key: "emp_id" },
      ],
      statusData: [
        { value: "Accepted", key: "accepted" },
        { value: "Requested", key: "requested" },
        { value: "Cancelled", key: "cancelled" },
        { value: "Recalled", key: "recalled" },
        { value: "Rejected", key: "rejected" },
      ],
      app_name: "",
      app_type: "",
      queryStaus: {},
      queryStatus2: {},
      month_wise: 1,
      countLOP: 0,
      // isDisable: true,
      total_days: "",
      isBack: false,
      pagenumberChange: "",
      dropdown_menu: false,
      list_index: "",
      approval: false,
      update: false,
      create: false,
      create_leave: false,
      employee_name: "",
      employee_list: [],
      showemployee: false,
      employee_id: "",
      timer: null,
      // leaveModal:false,
      add_leave: {
        leaveModal: false,
        employee: "wrwerwer",
        type: "",
        from: "",
        to: "",
        casual_type: "",
        reason: "",
      },
    };
    this.wrapperRef = React.createRef();
  }
  componentDidMount = () => {
    let params = new URLSearchParams(window.location.search);

    let name = "";

    let storage = JSON.parse(STORAGE.view);
    name = storage?.filter((app) => app?.name === "Time Off");
    let value = name;
    value?.filter((app) => {
      let names = app?.sub_roles?.filter((role) => role.name === "Leaves");
      names &&
        names.map((el) =>
          this.setState({
            approval: el?.permissions?.approval,
            update: el?.permissions?.update,
          })
        );
      this.setState({
        app_name: names,
      });
    });
    this.setState({
      app_type: STORAGE.type,
    });
    // this.setState({
    //   queryStaus: { page: 1, limit: 20,type:''},
    //   // queryStatus2: { page: 1, limit: 20,type:''},
    // },()=>{
    //   pushToFilteredPage(this.props, this.state.queryStaus);
    //   // pushToFilteredPage(this.props, this.state.queryStatus2);
    //   this.handleTable()
    // })
    this.setQueryStatus();
    //  if(this.state.tab === "Yearly" || this.state.tab === "Monthly"){
    //       this.setQueryStatus2();
    //     }else{

    //       this.setQueryStatus();
    //     }
    this.reload();
    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);
  };

  reload = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);

        if (Object.keys(query).length === 0) {
          this.clearSearch();
          // this.setState(
          //   {
          //     currentPage: 1,
          //     searchInput: "",
          //     searchOption: "",
          //     status: "",
          //     from: "",
          //     dateRange: null,
          //     to: "",
          //     type: "",
          //     queryStaus: { page: 1, limit: 20, type: "" },

          //     pageSize: 20,
          //   })
          // this.clearEmp();
        } else {
          // this.setQueryStatus();
        }
      });
    });
  };

  onPopstate = () => {
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);
    this.state.status = searchObj.status ?? "";
    this.state.from = searchObj.from ?? "";
    this.state.to = searchObj.to ?? "";
    // this.state.froms = searchObj.from ?? "";
    this.state.tos = searchObj.to ?? "";
    this.state.searchOption = searchObj.name
      ? "name"
      : searchObj.emp_id && "emp_id";
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.currentPage1 = parseInt(searchObj.page) ?? "";

    this.state.searchInput = (searchObj.name || searchObj.emp_id) ?? "";
    this.state.type = searchObj.type ?? "";
    this.state.searchEmp = searchObj.empId ?? "";
    this.state.queryStaus = returnSearchObject(search);
    this.state.queryStatus2 = returnSearchObject(search);
    this.state.pageSize = parseInt(searchObj.limit) ?? "";
    this.state.pageSize1 = parseInt(searchObj.limit) ?? "";

    this.setState(
      {
        queryStatus: this.getQuerySearch(),
        queryStatus2: this.getQuerySearch2(),
      },
      () => {
        if (this.state.leave_list) {
          this.handleTable();
        } else {
          this.handleGroup();
        }
      }
    );
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);
  }
  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };

  getQuerySearch2 = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    if (this.state.searchOption !== "" && this.state.searchInput !== "") {
      querysearch[this.state.searchOption] = this.state.searchInput;
    }

    querysearch.page = this.state.currentPage1 ?? "";
    querysearch.limit = this.state.pageSize1 ?? "";
    querysearch.status = this.state.searchEmp ?? "";

    querysearch.from = getIsoString(this.state.from) ?? "";
    querysearch.to = getIsoString(this.state.to) ?? "";

    return querysearch;
  };

  setQueryStatus2 = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          searchEmp: queryObj.empId ?? "",
          from: queryObj.from ?? "",
          to: queryObj.to ?? "",
          currentPage1: parseInt(queryObj.page) ?? "",
          type: queryObj.type ?? "",
          queryStatus2: returnSearchObject(query),
          pageSize1: parseInt(queryObj.limit) ?? "",
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus2);
          this.handleGroup();
        }
      );
    } else {
      this.setState(
        {
          queryStatus2: {
            ...this.state.queryStatus2,
            type: "",
            page: 1,
            limit: 20,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus2);
          this.handleGroup();
        }
      );
    }
  };

  handleFilter2 = () => {
    let { searchEmp, from, to, currentPage1, pageSize1, type, tab } =
      this.state;

    let queryObj = returnSearchObject(this.props.location.search);
    queryObj.empId = searchEmp ?? "";
    queryObj.from = getIsoString(from) ?? "";
    queryObj.to = getIsoString(to) ?? "";
    queryObj.page = currentPage1 ?? "";
    queryObj.limit = pageSize1 ?? "";
    queryObj.type =
      tab === "Monthly" ? "month" : tab === "Yearly" ? "year" : "";

    this.setState({ queryStatus2: queryObj }, () => {
      pushToFilteredPage(this.props, this.state.queryStatus2);
      this.handleGroup();
    });
  };

  onChangeCheckbox = (e) => {
    if (e.target.checked === true) {
      this.setState({ isChecked: e.target.checked });
    } else {
      this.setState({ isChecked: e.target.checked });
    }
  };

  countLOP = (e) => {
    this.setState({ countLOP: e.target.value.replace(/\D/g, "") });
  };
  handleGroup = () => {
    let tab = this.state.tab;
    // this.state.tab !== "Yearly"
    //   ? delete this.state.queryStatus2["type"]
    //   : this.state.queryStatus2;

    APIGATEWAY.get(
      `employees/leaves/list/summaries` +
        buildQueryString(this.state.queryStatus2),
      (response) => {
        if (response.success) {
          let GroupLeave = response?.data?.summaries;
          this.setState({
            groupLeave: GroupLeave,
            loading: false,
            total1: response?.data?.summaries?.length,
            Monthloading: false,
            total_month: response?.data?.total,
          });
        } else {
          this.setState({ loading: true });
        }
      }
    );
  };

  previous1 = () => {
    this.setState(
      {
        currentPage1: this.state.currentPage1 - 1,
        Monthloading: true,
      },
      () => {
        this.handleFilter2();
      }
    );
  };

  next1 = () => {
    this.setState(
      {
        currentPage1: this.state.currentPage1 + 1,
        Monthloading: true,
      },
      () => {
        this.handleFilter2();
      }
    );
  };

  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    if (this.state.searchOption !== "" && this.state.searchInput !== "") {
      querysearch[this.state.searchOption] = this.state.searchInput;
    }

    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";
    querysearch.status = this.state.status ?? "";
    querysearch.type = this.state.type ?? "";
    querysearch.from = getIsoString(this.state.from) ?? "";
    querysearch.to = getIsoString(this.state.to) ?? "";

    return querysearch;
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      let check =
       ( queryObj.empId || queryObj.type === "month" || queryObj.type === "year")
          ? ['empId', 'type'].forEach(e => delete queryObj[e]):queryObj

      let tabs =
        queryObj.type === "month"
          ? "Monthly"
          : queryObj.type === "year"
          ? "Yearly"
          : "Daily";
      this.setState(
        { 
          // searchEmp: queryObj.empId ?? "",
          searchInput: (queryObj.name || queryObj.emp_id) ?? "",
          searchOption: queryObj.name ? "name" : queryObj.emp_id && "emp_id",
          status: queryObj.status ?? "",
          type: !["month", "year"].includes(queryObj.type) ? queryObj.type : "",
          // type: "",

          currentPage: parseInt(queryObj.page) ?? "",
          from: queryObj.from ?? "",
          to: queryObj.to ?? "",
          queryStaus: queryObj,
          pageSize: parseInt(queryObj.limit) ?? "",
          // tab: tabs,
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStaus);

          this.handleTable();
        }
      );
    } else {
      this.setState(
        {
          queryStaus: { ...this.state.queryStaus, page: 1, limit: 20 },
          pageSize: 20,
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStaus);
          this.handleTable();
        }
      );
    }
  };

  handleFilter = () => {
    let query = this.getQuerySearch();

    this.setState({ queryStaus: query }, () => {
      pushToFilteredPage(this.props, this.state.queryStaus);
      this.handleTable();
    });
  };

  handleTable = () => {
    // this.state.queryStaus?.type === "year"
    //   ? delete this.state?.queryStaus["type"]
    //   : this.state.queryStaus;

  
    APIGATEWAY.get(
      "employees/leaves" + buildQueryString(this.state.queryStaus),
      (response) => {
        if (response.success) {
          let res = response.data.leave;

          this.setState({
            data: res,
            total: response.data?.leave?.length,
            Leaveloading: false,
            total_leave: response.data?.total,
            loading: false,
          });
        }
      }
    );
  };

  previous = () => {
    const { currentPage, total } = this.state;
    this.setState({ currentPage: currentPage - 1, Leaveloading: true }, () => {
      this.handleFilter();
    });
  };

  next = (prev) => {
    const { currentPage, total } = this.state;

    this.setState({ currentPage: prev + 1, Leaveloading: true }, () => {
      this.handleFilter();
    });
  };
  setModal1Visible = (modal1Visible) => {
    this.setState({ modal1Visible });
  };
  setModal3Visible = (modal3Visible) => {
    this.setState({ modal3Visible, textarea: "" });
  };

  Apply = () => {
    const { status, from, to, searchInput, searchOption, type } = this.state;
    if (
      status === "" &&
      from === "" &&
      to === "" &&
      type === "" &&
      (searchInput === "" || searchOption === "")
    ) {
      showError("Please select any one field");
    } else {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handleFilter();
        }
      );
    }
  };

  ApplyEmp = () => {
    const { searchEmp, from, to } = this.state;
    if (searchEmp === "" && from === "" && to === "") {
      showError("Please select any one field");
    } else {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handleFilter2();
        }
      );
    }
  };
  clearSearch = () => {
    this.setState(
      {
        currentPage: 1,
        searchInput: "",
        searchOption: "",
        status: "",
        from: "",
        dateRange: null,
        to: "",
        type: "",
        queryStaus: { page: 1, limit: 20, type: "" },
        pageSize: 20,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStaus);

        this.handleTable();
      }
    );
  };

  clearEmp = (state = {}) => {
    this.setState(
      {
        currentPage1: 1,
        searchEmp: "",
        from: "",
        to: "",
        dateRange: null,
        queryStatus2: {
          page: 1,
          limit: 20,
          type:
            this.state.tab === "Yearly"
              ? "year"
              : this.state.tab === "Monthly"
              ? "month"
              : "daily",
        },
        pageSize1: 20,
        ...state,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus2);

        this.handleGroup();
      }
    );
  };
  handleSearchChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.handleTable();
    });
  };
  handleOnSearchChange = (value) => {
    this.setState({ type: value, currentPage: 1 }, () => {
      this.handleFilter();
    });
  };

  handleOnStatusSearchChange = (value) => {
    this.setState({ status: value, currentPage: 1 }, () => {
      this.handleFilter();
    });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.handleTable();
    });
  };
  handlePageChange1 = (page) => {
    this.setState({ currentPage1: page }, () => {
      this.handleGroup();
    });
  };
  showAccept = (emp_data) => {
    if (emp_data.type === "wfh") {
      this.setState({
        isDisable: true,
      });
    } else {
      this.setState({
        isDisable: false,
      });
    }
    this.setModal1Visible(true);
    this.setState({
      showAccept: true,
      leave_id: emp_data.leave_id,
      efrom: moment(emp_data.date.from),
      eto: moment(emp_data.date.to),
      isChecked: emp_data?.lop?.is_true ?? false,
      countLOP: emp_data?.lop?.no_of_days ?? 0,
      total_days: emp_data?.total_days,
    });
  };

  EditLeave = (emp_data) => {
    this.setModal1Visible(true);

    if (emp_data.type === "wfh") {
      this.setState({ isDisable: true });
    } else {
      this.setState({ isDisable: false });
    }

    this.setState({
      leave_id: emp_data.leave_id,
      type_leaves: emp_data.type,
      reason: emp_data.reason,
      efrom: emp_data.date.from ? moment(emp_data.date.from) : "",
      eto: emp_data.date.to ? moment(emp_data.date.to) : "",
      casualTypes:
        emp_data.sub_type === "first_half" ||
        emp_data.sub_type === "second_half"
          ? "half"
          : emp_data.sub_type === "full"
          ? "full"
          : "",
      halfType:
        emp_data.sub_type === "first_half" ||
        emp_data.sub_type === "second_half"
          ? emp_data.sub_type
          : "",
      isChecked: emp_data?.lop?.is_true ?? false,
      countLOP: emp_data?.lop?.no_of_days ?? 0,
      total_days: emp_data?.total_days,
    });
  };

  searchOption = (value) => {
    this.setState({
      searchOption: value,
    });
  };
  searchInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  searchEmployee = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  acceptLeave = (id) => {
    const { efrom, eto, isChecked, countLOP, total_days } = this.state;
    // let fromto = eto.diff(efrom, "days") + 1;
    let countlop = parseInt(countLOP);
    let data;

    if (isChecked) {
      if (isChecked === true && countLOP === "") {
        showError("Please add no of Lop days");
      } else {
        data = {
          lop: {
            is_true: isChecked,
            no_of_days: countlop,
          },
        };

        this.Accept(id, data);
      }
    } else {
      data = {};

      this.Accept(id, data);
    }
  };

  Accept = (id, data) => {
    APIGATEWAY.patch("employees/leaves/accept/" + id, data, (response) => {
      if (response.success) {
        this.setModal1Visible(false);
        this.setState({ showAccept: false });
        message.success(response.message);
        this.handleTable();
      } else {
        message.error(response.message);
      }
    });
  };

  RejectLeave = (id) => {
    this.setModal3Visible(true);
    this.setState({
      leave_id: id,
    });
  };
  submitLeave = (id) => {
    const { textarea } = this.state;
    if (textarea === "") {
      showError("Please write the reason");
    } else {
      let data = {
        reason: textarea,
      };
      APIGATEWAY.patch("employees/leaves/reject/" + id, data, (response) => {
        if (response.success) {
          message.success(response.message);
          this.handleTable();
          this.setState({ textarea: "" });
          this.setModal3Visible(false);
        } else {
          message.error(response.message);
        }
      });
    }
  };
  handleCancel = () => {
    this.setModal3Visible(false);
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };

  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };
  handleFromChange = (date, dateString) => {
    this.setState({ from: date });
  };

  handleToChange = (date, dateString) => {
    this.setState({ to: date });
  };
  onDateRangeChange = (dateRange) => {
    if (dateRange) {
      this.setState({
        dateRange: this.returnMomentDateRange(dateRange[0], dateRange[1]),
        from: moment(dateRange[0]),
        to: moment(dateRange[1]),
      });
    } else {
      this.setState({
        dateRange: null,
      });
    }
  };

  returnMomentDateRange = (start, finish) => {
    return [moment(start, "YYYY-MM-DD"), moment(finish, "YYYY-MM-DD")];
  };
  halfLeaveTypes = (value) => {
    this.setState({ halfType: value.target.value });
  };
  leaveTypes = (value) => {
    const { isChecked, countLOP } = this.state;
    this.setState({ type_leaves: value });
    if (value === "wfh") {
      this.setState({ isDisable: true });
    } else {
      this.setState({ isDisable: false });
    }
    if (value === "leave") {
      this.setState({ casualTypes: "full" });
    }
  };

  casualTypeChange = (value) => {
    this.setState({ casualTypes: value, halfType: "" });
  };
  handleFromDateChange = (value) => {
    this.setState({
      efrom: value,
      eto:
        !this.state.eto ||
        showDates(this.state.eto) < showDates(value) ||
        this.state.casualTypes === "half"
          ? value
          : this.state.eto,
    });
  };

  handleToDateChange = (value) => {
    this.setState({
      eto: value,
      efrom:
        !this.state.efrom || showDates(this.state.efrom) > showDates(value)
          ? value
          : this.state.efrom,
    });
  };

  handleActions = (e, object) => {
    if (e === "edit") {
      this.setState({
        create_leave: false,
        employee_id: object?.emp_id ?? "",
      });
      this.EditLeave(object);
    }
    if (e === "add") {
      this.setState({
        create_leave: true,
      });
      this.EditLeave("");
    }
    if (e === "reject") {
      this.RejectLeave(object.leave_id);
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  updateLeave = (id) => {
    const {
      reason,
      efrom,
      eto,
      type_leaves,
      casualTypes,
      halfType,
      isChecked,
      countLOP,
      total_days,
      employee_id,
      create_leave,
      employee_name,
    } = this.state;
    // let fromto = eto.diff(efrom, "days") + 1;
    let countlop = parseInt(countLOP);

    let data;
    if (create_leave && isErrorTxt(employee_name)) {
      showError("Please enter employee name");
    } else if (isErrorTxt(type_leaves)) {
      showError("Please select the leave options");
    } else if (
      type_leaves !== "leave" &&
      casualTypes !== "half" &&
      isErrorTxt(eto)
    ) {
      showError("Please Enter the To date");
    } else if (
      type_leaves === "leave" &&
      casualTypes === "half" &&
      isErrorTxt(halfType)
    ) {
      showError("Please select first or second half");
    } else if (isErrorTxt(efrom)) {
      showError(
        `${
          type_leaves === "leave" && casualTypes === "half"
            ? "Please select date"
            : "Please select from date"
        }`
      );
    } else if (efrom > eto) {
      showError("To date must be greater than or equal to from date");
    } else if (isErrorTxt(reason)) {
      showError("Please Enter the reason");
    } else if (isChecked === true && isErrorTxt(countLOP)) {
      showError("Please add no of Lop days");
    } else {
      // if (isChecked === false) {
      //   let data = {
      //     emp_id: employee_id,
      //     reason: reason,
      //     date: {
      //       from: showDates(efrom),
      //       to:
      //         type_leaves === "leave" && casualTypes === "half"
      //           ? showDates(efrom)
      //           : showDates(eto),
      //     },
      //     type: type_leaves,
      //     sub_type:
      //       type_leaves === "leave"
      //         ? casualTypes === "half"
      //           ? halfType
      //           : casualTypes
      //         : "full",
      //   };

      //   this.state.create_leave ? this.AddLeave(data) : this.Edit(id, data);
      // } else {
      data = {
        emp_id: employee_id,
        reason: reason,
        date: {
          from: showDates(efrom),
          to:
            type_leaves === "leave" && casualTypes === "half"
              ? showDates(efrom)
              : showDates(eto),
        },
        type: type_leaves,
        sub_type:
          type_leaves === "leave"
            ? casualTypes === "half"
              ? halfType
              : casualTypes
            : "full",
        lop: {
          is_true: isChecked,
          no_of_days: countlop,
        },
      };
      // !isChecked && delete data["lop"];
      this.state.create_leave ? this.AddLeave(data) : this.Edit(id, data);
      // }
    }
  };

  AddLeave = (data) => {
    APIGATEWAY.post("employees/leaves", data, (response) => {
      if (response.success) {
        message.success(response.message);
        this.setModal1Visible(false);
        this.handleTable();
        this.setState({
          efrom: moment(),
          eto: moment(),
          reason: "",
          type_leaves: "",
          sub_type: "",
          employee_id: "",
          showemployee: false,
          employee_list: [],
          create_leave: false,
          employee_name: "",
          currentPage: 1,
        });
      } else {
        this.setModal1Visible(true);
        message.error(response.message);
      }
    });
  };

  Edit = (id, data) => {
    APIGATEWAY.patch("employees/leaves/" + id, data, (response) => {
      if (response.success) {
        message.success(response.message);
        this.setModal1Visible(false);
        this.handleTable();
        this.setState({
          efrom: null,
          eto: null,
          reason: "",
          type_leaves: "",
          sub_type: "",
          employee_name: "",
          employee_list: [],
        });
      } else {
        this.setModal1Visible(true);
        message.error(response.message);
      }
    });
  };

  cancelEdit = () => {
    let self = this;

    self.setState({
      efrom: moment(),
      eto: moment(),
      reason: "",
      type_leaves: "",
      isChecked: false,
      countLOP: 0,
      create_leave: false,
      employee_id: "",
      employee_name: "",
      employee_list: [],
    });
    self.setModal1Visible(false);
  };

  cancelAccept = () => {
    this.setModal1Visible(false);
    this.setState(
      {
        efrom: null,
        eto: null,
        // reason: "",
        // type_leaves: "",
        showAccept: false,
        isChecked: false,
        countLOP: 0,
      },
      () => {
        this.handleTable();
      }
    );
  };
  onChange = (e) => {
    if (e === "1") {
      this.setQueryStatus();
    } else if (e === "2") {
      this.setQueryStatus2();
    }

    // this.handleFilter2()
  };

  onlyNumberKey = (e) => {
    // Only ASCII character in that range allowed
    var ASCIICode = e.which ? e.which : e.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
  };

  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  pageLimitOnchange1 = (e) => {
    this.setState(
      {
        pageSize1: e,
        currentPage1: 1,
      },
      () => {
        this.handleFilter2();
      }
    );
  };
  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      list_index: index,
    });
  };
  leaveList = (e) => {
    this.setState(
      {
        tab: e,
      },
      () => {
        if (e === "Daily") {
          this.clearSearch();
        } else if (e === "Monthly" || e === "Yearly") {
          this.clearEmp();
        }
      }
    );
    // if (e === "leave") {
    //   this.setState(
    //     {
    //       leave_list: true,
    //       monthwise_list: false,
    //       // queryStaus: { page: 1, limit: 20 },
    //     },
    //     () => {
    //       this.clearSearch();
    //     }
    //   );
    // } else if (e === "month") {
    //   this.setState(
    //     {
    //       leave_list: false,
    //       monthwise_list: true,
    //       // queryStatus2: { page: 1, limit: 20 },
    //     },
    //     () => {
    //       this.clearEmp();
    //     }
    //   );
    // }
  };

  employeeMonthlyLIst = (employee, item) => {
    this.clearEmp({
      searchEmp: employee.official_emp_id,
      leave_list: false,
      monthwise_list: true,
      tab: item,
      queryStatus2: {
        page: 1,
        limit: 20,
        empId: employee.official_emp_id,
        type: item === "Monthly" ? "month" : item === "Yearly" ? "year" : "",
      },
    });
  };

  createLeave = () => {
    this.setState({
      add_leave: {
        ...this.state.add_leave,
        leaveModal: true,
      },
    });
  };
  closeLeaveModal = () => {
    this.setState({
      add_leave: {
        ...this.state.add_leave,
        leaveModal: false,
      },
    });
  };

  handleInputChange = (e, item) => {
    let value = e.target.value;
    this.setState({
      employee_name: value,
    });

    clearTimeout(this.state.timer);

    const delayDebounceFn = setTimeout(() => {
      this.callSearchService();
    }, 500);

    this.setState({
      timer: delayDebounceFn,
    });
  };

  callSearchService = () => {
    APIGATEWAY.get(
      `employees/check?filter=${this.state.employee_name}`,
      (res) => {
        if (res.success) {
          this.setState({
            employee_list: res?.data?.employees ?? [],
            // showemployee: res?.data?.employees.length > 0 ? true : false,
            showemployee: true,
          });
        }
      }
    );
  };

  getemployeeid = (emp) => {
    this.setState({
      employee_id: emp.emp_id,
      employee_name: emp.name,
      showemployee: false,
      employee_list: [],
    });
  };
  render() {
    const menu = (object) => {
      return (
        <Menu
          onClick={(e) => this.handleActions(e, object)}
          style={{ width: "100px" }}
        >
          {UPDATE(app_type, app_name, <Menu.Item key="edit">Edit</Menu.Item>)}
          {APPROVAL(
            app_type,
            app_name,
            <Menu.Item key="reject">Reject</Menu.Item>
          )}
        </Menu>
      );
    };

    const {
      currentPage,
      currentPage1,
      total,
      total1,
      data,

      efrom,
      eto,

      type_leaves,
      casualTypes,
      halfType,
      reason,
      groupLeave,
      app_name,
      app_type,
      isChecked,
      countLOP,
      pageSize,
      pageSize1,
      update,
      approval,
      leave_list,
      monthwise_list,
      tab,
    } = this.state;

    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }
    let countnext1 = total1;
    let countprev1 = total1 - (total1 - 1);
    if (currentPage1 > 1) {
      countnext1 = (currentPage1 - 1) * pageSize1 + total1;
      countprev1 = countnext1 - (total1 - 1);
    }

    let tablist = [
      {
        title: "Daily",
      },
      {
        title: "Monthly",
      },
      {
        title: "Yearly",
      },
    ];

    return (
      <div>
        {/* {this.state.loading ? (
          <div className="spinning-star">
            <Spin size="large" />
          </div>
        ) : ( */}
        <div className="Tab-wrap">
          <TopHeader
            title="Leaves"
            name="leave"
            type={app_type}
            permission={app_name}
            createLeave={() => this.handleActions("add", "")}
          />

          <div className="flex-content leave-page">
            {tablist &&
              tablist.map((item, i) => (
                <p
                  className={item.title === tab ? "leave-col" : "month-col"}
                  onClick={() => this.leaveList(item.title)}
                  key={i}
                >
                  {item.title}
                </p>
              ))}
            {/* <p
              className={leave_list ? "leave-col" : "month-col"}
              onClick={() => this.leaveList("leave")}
            >
              Daily
            </p>
            <p
              className={monthwise_list ? "leave-col" : "month-col"}
              onClick={() => this.leaveList("month")}
            >
              Monthly
            </p>

            <p
              className={monthwise_list ? "leave-col" : "month-col"}
              onClick={() => this.leaveList("year")}
            >
              Yearly
            </p> */}
          </div>
          {tab === "Daily" && (
            <div style={{ padding: "0px 23px 23px 30px" }}>
              <Filter
                typeData={this.state.typeData}
                typeSelect={this.state.type}
                typeClick={this.handleOnSearchChange}
                searchData={this.state.searchData}
                statusData={this.state.statusData}
                optionChange={(e) => this.searchOption(e)}
                searchOption={this.state.searchOption}
                typeName="type"
                searchname="searchOption"
                statusName="status"
                statusSelect={this.state.status}
                statusOnChange={this.handleOnStatusSearchChange}
                clear={() => this.clearSearch()}
                apply={() => this.Apply()}
                searchInputName="searchInput"
                searchInput={this.state.searchInput}
                inputChange={(e) => this.searchInput(e)}
                handleOnFromChange={this.handleFromChange}
                handleOnToChange={this.handleToChange}
                fromDate={this.state.from}
                toDate={this.state.to}
                fromName="from"
                toName="to"
                dateFormat="YYYY-DD-MM"
              />

              {this.state.Leaveloading ? (
                <div className="tableview">
                  <Spin />
                </div>
              ) : this.state.Tableloading ? (
                <div className="tableview">
                  <Spin />
                </div>
              ) : (
                <div className="table_page_wrap">
                  <table className="tables">
                    <thead>
                      <tr>
                        <th style={{ "min-width": "48px" }}>S.No</th>
                        <th style={{ "min-width": "96px" }}>Employee Id</th>
                        <th style={{ "min-width": "116px" }}>Name</th>
                        <th style={{ "min-width": "116px" }}>Requested on</th>
                        <th style={{ "min-width": "116px" }}>From</th>
                        <th style={{ "min-width": "116px" }}>To</th>
                        <th style={{ "min-width": "86px" }}>Leave days</th>
                        <th style={{ "min-width": "148px" }}>Type</th>
                        <th style={{ "min-width": "116px" }}>Reason</th>
                        <th style={{ "min-width": "116px" }}>Status</th>
                        <th style={{ "min-width": "116px" }}></th>
                      </tr>
                    </thead>

                    {data.length > 0 ? (
                      <tbody ref={this.wrapperRef}>
                        {data.map((item, r) => (
                          <tr key={r}>
                            <td className="">
                              {" "}
                              {(this.state.currentPage - 1) *
                                this.state.pageSize +
                                (r + 1)}
                            </td>
                            <td className="table-name">
                              {isErrorTxt(item?.official_emp_id ?? "")
                                ? "-"
                                : item?.official_emp_id}
                            </td>
                            <td className="table-name text-capitalize">
                              {isErrorTxt(item?.name ?? "") ? "-" : item?.name}
                            </td>

                            <td className="table-name ">
                              {showLongDateFormat(item.createdAt)}
                            </td>
                            <td className="table-name ">
                              {showLongDateFormat(item.date.from)}
                            </td>
                            <td className="table-name ">
                              {showLongDateFormat(item.date.to)}
                            </td>
                            <td className="table-name text-capitalize">
                              <Tooltip
                                placement="topRight"
                                title={
                                  item.sub_type === "second_half" ||
                                  item.sub_type === "first_half"
                                    ? removeUnderscores(item.sub_type)
                                    : ""
                                }
                              >
                                {totalLeaveDayCalculater(item)}
                              </Tooltip>
                            </td>

                            <td className="table-name text-capitalize">
                              {isErrorTxt(item.type)
                                ? "-"
                                : leaveType(item.type)}
                            </td>

                            <td className="table-name text-capitalize">
                              <div
                                style={{ textTransform: "capitalize" }}
                                className={`${
                                  item.reason.length >= 10 && "cursor-pointer"
                                }`}
                              >
                                {item.reason.length >= 10 ? (
                                  <Popover
                                    overlayStyle={{
                                      maxWidth: "20vw",
                                    }}
                                    content={item.reason}
                                    // trigger="click"
                                  >
                                    {item.reason.slice(0, 13) + "....."}
                                  </Popover>
                                ) : (
                                  item.reason
                                )}
                              </div>
                            </td>
                            <td className="table-name text-capitalize font-weight-content">
                              <div>
                                {item.status === "accepted" && (
                                  <div className="status-accept">
                                    {item.status}
                                  </div>
                                )}
                                {item.status === "requested" && (
                                  <div className="status-request">
                                    {item.status}
                                  </div>
                                )}
                                {item.status === "cancelled" && (
                                  <div className="status-cancel">
                                    {item.status}
                                  </div>
                                )}

                                {item.status === "rejected" && (
                                  <div className="status-reject">
                                    {item.status}
                                    <Tooltip
                                      placement="rightTop"
                                      title={item.reject_reason}
                                    >
                                      <span>
                                        <i
                                          className="fa fa-info-circle"
                                          style={{
                                            fontSize: 20,
                                            color: "gray",
                                            marginLeft: 10,
                                          }}
                                        ></i>
                                      </span>
                                    </Tooltip>
                                  </div>
                                )}

                                {item.status === "recalled" && (
                                  <div className="status-recall">
                                    {item.status}
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className="table-name">
                              <div className="flex-content">
                                <span>
                                  {item.status === "requested" ||
                                  item.status === "recalled" ? (
                                    <>
                                      {APPROVAL(
                                        app_type,
                                        app_name,
                                        <Button
                                          className="btn_style"
                                          onClick={() => this.showAccept(item)}
                                        >
                                          Accept
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>

                                {app_type &&
                                app_type === "sub_admin" &&
                                approval === false &&
                                update === false ? (
                                  ""
                                ) : item.status === "rejected" ? (
                                  ""
                                ) : (
                                  <span
                                    className="ml-5"
                                    onClick={() => this.dropdownClick(r)}
                                  >
                                    <div className="dropdown-head">
                                      <div className="dropbtn-head">
                                        <img
                                          src={layer}
                                          alt="profile"
                                          className="menu-bar-icons mr-40"
                                        />
                                      </div>
                                      {this.state.list_index === r &&
                                        this.state.dropdown_menu && (
                                          <div className="dropdown-content-heads">
                                            <div>
                                              {item.status === "recalled"
                                                ? ""
                                                : UPDATE(
                                                    app_type,
                                                    app_name,
                                                    <p
                                                      className="action-menu-bar"
                                                      onClick={() =>
                                                        this.handleActions(
                                                          "edit",
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Edit
                                                    </p>
                                                  )}

                                              {APPROVAL(
                                                app_type,
                                                app_name,
                                                <p
                                                  className="action-menu-bar"
                                                  onClick={() =>
                                                    this.handleActions(
                                                      "reject",
                                                      item
                                                    )
                                                  }
                                                >
                                                  Reject
                                                </p>
                                              )}

                                              <p
                                                className="action-menu-bar"
                                                onClick={() =>
                                                  this.employeeMonthlyLIst(
                                                    item,
                                                    "Monthly"
                                                  )
                                                }
                                              >
                                                Monthly
                                              </p>

                                              <p
                                                className="action-menu-bar"
                                                onClick={() =>
                                                  this.employeeMonthlyLIst(
                                                    item,
                                                    "Yearly"
                                                  )
                                                }
                                              >
                                                Yearly
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </span>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody className="empty-message">
                        <tr>
                          <td colspan="9">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>

                  {data.length > 0 && (
                    <TableField
                      data={data}
                      countprev={countprev}
                      countnext={countnext}
                      currentPage={currentPage}
                      ClickPrev={() => this.previous()}
                      ClickNext={() => this.next(this.state.currentPage)}
                      total={total}
                      overAll={this.state.total_leave ?? 0}
                      pagelimit={this.state.pageSize}
                      pagelimitHandle={this.pageLimitOnchange}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          {(tab === "Monthly" || tab === "Yearly") && (
            <div style={{ padding: "0px 23px 23px 30px" }}>
              <Filter
                inputFieldname="searchEmp"
                inputFieldvalue={this.state.searchEmp}
                inputOnchange={(e) => this.searchEmployee(e)}
                clear={() => this.clearEmp()}
                apply={() => this.ApplyEmp()}
                handleOnFromChange={
                  tab === "Yearly" ? "" : this.handleFromChange
                }
                handleOnToChange={this.handleToChange}
                fromDate={this.state.from}
                toDate={this.state.to}
                fromName="from"
                toName="to"
              />

              {this.state.Monthloading ? (
                <div className="tableview">
                  <Spin />
                </div>
              ) : (
                <div className="table-main">
                  {this.state.Tableloading ? (
                    <div className="tableview">
                      <Spin />
                    </div>
                  ) : (
                    <div className="table_page_wrap">
                      {groupLeave.length > 0 ? (
                        <table className="table-response">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Employee Id</th>
                              <th>Name</th>
                              {tab === "Yearly" ? "" : <th>Month</th>}
                              <th>Sick</th>
                              <th>Casual</th>
                              <th>WFH</th>
                              <th>Comp-Off</th>
                              <th>Vacation</th>
                              <th>Lop</th>
                              <th>Total</th>
                            </tr>
                          </thead>

                          {groupLeave.length > 0 ? (
                            <tbody>
                              {groupLeave.map((item, r) => (
                                <tr key={r}>
                                  <td className="">
                                    {" "}
                                    {(this.state.currentPage1 - 1) *
                                      this.state.pageSize1 +
                                      (r + 1)}
                                  </td>
                                  <td className="table-name ">
                                    {isErrorTxt(item?.empId)
                                      ? "-"
                                      : item?.empId}
                                  </td>
                                  <td className="table-name text-capitalize">
                                    {isErrorTxt(item.name) ? "-" : item.name}
                                  </td>
                                  {tab === "Yearly" ? (
                                    ""
                                  ) : (
                                    <td className="table-name ">
                                      {isErrorTxt(item.month)
                                        ? "-"
                                        : item.month}
                                    </td>
                                  )}

                                  <td className="table-name ">
                                    {isErrorTxt(item.sick) ? "-" : item.sick}
                                  </td>

                                  <td className="table-name ">
                                    {isErrorTxt(item.leave) ? "-" : item.leave}
                                  </td>
                                  <td className="table-name ">
                                    {isErrorTxt(item.wfh) ? "-" : item.wfh}
                                  </td>
                                  <td className="table-name ">
                                    {isErrorTxt(item.comp) ? "-" : item.comp}
                                  </td>
                                  <td className="table-name ">
                                    {isErrorTxt(item.vacation)
                                      ? "-"
                                      : item.vacation}
                                  </td>

                                  <td className="table-name ">
                                    {" "}
                                    <div className={item.lop && "item_lop"}>
                                      {item.lop !== 0 ? item.lop : "-"}
                                    </div>
                                  </td>

                                  <td className="table-name ">
                                    {item.sick + item.leave + item.vacation}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            ""
                          )}
                        </table>
                      ) : (
                        <table className="table-response">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Employee Id</th>
                              <th>Name</th>
                              <th>Month</th>
                              <th>Sick</th>
                              <th>Casual</th>
                              <th>WFH</th>
                              <th>Comp-Off</th>
                              <th>Vacation</th>
                              <th>Lop</th>
                              <th>Total</th>
                            </tr>
                          </thead>

                          <tbody className="empty-message">
                            <tr>
                              <td colspan="11">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                      {groupLeave.length > 0 && (
                        <TableField
                          data={groupLeave}
                          countprev={countprev1}
                          countnext={countnext1}
                          currentPage={currentPage1}
                          ClickPrev={() => this.previous1()}
                          ClickNext={() => this.next1(this.state.currentPage1)}
                          total={total1}
                          overAll={this.state.total_month ?? 0}
                          pagelimit={this.state.pageSize1}
                          pagelimitHandle={(e) => this.pageLimitOnchange1(e)}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <Modal
          style={{ top: 50 }}
          open={this.state.modal3Visible}
          footer={[
            <div className="foo_btn">
              <button
                className="dec-btn-secondary"
                onClick={() => this.setModal3Visible(false)}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </button>
              <button
                className="probtn"
                onClick={(e) => this.submitLeave(this.state.leave_id)}
              >
                Submit
              </button>
            </div>,
          ]}
          onCancel={() => this.setModal3Visible(false)}
          title="Reject Leave"
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <div>
              <div>
                <TextArea
                  name="textarea"
                  value={this.state.textarea}
                  placeholder="Enter the reason"
                  onChange={(e) => this.handleText(e)}
                  rows={4}
                />
              </div>
            </div>
          </Form>
        </Modal>

        <Modal
          style={{ top: 50 }}
          open={this.state.modal1Visible}
          title={
            this.state.showAccept
              ? "Confirm Accept"
              : this.state.create_leave
              ? "Add Leave"
              : "Leave Update"
          }
          footer={[
            <div className="foo_btn">
              <button
                className="dec-btn-secondary"
                onClick={
                  this.state.showAccept
                    ? () => this.cancelAccept()
                    : () => this.cancelEdit()
                }
                style={{ marginRight: "10px" }}
              >
                Cancel
              </button>
              <button
                className="probtn"
                onClick={
                  this.state.showAccept
                    ? () => this.acceptLeave(this.state.leave_id)
                    : this.state.create_leave
                    ? () => this.updateLeave()
                    : () => this.updateLeave(this.state.leave_id)
                }
              >
                {this.state.showAccept
                  ? "Accept"
                  : this.state.create_leave
                  ? "Add"
                  : "Update"}
              </button>
            </div>,
          ]}
          closable={false}
        >
          {this.state.showAccept ? (
            <div>
              <div className="">
                <span>
                  <Checkbox
                    className="checkbox"
                    checked={isChecked}
                    disabled={this.state.isDisable ? true : false}
                    onChange={this.onChangeCheckbox}
                  />
                </span>
                <span style={{ marginLeft: "4px" }}> Loss Of Pay</span>
              </div>

              <div style={{ marginTop: "15px" }} className="lopcount">
                <div className="lop_title"> No of LOP days</div>
                <input
                  type="text"
                  value={countLOP}
                  maxlength="2"
                  onChange={this.countLOP}
                  disabled={isChecked ? false : true}
                />
              </div>
            </div>
          ) : (
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              destroyOnClose={true}
            >
              <div
                onClick={() => {
                  this.state.showemployee === true &&
                    this.setState({
                      showemployee: false,
                    });
                }}
              >
                {this.state.create_leave && (
                  <div className="mb-10">
                    <div className="mb-5e">
                      <Typography> Employee</Typography>
                    </div>
                    <input
                      type="text"
                      placeholder="Search employee"
                      autoComplete="off"
                      value={this.state.employee_name}
                      onChange={(e) => this.handleInputChange(e, "employee")}
                      className="leave-input-field"
                    />

                    {this.state.showemployee && (
                      <div className="show-employee-list">
                        {this.state.employee_list.length > 0 ? (
                          this.state.employee_list.map((emp, p) => (
                            <p key={p} onClick={() => this.getemployeeid(emp)}>
                              <span className="emp-name">
                                {emp.official_emp_id}
                              </span>
                              /<span className="emp-id">{emp.name}</span>
                            </p>
                          ))
                        ) : (
                          <p>No data found</p>
                        )}
                      </div>
                    )}
                  </div>
                )}

                <Select
                  className="blood"
                  name="type_leaves"
                  onChange={this.leaveTypes}
                  placeholder="Select Leave type"
                  value={type_leaves || undefined}
                  style={{ width: "100%" }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Option value="wfh">Work From Home</Option>
                  <Option value="comp_off">Compensation</Option>
                  <Option value="sick">Sick</Option>
                  <Option value="leave">Casual Leave</Option>
                  <Option value="vacation">Vacational Leave</Option>
                </Select>

                {this.state.type_leaves === "leave" && (
                  <div className="casual_type" style={{ marginTop: "15px" }}>
                    {/* <p style={{ marginbottom: "0" }}>Select</p> */}
                    <Select
                      className="blood"
                      onChange={this.casualTypeChange}
                      placeholder="select casual type"
                      value={casualTypes || undefined}
                      style={{ width: "100%" }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      <Option value="full">Full Day</Option>
                      <Option value="half">Half Day</Option>
                    </Select>
                  </div>
                )}
                {casualTypes === "half" && type_leaves === "leave" && (
                  <Radio.Group
                    onChange={this.halfLeaveTypes}
                    style={{ marginBottom: "1.5rem" }}
                    value={halfType}
                  >
                    <Radio value="first_half">First Half</Radio>
                    <Radio value="second_half">Second Half</Radio>
                  </Radio.Group>
                )}

                <div className="pic" style={{ marginTop: "20px" }}>
                  <div
                    style={{ marginBottom: "20px" }}
                    className={
                      type_leaves === "leave" && casualTypes === "half"
                        ? "wid-100"
                        : "wid-50"
                    }
                  >
                    <DatePicker
                      value={efrom}
                      className="onboard_date"
                      style={{ height: "47px" }}
                      allowClear={false}
                      inputReadOnly
                      // style={{ width: "100%" }}
                      // inputReadOnly
                      // disabledDate={(current) =>
                      //   type_leaves === "vacation"
                      //     ? current < moment().add(1, "months") ||
                      //       current > moment().add(10, "months")
                      //     : current < moment().add(-1, "months") ||
                      //       current > moment().add(3, "months")
                      // }
                      destroyOnClose={true}
                      disabledDate={(current) =>
                        type_leaves === "vacation"
                          ? current < moment().add(1, "months") ||
                            current > moment().add(10, "months")
                          : current < moment().add(-11, "days") ||
                            current > moment().add(10, "days")
                      }
                      onChange={this.handleFromDateChange}
                      placeholder={casualTypes === "half" ? "Date" : "From"}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                  </div>

                  {type_leaves === "leave" && casualTypes === "half" ? (
                    ""
                  ) : (
                    <div style={{ width: "50%" }}>
                      <DatePicker
                        // inputReadOnly
                        // autoFocus={true}
                        allowClear={false}
                        value={eto || undefined}
                        className="onboard_date"
                        style={{ height: "47px" }}
                        inputReadOnly
                        // disabledDate={(current) =>
                        //   type_leaves === "vacation"
                        //     ? current < moment().add(1, "months") ||
                        //       current > moment().add(10, "months")
                        //     : current < moment().add(-1, "months") ||
                        //       current > moment().add(3, "months")
                        // }
                        disabledDate={(current) =>
                          type_leaves === "vacation"
                            ? current < moment().add(1, "months") ||
                              current > moment().add(10, "months")
                            : current < moment().add(-11, "days") ||
                              current > moment().add(10, "days")
                        }
                        onChange={this.handleToDateChange}
                        placeholder="To"
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    </div>
                  )}
                </div>

                <div>
                  <div>
                    <Typography> Reason</Typography>
                  </div>

                  <div style={{ width: "100%", marginTop: "10px" }}>
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      name="reason"
                      placeholder="Reason"
                      value={reason}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <div className="">
                    <span>
                      <Checkbox
                        className="checkbox"
                        checked={isChecked}
                        disabled={this.state.isDisable ? true : false}
                        onChange={this.onChangeCheckbox}
                      />
                    </span>
                    <span style={{ marginLeft: "4px" }}> Loss Of Pay</span>
                  </div>

                  <div style={{ marginTop: "15px" }} className="lopcount">
                    <div className="lop_title">No of LOP days</div>
                    <input
                      type="text"
                      value={countLOP}
                      maxlength="2"
                      onChange={this.countLOP}
                      disabled={isChecked ? false : true}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Modal>
      </div>
    );
  }
}
