import React, { useState, useEffect } from "react";
import moment from "moment";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { Line } from "react-chartjs-2";
import { dateLabels, monthLables } from "../../constants/Utils";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
let Inv_gradient, Exp_gradient;

const LineChart = (props) => {
  const [data, setdata] = useState({ datasets: [] });
 
  const [all, setAll] = useState("");
  const [month, setMonth] = useState("");


  useEffect(() => {
    monthHandlers(props.type);

    var ctx = document.getElementById("linechart").getContext("2d");
    Inv_gradient = ctx.createLinearGradient(0, 0, 0, 400);
    Inv_gradient.addColorStop(0.2, "rgba(51,200,99,.1)");
    Inv_gradient.addColorStop(1, "rgba(51,200,99,.3)");

    Exp_gradient = ctx.createLinearGradient(0, 0, 0, 400);
    Exp_gradient.addColorStop(0.2, "rgb(255, 10, 86,0.7)");
    Exp_gradient.addColorStop(1, "rgb(255, 10, 86,0.2)");
  }, [props.type]);

  const getAllYears = () => {
    let queryParams = "";

    APIGATEWAY.get("dashboard/graph", (response) => {
      if (response.success) {
        let data = response?.data;
        props.datas(data);
        setAll(data);


        setMonth("Years");
        let expenses_graph = data?.expensesGraph;
        let invoices_graph = data?.invoiceGraph;
        let invoices;
        let expenses;
        if (expenses_graph.length > 0) {
          expenses = expenses_graph.map((el) => {
            return {
              x: el.date,
              y: el.total_amount,
            };
          });
        } else {
          expenses = [];
        }
        if (invoices_graph.length > 0) {
          invoices = invoices_graph.map((el) => {
            return {
              x: el.date,
              y: el.total_amount,
            };
          });
        } else {
          invoices = [];
        }

        setdata({
          labels: dateLabels(expenses, invoices),
          datasets: [
            {
              label: " Expenses",
              data: expenses,
              backgroundColor: Exp_gradient,
              borderColor: "rgb(255, 10, 86,0.7)",
              pointBackgroundColor: "rgb(255, 10, 86,0.7)",
              // pointBorderWidth: 5,
              borderWidth: 3,
              pointBorderWidth: 3,
              pointHoverBorderWidth: 4,
              pointRadius: 5,
              tension: 0.3,
              fill: true,
            },
            {
              label: " Invoices",
              data: invoices,
              backgroundColor: Inv_gradient,
              borderColor: "rgba(51,200,99,.8)",
              pointBackgroundColor: "rgba(51,200,99,.7)",
              // pointBorderWidth: 5,
              borderWidth: 3,
              pointBorderWidth: 3,
              pointHoverBorderWidth: 4,
              pointRadius: 5,
              tension: 0.3,
              fill: true,
            },
          ],
        });
      }
    });
  };

  const thismonthGraph = () => {
    // let self = this;
    // const { from, to, currentPage } = self.state;

    // let queryParams = "?page=" + currentPage;
    // if (from !== "") queryParams += "&from=" + getIsoString(from);
    // if (to !== "") queryParams += "&to=" + getIsoString(to);
    // let queryParams = "?page=";
    let queryParams = "";

    APIGATEWAY.get("dashboard/graph?key=tm" + queryParams, (response) => {
      if (response.success) {
        let data = response?.data;
        props.datas(data);
        // setThismonth(data);
        let currentMon = moment().month();
        let CM = moment()._locale._months[currentMon];

        setMonth(`Month (${CM})`);
        let expenses_graph = data?.expensesGraph;
        let invoices_graph = data?.invoiceGraph;
        let invoices;
        let expenses;
        if (expenses_graph.length > 0) {
          expenses = expenses_graph.map((el) => {
            return {
              x: el.date,
              y: el.total_amount,
            };
          });
        } else {
          expenses = [];
        }
        if (invoices_graph.length > 0) {
          invoices = invoices_graph.map((el) => {
            return {
              x: el.date,
              y: el.total_amount,
            };
          });
        } else {
          invoices = [];
        }

        setdata({
          labels: dateLabels(expenses, invoices),
          datasets: [
            {
              label: " Expenses",
              data: expenses,
              backgroundColor: Exp_gradient,
              borderColor: "rgb(255, 10, 86,0.7)",
              pointBackgroundColor: "rgb(255, 10, 86,0.7)",
              // pointBorderWidth: 5,
              borderWidth: 3,
              pointBorderWidth: 3,
              pointHoverBorderWidth: 4,
              pointRadius: 5,
              tension: 0.3,
              fill: true,
            },
            {
              label: " Invoices",
              data: invoices,
              backgroundColor: Inv_gradient,
              borderColor: "rgba(51,200,99,.8)",
              pointBackgroundColor: "rgba(51,200,99,.7)",
              // pointBorderWidth: 5,
              borderWidth: 3,
              pointBorderWidth: 3,
              pointHoverBorderWidth: 4,
              pointRadius: 5,
              tension: 0.3,
              fill: true,
            },
          ],
        });
      }
    });
  };
  const previousMonth = () => {
    // let self = this;
    // const { from, to, currentPage } = self.state;
    // let queryParams = "?page=" + currentPage;
    // if (from !== "") queryParams += "&from=" + getIsoString(from);
    // if (to !== "") queryParams += "&to=" + getIsoString(to);

    let queryParams = "";

    APIGATEWAY.get("dashboard/graph?key=lm" + queryParams, (response) => {
      if (response.success) {
        let data = response?.data;
        props.datas(data);
        // setLastmonth(data);
        let expenses_graph = data?.expensesGraph;
        let invoices_graph = data?.invoiceGraph;

        let prev_month = moment().month() - 1;
        if (prev_month === -1) {
          prev_month = 11;
        }

        let PM = moment()._locale._months[prev_month];
        setMonth(`Month (${PM})`);
        let invoices;
        let expenses;
        if (expenses_graph.length > 0) {
          expenses = expenses_graph.map((el) => {
            return {
              x: el.date,
              y: el.total_amount,
            };
          });
        } else {
          expenses = [];
        }
        if (invoices_graph.length > 0) {
          invoices = invoices_graph.map((el) => {
            return {
              x: el.date,
              y: el.total_amount,
            };
          });
        } else {
          invoices = [];
        }

        setdata({
          labels: dateLabels(expenses, invoices),
          datasets: [
            {
              label: " Expenses",
              data: expenses,
              backgroundColor: Exp_gradient,
              borderColor: "rgb(255, 10, 86,0.7)",
              pointBackgroundColor: "rgb(255, 10, 86,0.7)",
              // pointBorderWidth: 4,
              borderWidth: 3,
              pointBorderWidth: 3,
              pointHoverBorderWidth: 4,
              pointRadius: 5,
              tension: 0.3,
              fill: true,
            },
            {
              label: " Invoices",
              data: invoices,
              backgroundColor: Inv_gradient,
              borderColor: "rgba(51,200,99,.8)",
              pointBackgroundColor: "rgba(51,200,99,.7)",
              borderWidth: 3,
              pointBorderWidth: 3,
              pointHoverBorderWidth: 4,
              pointRadius: 5,
              tension: 0.3,
              fill: true,
            },
          ],
        });
      }
    });
  };

  const getYear = () => {
    // let self = this;
    // const { from, to, currentPage } = self.state;
    // let queryParams = "?page=" + currentPage;
    // if (from !== "") queryParams += "&from=" + getIsoString(from);
    // if (to !== "") queryParams += "&to=" + getIsoString(to);
    let queryParams = "";

    APIGATEWAY.get("dashboard/graph?key=yr" + queryParams, (response) => {
      if (response.success) {
        let data = response?.data;
        props.datas(data);
        let expenses_graph = data?.expensesGraph;
        let invoices_graph = data?.invoiceGraph;
        const month = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let invoices;
        let expenses;
        setMonth(`Year - ${moment().year()}`);
        if (expenses_graph.length > 0) {
          expenses = expenses_graph.map((el) => {
            let each_month = el.date.split("-");
            let new_month = month[each_month[1] - 1];
            return {
              x: new_month,
              y: el.total_amount,
            };
          });
        } else {
          expenses = [];
        }
        if (invoices_graph.length > 0) {
          invoices = invoices_graph.map((el) => {
            let each_month = el.date.split("-");
            let new_month = month[each_month[1] - 1];
            return {
              x: new_month,
              y: el.total_amount,
            };
          });
        } else {
          invoices = [];
        }

        setdata({
          labels: monthLables(expenses, invoices),
          datasets: [
            {
              label: " Expenses",
              // data: [12, 19, 3, 5, 2, 3],
              data: expenses,
              borderWidth: 3,
              backgroundColor: Exp_gradient,
              borderColor: "rgb(255, 10, 86,0.7)",
              pointBackgroundColor: "rgb(255, 10, 86,0.7)",
              // pointBorderColor: "rgba(51,200,99,.7)",
              pointBorderWidth: 3,
              pointHoverBorderWidth: 4,
              pointRadius: 5,
              tension: 0.3,
              fill: true,
            },
            {
              label: " Invoices",
              data: invoices,
              backgroundColor: Inv_gradient,
              borderColor: "rgba(51,200,99,.8)",
              pointBackgroundColor: "rgba(51,200,99,.7)",
              // pointBorderColor: "rgba(237,94,94,0.2)",
              borderWidth: 3,
              pointBorderWidth: 3,
              pointHoverBorderWidth: 4,
              pointRadius: 5,
              tension: 0.3,
              fill: true,
            },
          ],
        });
      }
    });
  };

  const options = {
    maintainAspectRatio: false,
    interaction: {
      // mode: "index",
      // intersect: false,
      // axis: "x",
    },
    plugins: {
      tooltip: { enabled: true },
      // legend: false,
      legend: {
        display: true,
        labels: {
          font: {
            family: "Montserrat",
            size: 16,
            weight: 600,
            lineHeight: 1.2,
          },
          color: "#214757",
          // height: 100,
        },
      },
    },

    scales: {
      x: {
        beginAtZero: true,
        // min: 0,
        // max: 10,
        grid: {
          display: false,
          drawBorder: true,
          borderDash: [8],
          border: false,
        },
        title: {
          display: true,
          text: `${month}`,
          color: "#911",
          font: {
            family: "Montserrat",
            size: 20,
            weight: "bold",
            lineHeight: 1.2,
          },
          padding: { top: 10, left: 0, right: 0, bottom: 0 },
        },
        ticks: {
          font: {
            family: "Montserrat",
            size: "16px",
            weight: "bold",
            lineHeight: 1.2,
          },
          color: "#214757",
        },
      },
      y: {
        beginAtZero: true,

        // min: 0,
        // max: 40,
        ticks: {
          // stepSize: 5,
          font: {
            family: "Montserrat",
            size: "16px",
            weight: "bold",
            lineHeight: 1.2,
          },
          color: "#214757",

          callback: (value) => "₹" + value,
        },

        title: {
          display: true,
          text: "Amount",
          color: "#911",
          font: {
            family: "Montserrat",
            size: 20,
            weight: "bold",
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 10 },
        },
        grid: {
          borderDash: [10],
          display: false,
          drawOnChartArea: false,
          drawBorder: true,
        },
      },
    },
  };

  const monthHandlers = (value) => {
    if (value === "All") {
      getAllYears();
    } else if (value === "thisMonth") {
    
      thismonthGraph();
    } else if (value === "Previous") {
      
      previousMonth();
    } else if (value === "thisyear") {
      
      getYear();
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center">
          <p className="upcome-leave">Payment Overview</p>
        </div>
      </div>

      <div
        style={{
          marginTop: "20px",
        }}
        className="chart_div"
      >
        <Line data={data} options={options} id="linechart" />
      </div>
    </>
  );
};

export default LineChart;
