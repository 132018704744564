import React, { Component } from "react";
import { Input, Modal, Form, message, Spin, Select, Empty } from "antd";
import "antd/dist/antd.css";
import "../../stylesheets/styles.css";
import GBLVAR from "../../ApiService/GlobalVariables/globalVariables";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { STORAGE, UPDATE, DELETE } from "../../constants/roleStore";
import layer from "../../assets/Layer 2.svg";

import {
  showDate,
  numberWithComma,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  showError,
  isErrorTxt,
} from "../../constants/Utils";

import TopHeader from "../../components/ui/TopHeader";
import ModalPopUp from "../../components/ui/modalpop";

import Filter from "../../components/ui/filter";
import axios from "axios";
import cookie from "react-cookies";
import TableField from "../../components/ui/tableField";
import { ACCESSORIESACCESS } from "../../components/ui/constants";

const { Option } = Select;
export default class Expenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 20,
      currentPage: 1,
      data: [],
      searchOption: "",
      searchInput: "",
      status: "",
      modal1Visible: false,
      file: "",
      selectedFile: null,
      limit: 20,
      total: "",
      accessoryType: "",
      loading: true,
      Tableloading: false,
      totalDoc: "",
      app_name: "",
      app_type: "",
      delete_name: "",
      deleteAccessory: false,
      dropdown_menu: false,
      list_index: "",
      id: "",
      typeData: [
        { value: "laptop", key: "laptop" },
        { value: "TV", key: "tv" },
        { value: "Mobile", key: "mobile" },
      ],
      searchData: [{ value: "Item", key: "item" }],
      statusData: [
        { value: "Active", key: "active" },
        { value: "Inactive", key: "inactive" },
      ],
      queryStatus: {},

      update: false,
      deleted: false,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);

    let name = "";

    let storage = JSON.parse(STORAGE.view);
    name = storage?.filter((app) => app?.name === "Settings");

    let value = name;
    value?.filter((app) => {
      let names = app?.sub_roles?.filter((role) => role.name === "Accessories");
      names &&
        names.map((el) =>
          this.setState({
            update: el?.permissions?.update,
            deleted: el?.permissions?.delete,
          })
        );
      this.setState({
        app_name: names,
      });
    });

    this.setState({
      app_type: STORAGE.type,
    });
    this.setQueryStatus();
    this.reloadPage();
    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);
  }

  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ querystatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.clearSearch();
        }
      });
    });
  };
  onPopstate = () => {
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);

    this.state.searchOption = searchObj.select ?? "";
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.searchInput = searchObj.value ?? "";
    this.state.status = searchObj.status ?? "";
    this.state.accessoryType = searchObj.type ?? "";
    this.state.queryStatus = returnSearchObject(search);
    this.state.pageSize = parseInt(searchObj.limit) ?? "";

    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      this.handleTable();
    });
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);
  }
  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };
  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    if (this.state.searchOption !== "" && this.state.searchInput !== "") {
      querysearch.select = this.state.searchOption;
      querysearch.value = this.state.searchInput;

      querysearch[this.state.searchOption] = this.state.searchInput;
    }
    querysearch.status = this.state.status ?? "";
    querysearch.page = this.state.currentPage ?? "";
    querysearch.type = this.state.accessoryType ?? "";
    querysearch.limit = this.state.pageSize ?? "";

    return querysearch;
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          searchInput: queryObj.value ?? "",
          searchOption: queryObj.select ?? "",
          status: queryObj.status ?? "",
          accessoryType: queryObj.type ?? "",
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",
          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    }
  };

  handleFilter = () => {
    let query = this.getQuerySearch();

    this.setState(
      {
        queryStatus: query,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.handleTable();
      }
    );
  };
  handleTable = () => {
    APIGATEWAY.get(
      "accessories" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          let res = response.data;

          this.setState({
            data: res.accessories,
            total: res?.accessories?.length,
            loading: false,
            Tableloading: false,
            totalDoc: response.data?.total,
          });
        } else {
          this.setState({ loading: true });
        }
      }
    );
  };

  previous = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  next = (prev) => {
    const { currentPage } = this.state;

    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };
  handleDetailpage = (id) => {
    this.props.history.push("accessories/detail/" + id);
  };
  handleEditPage = (id) => {
    this.props.history.push("/accessories/add/" + id);
  };

  handleActions = (e, data) => {
    if (e === "edit") {
      this.props.history.push("/accessories/add/" + data.accessory_id);
    } else if (e === "delete") {
      this.setState({
        id: data.accessory_id,
        deleteAccessory: true,
        delete_name: data.item,
      });
    }
  };

  Apply = () => {
    const { status, accessoryType, searchInput, searchOption } = this.state;
    if (
      status === "" &&
      accessoryType === "" &&
      (searchInput === "" || searchOption === "")
    ) {
      showError("Please select any one field");
    } else {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handleFilter();
        }
      );
    }
  };
  clearSearch = () => {
    let self = this;
    self.setState(
      {
        searchInput: "",
        searchOption: "",
        status: "",
        accessoryType: "",
        currentPage: 1,
        queryStatus: { page: 1, limit: 20 },
        pageSize: 20,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);

        this.handleTable();
      }
    );
  };
  setModal1Visible = (modal1Visible) => {
    this.setState({ modal1Visible, file: "" });
  };
  searchInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  hanleStatus = (value) => {
    this.setState({ status: value, currentPage: 1 }, () => this.handleFilter());
  };
  searchOption = (value) => {
    this.setState({
      searchOption: value,
    });
  };

  handleDelete = (id, object) => {
    this.setState({ id: id, deleteAccessory: true, delete_name: object.item });
  };

  cancelDelete = () => {
    this.setState({ id: "", deleteAccessory: false, delete_name: "" });
  };

  submitDelete = () => {
    let self = this;
    let data = {};
    APIGATEWAY.delete("accessories/" + this.state.id, data, (response) => {
      if (response.success) {
        message.success(response.message);
        self.handleTable();
        this.setState({ id: "", deleteAccessory: false, delete_name: "" });
      }
    });
  };

  handleOnChange = (data) => {
    this.setState({
      file: data.target.files[0],
    });
  };
  typeSearch = (value) => {
    this.setState({ accessoryType: value, currentPage: 1 }, () => {
      this.handleFilter();
    });
  };

  submitHandle = () => {
    let self = this;
    const formData = new FormData();

    const { file } = self.state;

    formData.append("file", file);
    formData.append("test", "StringValueTest");

    axios
      .post(`${GBLVAR.BASE_URL}/accessories/import`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + cookie.load("token"),
        },
      })
      .then((result) => {
        message.success("Import successfully");
        self.handleTable();
        self.setState({
          modal1Visible: false,
          file: "",
        });
      });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.handleTable();
    });
  };

  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };
  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      list_index: index,
    });
  };
  render() {
    let self = this;
    const {
      data,
      currentPage,
      pageSize,
      total,
      accessoryType,
      app_name,
      app_type,
      list_index,
      dropdown_menu,
    } = self.state;
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }

    return (
      <div>
        <div className="">
          <TopHeader
            title="Accessories"
            imports={() => this.setModal1Visible(true)}
            to="/accessories/add"
            type={app_type}
            permission={app_name}
          />
          {this.state.loading ? (
            <div className="spinning-star">
              <Spin size="large" />
            </div>
          ) : (
            <div className="main-content">
              <div>
                <Filter
                  typeData={this.state.typeData}
                  typeSelect={accessoryType}
                  typeClick={this.typeSearch}
                  searchData={this.state.searchData}
                  statusData={this.state.statusData}
                  optionChange={(e) => this.searchOption(e)}
                  searchOption={this.state.searchOption}
                  typeName="accessoryType"
                  searchname="searchOption"
                  statusName="status"
                  statusSelect={this.state.status}
                  statusOnChange={(e) => this.hanleStatus(e)}
                  clear={() => this.clearSearch()}
                  apply={() => this.Apply()}
                  searchInputName="searchInput"
                  searchInput={this.state.searchInput}
                  inputChange={(e) => this.searchInput(e)}
                />
                {this.state.Tableloading ? (
                  <div className="tableview">
                    <Spin />
                  </div>
                ) : (
                  <div className="table_page_wrap">
                    <table className="table-response">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>ID</th>
                          <th>Item</th>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Date</th>
                          <th>Price</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      {data.length > 0 ? (
                        <tbody ref={this.wrapperRef}>
                          {data.map((item, i) => (
                            <tr key={i}>
                              <td className="">
                                {" "}
                                {(this.state.currentPage - 1) * 10 + (i + 1)}
                              </td>
                              <td>
                                {isErrorTxt(item.acc_id) ? "-" : item.acc_id}
                              </td>

                              <td className="text-capitalize ">
                                {" "}
                                <div
                                  onClick={() =>
                                    this.handleDetailpage(item.accessory_id)
                                  }
                                  style={{ cursor: "pointer" }}
                                  className="table-name"
                                >
                                  {item.item}
                                </div>
                              </td>
                              <td className="text-capitalize">
                                {" "}
                                <div>
                                  {item?.employee?.name !== ""
                                    ? item?.employee?.name
                                    : "-"}
                                </div>
                              </td>

                              <td className="text-capitalize">{item.type}</td>
                              <td className="">
                                {showDate(item.purchase_date)}
                              </td>
                              <td className="">
                                {numberWithComma(item.price ?? 0)}
                              </td>
                              <td className="text-capitalize font-weight-content">
                                <div>
                                  {item.status === "active" && (
                                    <div style={{ color: "green" }}>
                                      {item.status}
                                    </div>
                                  )}
                                  {item.status === "inactive" && (
                                    <div style={{ color: "red" }}>
                                      {item.status}
                                    </div>
                                  )}
                                </div>
                              </td>
                              {(ACCESSORIESACCESS.update ||
                                ACCESSORIESACCESS.delete) && (
                                <td
                                  className="table-action"
                                  onClick={() => this.dropdownClick(i)}
                                >
                                  <div className="tax-add-edit">
                                    <span className="ml-5">
                                      <div className="dropdown-head">
                                        <div className="dropbtn-head">
                                          <img
                                            src={layer}
                                            alt="profile"
                                            className="menu-bar-icon"
                                          />
                                        </div>
                                        {this.state.list_index === i &&
                                          this.state.dropdown_menu && (
                                            <div className="dropdown-content-heads">
                                              <div>
                                                {ACCESSORIESACCESS.update && (
                                                  <p
                                                    className="action-menu-bar"
                                                    onClick={() =>
                                                      this.handleActions(
                                                        "edit",
                                                        item
                                                      )
                                                    }
                                                  >
                                                    Edit
                                                  </p>
                                                )}

                                                {ACCESSORIESACCESS.delete && (
                                                  <p
                                                    className="action-menu-bar"
                                                    onClick={() =>
                                                      this.handleActions(
                                                        "delete",
                                                        item
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody className="empty-message">
                          <tr>
                            <td colspan="9">
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                    {data.length > 0 && (
                      <TableField
                        // column={columns}
                        data={data}
                        countprev={countprev}
                        countnext={countnext}
                        currentPage={currentPage}
                        ClickPrev={() => this.previous()}
                        ClickNext={() => this.next(this.state.currentPage)}
                        total={total}
                        overAll={this.state.totalDoc ?? 0}
                        pagelimit={this.state.pageSize}
                        pagelimitHandle={this.pageLimitOnchange}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          <Modal
            title="Import"
            style={{ top: 50 }}
            open={this.state.modal1Visible}
            onOk={() => this.setModal1Visible(false)}
            onCancel={() => this.setModal1Visible(false)}
            footer={[
              <div className="d-flex justify-content-between align-items-center">
                <button
                  key="cancel"
                  className="dec-btn-secondary"
                  onClick={() => this.setModal1Visible(false)}
                >
                  Cancel
                </button>

                <button
                  key="submit"
                  className="dec-btn"
                  onClick={() => this.submitHandle()}
                >
                  Submit
                </button>
              </div>,
            ]}
          >
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              className="admin_input"
            >
              <div>
                <Input
                  type="file"
                  id="csvFileInput"
                  accept=".csv"
                  name="file"
                  onChange={(e) => this.handleOnChange(e)}
                />
              </div>
            </Form>
          </Modal>
          <ModalPopUp
            visible={this.state.deleteAccessory}
            cancel={this.cancelDelete}
            submit={this.submitDelete}
            name="Accessory"
            delete_name={this.state.delete_name}
          />
        </div>
      </div>
    );
  }
}
