import React, { Component } from "react";
import {
  Form,
  Input,
  Divider,
  message,
  DatePicker,
  Tag,
  Spin,
  Select,
  Checkbox,
  Modal
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TweenOneGroup } from "rc-tween-one";
import "../../stylesheets/styles.css";
import moment from "moment";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import { subDays, addDays } from "date-fns";
import TopHeader from "../../components/ui/TopHeader";
import InputItem from '../../components/ui/InputItem';
import SelectItem from '../../components/ui/SelectItem';
import { Spinner } from "reactstrap";

import {
  isInvalidName,
  isInvalidEmail,
  showError,
  showDates,
  number,
  checkNameLength,
  isErrorTxt,
  checkDigitLength,
  checkTxtLength,
} from "../../constants/Utils";

const { Option } = Select;

export default class Onboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      role_id: "",
      email: "",
      phone: "",
      location: "",
      interview_date: "",
      join_date: "",
      job_title: "",
      report: "",
      salary: "",
      expires: "",
      inputVisible: false,
      inputValue: "",
      tags: [],
      roleList: [],
      role: "",
      job: "",
      benifits: [],
      isReadonly: true,
      employeeList: [],
      defaultInterviewer: [],
      isFetching: true,
      reporter: "",
      repobj: "",
      isChecked: true,
      add_edit_loader: false,
      mailSend:false,
      updateMailPopup:false
    };
  }
  async componentDidMount() {
    if (this.props.match.params.id) {
      this.setState(
        {
          id: this.props.match.params.id,
        },
        () => {
          this.getOnboardDetail(this.state.id);
        }
      );
    }

    this.callEmployeAPI();
    this.handleRolelist();
  }

  callEmployeAPI = () => {
    let { reporter } = this.state;
    var queryParams = "";
    if (reporter !== "") {
      queryParams = "?search_term=" + reporter;
    }
    // if (status) {
    //   queryParams = "?status=active" + status;
    // }

    // APIGATEWAY.get("employees/" + queryParams, (response) => {
    //   if (response.success) {
    //     this.setState({ employeeList: response.data.employees });
    //   }
    // });

    APIGATEWAY.get("employees/all" + queryParams, (response) => {
      if (response.success) {
        this.setState({ employeeList: response.data.employees });
      }
    });
  };

  getOnboardDetail = (id) => {
    let username = id;
    APIGATEWAY.get("employee/onboard/" + username, (response) => {
      if (response.success) {
        let data = response.data.employee_onboard;

        var interviewArr = data.interview_by;
        var additionalInterview = [];
        if (interviewArr.length > 0) {
          for (let i = 0; i < interviewArr.length; i++) {
            additionalInterview.push(JSON.stringify(interviewArr[i]));
          }
        } else {
          additionalInterview = [];
        }
        this.setState({ defaultInterviewer: additionalInterview });
        var jobrole = JSON.stringify(data.job_title);
        this.setState({
          name: data.name,
          email: data.email,
          phone: data.phone && data.phone.national_number,
          // location: "",
          interview_date: moment(
            data.official_date && data.official_date.interviewed
          ),

          join_date: moment(data.official_date && data.official_date.joined),
          // job_title: data.job_title && data.job_title,
          job: jobrole,
          reporter: data.report_to && data.report_to,
          salary: data.salary && data.salary,
          expires: moment(data.offer_expires && data.offer_expires),
          benifits: data.benefits && data.benefits,
          // inputVisible: false,
          // inputValue: "",
          tags: data.benefits && data.benefits,
          // roleList: [],
          // role:"",
          // interview_by: interviewer,
          role: jobrole,
          // repobj: data.report_to,
          isFetching: false,
          isChecked: data.probation,
        });
      }
    });
  };
  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  inputNumberChange = (event) => {
    let guidRegex = /^([0-9])+$/;

    if (event.target.value === "" || guidRegex.test(event.target.value)) {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleRolelist = () => {
    APIGATEWAY.get("roles?all=true", (response) => {
      if (response.success) {
        this.setState({ roleList: response.data.roles });
      }
    });
  };
  onChangeCheckbox = (e) => {
    this.setState({ isChecked: e.target.checked });
  };

  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  handleDateChange = (e) => {
    this.updateStateValues("expires", e);
  };

  handleJoinDateChange = (e) => {
    this.updateStateValues("join_date", e);
  };

  handleInterviewDateChange = (e) => {
    this.updateStateValues("interview_date", e);
  };


  popupConfirm=()=>{
    this.setState({ updateMailPopup: true });
  }

  updateWithMAil=()=>{
    this.setState({ mailSend: true }, () => {
      // This callback function will run after the state is updated
      this.createOnboard(this.state.mailSend);
    });
  }

  createOnboard = () => {
   
    const { id } = this.state;
    if (id) {
      this.editOnboard(this.state.id);
    } else {
      this.addOnboard();
    }
  };

  addOnboard = () => {
    const {
      name,
      email,
      phone,
      interview_date,
      join_date,

      repobj,

      salary,
      expires,
      isChecked,
      tags,
      role,
    } = this.state;
    const url =
      window.location.protocol + "//" + window.location.host + "/onboard";

    if (isErrorTxt(name)) {
      showError("Please enter the name");
    }
    // else if (isInvalidName(name)) {
    //   showError("Name should contain letters only");
    // }
    else if (checkNameLength(name)) {
      showError("Name should contain atleast 3 characters");
    } else if (isErrorTxt(email)) {
      showError("Please enter the email");
    } else if (isInvalidEmail(email)) {
      showError("Please enter a valid email id");
    } else if (isErrorTxt(phone)) {
      showError("Please enter the phone number");
    } else if (checkTxtLength(phone, 10)) {
      showError("Please enter 10 digits ");
    } else if (isErrorTxt(interview_date)) {
      showError("Please select the interview date");
    } else if (isErrorTxt(join_date)) {
      showError("Please select the join date");
    } else if (isErrorTxt(role)) {
      showError("Please select the job title");
    }
    //  else if (isInvalidName(job_title)) {
    //     showError("Job title should contain letters only");
    // }
    else if (isErrorTxt(expires)) {
      showError("Please select the expires date");
    } else if (isErrorTxt(repobj)) {
      showError("Please select the report to");
    } else if (isErrorTxt(salary)) {
      showError("Please enter the salary");
    } else {
      let selectedrole = this.state.roleList.find(
        (role) => role.role_id === JSON.parse(this.state.role).role_id
      );
      let report = this.state.employeeList.find(
        (rep) => rep.emp_id === JSON.parse(this.state.repobj).emp_id
      );

      //interview
      let Interview_by = [];
      let defEmploy = this.state.defaultInterviewer;

      if (
        defEmploy.length > 0 &&
        Array.isArray(defEmploy[defEmploy.length - 1])
      ) {
        var arrayProduct =
          this.state.defaultInterviewer[
            this.state.defaultInterviewer.length - 1
          ];
        for (let i = 0; i < arrayProduct.length; i++) {
          Interview_by.push(JSON.parse(arrayProduct[i]));
        }
      } else {
        var arrProd = this.state.defaultInterviewer;
        for (let i = 0; i < arrProd.length; i++) {
          Interview_by.push(JSON.parse(arrProd[i]));
        }
      }
      if (selectedrole === undefined) {
        this.setState({
          role: "",
        });
        showError("Please select the role");
      } else {
        let data = {
          name: name,
          email: email,
          phone: {
            national_number: phone,
          },
          official_date: {
            interviewed: showDates(interview_date),
            joined: showDates(join_date),
          },
          // job_title: job_title,
          // job_title: selectedrole.name && selectedrole.name,
          job_title: {
            name: selectedrole.name,
            role_id: selectedrole.role_id,
          },
          report_to: report.name,
          offer_expires: showDates(expires),
          salary: salary,
          benefits: tags,
          link: url,
          interview_by: Interview_by,
          probation: isChecked,
        };
        this.setState({ add_edit_loader: true });

        APIGATEWAY.post("employee/onboard", data, (response) => {
          if (response.success) {
            this.setState({ add_edit_loader: false });
            message.success(response.message);
            this.props.history.push("/onboard");
          } else {
            message.error(response.message);
          }
        });
      }
    }
  };

  editOnboard = (id,mail) => {
    const {
      name,
      email,
      phone,

      interview_date,
      join_date,

      reporter,
      salary,
      expires,

      tags,
      isChecked,
      job,
    } = this.state;
    if (name === "" || name === null) {
      showError("Please enter the name");
    }
    // else if (isInvalidName(name)) {
    //   showError("Name should contain letters only");
    // }
    else if (name.length < 3) {
      showError("Name should contain atleast 3 characters");
    } else if (email === "" || email === null || isInvalidEmail(email)) {
      showError("Please enter a valid email id");
    } else if (phone === "" || phone === null || number(phone)) {
      showError("Please enter a valid phone number");
    } else if (phone.length < 10 || phone.length > 10) {
      showError("Please enter 10 digits ");
    } else if (interview_date === "" || interview_date === null) {
      showError("Please select the interview date");
    } else if (join_date === "" || join_date === null) {
      showError("Please select the join date");
    } else if (job === "" || job === null) {
      showError("Please select the the role");
    } else if (reporter === "" || reporter === null) {
      showError("Please select the report to");
    } else if (expires === "" || expires === null) {
      showError("Please select the expires date");
    } else if (salary === "" || salary === null) {
      showError("Please select the salary");
    }
    // else if (inputValue === "" || inputValue === null) {
    //   showError("Please select benifits");
    // }
    else {
      //role
      let selectedrole = this.state.roleList.find(
        (role) => role.role_id === JSON.parse(this.state.role).role_id
      );

      //interview by
      let Interview_by = [];
      var defEmploy = this.state.defaultInterviewer;

      if (
        defEmploy.length > 0 &&
        Array.isArray(defEmploy[defEmploy.length - 1])
      ) {
        var arrayProduct =
          this.state.defaultInterviewer[
            this.state.defaultInterviewer.length - 1
          ];
        for (let i = 0; i < arrayProduct.length; i++) {
          Interview_by.push(JSON.parse(arrayProduct[i]));
        }
      } else {
        var arrProd = this.state.defaultInterviewer;
        for (let i = 0; i < arrProd.length; i++) {
          Interview_by.push(JSON.parse(arrProd[i]));
        }
      }

      let data = {
        name: name,
        email: email,
        phone: {
          national_number: phone,
        },
        official_date: {
          interviewed: showDates(interview_date),
          joined: showDates(join_date),
        },
        job_title: {
          name: selectedrole.name,
          role_id: selectedrole.role_id,
        },
        report_to: reporter,
        salary: salary,
        onboard_id: id,
        benefits: tags,
        // tags: tags,
        interview_by: Interview_by,
        probation: isChecked,
        mail:this.state.mailSend

      };
      this.setState({ add_edit_loader: true });
      this.setState({ updateMailPopup: false });

      APIGATEWAY.patch("employee/onboard/" + id, data, (response) => {

        this.setState({ mailSend: false });
        if (response.success) {
          this.setState({ add_edit_loader: false });
          this.props.history.push("/onboard");
          message.success(response.message);

        } else {
          message.error(response.message);
        }
      });
    }
  };
  goBack = () => {
    this.props.history.goBack();
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  saveInputRef = (input) => {
    this.input = input;
  };
  handleInputChanges = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue === "") {
      showError("Enter the benifits");
    } else if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }

    this.setState({
      tags: tags,
      inputVisible: false,
      inputValue: "",
    });
  };

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);
    this.setState({ tags });
  };

  forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          this.handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };
  render() {
    var defaultRole = "";
    try {
      defaultRole = JSON.parse(this.state.role);
    } catch (error) {}

    let {
      name,
      email,
      phone,

      interview_date,
      join_date,

      salary,
      expires,
      inputVisible,
      inputValue,
      tags,
      id,

      employeeList,
      defaultInterviewer,
      reporter,
    } = this.state;
    const tagChild = tags.map(this.forMap);

    return this.state.isFetching && this.state.id !== "" ? (
      <div className="flex-cen wh-100">
        <Spin size="large" />
      </div>
    ) : (
      <div className="">
        <TopHeader
          title="Onboard"
          backClick={this.goBack}
          submit={this.popupConfirm}
          id={this.state.id}
          spin={this.state.add_edit_loader}
        />

        <div className="main-content2" style={{ marginBottom: "100px" }}>
          <div className="divider">
            <Divider orientation="left" className="onboard_divider">
              Basic Details
            </Divider>
          </div>
          <div>
            <div>
              <Form
                name="basic"
                layout="vertical"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                autoComplete="off"
              >
                <div
                  className="flexes"
                  style={{ marginTop: 10, width: "100%" }}
                >
                  <div className="form_inputs">
                  <InputItem label='Name' name='name' value={name} star={false} placeholder="Enter the Name" onChange={this.handleInputChange} />
                    
                  </div>

                  <div className="form_inputs">
                <InputItem label='Email' name='email' value={email} star={false} placeholder="Enter the email" onChange={this.handleInputChange} />

                  </div>
                  <div className="form_inputs">
                <InputItem label='Phone' name='phone' value={phone} star={false} placeholder="Phone Number" onChange={this.inputNumberChange} />

                  </div>
                </div>
              </Form>
            </div>
          </div>

          <div className="divider">
            <Divider orientation="left" className="onboard_divider">
              Job Details
            </Divider>
          </div>

          <div>
            <Form
              name="basic"
              layout="vertical"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <div className="flexes" style={{ marginTop: 10, width: "100%" }}>
                <div className="form_inputs">
                  <p className="onboard_input_name">Interview By</p>
                  <Select
                    style={{ width: "100%", borderRadius: "7px" }}
                    className="interview-select"
                    mode="multiple"
                    placeholder="Interview by"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    defaultValue={defaultInterviewer}
                    showSearch
                    showArrow
                    onChange={(value) => {
                      this.setState({
                        defaultInterviewer: [...defaultInterviewer, ...[value]],
                      });
                    }}
                  >

                    {employeeList.map((employee, i) => (  
                      
                      <Option


                        value={JSON.stringify({
                          emp_id: employee.emp_id,
                          name: employee.name,
                        })}
                        key={employee.emp_id}
                      >
                        {employee.name}
                      </Option>
                    ))}
                  </Select>
                   
                </div>
                <div className="form_inputs">
                  <p className="onboard_input_name">Interview Date</p>

                  <DatePicker
                    name="interview_date"
                    placeholder="Interview Date"
                    dateFormat="YYYY-MM-DD"
                    style={{ width: "100%", height: 47 }}
                    className="onboard-date"
                    value={interview_date}
                    inputReadOnly
                    disabledDate={(d) =>
                      !d ||
                      d.isAfter(addDays(new Date(), 0)) ||
                      d.isSameOrBefore(subDays(new Date(), 60))
                    }
                    onChange={this.handleInterviewDateChange}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  />
                </div>

                <div className="form_inputs">
                  <p className="onboard_input_name">Joining Date</p>

                  <DatePicker
                    name="join_date"
                    placeholder="Joining date"
                    dateFormat="YYYY-MM-DD"
                    style={{ width: "100%", height: 47 }}
                    className="onboard-date"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    value={join_date}
                    inputReadOnly
                    disabledDate={(d) =>
                      !d ||
                      d.isAfter(addDays(new Date(), 120)) ||
                      d.isSameOrBefore(subDays(new Date(), 8))
                    }
                    onChange={id ? "" : this.handleJoinDateChange}
                  />
                </div>
              </div>
            </Form>
          </div>
          <div>
            <Form
              name="basic"
              layout="vertical"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <div className="flexes" style={{ marginTop: 20, width: "100%" }}>
                <div className="form_inputs">
                  <p className="onboard_input_name">Job Title</p>
                  <Select
                    placeholder="Select Jobtitle"
                    style={{ width: "100%" }}
                    // className="blood"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    value={this.state.job || undefined}
                    showSearch
                    //   value={defaultRole ? defaultRole.name : "Select a job title"}
                    onChange={(value) => {
                      this.setState({
                        role: value,
                        job: value,
                      });
                    }}
                  >
                    {this.state.roleList.map((list) => (
                      <Option
                        key={list.role_id}
                        value={JSON.stringify({
                          name: list.name,
                          role_id: list.role_id,
                        })}
                      >

                        {list.name}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className="form_inputs">
                  <p className="onboard_input_name">Reporting</p>

                  <Select
                    style={{ width: "100%" }}
                    className="blood"
                    placeholder="Reports to"
                    showSearch
                    getPopupContainer={(trigger) => trigger.parentNode}
                    value={reporter || undefined}
                    onChange={(value) =>
                      this.setState({
                        reporter: value,
                        repobj: value,
                      })
                    }
                  >
                    {employeeList.map((employes, i) => (
                      <Option
                        key={i}
                        value={JSON.stringify({
                          emp_id: employes.emp_id,
                          name: employes.name,
                        })}
                      >
                        {employes.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="form_inputs">
                  
                <InputItem label='Salary (month)' name='salary' value={salary} star={false} placeholder="Enter the salary" onChange={this.inputNumberChange} />

                </div>
              </div>
            </Form>
          </div>
          <div>
            <Form
              name="basic"
              layout="vertical"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <div className="flexes" style={{ marginTop: 20, width: "100%" }}>
                <div className="form_inputs">
                  <p className="onboard_input_name">Offer Expiry</p>
                  <DatePicker
                    inputReadOnly
                    onChange={this.handleDateChange}
                    name="expires"
                    dateFormat="YYYY-MM-DD"
                    placeholder="Expires date"
                    style={{ width: "100%", height: 47 }}
                    className="onboard-date"
                    value={expires}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    disabledDate={(d) =>
                      !d ||
                      d.isAfter(addDays(new Date(), 30)) ||
                      d.isSameOrBefore(subDays(new Date(), 1))
                    }
                  />
                </div>

                <div className="form_inputs">
                  <p className="onboard_input_name"></p>

                 
                  <div style={{ paddingTop: "25px" }}>
                    <Checkbox
                      className="checkbox"
                      checked={this.state.isChecked}
                      
                      onChange={this.onChangeCheckbox}
                    >
                      Probation period
                    </Checkbox>
                  </div>
                </div>
                <div className="form_inputs"></div>
              </div>
            </Form>
          </div>
          <div className="divider">
            <Divider orientation="left" className="onboard_divider">
              Benefits
            </Divider>
          </div>

          <div className="sm__grid--col-4" style={{ display: "flex" }}>
            {!inputVisible && (
              <div style={{ display: "flex" }}>
                <Tag onClick={this.showInput} className="site-tag-plus">
                  <i className="fa fa-plus add-icon"></i>
                  <span>Add Benefits</span>
                </Tag>
              </div>
            )}
            {inputVisible && (
              <Input
                ref={this.saveInputRef}
                type="text"
                size="small"
                className="client-field"
                value={inputValue}
                onChange={this.handleInputChanges}
                onBlur={this.handleInputConfirm}
                onPressEnter={this.handleInputConfirm}
              />
            )}
            <div>
              <TweenOneGroup
                enter={{
                  onComplete: (e) => {
                    e.target.style = "";
                  },
                }}
                appear={false}
                className="tag-client"
              >
                {tagChild}
              </TweenOneGroup>
            </div>
          </div>
        </div>

        <Modal
            title="Remove Member"
            onCancel={() => { this.setState({ updateMailPopup: false }) }}
            onOk={() => { this.setState({ updateMailPopup: true }) }}
            open={this.state.updateMailPopup}
            
            footer={[
              <div className="d-flex justify-content-between align-items-center">
                <button
                  className="secondary_color "
                  onClick={this.createOnboard}
                >
                  Update
                </button>
                <button className="dec-btn noWrap " onClick={()=>this.updateWithMAil()}>
                  Update & Mail
                </button>
              </div>
            ]}
          >
              <div>
                <p>Would you like to Update & Send Mail or just Update?</p>
            </div>
        
          </Modal>
      </div>
    );
  }
}
