import React, { Component } from "react";
import {
  Button,
  Table,
  Form,
  Modal,
  DatePicker,
  message,
  Spin,
  Select,
  Dropdown,
  Menu,
  Card,
  Popover,
  Empty,
} from "antd";
import { Link, withRouter } from "react-router-dom";
import "antd/dist/antd.css";
import "../../stylesheets/styles.css";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import urlLink from "../../ApiService/GlobalVariables/globalVariables";

import TopHeader from "../../components/ui/TopHeader";
import { ConsoleSqlOutlined, MoreOutlined } from "@ant-design/icons";
import moment from "moment";
import TableField from "../../components/ui/tableField";
import {
  STORAGE,
  UPDATE,
  APPROVAL,
  DELETE,
  CREATE,
} from "../../constants/roleStore";
import cookie from "react-cookies";
import layer from "../../assets/Layer 2.svg";
import ModalPopUp from "../../components/ui/modalpop";
import DateItem from "../../components/ui/DateItem";
import InputItem from "../../components/ui/InputItem";
import {
  showDate,
  getIsoString,
  isErrorTxt,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  showError,
  getMonthYear,
  showCurrency,
  numberWithCommas,
  numberFormatWithCurrency,
  numberWithRupees,
  numberWithComma,
  showSuccess,
  amountStatus,
} from "../../constants/Utils";

import Filter from "../../components/ui/filter";
import { INVOICEACCESS } from "../../components/ui/constants";

const { Option } = Select;

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 20,
      currentPage: 1,
      data: [],
      payData: [],
      total: "",
      status: "",
      mode: "",
      searchOption: "",
      searchInput: "",
      from: "",
      to: "",
      paidDate: "",
      dateRange: null,
      paid: "",
      overall: "",
      totalAmount: "",
      totalDocuments: "",
      unpaid: "",
      loading: true,
      Tableloading: true,
      app_name: "",
      app_type: "",
      dropdown_menu: false,
      modal1Visible: false,
      notes: "",
      list_index: "",
      statusData: [
        // { value: "All", key: "all" },
        { value: "Paid", key: "paid" },
        { value: "Unpaid", key: "unpaid" },
        { value: "Cancelled", key: "cancelled" },
      ],
      queryStatus: {},
      isBack: false,
      customer_name: "",
      searchData: [
        { value: "Project ", key: "project_name" },
        { value: "Client  ", key: "client_name" },
      ],
      cancelInvoiceModal: false,
      invoice_number: "",
      invoice_id: "",
      approval: false,
      create: false,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount = () => {
    let search = this.props.location.search;
    const status = new URLSearchParams(search).get("status");
    let name = "";

    let storage = JSON.parse(STORAGE.view);
    name = storage?.filter((app) => app?.name === "My Payments");

    let value = name;
    value?.filter((app) => {
      let names = app?.sub_roles?.filter((role) => role.name === "Invoices");

      this.setState({
        app_name: names,
      });
    });

    this.setState({
      app_type: STORAGE.type,
    });
    if (status !== null) {
      this.setState({ status: status }, () => {
        this.setQueryStatus();
      });
    } else this.setQueryStatus();

    this.overallInvoices();

    this.reload();
    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);
  };

  reload = () => {
    this.props.history.listen((location, search) => {
      this.setState({ queryStatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);
        if (Object.keys(query).length === 0) {
          this.clearSearch();
          this.setState({
            dropdown_menu: false,
          });
        }
      });
    });
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);
  }

  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };

  onPopstate = () => {
    const search = this.props.location.search;

    const searchObj = returnSearchObject(search);
    this.state.status = searchObj.status ?? "";
    this.state.from = searchObj.from ?? "";
    this.state.to = searchObj.to ?? "";
    this.state.searchOption = searchObj.type ?? "";
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";
    this.state.searchInput = searchObj.value ?? "";
    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      this.handleTable();
      this.setState({
        dropdown_menu: false,
      });
    });
  };

  getQuerySearch = () => {
    const {
      searchOption,
      searchInput,
      currentPage,
      pageSize,
      status,
      from,
      to,
    } = this.state;
    const querysearch = returnSearchObject(this.props.location.search);

    if (searchOption !== "" && searchInput !== "") {
      querysearch.type = searchOption;
      querysearch.value = searchInput;

      querysearch[searchOption] = searchInput;
    }

    querysearch.page = currentPage ?? "";
    querysearch.limit = pageSize ?? "";
    querysearch.status = status ?? "";
    querysearch.from = getIsoString(from) ?? "";
    querysearch.to = getIsoString(to) ?? "";

    return querysearch;
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);

      this.setState(
        {
          status: queryObj.status ?? "",
          from: queryObj.from ?? "",
          to: queryObj.to ?? "",
          searchOption: queryObj.type ?? "",
          searchInput: queryObj.value ?? "",
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",

          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    }
  };

  handleFilter = () => {
    let query = this.getQuerySearch();
    if (this.state.from > this.state.to) {
      showError("Select the valid date");
    } else {
      this.setState(
        {
          queryStatus: query,
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    }
  };

  handleTable = () => {
    APIGATEWAY.get(
      "invoices" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          let res = response.data.invoices;
          this.setState({
            data: res,
            total: response.data?.invoices?.length,
            loading: false,
            Tableloading: false,
            overall: response.data?.total,
            // dropdown_menu: false,
            // list_index: ''
          });
        } else {
          this.setState({ loading: true });
        }
      }
    );
  };

  previous = () => {
    const { currentPage } = this.state;
    this.setState(
      {
        currentPage: currentPage - 1,
        Tableloading: true,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  next = (prev) => {
    const { currentPage } = this.state;

    this.setState({ currentPage: prev + 1, Tableloading: true }, () => {
      this.handleFilter();
    });
  };

  overallInvoices = () => {
    APIGATEWAY.get("invoices/overall/amount", (response) => {
      if (response.success) {
        let data = response.data.invoices;
        this.setState({
          paid: data?.paid_amounts ?? 0,
          totalAmount: data?.total_amount ?? 0,
          totalDocuments: data?.total_documents ?? 0,
          unpaid: data?.unpaid_amounts ?? 0,
        });
      }
    });
  };

  onDateRangeChange = (dateRange) => {
    if (dateRange) {
      this.setState({
        dateRange: this.returnMomentDateRange(dateRange[0], dateRange[1]),
        from: moment(dateRange[0]),
        to: moment(dateRange[1]),
      });
    } else {
      this.setState({
        dateRange: null,
      });
    }
  };

  returnMomentDateRange = (start, finish) => {
    return [moment(start, "YYYY-MM-DD"), moment(finish, "YYYY-MM-DD")];
  };

  handleDelete = (id) => {
    let self = this;
    let data = {};
    APIGATEWAY.delete("invoices/" + id, data, (response) => {
      if (response.success) {
        message.success(response.message);
        self.handleTable();
        self.overallInvoices();
      } else {
        message.error(response.message);
      }
    });
  };

  handleEditPage = (id) => {
    this.props.history.push("/invoices/add/" + id);
  };

  handleDetailpage = (id) => {
    this.props.history.push("/invoices/detail/" + id);
  };

  handleCreatePage = (duplicate) => {
    this.props.history.push("invoices/add/duplicate/" + duplicate);
  };

  handleNotes = (e) => {
    this.setState({
      notes: e.target.value,
    });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.handleTable();
    });
  };

  hanleStatus = (value) => {
    this.setState(
      {
        status: value,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  InvoiceFilter = (value) => {
    if (value === "paid" || value === "unpaid") {
      this.setState(
        {
          status: value,
          currentPage: 1,
        },
        () => {
          this.handleFilter();
        }
      );
    } else {
      this.setState(
        {
          status: value,
          currentPage: 1,
        },
        () => {
          this.handleTable();
        }
      );
    }
  };

  clearSearch = () => {
    this.setState(
      {
        status: "",
        mode: "",
        from: "",
        to: "",
        currentPage: 1,
        dateRange: null,
        queryStatus: { page: 1, limit: 20 },
        pageSize: 20,
        searchInput: "",
        searchOption: "",
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);

        this.handleTable();
      }
    );
  };

  Apply = () => {
    const { status, mode, from, to, searchInput, searchOption } = this.state;
    if (
      status === "" &&
      from === "" &&
      to === "" &&
      (searchInput === "" || searchOption === "")
    ) {
      showError("Please select any one field");
    } else {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handleFilter();
        }
      );
    }
  };

  searchInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trim().trim(),
    });
  };

  searchOption = (value) => {
    const { searchOption } = this.state;
    this.setState({
      searchOption: value,
    });
  };

  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };

  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };

  handleOnPaidChange = (e) => {
    this.updateStateValues("paidDate", e);
  };

  handleFromChange = (date, dateString) => {
    this.setState({ from: date });
  };

  handleToChange = (date, dateString) => {
    this.setState({ to: date });
  };

  pageLimitOnchange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  download = (object) => {
    let token = cookie.load("empr_tk");

    var bearer = "Bearer " + token;
    fetch(urlLink.BASE_URL + "/invoices/pdf/" + object.invoice_id, {
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response) {
        response.blob().then((data) => {
          let url = window.URL.createObjectURL(data);

          let a = document.createElement("a");
          a.href = url;
          a.download = `${object.invoice_number}.pdf`;
          a.click();
          showSuccess("Downloaded Successesfully");
        });
      } else {
        showError("Download Fail");
      }
    });
  };

  handleActions = (e, object) => {
    if (e === "edit") {
      this.handleEditPage(object.invoice_id);
    }
    if (e === "delete") {
      this.handleDelete(object.invoice_id);
    }
    if (e === "download") {
      this.download(object);
    }
    if (e === "cancel") {
      this.setState({
        cancelInvoiceModal: true,
        invoice_number: object?.invoice_number ?? "",
        invoice_id: object?.invoice_id ?? "",
      });
    }
    if (e === "duplicate") {
      this.handleCreatePage(object.invoice_id);
    }
    if (e === "payInvoice") {
      this.setState({
        modal1Visible: true,
        payData: object,
      });
    }
  };

  cancelInvoice = () => {
    this.setState({
      cancelInvoiceModal: false,
      invoice_number: "",
      invoice_id: "",
    });
  };

  submitInvoice = () => {
    const { invoice_id } = this.state;
    let data = {};
    let status = "cancelled";
    APIGATEWAY.patch(
      "invoices/" + status + "/" + invoice_id,
      data,
      (response) => {
        if (response.success) {
          showSuccess(response.message);
          this.cancelInvoice();
          this.handleFilter();
          this.overallInvoices();
        } else {
          showError(response.message);
        }
      }
    );
  };

  handleCustomerChange = (e) => {
    this.setState({ customer_name: e.target.value });
  };
  dropdownClick = (index) => {
    this.setState({
      dropdown_menu: !this.state.dropdown_menu,
      list_index: index,
    });
  };
  setModal1Visible = (modal1Visible) => {
    this.setState({
      modal1Visible,
      paidDate: "",
      notes: "",
    });
  };
  submitHandle = () => {
    const { paidDate, notes, payData } = this.state;
    if (isErrorTxt(paidDate)) {
      showError("Please select the paid date");
    } else {
      let data = {
        paid_date: getIsoString(paidDate),
        received_amount: payData?.final_amount ?? "",
        received_notes: notes,
      };
      let status = "paid";
      APIGATEWAY.patch(
        "invoices/" + status + "/" + payData.invoice_id,
        data,
        (response) => {
          if (response.success) {
            this.handleTable();
            this.overallInvoices();
            message.success("Invoice paid successfully");
            this.setState({ modal1Visible: false, paidDate: "" });
          }
        }
      );
    }
  };
  render() {
    const {
      data,
      total,
      pageSize,
      currentPage,
      app_name,
      app_type,
      totalDocuments,
      totalAmount,
      paid,
      unpaid,
      payData,
    } = this.state;
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }
    const menu = (object) => {
      return (
        <Menu
          onClick={(e) => this.handleActions(e, object)}
          style={{ width: "100px" }}
        >
          {UPDATE(app_type, app_name, <Menu.Item key="edit">Edit</Menu.Item>)}

          {DELETE(
            app_type,
            app_name,
            <Menu.Item key="delete">Delete</Menu.Item>
          )}

          <Menu.Item key="download">Download</Menu.Item>
        </Menu>
      );
    };

    return (
      <div>
        <div>
          <TopHeader
            title="Invoices"
            to="/invoices/add"
            type={app_type}
            permission={app_name}
          />
          {this.state.Tableloading ? (
            <div className="tableview">
              <Spin />
            </div>
          ) : (
            <div style={{ padding: "0px 23px 30px 30px" }}>
              <div className="invoice_card_overall">
                <Card title="INVOICES" className="invoice_each_card">
                  <p className="invoice_dash">
                    {isErrorTxt(this.state.totalDocuments)
                      ? 0
                      : this.state.totalDocuments}
                  </p>
                </Card>

                <Card
                  title="TOTAL INVOICES"
                  className="invoice_each_card status-click"
                  onClick={() => this.InvoiceFilter("all")}
                >
                  <p className="invoice_dash">
                    {isErrorTxt(this.state.totalAmount)
                      ? numberWithComma(0)
                      : numberWithComma(this.state.totalAmount)}
                  </p>
                </Card>

                <Card
                  title="INVOICES PAID"
                  className="invoice_each_card status-click"
                  onClick={() => this.InvoiceFilter("paid")}
                >
                  <p className="invoice_dash">
                    {isErrorTxt(this.state.paid)
                      ? numberWithComma(0)
                      : numberWithComma(this.state.paid)}
                  </p>
                </Card>

                <Card
                  title="INVOICES PENDING"
                  className="invoice_each_card status-click"
                  onClick={() => this.InvoiceFilter("unpaid")}
                >
                  <p className="invoice_dash">
                    {isErrorTxt(this.state.unpaid)
                      ? numberWithComma(0)
                      : numberWithComma(this.state.unpaid)}
                  </p>
                </Card>
              </div>

              <div>
                <Filter
                  statusData={this.state.statusData}
                  statusName="status"
                  statusSelect={this.state.status}
                  statusOnChange={(e) => this.hanleStatus(e)}
                  clear={() => this.clearSearch()}
                  apply={() => this.Apply()}
                  searchData={this.state.searchData}
                  searchname="searchOption"
                  searchOption={this.state.searchOption}
                  optionChange={(e) => this.searchOption(e)}
                  searchInputName="searchInput"
                  searchInput={this.state.searchInput}
                  inputChange={(e) => this.searchInput(e)}
                  handleOnFromChange={this.handleFromChange}
                  handleOnToChange={this.handleToChange}
                  fromDate={this.state.from}
                  toDate={this.state.to}
                  fromName="from"
                  toName="to"
                />

                <div className="table-main">
                  {this.state.Tableloading ? (
                    <div className="tableview">
                      <Spin />
                    </div>
                  ) : (
                    <div className="table_page_wrap">
                      {data.length > 0 ? (
                        <table className="table-response">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Invoice #</th>
                              <th>Project</th>
                              <th>Client</th>
                              <th>Created On</th>
                              <th>Due On</th>
                              <th>Amount</th>
                              <th>Converted Amount</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>

                          {data.length > 0 ? (
                            <tbody ref={this.wrapperRef}>
                              {data.map((item, r) => (
                                <tr key={r}>
                                  <td>
                                    {" "}
                                    {(this.state.currentPage - 1) *
                                      this.state.pageSize +
                                      (r + 1)}
                                  </td>
                                  <td
                                    onClick={() =>
                                      this.handleDetailpage(item.invoice_id)
                                    }
                                    style={{
                                      color: "#333333",
                                      cursor: "pointer",
                                      fontWeight: "600 !important",
                                    }}
                                  >
                                    {isErrorTxt(item?.invoice_number)
                                      ? "-"
                                      : item?.invoice_number}
                                  </td>
                                  <td className="table-name text-capitalize ">
                                    <div

                                    // className="table_name_highlight"
                                    >
                                      {item?.project?.name
                                        ? item?.project?.name
                                        : "-"}
                                    </div>
                                  </td>

                                  <td className="table-name text-capitalize">
                                    <div>
                                      {item?.client?.name?.length >= 15 ? (
                                        <Popover
                                          overlayStyle={{
                                            maxWidth: "20vw",
                                          }}
                                          content={item?.client?.name}
                                        >
                                          {item.client.name.slice(0, 15) +
                                            "....."}
                                        </Popover>
                                      ) : (
                                        item.client?.name ?? "--"
                                      )}
                                    </div>
                                  </td>
                                  <td className="table-name">
                                    {item.createdAt
                                      ? `${showDate(item.createdAt)}`
                                      : "-"}
                                  </td>
                                  <td className="table-name">
                                    {item.due_date
                                      ? `${showDate(item.due_date)}`
                                      : "-"}
                                  </td>

                                  <td className="table-name">
                                    {isErrorTxt(item?.final_amount) ? (
                                      "-"
                                    ) : (
                                      <span>
                                        {item?.client?.currency !== "inr" && (
                                          <span>
                                            {showCurrency(
                                              item?.client?.currency ?? ""
                                            )}
                                          </span>
                                        )}

                                        <span>
                                          {item?.client?.currency !== "inr"
                                            ? numberWithRupees(
                                                item?.final_amount
                                              )
                                            : numberWithComma(
                                                item?.final_amount
                                              )}
                                        </span>
                                      </span>
                                    )}
                                  </td>
                                  <td className="table-name">
                                    {numberWithComma(item?.converted_amount) ??
                                      0}
                                  </td>
                                  <td className="table-name">
                                    {amountStatus(item?.status)}
                                  </td>
                                  {item.status === "cancelled" ? (
                                    ""
                                  ) : (
                                    <td
                                      className="table-action"
                                      onClick={() => this.dropdownClick(r)}
                                    >
                                      <div className="tax-add-edit">
                                        <span className="ml-5">
                                          <div className="dropdown-head">
                                            <div className="dropbtn-head">
                                              <img
                                                src={layer}
                                                alt="profile"
                                                className="menu-bar-icon"
                                              />
                                            </div>
                                            {this.state.list_index === r &&
                                              this.state.dropdown_menu && (
                                                <div className="dropdown-content-heads">
                                                  <div>
                                                    {/* {UPDATE(
                                                      app_type,
                                                      app_name,
                                                      item.status === "paid" ? (
                                                        ""
                                                      ) : (
                                                        <p
                                                          className="action-menu-bar"
                                                          onClick={() =>
                                                            this.handleActions(
                                                              "edit",
                                                              item
                                                            )
                                                          }
                                                        >
                                                          Edit
                                                        </p>
                                                      )
                                                    )} */}
                                                    {INVOICEACCESS.update &&
                                                      (item.status ===
                                                      "paid" ? (
                                                        ""
                                                      ) : (
                                                        <p
                                                          className="action-menu-bar"
                                                          onClick={() =>
                                                            this.handleActions(
                                                              "edit",
                                                              item
                                                            )
                                                          }
                                                        >
                                                          Edit
                                                        </p>
                                                      ))}

                                                    {/* {DELETE(
                                                app_type,
                                                app_name,
                                                <p className="action-menu-bar" onClick={() => this.handleActions("delete", item)}>
                                                  Delete
                                                </p>
                                              )} */}
                                                    {/* {APPROVAL(
                                                      app_type,
                                                      app_name,
                                                      item.status ===
                                                        "unpaid" && (
                                                        <p
                                                          className="action-menu-bar"
                                                          onClick={() =>
                                                            this.handleActions(
                                                              "cancel",
                                                              item
                                                            )
                                                          }
                                                        >
                                                          Cancel
                                                        </p>
                                                      )
                                                    )} */}
                                                    {INVOICEACCESS.approval &&
                                                      item.status ===
                                                        "unpaid" && (
                                                        <p
                                                          className="action-menu-bar"
                                                          onClick={() =>
                                                            this.handleActions(
                                                              "cancel",
                                                              item
                                                            )
                                                          }
                                                        >
                                                          Cancel
                                                        </p>
                                                      )}

                                                    <p
                                                      className="action-menu-bar"
                                                      onClick={() =>
                                                        this.handleActions(
                                                          "download",
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Download
                                                    </p>
                                                    {/* {CREATE(
                                                      app_type,
                                                      app_name,
                                                      <p
                                                        className="action-menu-bar"
                                                        onClick={() =>
                                                          this.handleActions(
                                                            "duplicate",
                                                            item
                                                          )
                                                        }
                                                      >
                                                        Duplicate
                                                      </p>
                                                    )} */}

                                                    {INVOICEACCESS.create && (
                                                      <p
                                                        className="action-menu-bar"
                                                        onClick={() =>
                                                          this.handleActions(
                                                            "duplicate",
                                                            item
                                                          )
                                                        }
                                                      >
                                                        Duplicate
                                                      </p>
                                                    )}
                                                    {/* {CREATE(
                                                      app_type,
                                                      app_name,
                                                      item.status ===
                                                        "unpaid" ? (
                                                        <p
                                                          className="action-menu-bar"
                                                          onClick={() =>
                                                            this.handleActions(
                                                              "payInvoice",
                                                              item
                                                            )
                                                          }
                                                        >
                                                          Mark as Paid
                                                        </p>
                                                      ) : (
                                                        ""
                                                      )
                                                    )} */}
                                                    {INVOICEACCESS.create &&
                                                    item.status === "unpaid" ? (
                                                      <p
                                                        className="action-menu-bar"
                                                        onClick={() =>
                                                          this.handleActions(
                                                            "payInvoice",
                                                            item
                                                          )
                                                        }
                                                      >
                                                        Mark as Paid
                                                      </p>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        </span>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            ""
                          )}
                        </table>
                      ) : (
                        <table className="table-response">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Invoice #</th>
                              <th>Project</th>
                              <th>Client</th>
                              <th>Created On</th>
                              <th>Due On</th>
                              <th>Amount</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>

                          <tbody className="empty-message">
                            <tr>
                              <td colspan="10">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                      {data.length > 0 && (
                        <TableField
                          data={data}
                          countprev={countprev}
                          countnext={countnext}
                          currentPage={currentPage}
                          ClickPrev={() => this.previous()}
                          ClickNext={() => this.next(this.state.currentPage)}
                          total={total}
                          overAll={this.state.overall ?? 0}
                          pagelimit={this.state.pageSize}
                          pagelimitHandle={this.pageLimitOnchange}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <ModalPopUp
                visible={this.state.cancelInvoiceModal}
                cancel={this.cancelInvoice}
                submit={this.submitInvoice}
                name="Invoice"
                delete_name={this.state.invoice_number}
                invoice={true}
              ></ModalPopUp>
            </div>
          )}
        </div>
        <Modal
          title="Invoice"
          style={{ top: 50 }}
          open={this.state.modal1Visible}
          onOk={() => this.setModal1Visible(false)}
          onCancel={() => this.setModal1Visible(false)}
          footer={[
            <div className="d-flex justify-content-between align-items-center">
              <button
                key="cancel"
                className="ad-btn-secondary"
                onClick={() => this.setModal1Visible(false)}
              >
                Cancel
              </button>
              <button
                key="submit"
                className="add-btn"
                onClick={() => this.submitHandle()}
              >
                Submit
              </button>
            </div>,
          ]}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className="admin_input"
          >
            <div>
              <div>
                <DateItem
                  label="Paid Date"
                  name="paidDate"
                  star={false}
                  value={this.state.paidDate}
                  placeholder="Paid Date"
                  onChange={this.handleOnPaidChange}
                />
              </div>

              <div className="mt-3">
                <InputItem
                  label="Received Amount"
                  name=""
                  star={false}
                  value={Math.round(payData.final_amount)}
                  placeholder=""
                  readonly={true}
                />
              </div>

              <div className="mt-3">
                <InputItem
                  label="Notes"
                  name="notes"
                  star={false}
                  value={this.state.notes}
                  placeholder="Enter the notes"
                  txtarea={true}
                  onChange={this.handleNotes}
                />
              </div>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Invoice);
