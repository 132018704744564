import React, { Component } from 'react';
import Apicall from '../../ApiService/ApiGateWay/apiGateWay';
import TopHeader from "../../components/ui/TopHeader";
import InputItem from '../../components/ui/InputItem';
import SelectItem from '../../components/ui/SelectItem';
import { showError, showSuccess, isErrorTxt, isInvalidEmail, checkTxtLength, currency } from '../../constants/Utils';
import { Divider, Spin } from 'antd';
export default class LeadsAddEdit extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            lead_id: this.props.match.params.id,
            project_title: '',
            name: '',
            description: '',
            phone_number: '',
            email: '',
            notes: '',
            loader: true,
            expected_amount: '',
            quote: '',
            currency:'',
            add_edit_loader: false,

            statusData: [
                { key: 'converted', value: 'Converted' },
                { key: 'hold', value: 'Hold' },
                { key: 'rejected', value: 'Rejected' },
                { key: 'pending', value: 'Pending' },

            ], 
            status:'pending',
            currencydata:[
                {key:'inr',value:'INR'},
                { key: 'eur', value: 'EUR' },
                { key: 'usd', value: 'USD' },
                { key: 'cad', value: 'CAD' }

            ],
          
        };

    }
    componentDidMount = () => {
        if ( this.state.lead_id ) {
            this.leadDetail();
        }
    };

    leadDetail = () => {
        const { lead_id } = this.state;
        Apicall.get( 'leads/' + lead_id, ( response ) => {
           
            let data = response?.data;
            let amount = data?.amount;
            if ( response.success ) {
                this.setState( {
                    name: data.client_name,
                    description: data.description,
                    phone_number: data?.phone?.national_number,
                    project_title: data?.project_name,
                    email: data?.email,
                    notes: data?.notes,
                    expected_amount: amount?.expected,
                    quote:amount?.quote,
                    currency: amount?.currency,
                    status:data?.status,
                    loader: false,
                } );
            }
        } );
    };
    handleChnage = ( event ) => {
        let name = event.target.name;
        let value = event.target.value;

        if ( value.length <= 250 ) {
            this.setState( {
                [ name ]: value
            } );
        }

    };
    handleStatusChange = ( e ) => {
        this.setState( {
            status: e
        } );
    };
    handleNumberChange = ( event ) => {

        let REGEX = /^([0-9])+$/;
        if ( event.target.value === "" || REGEX.test( event.target.value ) )
            this.setState( { [ event.target.name ]: event.target.value } );
    };
    goBack = () => {
        // this.props.history.push( '/leads' );
        this.props.history.goBack();

    };
    handleCurrencyChange=(e)=>{
       this.setState({
        currency:e,
       })
    }
    submit = () => {
        const { project_title, name, phone_number, email, notes, description, lead_id, currency, expected_amount,quote,status } = this.state;
        if ( isErrorTxt( name ) ) {
            showError( 'Please enter the client name' );
        } else if ( isErrorTxt( project_title ) ) {
            showError( 'Please enter the project name' );
        } else if ( isErrorTxt( email ) ? '' : isInvalidEmail( email ) ) {
            showError( 'Please enter valid email address' );
        } else if ( isErrorTxt( phone_number ) && isErrorTxt( email ) ) {
            showError( 'Please enter the email or phone number' );
        } else if ( isErrorTxt( phone_number ) ? '' : checkTxtLength( phone_number, 10 ) ) {
            showError( "Please enter 10 digits phone number" );

        } else {
            var data = {
                amount:{
                    currency:currency,
                    expected:parseInt(expected_amount),
                    quote:parseInt(quote)
                },
                
                phone: {
                    country_code: "91",
                    national_number: phone_number
                },
                client_name: name,
                project_name: project_title,
                description: description,
                email: email,
                notes: notes,
                status:status
            };
            this.setState( { add_edit_loader: true } );

            if ( lead_id ) {
                Apicall.patch( 'leads/' + lead_id, data, ( response ) => {
                    if ( response.success ) {
                        showSuccess(response.message );
                        this.props.history.push( '/leads' );
                        this.setState( { add_edit_loader: false } );

                    } else {
                        showError( response.message );
                    }
                } );
            } else {
                Apicall.post( 'leads/', data, ( response ) => {
                    if ( response.success ) {
                        showSuccess( response.message );
                        this.props.history.push( '/leads' );
                        this.setState( { add_edit_loader: false } );

                    } else {
                        showError( response.message );
                    }
                } );
            }

        }

    };

    render () {
        const { project_title, name, description, phone_number, email, notes, lead_id, loader, expected_amount, status, statusData, quote, currency, currencydata } = this.state;
       
        return (
            <div>
                {lead_id && loader ? ( <div className='tableview'>
                    <Spin />
                </div> ) : (
                    <div>
                        <TopHeader
                            title="Leads"
                            backClick={this.goBack}
                            submit={this.submit}
                            id={lead_id}
                            spin={this.state.add_edit_loader}

                        />

                        <div className='main-content2 mb-5'>
                            <Divider orientation="left">Basic Details</Divider>
                            <div className="nameCol">
                                <div className='col'>
                                    <InputItem label="Client Name" name='name' placeholder="Enter the client name" onChange={this.handleChnage} value={name} star={true} />

                                </div>
                                <div className='col'>
                                    <InputItem label="Project Name" name='project_title' placeholder="Enter the project title" onChange={this.handleChnage} star={true} value={project_title} />

                                </div>
                                <div className='col'>
                                    <InputItem label="Email" name='email' placeholder="Enter the email" onChange={this.handleChnage} value={email} />

                                </div>

                            </div>
                            <div className="nameCol">
                                <div className='col'>
                                    <InputItem label="Phone" name='phone_number' placeholder="Enter the phone number" phone={true} onChange={this.handleNumberChange} value={phone_number} />

                                </div>
                                <div className='col'>
                                    <InputItem label="Quote" name='quote' placeholder="Enter the quote" phone={true} onChange={this.handleNumberChange} value={quote} />

                                </div>
                                <div className='col'>

                                    <InputItem label="Expected" name='expected_amount' placeholder="Enter the expected amount" phone={true} onChange={this.handleNumberChange} value={expected_amount} />
                                </div>

                            </div>
                                <div className='name-col'>
                                    <div className='col col-describe'>
                                        <SelectItem label='Status' star={false} select={true} disabled={lead_id ?false:true} value={status} placeholder="Select by" data={statusData} onChange={this.handleStatusChange} />
                                </div>
                                <div className='col col-describe'>
                                      <SelectItem label="Currency" star={false} value={currency} placeholder="Select by" data={currencydata} onChange={this.handleCurrencyChange} />
                                </div>
                                


                            </div>
                            <div className="nameCol">
                                <div className='col col-describe'>
                                    <InputItem label="Description" name='description' placeholder="Enter the description" onChange={this.handleChnage} value={description} txtarea={true} height={true} />

                                </div>
                               

                            </div>
                            <Divider orientation="left">Notes</Divider>
                            <div className='col'>
                                <InputItem label="Notes" name='notes' placeholder="Enter your notes" onChange={this.handleChnage} txtarea={true} value={notes} height={true} notes={true} />
                            </div>
                        </div>
                    </div >
                )}

            </div>
        );
    }
}
