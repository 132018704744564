import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BsArrowUpRight, BsArrowDownLeft } from "react-icons/bs";
import { Select, Tabs, Row, Col, Spin } from "antd";
import APIGATEWAY from "../ApiService/ApiGateWay/apiGateWay";
import "../stylesheets/styles.css";
import { Tooltip } from "antd";
import "antd/dist/antd.css";
import { useState } from "react";
import { Input, Drawer, Dropdown, Space, Empty, message } from "antd";
import close from "../assets/emp_close.svg";

import {
  showShortDateFormat,
  showLongDateFormat,
  showDay,
  showday,
  showTime,
  numberWithComma,
  showFullDate,
  // checkInfinity,
  isErrorTxt,
  isErrorNum,
  daysCount,
} from "../constants/Utils";
import LineChart from "../components/ui/Chart";
import SidePopup from "./sidepopup";
import { DASHBOARDACCESS } from "../components/ui/constants";

const { Option } = Select;
class Dashboard extends Component {
  constructor() {
    super();
    this.myRef = React.createRef();
    this.state = {
      dash: "",
      currentPage: 1,
      pageSize: 10,
      total: "",
      loading: true,
      all_revenue: 0,
      totalEmployees: "",
      activeEmployees: "",
      pendingRequests: "",
      totalClients: "",
      overallSalary: "",
      totalProjects: "",
      activeProjects: "",
      lastMonth: "",
      graphType: "Expense",
      thismonth: true,
      prevmonth: "",
      selectType: "thisyear",
      data: {},
      this_month: "",
      last_month: "",
      this_year: "",
      previous_expenses: "",
      current_expenses: "",
      current_expenses1: "",
      month: "",
      expense_percentage: 0,
      previous_invoices: "",
      current_invoices: "",
      current_invoices1: "",
      invoice_percentage: 0,
      show: true,
      visible: false,
      expenses: [],
      invoices: [],
      from: "",
      to: "",
      office_leave: [],
      upcome_leave: [],
      upcome_birthdays: [],
      upcomingAllBirthday: [],
      upcome_anniversaries: [],
      upcomingAllAnniversaries: [],
      today_leave: [],
      today_wfh: [],
      upcome_permission: [],
      total_expenses: "",
      total_invoices: "",
      graph_dashboard: [],
      graph_date: [],
      series: [],
      options: {},
      year_graph: false,
      series1: [],
      options1: {},
      upcomeEmployeeType: "",
      chartData: {},
      graph_show: true,
      revenue: 0,
      loading: true,
      data_expense: "",
      data_invoice: "",
      profile: "",
      all_years: "",
      overall: "",
      nextReviews: [],
      dash_loader: true,
      isPopupOpen: false,
      openDrawer: false,
    };
  }

  searchString = "upcoming_review";

  componentDidMount = () => {
    this.handleDashboard();
  };

  handleDashboard = () => {
    APIGATEWAY.get("dashboard", (response) => {
      if (response.success) {
        let res = response?.data;

        let overall = Math.round(res?.totalSalary);
        this.setState({
          totalEmployees: res.employeesCount,
          activeEmployees: res.activeEmployees,
          pendingRequests: res.pendingLeaveResquest,
          totalClients: res.clientCount,
          totalProjects: res.projects_count,
          activeProjects: res.activeProject,
          overallSalary: numberWithComma(res?.totalSalary),
          data: res.leaves,
          office_leave: res.upcomingOfficeLeave,
          upcome_permission: res.upcomingPermission,
          upcome_leave: res?.upcomingLeave ?? "",
          today_leave: res.todayLeave,
          today_wfh: res.todayWFH,
          total_expenses: res.totalExpense,
          total_invoices: res.totalInvoice,
          profile: res.profile,
          nextReviews: res.nextReviews,
          upcome_birthdays: res.upcomingBirthdays,
          upcome_anniversaries: res.upcomingAnniversary,
          loading: false,
        });
      }
    });
  };

  getUpcomingBirthday = () => {
    return new Promise((resolve, reject) => {
      APIGATEWAY.get("dashboard/birthdays", (response) => {
        if (response.success) {
          let res = response?.data.birthdays;
          this.setState({
            upcomingAllBirthday: res,
          });
          resolve(res); // Resolve the promise with the result
        } else {
          reject(new Error("API request failed")); // Reject the promise with an error
        }
      });
    });
  };
  getUpcomingAnniversery = () => {
    return new Promise((resolve, reject) => {
      APIGATEWAY.get("dashboard/anniversary", (response) => {
        if (response.success) {
          let res = response?.data.anniversary;
          this.setState({
            upcomingAllAnniversaries: res,
          });
          resolve(res); // Resolve the promise with the result
        } else {
          reject(new Error("API request failed")); // Reject the promise with an error
        }
      });
    });
  };

  monthHandler = (value) => {
    this.setState({ selectType: value });
  };
  setDashboard = (data) => {
    const { selectType } = this.state;
    this.setState({ overall: data });
    let invoicePercentage =
      ((data?.previousInvoice - data?.currentInvoice) / data?.currentInvoice) *
      100;
    let expensePercentage =
      ((data?.previousExpense - data?.currentExpense) / data?.currentExpense) *
      100;
    if (selectType === "All") {
      // this.setState({ all_years: data });
      this.setState({
        all_revenue: numberWithComma(
          data?.currentInvoice - data?.currentExpense
        ),
        previous_invoices: numberWithComma(data?.previousInvoice),
        previous_expenses: numberWithComma(data?.previousExpense),
        current_invoices: numberWithComma(data?.currentInvoice),
        current_expenses: numberWithComma(data?.currentExpense),
      });
    } else if (selectType === "thisMonth") {
      this.setState({
        dash_loader: false,
        all_revenue: numberWithComma(
          data?.currentInvoice - data?.currentExpense
        ),
        expense_percentage: isErrorNum(expensePercentage)
          ? 0
          : Math.round(expensePercentage),
        invoice_percentage: isErrorNum(invoicePercentage)
          ? 0
          : Math.round(invoicePercentage),
        previous_invoices: isErrorNum(data?.previousInvoice)
          ? 0
          : data.previousInvoice,
        previous_expenses: isErrorNum(data?.previousExpense)
          ? 0
          : data.previousExpense,
        current_invoices: isErrorNum(data?.currentInvoice)
          ? 0
          : data.currentInvoice,
        current_expenses: isErrorNum(data?.currentExpense)
          ? 0
          : data.currentExpense,
      });
    } else if (selectType === "Previous") {
      this.setState({
        dash_loader: false,
        all_revenue: numberWithComma(
          data?.previousInvoice - data?.previousExpense
        ),
        expense_percentage: isErrorNum(expensePercentage)
          ? 0
          : Math.round(expensePercentage),
        invoice_percentage: isErrorNum(invoicePercentage)
          ? 0
          : Math.round(invoicePercentage),
        previous_invoices: isErrorNum(data?.previousInvoice)
          ? 0
          : data.previousInvoice,
        previous_expenses: isErrorNum(data?.previousExpense)
          ? 0
          : data.previousExpense,
        current_invoices: isErrorNum(data?.currentInvoice)
          ? 0
          : data.currentInvoice,
        current_expenses: isErrorNum(data?.currentExpense)
          ? 0
          : data.currentExpense,
      });
    } else if (selectType === "thisyear") {
      this.setState({
        all_revenue: numberWithComma(
          data?.currentInvoice - data?.currentExpense
        ),
        previous_invoices: isErrorNum(data?.previousInvoice)
          ? 0
          : data.previousInvoice,
        previous_expenses: isErrorNum(data?.previousExpense)
          ? 0
          : data.previousExpense,
        current_invoices: isErrorNum(data?.currentInvoice)
          ? 0
          : data.currentInvoice,
        current_expenses: isErrorNum(data?.currentExpense)
          ? 0
          : data.currentExpense,
      });
    }
  };

  onChange = (string) => {
    this.setState({ upcomeEmployeeType: Number(string) });
  };
  employerClick = (type) => {
    if (type === "employee") {
      this.props.history.push("/employees?limit=10&page=1&status=active");
    } else if (type === "invoices") {
      this.props.history.push("/invoices");
    } else if (type === "expenses") {
      this.props.history.push("/expenses");
    }
  };
  togglePopup = () => {
    if (
      this.state.upcomeEmployeeType === 1 ||
      this.state.upcomeEmployeeType === ""
    ) {
      this.getUpcomingBirthday();
    } else this.getUpcomingAnniversery();
    // this.setState({ openDrawer: true }, () => {});
    this.setState({ openDrawer: true });
  };
  closeDrawer() {
    return new Promise((resolve, reject) => {
      this.setState({ openDrawer: false });
      resolve(true);
    });
  }

  rev = () => {
    const { selectType, overall } = this.state;
    if (selectType === "All") {
      this.setState({
        all_revenue: numberWithComma(
          overall?.currentInvoice - overall?.currentExpense
        ),
      });
    } else if (selectType === "thisMonth") {
      this.setState({
        all_revenue: numberWithComma(
          overall?.currentInvoice - overall?.currentExpense
        ),
      });
    } else if (selectType === "Previous") {
      this.setState({
        all_revenue: numberWithComma(
          overall?.previousInvoice - overall?.previousExpense
        ),
      });
    } else if (selectType === "thisyear") {
      this.setState({
        all_revenue: numberWithComma(
          overall?.currentInvoice - overall?.currentExpense
        ),
      });
    }
  };
  render() {
    const {
      activeEmployees,
      overallSalary,
      office_leave,
      upcome_permission,
      upcome_leave,
      upcome_birthdays,
      upcome_anniversaries,
      today_leave,
      today_wfh,
      selectType,
      profile,
      all_years,
      nextReviews,
      overall,
      all_revenue,
      expense_percentage,
      invoice_percentage,
      previous_invoices,
      current_invoices,
      upcomeEmployeeType,
      previous_expenses,
      current_expenses,
      // upcomingAllAnniversaries,
      // upcomingAllBirthday
    } = this.state;

    numberWithComma(overall?.currentInvoice - overall?.currentExpense);

    let expense_year_total = Math.round(
      ((overall?.previousExpense - overall?.currentExpense) /
        overall?.previousExpense) *
        100
    );

    if (isFinite(expense_year_total)) {
      expense_year_total = 0;
    }

    const expense_year_total_color =
      overall?.currentExpense > overall?.previousExpense;
    const invoice_month_value =
      overall?.currentInvoice > overall?.previousInvoice;

    return (
      <div>
        {this.state.loading ? (
          <div className="spinning-star">
            <Spin size="large" />
          </div>
        ) : (
          <div style={{ padding: "28px 23px 28px 30px" }}>
            <div className="dashboard-header-row">
              <div>
                <p className="welcome">Welcome {profile?.name ?? "Admin"} </p>
                <p className="last-login">
                  Last Activity {showFullDate(profile?.last_login)}
                </p>
              </div>
              <div className="upcome-view">
                <span>
                  <Select
                    className="dashboard-dropdown-graph"
                    value={this.state.selectType}
                    onChange={this.monthHandler}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <Option value="All">All</Option>
                    <Option value="thisMonth">This Month</Option>
                    <Option value="Previous">Previous Month</Option>
                    <Option value="thisyear">This Year</Option>
                  </Select>
                </span>
              </div>
            </div>
            <Row className="admin-dash-board">
              <Col
                sm={6}
                className="admin-dash-col"
                onClick={() => this.employerClick("employee")}
              >
                <div className="employee-col">
                  <p className="employee-title-rev">Overall Employees</p>
                  <p className="employee-count-rev">
                    {activeEmployees ? activeEmployees : 0}
                  </p>
                  <p className="employee-years">
                    Overall Salary {overallSalary ?? 0}
                  </p>
                </div>
              </Col>

              <Col
                sm={6}
                className="admin-dash-col"
                onClick={() => this.employerClick("invoices")}
              >
                <div className="employee-col">
                  <div className="employee-col-sm">
                    <p className="employee-title-rev">Invoices</p>
                    {this.state.dash_loader &&
                    (selectType === "thisMonth" ||
                      selectType === "Previous") ? (
                      <div className="dash_loader"></div>
                    ) : (
                      <div>
                        {selectType === "All" || selectType === "thisyear" ? (
                          ""
                        ) : (
                          <span
                            className={
                              invoice_month_value
                                ? "employee-percent-success"
                                : "employee-percent"
                            }
                          >
                            <>
                              <Tooltip
                                style={{
                                  marginLeft: 70,
                                  whiteSpace: "nowrap",
                                }}
                                placement="right"
                                title={`Comparing the last invoice of ${
                                  numberWithComma(previous_invoices) ?? 0
                                } with current invoice of ${numberWithComma(
                                  current_invoices ?? 0
                                )}`}
                              >
                                <span>
                                  <span style={{ marginRight: "10px" }}>
                                    {invoice_month_value ? (
                                      <BsArrowUpRight />
                                    ) : (
                                      <BsArrowDownLeft />
                                    )}
                                  </span>
                                  {isErrorNum(invoice_percentage)
                                    ? 0
                                    : Math.abs(invoice_percentage)}
                                  <span style={{ marginLeft: "10px" }}>%</span>
                                </span>
                              </Tooltip>
                            </>
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  <p className="employee-count-rev">
                    {overall &&
                      (selectType === "All"
                        ? numberWithComma(overall.currentInvoice)
                        : selectType === "thisMonth"
                        ? overall && numberWithComma(overall.currentInvoice)
                        : selectType === "Previous"
                        ? overall && numberWithComma(overall.previousInvoice)
                        : overall && numberWithComma(overall.currentInvoice))}
                  </p>

                  <p className="employee-years">
                    {overall?.invoiceCount ?? 0} Invoices Sent{" "}
                    {selectType === "All"
                      ? ""
                      : selectType === "thisMonth"
                      ? "This Month"
                      : selectType === "Previous"
                      ? "Last Month"
                      : selectType === "thisyear" && "This Year"}
                  </p>
                </div>
              </Col>
              <Col
                sm={6}
                className="admin-dash-col"
                onClick={() => this.employerClick("expenses")}
              >
                <div className="employee-col">
                  <div className="employee-col-sm">
                    <p className="employee-title-rev">Expenses</p>
                    {this.state.dash_loader &&
                    (selectType === "thisMonth" ||
                      selectType === "Previous") ? (
                      <div className="dash_loader"></div>
                    ) : (
                      <div>
                        {selectType === "All" || selectType === "thisyear" ? (
                          ""
                        ) : (
                          <p>
                            <Tooltip
                              style={{
                                marginLeft: 70,
                                whiteSpace: "nowrap",
                              }}
                              placement="right"
                              title={`Comparing the last expense of ${
                                numberWithComma(previous_expenses) ?? 0
                              } with current expense of ${
                                numberWithComma(current_expenses) ?? 0
                              }`}
                            >
                              <span
                                className={
                                  expense_year_total_color
                                    ? "employee-percent"
                                    : "employee-percent-success"
                                }
                              >
                                <span style={{ marginRight: "10px" }}>
                                  {expense_year_total_color ? (
                                    <BsArrowUpRight />
                                  ) : (
                                    <BsArrowDownLeft />
                                  )}
                                </span>
                                {/* {isFinite(isFinite(expense_year_total)) ? 0 : Math.abs(parseFloat(expense_year_total))} */}
                                <span>
                                  {isErrorNum(expense_percentage)
                                    ? 0
                                    : Math.abs(expense_percentage)}
                                </span>
                                <span style={{ marginLeft: "10px" }}>%</span>
                              </span>
                            </Tooltip>
                          </p>
                        )}
                      </div>
                    )}
                  </div>

                  <p className="employee-count-rev">
                    {overall &&
                      (selectType === "All"
                        ? numberWithComma(overall.currentExpense)
                        : selectType === "thisMonth"
                        ? overall && numberWithComma(overall.currentExpense)
                        : selectType === "Previous"
                        ? overall && numberWithComma(overall.previousExpense)
                        : overall && numberWithComma(overall.currentExpense))}
                  </p>

                  <p className="employee-years">
                    {overall?.expenseCount && !isErrorTxt(overall.expenseCount)
                      ? overall.expenseCount
                      : 0}{" "}
                    {selectType === "All"
                      ? "Expense Made"
                      : selectType === "thisMonth"
                      ? "This Month"
                      : selectType === "Previous"
                      ? "Last Month"
                      : selectType === "thisyear" && "This Year"}
                  </p>
                </div>
              </Col>
              <Col sm={6} className="admin-dash-col">
                <div className="employee-col">
                  <p className="employee-title-rev">Revenue</p>

                  <p className="employee-count-rev">{all_revenue}</p>

                  <p className="employee-years">
                    {selectType === "All"
                      ? "Revenue"
                      : selectType === "thisMonth"
                      ? "This Month"
                      : selectType === "Previous"
                      ? "Last Month"
                      : selectType === "thisyear" && "This Year"}
                  </p>
                </div>
              </Col>
            </Row>

            <div className="d-flex mt-4">
              <div
                className="col ml-0 mr-4 border rounded-border overflow-auto upcoming-leaves"
                style={{ height: "400px" }}
              >
                <div className="flex-content pb-5">
                  <p className="upcome-leave">Upcoming Leaves</p>
                  <Link
                    to={{
                      pathname: "/holidays",
                      state: {
                        searchString: "uc",
                      },
                    }}
                    className="upcome-view"
                  >
                    View All
                  </Link>
                </div>
                {office_leave.length > 0 ? (
                  <div style={{ position: "relative" }}>
                    {office_leave.map((item, o) => (
                      <div key={o}>
                        <div className="flex-content">
                          <div className="flex-content">
                            <div
                              style={{ width: "123px", marginBottom: "-20px" }}
                            >
                              <p className="mb-2 pb-2 dashboard-festival">
                                {item.name}{" "}
                              </p>
                              <p className="mb-1 dash-day">{item.day} </p>
                            </div>
                            <div className="dashboard-border-leave"></div>
                          </div>
                          <div>
                            <p className="m-0 dash-date">
                              {showLongDateFormat(item.date)}
                            </p>
                          </div>
                        </div>
                        <hr className=" mb-05" />
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-muted nodata">No Data Found</p>
                )}
              </div>
              <div
                className="col mr-4 upcoming-leaves border rounded-border overflow-auto"
                style={{ height: "400px" }}
              >
                <div className="flex-content pb-3">
                  <p className="upcome-leave">Upcoming Employee Leaves</p>
                  <Link
                    className="upcome-view"
                    to={{
                      pathname:
                        this.state.upcomeEmployeeType === 1 ||
                        this.state.upcomeEmployeeType === ""
                          ? "/leaves"
                          : "/permissions",
                      state: {
                        searchString: "uc",
                      },
                    }}
                  >
                    View All
                  </Link>
                </div>

                <Tabs
                  defaultActiveKey="1"
                  onChange={(e) => this.onChange(e)}
                  tabBarStyle={{ color: "#757575" }}
                >
                  <Tabs.TabPane tab="Leaves" key="1">
                    {upcome_leave && upcome_leave.length > 0 ? (
                      <div style={{ position: "relative" }}>
                        {upcome_leave &&
                          upcome_leave.map((item, l) => (
                            <div
                              style={{ overflow: "auto", marginTop: "10px" }}
                            >
                              <div className="flex-content ">
                                <div className="flex-content">
                                  <div>
                                    <p className="dashboard-employee-name">
                                      {item.name}{" "}
                                    </p>
                                    <p className="mb-1 dash-empid">
                                      {item.official_emp_id}{" "}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <p className="pb-2 dash-emp-day">
                                    {showDay(item?.date?.from)}
                                  </p>
                                  <p className="mb-1 dash-emp-date">
                                    {daysCount(
                                      new Date(item?.date?.from),
                                      new Date(item?.date?.to)
                                    ) +
                                      1 >
                                    1
                                      ? `${showShortDateFormat(
                                          item?.date?.from
                                        )} - ${showLongDateFormat(
                                          item?.date?.to
                                        )}`
                                      : showLongDateFormat(item?.date?.from)}
                                  </p>
                                </div>
                              </div>
                              {upcome_leave.length === l ? (
                                ""
                              ) : (
                                <hr className=" mb-05" />
                              )}
                            </div>
                          ))}
                      </div>
                    ) : (
                      <p className="text-muted nodata mt-70">No Data Found</p>
                    )}
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Permissions" key="2">
                    {upcome_permission.length > 0 ? (
                      <>
                        {upcome_permission.map((item, p) => (
                          <div style={{ position: "relative" }} key={p}>
                            <div className="flex-content">
                              <div className="flex-content">
                                <div>
                                  <p className=" dashboard-employee-name">
                                    {item.name}{" "}
                                  </p>
                                  <p className="mb-1 dash-empid">
                                    {item.official_emp_id}{" "}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <p className="pb-2 dash-emp-day">{`${showDay(
                                  item?.hours?.from
                                )} (${showTime(item?.hours?.from)} - ${showTime(
                                  item?.hours?.to
                                )})`}</p>
                                <p className="mb-1 dash-emp-date">{`${showShortDateFormat(
                                  item?.hours?.from
                                )} - ${showLongDateFormat(
                                  item?.hours?.to
                                )}`}</p>
                              </div>
                            </div>
                            <hr className=" mb-05" />
                          </div>
                        ))}
                      </>
                    ) : (
                      <p className="text-muted nodata mt-70">No Data Found</p>
                    )}
                  </Tabs.TabPane>
                </Tabs>
              </div>

              <div
                className="col upcoming-leaves border rounded-border overflow-auto"
                style={{ height: "400px" }}
              >
                <div className="flex-content pb-3">
                  <p className="upcome-leave">Today's Status</p>
                </div>

                <Tabs
                  defaultActiveKey="1"
                  onChange={(e) => this.onChange(e)}
                  tabBarStyle={{ color: "#757575" }}
                >
                  <Tabs.TabPane tab="Leaves" key="1">
                    {today_leave.length > 0 ? (
                      <div style={{ position: "relative" }}>
                        {today_leave.map((item, l) => (
                          <div
                            style={{ overflow: "auto", marginTop: "10px" }}
                            key={l}
                          >
                            <div className="flex-content">
                              <div className="flex-content">
                                <div>
                                  <p className=" dashboard-employee-name">
                                    {item.name}
                                  </p>
                                  <p className="mb-1 dash-empid">
                                    {item.official_emp_id}{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {today_leave.length === l ? (
                              ""
                            ) : (
                              <hr className=" mb-05" />
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="mt-70 text-muted nodata">No Data Found</p>
                    )}
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="WFH" key="2">
                    {today_wfh.length > 0 ? (
                      <>
                        {today_wfh.map((item, l) => (
                          <div style={{ position: "relative" }} key={l}>
                            <div className="flex-content">
                              <div className="flex-content">
                                <div>
                                  <p className=" dashboard-employee-name">
                                    {item.name}{" "}
                                  </p>
                                  <p className="mb-1 dash-empid">
                                    {item.official_emp_id}{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {today_wfh.length === l ? (
                              ""
                            ) : (
                              <hr className=" mb-05" />
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      <p className="mt-70 text-muted nodata">No Data Found</p>
                    )}
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>

            <div className="mt-4 d-flex">
              <div
                className="dashboard-graph-col border upcoming-leaves mr-4 rounded-border"
                style={{ width: "70%" }}
              >
                <LineChart
                  datas={this.setDashboard}
                  type={this.state.selectType}
                />
              </div>

              <div className="bir_rev_div">
                <div
                  className="upcoming-leaves border rounded-border overflow-auto"
                  style={{ height: "356px" }}
                >
                  <div className="flex-content ">
                    <p className="upcome-leave">Upcoming Events</p>
                    <Link
                      className="upcome-view pointer"
                      onClick={this.togglePopup}
                    >
                      View All
                    </Link>
                  </div>

                  <Space>
                    <Drawer
                      placement="right"
                      open={this.state.openDrawer}
                      onClose={() => this.closeDrawer()}
                      className="tax-main-drawer"
                    >
                      <div className="drawer-head mb-3">
                        <span className="add-tax">
                          {upcomeEmployeeType === 1 || upcomeEmployeeType === ""
                            ? "Upcoming Birthdays"
                            : "Upcoming Anniversary"}
                        </span>
                        <span
                          onClick={() => this.closeDrawer()}
                          className="tax-close"
                        >
                          <img src={close} alt="close" />
                        </span>
                      </div>
                      {upcomeEmployeeType === 1 || upcomeEmployeeType === "" ? (
                        <>
                          {this.state.upcomingAllBirthday.length ? (
                            <>
                              {this.state.upcomingAllBirthday.map((item, i) => (
                                <div
                                  style={{
                                    overflow: "auto",
                                    padding: "0px 25px",
                                  }}
                                  key={i}
                                >
                                  <div className="flex-content">
                                    <div className="flex-content">
                                      <div>
                                        <p className=" dashboard-employee-name">
                                          {item.name}{" "}
                                        </p>
                                        <p className="mb-1 dash-empid">
                                          {item.emp_id}{" "}
                                        </p>
                                      </div>
                                    </div>
                                    <div>
                                      <p className="pb-2 dash-emp-day">
                                        {showday(item?.date ? item.date : "-")}
                                      </p>
                                      <p className="mb-1 dash-emp-date">
                                        {`${showShortDateFormat(
                                          item?.date ?? "-"
                                        )} 
                                  `}
                                      </p>
                                    </div>
                                  </div>
                                  {this.state.upcomingAllBirthday.length ===
                                  i ? (
                                    ""
                                  ) : (
                                    <hr className=" mb-05" />
                                  )}
                                </div>
                              ))}
                            </>
                          ) : (
                            <p className="mt-70 text-muted nodata">
                              No Data Found
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          {this.state.upcomingAllAnniversaries.length ? (
                            <>
                              {this.state.upcomingAllAnniversaries.map(
                                (item, i) => (
                                  <div
                                    style={{
                                      overflow: "auto",
                                      padding: "0px 25px",
                                    }}
                                    key={i}
                                  >
                                    <div className="flex-content">
                                      <div className="flex-content">
                                        <div>
                                          <p className=" dashboard-employee-name">
                                            {item.name}{" "}
                                          </p>
                                          <p className="mb-1 dash-empid">
                                            {item.emp_id}{" "}
                                          </p>
                                        </div>
                                      </div>
                                      <div>
                                        <p className="pb-2 dash-emp-day">
                                          {showday(
                                            item?.date ? item.date : "-"
                                          )}
                                        </p>
                                        <p className="mb-1 dash-emp-date">
                                          {`${showShortDateFormat(
                                            item?.date ?? "-"
                                          )} 
                               `}
                                        </p>
                                      </div>
                                    </div>
                                    {this.state.upcomingAllAnniversaries
                                      .length === i ? (
                                      ""
                                    ) : (
                                      <hr className="mt-4 mb-4" />
                                    )}
                                  </div>
                                )
                              )}
                            </>
                          ) : (
                            <p className="mt-70 text-muted nodata">
                              No Data Found
                            </p>
                          )}
                        </>
                      )}
                    </Drawer>
                  </Space>

                  <Tabs
                    defaultActiveKey="1"
                    onChange={(e) => this.onChange(e)}
                    tabBarStyle={{ color: "#757575" }}
                  >
                    <Tabs.TabPane tab="Birthdays" key="1">
                      {upcome_birthdays.length > 0 ? (
                        <div style={{ position: "relative" }}>
                          {upcome_birthdays.map((item, l) => (
                            <div
                              style={{ overflow: "auto", marginTop: "10px" }}
                              key={l}
                            >
                              <div className="flex-content">
                                <div className="flex-content">
                                  <div>
                                    <p className=" dashboard-employee-name">
                                      {item.name}{" "}
                                    </p>
                                    <p className="mb-1 dash-empid">
                                      {item.emp_id}{" "}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <p className="pb-2 dash-emp-day">
                                    {showday(item?.date ? item.date : "-")}
                                  </p>
                                  <p className="mb-1 dash-emp-date">
                                    {`${showShortDateFormat(item?.date ?? "-")} 
                                  `}
                                  </p>
                                </div>
                              </div>
                              {upcome_birthdays.length === l ? (
                                ""
                              ) : (
                                <hr className="mb-05" />
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className=" mt-70 text-muted nodata">
                          No Data Found
                        </p>
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Employee Anniversary" key="2">
                      {upcome_anniversaries.length > 0 ? (
                        <>
                          {upcome_anniversaries.map((item, p) => (
                            // <div style={{ position: "relative" }}>
                            <div
                              style={{ overflow: "auto", marginTop: "10px" }}
                              key={p}
                            >
                              <div className="flex-content">
                                <div className="flex-content">
                                  <div>
                                    <p className="dashboard-employee-name">
                                      {isErrorTxt(item.name)
                                        ? "-"
                                        : item.name ?? "-"}{" "}
                                    </p>
                                    <p className=" dashboard-employee-name">
                                      {isErrorTxt(item.year)
                                        ? "0"
                                        : `${item.year} Anniversary`}{" "}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <p className="pb-2 dash-emp-day">
                                    {isErrorTxt(item?.emp_id)
                                      ? "-"
                                      : item.emp_id}
                                  </p>
                                  <p className="pb-1 dash-emp-day">
                                    {`${showShortDateFormat(
                                      item?.date ?? "-"
                                    )}`}
                                    <span className="ml-1">
                                      ({`${showday(item?.date ?? "-")} `})
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <hr className=" mb-05" />
                            </div>
                          ))}
                        </>
                      ) : (
                        <p className=" mt-70 text-muted nodata">
                          No Data Found
                        </p>
                      )}
                    </Tabs.TabPane>
                  </Tabs>
                </div>

                <div
                  className="border rounded-border overflow-auto"
                  style={{
                    height: "354px",
                    marginTop: "1.5rem",
                    position: "relative",
                    padding: "24px",
                  }}
                >
                  <div>
                    <div className="flex-content">
                      <p className="upcome-leave">
                        Upcoming Reviews{" "}
                        <span style={{ marginLeft: 10 }}>
                          {nextReviews?.length > 0 ? nextReviews?.length : ""}
                        </span>
                      </p>
                      <Link
                        to={{
                          pathname: "/employees",
                          state: {
                            searchString: this.searchString,
                          },
                        }}
                        className="upcome-view pointer "
                      >
                        View All
                      </Link>
                    </div>
                    {nextReviews.length > 0 ? (
                      <div className="upcoming-view mt-20">
                        {nextReviews.map((item, w) => (
                          <div key={w}>
                            <div className="flex-sb" >
                              <p className="dashboard-employee-wfh m_0">
                                {item.name.charAt(0).toUpperCase() +
                                  item.name.slice(1)}{" "}
                                <span>({item.official_emp_id})</span>{" "}
                              </p>
                              <div className="fs_12">
                                {showShortDateFormat(item?.next_review ?? "-")}
                              </div>
                            </div>
                            <hr className=" mb-05" />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="  text-muted nodata">No Data Found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Dashboard;
