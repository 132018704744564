import React, { Component } from "react";
import {
  Spin,
  Modal,
  Form,
  message,
  Divider,
  Col,
  Row,
} from "antd";
import TopHeader from "../../components/ui/TopHeader";
import DateItem from '../../components/ui/DateItem';
import InputItem from '../../components/ui/InputItem';
import "antd/dist/antd.css";
import "../../stylesheets/styles.css";
import Logo from "../../assets/logo_black.svg";
import APIGATEWAY from "../../ApiService/ApiGateWay/apiGateWay";
import {
  showError,
  returnFirstDegreeObjValue,
  returnSecondDegreeObjValue,
  getIsoString,
  utcdate,
  showCurrency,
  numberWithRupees,
  numberFormatWithCurrency,
  isErrorTxt,
  showDate
} from "../../constants/Utils";

export default class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      specification: [],
      Id: this.props.match.params.id,
      currentPage: 1,
      modal1Visible: false,
      paid: "",
      receivedAmount: "",
      notes: "",
      client_address: "",
      client: {},
      Taxes: [],
      currency: '',
      loading: true
    };
  }

  componentDidMount = () => {
    const { Id } = this.state;
    if (Id) {
      this.handleTable(Id);
    }

  };
  handleTable = (id) => {
    APIGATEWAY.get("invoices/" + id, (response) => {

      if (response.success) {
        let res = response.data;
        const invoice = res?.invoice
        this.setState(
          {
            data: invoice,
            client_address: invoice?.client?.client_address,
            client: invoice?.client,
            discount: invoice?.discount,
            specification: invoice?.items,
            Taxes: invoice?.tax.map(tax => {return {...tax, enable: true}}),
            currency: invoice?.client?.currency,
            loading: false,
          },
        );
      }
    });
  };
  back = () => {
    this.props.history.goBack();
  };
  setModal1Visible = (modal1Visible) => {
    this.setState({
      modal1Visible,
      paid: "",
      receivedAmount: this.state.data.total_amount,
    });
  };

  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  handleOnPaidChange = (e) => {
    this.updateStateValues("paid", e);
  };

  handleAmount = (e) => {
    this.setState({ receivedAmount: e.target.value });
  };

  handleNotes = (e) => {
    this.setState({ notes: e.target.value });
  };

  submitHandle = () => {
    let self = this;
    const { paid, receivedAmount, notes, data } = self.state;
    let id = self.state.Id;
    if (isErrorTxt(paid)) {
      showError("Please select date");
    } else if (isErrorTxt(receivedAmount)) {
      showError("Please enter received amount");

    } else {
      let data = {
        paid_date: getIsoString(paid),
        received_amount: this.state.data?.final_amount,
        received_notes: notes,
      };
      let status = 'paid';
      APIGATEWAY.patch("invoices/" + status + "/" + id, data, (response) => {
        if (response.success) {
          self.handleTable(id);
          message.success(response.message);
          this.setState({ modal1Visible: false, paid: "" });
        }
      });
    }
  };
  textContent = (name, content, val) => {
    return (
      <div className="name-wrapper">
        <div className="title-wrapper font-500">{name}</div>
        <div className="data-content text-capitalize">
          {val === 'date' ? (isErrorTxt(content) ? "-" : showDate(content)) : (isErrorTxt(content) ? "-" : content ?? "-")}
        </div>
      </div >
    );
  };
  render() {
    const {
      data,
      specification,
      notes,
      client_address,
      client,
      loading,
      Taxes,
    } = this.state;


    return (
      <div>
        <div>
          <TopHeader
            title="Invoice Details"
            backClick1={this.back}
            status={this?.state?.data?.status}
            updateStatus={() => this.setModal1Visible(true)}
            statusName="Paid"
          />
          {loading ? (<div className="tableview">
            <Spin />
          </div>) : (
            <div className="invoice_detail_container">
              <Row
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                }}
                className="invoice_block"
                style={{ marginLeft: "0px", marginRight: "0px" }}
              >
                <Col
                  className="gutter-row"
                  span={17}
                  style={{ marginBottom: "50px" }}
                >
                  <span className="Invoice_company_name">
                    Quadkast Private Ltd
                  </span>
                  <p className="Invoice_company_addr">
                    Plot 12C, 1, 3rd Cross St, South Phase, Guindy Industrial
                    Estate, <br /> Guindy, Chennai, Tamil Nadu 600032
                  </p>
                  <p>
                    GSTIN<span className="hypen">-</span>
                    <span style={{ fontWeight: 600 }}>33AAACQ4345B1ZZ</span>
                  </p>
                </Col>
                <Col className="gutter-row Invoice_company_logo" span={7}>
                  <div className="company_logo_block">
                    {/* <input
                      type="file"
                      accept="image/png"
                      onChange={(e) => this.imageUpload(e)}
                      className="invoice_img_input"
                    ></input> */}
                    <img src={Logo} />

                  </div>
                </Col>

                <Col span={12}>

                  {this.textContent("Project Name", data?.project?.name)}
                </Col>
                <Col span={12}>

                  {this.textContent("Client Name", data?.client?.name)}

                </Col>
                <Col span={12}>

                  {this.textContent("Due Date", data.due_date, 'date')}

                </Col>

                <Col span={12}>

                  {this.textContent("Invoice Date", data?.invoice_date, 'date')}

                </Col>
                <Col span={12}>

                  {this.textContent("GST No", data?.gst_number)}

                </Col>
                <Col span={12}>

                  {this.textContent("Invoice No", data?.invoice_number)}

                </Col>
                {data?.notes ? (
                  <Col span={12}>

                    {this.textContent("Notes", data?.notes)}

                  </Col>
                ) : (
                  ""
                )}
                <Col span={12}>

                  {this.textContent("Status", data?.status)}

                </Col>
                {data.status === "paid" ? (
                  <Col span={12}>

                    {this.textContent("Paid Date", data?.paid_date, 'date')}

                  </Col>
                ) : (
                  ""
                )}
                {data.status === "paid" && data.received_amount ? (
                  <Col span={12}>
                    <div className="name-wrapper">
                      <div className="title-wrapper font-500">
                        Received Amount
                      </div>
                      <div className="data-content">
                          <span className="m-right"> {showCurrency(client.currency)}</span>
                        {numberFormatWithCurrency(
                          data.received_amount,
                          client.currency
                        )}
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}

                {data.status === "paid" && data.received_notes ? (
                  <Col span={12}>

                    {this.textContent(" Received Notes", data?.received_notes)}

                  </Col>
                ) : (
                  ""
                )}

                <div className="invoice_addr_edit_block">
                  <Col className="gutter-row" span={22}>
                    <>

                      <p className="quadkast_comp_name">
                        {isErrorTxt(client.company_name) ? '' : client.company_name !== ""
                          ? client.company_name
                          : client.client_name
                            ? (client.client_name)
                            : ""}
                      </p>
                      {isErrorTxt(client_address?.address) ? '' : (
                        <>
                          <p className="quadkast_addr_detail long_addr_desc">
                            {isErrorTxt(client_address?.address?.address1) ? '' : `${client_address?.address?.address1}, `}
                            {isErrorTxt(client_address?.address?.address2) ? '' : `${client_address?.address?.address2.split(",")
                              .join(", ")}`}
                          </p>

                          <p className="quadkast_addr_detail">
                            {isErrorTxt(client_address?.city) ? '' : `${client_address?.city},`}
                            {isErrorTxt(client_address?.state) ? '' : `${client_address?.state},`}
                            {isErrorTxt(client_address?.country) ? '' : `${client_address?.country}`}
                            {isErrorTxt(client_address?.country) ? '' : client_address?.country && isErrorTxt(client_address?.zip_code) ? '' : client_address?.zip_code && ` - `}

                            {isErrorTxt(client_address?.zip_code) ? '' : `${client_address?.zip_code} `}
                          </p>
                        </>
                      )}
                      <p>
                        {isErrorTxt(data.gst_number) ? '' : client.currency === 'inr' && data.gst_number && data.gst_number !== "" ? (
                          <>
                            <p className="quadkast_addr_detail">
                              GSTIN<span className="hypen">-</span>
                              <span className="quadkast_gst">{data.gst_number}</span>
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                    </>
                  </Col>
                </div>
              </Row>

              <Divider orientation="left">Type Of Items</Divider>

              <div className="preview-table-wrap preview-detail-table-wrap">
                <div className="preview-table-responsive">
                  <table class="preview_table preview_detail_table table">
                    <thead>
                      <tr>
                        <th scope="col">Item & Description</th>
                        <th scope="col" style={{ textAlign: "end" }}>
                          Quantity
                        </th>
                        <th scope="col" style={{ textAlign: "end" }}>
                          Rate
                        </th>
                        <th scope="col" style={{ textAlign: "end" }}>
                          Amount
                        </th>
                      </tr>
                    </thead>
                    {specification.length > 0 ? (
                      <tbody>
                        {specification.map((item, i) => (
                          <tr>
                            <td scope="row">
                              <span className="preview_item_name">
                                {item.name ?? "-"}
                              </span>
                              <br></br>
                              <span className="preview_item_desc">
                                {item.description ?? "-"}
                              </span>
                            </td>
                            <td className="preview_item_common">
                              {item.quantity ?? "-"}
                            </td>
                            <td className="preview_item_common">
                              {item.rate ? (
                                <>

                                  <span className="m-right"> {showCurrency(client.currency)}</span>

                                  {numberFormatWithCurrency(
                                    item.rate,
                                    client.currency
                                  )}
                                </>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="preview_item_common">
                              {item.amount ? (
                                <>
                                  <span className="m-right"> {showCurrency(client.currency)}</span>

                                  {numberFormatWithCurrency(
                                    item.amount,
                                    client.currency
                                  )}
                                </>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>no data</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>

              <div className="Tax_Container" style={{ marginTop: "40px" }}>
                <div className="invoice_flex">
                  <div className="invoice_tax_amt_title">Sub Total</div>
                  <div className="default_color">
                      <span className="m-right"> {showCurrency(client?.currency)} </span>

                    {data.total_amount
                      ? numberFormatWithCurrency(
                        data?.total_amount,
                        client?.currency
                      )
                      : ""}
                  </div>
                </div>

              </div>

              {Taxes && (
                <>
                  <div className="Tax_Container">
                    {Taxes.map((el) => (
                      <div className="invoice_flex">
                        <div className="invoice_tax_amt_title">
                          {el.name + " " + el.percentage + "%"}
                        </div>

                        <div
                          className={
                            el.enable ? "default_color" : "invoice_taxes"
                          }
                        >

                          {el.enable && <span className="m-right"> {showCurrency(client.currency)}</span>}
                          {el.enable
                            ? (
                              numberFormatWithCurrency(
                                el.tax_amount, client?.currency ?? ''
                              )
                            )
                            : 0}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <div className="Tax_Container">
                <div className="invoice_flex">
                  <div className="invoice_tax_amt_title">Total Amount</div>
                  <div className="default_color">
                    {data?.final_amount ? (
                      <>

                        <span className="m-right"> {showCurrency(client.currency)}</span>

                        {data?.final_amount
                          ? numberWithRupees((data?.final_amount))
                          : ""}
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
        <Modal
          title="Invoice"
          style={{ top: 50 }}
          open={this.state.modal1Visible}
          onOk={() => this.setModal1Visible(false)}
          onCancel={() => this.setModal1Visible(false)}
          footer={[
            <div className="d-flex justify-content-between align-items-center">
              <button
                key="cancel"
                className="ad-btn-secondary"
                onClick={() => this.setModal1Visible(false)}
              >
                Cancel
              </button>
              <button
                key="submit"
                className="add-btn"
                onClick={() => this.submitHandle()}
              >
                Submit
              </button>
            </div>,
          ]}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className="admin_input"
          >
            <div>

              <div>
                <DateItem label='Paid Date' name='relievedDate' star={false} value={this.state.paid} placeholder="Paid Date" onChange={this.handleOnPaidChange} />
              </div>

              <div className="mt-3">
                <InputItem label='Received Amount' name='' star={false} value={Math.round(data.final_amount)} placeholder="" readonly={true} onChange={this.handleAmount} />

              </div>

              <div className="mt-3">
                <InputItem label='Notes' name='notes' star={false} value={notes} placeholder="Enter the notes" txtarea={true} onChange={this.handleNotes} />

              </div>

            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}
