import React, { Component } from "react";
import TopHeader from "../../components/ui/TopHeader";
import layer from "../../assets/Layer 2.svg";
import TableField from "../../components/ui/tableField";
import Apicall from "../../ApiService/ApiGateWay/apiGateWay";
import Filter from "../../components/ui/filter";
import { Modal, Spin, Empty } from "antd";
import {
  isErrorTxt,
  statusColor,
  showSuccess,
  showError,
  buildQueryString,
  pushToFilteredPage,
  returnSearchObject,
  showDate,
  numberWithComma,
  numberFormatWithCurrency,
  showCurrency,
} from "../../constants/Utils";
import { STORAGE, UPDATE, DELETE } from "../../constants/roleStore";
import ModalPopUp from "../../components/ui/modalpop";
import { LEADSACCESS } from "../../components/ui/constants";

export default class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 20,
      leads: "",
      dropdown_menu: false,
      lead_index: "",
      searchOption: "",
      searchInput: "",
      project_name: "",
      searchData: [
        { value: "Client Name", key: "client_name" },
        { value: "Project Name", key: "project_name" },
      ],
      statusData: [
        { key: "converted", value: "Converted" },
        { key: "hold", value: "Hold" },
        { key: "rejected", value: "Rejected" },
        { key: "pending", value: "Pending" },
      ],
      deleteLead: false,
      lead_id: "",
      loader: true,
      total: 0,
      queryStatus: {},
      overAll: "",
      app_name: "",
      app_type: "",
      update: false,
      deleted: false,
      status: "",
    };
    this.wrapperRef = React.createRef();
  }
  componentDidMount = () => {
    let name = "";

    let storage = JSON.parse(STORAGE.view);

    name = storage?.filter((app) => app?.name === "My Business");
    let value = name;

    value?.filter((app) => {
      let names = app?.sub_roles?.filter((role) => role.name === "Leads");
      names &&
        names.map((el) =>
          this.setState({
            update: el?.permissions?.update,
            deleted: el?.permissions?.delete,
          })
        );
      this.setState({
        app_name: names,
      });
    });
    this.setState({
      app_type: STORAGE.type,
    });
    this.setQueryStatus();
    window.addEventListener("popstate", this.onPopstate);
    document.addEventListener("click", this.clickOutside);

    this.reloadPage();
  };

  setQueryStatus = () => {
    let query = this.props.location.search;
    if (query) {
      let queryObj = returnSearchObject(query);
      this.setState(
        {
          searchOption: queryObj.type,
          searchInput: queryObj.value,
          status: queryObj.status,
          currentPage: parseInt(queryObj.page) ?? "",
          pageSize: parseInt(queryObj.limit) ?? "",
          queryStatus: returnSearchObject(query),
        },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    } else {
      this.setState(
        { queryStatus: { page: 1, limit: 20 }, pageSize: 20 },
        () => {
          pushToFilteredPage(this.props, this.state.queryStatus);
          this.handleTable();
        }
      );
    }
  };
  getQuerySearch = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    if (this.state.searchOption !== "" && this.state.searchInput !== "") {
      querysearch.type = this.state.searchOption;
      querysearch.value = this.state.searchInput;
      querysearch[this.state.searchOption] = this.state.searchInput;
    }

    querysearch.page = this.state.currentPage ?? "";
    querysearch.status = this.state.status ?? "";

    querysearch.limit = this.state.pageSize ?? "";

    return querysearch;
  };

  getApiQuery = () => {
    const querysearch = returnSearchObject(this.props.location.search);

    querysearch.page = this.state.currentPage ?? "";
    querysearch.limit = this.state.pageSize ?? "";
    if (this.state.searchOption !== "" && this.state.searchInput !== "") {
      // querysearch.type = this.state.searchOption;
      // querysearch.value = this.state.searchInput
      querysearch[this.state.searchOption] = this.state.searchInput;
    }
    return querysearch;
  };

  handleFilter = () => {
    let query = this.getQuerySearch();

    this.setState(
      {
        queryStatus: query,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.handleTable();
      }
    );
  };

  ApplyFilter = () => {
    const querysearch = this.getApiQuery();
    this.setState(
      {
        queryStatus: querysearch,
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.handleTable();
      }
    );
  };

  onPopstate = () => {
    const search = this.props.location.search;
    const searchObj = returnSearchObject(search);
    this.state.searchOption = searchObj.type ?? "";
    this.state.status = searchObj.status ?? "";
    this.state.currentPage = parseInt(searchObj.page) ?? "";
    this.state.pageSize = parseInt(searchObj.limit) ?? "";

    this.state.searchInput = searchObj.value;
    this.setState({ queryStatus: this.getQuerySearch() }, () => {
      this.handleTable();
    });
  };

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopstate, false);
    document.removeEventListener("click", this.clickOutside);
  }
  clickOutside = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ dropdown_menu: false });
    }
  };
  reloadPage = () => {
    this.props.history.listen((location, search) => {
      this.setState({ queryStatus: {} }, () => {
        let query = this.props.location.search;
        query = returnSearchObject(query);

        if (Object.keys(query).length === 0) {
          this.clear();
        }
      });
    });
  };

  handleTable = () => {
    Apicall.get(
      "leads" + buildQueryString(this.state.queryStatus),
      (response) => {
        if (response.success) {
          let data = response?.data?.leads;
          this.setState({
            leads: data,
            overAll: response?.data?.total,
            total: data.length,

            loader: false,
          });
        }
      }
    );
  };

  dropdownMenu = (index) => {
    this.setState({
      lead_index: index,
      dropdown_menu: !this.state.dropdown_menu,
    });
  };

  searchOption = (value) => {
    this.setState({ searchOption: value });
  };

  searchInput = (e) => {
    this.setState({ searchInput: e.target.value });
  };

  handledropMenu = (e, item) => {
    if (e === "edit") {
      this.props.history.push("/leads/edit/" + item.leads_id);
    } else if (e === "delete") {
      this.setState({
        project_name: item?.project_name,
        deleteLead: true,
        lead_id: item?.leads_id,
      });
    }
  };

  cancelDelete = () => {
    this.setState({
      project_name: "",
      deleteLead: false,
      lead_id: "",
    });
  };

  leadDetailpage = (id) => {
    this.props.history.push("/leads/detail/" + id);
  };

  submitDelete = () => {
    let data = {};
    Apicall.delete("leads/" + this.state.lead_id, data, (response) => {
      if (response.success) {
        showSuccess(response.message);
        this.handleTable();

        this.setState({
          project_name: "",
          deleteLead: false,
          lead_id: "",
        });
      } else {
        showError(response.message);
      }
    });
  };

  clear = () => {
    this.setState(
      {
        currentPage: 1,
        queryStatus: { page: 1, limit: 20 },
        pageSize: 20,
        searchOption: "",
        searchInput: "",
        status: "",
      },
      () => {
        pushToFilteredPage(this.props, this.state.queryStatus);
        this.handleTable();
      }
    );
  };

  Apply = () => {
    const { searchOption, searchInput, status } = this.state;
    if (searchOption === "" && searchInput === "" && status === "") {
      showError("Please select atleast anyone field");
    } else {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handleFilter();
        }
      );
    }
  };

  handlepagechange = (e) => {
    this.setState(
      {
        pageSize: e,
        currentPage: 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  prevPage = () => {
    this.setState(
      {
        currentPage: this.state.currentPage - 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  nextPage = (page) => {
    this.setState(
      {
        currentPage: page + 1,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  hanleStatus = (e) => {
    this.setState(
      {
        status: e,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  render() {
    const {
      currentPage,
      pageSize,
      leads,
      searchData,
      lead_index,
      dropdown_menu,
      project_name,
      deleteLead,
      loader,
      searchOption,
      searchInput,
      total,
      overAll,
      app_type,
      app_name,
      statusData,
      status,
    } = this.state;
  
    let countnext = total;
    let countprev = total - (total - 1);
    if (currentPage > 1) {
      countnext = (currentPage - 1) * pageSize + total;
      countprev = countnext - (total - 1);
    }
    return (
      <div>
        {loader ? (
          <div className="tableview">
            <Spin />
          </div>
        ) : (
          <div>
            <TopHeader
              title="Leads"
              to="/leads/add"
              type={app_type}
              permission={app_name}
            />
            <div className="main-content">
              <Filter
                statusData={statusData}
                statusName="status"
                statusSelect={status}
                statusOnChange={(e) => this.hanleStatus(e)}
                searchData={searchData}
                optionChange={(e) => this.searchOption(e)}
                searchOption={searchOption}
                searchname="searchOption"
                clear={() => this.clear()}
                apply={() => this.Apply()}
                searchInputName="searchInput"
                searchInput={searchInput}
                inputChange={(e) => this.searchInput(e)}
              />

              <div className="table-main">
                <div className="table_page_wrap">
                  <table className="table-response">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Client Name</th>
                        <th>Project Title</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Quote</th>
                        <th>Expected</th>

                        <th></th>
                      </tr>
                    </thead>
                    {leads.length > 0 ? (
                      <tbody ref={this.wrapperRef}>
                        {leads &&
                          leads.map((lead, l) => (
                            <tr>
                              <td> {(currentPage - 1) * pageSize + (l + 1)}</td>
                              <td
                                className="text-capitalize cursor-pointer"
                                onClick={() =>
                                  this.leadDetailpage(lead.leads_id)
                                }
                              >
                                {isErrorTxt(lead?.client_name)
                                  ? "-"
                                  : lead?.client_name}
                              </td>
                              <td className="text-capitalize">
                                {isErrorTxt(lead?.project_name)
                                  ? "-"
                                  : lead?.project_name}
                              </td>
                              <td>
                                {isErrorTxt(lead?.createdAt)
                                  ? "-"
                                  : showDate(lead?.createdAt)}
                              </td>

                              <td className="text-capitalize">
                                {isErrorTxt(lead?.status)
                                  ? "-"
                                  : statusColor(lead?.status)}
                              </td>
                              <td>
                                {showCurrency(lead?.amount?.currency)}
                                {isErrorTxt(lead?.amount?.quote)
                                  ? numberFormatWithCurrency(0)
                                  : numberFormatWithCurrency(
                                      lead?.amount?.quote,
                                      lead?.amount?.currency
                                    )}
                              </td>
                              <td>
                                {showCurrency(lead?.amount?.currency)}
                                {isErrorTxt(lead?.amount?.expected)
                                  ? numberFormatWithCurrency(0)
                                  : numberFormatWithCurrency(
                                      lead?.amount?.expected,
                                      lead?.amount?.currency
                                    )}
                              </td>

                              {(LEADSACCESS.update ||
                                LEADSACCESS.delete) && (
                                  <td
                                    className="table-action"
                                    onClick={() => this.dropdownMenu(l)}
                                  >
                                    <div className="tax-add-edit">
                                      <span className="ml-5">
                                        <div className="dropdown-head">
                                          <div className="dropbtn-head">
                                            <img
                                              src={layer}
                                              alt="profile"
                                              className="menu-bar-icon"
                                            />
                                          </div>
                                          {lead_index === l &&
                                            dropdown_menu && (
                                              <div className="dropdown-content-heads">
                                                <div>
                                                  {LEADSACCESS.update && (
                                                    <p
                                                      className="action-menu-bar"
                                                      onClick={() =>
                                                        this.handledropMenu(
                                                          "edit",
                                                          lead
                                                        )
                                                      }
                                                    >
                                                      Edit
                                                    </p>
                                                  )}

                                                  {LEADSACCESS.delete && (
                                                    <p
                                                      className="action-menu-bar"
                                                      onClick={() =>
                                                        this.handledropMenu(
                                                          "delete",
                                                          lead
                                                        )
                                                      }
                                                    >
                                                      Delete
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      </span>
                                    </div>
                                  </td>
                                )}
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="empty-message">
                        <td colspan="8">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tbody>
                    )}
                  </table>

                  <TableField
                    data={leads}
                    countprev={countprev}
                    countnext={countnext}
                    currentPage={currentPage}
                    ClickPrev={() => this.prevPage()}
                    ClickNext={() => this.nextPage(currentPage)}
                    total={total}
                    overAll={overAll ?? 0}
                    pagelimit={pageSize}
                    pagelimitHandle={this.handlepagechange}
                  />
                </div>
              </div>
            </div>
            <ModalPopUp
              visible={deleteLead}
              cancel={this.cancelDelete}
              submit={this.submitDelete}
              name="Lead"
              delete_name={project_name}
            />
          </div>
        )}
      </div>
    );
  }
}
